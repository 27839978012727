import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-simple-payment-content",
  templateUrl: "./simple-payment-content.component.html",
  styleUrls: ["./simple-payment-content.component.scss"]
})
export class SimplePaymentContentComponent {

  @Input() vehicleModel: string;

  @Output() toggleShowCalculator = new EventEmitter();

  constructor() { }

  onToggleShowCalculator() {
    this.toggleShowCalculator.emit();
  }

}

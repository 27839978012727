import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService, DealService, VehicleService } from "src/app/clearpath-module/services";
import { Router } from "@angular/router";
import { MODEL_TABLE } from "../../../../sales-manager-module/data/modelTable";
import { VehicleModel } from "src/app/sales-manager-module/models/data";

@Component({
  selector: "app-model-lookup",
  templateUrl: "./model-lookup.component.html",
  styleUrls: ["./model-lookup.component.scss"]
})
export class ModelLookupComponent implements OnInit {

  models = MODEL_TABLE;
  filteredModels: VehicleModel[] = [];
  searchText: string;
  sortParam = "model";
  filter = false;
  ascending = false;
  changeDealOnSelectVehicle = true;
  private unsubscribe$ = new Subject();

  constructor(
    private appService: AppService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.subToSearchString();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isActiveSort(type: string): boolean {
    return this.sortParam === type;
  }

  private subToSearchString() {
    this.appService.selectSearchString()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((searchString: string) => {
        this.searchText = searchString;
        if (this.searchText) {
          this.filterModels();
          this.filter = true;
        } else {
          this.filter = false;
        }
      });
  }

  filterModels() {
    this.filteredModels = this.models.filter((model: VehicleModel) => {
      return Object.keys(model).filter(key => {
        const prop = model[ key ];
        if (Array.isArray(prop)) {
          return prop.includes(this.searchText);
        } else {
          const stringProp = String(prop);
          const nonNumericSearchString = this.searchText.toLowerCase().replace(/\D/g, "");
          return stringProp.toLowerCase().includes(nonNumericSearchString);
        }
      }).length > 0;
    });
  }

  get modelList() {
    return this.filter ? this.filteredModels : this.models;
  }

  getType(vehicle) {
    return this.vehicleService.vehicleCondition(vehicle);
  }

  // ACTIONS

  onSelect(model: VehicleModel) {

    // this.vehicleService.dispatchSetModel(model);
    this.router.navigate([{outlets: {modal: null}}]);
    this.vehicleService.dispatchSetVehicle({...model, modelNumber: model.modelNumber.toString()});
  }

  onSelectSort(type: string) {
    this.sortParam = type;
    this.ascending = !this.ascending;
  }

}

<div class="app-page-container">

  <div *ngIf="waitOnInit" class="d-flex app-text-success justify-content-center align-items-center">
    <span class="spinner-border spinner-border-sm mr-1"></span>
    Loading Users...
  </div>

  <!-- USER LIST -->
  <section *ngIf="!waitOnInit && currentView('defaultView')">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <h1 class="mb-3 mr-3" style="line-height: 1">
        Users
      </h1>
      <button class="btn app-btn-secondary mb-3" type="button" (click)="onSelectView('createUserForm')"
              [disabled]="noSecurityGroups || waitOnAction">
        Create New
      </button>
    </div>

    <!-- SEARCH -->
    <form [formGroup]="searchForm" class="input-group mb-3">
      <div class="input-group-prepend">
        <button class="btn app-btn-success" style="z-index:0" type="submit" (click)="onSearch(searchForm.value)">
          Search
        </button>
      </div>
      <input class="form-control" formControlName="name" type="text" [maxlength]="REQS.maxLength.standard"
             placeholder="by name" required/>
      <div class="input-group-append">
        <button *ngIf="searchActive()" class="btn app-btn-neutral" type="button" (click)="onClearSearch()">
          Clear
        </button>
      </div>
    </form>

    <!-- FILTER BY SECURITY GROUP -->
    <!-- <div *ngIf="users.length > 0" class="input-group mb-3">
      <div class="d-flex align-items-start">
        <p class="mt-auto mb-auto" style="font-weight: bold">Security&nbsp;Groups:</p>
        <div class="d-flex flex-wrap">
          <div *ngFor="let group of securityGroupFilterOptions; let i = index;"
            class="d-flex align-items-center pl-3 pr-3">
            <span class="pr-2">{{group.name}}</span>
            <input type="checkbox" checked="group.selected" (click)="toggleSelectSecurityGroup(i)">
          </div>
        </div>
      </div>
    </div> -->

    <article *ngFor="let user of users; let i = index" class="card mb-3"
             [ngClass]="{ 'd-none': !searchMatch(user) }">
      <div class="card-header app-text-white app-bg-dark d-flex justify-content-start">
        <div class="d-flex align-items-center">
          <img *ngIf="user.photoUrl" [src]="user.photoUrl" class="img-thumbnail rounded-circle mr-3"
               style="height: 50px; width: 50px; object-fit: cover" (error)="user.photoUrl = ''"/>
          <img *ngIf="!user.photoUrl" appDealerIcon class="img-thumbnail rounded-circle mr-3"
               style="height: 50px; width: 50px; object-fit: cover"/>
          <span>{{ user.firstName + ' ' + user.lastName }}</span>
        </div>
      </div>
      <div class="card-body d-flex align-items-start justify-content-between">
        <div>
          <p class="card-text"><strong>User ID:</strong> {{ user.id }}</p>
          <p class="card-text text-break"><strong>Email:</strong> {{ user.email }}</p>
          <p class="card-text text-break"><strong>Phone:</strong> {{ formatPhoneNumber(user.smsCellNumber) }}</p>
          <p class="card-text text-break"><strong>Security Groups:</strong> {{ user.securityGroups || 'N/A' }}</p>
        </div>
        <div class="btn-group-vertical ml-3">
          <button class="btn btn-sm app-btn-success" type="button" (click)="onSelectEditTarget(user)"
                  [disabled]="noSecurityGroups || waitOnAction">
            Edit
          </button>
          <button class="btn btn-sm app-btn-neutral" type="button" (click)="deleteUser(user)" [disabled]="waitOnAction">
            Delete
          </button>
        </div>
      </div>
    </article>
  </section>

  <!-- CREATE USER -->
  <section *ngIf="currentView('createUserForm')">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <h1 class="mb-3 mr-3" style="line-height: 1">
        Create User
      </h1>
      <button class="btn app-btn-neutral mb-3" type="button" (click)="onSelectView('defaultView')">
        Cancel
      </button>
    </div>

    <app-user-form [securityGroups]="securityGroups" [waitOnAction]="waitOnAction" (createUser)="createUser($event)">
    </app-user-form>
  </section>

  <!-- EDIT USER -->
  <section *ngIf="currentView('editUserForm')">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-3 mr-3">
        <h1 class="mr-3 mb-0" style="line-height: 1">
          Edit User
        </h1>
        <span class="badge badge-dark">{{'USER ID: ' + currentEditTarget.id + ''}}</span>
      </div>
      <button class="btn app-btn-neutral mb-3" type="button" (click)="onCancelEditTarget()">
        Cancel
      </button>
    </div>

    <app-user-form [securityGroups]="securityGroups" [editTarget]="currentEditTarget" [waitOnAction]="waitOnAction"
                   (editUser)="editUser($event)">
    </app-user-form>
  </section>

</div>

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { DealState } from "src/app/clearpath-module/store/state";
import * as dealActions from "../../../clearpath-module/store/actions/deal.actions";
import { User } from "src/app/user-admin-module/models";
import { DEAL_STATUSES } from "src/app/app.config";
import { partition } from "ramda";
import { AuthService } from "src/app/auth-module/services";
import { AppService } from "src/app/clearpath-module/services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { UserService } from "src/app/user-admin-module/services";
import { HistoryService } from "src/app/clearpath-module/services/history.service";
import { DealHistory } from "src/app/clearpath-module/models/history";
import { Router } from "@angular/router";

@Component({
  selector: "app-writeups-list",
  templateUrl: "./writeups-list.component.html",
  styleUrls: ["./writeups-list.component.scss"]
})
export class WriteupsListComponent implements OnInit, OnChanges, OnDestroy {

  private unsubscribe$ = new Subject();

  @Input() deals: DealState[] = [];
  @Input() searchText: string;
  @Input() selectedManagerId: string;
  @Input() currentUser: User;
  @Input() loading = true;
  @Input() isSalesManager: boolean;
  @Output() dealSelected = new EventEmitter<any>();

  histories: DealHistory[] = [];
  dealList: DealState[] = [];
  salesManagerDeals: DealState[] = [];
  otherDeals: DealState[] = [];
  sortParam = "statusDate";
  ascending = false;
  salesManagers: User[];

  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private historyService: HistoryService,
    private appService: AppService,
    private userService: UserService
  ) { }

  // COMPONENT LIFECYCLE

  ngOnInit() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.notificationsClicked),
    ).subscribe({next: () => this.onSelectSort("alerts", false)});
    this.getSalesManagers();

    this.historyService.selectHistories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((histories: DealHistory[]) => {
        this.histories = histories || [];
      });


  }

  ngOnChanges() {

    /*const {salesManagerDeals, otherDeals} = this.partitionDeals();
    this.salesManagerDeals = salesManagerDeals;
    this.otherDeals = otherDeals;

    if (this.authService.isSalesManager(this.currentUser)) {
      if (this.currentUser.employeeId) {
        this.salesManagerDeals = this.partitionDeals().salesManagerDeals;
        this.otherDeals = this.partitionDeals().otherDeals;
        this.dealList = [];
      }
    }*/

    this.dealList = this.deals;
    this.dispatchListLength();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  partitionDeals(): { salesManagerDeals: DealState[], otherDeals: DealState[] } {
    const [salesManagerDeals, otherDeals] = partition(
      deal => deal.managerId === this.currentUser.employeeId,
      this.deals
    );
    return {salesManagerDeals, otherDeals};
  }

  private getSalesManagers() {
    this.userService.getSalesManagers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (salesManagers: User[]) => {
          this.salesManagers = salesManagers || [];
        }
      });
  }

  hasAlert(deal: DealState) {
    if (this.authService.isSalesManager(this.currentUser)) {
      if (deal.managerId === this.currentUser.employeeId) {
        if (deal.status === DEAL_STATUSES.Submitted) {
          return true;
        }
      }
    } else {
      if (deal.status === DEAL_STATUSES.Approved ||
        deal.status === DEAL_STATUSES.Updated) {
        return true;
      }
    }
  }

  // ACTIONS


  dispatchListLength() {
    const partitionedListsLength = this.salesManagerDeals.length + this.otherDeals.length + this.dealList.length;
    if (partitionedListsLength) {
      this.appService.dispatchSetWriteupListLength(partitionedListsLength);
    }
    if (this.deals.length) {
      this.appService.dispatchSetWriteupListLength(this.deals.length);
    }
  }

  onSelectDeal(deal) {
    this.dealSelected.emit(deal);
  }

  onSelectDealHistory(deal) {
    this.router.navigate([`/sales-manager/activity/${deal.dealId}`]);
  }

  // UI CONTROL & RENDERING

  getHistory(dealId: string) {
    const index = this.histories.findIndex((history: DealHistory) => history.dealId === dealId);
    return this.histories[ index ];
  }

  getSalesManagerName(deal: DealState): string {
    const {managerId} = deal;
    const managerObject = (this.salesManagers || []).find(mgr => {
      return mgr.employeeId === managerId;
    });
    return managerId && managerObject ?
      `${managerObject.firstName} ${managerObject.lastName}` :
      "---";
  }

  onSelectSort(type: string, toggleAscending = true) {
    const {salesManagerDeals, otherDeals} = this.partitionDeals();
    this.dealList = [...salesManagerDeals, ...otherDeals];
    if (type === "alerts") {
      this.dealList = this.dealList.sort((a, b) => {
        const aHasAlert = this.hasAlert(a) ? -1 : 1;
        const bHasAlert = this.hasAlert(b) ? -1 : 1;
        return aHasAlert - bHasAlert;
        // return this.ascending ? aHasAlert - bHasAlert : bHasAlert - aHasAlert;
      });
    }
    if (type === "state") {
      this.dealList = this.dealList.sort((a, b) => {
        const a1 = a?.customer?.state?.toLowerCase();
        const b1 = b?.customer?.state?.toLowerCase();
        if (a1 <= b1) return this.ascending ? -1 : 1;
        if (a1 > b1) return this.ascending ? 1 : -1;
        //return 0;
      });
    }
    this.sortParam = type;
    this.ascending = toggleAscending ? !this.ascending : this.ascending;
    this.salesManagerDeals = [];
    this.otherDeals = [];
    this.dispatchListLength();
  }

  isActiveSort(type: string): boolean {
    return this.sortParam === type;
  }

}

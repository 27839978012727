<div class="card card-body flex-item tight mt-3">
  <div class="d-flex flex-row align-items-baseline panel-with-button header-row">
    <h5 class="card-title">{{formLabel}}</h5>
    <button *ngIf="showCopyButton" class="header-icon-button shift-down" (click)="copyRequested.emit()">
      <i class="material-icons app-text-white">assignment</i>
    </button>
  </div>


  <form [formGroup]="formGroup">
    <table class="table table-striped">
      <tbody>
      <tr>
        <td>First Name</td>
        <td><input formControlName="firstName" class="float-right" type="text"/></td>
      </tr>
      <tr>
        <td>Middle Name</td>
        <td><input formControlName="middleName" class="float-right" type="text"/></td>
      </tr>
      <tr>
        <td>Last Name</td>
        <td><input formControlName="lastName" class="float-right" type="text"/></td>
      </tr>
      <tr>
        <td>Address</td>
        <td><input formControlName="street" class="float-right" type="text"/></td>
      </tr>
      <tr>
        <td>City</td>
        <td><input formControlName="city" class="float-right" type="text"/></td>
      </tr>
      <tr>
        <td>State</td>
        <td><input formControlName="state" class="float-right" type="text"/></td>
      </tr>
      <tr>
        <td>Zip</td>
        <td><input formControlName="zip" class="float-right" type="text"/></td>
      </tr>
      <tr>
        <td>Phone Number</td>
        <td><input formControlName="phone" class="float-right" type="text" maxlength="10"/></td>
      </tr>
      <tr>
        <td>Email Address</td>
        <td><input formControlName="email" class="float-right" type="text"/></td>
      </tr>
      </tbody>
    </table>
  </form>
</div>

// OUR MODULES
import { SharedModule } from "../shared-module/shared.module";
import { ClearpathRoutingModule } from "./clearpath-routing.module";

// OTHER MODULES / CORE
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

// NGRX
import { StoreModule } from "@ngrx/store";
import { rootReducer } from "./store/reducers/clearpath.reducer";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects, DealChangeEffects, DealEffects, HistoryEffects, RouterEffects, VehicleEffects } from "./store/effects";

// COMPONENTS
import { ClearpathComponent, NewDealComponent, SalesPersonWriteupsListComponent } from "./pages";
import {
  AccessoryItemComponent,
  AddAccessoryComponent,
  BuyBoxCashComponent,
  BuyBoxCustomComponent,
  BuyBoxFinanceComponent,
  BuyBoxLeaseComponent,
  BuyBoxMenuComponent,
  ClearGraphComponent,
  CreditTierInputComponent,
  CustomRateInputComponent,
  DownPaymentComponent,
  FuelSavingsCalculatorComponent,
  FuelSavingsModalComponent,
  GapGraphComponent,
  InsuranceItemEliteComponent,
  InsuranceItemEwuComponent,
  InsuranceItemGapComponent,
  InsuranceItemPpmComponent,
  InsuranceItemTwpComponent,
  InsuranceItemVsaComponent,
  InsuranceMenuComponent,
  MilesPerYearCalculatorComponent,
  MilesPerYearModalComponent,
  ProductGraphComponent,
  RollPaymentCalculatorComponent,
  RollPaymentModalComponent,
  SideNavComponent,
  SimplePaymentCalculatorComponent,
  SimplePaymentContainerComponent,
  SimplePaymentContentComponent,
  SimplePaymentModalComponent,
  StockNumberComponent,
  StockNumberModalComponent,
  SubmitModalComponent,
  VehicleBaseFinanceComponent,
  VehicleImageComponent,
  VehicleIncentiveComponent,
  VehicleIncentivesComponent,
  VehicleLookupComponent,
  VehiclePriceComponent,
  VehicleTradeInComponent,
  WarrantyComponent,
  WarrantyFooterComponent,
  WarrantyFormComponent,
  WarrantyGraphComponent,
  WarrantyNavButtonComponent,
  WarrantyUiModalComponent
} from "./components";
import { CalculationExposureComponent } from "./components/calculation-exposure/calculation-exposure.component";
import { ModelLookupComponent } from "./components/modals/model-lookup/model-lookup.component";
import { TradeInFormComponent } from "./components/modals/submit-modal/forms/trade-in-form/trade-in-form.component";
import { TradeInOwnerFormComponent } from "./components/modals/submit-modal/forms/trade-in-owner-form/trade-in-owner-form.component";
import { CreditCheckComponent } from './pages/credit-check/credit-check.component';
import { TaxLookupModalComponent } from './components/modals/tax-lookup-modal/tax-lookup-modal.component';
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { CnaListDialogHeaderComponent } from './components/cna-list-dialog-header/cna-list-dialog-header.component';
import { FinanceVsCashModalComponent } from './components/modals/finance-vs-cash-modal/finance-vs-cash-modal/finance-vs-cash-modal.component';
import { FinanceVsCashCalculatorComponent } from './components/calculation-widgets/finance-vs-cash-calculator/finance-vs-cash-calculator/finance-vs-cash-calculator.component';
import { JdStudyInfoComponent } from './components/insurance-item-vsa/jd-study-info/jd-study-info.component';
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [
    ClearpathComponent,
    SalesPersonWriteupsListComponent,
    SideNavComponent,
    VehicleBaseFinanceComponent,
    VehicleImageComponent,
    VehiclePriceComponent,
    VehicleTradeInComponent,
    DownPaymentComponent,
    CreditTierInputComponent,
    CustomRateInputComponent,
    InsuranceMenuComponent,
    InsuranceItemEwuComponent,
    InsuranceItemGapComponent,
    InsuranceItemPpmComponent,
    InsuranceItemTwpComponent,
    InsuranceItemVsaComponent,
    InsuranceItemEliteComponent,
    BuyBoxMenuComponent,
    BuyBoxFinanceComponent,
    BuyBoxCustomComponent,
    BuyBoxLeaseComponent,
    AddAccessoryComponent,
    AccessoryItemComponent,
    SimplePaymentContainerComponent,
    SimplePaymentContentComponent,
    SimplePaymentCalculatorComponent,
    MilesPerYearCalculatorComponent,
    FuelSavingsCalculatorComponent,
    NewDealComponent,
    RollPaymentCalculatorComponent,
    StockNumberComponent,
    WarrantyComponent,
    WarrantyFooterComponent,
    WarrantyGraphComponent,
    WarrantyFormComponent,
    WarrantyNavButtonComponent,
    ProductGraphComponent,
    GapGraphComponent,
    FuelSavingsModalComponent,
    MilesPerYearModalComponent,
    RollPaymentModalComponent,
    SimplePaymentModalComponent,
    StockNumberModalComponent,
    SubmitModalComponent,
    VehicleLookupComponent,
    WarrantyUiModalComponent,
    VehicleIncentivesComponent,
    VehicleIncentiveComponent,
    BuyBoxCashComponent,
    ClearGraphComponent,
    CalculationExposureComponent,
    ModelLookupComponent,
    TradeInFormComponent,
    TradeInOwnerFormComponent,
    CreditCheckComponent,
    TaxLookupModalComponent,
    CnaListDialogHeaderComponent,
    FinanceVsCashModalComponent,
    FinanceVsCashCalculatorComponent,
    JdStudyInfoComponent,
  ],
  imports: [
    CommonModule,
    ClearpathRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    StoreModule.forFeature("clearpath", rootReducer),
    EffectsModule.forFeature([
      AppEffects, RouterEffects, VehicleEffects, DealEffects, HistoryEffects, DealChangeEffects
    ]),
    HttpClientModule,
    NgxJsonViewerModule,
    MatIconModule,
    MatDialogModule
  ]
})
export class ClearpathModule {}

export {
  SalesManagerWriteupComponent
} from "./sales-manager-writeup/sales-manager-writeup.component";
export {
  SalesManagerWriteupsListComponent
} from "./sales-manager-writeups-list/sales-manager-writeups-list.component";
export {
  MasterSalesManagerWriteupComponent
} from "./master-sales-manager-writeup/master-sales-manager-writeup.component";


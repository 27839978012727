import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertComponent, FooterComponent, ManagerSelectComponent, ModalViewComponent, NavbarComponent, PageNotFoundComponent, SearchFormComponent, SignaturePadComponent, WriteupsListComponent } from "./components";
import { ClickOutsideDirective, DealerIconDirective, DealerLogoDirective } from "./directives";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DealFilterPipe, DealSortPipe, HowOldPipe, OrderByPipe, TeamFilterPipe, VehicleNamePipe, VehicleSortPipe } from "./pipes/";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { ActivityComponent } from './components/activity/activity.component';
import { ActivityEventsComponent } from './components/activity-events/activity-events.component';
import { CDK_DRAG_CONFIG, DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

@NgModule({
  declarations: [
    AlertComponent,
    ClickOutsideDirective,
    DealerIconDirective,
    DealerLogoDirective,
    PageNotFoundComponent,
    ManagerSelectComponent,
    NavbarComponent,
    ModalViewComponent,
    SearchFormComponent,
    WriteupsListComponent,
    DealFilterPipe,
    DealSortPipe,
    VehicleSortPipe,
    HowOldPipe,
    OrderByPipe,
    TeamFilterPipe,
    VehicleNamePipe,
    SignaturePadComponent,
    FooterComponent,
    FeedbackComponent,
    ActivityComponent,
    ActivityEventsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatButtonModule
  ],
  exports: [
    CommonModule,
    DealerIconDirective,
    DealerLogoDirective,
    AlertComponent,
    OrderByPipe,
    NavbarComponent,
    FooterComponent,
    ModalViewComponent,
    PageNotFoundComponent,
    SearchFormComponent,
    WriteupsListComponent,
    VehicleNamePipe,
    HowOldPipe,
    VehicleSortPipe,
    SignaturePadComponent,
    ClickOutsideDirective
  ],
  providers: [{provide: CDK_DRAG_CONFIG, useValue: DragConfig}]
})
export class SharedModule {}

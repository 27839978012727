import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class PipeHelpers {

  compare = (a: string | number, b: string | number, ascending: boolean): number => {
    if (ascending) {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    } else {
      if (a > b) { return -1; }
      if (a < b) { return 1; }
      return 0;
    }
  }

}

import { Pipe, PipeTransform } from "@angular/core";

/*
This pipe ensures that any value passed to an <input type"***">
will be valid html.
It will also take custom types, which are properties of objects,
and transform them into the correct input type for editing in a form.
*/
@Pipe({
  name: "type"
})
export class TypePipe implements PipeTransform {
  // all accepted types of html inputs
  acceptedTypes = [
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week"
  ];

  // custom types are the name of properties on the models that are editable
  /* the inputs that edit the properties are assigned
  the correct type in transform with customTypesMap */
  customTypesMap = {
    name: "text",
    securityGroups: "text"
  };

  transform(value: any): any {
    value = this.customTypesMap[ value ] || value;
    return this.acceptedTypes.includes(value) ? value : null;
  }
}

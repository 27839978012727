import { Injectable } from "@angular/core";
import { FinanceOptions, FinancingSettings, InsuranceProduct, LeaseOptions, LeaseResidual, TaxResult, Vehicle, VehicleNeeds } from "../../models";
import { VehicleService } from "../vehicle.service";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { DealService } from "../deal/deal.service";
import { AppService } from "../app.service";
import { CalculationUtilityService } from "./calculation-utility.service";
import { clone, pathOr } from "ramda";
import { DealIncentive, LeaseOfferTerm } from "src/app/settings-module/models/incentives";
import { CalculationService } from "./calculation.service";
import { DealIncentivesService } from "../deal/deal-incentives.service";
import { DealState } from "../../store/state";
import { DEFAULT_LEASE_MONEY_FACTORS, DEFAULT_LEASE_W_TIER_MONEY_FACTOR_REDUCTION, DEFUALT_LEASE_LONG_TERM_MONEY_FACTOR_ADDITION, HIGH_FICO_REDUCTION, PREPAID_MILEAGE_RATE, PREPAID_MILES_PER_YEAR } from "src/app/app.config";
import { LeaseTerm } from "../../models/calculations";
import Big from "big.js";

@Injectable({
  providedIn: "root"
})
export class LeaseCalculationService {

  constructor(
    private vehicleService: VehicleService,
    private calculationUtilityService: CalculationUtilityService,
    private dealService: DealService,
    private appService: AppService,
    private calculationService: CalculationService,
    private dealIncentivesService: DealIncentivesService
  ) { }

  // Lease Calculations

  residualValue$(term?: number): Observable<any> {
    // TODO: Save lease miles per year in lease options for use here
    return combineLatest([
      this.getLeaseResiduals(),
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle()
    ]).pipe(
      map(([leaseResiduals, deal, vehicle]) => {
        const msr = deal.financeOptionsEdits.msr || vehicle.msr;
        term = term || deal.leaseOptions.selectedLeaseTerm;
        const residual = leaseResiduals.filter((el: LeaseResidual) => {
          return term === el.term;
        })[ 0 ];
        if (!deal.vehicleNeeds.milesDrivenPerYear) {
          if (residual) {
            return residual.standard;
          }
        }
        if (!residual) { return 0; }
        const residualPercentages = this.getResidualPercentage(residual, msr);
        if (deal.vehicleNeeds.milesDrivenPerYear >= 15000) {
          return residual.standard;
        }
        if (
          deal.vehicleNeeds.milesDrivenPerYear >= 12000 &&
          deal.vehicleNeeds.milesDrivenPerYear < 15000
        ) {
          return residual.low;
        }
        if (
          deal.vehicleNeeds.milesDrivenPerYear >= 10000 &&
          deal.vehicleNeeds.milesDrivenPerYear < 12000
        ) {
          return residual.lowest;
          // return Big(residualPercentages.lowest).times(msr).toNumber();
        }
      })
    );
  }

  getResidualPercentage(residual: LeaseResidual, msr: number) {
    const standard = msr ? Big(residual.standard).div(msr).toNumber() : 0;
    const low = msr ? Big(residual.low).div(msr).toNumber() : 0;
    const lowest = Big(low).add(.01).toNumber();
    return {
      standard,
      low,
      lowest
    };
  }

  private calcGrossCapCost(
    customizedVehiclePrice: number,
    fees: number,
    tax: number,
    excessMileageCharge: number,
    negativeTradeEquity: number
  ): number {
    return Big(customizedVehiclePrice || 0)
      .add(fees || 0)
      .add(tax || 0)
      .add(excessMileageCharge || 0)
      .add(Math.abs(negativeTradeEquity || 0))
      .round(2)
      .toNumber();
  }

  moneyFactor$ = (term?: number) => {
    return combineLatest([
      this.appService.selectFinancing(),
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle()
    ]).pipe(map((
      [financingSettings, deal, vehicle]:
        [FinancingSettings, DealState, Vehicle]
    ) => {
      return this.calcMoneyFactorLogic(term, financingSettings, deal, vehicle);
    }));
  };

  moneyFactorType$ = (term?: number) => {
    return combineLatest([
      this.dealService.selectDeal()
    ]).pipe(map((
      [deal]: [DealState]
    ) => {
      const selectedTermIndex = pathOr([], ["leaseOptions", "leaseTerms"], deal).findIndex((leaseTerm: LeaseTerm) => {
        return leaseTerm.term === term;
      });
      const customMoneyFactor = pathOr(
        false, ["leaseOptions", "leaseTerms", selectedTermIndex, "customMoneyFactor"], deal
      );
      if (customMoneyFactor) {
        return 'CUSTOM';
      }
      const selectedCreditTier = deal.financeOptions.selectedCreditTier;
      const incentive = (deal.incentives || []).find(i => (
        i.leaseOffer && i.leaseOffer.leaseOfferTerms && i.leaseOffer.leaseOfferTerms.length > 0
      ));
      const isLeaseCashApplied = incentive && incentive.leaseCash > 0 && !incentive.leaseCashDisabled;
      if (incentive && incentive.leaseOffer && !isLeaseCashApplied) {
        const selectedLeaseOffer = this.findLeaseOffer(incentive.leaseOffer.leaseOfferTerms, term);
        if (selectedLeaseOffer) {
          const selectedMFOffer = pathOr(null, ["tieredLeaseMFs", selectedCreditTier], selectedLeaseOffer);
          if (selectedMFOffer && Big(selectedMFOffer).round(6).toNumber() > 0) {
            return 'INCENTIVE';
          }
        }
      }
      return 'STANDARD';
    }));
  };

  calcMoneyFactorLogic(term, financingSettings, deal, vehicle) {
    const selectedTermIndex = pathOr([], ["leaseOptions", "leaseTerms"], deal).findIndex((leaseTerm: LeaseTerm) => {
      return leaseTerm.term === term;
    });
    const customMoneyFactor = pathOr(
      false, ["leaseOptions", "leaseTerms", selectedTermIndex, "customMoneyFactor"], deal
    );
    let customMoneyFactorValue = 0;
    if (customMoneyFactor) {
      customMoneyFactorValue = pathOr(
        false, ["leaseOptions", "leaseTerms", selectedTermIndex, "moneyFactor"], deal
      );
    }
    const moneyFactor = this.calcMoneyFactor({
      vehicleCondition: this.vehicleService.vehicleCondition(vehicle),
      financingSettings,
      incentives: deal.incentives,
      selectedCreditTier: deal.financeOptions.selectedCreditTier,
      selectedLeaseTerm: term || deal.leaseOptions.selectedLeaseTerm,
      customMoneyFactor: customMoneyFactorValue
    });
    // console.log("Final MoneyFactor$: ", moneyFactor);
    return moneyFactor;
  }

  // calculation is without tax
  calculateInsuranceProductMonthlyPaymentForLease$(product: InsuranceProduct, term?: number) {
    return combineLatest([
      this.appService.selectFinancing(),
      this.dealService.selectLeaseOptions(),
      this.moneyFactor$(term)
    ]).pipe(map((
        [financingSettings, leaseOptions, moneyFactor]:
          [FinancingSettings, LeaseOptions, number]
      ) => {
        term = term || leaseOptions.selectedLeaseTerm;
        const prod = Object.assign({}, product);
        const closestTerm = this.dealService.dealInsuranceService.findClosestTerm(
          prod,
          term
        );
        const interestRatePercentage = (moneyFactor * 2400);
        const interestRate = interestRatePercentage / 100;
        let closestTermBasePrice = 0;
        if (closestTerm) {
          closestTermBasePrice = closestTerm.price;
        }
        return closestTermBasePrice;
      }),
      switchMap(closestTermBasePrice => {
        return combineLatest([
          this.calcBaseMonthlyLeasePayment$({term}),
          this.calcBaseMonthlyLeasePayment$({term, modifyGrossCap: closestTermBasePrice})
        ]).pipe(map(([baseMonthlyPayment, monthlyPaymentWithProduct]) => {
          return monthlyPaymentWithProduct - baseMonthlyPayment;
        }));
      }));
  }

  // calculation is without tax
  calculateInsuranceProductMonthlyPaymentForLease(
    product: InsuranceProduct,
    term: number,
    moneyFactor: number
  ) {
    // find the insurance product term that is closest to the lease term and select it.
    const prod = Object.assign({}, product);
    const closestTerm = this.dealService.dealInsuranceService.findClosestTerm(
      prod,
      term
    );
    const interestRatePercentage = (moneyFactor * 2400);
    const interestRate = interestRatePercentage / 100;
    let closestTermBasePrice = 0;
    if (closestTerm) {
      closestTermBasePrice = closestTerm.price;
    }

    function getPayment(firstMonthPayment: number) {
      const reduceCap = firstMonthPayment;
      const capCost = closestTermBasePrice - reduceCap;
      const payment = ((capCost / term) + (capCost * moneyFactor));
      return payment;
    }

    let payment = getPayment(0);
    let i = 0;
    while (i < 4) {
      payment = getPayment(payment);
      i++;
    }

    const closestTermMonthlyTaxedPrice = this.calculationUtilityService.calculateMonthlyPayment(
      closestTermBasePrice,
      term,
      moneyFactor,
      true
    );

    return closestTermMonthlyTaxedPrice;
  }

  public calcMonthlyLeasePayment(
    withoutTax: boolean,
    taxResult: TaxResult,
    financeOptions: FinanceOptions,
    leaseOptions: LeaseOptions,
    tradeInEquity: number,
    grossCapCost: number,
    retail: number,
    customizedVehiclePrice: number,
    residualValue: number,
    term: number,
    moneyFactor: number,
    cashIncentivesTotal: number
  ): number {
    // only use positive trade equity, negative is already added to gross cap cost
    tradeInEquity = tradeInEquity > 0 ? tradeInEquity : 0;

    function getPayment(firstMonthPayment: number) {
      const ccr = Big(financeOptions.downPayment)
        .add(tradeInEquity)
        .add(cashIncentivesTotal)
        .minus(firstMonthPayment)
        .round(2)
        .toNumber();
      const equity = Big(financeOptions.downPayment).add(tradeInEquity);
      let capCost = grossCapCost;
      let reduceCap = equity.minus(firstMonthPayment).round(2).toNumber();
      //reduceCap += cashIncentivesTotal;
      capCost = Big(capCost).minus(reduceCap).toNumber();
      const payment = (
        (Big(capCost).minus(residualValue))
          .div(term)
          .add(((Big(capCost).add(residualValue)).times(moneyFactor)))).round(2).toNumber();
      return payment;
    }

    let payment = getPayment(0);
    let i = 0;
    while (i < 4) {
      payment = getPayment(payment);
      i++;
    }
    // return this.helpers.truncateDecimal(monthlyLeasePayment, 2);
    return this.calculationUtilityService.truncateDecimal(payment, 2, true);
  }

  public vehicleLeaseTaxTotal$(term?: number): Observable<number> {
    return combineLatest([
      this.dealService.selectDeal(),
      this.calcTotalMonthlyLeasePayment$({term, withoutTax: true}),
      this.calcTotalMonthlyLeasePayment$({term}),
      this.appService.selectFinancing()
    ])
      .pipe(
        map(([deal, monthlyLeasePaymentWithoutTax, monthlyLeasePaymentWithTax, financingSettings]) => {
          const monthlyTax = monthlyLeasePaymentWithTax - monthlyLeasePaymentWithoutTax;
          term = term || deal.leaseOptions.selectedLeaseTerm;
          return this.calculationUtilityService.truncateDecimal(monthlyTax * term || 0, 2);
        })
      );
  }

  public calcResidualRate(finalVehiclePrice: number, residualValue: number): number {
    return this.calculationUtilityService.truncateDecimal(residualValue / finalVehiclePrice, 2);
  }

  public calcResidualValue(finalVehiclePrice: number, residualRate: number): number {
    return this.calculationUtilityService.truncateDecimal(finalVehiclePrice * residualRate);
  }

  getLeaseResiduals(): Observable<LeaseResidual[]> {
    return combineLatest([
      this.vehicleService.selectNewResiduals(),
      this.vehicleService.selectCertUsedResiduals(),
      this.vehicleService.selectVehicle(),
      this.dealService.selectFinanceOptionsEdits()
    ]).pipe(take(1), map(([newResiduals, certUsedResiduals, vehicle, financeOptionsEdits]) => {
      const {modelNumber, year} = vehicle;
      const msr = financeOptionsEdits.msr === null ? vehicle.msr : financeOptionsEdits.msr;
      const vehicleCondition = this.vehicleService.vehicleCondition(vehicle);

      if (vehicleCondition === "new") {
        const yearResiduals = (newResiduals || []).find(v =>
          this.vehicleService.yearMatch(v.year, year));
        if (!yearResiduals) { return []; }
        const vehicleModelResiduals = yearResiduals.residualPercentages.find(residual => {
          return this.vehicleService.modelNumberMatch(residual.modelNumber, modelNumber);
        });
        if (!vehicleModelResiduals || !vehicleModelResiduals.termPercentages) { return []; }
        const newLeaseVehicleOver500MilesResidualReduction = this.calculateNewLeaseVehicleOver500MilesResidualReduction(vehicleCondition, vehicle.odometer);
        return vehicleModelResiduals.termPercentages
          .map(residual => {
            const standard = this.calcResidualValue(msr, residual.percentage / 100) - newLeaseVehicleOver500MilesResidualReduction;
            const low = this.calcResidualValue(msr, (residual.percentage / 100) + .02) - newLeaseVehicleOver500MilesResidualReduction;
            const lowest = this.calcResidualValue(msr, ((residual.percentage / 100) + .02) + .01) - newLeaseVehicleOver500MilesResidualReduction;
            return {
              term: residual.term,
              standard,
              low,
              lowest
            };
          });

      } else if (vehicleCondition === "certified") {
        const residualValues = pathOr([], ["residualValues"], certUsedResiduals);
        const vehicleModelResiduals = residualValues.find(v => {
          return (
            this.vehicleService.modelNumberMatch(v.modelNumber, modelNumber) &&
            this.vehicleService.yearMatch(v.year, year)
          );
        });
        if (!vehicleModelResiduals || !vehicleModelResiduals.values) { return []; }
        return vehicleModelResiduals.values
          .map(residual => {
            const low = this.calcLowResidualValue(residual.value, msr);
            const lowest = this.calcLowestResidualValue(low, msr);
            return {
              term: residual.term,
              standard: residual.value,
              low,
              lowest
            };
          });
      }
      return [];
    }));
  }

  /**
   * If a vehicle is leased, is new, and the odometer is greater then 500 then reduce the residual amount by 10 cents for every mile on the odometer.
   * (Issue Reference: #934)
   */
  protected calculateNewLeaseVehicleOver500MilesResidualReduction(vehicleCondition: string, odometer: number): number {
    if (vehicleCondition === "new" && odometer > 500) {
      return odometer * .1;
    }
    return 0;
  }

  calcLowResidualValue(val: number, finalVehiclePrice: number): number {
    const residualRate = this.calcResidualRate(finalVehiclePrice, val);
    const lowRate = residualRate + .02;
    return this.calcResidualValue(finalVehiclePrice, lowRate);
  }

  calcLowestResidualValue(val: number, finalVehiclePrice: number): number {
    const residualRate = this.calcResidualRate(finalVehiclePrice, val);
    const lowRate = residualRate + .01;
    return this.calcResidualValue(finalVehiclePrice, lowRate);
  }

  calcCCR$() {
    return combineLatest([
      this.calcTotalMonthlyLeasePayment$(),
      this.dealService.selectDeal(),
    ]).pipe(
      switchMap(([monthlyPayment, deal]) => {
        return this.dealIncentivesService.cashIncentivesTotal$({
          lease: true
        })
          .pipe(map(cashIncentivesTotal => {
            let dueAtSigning = deal.financeOptions.downPayment;
            if (deal.tradeIn.tradeValue > 0) {
              dueAtSigning += deal.tradeIn.tradeValue;
            }
            if (deal.tradeIn2.tradeValue > 0) {
              dueAtSigning += deal.tradeIn2.tradeValue;
            }
            return dueAtSigning - monthlyPayment;
          }));
      })
    );
  }

  adjustedCapCost$() {
    return combineLatest([
      this.calcCCR$(),
      this.calcGrossCapCost$()
    ]).pipe(map(([ccr, gross]) => {
      return gross - ccr;
    }));
  }

  depreciationAmount$() {
    return combineLatest([
      this.adjustedCapCost$(),
      this.residualValue$()
    ]).pipe(map(([adjustedCapCost, residualValue]) => {
      return adjustedCapCost - residualValue;
    }));
  }

  rentCharge$() {
    return combineLatest([
      this.adjustedCapCost$(),
      this.residualValue$(),
      this.moneyFactor$()
    ]).pipe(map(([adjustedCapCost, residualValue, moneyFactor]) => {
      return (adjustedCapCost + residualValue) * moneyFactor;
    }));
  }

  private getNeededValuesForLease(term?: number) {
    return combineLatest([
      this.dealService.selectDeal(),
      this.appService.selectFinancing(),
      this.vehicleService.selectVehicle(),
      this.residualValue$(term),
      this.dealService.selectIncentives(),
      this.calculationService.customizedVehiclePrice$({
        excludeIncentives: true, finance: false, lease: true
      }), // without incentives
      this.calculationService.calcTax$({leaseSelected: true, term}),
      this.dealIncentivesService.cashIncentivesTotal$({lease: true, term}),
      this.dealService.selectLeaseOptions(),
      this.calculationService.calcFees$(),
      this.calculationService.hasClearCareEliteAccessory$()
    ]);
  }

  calcTotalMonthlyLeasePayment$ = (
    {term, withoutTax, residualValue, customMoneyFactor}:
      {
        term?: number, withoutTax?: boolean, residualValue?: number, customMoneyFactor?: number
      } = {}
  ): Observable<number> => {
    return combineLatest([
      this.calculationService.calcTotalLeaseInsuranceProductsPrice$(term),
      this.dealService.selectLeaseOptions()
    ])
      .pipe(switchMap(([insuranceProductsTotal, leaseOptions]) => {
        return this.calcBaseMonthlyLeasePayment$({
          term: term || leaseOptions.selectedLeaseTerm,
          withoutTax,
          passedResidualValue: residualValue,
          modifyGrossCap: insuranceProductsTotal,
        });
      }));
  };

  calcBaseMonthlyLeasePayment$(
    {
      term,
      withoutTax,
      modifyGrossCap = 0,
      passedResidualValue
    }: {
      term?: number,
      withoutTax?: boolean,
      modifyGrossCap?: number,
      passedResidualValue?: number,
    } = {}
  ): Observable<number> {
    return this.getNeededValuesForLease(term).pipe(
      map(([
             deal,
             financingSettings,
             vehicle,
             residualValue,
             incentives,
             customizedVehiclePrice,
             taxResult,
             cashIncentivesTotal,
             leaseOptions,
             feesResult,
             hasClearCareEliteAccessory
           ]) => {

        // if (!deal.leaseOptions.leaseSelected) {
        //   incentives = (incentives || []).map(incentive => {
        //     if (incentive.customerCash) {
        //       cashIncentivesTotal -= incentive.customerCash;
        //     }
        //     return incentive;
        //   });
        // }

        const retail = deal.financeOptionsEdits.retail === null ?
          vehicle.retail :
          deal.financeOptionsEdits.retail;
        const fees = (feesResult?.totalFees || 0) +
          (leaseOptions.acquisitionFee || financingSettings.leaseDefault.acquisitionFee);
        residualValue = passedResidualValue || residualValue; // priority passed residual value

        term = term || deal.leaseOptions.selectedLeaseTerm;
        const selectedTermIndex = pathOr([], ["leaseOptions", "leaseTerms"], deal).findIndex((leaseTerm: LeaseTerm) => {
          return leaseTerm.term === term;
        });
        const customMoneyFactor = pathOr(
          false, ["leaseOptions", "leaseTerms", selectedTermIndex, "customMoneyFactor"], deal
        );
        let customMoneyFactorValue = 0;
        if (customMoneyFactor) {
          customMoneyFactorValue = pathOr(
            false, ["leaseOptions", "leaseTerms", selectedTermIndex, "moneyFactor"], deal
          );
        }
        const moneyFactor = this.calcMoneyFactor({
          vehicleCondition: this.vehicleService.vehicleCondition(vehicle),
          financingSettings,
          incentives: deal.incentives,
          selectedCreditTier: deal.financeOptions.selectedCreditTier,
          selectedLeaseTerm: term,
          customMoneyFactor: customMoneyFactorValue
        });
        const excessMileageCharge = this.calcExcessMileageCharge(
          deal,
          PREPAID_MILEAGE_RATE,
          term
        );

        if (!residualValue || residualValue === 0) {
          return 0;
        }

        const grossCapCost = this.calcGrossCapCost(
          customizedVehiclePrice,
          fees,
          taxResult.totalTax,
          excessMileageCharge,
          (deal.tradeIn?.tradeValue + deal.tradeIn2?.tradeValue < 0) ? deal.tradeIn?.tradeValue + deal.tradeIn2?.tradeValue : 0
        ) + modifyGrossCap;

        return this.calcMonthlyLeasePayment(
          withoutTax,
          taxResult,
          deal.financeOptions,
          deal.leaseOptions,
          (deal.tradeIn?.tradeValue || 0) + (deal.tradeIn2?.tradeValue || 0),
          grossCapCost,
          retail,
          customizedVehiclePrice,
          residualValue,
          term || deal.leaseOptions.selectedLeaseTerm,
          moneyFactor,
          cashIncentivesTotal
        );
      })
    );
  }

  calcGrossCapCost$(): Observable<number> {
    return this.getNeededValuesForLease().pipe(
      map(([deal, financingSettings, vehicle, residualValue, incentives, customizedVehiclePrice, tax, cashIncentivesTotal, leaseOptions, feesResult]) => {
        const excessMileageCharge = this.calcExcessMileageCharge(deal, PREPAID_MILEAGE_RATE);
        const fees = feesResult?.totalFees + (deal.leaseOptions.acquisitionFee || financingSettings.leaseDefault.acquisitionFee);
        return this.calcGrossCapCost(
          customizedVehiclePrice,
          fees,
          tax.totalTax,
          excessMileageCharge,
          (deal.tradeIn.tradeValue < 0) ? deal.tradeIn.tradeValue : 0
        );
      })
    );
  }

  excessMileageCharge$(term?: number): Observable<number> {
    return this.getNeededValuesForLease().pipe(
      map(([deal, financingSettings]) => {
        return this.calcExcessMileageCharge(deal, PREPAID_MILEAGE_RATE, term);
      })
    );
  }

  calcExcessMileageCharge(deal: DealState, excessMileageRate: number, term?: number) {
    let excessMileageCharge = 0;
    term = term || deal.leaseOptions.selectedLeaseTerm;
    if (deal.vehicleNeeds.milesDrivenPerYear >= 15000) {
      const excessMiles = deal.vehicleNeeds.milesDrivenPerYear - 15000;
      excessMileageCharge = excessMileageRate *
        excessMiles *
        (term / 12);
    }
    return excessMileageCharge;
  }

  findLeaseOffer(leaseOfferTerms: LeaseOfferTerm[], term: number): LeaseOfferTerm {
    return clone(leaseOfferTerms).filter((leaseOfferTerm: LeaseOfferTerm) => {
      return leaseOfferTerm.term = term;
    })[ 0 ];
  }

  calcPrepaidMilesTotal$() {
    return combineLatest([
      this.appService.selectFinancing(),
      this.dealService.selectVehicleNeeds(),
      this.dealService.selectLeaseOptions()
    ]).pipe(map((
      [financingSettings, vehicleNeeds, leaseOptions]:
        [FinancingSettings, VehicleNeeds, LeaseOptions]
    ) => {
      const diff = vehicleNeeds.milesDrivenPerYear - PREPAID_MILES_PER_YEAR;
      if (diff > 0) {
        const res = PREPAID_MILEAGE_RATE * diff * (leaseOptions.selectedLeaseTerm || 24 / 12);
        return +(res).toPrecision(2);
      } else {
        return 0;
      }
    }));
  }

  calcExcessMiles$() {
    return combineLatest([
      this.appService.selectFinancing(),
      this.dealService.selectVehicleNeeds(),
      this.dealService.selectLeaseOptions()
    ]).pipe(map((
      [financingSettings, vehicleNeeds, leaseOptions]:
        [FinancingSettings, VehicleNeeds, LeaseOptions]
    ) => {
      const diff = vehicleNeeds.milesDrivenPerYear - PREPAID_MILES_PER_YEAR;
      if (diff > 0) {
        return Math.round(diff);
      } else {
        return 0;
      }
    }));
  }

  calcMoneyFactor(
    {
      vehicleCondition,
      financingSettings,
      incentives,
      selectedCreditTier,
      selectedLeaseTerm,
      customMoneyFactor
    }: {
      vehicleCondition: string,
      financingSettings: FinancingSettings,
      incentives: DealIncentive[],
      selectedCreditTier: number,
      selectedLeaseTerm: number,
      customMoneyFactor?: number
    }): number {
    // if this deal has a custom money factor, return it
    if (customMoneyFactor) {
      return customMoneyFactor;
    }

    // the default money factor for the given credit tier
    let defaultMoneyFactorTier: number = DEFAULT_LEASE_MONEY_FACTORS[ selectedCreditTier ];
    defaultMoneyFactorTier = this.calculationUtilityService.truncateDecimal(defaultMoneyFactorTier, 5);

    //console.log("defaultMoneyFactorTier:",defaultMoneyFactorTier,selectedCreditTier)

    //console.log("1.) defaultMoneyFactorTier:", defaultMoneyFactorTier);

    //console.log("incentives:",incentives)

    // check to see if there is an incentive associated with this vehicle modal
    const incentive = (incentives || []).find(i => (
      i.leaseOffer && i.leaseOffer.leaseOfferTerms && i.leaseOffer.leaseOfferTerms.length > 0
    ));

    //console.log("incentive:",incentive)

    // console.log("2.) incentive:", incentive);

    // ISSUE #2057: Do not use incentive MF if lease cash has been applied
    const isLeaseCashApplied = incentive && incentive.leaseCash > 0 && !incentive.leaseCashDisabled;

    if (incentive && incentive.leaseOffer && !isLeaseCashApplied) {

      // check to see if there is a lease offer money factor for the current selected lease term
      const selectedLeaseOffer = this.findLeaseOffer(incentive.leaseOffer.leaseOfferTerms, selectedLeaseTerm);

      //console.log("3.) selectedLeaseOffer:", selectedLeaseOffer,selectedLeaseTerm);

      if (selectedLeaseOffer) {

        // console.log("3.) selectedLeaseOffer:", selectedLeaseOffer);

        // find the custom money factor for the given credit tier
        const selectedMFOffer = pathOr(null, ["tieredLeaseMFs", selectedCreditTier], selectedLeaseOffer);

        // console.log("4.) selectedMFOffer:", selectedMFOffer);

        //console.log("selectedMFOffer:",selectedMFOffer)

        // if a selected money factor offer was found and it's greater than 0, then return it.
        if (selectedMFOffer && Big(selectedMFOffer).round(6).toNumber() > 0) {
          // console.log("5.) selectedMFOffer value:", Big(selectedMFOffer).round(6).toNumber() > 0);
          return Big(selectedMFOffer).round(6).toNumber();
        }
      }
    }

    // otherwise, return the default money factor term.
    return +(defaultMoneyFactorTier.toPrecision(6));

    /* Issue 1968
    const incentive = (incentives || []).find(i => (
      i.leaseOffer && i.leaseOffer.leaseOfferTerms && i.leaseOffer.leaseOfferTerms.length > 0
    ));

    console.log("2.)  incentive? ", incentive);

    if (!incentive) { return +(defaultMoneyFactorTier.toPrecision(6)); }
    const selectedLeaseOffer = this.findLeaseOffer(incentive.leaseOffer.leaseOfferTerms, selectedLeaseTerm);

    console.log("3.)  selectedLeaseOffer? ", selectedLeaseOffer);
    console.log("3.)  selectedLeaseTerm? ", selectedLeaseTerm);

    console.log("3.)  selectedCreditTier? ", selectedCreditTier);

    const selectedMFOffer = pathOr(null, ["tieredLeaseMFs", selectedCreditTier], selectedLeaseOffer);

    console.log("3.)  selectedMFOffer? ", selectedMFOffer);

    if (!selectedMFOffer) {
      return +(financingSettings.leaseDefault.moneyFactor).toPrecision(6);
    }
    const moneyFactorIncentiveMarkup = vehicleCondition === "new" ?
      financingSettings.leaseDefault.newMarkup :
      financingSettings.leaseDefault.certUsedMarkup;
    const result = Big(selectedMFOffer).add(moneyFactorIncentiveMarkup).round(6).toNumber();
    // const mf = +(selectedMFOffer + moneyFactorIncentiveMarkup).toPrecision(6);
    console.log("Final Money Factor Calc: ", result);
    return result;
    // return selectedMFOffer;
   */
  }
}


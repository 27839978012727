import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidateDate } from "src/app/shared-module/validators/date.validator";
import { DefaultIncentive, DefaultIncentiveGroup, Incentive, IncentiveGroup } from "../../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-incentive-group",
  templateUrl: "./incentive-group.component.html",
  styleUrls: ["./incentive-group.component.scss"]
})
export class IncentiveGroupComponent {
  @Input() set incentiveGroup(group: IncentiveGroup) { this.syncIncentiveGroup(group); }

  @Input() waitOnAction: boolean;
  @Input() isNewGroup = false;
  @Output() updateGroup = new EventEmitter<IncentiveGroup>();
  @Output() createGroup = new EventEmitter<IncentiveGroup>();
  @Output() removeGroup = new EventEmitter();
  @Output() flagUnsavedChanges = new EventEmitter();

  public REQS = FORM_INPUT_REQS;
  private uiState = {unsavedChanges: false, hideFooterMenu: true};
  expandChildAction = {showContents: false, changeDetection: 0,};
  incentives: { value: Incentive }[] = [];

  incentiveGroupForm: FormGroup = this.formBuilder.group({
    name: ["", Validators.required],
    description: [""],
    exclusions: new FormArray([]),
    startDate: ["", [Validators.required, ValidateDate]],
    endDate: ["", [Validators.required, ValidateDate]],
    groupName: [""],
    groupDescription: [""],
    validationRequired: [false]
  });

  constructor(private formBuilder: FormBuilder) { }

  // INITIALIZATION

  syncIncentiveGroup(incentiveGroup: IncentiveGroup) {
    this.unsavedChanges = false;
    if (this.isNewGroup) { incentiveGroup = new DefaultIncentiveGroup(); }
    const {name, description, exclusions, startDate, endDate, incentives} = incentiveGroup;
    const {groupName, groupDescription, validationRequired} = incentiveGroup.groupQualifier;
    this.setExclusions(exclusions);
    this.setIncentives(incentives);
    this.incentiveGroupForm.patchValue({
      name,
      description,
      startDate: startDate.slice(0, 10),
      endDate: endDate.slice(0, 10),
      groupName,
      groupDescription,
      validationRequired
    });
  }

  private setExclusions(exclusions: string[]) {
    const formArray = this.incentiveGroupForm.get("exclusions") as FormArray;
    formArray.clear();
    exclusions.forEach(exclusion => { this.addControl(formArray, exclusion); });
  }

  private setIncentives(incentives: Incentive[]) {
    this.incentives = incentives.map(value => ({value}));
  }

  // ACTIONS

  onAddExclusion() {
    const formArray = this.incentiveGroupForm.get("exclusions") as FormArray;
    this.addControl(formArray, "");
    this.flagChange();
  }

  onRemoveExclusion(i: number) {
    const formArray = this.incentiveGroupForm.get("exclusions") as FormArray;
    formArray.removeAt(i);
    this.onSubmitIncentiveGroup();
  }

  onAddIncentive() {
    this.incentives.push({value: new DefaultIncentive()});
  }

  onRemoveIncentive(i: number) {
    if (window.confirm("Are you sure want to remove this incentive?")) {
      this.incentives = [...this.incentives.slice(0, i), ...this.incentives.slice(i + 1)];
      this.onSubmitIncentiveGroup();
    }
  }

  onUpdateIncentive(updatedIncentive: Incentive, i: number) {
    this.incentives[ i ].value = updatedIncentive;
    this.flagChange();
  }

  onSubmitIncentiveGroup() {
    const {
      name,
      description,
      startDate,
      endDate,
      groupName,
      groupDescription,
      validationRequired
    } = this.incentiveGroupForm.value;

    const incentiveGroup = {
      name,
      description,
      startDate: startDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T12:00:00Z",
      endDate: endDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T12:00:00Z",
      exclusions: this.incentiveGroupForm.get("exclusions").value.map(el => el.item),
      groupQualifier: {
        groupName,
        groupDescription,
        validationRequired
      },
      incentives: this.incentives.map(incentive => incentive.value)
    };
    //console.log(incentiveGroup)

    if (this.isNewGroup) {
      this.createGroup.emit(incentiveGroup);
    } else {
      this.updateGroup.emit(incentiveGroup);
    }
    this.unsavedChanges = false;
  }

  onRemoveIncentiveGroup() {
    if (window.confirm("Are you sure want to remove this incentive group?")) {
      this.removeGroup.emit();
    }
  }

  flagChange() {
    this.unsavedChanges = true;
    this.flagUnsavedChanges.emit();
    this.incentiveGroupForm.controls.groupDescription.clearValidators();
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.incentiveGroupForm.controls;
    for (const name in controls) {
      if (controls[ name ].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onSetChildExpansion(bool: boolean) {
    this.expandChildAction = {
      showContents: bool,
      // Change Detection ensures data change is detected in Child Input on every callback.
      changeDetection: (this.expandChildAction.changeDetection === 0) ? 1 : 0,
    };
  }

  logVehiclesWithIncentives(): void {
    if (Array.isArray(this.incentives)) {
      let totalIncentives = 0;
      let incentivesWithRates = 0;
      this.incentives.forEach(incentive => {
        totalIncentives++;
        if (incentive.value && incentive.value.financeOffer && Array.isArray(incentive.value.financeOffer.financeRates) && incentive.value.financeOffer.financeRates.length > 0) {
          incentivesWithRates++;
          //console.log(incentive.value.title, incentive.value.financeOffer.financeRates, incentive?.value?.vehicleQualifierList);
        }
      });
      //console.log("Total Incentives = " + totalIncentives);
      //console.log("Total Incentives w/ Rates = " + incentivesWithRates);
    }
  }

  // UI & RENDERING

  get unsavedChanges(): boolean {
    return this.uiState.unsavedChanges;
  }

  set unsavedChanges(val: boolean) {
    this.uiState.unsavedChanges = val;
  }

  // FORM VALIDATION & HELPERS

  addControl(formArray: FormArray, item: any) {
    const formGroup = this.formBuilder.group({item: [item, Validators.required]});
    formArray.push(formGroup);
  }

  removeControl(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.incentiveGroupForm.get(controlName);
    return control.touched && control.invalid;
  }

  logInvalidControls(): void {
    const controls = this.incentiveGroupForm.controls;
    //console.log("Invalid Controls:");
    for (const name in controls) {
      if (controls[ name ].invalid) {
        // console log the full control that is invalid
        //console.log(controls[ name ]);
      }
    }
  }
}

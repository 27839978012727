import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgotPasswordComponent, LoginComponent, ResetPasswordComponent } from "./pages";

const appRoutes: Routes = [
  {path: "", component: LoginComponent},
  {path: "login", component: LoginComponent},
  {path: "reset-password", component: ResetPasswordComponent},
  {path: "forgot-password", component: ForgotPasswordComponent}
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}

import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { CalculationService, CalculationUtilityService, DealService } from "../../services";
import { TradeIn } from "../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-vehicle-trade-in",
  templateUrl: "./vehicle-trade-in.component.html",
  styleUrls: ["./vehicle-trade-in.component.scss"]
})
export class VehicleTradeInComponent implements OnDestroy {
  @Input() public title = "Trade-in Vehicle";
  @Output() public TradeInAutoSubmit: EventEmitter<Partial<TradeIn>> = new EventEmitter();
  private unsubscribe$ = new Subject();
  private uiState = {
    showDetails: false,
    tradeEquity: 0,
    tradeEstimate: 0,
  };
  public REQS = FORM_INPUT_REQS;
  public tradeInForm: FormGroup = this.formBuilder.group({
    year: [null, [Validators.min(1000), Validators.max(9999)]],
    make: [""],
    model: [""],
    tradeEstimate: [0, Validators.min(0)],
    payOffEstimate: [0, Validators.min(0)],
  });

  constructor(
    private calculationService: CalculationService,
    private calculationUtilityService: CalculationUtilityService,
    private historyService: HistoryService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @Input()
  public set tradeIn(tradeIn: TradeIn) {
    if (tradeIn) {
      this.tradeInForm.patchValue({
        tradeEstimate: tradeIn.tradeEstimate,
        payOffEstimate: tradeIn.payOffEstimate,
        year: tradeIn.year || null,
        make: tradeIn.make,
        model: tradeIn.model,
        trim: tradeIn.trim,
        condition: tradeIn.condition
      });
    } else {
      this.tradeInForm.reset();
    }
    this.uiState.tradeEquity = this.calcTradeEquity();
  }

  // UI CONTROL & RENDERING

  onToggleDetails() {
    this.uiState.showDetails = !this.uiState.showDetails;
  }

  get showDetails(): boolean {
    return this.uiState.showDetails;
  }

  get tradeEquity(): number {
    return this.uiState.tradeEquity;
  }

  // FORM VALIDATION & HELPERS

  public autoSubmitTradeInForm() {
    const {pristine, invalid} = this.tradeInForm;
    if (pristine || invalid) { return; }

    const eventObj = {
      shortName: this.title + ' Valuation Changed',
      oldValue: this.calculationUtilityService.formatCurrencyUS(
        this.uiState.tradeEstimate === this.tradeInForm.value.tradeEstimate
          ? 0
          : this.uiState.tradeEstimate),
      newValue: this.calculationUtilityService.formatCurrencyUS(this.tradeInForm.value.tradeEstimate),
    };

    this.historyService.dispatchAddEvent(eventObj);

    this.TradeInAutoSubmit.emit({
      tradeValue: this.tradeInForm.value.tradeEstimate - this.tradeInForm.value.payOffEstimate,
      tradeEstimate: this.tradeInForm.value.tradeEstimate,
      payOffEstimate: this.tradeInForm.value.payOffEstimate,
      payOff: this.tradeInForm.value.payOffEstimate,
      year: this.tradeInForm.value.year,
      make: this.tradeInForm.value.make,
      model: this.tradeInForm.value.model,
    } as Partial<TradeIn>);
    this.uiState.tradeEquity = this.calcTradeEquity();
    this.uiState.tradeEstimate = this.tradeInForm.value.tradeEstimate;
    this.tradeInForm.markAsPristine();
  }

  calcTradeEquity(): number {
    const {tradeEstimate, payOffEstimate} = this.tradeInForm.value;
    return this.calculationService.calcVehicleTradeEquity(tradeEstimate, payOffEstimate);
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.tradeInForm.get(controlName);
    return control.touched && control.invalid;
  }

  trimInput(controlName: string) {
    const value = this.tradeInForm.value[ controlName ].trim();
    this.tradeInForm.patchValue({[ controlName ]: value});
  }

}

<p>
  <button type="button" class="btn btn-outline-primary" (click)="collapsed = !collapsed">
    {{title || menuItem.description}}
  </button>
</p>
<div *ngIf="!collapsed" class="card">
  <div class="card-body">
    <app-menu-link-form [menuItem]="menuItem" (formChanges)="onFormChanges($event)">
    </app-menu-link-form>
  </div>
</div>
import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService, CalculationService, DealService } from "../../services";
import { Accessory, FinanceOptions, FinancingSettings, LeaseOptions, TaxResult, TradeIn } from "../../models";
import { DealIncentivesService } from "../../services/deal/deal-incentives.service";
import { PLATE_TRANSFER_FEE } from "src/app/app.config";

@Component({
  selector: "app-vehicle-base-finance",
  templateUrl: "./vehicle-base-finance.component.html",
  styleUrls: ["./vehicle-base-finance.component.scss"]
})
export class VehicleBaseFinanceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vehicle = {retail: null};
  private unsubscribe$ = new Subject();
  private uiState = {
    showDetails: false,
    showTaxDetails: false,
    showDMVDetails: false,
    financeInitialized: false
  };
  private appData = {financeDefault: null};
  private dealData = {
    accessories: null,
    financeOptions: null,
    leaseOptions: null,
    tradeIn: null,
    tradeIn2: null,
    selectedCashIncentivesTotal: 0,
    acquisitionFee: 0,
    tax: null
  };
  PLATE_TRANSFER_FEE;
  eFilingFee: number;
  vehicleBaseFinanceAmount;
  calcFees$ = this.calculationService.calcFees$();
  taxResult;
  // docFeesForm: FormGroup = this.formBuilder.group({
  //   docFees: [0, [Validators.min(0)]],
  // });

  constructor(
    private appService: AppService,
    private calculationService: CalculationService,
    private incentivesService: DealIncentivesService,
    private dealService: DealService,
  ) { }

  ngOnInit() {
    this.subToStoreData();
    this.dealService.dispatchCalcTax();
    this.PLATE_TRANSFER_FEE = PLATE_TRANSFER_FEE;
  }

  ngOnChanges() {
    this.dealService.dispatchCalcTax();
    this.calculationService.vehicleBaseFinanceAmount$({finance: true})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicleBaseFinanceAmount: number) => {
        this.vehicleBaseFinanceAmount = vehicleBaseFinanceAmount;
      });
    this.calculationService.calcTax$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((taxResult: TaxResult) => {
        this.taxResult = taxResult;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private subToStoreData() {
    this.dealService.selectDeal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((deal) => {
        this.dealData.acquisitionFee = deal.leaseOptions.acquisitionFee;
        this.dealData.financeOptions = deal.financeOptions;
        this.dealData.leaseOptions = deal.leaseOptions;
        this.eFilingFee = deal.financeOptions.eFilingFee;
        const {adjustedPrice, incentivesApplied} = this.incentivesService.applyCashIncentives({
          price: 0,
          incentives: deal.incentives,
          leaseOptions: deal.leaseOptions,
          leaseSelected: deal.leaseOptions.leaseSelected,
          financeOptions: deal.financeOptions
        });
        this.dealData.selectedCashIncentivesTotal = Math.abs(adjustedPrice);
      });

    this.calculationService.vehicleBaseFinanceAmount$({finance: true})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicleBaseFinanceAmount: number) => {
        this.vehicleBaseFinanceAmount = vehicleBaseFinanceAmount;
      });

    this.dealService.selectAccessories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((accessories: Partial<Accessory>[]) => {
        this.dealData.accessories = accessories;
      });

    this.dealService.selectTradeIn()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tradeIn: TradeIn) => {
        this.dealData.tradeIn = tradeIn;
      });

    this.dealService.selectTradeIn2()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tradeIn2: TradeIn) => {
        this.dealData.tradeIn2 = tradeIn2;
      });

    this.appService.selectFinancing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((financeSettings: FinancingSettings) => {
        this.appData.financeDefault = financeSettings.financeDefault;
        // this.updateDocFeesForm();
      });
  }

  // UI CONTROL & RENDERING

  calcCustomizedVehiclePrice(): number {
    return this.calculationService.calcCustomizedVehiclePrice(
      this.vehicle.retail,
      this.dealData.selectedCashIncentivesTotal,
      this.selectedAccessories
    );
  }

  calcTradeEquityEstimate(): number {
    return this.calculationService.calcVehicleTradeEquity(
      (this.tradeIn?.tradeEstimate || 0) + (this.tradeIn2?.tradeEstimate || 0),
      (this.tradeIn?.payOffEstimate || 0) + (this.tradeIn2?.payOffEstimate || 0)
    );
  }

  onToggleDetails() {
    this.uiState.showDetails = !this.uiState.showDetails;
  }

  onTogglePlateTransfer() {
    const plateTransfer = !this.financeOptions.plateTransfer;
    this.dealService.dispatchSetFinanceOptions({plateTransfer});
    this.dealService.dispatchCalcFees();
  }

  onToggleTaxDetails() {
    this.uiState.showTaxDetails = !this.uiState.showTaxDetails;
  }

  onToggleDMVDetails() {
    this.uiState.showDMVDetails = !this.uiState.showDMVDetails;
  }

  get showDetails(): boolean {
    return this.uiState.showDetails;
  }

  get showTaxDetails(): boolean {
    return this.uiState.showTaxDetails;
  }

  get showDMVDetails(): boolean {
    return this.uiState.showDMVDetails;
  }

  get selectedAccessories(): Partial<Accessory>[] {
    return this.dealData.accessories || [];
  }

  get financeDefault() {
    return this.appData.financeDefault || {};
  }

  get financeOptions(): FinanceOptions {
    return this.dealData.financeOptions || {};
  }

  get leaseOptions(): LeaseOptions {
    return this.dealData.leaseOptions || {};
  }

  get tradeIn(): TradeIn {
    return this.dealData.tradeIn || {};
  }

  get tradeIn2(): TradeIn {
    return this.dealData.tradeIn2 || {};
  }

  get acquisitionFee(): number {
    return this.dealData.acquisitionFee || 0;
  }

  // FORM VALIDATION & HELPERS

  // autoSubmitDocFeesForm() {
  //   const { pristine, invalid } = this.docFeesForm;
  //   if (pristine || invalid) { return; }

  //   const dmvFees = this.financeDefault.dmvFees || 0;
  //   const { docFees } = this.docFeesForm.value;
  //   const newDocFees = docFees - dmvFees >= 0 ? docFees - dmvFees : 0;
  //   this.dealService.submitDocFees(newDocFees);
  //   this.docFeesForm.markAsPristine();
  // }

  // updateDocFeesForm() {
  //   this.docFeesForm.patchValue({ docFees: this.dealData.financeOptions.totalFees });
  // }

  // touchedInvalid(controlName: string): boolean {
  //   const control = this.docFeesForm.get(controlName);
  //   return control.touched && control.invalid;
  // }

}

import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AppService } from "../../services";
import { take } from "rxjs/operators";

@Component({
  selector: "app-vehicle-image",
  templateUrl: "./vehicle-image.component.html",
  styleUrls: ["./vehicle-image.component.scss"]
})
export class VehicleImageComponent implements OnInit {
  @Input() set vehicle(
    vehicle: { stockPhoto: string, stockNumber: string }
  ) {
    if (vehicle) {
      const {stockPhoto, stockNumber} = vehicle;
      const {placeholderHeight} = this.uiState;
      this.uiState = {
        stockPhoto,
        stockNumber,
        isValidUrl: true,
        placeholderHeight,
        defaultIsValid: true
      };
    }
  }

  @ViewChild("imageArea", {static: true}) imageArea: ElementRef;

  dealerDefaultVehiclePhotoUrl: string;
  private uiState = {
    stockPhoto: "",
    stockNumber: "",
    isValidUrl: true,
    defaultIsValid: true,
    placeholderHeight: null
  };

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.autoSetPlaceholderHeight();
    this.appService.selectDefaultDealerVehiclePhotoUrl().pipe(take(1)).subscribe(url => {
      this.dealerDefaultVehiclePhotoUrl = url;
    });
  }

  // UI RENDERING

  autoSetPlaceholderHeight() {
    const width = this.imageArea.nativeElement.offsetWidth;
    const widthHeightRatio = 1.75;
    this.uiState.placeholderHeight = Math.floor(width / widthHeightRatio);
  }

  /* On image error, try the dealer default,
  if the default fails, use the placeholder in the template
  */
  invalidateImage() {
    this.uiState.isValidUrl = false;
    if (this.uiState.defaultIsValid) {
      this.uiState.stockPhoto = this.dealerDefaultVehiclePhotoUrl;
      this.uiState.defaultIsValid = false;
    }
  }

  get placeholderHeight(): number {
    return this.uiState.placeholderHeight;
  }

  get stockPhoto(): string {
    return this.uiState.stockPhoto;
  }

  get stockNumber(): string {
    return this.uiState.stockNumber;
  }

  get isValidUrl(): boolean {
    return this.uiState.isValidUrl;
  }

  get defaultIsValid(): boolean {
    return this.uiState.defaultIsValid;
  }

}

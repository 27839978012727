<form [formGroup]="customRateForm"
      class="input-group">
  <input class="form-control text-right"
         [ngClass]="{ 'border-danger': touchedInvalid('customRatePercentage') }"
         formControlName="customRatePercentage"
         type="number"
         min="0"
         (blur)="autoSubmitCustomRateForm()">

  <div class="input-group-append">
    <span class="input-group-text">%</span>
  </div>

  <a (click)="clearRate()"
     class="btn btn-link app-text-blue">clear</a>
</form>
<section *ngIf="vehicle"
         class="d-flex justify-content-between">
  <p class="mr-3"
     *ngIf="warrantyUi">
    This <strong>{{ warrantyUi.vehicleCondition }}</strong> {{vehicle.model | uppercase }} has
    {{ vehicle.odometer | number }}
    <span *ngIf="warrantyUi.vehicleCondition === 'new'">miles.</span>
    <span *ngIf="warrantyUi.vehicleCondition !== 'new'">miles and is {{ vehicle.year | howOld }} year(s) old.</span>
  </p>
  <a *ngIf="vehicleUsedOrCertified"
     [href]="carfaxUrl"
     target="_blank">
    <img src="/static/images/carfax.png"
         style="height: 24px"/>
  </a>
</section>

<section *ngIf="isValidWarrantyUi()">
  <app-warranty-form
    [warrantyUi]="warrantyUi"></app-warranty-form>

  <div class="mb-3">
    <app-warranty-graph
      [warrantyUi]="warrantyUi"
      [vehicle]="vehicle"></app-warranty-graph>
  </div>

  <app-warranty-footer
    [warrantyUi]="warrantyUi"
    [vehicle]="vehicle"
    [maxOwnershipYears]="warrantyUi.maxOwnershipYears"></app-warranty-footer>
</section>

<section *ngIf="!isValidWarrantyUi()"
         class="app-bg-light app-text-neutral rounded text-center px-3 py-2">
  Vehicle has no insurance products to present.
</section>

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, filter, switchMap, throttle, withLatestFrom } from "rxjs/operators";
import * as dealActions from "../actions/deal.actions";
import { DealService } from "../../services";
import { NavigationEnd, Router } from "@angular/router";
import { DealState } from "../state";
import { COMPLETED_STATUSES, DEAL_STATUSES } from "src/app/app.config";
import { interval, of } from "rxjs";
import { v1 as uuidv1 } from "uuid";

/*
These effects are responsible for calling change deal to save the current deal state to the server
*/

@Injectable()
export class DealChangeEffects {

  // CHANGE DEAL TRACKiNG

  changeDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        dealActions.setFinancingTerm,
        dealActions.setLeasingTerm,
        dealActions.setInsuranceProducts,
        dealActions.setFinanceOptions,
        dealActions.setIncentives,
        dealActions.setAccessories,
        dealActions.setTradeIn,
        dealActions.setVehicleNeeds,
        dealActions.incentivesSet,
        dealActions.changeDeal,
        dealActions.updateSelectedDealerAccessories,
      ),
      throttle(ev => interval(1000)),
      withLatestFrom(
        this.dealService.selectDeal(),
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        )
      ),
      switchMap((
        [action, currentDeal, event]:
          [any, DealState, NavigationEnd]
      ) => {
        //console.log("Changing Deal");
        let deal = currentDeal;
        if (action.type.includes("CHANGE DEAL")) {
          deal = action.deal;
        }
        if (
          event.url.includes("newSMWriteup") &&
          !deal.dealId
        ) {
          deal.dealId = uuidv1();
          this.dealService.dispatchSalesManagerCreateDeal(deal);
          return of();
        }
        if (event.url.includes("/sales-manager/writeup/")) {
          return of();
        }
        if (deal.initialized) {
          if (!deal.dealId) {
            return of();
          }
          // don't auto save if the deal is in one of the completed statuses (already submitted)
          if (COMPLETED_STATUSES.includes(deal.status as DEAL_STATUSES)) {
            return of();
          }
          return this.dealService.change(deal)
            .pipe(
              // map(resDeal => {
              //   return dealActions.dealApiSuccess(resDeal);
              // }),
              catchError(error => {
                alert("There was an error auto-saving the deal.");
                return of(dealActions.dealApiFailure({error}));
              })
            );
        } else {
          return of();
        }
      })
    ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private dealService: DealService,
    private router: Router
  ) { }
}

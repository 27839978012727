<div class="lease px-1 app-text-bold app-text-white my-1 py-1 d-flex justify-content-between mb-1 primary-section-header-block" (click)="onSelectLeaseDeal()">
  <span>Lease</span>
  <span class="app-text-secondary app-text-white">{{totalLeaseMonthlyPayment$() | async | currency}}</span>
</div>

<article *ngFor="let option of leaseTerms; let i = index">

  <div class="w-100">
    <div class="d-flex align-items-center mb-2 mt-2 ml-1">
      <input
        [ngClass]="checkBoxColor"
        (click)="onSelectLeaseTerm(option.term)"
        [checked]="this.activeLeaseTerm(option.term)"
        [disabled]="!leaseSelected"
        type="checkbox"
        class="check-box-btn mr-2"/>
      <span class="cursor-default color-grey-4">{{option.term}} Months</span>
    </div>
  </div>

  <div class="app-inline-form-layout form-header-row cursor-default">
    <span></span>
    <span class="app-text-small app-text-bold">MPY</span>
    <span class="app-text-small app-text-bold">Residual</span>
    <span class="app-text-small app-text-bold">Payment</span>
  </div>

  <ng-container *ngIf="option.term!==24">

    <div
      (click)="updateEstMPY(option.term, 10000)"
      class="app-inline-form-layout {{isCurrentlySelectedResidual(option.term, 10000) ? (leaseSelected ? 'row-selected-blue' : 'row-selected-grey') : 'lease-rate-row'}}">
      <span></span>
      <span>10,000</span>
      <span>{{ option.lowest | currency }}</span>
      <span>{{totalLeaseMonthlyPayment$({term: option.term, residualValue: option.lowest})| async | currency }}</span>
    </div>

  </ng-container>

  <div
    (click)="updateEstMPY(option.term, 12000)"
    class="app-inline-form-layout {{isCurrentlySelectedResidual(option.term, 12000) ? (leaseSelected ? 'row-selected-blue' : 'row-selected-grey') : 'lease-rate-row'}}">
    <span></span>
    <span>12,000</span>
    <span>{{ option.low | currency }}</span>
    <span>{{totalLeaseMonthlyPayment$({term: option.term, residualValue: option.low}) | async | currency }}</span>
  </div>

  <div
    (click)="updateEstMPY(option.term, 15000)"
    class="app-inline-form-layout {{isCurrentlySelectedResidual(option.term, 15000) ? (leaseSelected ? 'row-selected-blue' : 'row-selected-grey') : 'lease-rate-row'}}">
    <span></span>
    <span>15,000</span>
    <span>{{ option.standard | currency }}</span>
    <span>{{totalLeaseMonthlyPayment$({term: option.term, residualValue: option.standard})| async | currency }}</span>
  </div>

  <app-simple-input
    [parentForm]="leaseForm"
    [formCtrlName]="'moneyFactor' + option.term"
    [fieldValue]="leaseForm.value['moneyFactor' + option.term]"
    [name]="'Money Factor'"
    [type]="'number'"
    [blur]="submitLeaseForm"
    [keydown]="flagChange"
    [disabled]="uiState.selectedLeaseTerm !== option.term">
  </app-simple-input>

  <hr class="standard-section-breaker">

</article>

<div style="display:flex;gap:2px;align-items: center">
  <input
    type="checkbox"
    class="form-check"
    [checked]="!leaseForm?.get('subventionCashDisabled').value"
    (click)="toggleSubventionCashDisabled()"/>
  <app-simple-input
    formCtrlName="subventionCash"
    name="Lease Subvention"
    type="number"
    pipe="currency"
    [parentForm]="leaseForm"
    [fieldValue]="uiState.subventionCash"
    [blur]="submitLeaseForm"
    [keydown]="flagChange">
  </app-simple-input>
</div>

<app-simple-input
  [parentForm]="leaseForm"
  [formCtrlName]="'acquisitionFee'"
  [fieldValue]="leaseOptions?.acquisitionFee"
  [name]="'Acquisition Fee'"
  [type]="'number'"
  [blur]="submitLeaseForm"
  [keydown]="flagChange"
  [pipe]="'currency'">
</app-simple-input>

<app-simple-input
  *ngIf="uiState.rateMarkup"
  [parentForm]="leaseForm"
  [formCtrlName]="'rateMarkup'"
  [fieldValue]="uiState.rateMarkup"
  [name]="'Rate Markup'"
  [type]="'number'"
  [blur]="submitLeaseForm"
  [keydown]="flagChange"
  [pipe]="'currency'">
</app-simple-input>

<app-simple-input
  [parentForm]="excessMilesForm"
  [formCtrlName]="'prepaidMilesTotal'"
  [fieldValue]="uiState.prepaidMilesTotal"
  [name]="'Prepaid Miles Total'"
  [type]="'number'"
  [blur]="submitExcessMilesForm"
  [keydown]="flagChange"
  [additionalFieldValue]="excessMilesPerYear$ | async">
</app-simple-input>

<hr class="standard-section-breaker">

<app-smw-rebates-form
  dealType="lease"
  [dealIncentives]="dealIncentives"
  [vehicleIncentives]="vehicleIncentives"
  (updateIncentives)="onUpdateIncentives($event)">
</app-smw-rebates-form>

<div class="section-header">Products</div>

<app-smw-insurance-products-form
  [dealDefaults]="dealDefaults"
  [selectedDealTerm]="uiState.selectedLeaseTerm"
  (flagUnsavedChanges)="flagChange()"
  [type]="'lease'">
</app-smw-insurance-products-form>

<!-- <app-simple-input
  [fieldValue]="totalInsuranceProductsPrice$ | async | currency"
  [name]="'Total Protection Adds'"
  [type]="'number'">
</app-simple-input> -->

<app-simple-input
  [fieldValue]="tradeInNetValue$ | async"
  name="Trade Net"
  type="number"
  pipe="currency">
</app-simple-input>

<app-simple-input
  [fieldValue]="adjustedCapCost$ | async"
  name="Adjusted Cap Cost"
  type="number"
  pipe="currency">
</app-simple-input>

<app-modal-view
  #modal="modal"
  modalTitle="Change Stock Number">

  <app-stock-number
    modal-body
    (formSubmitFlag)="modal.closeModal()">
  </app-stock-number>

</app-modal-view>

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../auth-module/services";
import { USER_ROLES } from "../app.config";
import { CNAPageComponent } from "./pages/cna-page.component";


const cnaRoutes: Routes = [
  {
    path: "cna",
    children: [
      {
        path: "list",
        component: CNAPageComponent
      }
    ],
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.sales_floor]
    }
  },
  {path: "**", component: CNAPageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(cnaRoutes)],
  exports: [RouterModule]
})
export class CNARoutingModule {}

<!-- BUYER FORM - SECTION 1 -->
<div class="section-header" style="margin-top:7px!important;display:flex;align-items: center;justify-content: space-between">
  <span>Insurance Info</span>
  <a
    (click)="clearInsuranceInfo()"
    style="font-size: 14px;text-decoration: underline;cursor:pointer;color:#027aff;font-weight:normal">
    Clear
  </a>
</div>
<form [formGroup]="insuranceInformationForm">
  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'companyName'"
                    [fieldValue]="insuranceInfo.companyName"
                    [name]="'Company Name'"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input>


  <!-- <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'agentName'"
                    [fieldValue]="insuranceInfo.agentName"
                    [name]="'Agent Name'"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input>


  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'street'"
                    [fieldValue]="insuranceInfo.street"
                    [name]="'Street'"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input> -->


  <!-- <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'city'"
                    [fieldValue]="insuranceInfo.city"
                    [name]="'City'"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'state'"
                    [fieldValue]="insuranceInfo.state"
                    [name]="'State'"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input> -->
  <!--
  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'zip'"
                    [fieldValue]="insuranceInfo.zip"
                    [name]="'Zip'"
                    [minLength]="5"
                    [maxLength]="5"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input> -->

  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'policyNumber'"
                    [fieldValue]="insuranceInfo.policyNumber"
                    [name]="'Policy Number'"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'phone'"
                    [fieldValue]="insuranceInfo.phone"
                    [name]="'Phone'"
                    [minLength]="10"
                    [type]="'text'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input>


  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'policyEffectiveDate'"
                    [fieldValue]="uiState.policyEffectiveDate"
                    [name]="'Policy Effective Date'"
                    [type]="'date'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input>


  <app-simple-input [parentForm]="insuranceInformationForm"
                    [formCtrlName]="'policyExpirationDate'"
                    [fieldValue]="uiState.policyExpirationDate"
                    [name]="'Policy Expiration Date'"
                    [type]="'date'"
                    [maxLength]="REQS.maxLength.standard"
                    [blur]="autoSubmitForm"
                    [keydown]="flagChange">
  </app-simple-input>

</form>

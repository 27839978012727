import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Accessory, MenuItem } from "src/app/clearpath-module/models";
import { IsCollapsed } from "../../models";
import { mergeDeepRight } from "ramda";

const defaultCollapsed: IsCollapsed = {isCollapsed: true};

@Component({
  selector: "app-featured-accessory",
  templateUrl: "./featured-accessory.component.html",
  styleUrls: ["./featured-accessory.component.scss"]
})
export class FeaturedAccessoryComponent implements OnInit {
  constructor() { }

  @Input() accessory: Accessory;
  @Input() index: number;
  @Output() changes = new EventEmitter();
  onFormChanges: any;
  uiState: {
    isCollapsed: boolean;
    accessoryLink: IsCollapsed;
  };

  ngOnInit() {
    this.initUiState();
    this.watchFormChanges();
  }

  initUiState() {
    this.uiState = {...defaultCollapsed, accessoryLink: defaultCollapsed};
  }

  watchFormChanges() {
    this.onFormChanges = {
      accessoryLink: (menuItem: MenuItem) => {
        const accessory = mergeDeepRight(
          this.accessory,
          {accessoryLink: menuItem}
        );
        this.accessory = accessory;
      },
      accessoryForm: (updatedAccessory: Partial<Accessory>) => {
        const accessory = mergeDeepRight(this.accessory, updatedAccessory);
        this.accessory = accessory;
      }
    };
  }

  save() {
    this.accessory.name = this.accessory.name.trim();
    this.changes.emit({accessory: this.accessory, index: this.index});
    this.uiState.isCollapsed = true;
    this.uiState.accessoryLink.isCollapsed = true;
  }

  deleteItem() {
    if (confirm("Delete this entry?")) {
      this.changes.emit({index: this.index});
    }
  }

}

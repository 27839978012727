import { createAction } from "@ngrx/store";

const prefix = "[AUTH]";

export const LOGIN_ROUTED = `${prefix} LOGIN_ROUTED`;
export const LOGIN = `${prefix} LOGIN`;
export const LOGIN_SUCCESS = `${prefix} LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${prefix} LOGIN_FAILURE`;

export const LOGIN_WITH_TOKEN = `${prefix} LOGIN_WITH_TOKEN`;
export const LOGIN_WITH_TOKEN_SUCCESS = `${prefix} LOGIN_WITH_TOKEN_SUCCESS`;

export const LOGOUT = `${prefix} LOGOUT`;
export const LOGOUT_SUCCESS = `${prefix} LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${prefix} LOGOUT_FAILURE`;

export const REFRESH_TOKEN = `${prefix} REFRESH_TOKEN`;
export const REFRESH_TOKEN_WITH_RETRY = `${prefix} REFRESH_TOKEN_WITH_RETRY`;
export const REFRESH_TOKEN_SUCCESS = `${prefix} REFRESH_TOKEN_SUCCESS`;
export const REFRESH_TOKEN_FAILURE = `${prefix} REFRESH_TOKEN_FAILURE`;

export const VERIFY_TOKEN = `${prefix} VERIFY_TOKEN`;

export const CLEAR_USER = `${prefix} CLEAR_USER`;

export const NO_ACTION = `${prefix} NO_ACTION`;

export const login = createAction(LOGIN, token => token);
export const loginSuccess = createAction(LOGIN_SUCCESS, ({token, user}) => ({token, user}));
export const loginFailure = createAction(LOGIN_FAILURE, error => error);

export const loginWithToken = createAction(LOGIN_WITH_TOKEN, ({token}) => ({token}));
export const loginWithTokenSuccess = createAction(LOGIN_WITH_TOKEN_SUCCESS, ({token, user}) => (
  {token, user})
);

export const logout = createAction(LOGOUT);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const logoutFailure = createAction(LOGOUT_FAILURE, error => error);

export const refreshToken = createAction(REFRESH_TOKEN);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS, ({token}) => ({token}));
export const refreshTokenFailure = createAction(REFRESH_TOKEN_FAILURE, error => error);
export const verifyToken = createAction(VERIFY_TOKEN);

export const clearUser = createAction(CLEAR_USER);

export const noAction = createAction(NO_ACTION);


<app-modal-view modalTitle="Vehicle Lookup" role="dialog" [disableClickOut]="true">
  <section modal-body>

    <app-search-form [placeholder]="'Search'"></app-search-form>

    <section class="data-table-container" style="margin-top:20px;">
      <div class="table-responsive">
        <table class="data-table mx-auto w-100">
          <tr>
            <th class="selectable" style="width: auto;" [ngClass]="{ 'selected': isActiveSort('stockNumber') }" (click)="onSelectSort('stockNumber')">
              <div class="d-flex align-items-center">Stock #
                <i *ngIf="isActiveSort('stockNumber')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="width: 55px;" [ngClass]="{ 'selected': isActiveSort('year') }" (click)="onSelectSort('year')">
              <div class="d-flex align-items-center">Year
                <i *ngIf="isActiveSort('year')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 125px;" [ngClass]="{ 'selected': isActiveSort('make') }" (click)="onSelectSort('make')">
              <div class="d-flex align-items-center">Make
                <i *ngIf="isActiveSort('make')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('model') }" (click)="onSelectSort('model')">
              <div class="d-flex align-items-center">Model<i *ngIf="isActiveSort('model')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('trim') }" (click)="onSelectSort('trim')">
              <div class="d-flex align-items-center">Trim
                <i *ngIf="isActiveSort('trim')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 80px" [ngClass]="{ 'selected': isActiveSort('type') }" (click)="onSelectSort('type')">
              <div class="d-flex align-items-center">Condition
                <i *ngIf="isActiveSort('type')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 70px;" [ngClass]="{ 'selected': isActiveSort('odometer') }" (click)="onSelectSort('odometer')">
              <div class="d-flex align-items-center">Mileage
                <i *ngIf="isActiveSort('odometer')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 80px;" [ngClass]="{ 'selected': isActiveSort('retail') }" (click)="onSelectSort('retail')">
              <div class="d-flex align-items-center">Retail
                <i *ngIf="isActiveSort('retail')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 70px" [ngClass]="{ 'selected': isActiveSort('bodyStyle') }" (click)="onSelectSort('bodyStyle')">
              <div class="d-flex align-items-center">Body
                <i *ngIf="isActiveSort('bodyStyle')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 300px;" [ngClass]="{ 'selected': isActiveSort('engine') }" (click)="onSelectSort('engine')">
              <div class="d-flex align-items-center">Engine
                <i *ngIf="isActiveSort('engine')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 300px;" [ngClass]="{ 'selected': isActiveSort('transmission') }" (click)="onSelectSort('transmission')">
              <div class="d-flex align-items-center">Transmission
                <i *ngIf="isActiveSort('transmission')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

            <th class="selectable" style="min-width: 140px" [ngClass]="{ 'selected': isActiveSort('lot') }" (click)="onSelectSort('lot')">
              <div class="d-flex align-items-center">Lot
                <i *ngIf="isActiveSort('lot')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
              </div>
            </th>

          </tr>

          <ng-container *ngIf="(vehicles || []).length">
            <tr *ngFor="let vehicle of vehiclesList | vehicleSort: sortParam : ascending"
                class="selectable"
                (click)="onSelectVehicle(vehicle)">
              <td class="table-element">{{vehicle.stockNumber}}</td>
              <td class="table-element">{{vehicle.year}}</td>
              <td class="table-element">{{vehicle.make ? vehicle.make.toUpperCase() : ""}}</td>
              <td class="table-element">{{vehicle.model}}</td>
              <td class="table-element">{{vehicle.trim}}</td>
              <td class="table-element">{{getCondition(vehicle)}}</td>
              <td class="table-element">{{numberWithCommas(vehicle.odometer)}}</td>
              <td class="table-element">${{numberWithCommas(vehicle.retail)}}</td>
              <td class="table-element">{{vehicle.bodyStyle}}</td>
              <td class="table-element">{{truncateString(vehicle.engine)}}</td>
              <td class="table-element">{{truncateString(vehicle.transmission)}}</td>
              <td class="table-element">{{vehicle.lot}}</td>
            </tr>
          </ng-container>
        </table>
      </div>
    </section>
  </section>

</app-modal-view>

<section class="d-flex justify-content-center pt-3">
  <table *ngIf="residuals" class="data-table" style="max-width: 700px;">
    <tr>
      <th *ngFor="let header of headers">{{header}}</th>
    </tr>
    <tr *ngFor="let residual of residuals">
      <td class="font-weight-bold">{{residual.modelNumber}}</td>
      <td class="font-weight-bold">{{residual.year}}</td>
      <td *ngFor="let item of residual.values">{{item.value | currency}}</td>
    </tr>
  </table>
</section>    
import { createAction } from "@ngrx/store";

const prefix = "[Clearpath]";

const GET_DEAL_BY_DEAL_ID = `${prefix} GET DEAL BY DEAL ID`;
const GET_PRINT_DEALS = `${prefix} GET PRINT DEALS`;
const GET_PRINT_DEALS_API_SUCCESS = `${prefix} GET PRINT DEALS API SUCESS`;
const GET_PRINT_DEALS_API_FAILURE = `${prefix} GET PRINT DEALS API FAILURE`;

/**
 * DEAL SUB ACTIONS
 * Manipulates slices of deal
 */
const SET_APR_SUBVENTION_DISABLED = `${prefix} SET_APR_SUBVENTION_DISABLED`;
const SET_ACCESSORIES = `${prefix} SET ACCESSORIES`;
const SET_COSIGNER = `${prefix} SET CO SIGNER`;
const SET_CUSTOMER = `${prefix} SET CUSTOMER`;
const SET_CUSTOMER_STATE_COUNTY = `${prefix} SET CUSTOMER STATE COUNTY`;
const SET_DEAL_TYPE = `${prefix} SET DEAL TYPE`;
const SET_DEAL_ODOMETER = `${prefix} SET DEAL ODOMETER`;
const SET_FINANCE_OPTIONS = `${prefix} SET FINANCE OPTIONS`;
const SET_CONTRACT_DATE = `${prefix} SET CONTRACT DATE`;
const SET_FINANCE_OPTIONS_EDITS = `${prefix} SET FINANCE OPTIONS EDITS`;
const SET_LIEN_HOLDER = `${prefix} SET LIEN HOLDER`;
const SET_LEASE_OPTIONS = `${prefix} SET LEASE OPTIONS`;
const SET_INSURANCE_PRODUCTS = `${prefix} SET INSURANCE PRODUCTS`;
const UPDATE_INSURANCE_PRODUCT = `${prefix} UPDATE INSURANCE PRODUCT`;
const SET_SALES_MANAGER = `${prefix} SET SALES MANAGER`;
const SET_SALES_PERSON = `${prefix} SET SALES PERSON`;
const SET_TRADE_IN = `${prefix} SET TRADE IN`;
const SET_TRADE_IN_OWNER = `${prefix} SET TRADE IN OWNER`;
const SET_TRADE_IN_OWNER_2 = `${prefix} SET TRADE IN OWNER 2`;
const SET_TRADE_IN_2 = `${prefix} SET TRADE IN 2`;
const SET_TRADE_IN_2_OWNER = `${prefix} SET TRADE IN 2 OWNER`;
const SET_TRADE_IN_2_OWNER_2 = `${prefix} SET TRADE IN 2 OWNER 2`;
const SET_VEHICLE_NEEDS = `${prefix} SET VEHICLE NEEDS`;
const SET_INCENTIVES = `${prefix} SET INCENTIVES`;
const SET_PLATE_TRANSFER = `${prefix} PLATE TRANSFER`;
const SET_SIGNATURES = `${prefix} SET SIGNATURES`;
const SET_SIGNATURES_SUCCESS = `${prefix} SET SIGNATURES SUCCESS`;
const SET_SIGNATURES_FAILURE = `${prefix} SET SIGNATURES FAILURE`;
const SET_PLATE = `${prefix} SET PLATE`;
const INSURANCE_PRODUCTS_SET = `${prefix} INSURANCE PRODUCTS SET`;
const INCENTIVES_SET = `${prefix} INCENTIVES SET`;
const SET_DISPLAY_TERMS = `${prefix} SET DISPLAY TERMS`;
const SET_INITIALIZED = `${prefix} SET INITIALIZED`;
const SET_INSURANCE_INFO = `${prefix} SET INSURANCE INFO`;
const RECALCULATE = `${prefix} RECALCULATE`;
const PUSH_VERIFIED_FORM = `${prefix} PUSH VERIFIED FORM`;
const APPLY_INITIAL_INSURANCE_PRODUCTS = `${prefix} APPLY INITIAL INSURANCE PRODUCTS`;
const REMOVE_VERIFIED_FORM = `${prefix} REMOVE VERIFIED FORM`;
const CLEAR_FINANCE_OPTIONS_EDITS = `${prefix} CLEAR FINANCE OPTIONS EDITS`;
const REASSIGN_CLEAR_CARE_ELITE = `${prefix} REASSIGN CLEAR CARE ELITE`;
const SET_CUSTOM_FINANCE_RATE = `${prefix} SET CUSTOM FINANCE RATE`;
const SET_GAP_TO_FINANCING_TERM = `${prefix} SET GAP TO INANCING TERM`;
const NO_OP = `${prefix} NO OP`;
/**
 * DEAL LIFECYCLE
 * Stages of business sales process.
 */
const CLEAR_DEAL = `${prefix} CLEAR DEAL`;
const CREATE_DEAL = `${prefix} CREATE DEAL`;
const CHANGE_DEAL = `${prefix} CHANGE DEAL`;
const SUBMIT_DEAL = `${prefix} SUBMIT DEAL`;
const RETRACT_DEAL = `${prefix} RETRACT DEAL`;
const UPDATE_DEAL = `${prefix} UPDATE DEAL`;
const SAVE_DEAL = `${prefix} SAVE DEAL`;
const APPROVE_DEAL = `${prefix} APPROVE DEAL`;
const PRINT_DEAL = `${prefix} PRINT DEAL`;
const PRINT_DEAL_SETUP = `${prefix} PRINT DEAL SETUP`;
const PRINT_DEAL_SETUP_FINISHED = `${prefix} PRINT DEAL SETUP FINISHED`;
const DECLINE_DEAL = `${prefix} DECLINE DEAL`;
const COMPLETE_DEAL = `${prefix} COMPLETE DEAL`;
const CANCEL_CHANGE_DEAL = `${prefix} CANCEL CHANGE DEAL`;

/**
 * DEAL API
 * Update store when HTTP request successfuly returns with saved deal data.
 */
const DEAL_API_SUCCESS = `${prefix} DEAL API SUCCESS`;
const DEAL_API_FAILURE = `${prefix} DEAL API FAILURE`;

/**
 * FORM SUBMIT ACTIONS
 * Carry form data to be processed by other actions in deal effects
 */
const SUBMIT_DEAL_SUBMIT_MODAL_FORM = `${prefix} SUBMIT DEAL SUBMIT MODAL FORM`;
const GET_DEAL_AND_SUBMIT = `${prefix} GET DEAL AND SUBMIT`;

/**
 * OTHER ACTIONS
 */
const SALES_MANAGER_CREATE_DEAL = `${prefix} SALES MANAGER CREATE DEAL`;
const SALES_MANAGER_SET_FINANCING_TERM = `${prefix} SALES MANAGER SET FINANCING TERM`;
const SET_FINANCING_TERM = `${prefix} SET FINANCING TERM`;
const SET_STATE_TAXES_FEES = `${prefix} SET_STATE_TAXES_FEES`;
const SET_LEASING_TERM = `${prefix} SET LEASING TERM`;
const NOTIFICATIONS_CLICKED = `${prefix} NOTIFICATIONS CLICKED`;
const CALC_FEES = `${prefix} CALC FEES`;
const CALC_TAX = `${prefix} CALC TAX`;
const CLEAR_MONEY_FACTORS = `${prefix} CLEAR_MONEY_FACTORS`;
const UPDATE_SELECTED_DEALER_ACCESSORIES = `${prefix} UPDATE_SELECTED_DEALER_ACCESSORIES`;

// DEAL SUB-ACTIONS

export const getDealByDealId = createAction(
  GET_DEAL_BY_DEAL_ID, dealId => ({dealId})
);

export const setAccessories = createAction(
  SET_ACCESSORIES, accessories => ({accessories})
);
export const setCoSigner = createAction(
  SET_COSIGNER, coSigner => ({coSigner})
);
export const setCustomer = createAction(
  SET_CUSTOMER, customer => ({customer})
);
export const setCustomerStateCounty = createAction(
  SET_CUSTOMER_STATE_COUNTY, ({state, county}) => ({state, county})
);
export const setDealType = createAction(
  SET_DEAL_TYPE, dealType => ({dealType})
);
export const setFinanceOptions = createAction(
  SET_FINANCE_OPTIONS, financeOptions => ({financeOptions})
);
export const aprSubventionDisabled = createAction(
  SET_APR_SUBVENTION_DISABLED, financeOptions => ({financeOptions})
);


export const setContractDate = createAction(
  SET_CONTRACT_DATE, contractDate => ({contractDate})
);
export const setFinanceOptionsEdits = createAction(
  SET_FINANCE_OPTIONS_EDITS, financeOptionsEdits => ({financeOptionsEdits})
);
export const setCustomFinanceRate = createAction(
  SET_CUSTOM_FINANCE_RATE,
  ({customerProvidedInterestRate, customSelected}) =>
    ({customerProvidedInterestRate, customSelected})
);
export const setLienHolder = createAction(
  SET_LIEN_HOLDER, lienHolder => ({lienHolder})
);
export const setLeaseOptions = createAction(
  SET_LEASE_OPTIONS, leaseOptions => ({leaseOptions})
);
export const setInsuranceProducts = createAction(
  SET_INSURANCE_PRODUCTS, insuranceProducts => {
    return ({insuranceProducts});
  }
);
export const updateInsuranceProduct = createAction(
  UPDATE_INSURANCE_PRODUCT, insuranceProduct => ({insuranceProduct}));

export const setGapToFinancingTerm = createAction(
  SET_GAP_TO_FINANCING_TERM, selectedFinancingTerm => {
    return ({selectedFinancingTerm});
  }
);
export const applyInitialInsuranceProducts = createAction(
  APPLY_INITIAL_INSURANCE_PRODUCTS
);
export const setSalesManager = createAction(
  SET_SALES_MANAGER, salesManager => ({salesManager})
);
export const setDisplayTerms = createAction(
  SET_DISPLAY_TERMS, displayTerms => ({displayTerms})
);
export const setSalesPerson = createAction(
  SET_SALES_PERSON, salesPerson => ({salesPerson})
);
export const setTradeIn = createAction(
  SET_TRADE_IN, tradeIn => ({tradeIn})
);
export const setTradeInOwner = createAction(
  SET_TRADE_IN_OWNER, tradeInOwner => ({tradeInOwner})
);
export const setTradeInOwner2 = createAction(
  SET_TRADE_IN_OWNER_2, tradeInOwner2 => ({tradeInOwner2})
);
export const setTradeIn2 = createAction(
  SET_TRADE_IN_2, tradeIn2 => ({tradeIn2})
);
export const setTradeIn2Owner = createAction(
  SET_TRADE_IN_2_OWNER, tradeIn2Owner => ({tradeIn2Owner})
);
export const setTradeIn2Owner2 = createAction(
  SET_TRADE_IN_2_OWNER_2, tradeIn2Owner2 => ({tradeIn2Owner2})
);
export const setVehicleNeeds = createAction(
  SET_VEHICLE_NEEDS, vehicleNeeds => ({vehicleNeeds})
);
export const setIncentives = createAction(
  SET_INCENTIVES, incentives => ({incentives})
);
export const setInsuranceInfo = createAction(
  SET_INSURANCE_INFO, ({insuranceInfo}) => ({insuranceInfo})
);
export const setPlateTransfer = createAction(
  SET_PLATE_TRANSFER, plateTransfer => {
    return ({plateTransfer});
  }
);
export const setSignatures = createAction(
  SET_SIGNATURES, ({signatureData}) => ({signatureData})
);
export const pushVerifiedForm = createAction(
  PUSH_VERIFIED_FORM, ({formName}) => ({formName})
);
export const removeVerifiedForm = createAction(
  REMOVE_VERIFIED_FORM, ({formName}) => ({formName})
);
export const setSignaturesSuccess = createAction(
  SET_SIGNATURES_SUCCESS,
  ({buyerSignatureSaved, coBuyerSignatureSaved}) =>
    ({buyerSignatureSaved, coBuyerSignatureSaved})
);
export const setSignaturesFailure = createAction(
  SET_SIGNATURES_FAILURE, error => error
);
export const insuranceProductsSet = createAction(
  INSURANCE_PRODUCTS_SET, ({insuranceProductsSet}) => ({insuranceProductsSet})
);
export const incentivesSet = createAction(
  INCENTIVES_SET, ({incentivesSet}) => ({incentivesSet})
);
export const recalculate = createAction(RECALCULATE);
// DEAL LIFECYCLE / TOP-LEVEL DEAL ACTIONS

export const getDealAndSubmit = createAction(GET_DEAL_AND_SUBMIT);
export const clearDeal = createAction(CLEAR_DEAL);
export const createDeal = createAction(
  CREATE_DEAL, deal => ({deal})
);
export const salesManagerCreateDeal = createAction(
  SALES_MANAGER_CREATE_DEAL, deal => ({deal})
);
export const changeDeal = createAction(
  CHANGE_DEAL, deal => ({deal})
);
export const submitDeal = createAction(
  SUBMIT_DEAL, deal => ({deal})
);
export const retractDeal = createAction(
  RETRACT_DEAL, dealId => ({dealId})
);
export const updateDeal = createAction(
  UPDATE_DEAL, deal => ({deal})
);
export const saveDeal = createAction(
  SAVE_DEAL, deal => ({deal})
);
export const approveDeal = createAction(
  APPROVE_DEAL, (dealId, criticalMemo) => ({dealId, criticalMemo})
);
export const printDeal = createAction(
  PRINT_DEAL, dealId => ({dealId})
);
export const getPrintDeals = createAction(
  GET_PRINT_DEALS
);
export const getPrintDealsApiSuccess = createAction(
  GET_PRINT_DEALS_API_SUCCESS, deals => ({deals})
);
export const getPrintDealsApiFailure = createAction(
  GET_PRINT_DEALS_API_FAILURE, error => error
);
export const printDealSetup = createAction(
  PRINT_DEAL_SETUP
);
export const printDealSetupFinished = createAction(
  PRINT_DEAL_SETUP_FINISHED
);
export const declineDeal = createAction(
  DECLINE_DEAL, dealId => ({dealId})
);
export const completeDeal = createAction(
  COMPLETE_DEAL, deal => ({deal})
);
export const dealApiSuccess = createAction(
  DEAL_API_SUCCESS, (deal, actionType = '') => ({deal, actionType})
);
export const dealApiFailure = createAction(
  DEAL_API_FAILURE, error => error
);
export const setDealOdometer = createAction(
  SET_DEAL_ODOMETER, odometer => ({odometer})
);
export const setPlate = createAction(
  SET_PLATE, ({plateExpires, plateNumber}) => ({plateExpires, plateNumber})
);
export const setInitialized = createAction(
  SET_INITIALIZED, ({initialized}) => ({initialized})
);
export const cancelChangeDeal = createAction(
  CANCEL_CHANGE_DEAL
);
export const clearFinanceOptionsEdits = createAction(
  CLEAR_FINANCE_OPTIONS_EDITS
);
export const noOp = createAction(
  NO_OP
);

export const calcFees = createAction(CALC_FEES);
export const calcTax = createAction(CALC_TAX);


// FORM SUBMIT ACTIONS

export const submitDealSubmitModalForm = createAction(
  SUBMIT_DEAL_SUBMIT_MODAL_FORM, ({
                                    signatureData,
                                    signatureUrls,
                                    customer,
                                    coSigner,
                                    tradeIn,
                                    tradeInOwner,
                                    tradeInOwner2,
                                    tradeIn2,
                                    tradeIn2Owner,
                                    tradeIn2Owner2,
                                    insuranceInfo,
                                    comments
                                  }) => ({
    signatureData,
    signatureUrls,
    customer,
    coSigner,
    tradeIn,
    tradeInOwner,
    tradeInOwner2,
    tradeIn2,
    tradeIn2Owner,
    tradeIn2Owner2,
    insuranceInfo,
    comments
  })
);

// OTHER ACTIONS

export const setFinancingTerm = createAction(
  SET_FINANCING_TERM,
  selectedFinancingTerm => ({selectedFinancingTerm})
);

export const clearCustomMoneyFactors = createAction(
  CLEAR_MONEY_FACTORS
);

export const setStateTaxesFees = createAction(
  SET_STATE_TAXES_FEES, (totalStateTaxes, totalStateFees) => ({totalStateTaxes, totalStateFees})
);


export const salesManagerSetFinancingTerm = createAction(
  SALES_MANAGER_SET_FINANCING_TERM,
  selectedFinancingTerm => ({selectedFinancingTerm})
);

export const setLeasingTerm = createAction(
  SET_LEASING_TERM,
  selectedLeaseTerm => ({selectedLeaseTerm})
);

export const updateSelectedDealerAccessories = createAction(
  UPDATE_SELECTED_DEALER_ACCESSORIES,
  selectedDealerAccessories => ({selectedDealerAccessories})
);

export const notificationsClicked = createAction(NOTIFICATIONS_CLICKED);

export class User {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  securityGroups?: string;
  password?: string;
  photoUrl?: string;
  smsCellNumber?: string;
  employeeId?: string;
  managerId?: string;
  suppressPresentationStarted?: boolean;

  static unpackSecurityGroups(secGrpStr: string): Array<string> {
    return secGrpStr ? secGrpStr.replace(/[{}\s]/g, "").split(",") : [];
  }

  static packSecurityGroups(list: Array<string>): string {
    return list ? "{" + list.join(",") + "}" : "{}";
  }
}

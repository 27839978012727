import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cna-list-dialog-header',
  templateUrl: './cna-list-dialog-header.component.html',
  styleUrls: ['./cna-list-dialog-header.component.scss']
})
export class CnaListDialogHeaderComponent {

  @Input() dialogName: string;

  /**
   *
   * @param option
   */
  getOptionIcon(option: string): string {
    return "/static/images/cna_icons/" + (option.toLowerCase().replace(' ', '_')) + '.png'
  }

  getCnaListForAccessory(dialogName: string): string[] {
    // console.log("getCnaListForAccessory:", dialogName)
    switch (dialogName) {
      case 'Paint Protection Film':
        return ["Appearance", "Expenses", "Resale Value"];
      case 'Data Dots':
        return ["Safety"];
      case 'Vehicle Service Agreement':
        return ["Dependability", "Performance", "Expenses"];
      case 'Excess Wear & Use':
        return ["Expenses"];
      case 'Guaranteed Auto Protection':
        return ["Expenses"];
      case 'CLEAR Care Elite':
        return ["Appearance", "Expenses"];
      case 'Pre-Paid Maintenance':
        return ["Dependability", "Expenses", "Convenience"];
      default:
        return []
    }
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CNAPageComponent } from "./pages/cna-page.component";
import { RouterModule } from "@angular/router";
import { CNARoutingModule } from "./cna.routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    CNAPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CNARoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatInputModule,
    DragDropModule,
  ]
})
export class CNAModule {}

<section class="header-layout">
  <h1 class="h2 header-title">Guaranteed Auto Protection</h1>

  <form [formGroup]="gapForm" class="header-form">
    <select
      class="header-form-control form-control"
      formControlName="termCostIndex">
      <option value="" disabled>Select A Plan</option>
      <option
        *ngFor="let option of termCosts; let i = index"
        [value]="i">
        {{ option.term / 12 }} Years / {{ option.miles || "Unlimited" }} Miles -
        {{ (option.priceOverride ? option.priceOverride : option.price) | currency: "USD":"symbol":"1.0-0" }}
      </option>
    </select>
  </form>
</section>

<app-cna-list-dialog-header dialogName="Guaranteed Auto Protection"></app-cna-list-dialog-header>

<div class="w-100 mx-auto my-4" style="max-width: 700px">
  <app-gap-graph></app-gap-graph>
</div>

<div class="btn-layout">
  <button
    class="btn"
    [ngClass]="planSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
    type="button"
    (click)="onAcceptPlan()">
    Yes: Include Coverage
  </button>
  <button
    class="btn"
    [ngClass]="noPlanSelected() ? 'app-btn-dark' : 'app-btn-outline-dark'"
    type="button"
    (click)="onDeclinePlan()">
    Decline
  </button>
</div>

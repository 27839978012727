<div #imageArea (window:resize)="autoSetPlaceholderHeight()">
  <img *ngIf="isValidUrl || defaultIsValid" [src]="stockPhoto" class="w-100 rounded" (error)="invalidateImage()"/>

  <div *ngIf="!isValidUrl"
       class="d-flex flex-column align-items-center justify-content-center app-text-neutral app-bg-light border rounded"
       [ngStyle]="{ height: placeholderHeight + 'px'}">
    <i class="material-icons" style="font-size: 2rem">drive_eta</i>
    <span class="small">Photos Coming Soon!</span>
  </div>
</div>

<div class="d-flex justify-content-center mt-2">
  <span class="badge badge-secondary">
    Stock #{{ stockNumber }}
  </span>
</div>

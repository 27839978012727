import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent {
  loading = false;

  forgotPasswordForm: FormGroup = this.formBuilder.group({
    email: ["", Validators.required]
  });

  constructor(private formBuilder: FormBuilder, private authService: AuthService) {}

  async onSubmit() {
    this.loading = true;
    const {email} = this.forgotPasswordForm.value;
    await this.authService.forgotPassword(email);
    this.forgotPasswordForm.reset();
    this.loading = false;
  }

  // FORM VALIDATION & HELPERS

  touchedInvalid(controlName: string): boolean {
    const touched = this.forgotPasswordForm.controls[ controlName ].touched;
    const valid = this.forgotPasswordForm.controls[ controlName ].valid;
    return touched && !valid;
  }

  trimInput(controlName: string) {
    const value = this.forgotPasswordForm.value[ controlName ].trim();
    this.forgotPasswordForm.patchValue({[ controlName ]: value});
  }
}

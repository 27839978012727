<section class="cp-accordion-header"
         [ngClass]="{ 'cp-accordion-header--open': showDetails }"
         (click)="onToggleDetails()">
  <div class="d-flex justify-content-between mb-0">
    <div class="d-flex align-items-center">
      <i class="material-icons app-bg-light rounded mr-1">{{ showDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
      <h2 class="h5 mb-0">Down Payment</h2>
    </div>
    <div class="h5 mb-0">
      {{ downPayment | currency:'USD':'symbol':'1.0-0' }}
    </div>
  </div>
</section>

<form *ngIf="showDetails"
      [formGroup]="downPaymentForm"
      class="cp-accordion-content p-4">
  <div class="row">
    <label class="col-sm-6 col-form-label"
           [ngClass]="{ 'app-text-danger': touchedInvalid('downPayment') }">
      Down Payment
    </label>
    <div class="col-sm-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      <input class="form-control text-right"
             formControlName="downPayment"
             type="number"
             inputmode="numeric"
             min="0"
             (blur)="autoSubmitDownPaymentForm()"
             required>
    </div>
  </div>
</form>
import { Lender } from "../models/data";

export const LENDERS: Lender[] = [
  {
    name: "ADDISON AVENUE FCU",
    phone: "(720) 974-1311",
    address: "3408 HILLVIEW AVE",
    city: "PALO ALTO",
    state: "CA",
    zip: "94304",
    titleAddress: "PO BOX 10302",
    titleCity: "PALO ALTO",
    titleState: "CA",
    titlePostalCode: "94303-0920",
    id: "9788932",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "ADVANTIS CREDIT UNION",
    phone: "(503) 785-2628",
    address: "14405 Meyers Rd",
    city: "Oregon City",
    state: "OR",
    zip: "97045",
    titleAddress: "14405 Meyers Rd",
    titleCity: "Oregon City",
    titleState: "OR",
    titlePostalCode: "97045",
    id: "6049135",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "Ally Financial",
    phone: "",
    address: "PO Box 8128",
    city: "Cockeysville",
    state: "MD",
    zip: "21030",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "AMERICREDIT FINANCIAL SERVICES",
    phone: "(800) 920-0477",
    address: "284 STATE ROUTE 72N DEPT 300",
    city: "REESVILLE",
    state: "OH",
    zip: "45166",
    titleAddress: "PO BOX 182673",
    titleCity: "ARLINGTON",
    titleState: "TX",
    titlePostalCode: "76096-2673",
    id: "834897",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*BANK OF AMERICA N.A.",
    phone: "",
    address: "PO BOX 2240",
    city: "BREA",
    state: "CA",
    zip: "92822-2240",
    titleAddress: "PO BOX 2759",
    titleCity: "JACKSONVILLE",
    titleState: "FL",
    titlePostalCode: "32203-2759",
    id: "6979684",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "BANK OF HAWAII",
    phone: "(800) 858-2073",
    address: "949 KAMOKILA BLVD. STE 320",
    city: "KAP0LEI",
    state: "HI",
    zip: "96707",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "947766",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "BOEING EMPLOYEES CREDIT UNION",
    phone: "(206) 439-5093",
    address: "PO BOX 997500",
    city: "SACRAMENTO",
    state: "CA",
    zip: "95899",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "548240",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "CAPITAL ONE AUTO FINANCE",
    phone: "(800) 945-9875",
    address: "3901 N DALLAS PARKWAY",
    city: "PLANO",
    state: "TX",
    zip: "75093",
    titleAddress: "PO BOX 660068",
    titleCity: "SACRAMENTO",
    titleState: "CA",
    titlePostalCode: "65866",
    id: "6518300",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "CASTPARTS EMPLOYEES FCU",
    phone: "(503) 771-2464",
    address: "8120 SE LUTHER RD.",
    city: "PORTLAND",
    state: "OR",
    zip: "97206",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "CITIFINANCIAL AUTO",
    phone: "(503) 636-5299",
    address: "4000 KRUSE WAY PLACE 2-170",
    city: "LAKE OSWEGO",
    state: "OR",
    zip: "97035",
    titleAddress: "PO BOX 3449",
    titleCity: "COPPELL",
    titleState: "TX",
    titlePostalCode: "75019",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "EMBOLD CREDIT UNION",
    phone: "",
    address: "PO BOX 2020",
    city: "OREGON CITY",
    state: "OR",
    zip: "97045",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "6049194",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "CONSOLIDATED COMMUNITY CU",
    phone: "(503) 232-8070",
    address: "2021 NE SANDY BLVD",
    city: "PORTLAND",
    state: "OR",
    zip: "97232",
    titleAddress: "1033 NE 6TH AVE",
    titleCity: "PORTLAND",
    titleState: "OR",
    titlePostalCode: "97232",
    id: "3572701",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*CUDL FINANCIAL SERVICES",
    phone: "(866) 852-2835",
    address: "3301 W. AIRPORT FWY. STE 300",
    city: "BEDFORD",
    state: "TX",
    zip: "76021",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "DIGITAL FEDERAL CREDIT UNION",
    phone: "(800) 328-8797",
    address: "220 DONALD LYNCH BLVD.",
    city: "MARLBOROUGH",
    state: "MA",
    zip: "1752",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "FIBRE FEDERAL CREDIT UNION",
    phone: "(360) 225-6537",
    address: "PO BOX 1234",
    city: "LONGVIEW",
    state: "WA",
    zip: "98632",
    titleAddress: "PO BOX 1234",
    titleCity: "LONGVIEW",
    titleState: "WA",
    titlePostalCode: "98632",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "Fifth Third Bank, N.A.",
    phone: "",
    address: "4054 WILLOW LAKE BLVD STE 2062",
    city: "MEMPHIS",
    state: "TN",
    zip: "38153",
    titleAddress: "PO Box 674",
    titleCity: "Wilmington",
    titleState: "OH",
    titlePostalCode: "45177",
    id: "7224055",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "FIRESIDE BANK",
    phone: "(503) 643-0494",
    address: "6600 SW 105TH AVE  STE 140",
    city: "BEAVERTON",
    state: "OR",
    zip: "97008",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "FIRST FEDERAL SAVING & LOAN ASSOC",
    phone: "",
    address: "121 N EDWARDS ST",
    city: "NEWBERG",
    state: "OR",
    zip: "97132",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "First Help Financial, LLC",
    phone: "",
    address: "160 Gould Street Suite 100",
    city: "Needham",
    state: "MA",
    zip: "02494",
    titleAddress: "160 Gould Street Suite 100",
    titleCity: "Needham",
    titleState: "MA",
    titlePostalCode: "02494",
    id: "8007562",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "FIRST TECHNOLOGY FEDERAL CU",
    phone: "(800) 637-0852",
    address: "PO BOX 276181",
    city: "SACRAMENTO",
    state: "CA",
    zip: "95827",
    titleAddress: "PO BOX 276181",
    titleCity: "SACRAMENTO",
    titleState: "CA",
    titlePostalCode: "95827",
    id: "6049202",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "FOREST PARK FCU",
    phone: "(503) 228-2106",
    address: "2465 NW THURMAN ST",
    city: "PORTLAND",
    state: "OR",
    zip: "97210",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*FRED MEYER EMPLOYEES FCU",
    phone: "(503) 253-6301",
    address: "12001 SE MORRISON STREET",
    city: "PORTLAND",
    state: "OR",
    zip: "97292",
    titleAddress: "PO BOX 33170",
    titleCity: "PORTLAND",
    titleState: "OR",
    titlePostalCode: "97292",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "GM FINANCIAL",
    phone: "(800) 920-0477",
    address: "PO BOX 182673",
    city: "ARLINGTON",
    state: "TX",
    zip: "76096-2673",
    titleAddress: "PO BOX 1510",
    titleCity: "COCKEYSVILLE",
    titleState: "MD",
    titlePostalCode: "21030",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "HAWAII STATE FEDERAL CU",
    phone: "",
    address: "PO BOX 3072",
    city: "HONOLULU",
    state: "HI",
    zip: "96802",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "IBEW & UNITED WORKERS FCU",
    phone: "(503) 253-8193",
    address: "P.O. BOX 16877",
    city: "PORTLAND",
    state: "OR",
    zip: "97292-0877",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "INROADS CREDIT UNION",
    phone: "(503) 397-2376",
    address: "PO BOX 537",
    city: "ST HELENS",
    state: "OR",
    zip: "97051",
    titleAddress: "PO BOX 537",
    titleCity: "ST HELENS",
    titleState: "OR",
    titlePostalCode: "97051",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "IQ CREDIT UNION",
    phone: "(360) 418-4404",
    address: "PO BOX 1739",
    city: "VANCOUVER",
    state: "WA",
    zip: "98668",
    titleAddress: "PO BOX 1739",
    titleCity: "VANCOUVER",
    titleState: "WA",
    titlePostalCode: "98668",
    id: "14798",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*JPMORGAN CHASE BANK NA",
    phone: "(800) 336-6675",
    address: "PO BOX 901098",
    city: "FORT WORTH",
    state: "TX",
    zip: "76101-2098",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "7901929",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "KAIPERM NORTHWEST CU",
    phone: "",
    address: "500 NE MULTNOMAH ST.  STE 320",
    city: "PORTLAND",
    state: "OR",
    zip: "97232",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "LACAMAS COMMUNITY CU",
    phone: "",
    address: "PO BOX 1108",
    city: "CAMAS",
    state: "WA",
    zip: "98607",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "LANECO FEDERAL CREDIT UNION",
    phone: "",
    address: "P.O.BOX 866",
    city: "EUGENE",
    state: "OR",
    zip: "97440",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "LEGACY FEDERAL CREDIT UNION",
    phone: "(503) 413-7474",
    address: "2148 NW OVERTON",
    city: "PORTLAND",
    state: "OR",
    zip: "97210",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "LINN CO FEDERAL CREDIT UNION",
    phone: "(541) 259-1235",
    address: "PO BOX 265",
    city: "LEBANON",
    state: "OR",
    zip: "97355",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "MAPS CREDIT UNION",
    phone: "",
    address: "PO BOX 12398",
    city: "SALEM",
    state: "OR",
    zip: "97309",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "Mechanics Bank",
    phone: "",
    address: "PO Box 278852",
    city: "Sacramento",
    state: "CA",
    zip: "95827-8852",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "MID OREGON CREDIT UNION",
    phone: "(541) 382-1795",
    address: "1386 NE CUSHING DR",
    city: "BEND",
    state: "OR",
    zip: "97701",
    titleAddress: "PO BOX 6749",
    titleCity: "BEND",
    titleState: "OR",
    titlePostalCode: "97708",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "NATIONAL AUTO FINANCE CO",
    phone: "(800) 856-6190",
    address: "12850 W GRAN BAY PARKWAY",
    city: "JACKSONVILLE",
    state: "FL",
    zip: "32258",
    titleAddress: "PO BOX 8106",
    titleCity: "COCKEYSVILLE",
    titleState: "MD",
    titlePostalCode: "21030",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "NATIONWIDE NORTHWEST LLC",
    phone: "",
    address: "2250 S RACHCO DRIVE SUITE 155",
    city: "LAS VEGAS",
    state: "NV",
    zip: "89102",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "2565697",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "NAVY FEDERAL CREDIT UNION",
    phone: "",
    address: "PO BOX 659027",
    city: "SACRAMENTO",
    state: "CA",
    zip: "95865-9027",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "6049513",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "NORTHWEST COMMUNITY CU",
    phone: "(800) 452-9515",
    address: "PO Box 10607",
    city: "Eugene",
    state: "OR",
    zip: "97440",
    titleAddress: "PO BOX 10607",
    titleCity: "EUGENE",
    titleState: "OR",
    titlePostalCode: "97440",
    id: "6831700",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "NORTHWEST RESOURCE FCU",
    phone: "(800) 858-2073",
    address: "221 NW SECOND AVENUE STE 100",
    city: "PORTLAND",
    state: "OR",
    zip: "97209",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "NW ADVENTIST FEDERAL CU",
    phone: "",
    address: "10333 SE MAIN",
    city: "PORTLAND",
    state: "OR",
    zip: "97216",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "NW PRIORITY CU",
    phone: "",
    address: "PO BOX 16640",
    city: "PORTLAND",
    state: "OR",
    zip: "97292-0640",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "OCTFCU",
    phone: "",
    address: "P O BOX 11547",
    city: "SANTA ANA",
    state: "CA",
    zip: "92711-1547",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "ONPOINT COMMUNITY CU",
    phone: "(541) 868-2800",
    address: "PO Box 3750",
    city: "Portland",
    state: "OR",
    zip: "97208",
    titleAddress: "PO BOX 3750",
    titleCity: "PORTLAND",
    titleState: "OR",
    titlePostalCode: "97208",
    id: "6049227",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "OREGON COMMUNITY CU",
    phone: "(541) 681-6170",
    address: "PO BOX 77002",
    city: "SPRINGFIELD",
    state: "OR",
    zip: "97475",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "6049242",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "OREGON EMPLOYEES FCU",
    phone: "(503) 588-0211",
    address: "631 WINTER STREET N.E.",
    city: "SALEM",
    state: "OR",
    zip: "97301",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "675322",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "OREGONIANS CREDIT UNION",
    phone: "(503) 239-5338",
    address: "6915 SE Lake Rd",
    city: "Milwaukie",
    state: "OR",
    zip: "97267",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "6049515",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "PACIFIC CASCADE FCU",
    phone: "(541) 343-6238",
    address: "1075 OAK ST",
    city: "EUGENE",
    state: "OR",
    zip: "97401",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "9140489",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "PEOPLE'S COMMUNITY FEDERAL CU",
    phone: "",
    address: "PO BOX 764",
    city: "VANCOUVER",
    state: "WA",
    zip: "98666",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "POINT WEST CREDIT UNION",
    phone: "(503) 546-5000",
    address: "PO BOX 11999",
    city: "PORTLAND",
    state: "OR",
    zip: "97211",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "PRIMESOURCE CU",
    phone: "",
    address: "P O BOX 48275",
    city: "SPOKANE",
    state: "WA",
    zip: "99228",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "RED CANOE CREDIT UNION",
    phone: "(800) 562-5611",
    address: "1418 15TH AVE.",
    city: "LONGVIEW",
    state: "WA",
    zip: "98632",
    titleAddress: "PO BOX 3020",
    titleCity: "LONGVIEW",
    titleState: "WA",
    titlePostalCode: "98632",
    id: "554193",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "RELIABLE CREDIT ASSN INC.",
    phone: "(503) 462-3000",
    address: "10690 SE MCLOUGHLIN BLVD",
    city: "MILWAUKIE",
    state: "OR",
    zip: "97222",
    titleAddress: "PO BOX 22829",
    titleCity: "MILWAUKIE",
    titleState: "OR",
    titlePostalCode: "97269",
    id: "728448",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "RICHARD KOPF",
    phone: "",
    address: "11695 SW SUMMERCREST DR",
    city: "TIGARD",
    state: "OR",
    zip: "97223",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "RIVERMARK COMMUNITY CU",
    phone: "(503) 644-0404",
    address: "PO Box 661298",
    city: "Sacramento",
    state: "CA",
    zip: "95866",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "6049158",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "SELCO COMMUNITY CREDIT UNION",
    phone: "(541) 686-5317",
    address: "PO BOX 7487",
    city: "SPRINGFIELD",
    state: "OR",
    zip: "97475",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "6049161",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "ST HELENS COMMUNTY FCU",
    phone: "",
    address: "1720 ST HELENS STREET",
    city: "ST HELENS",
    state: "OR",
    zip: "97051",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*TOYOTA LEASE TRUST",
    phone: "(800) 874-5386",
    address: "PO BOX 30203",
    city: "COLLEGE STATION",
    state: "TX",
    zip: "77842-3203",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "761685",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*TOYOTA MOTOR CREDIT CORPORATION",
    phone: "(800) 874-8822",
    address: "PO BOX 30203",
    city: "COLLEGE STATION",
    state: "TX",
    zip: "77842-3203",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "8478178",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "TWINSTAR CU",
    phone: "",
    address: "PO BOX 785",
    city: "WILMINGTON",
    state: "OH",
    zip: "45177",
    titleAddress: "PO Box 785",
    titleCity: "Wilmington",
    titleState: "OH",
    titlePostalCode: "45177-0785",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "UNITED AUTO CREDIT CORPORATION",
    phone: "(503) 574-4477",
    address: "P.O. BOX 1118",
    city: "BEAVERTON",
    state: "OR",
    zip: "97075",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "UNITED FINANCE CO",
    phone: "",
    address: "10870 SW BEAVERTON-HILLSDALE H",
    city: "BEAVERTON",
    state: "OR",
    zip: "97005",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "UNITUS COMMUNITY CU",
    phone: "(503) 227-5571",
    address: "PO BOX 277908",
    city: "SACRAMENTO",
    state: "CA",
    zip: "95827",
    titleAddress: "PO BOX 277908",
    titleCity: "SACRAMENTO",
    titleState: "CA",
    titlePostalCode: "95827",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "US AGENCIES CREDIT UNION",
    phone: "",
    address: "95 SW TAYLOR ST",
    city: "PORTLAND",
    state: "OR",
    zip: "97204",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*US BANK",
    phone: "(800) 920-0477",
    address: "17650 NE SANDY BLVD.",
    city: "PORTLAND",
    state: "OR",
    zip: "97230",
    titleAddress: "PO BOX 3427",
    titleCity: "OSHKOSH",
    titleState: "WI",
    titlePostalCode: "54903",
    id: "9793431",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "USAA FEDERAL SAVINGS BANK",
    phone: "(210) 456-8000",
    address: "10750 MCDERMOTT FREEWAY",
    city: "SAN ANTONIO",
    state: "TX",
    zip: "78288-0593",
    titleAddress: "PO BOX 25145",
    titleCity: "LEHIGH VALLEY",
    titleState: "PA",
    titlePostalCode: "18002",
    id: "6049184",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "WACHOVIA DEALER SERVICES INC",
    phone: "(503) 303-6100",
    address: "4000 KRUSE WAY PLACE  BLDG 3 #300",
    city: "LAKE OSWEGO",
    state: "OR",
    zip: "97035",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "9976809",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "WAUNA FEDERAL CREDIT UNION",
    phone: "",
    address: "101 TRUHAAK ST",
    city: "CLATSKANIE",
    state: "OR",
    zip: "97016-0067",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "*WELLS FARGO AUTO",
    phone: "(503) 303-6100",
    address: "PO BOX 997517",
    city: "SACRAMENTO",
    state: "CA",
    zip: "95899-7517",
    titleAddress: "PO BOX 997517",
    titleCity: "SACRAMENTO",
    titleState: "CA",
    titlePostalCode: "95899-7517",
    id: "750522",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "Westlake Financial Services",
    phone: "",
    address: "PO Box 997592",
    city: "SACRAMENTO",
    state: "CA",
    zip: "95899-7592",
    titleAddress: "PO Box 997592",
    titleCity: "SACRAMENTO",
    titleState: "CA",
    titlePostalCode: "95899-7592",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "WEYERHAEUSER EMPLOYEES CU",
    phone: "(800) 562-5611",
    address: "1418 15TH AVE.",
    city: "LONGVIEW",
    state: "WA",
    zip: "98632",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "IBEW & UNITED WORKERS FEDERAL CREDIT UNION",
    phone: "",
    address: "PO BOX 16877",
    city: "PORTLAND",
    state: "OR",
    zip: "97292",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "OREGON STATE CREDIT UNION",
    phone: "(541) 714-4192",
    address: "PO Box 306",
    city: "Corvallis",
    state: "OR",
    zip: "97339",
    titleAddress: "PO Box 306",
    titleCity: "Corvallis",
    titleState: "OR",
    titlePostalCode: "97339",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "FIRST COMMUNITY CU",
    phone: "(541) 267-5115",
    address: "150 E JOHNSON AVE",
    city: "COOS BAY",
    state: "OR",
    zip: "97420",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "PACIFIC NW FEDERAL CREDIT UNION",
    phone: "(503) 258-2632",
    address: "12005 NE ERIN WAY",
    city: "PORTLAND",
    state: "OR",
    zip: "97220",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "309",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "CENTRAL WILLAMETTE CU",
    phone: "(800) 950-4536",
    address: "",
    city: "",
    state: "",
    zip: "x",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "GREAT NORTHWEST FEDERAL C. U.",
    phone: "(360) 590-2242",
    address: "301 W. WISHKAH ST.",
    city: "ABERDEEN",
    state: "WA",
    zip: "98520",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "TD AUTO FINANCE",
    phone: "",
    address: "PO BOX 997551",
    city: "SACRAMENTO",
    state: "CA",
    zip: "95899",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }, {
    name: "washington state employees cu",
    phone: "(800) 562-0999",
    address: "po bx wsecu",
    city: "OLYMPIA",
    state: "wa",
    zip: "98507",
    titleAddress: "",
    titleCity: "",
    titleState: "",
    titlePostalCode: "",
    id: "",
    insuranceAddress: "",
    insuranceCity: "",
    insuranceState: "",
    insurancePostalCode: ""
  }
];

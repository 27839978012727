<h1 class="h2 text-center mt-3 mb-3">
  Lease Residuals
</h1>

<section class="view-list-layout mb-4">
  <button *ngFor="let view of viewList"
          class="btn"
          [ngClass]="activeView(view.id) ? 'app-btn-success' : 'app-btn-outline-success'"
          (click)="onSelectView(view.id)">
    {{ view.title }}
  </button>
</section>

<div *ngIf="waitOnInit" class="d-flex app-text-success justify-content-center align-items-center">
  <span class="spinner-border spinner-border-sm mr-1"></span>
  Loading...
</div>

<section *ngIf="!waitOnInit">

  <article *ngIf="activeView('viewNewResiduals')">
    <h2 class="h3 text-center mb-3">
      New Vehicles<br>
    </h2>
    <section *ngFor="let residuals of newResiduals">
      <app-new-residuals-table [residuals]="residuals"></app-new-residuals-table>
    </section>
  </article>

  <article *ngIf="activeView('viewCertUsedResiduals')">
    <h2 class="h3 text-center mb-3">
      Certified Used Vehicles<br>
    </h2>
    <app-cert-used-residuals-table [residuals]="certUsedResiduals"></app-cert-used-residuals-table>
  </article>

  <article *ngIf="activeView('uploadNewResiduals')">
    <h2 class="h3 text-center mb-3">
      New Vehicles<br>
      <span class="h5 font-weight-normal">
        Upload
      </span>
    </h2>
    <app-upload-new-residuals
      [waitOnAction]="waitOnAction"
      (unsavedChanges)="unsavedChanges($event)"
      (submitResiduals)="onSubmitNewResiduals($event)">
    </app-upload-new-residuals>
  </article>

  <article *ngIf="activeView('uploadCertUsedResiduals')">
    <h2 class="h3 text-center mb-3">
      Certified Used Vehicles<br>
      <span class="h5 font-weight-normal">
        Upload
      </span>
    </h2>
    <app-upload-cert-used-residuals
      [waitOnAction]="waitOnAction"
      (unsavedChanges)="unsavedChanges($event)"
      (submitResiduals)="onSubmitCertUsedResiduals($event)">
    </app-upload-cert-used-residuals>
  </article>

</section>
  
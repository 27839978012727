import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from "rxjs";
import { AppService, CalculationService, DealService, VehicleService } from "../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../store/state";
import { take, takeUntil } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { v4 as uuid } from "uuid";
import { DealState } from "../../../store/state";
import { Vehicle } from "../../../models";

@Component({
  selector: 'app-tax-lookup-modal',
  templateUrl: './tax-lookup-modal.component.html',
  styleUrls: ['./tax-lookup-modal.component.scss']
})
export class TaxLookupModalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  public responseReceived = false;
  public responseData: any = {};

  public mockData = {
    "fees": [
      {
        "feeType": "StateTax",
        "name": "WA State Tax (6.5%)",
        "value": 3250.06
      },
      {
        "feeType": "StateTax",
        "name": "WA MV Sales Tax (0.3%)",
        "value": 150
      },
      {
        "feeType": "StateTax",
        "name": "WA City Tax (3.75%)",
        "value": 1875.04
      },
      {
        "feeType": "StateFee",
        "name": "Title Fee",
        "value": 15
      },
      {
        "feeType": "StateFee",
        "name": "Filing Fee",
        "value": 10
      },
      {
        "feeType": "StateFee",
        "name": "License Registration Fee",
        "value": 30
      },
      {
        "feeType": "StateFee",
        "name": "RTA Tax",
        "value": 333
      },
      {
        "feeType": "StateFee",
        "name": "Plate Fee",
        "value": 104
      },
      {
        "feeType": "StateFee",
        "name": "Motor Vehicle Weight Fee",
        "value": 65
      },
      {
        "feeType": "StateFee",
        "name": "Emergency Medical Service Fee",
        "value": 6.5
      },
      {
        "feeType": "StateFee",
        "name": "License Service Fee",
        "value": 0.75
      },
      {
        "feeType": "StateFee",
        "name": "Service Fee",
        "value": 23
      },
      {
        "feeType": "StateFee",
        "name": "Out of State Vehicle Check Fee",
        "value": 50
      },
      {
        "feeType": "StateFee",
        "name": "Additional Vehicle Weight Fee",
        "value": 10
      },
      {
        "feeType": "StateFee",
        "name": "Transportation Benefit District Fee",
        "value": 40
      }
    ]
  };

  public sellingPrice = 0;
  public deal!: DealState;
  public vehicle!: Vehicle;

  vehicleForm: FormGroup = this.formBuilder.group({
    state: new FormControl(''),
    vehicleType: new FormControl('Passenger'),
    fuelType: new FormControl('Electric'),
    useType: new FormControl('Personal'),
    purchaseDate: new FormControl(new Date()),
    year: new FormControl(2023),
    unladenWeight: new FormControl(undefined),

    numberOfLienholder: new FormControl(0),
    manufacturesSuggestedRetailPrice: new FormControl(''),
    addOns: new FormControl(undefined),
    color: new FormControl(undefined),
    make: new FormControl(undefined),
    model: new FormControl(undefined),
    vin: new FormControl(undefined),

    bodyType: new FormControl(undefined),
    bodyTypeId: new FormControl(undefined),
    grossWeight: new FormControl(undefined),
    odometerReading: new FormControl(undefined),
    taxableSellingPrice: new FormControl(undefined),
    sellingPrice: new FormControl(undefined),
    documentFees: new FormControl(undefined),

    saleType: new FormControl('Purchase'),
    vehicleSoldAs: new FormControl('New'),
    odometerValidity: new FormControl('Actual'),
    isOwnerMilitary: new FormControl(false),
  });

  buyerAddressForm: FormGroup = this.formBuilder.group({
    street: new FormControl(''),
    city: new FormControl(''),
    county: new FormControl(''),
    state: new FormControl(''),
    zipCode: new FormControl(''),
    zipCodeExt: new FormControl(''),
  });

  buyerForm: FormGroup = this.formBuilder.group({
    isIndividual: new FormControl(true),
    ownerType: new FormControl('Individual'),
    dlNotMatchName: new FormControl(false),
    ownersAddressMatchOwnersId: new FormControl(true),
    applyingForPrimaryAddressException: new FormControl(false),
    fein: new FormControl(''),
    businessName: new FormControl(''),
  });

  constructor(
    private appService: AppService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private calculationService: CalculationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromRoot.DealState>,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    combineLatest([
      this.activatedRoute.queryParams,
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.calculationService.totalVehicleFinancePrice$({
        withoutDaysToFirstPay: true,
        actualTrade: true
      })
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, deal, vehicle, totalAmountFinanced]) => {

        this.sellingPrice = totalAmountFinanced;
        this.deal = deal;
        this.vehicle = vehicle;

        //console.log("Tax Lookup Params:", totalAmountFinanced, deal, vehicle);

        this.buyerAddressForm.patchValue({
          street: deal?.customer?.street || '',
          city: deal?.customer?.city || '',
          county: deal?.customer?.county || '',
          state: deal?.customer?.state || '',
          zipCode: deal?.customer?.zip || '',
        });

        if (params.changeDealOnSelectVehicle === "false") {
          //this.changeDealOnSelectVehicle = false;
        }
        if (params.dispatchStockNumberOnly === "true") {
          //this.dispatchStockNumberOnly = true;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  totalFees = 0;
  totalTaxes = 0;

  lookupTaxes() {
    //console.log("Looking Up Taxes.");
    //console.log("Vitu Fees Request Body:", this.generateRequestBody());
    //console.log("Sending to Vitu:", this.dealService.taxLookupDeal, this.dealService.taxLookupVehicle)

    this.dealService.getVituTaxes(this.deal, this.vehicle, this.sellingPrice)
      //of(this.mockData)
      .pipe(take(1))
      .subscribe(
        response => {
          //console.log("Response from Vitu:", response);
          this.responseReceived = true;
          this.responseData = response;
          delete (this.responseData.payload);
          delete (this.responseData.extraData);
          if (this.responseData?.fees?.length > 0) {

            this.responseData.fees = this.responseData.fees.filter((fee: any) => {
              return fee.name !== "Electric Fee" &&
                fee.name !== "Out of State Vehicle Check Fee" &&
                fee.name !== "Transportation Benefit District Fee";
            });

            this.responseData.fees.forEach(fee => {
              if (fee.feeType === "StateTax") {
                this.totalTaxes += fee.value;
              } else {
                this.totalFees += fee.value;
              }
            });
          }
        },
        error => {
          console.error("Error from Vitu:", error);
        }
      );
  }

  back = () => {
    //console.log("Back to Previous Screen");
    this.responseReceived = false;
    this.responseData = {};
  };

  applyUpdates = () => {
    //console.log("Applying Updates");
    this.router.navigate([{outlets: {modal: null}}]).then();
    this.dealService.setStateTaxesFees(this.totalTaxes, this.totalFees);
  };
}

<section class="scale">
  <div class="scale__label small">
    <ng-container *ngIf="vehicle.isUsed">
      {{usedYearScale(_warrantyUi) }}
    </ng-container>
    <ng-container *ngIf="!vehicle.isUsed">
      Year
    </ng-container>
  </div>
  <div class="scale__grid"
       [ngStyle]="{ 'grid-template-columns': 'repeat(' + yearScale.length + ', 1fr)' }">
    <ng-container *ngIf="vehicle.isUsed">
      <div *ngFor="let marker of yearScale;let i=index"
           class="marker small">
        <span class="marker__label">
             {{ usedYearLabel(i)}}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="!vehicle.isUsed">
      <div *ngFor="let marker of yearScale"
           class="marker small">
        <span class="marker__label">{{ marker }}</span>
      </div>
    </ng-container>
  </div>
</section>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppService, CalculationService, DealIncentivesService, DealService, LeaseCalculationService, VehicleService } from "../../services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { pathOr } from "ramda";
import { AppState, DealState } from "../../store/state";
import { CAT_TAX_RATE, PRIVILEGE_TAX_RATE, WASHINGTON_SALES_TAX } from "../../../app.config";

@Component({
  selector: "app-calculation-exposure",
  templateUrl: "./calculation-exposure.component.html",
  styleUrls: ["./calculation-exposure.component.scss"]
})
export class CalculationExposureComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject();
  deal: DealState;
  app: AppState;
  eFilingFee: number;
  privilegeTaxRate: number;
  catTaxRate: number;
  waSalesTaxRate: number;
  pathOr = pathOr;

  // flag used to display 'msrp' vs 'market price'
  isUsed: boolean = false;

  customizedVehiclePrice$ = this.calculationService.customizedVehiclePrice$();
  baseVehiclePrice$ = this.calculationService.baseVehiclePrice$();
  vehicleBaseFinanceAmount$ = this.calculationService.vehicleBaseFinanceAmount$();
  findInterestRate$ = this.calculationService.findInterestRate$();
  totalVehicleFinanceMonthlyPayment$ = this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$();
  baseMonthlyPayment$ = this.calculationService.baseMonthlyPayment$();
  moneyFactor$ = this.leaseCalculationService.moneyFactor$();
  residualValue$ = this.leaseCalculationService.residualValue$();
  grossCapCost$ = this.leaseCalculationService.calcGrossCapCost$();
  accessoriesTotal$ = this.calculationService.accessoriesTotal$();
  incentivesTotal$ = this.incentivesService.cashIncentivesTotal$();
  calcTax$ = this.calculationService.calcTax$();
  calcFees$ = this.calculationService.calcFees$();
  tradeEquity$ = this.calculationService.tradeEquity$();
  CCR$ = this.leaseCalculationService.calcCCR$();
  adjustedCapCost$ = this.leaseCalculationService.adjustedCapCost$();
  depreciationAmount$ = this.leaseCalculationService.depreciationAmount$();
  rentCharge$ = this.leaseCalculationService.rentCharge$();
  baseMonthlyLeasePayment$ = this.leaseCalculationService.calcBaseMonthlyLeasePayment$();
  totalMonthlyLeasePayment$ = this.leaseCalculationService.calcTotalMonthlyLeasePayment$();
  totalFinanceInsuranceProductsPrice$ = this.calculationService.totalFinanceInsuranceProductsPrice$();
  calcTotalLeaseInsuranceProductsPrice$ = this.calculationService.calcTotalLeaseInsuranceProductsPrice$();
  selectedCashIncentives$ = this.incentivesService.selectedCashIncentives$();
  selectedAccessories$ = this.dealService.selectAccessories();
  financeInsuranceProducts$ = this.dealService.dealInsuranceService.selectFinanceInsuranceProducts();
  allInsuranceProducts$ = this.dealService.dealInsuranceService.selectInsuranceProducts();
  leaseInsuranceProducts$ = this.dealService.dealInsuranceService.selectLeaseInsuranceProducts();

  constructor(
    private calculationService: CalculationService,
    private leaseCalculationService: LeaseCalculationService,
    private dealService: DealService,
    private appService: AppService,
    private incentivesService: DealIncentivesService,
    private vehicleService: VehicleService,
  ) {
    this.privilegeTaxRate = PRIVILEGE_TAX_RATE;
    this.catTaxRate = CAT_TAX_RATE;
    this.waSalesTaxRate = WASHINGTON_SALES_TAX;
  }

  ngOnInit() {
    this.dealService.selectDeal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((deal: DealState) => {
        this.deal = deal;
      });

    this.appService.selectClearpathApp()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((app: AppState) => {
        this.app = app;
      });

    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle: any) => {
        //console.log("Vehicle stock number:", vehicle.stockNumber)
        this.isUsed = vehicle.isUsed;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  insuranceProductMonthlyPayment$(product) {
    return this.calculationService.insuranceProductMonthlyPayment$(product);
  }

  insuranceProductMonthlyPaymentForLease$(product) {
    return this.leaseCalculationService.calculateInsuranceProductMonthlyPaymentForLease$(product);
  }

  get msrp() {
    return this.deal.financeOptionsEdits.msr ?
      this.app.vehicle.msr :
      this.deal.financeOptionsEdits.msr;
  }

  get retail() {
    return this.deal.financeOptionsEdits.retail ?
      this.app.vehicle.retail :
      this.deal.financeOptionsEdits.retail;
  }

}

<div class="app-page-container">

  <header class="d-flex flex-wrap align-items-center justify-content-between">
    <h1 class="h2 mb-3 mr-3">
      Incentives
    </h1>
    <div>
      <button class="btn btn-sm app-btn-secondary mb-3 mr-3"
              type="button"
              (click)="onSelectAddGroup()"
              [disabled]="waitOnInit || waitOnAction">
        Add Incentive Group
      </button>
      <button class="btn btn-sm app-btn-secondary mb-3"
              type="button"
              (click)="uiState.uploadSelected = !uiState.uploadSelected"
              [disabled]="waitOnInit || waitOnAction">
        Upload Factory Incentives CSV
      </button>
    </div>
  </header>

  <div *ngIf="waitOnInit"
       class="d-flex justify-content-center align-items-center app-text-success">
    <span class="spinner-border spinner-border-sm mr-1"></span>
    Loading...
  </div>

  <section *ngIf="uiState.uploadSelected">
    <div class="d-flex justify-content-center mt-4 mb-4 ml-auto mr-auto">
      <div style="max-width: 280px;">
        <label class="text-center"
               style="width: 100%">Upload CSV File</label>
        <input class="form-control"
               type="file"
               accept="text/csv"
               (change)="fileEvent($event)"
               #certUsedResidualsCSV>
      </div>
    </div>
  </section>

  <section *ngIf="!waitOnInit">

    <!-- INCENTIVE GROUPS -->
    <div class="mb-3">
      <app-incentive-group-list [incentiveGroups]="incentiveGroups"
                                [activeGroupIndex]="activeGroupIndex"
                                (selectGroup)="onSelectGroup($event)">
      </app-incentive-group-list>
    </div>

    <!-- EDIT GROUP -->
    <div *ngIf="incentiveGroups[activeGroupIndex]">
      <h2 class="h3 text-center mb-0 mt-4">Edit Incentive Group</h2>
      <app-incentive-group [incentiveGroup]="incentiveGroups[activeGroupIndex]"
                           [waitOnAction]="waitOnAction"
                           (updateGroup)="updateGroup($event)"
                           (removeGroup)="removeGroup()"
                           (flagUnsavedChanges)="unsavedChanges = true">
      </app-incentive-group>
    </div>

    <!-- ADD GROUP -->
    <div *ngIf="newGroup">
      <h2 class="h3 text-center mb-0 mt-4">Add Incentive Group</h2>
      <app-incentive-group [isNewGroup]="true"
                           [waitOnAction]="waitOnAction"
                           (createGroup)="createGroup($event)"
                           (flagUnsavedChanges)="unsavedChanges = true">
      </app-incentive-group>
    </div>

  </section>

</div>
// selectors.ts
import { createSelector } from "@ngrx/store";
import { AppState } from "../state";
import { FinancingSettings, GlobalSettings, Vehicle } from "../../models";
import { ClearpathState } from "../state/clearpath";

export const selectClearpath = (state) => state.clearpath;
export const selectClearpathApp = createSelector(selectClearpath, state => state.app);

export const selectVehicles = createSelector(
  selectClearpath,
  state => state.vehicles
);

export const selectHistory = createSelector(
  selectClearpath,
  state => state.dealHistory
);

export const selectHistories = createSelector(
  selectClearpath,
  state => state.dealHistories
);

export const selectSearchString = createSelector(
  selectClearpathApp,
  (state: AppState) => state.searchString
);
export const selectWriteupListLength = createSelector(
  selectClearpathApp,
  (state: AppState) => state.writeupListLength
);
export const selectZipTaxTable = createSelector(
  selectClearpathApp,
  (state: AppState) => state.zipTaxTable
);
export const selectFormServer = createSelector(
  selectClearpathApp,
  (state: AppState) => state.formServer
);
export const selectPrintDeals = createSelector(
  selectClearpathApp,
  (state: AppState) => state.printDeals
);
export const selectDealer = createSelector(
  selectClearpathApp,
  (state: AppState) => state.dealer
);
export const selectDefaultDealerVehiclePhotoUrl = createSelector(
  selectClearpathApp,
  (state: AppState) => state.dealer.displayDefaults.vehiclePhotoUrl
);
export const selectSelectedManagerId = createSelector(
  selectClearpathApp,
  (state: AppState) => state.selectedManagerId
);
export const selectLinks = createSelector(
  selectClearpathApp,
  (state: AppState) => state.links
);
export const selectMenuItems = createSelector(
  selectClearpathApp,
  (state: AppState) => state.links.menuItems
);
export const selectVehicle = createSelector(
  selectClearpathApp,
  (state: AppState) => state.vehicle
);
export const selectInsuranceProducts = createSelector(
  selectVehicle,
  (vehicle: Vehicle) => vehicle.insuranceProducts
);
export const selectFinancing = createSelector(
  selectClearpathApp,
  (state: AppState) => state.financing
);
export const selectCreditTiers = createSelector(
  selectFinancing,
  (state: FinancingSettings) => state.creditTiers
);
export const selectGlobalSettings = createSelector(
  selectClearpathApp,
  (state: AppState) => state.globalSettings
);
export const selectFuelCostPerGallon = createSelector(
  selectGlobalSettings,
  (state: GlobalSettings) => state.fuelCostPerGallon
);
export const selectAllDeals = createSelector(
  selectClearpath,
  (state: ClearpathState) => state.deals.deals || []
);
export const selectDealsLoadingStatus = createSelector(
  selectClearpath,
  (state: ClearpathState) => state.deals.loading
);
export const selectNewResiduals = createSelector(
  selectClearpathApp,
  (state: AppState) => state.newResiduals
);
export const selectCertUsedResiduals = createSelector(
  selectClearpathApp,
  (state: AppState) => state.certUsedResiduals
);

<section
  class="cp-accordion-header"
  [ngClass]="{ 'cp-accordion-header--open': showDetails }"
  (click)="onToggleDetails()">
  <div class="d-flex justify-content-between small mb-3">
    <div class="d-flex align-items-center">
      <i class="material-icons app-bg-light rounded mr-1">{{showDetails ? "keyboard_arrow_up" : "keyboard_arrow_down"}}</i>
      <span style="margin-right:10px;">Vehicle Price</span>
      <div
        *ngIf="showCostOfOwnershipLink"
        class="cost-of-ownership"
        (click)="viewCostOfOwnership($event)">5 Year Cost of Ownership
      </div>
    </div>
    <span *ngIf="vehicle.msr && btSavings() > 0" style="text-align: right">
      Market Price: {{ vehicle.msr | currency: "USD":"symbol":"1.0-0" }}
    </span>
  </div>

  <div class="d-flex justify-content-between">
    <h2 class="h5 mr-4 mb-0">{{ vehicleName }}</h2>
    <span class="h5 mb-0">
      {{
      retail
        ? (retail - selectedCashIncentivesTotal
          | currency: "USD":"symbol":"1.0-0")
        : "---"
      }}
    </span>
  </div>
</section>

<section *ngIf="showDetails" class="cp-accordion-content p-4">
  <div
    *ngIf="btSavings() > 0"
    class="d-flex justify-content-between app-text-neutral mb-3"
  >
    <span>{{isUsed ? 'Market Price' : 'MSRP'}}</span>
    <span>{{ msrp | currency: "USD":"symbol":"1.0-0" }}</span>
  </div>

  <div *ngIf="!isUsed" class="d-flex justify-content-between mb-3">
    <div>
      <div
        class="app-text-secondary"
        style="cursor: pointer"
        (click)="onViewIncentives()">
        Factory Offers
      </div>

      <div
        *ngFor="let incentive of selectedIncentives"
        class="p-0 m-0 app-text-neutral"
        style="line-height: 1.2"
      >
        <small *ngIf="incentive.value">{{ incentive.type }}</small>
      </div>
    </div>
    <div>
      <span class="app-text-neutral">{{
        selectedCashIncentivesTotal | currency: "USD":"symbol":"1.0-0"
        }}</span>
      <div
        *ngFor="let incentive of selectedIncentives"
        class="p-0 m-0 app-text-neutral"
        style="line-height: 1.2"
      >
        <small *ngIf="incentive.value" class="float-right d-none">{{
          incentive.value | currency: "USD":"symbol":"1.0-0"
          }}</small>
      </div>
    </div>
  </div>

  <div
    *ngIf="btSavings() > 0"
    class="d-flex justify-content-between app-text-neutral mb-3"
  >
    <span>Beaverton Toyota Savings</span>
    <span>{{ btSavings() | currency: "USD":"symbol":"1.0-0" }}</span>
  </div>

  <div *ngFor="let dealerAcc of dealerAccessories">
    <ng-container *ngIf="selectedDealerAccessories && selectedDealerAccessories.includes(dealerAcc.name)">
      <div class="d-flex justify-content-between app-text-neutral mb-3">
        <span>{{ dealerAcc.name }}</span>
        <span>{{ dealerAcc.price | currency: "USD":"symbol":"1.0-0" }}</span>
      </div>
    </ng-container>
  </div>

  <!-- Issue #1981 - Remove this row from presentation
    <div class="d-flex justify-content-between mb-3">
    <strong>CLEAR Vehicle Price</strong>
    <span>{{
      baseVehiclePrice$ | async | currency: "USD":"symbol":"1.0-0"
      }}</span>
  </div> -->

  <div class="small font-weight-bold mb-3">Featured Accessories</div>

  <div *ngIf="featuredAccessories.length === 0">No Products Set.</div>

  <div class="row mb-3">
    <app-accessory-item
      *ngFor="let accessory of featuredAccessories; let i = index"
      class="col-sm-6 mb-3"
      [accessory]="accessory"
      [selectedDealAccessory]="selectedDealAccessory(accessory,selectedAccessories)"
      [itemIndex]="i"
      [inCart]="accessoryInCart(accessory) && isPaintAccessoryEnabled(accessory,selectedAccessories)">
    </app-accessory-item>
  </div>

  <div class="d-flex justify-content-between small mb-3">
    <strong>More Accessories</strong>
    <a
      [href]="catalogUrl"
      class="app-text-secondary"
      (click)="dispatchCatalogEvent()"
      target="_blank"
    >
      Catalog
    </a>
  </div>

  <div class="mb-3">
    <app-add-accessory (addAccessory)="onAddAccessory($event)">
    </app-add-accessory>
  </div>

  <ng-container *ngFor="let accessory of selectedAccessories">
    <ng-container *ngIf="!accessory.disabled">
      <div
        class="d-flex align-items-start justify-content-between mb-3">
        <div class="d-flex align-items-start mr-2">
          <button
            class="btn app-btn d-flex align-items-center border-0 p-0 mr-2"
            style="margin-top: 0.125rem"
            type="button"
            (click)="onRemoveAccessory(accessory)">
            <img src="/static/images/remove-accessory.png" alt="remove-accessory" height="20" width="20">
            <i class="material-icons"></i>
          </button>
          <span>{{ accessory.name }}</span>
        </div>
        <div>{{ accessory.price | currency: "USD":"symbol":"1.0-0" }}</div>
      </div>
    </ng-container>
  </ng-container>

  <div class="d-flex justify-content-between">
    <strong>Customized Vehicle Price</strong>
    <span>{{
      customizedVehiclePrice$ | async | currency: "USD":"symbol":"1.0-0"
      }}</span>
  </div>
</section>

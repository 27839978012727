import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import * as appSelectors from "../store/selectors/clearpath";
import * as vehicleActions from "../store/actions/vehicle.actions";
import { Store } from "@ngrx/store";
import { FinancingSettings, PBSCustomField, Vehicle } from "../models";
import { DealState } from "../store/state";
import { DealIncentive } from "src/app/settings-module/models/incentives";
import { FinanceRate } from "src/app/settings-module/models";
import { pathOr } from "ramda";
import { DealerAccessory } from "../models/vehicle";
import { CalculationUtilityService } from "./calculations/calculation-utility.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class VehicleService {

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private calcHelpers: CalculationUtilityService,
  ) { }

  // CONSTANTS

  api = "/v1/vehicle";

  routes = {
    get: stockNum => `${this.api}/read/${stockNum}`,
    getAll: `${this.api}/list`,
    update: `${this.api}/update`
  };

  rateTypes = {
    new: "newRates",
    used: "usedRates",
    certified: "certUsedRates"
  };

  // SELECTORS

  selectVehicle(): Observable<any> {
    return this.store.select(appSelectors.selectVehicle);
  }

  selectVehicles(): Observable<Vehicle[]> {
    return this.store.select(appSelectors.selectVehicles);
  }

  selectUnsoldVehicles(): Observable<Vehicle[]> {
    return this.store.select(appSelectors.selectVehicles)
      .pipe(map((vehicles: Vehicle[]) => {
        return vehicles.filter((vehicle: Vehicle) => !vehicle.sold);
      }));
  }

  selectInsuranceProducts(): Observable<any> {
    return this.store.select(appSelectors.selectInsuranceProducts);
  }

  selectNewResiduals(): Observable<any> {
    return this.store.select(appSelectors.selectNewResiduals);
  }

  selectCertUsedResiduals(): Observable<any> {
    return this.store.select(appSelectors.selectCertUsedResiduals);
  }

  // DISPATCH

  // dispatchSetModel(model: VehicleModel){
  //   this.store.dispatch(vehicleActions.setVehicleModel)
  // }

  getVehicle(stockNumber, odometer) {
    this.store.dispatch(vehicleActions.getVehicle(
      {stockNumber, odometer}
    ));
  }

  getCurrentVehicle() {
    this.store.dispatch(vehicleActions.getCurrentVehicle());
  }

  dispatchSetVehicle(vehicle: Partial<Vehicle>) {
    this.store.dispatch(vehicleActions.setVehicle({vehicle}));
  }

  dispatchSetVehicleByLookup(vehicle: Partial<Vehicle>) {
    this.store.dispatch(vehicleActions.setVehicleByLookup({vehicle}));
  }

  dispatchUpdateVehicle(vehicle: Partial<Vehicle>) {
    this.store.dispatch(vehicleActions.updateVehicle({vehicle}));
  }

  dispatchGetAllVehicles() {
    this.store.dispatch(vehicleActions.getAllVehicles());
  }

  dispatchClearVehicle() {
    this.store.dispatch(vehicleActions.clearVehicle());
  }

  dispatchChangeStockNumber(stockNumber, odometer) {
    this.store.dispatch(vehicleActions.changeStockNumber({stockNumber, odometer}));
  }

  // HTTP

  get(stockNum): Observable<any> {
    return this.http.get(this.routes.get(stockNum));
  }

  getAll(): Observable<any> {
    return this.http.get(this.routes.getAll);
  }

  update(vehicle: Partial<Vehicle>): Observable<any> {
    return this.http.post(this.routes.update, vehicle);
  }

  /*
    given a vehicle and odometer, input odometer will overwrite
    the vehicle's odometer and return the vehicle
    if the vehicle does not have odometer
  */
  overwriteOdometer(vehicle, odometer): Observable<any> {
    if (odometer) {
      vehicle.odometer = odometer;
    }
    vehicle.odometer = odometer;
    return this.update(vehicle);
  }

  // HELPERS

  vehicleCondition(vehicle): string {
    const stockNumber = vehicle ? vehicle.stockNumber : false;
    if (!stockNumber) { return ""; }

    const {isCertified, isUsed} = vehicle;
    return isCertified ? "certified" : isUsed ? "used" : "new";
  }

  vehicleName(vehicle): string {
    if (!vehicle) { return null; }
    const {year, make, model, trim} = vehicle;
    const vehicleName = `${year} ${make} ${model}`;
    return trim ? `${vehicleName} ${trim}` : vehicleName;
  }

  calculateVehicleAge(year: number) {
    const now = new Date().getTime();
    const dateYear = new Date(year, 0, 1).getTime();
    const diffMs = now - dateYear;
    const diffSeconds = diffMs / 1000;
    const diffMins = diffSeconds / 60;
    const diffHours = diffMins / 60;
    const diffDays = diffHours / 24;
    const rounded = Math.abs(Math.round(diffDays / 365.25));
    return rounded;
  }

  findInterestRate(
    vehicle: Vehicle,
    deal: DealState,
    financingSettings: Partial<FinancingSettings>,
    term?: number,
    excludeIncentives?: boolean
  ) {
    term = term || deal.financeOptions.selectedFinancingTerm;
    const financeOptions = deal.financeOptions;
    const {selectedCreditTier} = financeOptions;
    const rateType = this.rateTypes[ this.vehicleCondition(vehicle) ];
    const terms = financingSettings[ rateType ] || [];
    let tieredRates = [];
    let filteredTerms = this.calcHelpers.filterRates(vehicle.year, terms);

    // get incentive rate
    if (!excludeIncentives) {
      filteredTerms = this.findIncentiveRate(deal.incentives, term);
    }

    tieredRates = this.getTieredRates(filteredTerms, term);
    const selectedRate = tieredRates[ selectedCreditTier ];
    if (selectedRate === 0) {
      return selectedRate;
    }
    if (typeof selectedRate === "undefined") {
      const filteredTerms = this.calcHelpers.filterRates(vehicle.year, terms);
      tieredRates = this.getTieredRates(filteredTerms, term);
      const selectedRate = tieredRates[ selectedCreditTier ] ? tieredRates[ selectedCreditTier ] : 0;
      return selectedRate;
    }

    return tieredRates[ selectedCreditTier ] || null;
  }

  findIncentiveRate(incentives, term) {
    let filteredRates = [];
    if (incentives) {
      incentives.forEach((dealIncentive: DealIncentive) => {
        const financeRates = pathOr([], ["financeOffer", "financeRates"], dealIncentive);
        if (financeRates) {
          if (financeRates.length > 0) {
            const ratesForTerm = this.findRatesForTerm(dealIncentive, term);
            if (ratesForTerm) {
              const tieredRates = this.getTieredRates(financeRates, term);
              if (tieredRates.length) {
                filteredRates = financeRates;
              }
            }
          }
        }
      });
    }
    return filteredRates;
  }

  findRatesForTerm(incentive: DealIncentive, selectedFinancingTerm: number): FinanceRate {
    return incentive.financeOffer.financeRates
      .find(rate => {
        if (selectedFinancingTerm === rate.term) {
          return true;
        }
        if (selectedFinancingTerm === 71 && rate.term === 72) {
          return true;
        }
      });
  }

  getTieredRates(terms, term) {
    let tieredRates = [];
    for (const item of terms) {
      if (term === 71 && item.term === 72) {
        tieredRates = item.tieredRates;
        break;
      }
      if (item.term === term) {
        tieredRates = item.tieredRates;
        break;
      }
    }
    return tieredRates;
  }

  isHybrid(vehicle) {
    return vehicle.model.toLowerCase().includes("hybrid");
  }

  modelNumberMatch(n1: string, n2: string): boolean {
    return n1 === n2 || n1 === n2.slice(0, 4);
  }

  yearMatch(y1: number | string, y2: number | string): boolean {
    if (typeof y1 === "number") { y1 = y1.toString(); }
    if (typeof y2 === "number") { y2 = y2.toString(); }
    return y1 === y2;
  }

  parsePBSCustomFields(vehicle: Vehicle): DealerAccessory[] {
    const dealerAccessories = {};
    const pricePrefix = "DealerAccPrice";
    const descPrefix = "DealerAccDesc";
    const indexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    if (vehicle.customFields) {
      vehicle.customFields.forEach((customField: PBSCustomField) => {
        let dealerAccIndex = 0;
        const acc: DealerAccessory = {
          name: null,
          price: null
        };
        if (customField.key.includes(pricePrefix)) {
          const dealerAccI = customField.key.split(pricePrefix)[ 1 ];
          if (dealerAccI) {
            dealerAccIndex = parseInt(dealerAccI, 10);
            acc.price = parseFloat(customField.value);
          }
        }
        if (customField.key.includes(descPrefix)) {
          const dealerAccI = customField.key.split(descPrefix)[ 1 ];
          if (dealerAccI) {
            dealerAccIndex = parseInt(dealerAccI, 10);
            acc.name = customField.value;
          }
        }
        if (dealerAccIndex) {
          dealerAccessories[ dealerAccIndex ] = {
            name: pathOr(null, [dealerAccIndex, "name"], dealerAccessories) || "",
            price: pathOr(null, [dealerAccIndex, "price"], dealerAccessories) || 0
          };
        }
        if (acc.name) {
          dealerAccessories[ dealerAccIndex ].name = acc.name;
        }
        if (acc.price) {
          dealerAccessories[ dealerAccIndex ].price = acc.price;
        }
        dealerAccIndex = 0;
      });
    }
    const dealerAccessoriesArray = Object.keys(dealerAccessories).map(dealerAccIndex => {
      return dealerAccessories[ parseInt(dealerAccIndex, 10) ];
    }).filter(dealerAcc => dealerAcc.name);
    return dealerAccessoriesArray;
  }

}

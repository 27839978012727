import { FinanceRate } from "src/app/clearpath-module/models";

export interface ConfigIncentives {
  incentiveGroups: IncentiveGroup[];
}

export interface IncentiveGroup {
  name: string;
  description: string;
  exclusions: string[];
  startDate: string;
  endDate: string;
  groupQualifier: GroupQualifier;
  incentives: Incentive[];
}

export class DefaultIncentiveGroup {
  constructor() { }

  name = "";
  description = "";
  exclusions = [""];
  startDate = "";
  endDate = "";
  groupQualifier: {
    groupName: "",
    groupDescription: "",
    validationRequired: false
  };
  incentives = [];
}

export interface GroupQualifier {
  groupName: string;
  groupDescription: string;
  validationRequired: boolean;
}

export interface Incentive {
  title: string;
  vehicleQualifierList: VehicleQualifier[];
  financeOffer: FinanceOffer;
  leaseOffer: LeaseOffer;
  cashDetails: CashDetails;
  dealerFinancingRequired: boolean;
  combineOffers: boolean;
}

export interface VehicleQualifier {
  year: string;
  make: string;
  model: string;
  modelNumber: string;
  trim: string;
}

export class DefaultVehicleQualifier {
  constructor() { }

  year = "";
  make = "";
  model = "";
  modelNumber = "";
  trim = "";
}

export interface FinanceOffer {
  financeRates: FinanceRate[];
  aprSubventionTiers: number[];
}

export interface LeaseOffer {
  leaseOfferTerms: LeaseOfferTerm[];
}

export interface LeaseOfferTerm {
  term: number;
  tieredLeaseMFs: number[];
  leaseSubvention: number;
  cashAtSigning: 0;
  rcf: number;
}

export class DefaultIncentive implements Incentive {
  constructor() { }

  title = "";
  vehicleQualifierList = [];
  financeOffer = {
    financeRates: [],
    aprSubventionTiers: []
  };
  leaseOffer = {
    leaseOfferTerms: []
  };
  cashDetails = {
    bonusCash: null,
    customerCash: null,
    leaseCash: null,
    financeCash: null,
    militaryRebate: null,
    collegeRebate: null,
    otherRebate: null,
    customerLoyalty500: null,
    customerLoyalty1000: null,
    uberRebate: null
  };
  dealerFinancingRequired: false;
  combineOffers: true;
}

export interface CashDetails {
  customerCash: number;
  bonusCash: number;
  leaseCash: number;
  financeCash: number;
  militaryRebate: number;
  collegeRebate: number;
  otherRebate: number;
  customerLoyalty500: number;
  customerLoyalty1000: number;
  uberRebate: number;
}

export interface DealIncentive {
  incentiveGroupName: string;
  financeOffer?: FinanceOffer;
  financeOfferDisabled?: boolean;
  leaseOffer?: LeaseOffer;
  leaseOfferDisabled?: boolean;
  bonusCash?: number;
  bonusCashDisabled?: boolean;
  customerCash?: number;
  customerCashDisabled?: boolean;
  leaseCash?: number;
  leaseCashDisabled?: boolean;
  financeCash?: number;
  financeCashDisabled?: boolean;
  militaryRebate?: number;
  collegeRebate?: number;
  otherRebate?: number;
  customerLoyalty500?: number;
  customerLoyalty1000?: number;
  uberRebate?: number;
  leaseOfferTermBuffer?: number;
  leaseOfferSelectedBuffer?: boolean;
  aprSubventionSelectedBuffer?: boolean;
  financeOfferSelectedBuffer?: boolean;
}

export interface SelectedIncentive {
  type: string;
  value: number;
}

export interface VehicleIncentive {
  incentiveGroupName: string;
  description: string;
  exclusions: string[];
  startDate: string;
  endDate: string;
  groupQualifier: GroupQualifier;
  incentive: Incentive;
}


export interface ImportIncentiveRaw {
  "Model#": string;
  "Year": string;
  "Description": string;
  "Expires": string;
  "CustCash": string;
  "T1+36APR": string;
  "T1+48APR": string;
  "T1+60APR": string;
  "T1+66APR": string;
  "T1+72APR": string;
  "T1+APRSubvention": string;
  "T1-36APR": string;
  "T1-48APR": string;
  "T1-60APR": string;
  "T1-66APR": string;
  "T1-72APR": string;
  "T1-APRSubvention": string;
  "T2-36APR": string;
  "T2-48APR": string;
  "T2-60APR": string;
  "T2-66APR": string;
  "T2-72APR": string;
  "T2-APRSubvention": string;
  "T3-36APR": string;
  "T3-48APR": string;
  "T3-60APR": string;
  "T3-66APR": string;
  "T3-72APR": string;
  "T3-APRSubvention": string;
  "T1+24MF": string;
  "T124MF": string;
  "T224MF": string;
  "T324MF": string;
  "24moLeaseSubvention": string;
  "T1+36MF": string;
  "T136MF": string;
  "T236MF": string;
  "T336MF": string;
  "36moLeaseSubvention": string;
  "TFSCash": string;
  "College": string;
  "Military": string;
  "BonusCash": string;
}

export interface ImportIncentive {
  "Model#": string;
  "Year": string;
  "Description": string;
  "Expires": string;
  "CustCash": number;
  "T1+36APR": number;
  "T1+48APR": number;
  "T1+60APR": number;
  "T1+66APR": string;
  "T1+72APR": number;
  "T1+APRSubvention": number;
  "T1-36APR": number;
  "T1-48APR": number;
  "T1-60APR": number;
  "T1-66APR": string;
  "T1-72APR": number;
  "T1-APRSubvention": number;
  "T2-36APR": number;
  "T2-48APR": number;
  "T2-60APR": number;
  "T2-66APR": string;
  "T2-72APR": number;
  "T2-APRSubvention": number;
  "T3-36APR": number;
  "T3-48APR": number;
  "T3-60APR": number;
  "T3-66APR": string;
  "T3-72APR": number;
  "T3-APRSubvention": number;
  "T1+24MF": number;
  "T124MF": number;
  "T224MF": number;
  "T324MF": number;
  "24moLeaseSubvention": number;
  "T1+36MF": number;
  "T136MF": number;
  "T236MF": number;
  "T336MF": number;
  "36moLeaseSubvention": number;
  "TFSCash": number;
  "TFSLeaseCash": number;
  "College": number;
  "Military": number;
  "BonusCash": number;
  "CustomerLoyalty500": number;
  "CustomerLoyalty1000": number;
  "UberRebate": number;
}

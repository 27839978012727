<div class="app-page-container">
  <!-- PAGE HEADER -->
  <section class="cp-header-layout my-4 my-md-5">
    <div class="cp-logo">
      <img
        appDealerLogo
        class="w-50"
        style="min-width: 115px; max-width: 500px"
      />
    </div>

    <div class="cp-side-nav">
      <app-side-nav></app-side-nav>
    </div>
  </section>

  <!-- MAIN CONTENT -->
  <section class="cp-body-layout mb-5">
    <div class="cp-title">
      <h1 class="h2 font-weight-light mb-0" style="line-height: 1">
        {{ customerName && customerName.trim().length > 0 ? customerName + "'s " : "" }}{{ vehicleCondition | titlecase }}
        {{ appData.vehicle.make | titlecase }}
      </h1>
    </div>

    <div class="cp-vehicle-info">
      <div class="vehicle-info">
        <app-vehicle-price
          [vehicle]="appData.vehicle"
          [financeOptionsEdits]="dealData.financeOptionsEdits"
        ></app-vehicle-price>
      </div>

      <div class="vehicle-info">
        <app-vehicle-trade-in
          [tradeIn]="dealService.selectTradeIn()|async"
          (TradeInAutoSubmit)="dealService.dispatchSetTradeIn($event)"
          title="Trade-in Vehicle 1">
        </app-vehicle-trade-in>
      </div>

      <div class="vehicle-info">
        <app-vehicle-trade-in
          [tradeIn]="dealService.selectTradeIn2()|async"
          (TradeInAutoSubmit)="dealService.dispatchSetTradeIn2($event)"
          title="Trade-in Vehicle 2">
        </app-vehicle-trade-in>
      </div>

      <div class="vehicle-info">
        <app-down-payment></app-down-payment>
      </div>

      <div class="vehicle-info">
        <app-vehicle-base-finance
          [vehicle]="appData.vehicle"
        ></app-vehicle-base-finance>
      </div>
    </div>

    <div class="cp-vehicle-img mb-4">
      <app-vehicle-image [vehicle]="appData.vehicle"></app-vehicle-image>
    </div>

    <div class="cp-next-btn-bt">
      <button
        class="btn app-btn-secondary"
        type="button"
        (click)="onStartWarrantyPresentation()"
      >
        NEXT
      </button>
    </div>
  </section>

  <div *ngIf="showBuyBoxes(suppressBuyBoxes)" class="mx-xl-5">
    <!-- INSURANCE PRODUCTS -->
    <section class="mb-5">
      <h2 class="text-center mb-3">
        Your <span class="app-text-info" style="font-style: italic;text-decoration: underline;color: #59873d!important;font-weight:700">COMPLETE</span> Ownership Experience
      </h2>
      <app-insurance-menu></app-insurance-menu>
    </section>
    <!-- BUY BOXES -->
    <section class="mb-5">
      <app-buy-box-menu></app-buy-box-menu>
    </section>
  </div>

  <section class="text-center small text-secondary">
    Leasing base payments may recalculate due to equity allocation. All figures presented to be approved by manager.
    Sorry, typographical errors cannot be honored. Payments for illustrative purposes only.
    Please review contract for final payment terms.
  </section>
</div>

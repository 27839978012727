import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../auth-module/services";

import { SalesQueueAdminComponent, SalesQueueDisplayComponent, SalesQueueViewComponent } from "./pages";
import { USER_ROLES } from "../app.config";

const appRoutes: Routes = [
  {
    path: "sales-queue-view",
    component: SalesQueueViewComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.sales_floor]
    }
  },
  {
    path: "sales-queue-display",
    component: SalesQueueDisplayComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.cue_display]
    }
  },
  {
    path: "sales-queue-admin",
    component: SalesQueueAdminComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.cue_admin]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class SalesQueueRoutingModule {}

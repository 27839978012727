<!-- BUYER FORM - SECTION 1 -->
<form [formGroup]="buyerForm">

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    formCtrlName="companyName"
    name="Business Name"
    type="text"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'firstName'"
    [name]="'First Name'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'middleName'"
    [name]="'Middle Name'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'lastName'"
    [name]="'Last Name'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'street'"
    [name]="'Address'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'city'"
    [name]="'City'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'county'"
    [name]="'County'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'state'"
    [name]="'State'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'zip'"
    [name]="'Zip'"
    [minLength]="5"
    [maxLength]="5"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'phone'"
    [name]="'Phone'"
    [minLength]="10"
    [maxLength]="10"
    [type]="'text'"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'email'"
    [name]="'Email'"
    [type]="'text'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <div [ngClass]="{ 'border-danger': touchedInvalid('birthdate') }">
    <app-simple-input
      [parentForm]="buyerForm"
      [forceUpdate]="forceChildFormUpdate"
      [formCtrlName]="'birthdate'"
      [name]="'DOB'"
      [type]="'date'"
      [maxLength]="REQS.maxLength.standard"
      [blur]="autoSubmitBuyerForm"
      [keydown]="flagChange"
      [required]="validationOn">
    </app-simple-input>
  </div>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'driverId'"
    [name]="'Driver License #'"
    [type]="'string'"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <!-- CREDIT & VEHICLE NEEDS FORMS - CUSTOMER ONLY -->
  <app-credit-tier-select
    *ngIf="buyerIsCustomer"
    [change]="autoSubmitFinanceOptionsForm"
    [formCtrlName]="'selectedCreditTier'"
    [parentForm]="financeOptionsForm">
  </app-credit-tier-select>

  <app-vehicle-needs-form
    *ngIf="buyerIsCustomer"
    [change]="autoSubmitVehicleNeedsForm"
    [parentForm]="vehicleNeedsForm">
  </app-vehicle-needs-form>

  <app-simple-input
    *ngIf="this.buyerIsCustomer"
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="financeOptionsForm"
    [formCtrlName]="'salesTax'"
    [name]="'Sales Tax'"
    [type]="'text'"
    [blur]="autoSubmitFinanceOptionsForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

  <app-simple-input
    [parentForm]="buyerForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'dmsContactId'"
    [name]="'DMS ID'"
    [type]="'text'"
    [blur]="autoSubmitBuyerForm"
    [keydown]="flagChange"
    [required]="validationOn">
  </app-simple-input>

</form>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import * as vehicleActions from "../../store/actions/vehicle.actions";
import * as appActions from "../../store/actions/app.actions";
import { Router } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { AlertService, CanComponentDeactivate } from "src/app/shared-module/services";
import { DealService, VehicleService, WarrantyUiService } from "../../services";
import { takeUntil, tap } from "rxjs/operators";
import { DealState } from "../../store/state";
import { DEAL_STATUSES } from "src/app/app.config";
import { DealIncentivesService } from "../../services/deal/deal-incentives.service";
import { HistoryService } from "../../services/history.service";
import { DealIncentive } from "../../../settings-module/models/incentives";

@Component({
  selector: "app-clearpath",
  templateUrl: "./clearpath.component.html",
  styleUrls: ["./clearpath.component.scss"]
})
export class ClearpathComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new Subject();
  private uiState = {
    warrantyPresentationComplete: false,
    downPaymentReset: false,
    warrantyUiReset: false,
    incentivesAssigned: false
  };
  suppressBuyBoxes = false;
  dealData = {
    customer: null,
    financeOptions: null,
    leaseOptions: null,
    financeOptionsEdits: null,
    status: ""
  };
  appData = {vehicle: null};

  private initialEventRecorded = false;

  constructor(
    public dealService: DealService,
    private router: Router,
    private actions$: Actions,
    private alertService: AlertService,
    private historyService: HistoryService,
    private vehicleService: VehicleService,
    private warrantyUiService: WarrantyUiService,
    private incentivesService: DealIncentivesService
  ) { }

  ngOnInit() {
    this.subToStoreData();
    this.subToActionFailure();
    this.subToWarrantyUiStatus();
  }

  canDeactivate() {
    this.dealService.dispatchChangeDeal();
    return true;
  }

  ngOnDestroy() {
    this.dealService.dispatchAddEvent({shortName: "Presentation Page Exited"});
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.dealService.dispatchClearDeal();
  }

  // INITIALIZATION

  private subToStoreData() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle: any) => {
        this.appData.vehicle = vehicle;
        if (vehicle.stockNumber) {
          if (vehicle.stockNumber[ 0 ] === "D" && vehicle.stockNumber[ 1 ] === "T") {
            this.dealService.dispatchApplyInitialInsuranceProducts();
          }
          this.dealService.applyInitialDefaults();
        }
      });

    this.dealService.selectDeal()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((newDeal: DealState) => {
          if (newDeal.dealId && !this.initialEventRecorded) {
            this.initialEventRecorded = true;
            this.historyService.dispatchOverviewPage();
          }
          const dealIdChanged = this.warrantyUiService.activeDealId !== newDeal.dealId;
          // set warrantyui
          if (dealIdChanged && !this.uiState.warrantyUiReset) {
            this.uiState.warrantyUiReset = true;
            this.warrantyUiService.resetWarrantyUi();
          }

          // set default incentives
          if (
            dealIdChanged &&
            !this.uiState.incentivesAssigned &&
            this.appData.vehicle.incentives.length
          ) {
            this.uiState.incentivesAssigned = true;
            this.incentivesService.assignDefaultIncentives();
          }

          // sometimes, vehicle won't have a retail price
          if (dealIdChanged) {
            if (this.appData.vehicle.retail === 0) {
              this.appData.vehicle.retail = this.appData.vehicle.msr;
            }
          }
        })
      )
      .subscribe((newDeal: DealState) => {
        const {customer, financeOptions, leaseOptions, status, financeOptionsEdits} = newDeal;

        // check to see if the lease term buffer is set and suppress buy boxes accordingly
        let suppressBuyBoxes = false;
        if (this.uiState.warrantyPresentationComplete && newDeal.incentives) {
          // console.log("incentives", newDeal.incentives)
          newDeal.incentives.forEach(incentive => {
            if (incentive.leaseOfferSelectedBuffer && incentive.leaseOfferTermBuffer) {
              // console.log("Found buffer!", incentive.leaseOfferSelectedBuffer, incentive.leaseOfferTermBuffer)
              //this.IncentiveService.onUpdateDealIncentive(incentive, this.incentives);
              this.dealService.setLeasingTerm(incentive.leaseOfferTermBuffer);
              this.dealService.dispatchSetDealType("lease");
              incentive.leaseOfferSelectedBuffer = false;
              incentive.leaseOfferTermBuffer = null;
            }
            if (incentive.financeOfferSelectedBuffer) {
              this.dealService.dispatchSetDealType("finance");
              incentive.financeOfferSelectedBuffer = false;
            }
          });
        }

        if (newDeal.incentives) {
          newDeal.incentives.forEach((incentive: DealIncentive) => {
              if (incentive.leaseOfferSelectedBuffer || incentive.financeOfferSelectedBuffer) suppressBuyBoxes = true;
            }
          );
        }
        this.suppressBuyBoxes = suppressBuyBoxes;
        // console.log("suppressBuyBoxes", this.suppressBuyBoxes)

        this.dealData = {customer, financeOptions, leaseOptions, status, financeOptionsEdits};
        // Navigate straight to confirm if status is "updated"
        if (
          this.dealData.status === DEAL_STATUSES.Updated ||
          this.dealData.status === DEAL_STATUSES.Approved ||
          this.dealData.status === DEAL_STATUSES.Printed ||
          this.dealData.status === DEAL_STATUSES.Printing ||
          this.dealData.status === DEAL_STATUSES.Completed ||
          this.dealData.status === DEAL_STATUSES.Submitted
        ) {
          this.router.navigate([{outlets: {modal: "submit-writeup"}}]);
        }
      });
  }

  private subToActionFailure() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(vehicleActions.getVehicleFailure),
      ofType(appActions.linksApiFailure)
    ).subscribe(action => {
      this.alertService.error(action.error);
    });
  }

  private subToWarrantyUiStatus() {
    this.warrantyUiService.presentationCompleteObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((complete: boolean) => {
        this.uiState.warrantyPresentationComplete = complete;
        /*if (complete) {
          this.historyService.dispatchPaymentOptionPresented();
        }*/
      });
  }

  // UI CONTROL & RENDERING

  onStartWarrantyPresentation() {
    this.router.navigate([{outlets: {modal: "warranty"}}]);
  }

  get vehicleCondition(): string {
    return this.vehicleService.vehicleCondition(this.appData.vehicle);
  }

  get customerName(): string {
    const {firstName, lastName} = this.dealData.customer;
    return `${firstName} ${lastName}`;
  }

  showBuyBoxes(suppress: boolean): boolean {
    //console.log("showBuyBoxes: ", this.dealData, this.uiState.warrantyPresentationComplete, this.dealData)
    const {warrantyPresentationComplete} = this.uiState;
    if (!this.dealData) return;
    const leaseOrFinancingSelected = this.dealData.financeOptions.selectedFinancingTerm ||
      this.dealData.leaseOptions.selectedLeaseTerm ||
      this.dealData.financeOptions.cashPurchase ||
      this.dealData.financeOptions.customSelected;
    const show = !suppress && (leaseOrFinancingSelected || warrantyPresentationComplete);
    if (show) {
      this.uiState.warrantyPresentationComplete = true;
    }
    return show;
  }
}

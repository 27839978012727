import { ActionReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { AES, enc } from 'crypto-js';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const localStorageKey = 'O1O4E7Z6F7T9P1Q1'
  const encryptKey = state => {
    try {
      const value = AES.encrypt(state, localStorageKey)?.toString()
      return value || ''
    } catch (e) {
      // console.error(e)
      return ''
    }
  }

  const decryptKey = state => {
    try {
      const value = AES.decrypt(state, localStorageKey)?.toString(enc.Utf8)
      return value || ''
    } catch (e) {
      // console.error(e)
      return ''
    }
  }

  return localStorageSync({
    keys: [
      {
        "auth": {
          encrypt: encryptKey,
          decrypt: decryptKey,
        }
      }
    ],
    rehydrate: true,
    removeOnUndefined: true,
  })(reducer);
}

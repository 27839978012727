import { Component, OnInit } from "@angular/core";
import { SettingsService } from "../../services";
import { AlertService } from "src/app/shared-module/services";
import { InsuranceProduct } from "../../models";

@Component({
  selector: "app-insurance-products",
  templateUrl: "./insurance-products.component.html",
  styleUrls: ["./insurance-products.component.scss"]
})
export class InsuranceProductsComponent implements OnInit {
  private uiState = {
    waitOn: {load: true, action: false},
    insuranceProducts: [],
    activeProductIndex: null,
    newProduct: false
  };

  constructor(
    private alertService: AlertService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.getInsuranceProducts();
  }

  // DATA ACCESS

  private getInsuranceProducts() {
    this.settingsService.getInsuranceProducts()
      .subscribe({
        next: res => {
          const data: InsuranceProduct[] = res.insuranceProducts || [];
          this.uiState.insuranceProducts = data;
          this.uiState.waitOn.load = false;
        },
        error: error => {
          this.uiState.waitOn.load = false;
          this.alertService.error(error);
        }
      });
  }

  private setInsuranceProducts(insuranceProducts: InsuranceProduct[], actionVerb: string) {
    this.uiState.waitOn.action = true;

    this.settingsService.setInsuranceProducts(insuranceProducts)
      .subscribe({
        next: res => {
          const data: InsuranceProduct[] = res.insuranceProducts || [];
          this.uiState.insuranceProducts = data;
          this.uiState.activeProductIndex = null;
          this.uiState.newProduct = false;
          this.uiState.waitOn.action = false;
          this.alertService.success(`Insurance Product ${actionVerb}`);
          window.scroll({top: 0, behavior: "smooth"});
        },
        error: error => {
          this.uiState.waitOn.action = false;
          this.alertService.error(error);
          window.scroll({top: 0, behavior: "smooth"});
        }
      });
  }

  // ACTIONS

  createProduct(product: InsuranceProduct) {
    const newInsuranceProducts: InsuranceProduct[] = this.insuranceProducts.slice();
    newInsuranceProducts.push(product);
    this.setInsuranceProducts(newInsuranceProducts, "Created");
  }

  updateProduct(product: InsuranceProduct) {
    const newInsuranceProducts: InsuranceProduct[] = this.insuranceProducts.slice();
    newInsuranceProducts[ this.activeProductIndex ] = product;
    this.setInsuranceProducts(newInsuranceProducts, "Updated");
  }

  removeProduct() {
    const newInsuranceProducts: InsuranceProduct[] = this.insuranceProducts.slice();
    newInsuranceProducts.splice(this.activeProductIndex, 1);
    this.setInsuranceProducts(newInsuranceProducts, "Removed");
  }

  runEthosImporter() {
    this.uiState.waitOn.load = true;
    this.settingsService.runEthosImporter().subscribe({
      next: res => {
        this.alertService.success("Ethos importer now running in background. Results will take a few minutes.");
        this.uiState.waitOn.load = false;
      },
      error: error => {
        this.uiState.waitOn.load = false;
        this.alertService.error(error);
        window.scroll({top: 0, behavior: "smooth"});
      }
    });
  }

  runPbsUserImporter() {
    this.uiState.waitOn.load = true;
    this.settingsService.runPbsUserImporter().subscribe({
      next: res => {
        this.alertService.success("PBS user importer now running in background. Results will take a few minutes.");
        this.uiState.waitOn.load = false;
      },
      error: error => {
        this.uiState.waitOn.load = false;
        this.alertService.error(error);
        window.scroll({top: 0, behavior: "smooth"});
      }
    });
  }

  runPbsImporter() {
    this.uiState.waitOn.load = true;
    this.settingsService.runPbsImporter().subscribe({
      next: res => {
        this.alertService.success("PBS importer now running in background. Results will take a few minutes.");
        this.uiState.waitOn.load = false;
      },
      error: error => {
        this.uiState.waitOn.load = false;
        this.alertService.error(error);
        window.scroll({top: 0, behavior: "smooth"});
      }
    });
  }

  // UI CONTROL & RENDERING

  onSelectAddProduct() {
    this.uiState.activeProductIndex = null;
    this.uiState.newProduct = true;
    this.alertService.clear();
  }

  onSelectProduct(index: number) {
    this.uiState.activeProductIndex = index;
    this.uiState.newProduct = false;
    this.alertService.clear();
  }

  get insuranceProducts(): InsuranceProduct[] {
    return this.uiState.insuranceProducts || [];
  }

  get activeProductIndex(): number {
    return this.uiState.activeProductIndex;
  }

  get waitOnLoad(): boolean {
    return this.uiState.waitOn.load;
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOn.action;
  }

  get newProduct(): boolean {
    return this.uiState.newProduct;
  }

}

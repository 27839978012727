import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { combineLatest, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { AppService, CalculationUtilityService, CalculationService, DealService, LeaseCalculationService, VehicleService } from "../../services";
import { Accessory, FinanceOptions, FinanceRate, InsuranceProduct, TradeIn, Vehicle } from "../../models";
import { LeaseOptions } from "../../models";
import { DealIncentive, FinanceOffer, LeaseOffer } from "src/app/settings-module/models/incentives";
import { DealIncentivesService } from "../../services";
import { CAT_TAX_RATE, USER_ROLES, WASHINGTON_SALES_TAX } from "src/app/app.config";
import { pathOr } from "ramda";
import { DealState } from "../../store/state";
import html2canvas from "html2canvas";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { User } from "../../../user-admin-module/models";
import { AuthService } from "../../../auth-module/services";
import { formatCurrency } from "@angular/common";
import { formatPhoneNumber } from "../../../util/angularUtils";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-buy-box-menu",
  templateUrl: "./buy-box-menu.component.html",
  styleUrls: ["./buy-box-menu.component.scss"]
})
export class BuyBoxMenuComponent implements OnInit, OnDestroy {
  @ViewChild('gapQuestionModal') gapQuestionModal: ElementRef;
  @ViewChild('downloadButton') downloadButton: ElementRef;
  private unsubscribe$ = new Subject();
  private RESTRICTED_TERMS = [39, 42];
  private currentUser: User;
  private uiState = {
    displayTerms: {
      financing: {},
      leasing: {},
      other: {}
    }
  };
  private appData = {
    financeSettings: {
      newRates: null,
      usedRates: null,
      certUsedRates: null,
      financeDefault: null,
      leaseDefault: null
    },
    vehicle: null,
    vehicleCondition: null,
    incentives: []
  };
  private dealData = {
    deal: null,
    accessories: null,
    financeOptions: null,
    financeOptionsEdits: null,
    leaseOptions: null,
    vehicleNeeds: null,
    insuranceProducts: null,
    tradeIn: null,
    leaseResiduals: null,
    incentives: null,
    selectedCashIncentivesTotal: 0,
    salesTax: 0,
    customer: null,
    displayTerms: null
  };
  existingStockNumber?: string;
  existingDisplayTerms?: any;
  findInterestRate$;
  findStandardInterestRate$;
  disabledProducts: InsuranceProduct[] = [];
  gapQuestionProduct?: InsuranceProduct;
  financingTermRates: FinanceRate[] = [];
  defaultFinancingTerms: number[] = [];
  base64: any;
  logoImage: any;
  docFeesForm: FormGroup = this.formBuilder.group({
    docFees: [0, Validators.min(0)],
  });
  taxRateForm: FormGroup = this.formBuilder.group({
    taxRatePercentage: [0, Validators.min(0)],
  });

  constructor(
    private appService: AppService,
    private calculationService: CalculationService,
    private leaseCalculationService: LeaseCalculationService,
    private calcHelpers: CalculationUtilityService,
    private incentivesService: DealIncentivesService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {

    console.log("init buy boxes");

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    this.findInterestRate$ = this.calculationService.findInterestRate$;
    this.findStandardInterestRate$ = this.calculationService.findStandardInterestRate$;
    this.subToStoreData();

    // These images are used in the payment plans PDF (if needed)
    this.convertImageToBase64('/static/images/BeavertonToyota_InsideBrochure.jpg').then(
      (base64) => {

        this.convertImageToBase64('/static/images/BT_PDF_Logo.jpg').then(
          (logoImage) => {
            this.base64 = base64;
            this.logoImage = logoImage;
            this.focusDownloadButton();
          });
      });

    this.focusDownloadButton();
  }

  focusDownloadButton() {
    const button = this.downloadButton?.nativeElement as any;
    if (button) {
      button.focus();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleGapQuestion(product: InsuranceProduct) {
    this.gapQuestionProduct = product;
    ($(this.gapQuestionModal.nativeElement) as any).modal('show');
  }

  handleGapQuestionContinued() {
    ($(this.gapQuestionModal.nativeElement) as any).modal('hide');
    if (this.gapQuestionProduct)
      this.dealService.dealInsuranceService.removeInsuranceProduct(this.gapQuestionProduct);
  }

  saveDisplayTerms() {
    this.dealService.dispatchSetDisplayTerms(this.uiState.displayTerms);
    this.existingDisplayTerms = this.uiState.displayTerms;
  }

  downpaymentPercent = 0;

  // INITIALIZATION

  private subToStoreData() {
    combineLatest([
      this.dealService.selectDeal(),
      this.calculationService.totalVehicleFinancePrice$({withoutDaysToFirstPay: true})
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, baseFinanceAmount]: [DealState, number]) => {
        this.existingDisplayTerms = this.existingDisplayTerms || this.dealData?.displayTerms;
        this.dealData.deal = deal;
        this.dealData.financeOptions = deal.financeOptions;
        this.dealData.financeOptionsEdits = deal.financeOptionsEdits;
        this.dealData.salesTax = deal.financeOptions.salesTax || deal.financeOptions.privilegeTax;
        this.updateDocFeesForm();
        this.updateTaxRateForm();
        this.dealData.leaseOptions = deal.leaseOptions;
        this.dealData.tradeIn = deal.tradeIn;
        this.dealData.displayTerms = this.existingDisplayTerms || deal.displayTerms;
        this.existingDisplayTerms = this.existingDisplayTerms || this.dealData?.displayTerms;
        this.dealData.accessories = deal.accessories;
        this.dealData.vehicleNeeds = deal.vehicleNeeds;
        this.dealData.customer = deal.customer;
        this.dealData.incentives = deal.incentives || [];
        const {adjustedPrice, incentivesApplied} = this.incentivesService.applyCashIncentives({
          price: 0,
          incentives: this.dealData.incentives,
          leaseOptions: this.dealData.leaseOptions,
          leaseSelected: this.dealData.leaseOptions.leaseSelected,
          financeOptions: this.dealData.financeOptions
        });
        this.dealData.selectedCashIncentivesTotal = Math.abs(adjustedPrice);
      });

    combineLatest([
      this.vehicleService.selectVehicle(),
      this.appService.selectFinancing()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([vehicle, financingSettings]) => {
        this.appData.incentives = vehicle.incentives;
        this.appData.financeSettings = financingSettings;
        this.updateDocFeesForm();
        this.appData.vehicle = vehicle || {};
        this.appData.vehicleCondition = this.vehicleService.vehicleCondition(this.vehicle);
        const types = {new: "newRates", used: "usedRates", certified: "certUsedRates"};
        const rateType = types[ this.appData.vehicleCondition ];
        const rates = this.appData.financeSettings[ rateType ] || [];
        this.setDefaultFinancingTerms(financingSettings.financeDefault, this.appData.vehicleCondition);

        // only reset display terms if the stock number has changed or if the dealData.displayTerms is null
        if (!this?.existingDisplayTerms?.financing || this.existingStockNumber != this.appData.vehicle.stockNumber) {
          this.setDefaultTermsDisplay();
          this.existingStockNumber = this.appData.vehicle.stockNumber;
        }

        this.financingTermRates = this.calcHelpers.filterRates(this.vehicle.year, rates);
        this.getLeaseResiduals();
      });

    this.dealService.dealInsuranceService.selectInsuranceProducts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((insuranceProducts: InsuranceProduct[]) => {
        this.dealData.insuranceProducts = Object.assign([], insuranceProducts);
      });

    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });
  }

  // findIncentiveRate(term) {
  //   const result = this.incentives.filter((incentive: Incentive) => {
  //     const financeRates: FinanceRate[] = pathOr(
  //       [], ["financeOffer", "financeRates"], incentive
  //     );
  //     const selectedCreditTier = this.financeOptions.selectedCreditTier;
  //     const foundRate = financeRates.find((rate: FinanceRate) => {
  //       if (rate.term === term) {
  //         const result = pathOr(null, ["tieredRates", selectedCreditTier], rate) !== null;
  //         return result;
  //       }
  //       if (term === 71 && rate.term === 72) {
  //         return true;
  //       }
  //     });
  //     return pathOr(null, ["tieredRates", selectedCreditTier], foundRate);
  //   });
  //   return result.length ? 0 : result[2];
  // }

  findIncentiveRate(term) {
    const vehicleIncentive = pathOr(null, ["appData", "vehicle", "incentives", 0, "incentive"], this);
    const financeRates: FinanceRate[] = pathOr(
      [], ["financeOffer", "financeRates"], vehicleIncentive
    );
    const selectedCreditTier = this.financeOptions.selectedCreditTier;
    const foundRate = financeRates.find((rate: FinanceRate) => {
      if (rate.term === term) {
        const result = pathOr(null, ["tieredRates", selectedCreditTier], rate) !== null;
        return result;
      }
      if (term === 71 && rate.term === 72) {
        return true;
      }
    });
    return pathOr(null, ["tieredRates", selectedCreditTier], foundRate);

  }

  getLeaseResiduals() {
    this.leaseCalculationService.getLeaseResiduals()
      .pipe(take(1))
      .subscribe((leaseResiduals: []) => {
        this.dealData.leaseResiduals = leaseResiduals || [];
      });
  }

  applyStandardRate() {
    const incentive: DealIncentive = this.dealData.incentives[ 0 ];
    incentive.financeOffer = {
      aprSubventionTiers: [],
      financeRates: []
    };
    const vehicleIncentive = this.appData.vehicle.incentives[ 0 ];
    incentive.customerCash = vehicleIncentive.incentive.cashDetails.customerCash || 0;
    incentive.customerCashDisabled = false;
    incentive.bonusCash = vehicleIncentive.incentive.cashDetails.bonusCash || 0;
    incentive.bonusCashDisabled = false;
    incentive.leaseCash = vehicleIncentive.incentive.cashDetails.leaseCash || 0;
    incentive.leaseCashDisabled = true;
    incentive.financeCash = vehicleIncentive.incentive.cashDetails.financeCash || 0;
    incentive.financeCashDisabled = true;
    this.dealService.dispatchSetFinanceOptions({incentiveSelected: false});
    this.incentivesService.onUpdateDealIncentive(incentive, this.dealData.incentives);
  }

  applyIncentiveRate() {
    const incentive = this.dealData.incentives[ 0 ];
    const vehicleIncentive = this.appData.vehicle.incentives[ 0 ];
    incentive.financeOffer = vehicleIncentive.incentive.financeOffer;
    incentive.customerCashDisabled = true;
    const aprSubventionTiers = pathOr(
      [],
      ["incentives", 0, "financeOffer", "aprSubventionTiers"],
      this.dealData
    );
    if (incentive.financeOffer.financeRates.length) {
      this.dealService.dispatchSetFinanceOptions({incentiveSelected: true});
    }
    this.incentivesService.onUpdateDealIncentive(incentive, this.dealData.incentives);
  }

  setDefaultTermsDisplay() {
    //console.log("Resetting Default Terms...", this.existingDisplayTerms);
    this.uiState.displayTerms.financing = {};
    this.uiState.displayTerms.leasing = {};

    this.defaultFinancingTerms.forEach((term: number) => {
      this.uiState.displayTerms.financing[ term ] = true;
    });

    this.leasingTermsShown.forEach((term: number) => {
      this.uiState.displayTerms.leasing[ term ] = true;
    });

    // if a financing term was selected in Desking, ensure that the buy box is available in Presentation
    if (this.dealData.financeOptions.financeSelected && this.dealData.financeOptions.selectedFinancingTerm) {
      this.uiState.displayTerms.financing[ this.dealData.financeOptions.selectedFinancingTerm ] = true;
    }

    const selectedLeasingTerm = this.dealData.leaseOptions.selectedLeaseTerm;
    if (!Object.keys(this.uiState.displayTerms.leasing)
      .includes(selectedLeasingTerm)) {
      this.leasingTermsShown.push();
      this.uiState.displayTerms.leasing[ selectedLeasingTerm ] = true;
    }
  }

  // UI CONTROL & RENDERING

  hasRateForCreditTier(termRate: FinanceRate) {
    const rate = pathOr(false, ["tieredRates", this.financeOptions.selectedCreditTier], termRate);
    return rate;
  }

  onToggleTerm(type: string, id: string) {
    this.uiState.displayTerms[ type ][ id ] = !this.uiState.displayTerms[ type ][ id ];
    this.saveDisplayTerms();
  }

  displayTerm(type: string, id: string): boolean {
    return this.uiState.displayTerms[ type ][ id ] || false;
  }

  isNewOrCertVehicle(): boolean {
    return this.appData.vehicleCondition === "new" || this.appData.vehicleCondition === "certified";
  }

  disabledProductsCatch(disabledProducts: InsuranceProduct[]) {
    this.disabledProducts = disabledProducts;
  }

  get vehicle() {
    return this.appData.vehicle || {};
  }

  setDefaultFinancingTerms(financeDefault, vehicleCondition) {
    const types = {new: "newTermsShown", used: "usedTermsShown", certified: "certTermsShown"};
    const rateType = types[ vehicleCondition ];
    this.defaultFinancingTerms = financeDefault[ rateType ] || [];
  }

  get dealInsuranceProducts() {
    return this.dealData.insuranceProducts;
  }

  get leasingTerms(): number[] {
    const types = {new: "newTerms", certified: "certUsedTerms", used: "n/a"};
    const rateType = types[ this.appData.vehicleCondition ];
    return this.leaseDefault[ rateType ] || [];
  }

  get leasingTermsShown(): number[] {
    const types = {new: "newTermsShown", certified: "certUsedTermsShown", used: "n/a"};
    const rateType = types[ this.appData.vehicleCondition ];
    return (this.leaseDefault[ rateType ] || []);

  }

  restrictedTerm(term: number) {
    const selectedLeasingTerm = this.dealData.leaseOptions.selectedLeaseTerm;
    return this.RESTRICTED_TERMS.includes(term) && selectedLeasingTerm !== term;
  }

  get leaseResiduals(): any[] {
    return this.dealData.leaseResiduals || [];
  }

  get leaseOptions(): LeaseOptions {
    return this.dealData.leaseOptions || [];
  }

  get tradeIn(): TradeIn {
    return this.dealData.tradeIn;
  }

  get vehicleNeeds() {
    return this.dealData.vehicleNeeds;
  }

  get financeDefault() {
    return this.appData.financeSettings.financeDefault || {};
  }

  get leaseDefault() {
    return this.appData.financeSettings.leaseDefault || {};
  }

  get financeOptions(): FinanceOptions {
    return this.dealData.financeOptions || {};
  }

  get insuranceProducts(): InsuranceProduct[] {
    return this.vehicle.insuranceProducts || [];
  }

  get selectedAccessories(): Partial<Accessory>[] {
    return this.dealData.accessories || [];
  }

  get financeSettings() {
    return this.appData.financeSettings;
  }

  get salesTax() {
    return this.dealData.salesTax;
  }

  get incentives() {
    return this.dealData.incentives;
  }

  get financeOffer(): FinanceOffer {
    const incentive = this.incentives.find(el => el.financeOffer && el.financeOffer.financeRates);
    return incentive ? incentive.financeOffer : null;
  }

  get leaseOffer(): LeaseOffer {
    const incentive = this.incentives.find(el => el.leaseOffer && el.financeOffer.leaseRates);
    return incentive ? incentive.leaseOffer : null;
  }

  get financeIncentiveRatesApplied(): boolean {
    return (this.incentives || []).filter(incentive => {
      if (pathOr(false, ["financeOffer", "financeRates", length], incentive)) {
        return true;
      }
    }).length > 0;
    // return pathOr(false, ["incentives", 0, "financeOffer", "financeRates", "length"], this) > 0;
  }

  get financeIncentiveRatesExist(): boolean {
    return this.appData.incentives.length > 0;
  }

  get retail() {
    return this.dealData.financeOptionsEdits.retail === null ?
      this.vehicle.retail :
      this.dealData.financeOptionsEdits.retail;
  }

  calcCustomizedVehiclePrice() {
    return this.calculationService.calcCustomizedVehiclePrice(
      this.retail,
      this.dealData.selectedCashIncentivesTotal,
      this.selectedAccessories
    );
  }

  get customerProvidedInterestRate() {
    const {customerProvidedInterestRate} = this.financeOptions;
    if (customerProvidedInterestRate > 0) { return customerProvidedInterestRate; }
  }

  // FORM VALIDATION & HELPERS

  autoSubmitTaxRateForm() {
    const {dirty, valid} = this.taxRateForm;
    if (dirty && valid) {
      const {taxRatePercentage} = this.taxRateForm.value;
      const taxRate = taxRatePercentage / 100;
      this.dealService.submitTaxRate(taxRate);
      this.taxRateForm.markAsPristine();
    }
  }

  private updateTaxRateForm() {
    let taxRate = 0;
    if (pathOr("", ["dealData", "customer", "state"], this).toLowerCase() === "or") {
      taxRate = CAT_TAX_RATE;
    }
    if (pathOr("", ["dealData", "customer", "state"], this).toLowerCase() === "wa") {
      taxRate = WASHINGTON_SALES_TAX;
    }
    if (this.financeOptions.customSalesTaxRate) {
      taxRate = this.financeOptions.customSalesTaxRate;
    }
    const taxRatePercentage = Math.floor(taxRate * 10000) / 100;
    this.taxRateForm.patchValue({taxRatePercentage});
  }

  autoSubmitDocFeesForm() {
    const {dirty, valid} = this.docFeesForm;
    if (dirty && valid) {
      const dmvFees = this.financeDefault.dmvFees || 0;
      const {docFees} = this.docFeesForm.value;
      const newDocFees = docFees - dmvFees >= 0 ? docFees - dmvFees : 0;
      this.dealService.submitDocFees(newDocFees);
      this.docFeesForm.markAsPristine();
    }
  }

  private updateDocFeesForm() {
    this.docFeesForm.patchValue({docFees: this.financeOptions?.totalFees});
  }

  touchedInvalid(formGroup: string, controlName: string): boolean {
    const control = this[ formGroup ].get(controlName);
    return control.touched && control.invalid;
  }

  isOregon() {
    return this.dealData.customer.state.toLowerCase() === "or";
  }

  termNotAlreadyDisplayed(existingRates: FinanceRate[], customTerm: number, customInterestRate: number): boolean {
    if (!customInterestRate) {
      return false;
    }
    let unique = true;
    if (existingRates) {
      existingRates.forEach(rate => {
        //
        if (rate.term == customTerm) {
          unique = false;
        }
      });
    }
    return unique;
  }

  async convertImageToBase64(imageUrl: string): Promise<string> {
    try {
      // Fetch the image
      const response = await fetch(imageUrl);
      if (!response.ok) throw new Error('Network response was not ok');

      // Read the image content as Blob
      const blob = await response.blob();

      // Convert the Blob to Base64
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // The result includes the Base64 string including the data URL scheme
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to Base64:', error);
      return Promise.reject(error);
    }
  }

  screenshot() {

    //console.log("Deal Data:", this.dealData)

    const customizedVehiclePrice = this.calcCustomizedVehiclePrice();

    // console.log("Customized Vehicle Price:", customizedVehiclePrice)
    // Select the element that you want to capture
    //const captureElement = document.querySelector("#buyBoxMenuLayout");

    // Call the html2canvas function and pass the element as an argument
    //html2canvas(captureElement as HTMLElement).then(canvas => {

    // Get the image data as a base64-encoded string
    //const imageData = canvas.toDataURL("image/jpg");

    let content = [];

    const [year, month, day] = new Date().toISOString().substr(0, 10).split("-");
    const today = [month, day, year].join("/");

    const vehicle = this.appData.vehicle as Vehicle;
    const deal = this.dealData.deal as DealState;

    content = content.concat(
      [{pageOrientation: 'landscape', text: '\n\n\n\n', margin: [50, 0, 0, 0], padding: [100, 0, 0, 0],}, {
        image: this.base64,
        margin: [50, 0, 0, 0],
        padding: [100, 0, 0, 0],
        alignment: 'center',
        width: 680,
        pageOrientation: 'landscape',
      }, {pageOrientation: 'portrait', text: '', pageBreak: 'after'}]
    );

    content = content.concat(
      this.renderDocumentTitle('CLEAR Payment Estimates')
    );

    content = content.concat(
      [{
        image: this.logoImage,
        absolutePosition: {x: 432.8, y: 47},
        width: 140,
      }]
    );

    const salesAgent = this.dealData.deal.salesPerson + (!this.dealData.deal.salesPersonPhone ? "" : ', Call or Text: ' + formatPhoneNumber(this.dealData.deal.salesPersonPhone));

    let body = [
      ['', ''],
      [{text: 'Today\'s Date:', style: 'overviewLeftCell'}, {text: today, style: 'overviewRightCell'}],
      [{text: 'Sales Agent:', style: 'overviewLeftCell'}, {text: salesAgent, style: 'overviewRightCell'}],
      [{text: 'Guest:', style: 'overviewLeftCell'}, {text: deal.customer?.firstName + " " + deal.customer?.lastName, style: 'overviewRightCell'}],
      [{text: 'Sent By:', style: 'overviewLeftCell'}, {text: this.currentUser?.firstName + " " + this.currentUser?.lastName, style: 'overviewRightCell'}],
      [{text: 'Vehicle:', style: 'overviewLeftCell'}, {text: vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model, style: 'overviewRightCell'}],
      [{text: 'VIN:', style: 'overviewLeftCell'}, {text: vehicle.vin, style: 'overviewRightCell'}],
      [{text: 'Customized Vehicle Price:', style: 'overviewLeftCell'}, {text: this.formatCurrency(customizedVehiclePrice), style: 'overviewRightCell'}],
    ];

    if (Math.round(this.getDownPayment()) != 0) {
      body = body.concat([
        [{text: 'Down Payment', style: 'overviewLeftCell'}, {text: (this.getDownPayment() > 0 ? '' : '') + this.formatCurrency(this.getDownPayment()), style: 'overviewRightCell'}],
      ]);
    }

    content = content.concat([
        {
          layout: 'exampleLayout',
          table: {
            headerRows: 1,
            width: '100%',
            widths: [150, '*'],
            body
          },
          margin: [0, 10, 0, 10]
        }
      ]
    );

    content = content.concat(
      [{
        image: this.imageData,
        margin: [0, 0, 0, 0],
        alignment: 'center',
        width: 530
      }]
    );

    content = content.concat(
      [{
        pageOrientation: 'landscape',
        text: '\n\nLeasing base payments may recalculate due to equity allocation. All figures presented to be approved by manager. Sorry, typographical errors cannot be honored.\nPayments for illustrative purposes only. Please review contract for final payment terms. The estimates are valid for 72 hours.',
        style: 'disclaimerText'
      }]
    );

    let def = {
      info: {title: "Payment Plans"},
      pageSize: 'letter',
      pageOrientation: 'landscape',
      pageMargins: [40, 40, 40, 40],
      content,
      styles: {
        header: {
          fontSize: 22,
          bold: true
        },
        overviewLeftCell: {
          alignment: 'left',
          bold: false,
          fontSize: 9
        },
        overviewRightCell: {
          alignment: 'right',
          bold: true,
          fontSize: 9
        },
        disclaimerText: {
          alignment: 'center',
          bold: false,
          fontSize: 7
        }
      }
    };

    const tableLayouts = {
      exampleLayout: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return (i === node.table.headerRows) ? 2 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        }
      },
    };

    //this.outputPDF(def, tableLayouts, 'open');


    var createPdf = pdfMake.createPdf(def, tableLayouts);
    var base64data = null;

    createPdf.getBase64(function (encodedString) {
      base64data = encodedString;
      //console.log(base64data);


      var byteCharacters = atob(base64data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[ i ] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], {type: 'application/pdf;base64'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    });
  }

  imageData: any;

  public saveSnapshot() {
    const captureElement = document.querySelector("#buyBoxMenuLayout");
    html2canvas(captureElement as HTMLElement).then(canvas => {
      this.imageData = canvas.toDataURL("image/png");
      this.focusDownloadButton();
      (this.downloadButton.nativeElement as any).dispatchEvent(new MouseEvent('dblclick'));
    });
  }

  formatCurrency(val: number) {
    return formatCurrency(val ? Math.round(val * 100) / 100 : 0, "en-US", "$", "USD");
  }

  getDownPayment() {
    if (!this.dealData) return "0";
    return this.dealData.financeOptionsEdits.downPayment || this.dealData.financeOptions.downPayment;
  }

  outputPDF(documentDefinition, tableLayouts, action = 'open', filename = 'document.pdf') {
    switch (action) {
      case 'print':
        pdfMake.createPdf(documentDefinition, tableLayouts).print(filename);
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition, tableLayouts).download(filename);
        break;
      case 'open':
      default:
        pdfMake.createPdf(documentDefinition, tableLayouts).open();
        break;
    }
  }

  renderDocumentTitle(pageTitle: string): any[] {
    return [
      {
        text: pageTitle,
        bold: true,
        fontSize: 20,
        alignment: 'left',
        margin: [0, 10, 0, 0]
      }
    ];
  }

  paymentOptionsModalOpened() {
    this.historyService.dispatchAddEvent({
      shortName: "Payment Options Window Opened"
    });
  }

  paymentOptionsModalClosed() {
    this.historyService.dispatchAddEvent({
      shortName: "Payment Options Window Closed"
    });
  }
}

<form [formGroup]="tradeInForm">

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'year'"
    [name]="'Year'"
    [blur]="autoSubmitForm"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'make'"
    [name]="'Make'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'model'"
    [name]="'Model'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'trim'"
    [name]="'Trim'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'bodyType'"
    [name]="'Body Type'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'color'"
    [name]="'Color'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'vin'"
    [name]="'VIN'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'license'"
    [name]="'Plate #'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'odometer'"
    [name]="'Odometer'"
    [blur]="autoSubmitForm"
    [type]="'number'"
    inputmode="numeric">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'lienHolderName'"
    [name]="'LienHolder'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'stockNumber'"
    [name]="'Stock Number'"
    [blur]="autoSubmitForm"
    [type]="'text'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'tradeEstimate'"
    [name]="'Trade Value'"
    [blur]="autoSubmitForm"
    [pipe]="'currency'"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="tradeInForm"
    [forceUpdate]="forceChildFormUpdate"
    [formCtrlName]="'payOff'"
    [name]="'Payoff'"
    [pipe]="'currency'"
    [blur]="autoSubmitForm"
    [type]="'number'">
  </app-simple-input>

</form>

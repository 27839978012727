import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ForgotPasswordComponent, LoginComponent, ResetPasswordComponent } from "./pages";
import { SharedModule } from "../shared-module/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthRoutingModule } from "./auth-routing.module";
import { JwtModule } from "@auth0/angular-jwt";
import { rootReducer } from "./store/reducers/auth.reducer";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { AuthEffects } from "./store/effects/auth.effects";
import { FlutterLogoutDirective } from "./directives/flutter-logout.directive";

export function jwtTokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [LoginComponent, ResetPasswordComponent, ForgotPasswordComponent, FlutterLogoutDirective],
  exports: [FlutterLogoutDirective],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    StoreModule.forFeature("auth", rootReducer),
    EffectsModule.forFeature([AuthEffects]),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    })
  ]
})
export class AuthModule {}

<!-- <div appFlutterLogout></div> -->

<div
  class="app-page-container d-flex flex-column align-items-center justify-content-center"
  style="min-height: 90vh"
>
  <div
    *ngIf="environment.name === 'staging'">
    <p class="staging">This is the Northstar staging site</p>
  </div>
  <img appDealerLogo class="w-75 mb-5" style="max-width: 500px"/>

  <form [formGroup]="loginForm" class="mb-3">
    <h1 class="h4 font-weight-light text-center mb-3">Login</h1>

    <div class="form-group">
      <input
        class="form-control"
        [ngClass]="{ 'border-danger': touchedInvalid('email') }"
        formControlName="email"
        type="email"
        placeholder="Email"
        autofocus="true"
        autocomplete="username"
        (blur)="trimInput('email')"
        required
      />
    </div>

    <div class="form-group">
      <input
        class="form-control"
        [ngClass]="{ 'border-danger': touchedInvalid('password') }"
        formControlName="password"
        type="password"
        placeholder="Password"
        autocomplete="current-password"
        required
      />
    </div>

    <button
      class="btn app-btn-success btn-block"
      type="submit"
      (click)="onLogin()"
      [disabled]="loginForm.invalid || loading"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      LOGIN
    </button>
  </form>

  <a routerLink="/forgot-password" class="app-text-success"
  >Forgot Your Password?</a
  >

  <div style="margin:60px"
       *ngIf="environment.name === 'staging'">
    <p>Looking for the production site? </p>
    <a href="https://www.northstar-auto.com/login">
      <button
        style="margin-bottom:10px"
        class="btn app-btn-success btn-block"
        type="submit"
      >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
        PRODUCTION
      </button>
    </a>
  </div>
</div>

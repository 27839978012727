<form [formGroup]="tradeOwnerForm">

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'firstName'"
    [name]="'First Name'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'middleName'"
    [name]="'Middle Name'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'lastName'"
    [name]="'Last Name'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'street'"
    [name]="'Address'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'city'"
    [name]="'City'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'county'"
    [name]="'County'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'state'"
    [name]="'State'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'zip'"
    [name]="'Zip'"
    [minLength]="5"
    [maxLength]="5"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'phone'"
    [name]="'Phone'"
    [minLength]="10"
    [maxLength]="10"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'email'"
    [name]="'Email'"
    [type]="'text'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

  <app-simple-input
    [forceUpdate]="forceChildFormUpdate"
    [parentForm]="tradeOwnerForm"
    [formCtrlName]="'birthdate'"
    [fieldValue]="uiState.birthdate"
    [name]="'DOB'"
    [type]="'date'"
    [blur]="autoSubmitForm"
    [keydown]="flagChange">
  </app-simple-input>

</form>

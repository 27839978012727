<section class="container-fluid">

  <div class="d-flex justify-content-center mt-4 ml-auto mr-auto">
    <div style="max-width: 280px;">
      <label class="text-center"
             style="width: 100%">CSV File</label>
      <input class="form-control"
             type="file"
             accept="text/csv"
             (change)="fileEvent($event)"
             #newResidualsCSV>
    </div>
  </div>

  <hr *ngIf="newResiduals">

  <button *ngIf="newResiduals"
          class="btn app-btn-secondary d-block ml-auto mr-auto mt-4"
          [disabled]="waitOnAction"
          (click)="onSubmitResiduals(newResiduals)">
    Save
  </button>

  <div class="mt-4 ml-auto mb-auto mb-4">
    <section *ngFor="let residuals of newResiduals">
      <app-new-residuals-table [residuals]="residuals"></app-new-residuals-table>
    </section>
  </div>

</section>
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NewResiduals } from "../../../models";
import { AlertService } from "../../../../shared-module/services";
import { ParsingService } from "../../../services";
import * as Papa from "papaparse";
import { NewResidualInput, TermPercentage } from "src/app/settings-module/models/lease-residuals";

@Component({
  selector: "app-upload-new-residuals",
  templateUrl: "./upload-new-residuals.component.html",
  styleUrls: ["./upload-new-residuals.component.scss"]
})
export class UploadNewResidualsComponent {

  @Input() waitOnAction: boolean;
  @Output() unsavedChanges = new EventEmitter<boolean>();
  @Output() submitResiduals = new EventEmitter<NewResiduals[]>();

  @ViewChild("newResidualsCSV", {static: false}) newResidualsCSV: ElementRef;

  newResiduals: NewResiduals[];

  constructor(
    private alertService: AlertService,
    private parsingService: ParsingService
  ) { }

  // ACTIONS

  fileEvent(event) {
    const file = event.target.files[ 0 ];
    this.alertService.clear();
    this.readFile(file);
  }

  readFile(file: File) {
    const results = Papa.parse(file, {
      beforeFirstChunk: (re) => {
        re = "year,modelNumber,24,27,30,33,36,39,42,45,48,51,54,57,60" + "\n" + re;
        return re;
      },
      header: true,
      complete: ({data, errors}) => {
        const newResiduals = this.constructNewResiduals(data);
        // this.onSubmitResiduals(newResiduals);
        this.newResiduals = newResiduals;
      },
      error: (e) => {
        //console.log("e:", e);
      }
    });
  }

  onSubmitResiduals(residuals: NewResiduals[]) {
    this.submitResiduals.emit(residuals);
  }

  // UI & RENDERING

  constructNewResiduals(data: Array<NewResidualInput>): NewResiduals[] {
    const newResiduals: NewResiduals[] = [];

    data.forEach((el: NewResidualInput) => {
      const termPercentages: TermPercentage[] = [];
      if (!this.parsingService.isValidModelNumber(el.modelNumber)) {
        return;
      }
      [24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60].forEach(term => {
        termPercentages.push({
          term,
          percentage: parseInt(el[ term ], 10)
        });
      });


      const year = parseInt(el.year, 10);
      const newResidualForYearIndex = newResiduals.findIndex((newResidual: NewResiduals) => {
        return newResidual.year === year;
      });
      if (newResidualForYearIndex >= 0) {
        newResiduals[ newResidualForYearIndex ].residualPercentages.push({
          modelNumber: el.modelNumber,
          termPercentages
        });
      } else {
        newResiduals.push({
          year,
          residualPercentages: [{
            modelNumber: el.modelNumber,
            termPercentages
          }]
        });
      }


    });

    return newResiduals;

  }

}

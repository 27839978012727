import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CalculationUtilityService, DealService, VehicleService } from "../../services";
import { InsuranceProduct, InsuranceProductKeys, InsuranceProductKeysIconSrcMap, TermCost, Vehicle } from "../../models";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-insurance-menu",
  templateUrl: "./insurance-menu.component.html",
  styleUrls: ["./insurance-menu.component.scss"]
})
export class InsuranceMenuComponent implements OnInit {
  @ViewChild("closeModal", {static: false}) closeModal: ElementRef;
  private unsubscribe$ = new Subject();
  private uiState = {activeProductKey: ""};
  private appData = {insuranceProducts: null};
  dealData = {insuranceProducts: null, disabledInsuranceProducts: null, vehicleCondition: ""};
  productSelectedTerm: Map<string, any> = new Map();
  loaded = false;

  constructor(
    private dealService: DealService,
    private vehicleService: VehicleService,
    private calculationUtilityService: CalculationUtilityService,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    this.subToStoreData();
  }

  // INITIALIZATION

  subToStoreData() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle: Vehicle) => {
        const {insuranceProducts} = vehicle;
        this.appData.insuranceProducts = insuranceProducts || [];
        this.dealData.vehicleCondition = this.vehicleService.vehicleCondition(vehicle);
      });

    this.dealService.dealInsuranceService.selectInsuranceProducts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((insuranceProducts: InsuranceProduct[]) => {
        this.dealData.insuranceProducts = insuranceProducts || [];
      });

    this.dealService.dealInsuranceService.selectDisabledInsuranceProducts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((insuranceProducts: InsuranceProduct[]) => {
        this.dealData.disabledInsuranceProducts = insuranceProducts || [];
      });

  }

  // UI CONTROL & RENDERING

  onCloseModal() {
    this.closeModal.nativeElement.click();
    this.onSelectProduct('');
  }

  currentProductKey = '';

  onSelectProduct(productKey: string) {
    //console.log("onSelectProduct", productKey)
    if (productKey) {
      this.loaded = false;
      setTimeout(() => {
        this.loaded = true;
      }, 1000);
      //console.log("dispatchAddEvent", this.getProductKeyFriendlyName(productKey))
      this.historyService.dispatchAddEvent({
        shortName: this.getProductKeyFriendlyName(productKey) + " Window Opened"
      });
      const product = this.insuranceProductByKey(productKey);
      if (product) {
        this.productSelectedTerm.set(productKey, product.selectedTerm);
      }
      this.currentProductKey = productKey;
    } else {
      this.historyService.dispatchAddEvent({
        shortName: this.getProductKeyFriendlyName(this.currentProductKey) + " Window Closed"
      });
      this.currentProductKey = '';
    }
    this.uiState.activeProductKey = productKey;
  }

  getProductKeyFriendlyName(productKey: string): string {
    if (!productKey) return '';
    switch (productKey) {
      case 'VSC':
        return 'VSA';
      case 'SELECT':
      case 'CCE':
        return 'CCE';
      case 'GAP':
        return 'GAP';
      case 'LEASEWEARTEAR':
        return 'EWU';
      case 'MAINTENANCEPLAN':
        return 'PPM';
    }
    return '';
  }

  isActiveProduct(productRef: string): boolean {
    return this.uiState.activeProductKey === InsuranceProductKeys[ productRef ];
  }

  get insuranceProducts(): InsuranceProduct[] {
    return this.appData.insuranceProducts || [];
  }

  getIconSrc(productKey: string) {
    return InsuranceProductKeysIconSrcMap[ productKey ];
  }

  insuranceProduct(productRef: string): Partial<InsuranceProduct> {
    const productKey = InsuranceProductKeys[ productRef ];
    const product = this.insuranceProducts.find(item => item.productKey === productKey);
    return product || {};
  }

  insuranceProductByKey(productKey: string): Partial<InsuranceProduct> {
    const product = this.insuranceProducts.find(item => item.productKey === productKey);
    return product || null;
  }

  getProductTermLabel(product: InsuranceProduct, termCostIndex: number): string {
    if (!product || product.termCosts.length <= termCostIndex) return 'a';
    const termCost: TermCost = product.termCosts[ termCostIndex ];
    //console.log("getProductTermLabel", termCostIndex, termCost)
    if (!termCost || !termCost.term || !termCost.miles || !termCost.price) return 'b';
    return `${termCost.term / 12} Years / ${termCost.miles || "Unlimited"} Miles - ${this.calculationUtilityService.formatCurrencyUS(termCost.price)}`;
  }


  selectedProductTermIndex(productRef: string): number {
    const productKey = InsuranceProductKeys[ productRef ];
    //console.log("selectedProductTermIndex", productKey)
    let product = this.dealData.insuranceProducts.find(item => item.productKey === productKey);
    if (!product) {
      product = this.dealData.disabledInsuranceProducts.find(item => item.productKey === productKey);
    }
    if (product) {
      //console.log("selectedProductTermIndex", product.selectedTerm)
      if (this.loaded)
        this.historyService.dispatchAddEvent({
          shortName: this.getProductKeyFriendlyName(this.currentProductKey) + " Term Changed",
          oldValue: this.getProductTermLabel(product, this.productSelectedTerm.get(productKey)),
          newValue: this.getProductTermLabel(product, product.selectedTerm),
        });
      this.productSelectedTerm.set(productKey, product.selectedTerm);
    }
    return product ? product.selectedTerm : null;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DealService } from "../../services";
import { InsuranceProduct, TermCost } from "../../models";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-insurance-item-ppm",
  templateUrl: "./insurance-item-ppm.component.html",
  styleUrls: ["./insurance-item-ppm.component.scss"]
})
export class InsuranceItemPpmComponent implements OnInit {
  @Input() product: InsuranceProduct;
  @Input() selectedTermIndex = null;
  @Output() closeModal = new EventEmitter();

  ppmForm: FormGroup = this.formBuilder.group({
    termCostIndex: [0]
  });

  constructor(
    private formBuilder: FormBuilder,
    private dealService: DealService,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    this.syncSelectedPlan();
    this.ppmForm.valueChanges.subscribe(({termCostIndex}) => {
      this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    });
  }

  // INITIALIZATION

  syncSelectedPlan() {
    const termCostIndex = this.selectedTermIndex || 0;
    this.ppmForm.patchValue({termCostIndex});
  }

  // ACTIONS

  onAcceptPlan() {
    const {termCostIndex} = this.ppmForm.value;
    this.historyService.dispatchAddEvent({
      shortName: "PPM Plan Accepted",
    })
    this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +parseInt(termCostIndex, 10));
    this.closeModal.emit();
  }

  onDeclinePlan() {
    this.historyService.dispatchAddEvent({
      shortName: "PPM Plan Declined",
    })
    this.dealService.dealInsuranceService.removeInsuranceProduct(this.product);
    this.closeModal.emit();
  }

  // UI CONTROL & RENDERING

  get termCosts(): TermCost[] {
    const terms = this.product ? this.product.termCosts : false;
    return terms || [];
  }

  planSelected(): boolean {
    const {termCostIndex} = this.ppmForm.value;
    return this.selectedTermIndex === +termCostIndex;
  }

  noPlanSelected(): boolean {
    return this.selectedTermIndex === null;
  }

}

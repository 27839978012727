import { Component } from "@angular/core";

@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"]
})
export class ActivityComponent {

  /*
  // numbers refer to events in server model for DEAL_HISTORY_EVENT_IDS
  eventAbbreviations = {
    TD: 0,
    TI: 1,
    OP: 2,
    AC: 4,
    OE: 11,
    PO: 13
  };

  @Input() history: DealHistory;

  constructor(
    private router: Router
  ) { }

  clickActivity() {
    //console.log("hello");
  }

  hasEvent(type: string): boolean {
    if (this.history) {
      return this.history.events
        .findIndex((event: HistoryEvent) => {
          return this.eventAbbreviations[ type ] === event.eventId;
        }) > -1;
    }
  }
  */
}

<form [formGroup]="simplePaymentForm"
      class="form-layout">

  <div>
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('leaseResidual') }">
      Lease Residual *
    </label>
    <input class="form-control"
           formControlName="leaseResidual"
           type="number"
           inputmode="numeric"
           required/>
  </div>

  <div>
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('loanTerm') }">
      Months *
    </label>
    <input class="form-control"
           formControlName="loanTerm"
           type="number"
           inputmode="numeric"
           required/>
  </div>

  <div>
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('interestRate') }">
      Interest Rate *
    </label>
    <input class="form-control"
           formControlName="interestRate"
           type="number"
           inputmode="numeric"
           required/>
  </div>

  <div *ngIf="simplePaymentForm.valid"
       class="form-result">
    <span class="h5 mb-0">
      {{(monthlyPayment > 0 ? monthlyPayment : 0) | currency}}
    </span>
    <span class="small">monthly payment</span>
  </div>

</form>

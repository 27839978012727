import { Component } from "@angular/core";
import { AuthService } from "../../../auth-module/services";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-settings-menu",
  templateUrl: "./settings-menu.component.html",
  styleUrls: ["./settings-menu.component.scss"]
})
export class SettingsMenuComponent {
  buttons = [
    {
      link: "/settings/menu",
      name: "Menu / External Links"
    },
    {
      link: "/settings/dealer",
      name: "Dealer"
    },
    {
      link: "/settings/insurance",
      name: "Insurance Products"
    },
    {
      link: "/settings/finance",
      name: "Finance / Lease"
    },
    {
      link: "/settings/lease-residuals",
      name: "Lease Residuals"
    },
    {
      link: "/settings/incentives",
      name: "Incentives"
    },
    {
      link: "/settings/archive",
      name: "Archive"
    },
    {
      link: "/settings/form-server",
      name: "Form Server Configurations"
    }
  ];

  environmentName = environment.name;
  token!: string;

  constructor(private authService: AuthService) {
    this.authService.selectToken().subscribe(token => this.token = token);
  }

}

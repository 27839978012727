<app-modal-view #modal="modal"
                [modalTitle]="activeTitle"
                [showFooter]="true">

  <section modal-body>

    <!-- CREDIT -->
    <article *ngIf="isActiveSequence('creditTier')"
             class="d-flex flex-column align-items-center">
      <div class="w-100 my-3"
           style="max-width: 600px">
        <app-credit-tier-input></app-credit-tier-input>
      </div>
      <ul class="mb-0">
        <li>Payments quoted are based on the credit tier you selected.</li>
        <li>Payments may vary based on your credit profile and other factors.</li>
      </ul>
    </article>

    <!-- WARRANTY COVERAGE -->
    <!-- Hide Component to Preserve Active View Index -->
    <article [ngClass]="{ 'd-none': !isActiveSequence('warrantyUi') }">
      <app-warranty></app-warranty>
    </article>

    <!-- CLEAR CARE PRODUCT -->
    <article *ngIf="isActiveSequence('clearGraph')"
             class="w-100 mx-auto"
             style="max-width: 700px">
      <app-clear-graph></app-clear-graph>
    </article>


    <!-- GAP PRODUCT -->
    <article *ngIf="isActiveSequence('gapGraph')"
             class="w-100 mx-auto"
             style="max-width: 700px">
      <app-gap-graph></app-gap-graph>
    </article>

  </section>

  <section modal-footer>

    <article class="d-flex align-items-center justify-content-between">
      <button *ngIf="!allowWarrantyUiNavBack"
              class="btn app-btn-neutral"
              type="button"
              (click)="previousSequence()">
        BACK
      </button>

      <app-warranty-nav-button *ngIf="allowWarrantyUiNavBack"
                               navDirection="reverse">
      </app-warranty-nav-button>

      <button *ngIf="!allowWarrantyUiNavNext"
              class="btn app-btn-secondary"
              type="button"
              (click)="nextSequence()">
        NEXT
      </button>

      <app-warranty-nav-button *ngIf="allowWarrantyUiNavNext"
                               navDirection="forward">
      </app-warranty-nav-button>
    </article>

  </section>

</app-modal-view>
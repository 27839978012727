import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InsuranceProduct } from "../../../models";

@Component({
  selector: "app-insurance-product-list",
  templateUrl: "./insurance-product-list.component.html",
  styleUrls: ["./insurance-product-list.component.scss"]
})
export class InsuranceProductListComponent {
  @Input() insuranceProducts: InsuranceProduct[] = [];
  @Input() activeProductIndex: number;
  @Output() selectProduct = new EventEmitter<number>();

  constructor() { }

  onSelectProduct(index: number) {
    this.selectProduct.emit(index);
  }

  isActiveProduct(index: number): boolean {
    return index === this.activeProductIndex;
  }

  noProducts(): boolean {
    return this.insuranceProducts.length === 0;
  }

}

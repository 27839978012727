<form [formGroup]="financeDefaultForm" class="card card-body mb-3">
  <label>New Terms Shown</label>

  <div
    *ngIf="newTermsShown.length === 0"
    class="app-bg-light app-text-neutral text-center rounded px-3 py-2 mb-3"
  >
    No Terms Set.
  </div>

  <section formArrayName="newTermsShown" class="term-controls-layout">
    <article
      *ngFor="let term of newTermsShown; let i = index"
      class="input-group"
    >
      <input
        class="form-control"
        [ngClass]="{
          'app-border-danger': touchedInvalidTerm('newTermsShown', i)
        }"
        [formControlName]="i"
        type="number"
        min="1"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-sm app-btn-neutral d-flex align-items-center"
          type="button"
          (click)="onRemoveTerm('newTermsShown', i)"
          [disabled]="waitOnAction"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onAddTerm('newTermsShown')"
      [disabled]="waitOnAction"
    >
      Add
    </button>
  </section>

  <hr/>

  <label>Used Terms Shown</label>

  <div
    *ngIf="usedTermsShown.length === 0"
    class="app-bg-light app-text-neutral text-center rounded px-3 py-2 mb-3"
  >
    No Terms Set.
  </div>

  <section formArrayName="usedTermsShown" class="term-controls-layout">
    <article
      *ngFor="let term of usedTermsShown; let i = index"
      class="input-group"
    >
      <input
        class="form-control"
        [ngClass]="{
          'app-border-danger': touchedInvalidTerm('usedTermsShown', i)
        }"
        [formControlName]="i"
        type="number"
        min="1"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-sm app-btn-neutral d-flex align-items-center"
          type="button"
          (click)="onRemoveTerm('usedTermsShown', i)"
          [disabled]="waitOnAction"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onAddTerm('usedTermsShown')"
      [disabled]="waitOnAction"
    >
      Add
    </button>
  </section>

  <hr/>

  <label>Certified Terms Shown</label>

  <div
    *ngIf="certTermsShown.length === 0"
    class="app-bg-light app-text-neutral text-center rounded px-3 py-2 mb-3"
  >
    No Terms Set.
  </div>

  <section formArrayName="certTermsShown" class="term-controls-layout">
    <article
      *ngFor="let term of certTermsShown; let i = index"
      class="input-group"
    >
      <input
        class="form-control"
        [ngClass]="{
          'app-border-danger': touchedInvalidTerm('certTermsShown', i)
        }"
        [formControlName]="i"
        type="number"
        min="1"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-sm app-btn-neutral d-flex align-items-center"
          type="button"
          (click)="onRemoveTerm('certTermsShown', i)"
          [disabled]="waitOnAction"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onAddTerm('certTermsShown')"
      [disabled]="waitOnAction"
    >
      Add
    </button>
  </section>

  <hr/>

  <section class="form-controls-layout">
    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('customTermDefault') }"
      >
        Custom Term Default *
      </label>
      <input
        class="form-control"
        formControlName="customTermDefault"
        type="number"
        min="0"
        required
      />
    </div>

    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('daysToFirstPay') }"
      >
        Days To First Pay *
      </label>
      <input
        class="form-control"
        formControlName="daysToFirstPay"
        type="number"
        min="0"
        required
      />
    </div>

    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('dmvFees') }">
        DMV Fees *
      </label>
      <div class="input-group">
        <span class="input-group-prepend">
          <span class="input-group-text">$</span>
        </span>
        <input
          class="form-control"
          formControlName="dmvFees"
          type="number"
          min="0"
          required
        />
      </div>
    </div>

    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('gapAmortFactor') }"
      >
        GAP Amort Factor *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="gapAmortFactor"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">
            {{
            valid("gapAmortFactor")
              ? (value("gapAmortFactor") | percent: "1.0-2")
              : "--%"
            }}
          </span>
        </span>
      </div>
    </div>

    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('gapDepreciation') }"
      >
        GAP Depreciation *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="gapDepreciation"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">
            {{
            valid("gapDepreciation")
              ? (value("gapDepreciation") | percent: "1.0-2")
              : "--%"
            }}
          </span>
        </span>
      </div>
    </div>

    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('taxRate') }">
        Tax Rate *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="taxRate"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">
            {{
            valid("taxRate") ? (value("taxRate") | percent: "1.0-2") : "--%"
            }}
          </span>
        </span>
      </div>
    </div>
  </section>
</form>

<button
  class="btn app-btn-secondary d-block mx-auto"
  type="button"
  (click)="onSubmitFinanceDefaultForm()"
  [disabled]="
    waitOnAction || financeDefaultForm.pristine || financeDefaultForm.invalid
  "
>
  Submit
</button>

import { createAction } from "@ngrx/store";

const prefix = "[Clearpath]";

const GET_VEHICLE = `${prefix} GET VEHICLE`;
const GET_VEHICLE_SUCCESS = `${prefix} GET VEHICLE SUCCESS`;
const GET_VEHICLE_FAILURE = `${prefix} GET VEHICLE FAILURE`;
const GET_CURRENT_VEHICLE = `${prefix} GET CURRENT VEHICLE`;

const UPDATE_VEHICLE = `${prefix} UPDATE VEHICLE`;
const UPDATE_VEHICLE_SUCCESS = `${prefix} UPDATE VEHICLE SUCCESS`;
const UPDATE_VEHICLE_FAILURE = `${prefix} UPDATE VEHICLE FAILURE`;

const SET_VEHICLE = `${prefix} SET VEHICLE`;
const SET_VEHICLE_BY_LOOKUP = `${prefix} SET VEHICLE BY LOOKUP`;

const GET_ALL_VEHICLES = `${prefix} GET ALL VEHICLES`;
const GET_ALL_VEHICLES_SUCCESS = `${prefix} GET ALL VEHICLES SUCCESS`;
const GET_ALL_VEHICLES_FAILURE = `${prefix} GET ALL VEHICLES FAILURE`;

const CHANGE_STOCK_NUMBER = `${prefix} CHANGE STOCK NUMBER`;
const CHANGE_STOCK_NUMBER_SUCCESS = `${prefix} CHANGE STOCK NUMBER SUCCESS`;
const CHANGE_STOCK_NUMBER_FAILURE = `${prefix} CHANGE STOCK NUMBER FAILURE`;

const CLEAR_VEHICLE = `${prefix} CLEAR VEHICLE`;

export const getVehicle = createAction(
  GET_VEHICLE,
  ({stockNumber, odometer}) => ({stockNumber, odometer})
);
export const getVehicleSuccess = createAction(GET_VEHICLE_SUCCESS, vehicle => vehicle);
export const getVehicleFailure = createAction(GET_VEHICLE_FAILURE, error => error);
export const getCurrentVehicle = createAction(GET_CURRENT_VEHICLE);
export const updateVehicle = createAction(UPDATE_VEHICLE, vehicle => vehicle);
export const updateVehicleSuccess = createAction(UPDATE_VEHICLE_SUCCESS, vehicle => vehicle);
export const updateVehicleFailure = createAction(UPDATE_VEHICLE_FAILURE, error => error);


export const setVehicle = createAction(SET_VEHICLE, vehicle => vehicle);
export const setVehicleByLookup = createAction(SET_VEHICLE_BY_LOOKUP, vehicle => vehicle);

export const getAllVehicles = createAction(GET_ALL_VEHICLES);
export const getAllVehiclesSuccess = createAction(GET_ALL_VEHICLES_SUCCESS, vehicle => vehicle);
export const getAllVehiclesFailure = createAction(GET_ALL_VEHICLES_FAILURE, error => error);

export const changeStockNumber = createAction(
  CHANGE_STOCK_NUMBER,
  ({stockNumber, odometer}) => ({stockNumber, odometer})
);
export const changeStockNumberSuccess = createAction(
  CHANGE_STOCK_NUMBER_SUCCESS, vehicle => vehicle
);
export const changeStockNumberFailure = createAction(CHANGE_STOCK_NUMBER_FAILURE, error => error);

export const clearVehicle = createAction(CLEAR_VEHICLE);

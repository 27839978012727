import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DealState, DealStatus, DealType } from "src/app/clearpath-module/store/state";
import { FinanceOptions, TradeIn } from "src/app/clearpath-module/models";
import { CalculationService } from "src/app/clearpath-module/services";
import { DealIncentive, VehicleIncentive } from "src/app/settings-module/models/incentives";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectNetTradeInValue } from "../../../../clearpath-module/store/selectors/deal";

@Component({
  selector: "app-cash-form",
  templateUrl: "./cash-form.component.html",
  styleUrls: ["./cash-form.component.scss"]
})
export class CashFormComponent implements OnChanges {
  @Input() financeOptions: FinanceOptions;
  @Input() dealIncentives: DealIncentive[];
  @Input() vehicleIncentives: VehicleIncentive[];
  @Input() dealDefaults: DealDefaults;
  @Input() tradeIn: TradeIn;
  @Input() verified: boolean;

  @Input() set dealStatus(status: string) { this.syncFormStatus(status); }

  @Output() submitDealType = new EventEmitter<string>();
  @Output() submitIncentives = new EventEmitter<DealIncentive[]>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  private uiState = {completedDeal: false};

  // Calculation Selectors
  tradeInNetValue$: Observable<number> = this.store.select(selectNetTradeInValue);

  // Calculation Observables
  totalVehicleCashPrice$ = this.calcService.totalVehicleCashPrice$();
  totalInsuranceProductsPrice$ = this.calcService.totalFinanceInsuranceProductsPrice$();
  calcTax$ = this.calcService.calcTax$({finance: true});

  constructor(
    private store: Store<DealState>,
    private calcService: CalculationService
  ) { }

  // INITIALIZATION

  ngOnChanges() {
    this.totalVehicleCashPrice$ = this.calcService.totalVehicleCashPrice$();
  }

  private syncFormStatus(status: string) {
    const completedDeal = status === DealStatus.Completed;
    this.uiState.completedDeal = completedDeal;
  }

  // ACTIONS

  onUpdateIncentives(incentives: DealIncentive[]) {
    this.submitIncentives.emit(incentives);
  }

  // UI CONTROL RENDERING

  get cashPurchase(): boolean {
    return this.financeOptions.cashPurchase;
  }

  get completedDeal(): boolean {
    return this.uiState.completedDeal;
  }

  onSelectCashDeal() {
    if (this.cashPurchase) { return; }
    this.submitDealType.emit(DealType.Cash);
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as appActions from "../actions/app.actions";
import { AppService, DealService, RoutingService } from "../../services";
import { SettingsService } from "src/app/settings-module/services";
import { ConfigDealer } from "src/app/settings-module/models";

@Injectable()
export class AppEffects {
  getLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getLinks),
      switchMap(() => {
          return this.routingService.getLinks().pipe(
            map(links => appActions.linksApiSuccess(links)),
            catchError(error => of(appActions.linksApiFailure({error})))
          );
        }
      )
    ),
  );

  setLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.setLinks),
      switchMap(({links}) => {
        return this.routingService.setLinks(links).pipe(
          map(resLinks => appActions.linksApiSuccess(resLinks)),
          catchError(error => of(appActions.linksApiFailure({error})))
        );
      })
    ),
  );

  getDeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getDeals),
      switchMap(() => {
          return this.dealService.list().pipe(
            map((deals) => appActions.getDealsSuccess({deals: deals || []})),
            catchError(error => of(appActions.getDealsFailure({error})))
          );
        }
      )
    )
  );

  getDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getDeals),
      switchMap(() => {
          return this.settingsService.getConfigDealer().pipe(
            map((dealer: ConfigDealer) => appActions.getDealerSuccess({dealer})),
            catchError(error => of(appActions.getDealerFailure({error})))
          );
        }
      )
    )
  );

  getConfigFinance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getConfigFinance),
      switchMap(() => {
        return this.appService.getConfigFinance().pipe(
          map(configData => appActions.configFinanceApiSuccess(configData)),
          catchError(error => of(appActions.configFinanceApiFailure({error})))
        );
      })
    ),
  );

  getFormServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getFormServer),
      switchMap(() => {
        return this.settingsService.getForms().pipe(
          map(configData => appActions.formServerApiSuccess(configData)),
          catchError(error => of(appActions.formServerApiFailure({error})))
        );
      })
    ),
  );

  getZipTaxTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getZipTaxTable),
      switchMap(() => {
        return this.appService.getZipTaxTable().pipe(
          map(zipTaxTable => {
            return appActions.getZipTaxTableSuccess({zipTaxTable});
          }),
          catchError(error => of(appActions.getZipTaxTableFailure({error})))
        );
      })
    ),
  );

  setConfigFinance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.setConfigFinance),
      switchMap(({configData}) => {
        return this.appService.setConfigFinance(configData).pipe(
          map(resConfigData => appActions.configFinanceApiSuccess(resConfigData)),
          catchError(error => of(appActions.configFinanceApiFailure({error})))
        );
      })
    ),
  );

  getNewResiduals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getNewResiduals),
      switchMap(() => {
          return this.appService.getNewResiduals().pipe(
            map(({newResiduals}) => appActions.getNewResidualsSuccess({newResiduals})),
            catchError(error => of(appActions.getNewResidualsFailure({error})))
          );
        }
      )
    ),
  );

  getCertUsedResiduals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getCertUsedResiduals),
      switchMap(() => {
          return this.appService.getCertUsedResiduals().pipe(
            map(certUsedResiduals => appActions.getCertUsedResidualsSuccess({certUsedResiduals})),
            catchError(error => of(appActions.getCertUsedResidualsFailure({error})))
          );
        }
      )
    ),
  );

  constructor(
    private actions$: Actions,
    private appService: AppService,
    private dealService: DealService,
    private routingService: RoutingService,
    private settingsService: SettingsService
  ) { }
}

<div
  #main
  class="app-inline-form-layout main-holder"
  [tabindex]="(readOnly || isDisabled || editing)?-1:0"
  (focus)="!readOnly && !isDisabled ? onMainFocus() : null"
  [ngClass]="!readOnly && !isDisabled ? (editing ?'main-holder-active':''):'no-hover'"
  [class.font-bold]="isBold">
  <label [ngClass]="{ 'app-text-danger': touchedInvalid() }" [ngStyle]="{'outline':'none','white-space':(type === 'date'?'wrap':'nowrap')}">{{name}}</label>
  <ng-content></ng-content>
  <div [formGroup]="parentForm" *ngIf="showForm() || touchedInvalid()">
    <input
      #input
      class="input-underline"
      [formControlName]="formCtrlName"
      [type]="type"
      [maxlength]="maxLength"
      [minlength]="minLength"
      (focus)="focusAction()"
      (blur)="blurAction()"
      (keydown)="keydown()"
      (focusout)="onInputFocusOut();"
      (keyup.enter)="$event.preventDefault();input.blur()"
      (change)="change()"
      [value]="fieldValue"
      [attr.required]="required"
      [tabindex]="(readOnly || isDisabled || editing)?-1:0"/>
  </div>
  <a *ngIf="
      !showForm() &&
      !readOnly &&
      (!isDisabled || pipe === 'currency') &&
      !touchedInvalid() &&
      type !== 'date'"
     class="btn btn-link app-text-blue overflow" style="cursor:inherit;text-decoration: none">
    {{ pipe === "currency" ? (fieldValue | currency) : fieldValue }}</a>
  <a *ngIf="
      !showForm() &&
      !readOnly &&
      (!isDisabled || pipe !== 'currency') &&
      !touchedInvalid() &&
      type === 'date'"
     class="date-link btn btn-link app-text-blue overflow">{{isDefaultDate(fieldValue) ? "mm/dd/yyyy" : (formatDateString(fieldValue))}}</a>
  <a class="btn btn-link app-text-grey overflow default-cursor" *ngIf="isDisabled && pipe !== 'currency'">{{ fieldValue }}</a>
  <a class="btn btn-link app-text-grey overflow default-cursor" *ngIf="readOnly">{{ pipe === "currency" ? (fieldValue | currency) : fieldValue }}</a>
  <a class="btn btn-link overflow default-cursor" *ngIf="hasAdditionalFieldValue()"><span>$</span>{{additionalFieldValue}}</a>
</div>

<section class="product-list-layout">

  <button *ngFor="let product of insuranceProducts; let i = index;"
          class="btn"
          [ngClass]="isActiveProduct(i) ? 'app-btn-success' : 'app-btn-outline-success'"
          type="button"
          (click)="onSelectProduct(i)">
    {{ product.name }}
  </button>

  <div *ngIf="noProducts()"
       class="p-2 rounded text-center app-bg-light app-text-neutral">
    No Insurance Products Set.
  </div>

</section>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, Subject } from "rxjs";
import { Vehicle, WarrantyUi } from "../../models";
import { takeUntil } from "rxjs/operators";
import { DealService, VehicleService, WarrantyUiService } from "../../services";

@Component({
  selector: "app-warranty",
  templateUrl: "./warranty.component.html",
  styleUrls: ["./warranty.component.scss"]
})
export class WarrantyComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  private uiState = {
    warrantyUi: null,
    vehicle: null
  };

  constructor(
    private warrantyUiService: WarrantyUiService,
    private vehicleService: VehicleService,
    private dealService: DealService,
  ) { }

  ngOnInit() {
    this.warrantyUiService.initializeWarrantyUi();
    combineLatest([
      this.vehicleService.selectVehicle(),
      this.warrantyUiService.warrantyUiObservable(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([vehicle, warrantyUi]: [Vehicle, WarrantyUi]) => {
        this.uiState.vehicle = vehicle;
        this.uiState.warrantyUi = warrantyUi;
      });

    this.dealService.dealInsuranceService.selectInsuranceProducts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((insuranceProducts) => {
        this.warrantyUiService.configInitialProductSelections(
          this.uiState.warrantyUi,
          this.uiState.vehicle.insuranceProducts,
          insuranceProducts
        );
        this.warrantyUiService.configProductGraphWidths(this.uiState.warrantyUi);
      });


  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.dealService.dispatchChangeDeal();
  }

  // UI RENDERING

  isValidWarrantyUi(): boolean {
    if (!this.warrantyUi) { return false; }
    const {productGraphs} = this.warrantyUi;
    return productGraphs.length > 0;
  }

  get warrantyUi(): WarrantyUi {
    return this.uiState.warrantyUi || null;
  }

  get vehicle() {
    return this.uiState.vehicle || null;
  }

  get vehicleUsedOrCertified(): boolean {
    const used = this.warrantyUi.vehicleCondition === "used";
    const certified = this.warrantyUi.vehicleCondition === "certified";
    return used || certified;
  }

  get carfaxUrl(): string {
    const url = "http://www.carfax.com/VehicleHistory/p/Report.cfx?vin=";
    return this.vehicle ? url + this.vehicle.vin : "";
  }

}

<div class="position-absolute px-4 py-5 px-md-5 app-bg-primary"
     style="top: 0; left: 0; width: 100vw; min-height: 100vh">
  <div class="d-flex justify-content-center mb-4">
    <a routerLink="/">
      <img src="/static/images/BT_reverse.png" style="width: 200px"/>
    </a>
  </div>

  <div class="layout-sales-cue-display">
    <!-- GUEST COUNT -->
    <aside id="guest-count" class="d-flex flex-column align-items-center">
      <div class="app-text-white mb-1" style="font-size: 0.75rem">With Guest</div>
      <div class="d-flex align-items-center justify-content-center app-bg-white rounded-pill p-1">
        <span class="d-flex align-items-center justify-content-center app-bg-secondary app-text-white rounded-circle mr-2"
              style="height: 30px; width: 30px">
          {{ withGuestCount }}
        </span>
        <i class="material-icons app-text-secondary mr-1">person</i>
      </div>
    </aside>

    <!-- AVAILABLE COUNT -->
    <aside id="available-count" class="d-flex flex-column align-items-center">
      <div class="app-text-white mb-1" style="font-size: 0.75rem">Available</div>
      <div class="d-flex align-items-center justify-content-center app-bg-white rounded-pill p-1">
        <span class="d-flex align-items-center justify-content-center"
              style="height: 30px; width: 30px">
          {{ availableCount }}
        </span>
      </div>
    </aside>

    <!-- SALES CUE LIST -->
    <section id="sales-cue" class="mt-1">
      <article *ngFor="let person of salesQueue; let i = index"
               class="cue-item rounded-pill"
               [ngClass]="{ 'cue-item-position-1': i === 0, 'cue-item-next-up': i === 1 }">
        <div class="d-flex align-items-center">
          <img *ngIf="person.photoUrl"
               [src]="person.photoUrl"
               class="cue-item-img"
               (error)="person.photoUrl = null"/>

          <div *ngIf="!person.photoUrl"
               class="cue-item-img app-bg-dark app-text-light d-flex align-items-center justify-content-center">
            {{ nameInitials(person) }}
          </div>

          <div class="cue-item-name" [ngClass]="{ 'app-text-secondary': person.withCustomer }">
            {{ fullName(person) | titlecase }}
          </div>
        </div>

        <i *ngIf="person.withCustomer" class="cue-item-icon material-icons app-text-secondary">
          person
        </i>

        <div *ngIf="i === 1" class="cue-item-next-up-msg">
          <div class="cue-item-next-up-msg-line app-bg-primary-accent"></div>
          <span class="cue-item-next-up-msg-text app-bg-primary app-text-primary-accent">
            Next Up
          </span>
        </div>
      </article>
    </section>
  </div>
</div>

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { AuthService } from "./auth.service";
import { User } from "src/app/user-admin-module/models";
import { USER_ROLES } from "src/app/app.config";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AppService } from "src/app/clearpath-module/services";

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private appService: AppService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.selectUser().pipe(
      first(),
      map(currentUser => {


        // Check for token in query params
        const token = route.queryParams.token || this.authService.token;
        if (route.queryParams.token) {
          if (route.queryParams.token !== this.authService.token) {
            this.authService.token = route.queryParams.token;
            if (!currentUser) {
              this.authService.loginWithToken(token);
            }
            return true;
          }
        }
        if (token && !currentUser) {
          this.authService.loginWithToken(token);
          return true;
        }

        // Login Check
        if (!currentUser) {
          const resetPassword = state.url.includes("reset-password");
          return resetPassword ? this.routeToResetPassword(state) : this.routeToLogin();
        }

        if (!token) {
          // if no token, log user out and send to login page
          this.routeToLogin();
          return;
        }

        const userRoles = User.unpackSecurityGroups(currentUser.securityGroups);

        // Security Group Check
        return this.checkSecurityGroups(route.data, userRoles);
        ;
      })
    );
  }

  checkSecurityGroups(routeData, userRoles) {
    const expectedRoles = routeData ? routeData.expectedRoles : false;
    if (!expectedRoles) {
      return true;
    } else {
      return expectedRoles.filter(expectedRole =>
        userRoles.includes(expectedRole) || userRoles.includes(USER_ROLES.sys_admin)
      ).length > 0;
    }
  }

  // REROUTES

  private routeToHome() {
    return this.router.createUrlTree(["/"]);
  }

  private routeToLogin() {
    //console.log("Route to Login");
    this.authService.logout()
    return this.router.createUrlTree(["/login"]);
  }

  private routeToResetPassword(state) {
    const tokenExtract = state.url.split("token=")[ 1 ];
    return this.router.createUrlTree(["/reset-password"], {
      queryParams: {token: tokenExtract}
    });
  }
}

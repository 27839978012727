<div *ngIf="termRateControls.length === 0"
     class="app-bg-light app-text-neutral rounded text-center px-2 py-3 mb-3">
  No Finance Rates Set.
</div>

<form [formGroup]="financeRatesForm">
  <div formArrayName="termRates">

    <article *ngFor="let termRate of termRateControls; let i = index"
             class="card card-body app-bg-light mb-3"
             [formGroupName]="i">

      <div class="row">
        <div class="form-group col-sm-6">
          <label [ngClass]="{ 'app-text-danger': touchedInvalid(i, 'term') }">
            Term *
          </label>
          <input class="form-control"
                 formControlName="term"
                 type="number"
                 min="1"
                 required>
        </div>

        <div class="form-group col-sm-6">
          <label [ngClass]="{ 'app-text-danger': touchedInvalid(i, 'minYear') }">
            Min Model Year *
          </label>
          <input class="form-control"
                 formControlName="minYear"
                 type="number"
                 min="1000"
                 max="9999"
                 required>
        </div>
      </div>

      <label class="font-weight-bold mb-3">Tiered Rates</label>
      <div formArrayName="tieredRates"
           class="tiered-rates-layout">
        <div *ngFor="let rate of tieredRatesControls(i); let j = index">
          <label [ngClass]="{ 'app-text-danger': touchedInvalidRate(i, j) }"
                 class="d-flex justify-content-between align-items-center">
            <span>Tier {{ j }} *</span>
            <a class="btn btn-link p-0"><i class="material-icons app-text-small app-text-danger"
                                           (click)="onRemoveTier(i, j)">close</i></a>
          </label>
          <input class="form-control"
                 [formControlName]="j"
                 type="number"
                 min="0"
                 max="1"
                 required>
        </div>
        <div>
          <label style="padding-top: 3em;"></label>
          <button class="btn app-btn-secondary btn-small app-text-small"
                  type="button"
                  (click)="onAddTierRate(i)"
                  [disabled]="waitOnAction">
            Add Tier Rate
          </button>
        </div>
      </div>

      <button class="btn app-btn-neutral d-flex align-items-center justify-content-center"
              style="position: absolute; top: 0; right: 0"
              type="button"
              (click)="onRemoveTermRate(i)">
        <i class="material-icons">close</i>
      </button>
    </article>

    <div class="d-flex justify-content-between">
      <button class="btn app-btn-secondary"
              type="button"
              (click)="onAddTermRate()"
              [disabled]="waitOnAction">
        Add Term
      </button>
      <button class="btn app-btn-secondary"
              type="button"
              (click)="onSubmitFinanceRatesForm()"
              [disabled]="waitOnAction || financeRatesForm.pristine || financeRatesForm.invalid">
        Submit
      </button>
    </div>

  </div>
</form>

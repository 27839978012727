import { Component, Input, OnChanges } from "@angular/core";
import { NewResiduals } from "../../../models/lease-residuals";

@Component({
  selector: "app-new-residuals-table",
  templateUrl: "./new-residuals-table.component.html",
  styleUrls: ["./new-residuals-table.component.scss"]
})
export class NewResidualsTableComponent implements OnChanges {

  @Input() residuals: NewResiduals;

  headers: string[];
  emphasizedTerms = [24, 36, 48, 60];

  constructor() { }

  ngOnChanges() {
    this.headers = this.constructTableHeaders(this.residuals);
  }

  constructTableHeaders(residuals: NewResiduals): string[] {
    if (!residuals) { return; }
    const headers = ["Model #"];
    residuals.residualPercentages[ 0 ].termPercentages.forEach(el => {
      headers.push(el.term.toString());
    });
    return headers;
  }

  isEmphasizedTerm(input: string): boolean {
    const term = parseInt(input, 10);
    return this.emphasizedTerms.includes(term);
  }

}

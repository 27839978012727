import { Buyer } from "../../../models";

export const initialCustomerState: Buyer = {
  firstName: "",
  middleName: "",
  lastName: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  driverId: "",
  signatureUrl: "",
  crmLeadId: "",
  dmsContactId: "",
  crmContactId: ""
};

import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../../auth-module/services";
import { User } from "src/app/user-admin-module/models";
import { USER_ROLES } from "src/app/app.config";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RoutingService } from "src/app/clearpath-module/services";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"]
})
export class LandingPageComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  buttons = [];

  // TODO: Design a better system for permissions-based menu
  permissions = {
    sales_floor: {
      link: "/sales-queue-view",
      name: "Sales Cue View"
    },
    cue_display: {
      link: "/sales-queue-display",
      name: "Sales Cue Display"
    },
    cue_admin: {
      link: "/sales-queue-admin",
      name: "Sales Cue Admin"
    },
    sys_admin: {
      link: "/user",
      name: "User Admin"
    }
  };

  constructor(private authService: AuthService, private routingService: RoutingService) { }

  // INITIALIZATION

  ngOnInit() {
    this.routingService.routeToInitialView();
    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentUser => {
        const userRoles = User.unpackSecurityGroups(currentUser.securityGroups);
        const isSysAdmin = userRoles.includes(USER_ROLES.sys_admin);

        for (const securityGroup of Object.keys(this.permissions)) {
          const roleMatch = isSysAdmin || userRoles.includes(securityGroup);

          if (roleMatch) {
            this.buttons.push(this.permissions[ securityGroup ]);
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-clear-graph",
  templateUrl: "./clear-graph.component.html",
  styleUrls: ["./clear-graph.component.scss"]
})
export class ClearGraphComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

<div id="app-modal-container"
     class="app-modal-container"
     (click)="clickOutModal($event)">

  <section class="app-modal">

    <!-- HEADER -->
    <article class="app-modal-header">
      <h1 class="app-modal-title">
        {{ modalTitle }}
      </h1>
      <button class="app-modal-close-btn"
              type="button"
              (click)="closeModal()">
        <img src="static/images/close.png" alt="close button">
      </button>
    </article>

    <!-- BODY -->
    <article class="app-modal-body">
      <ng-content select="[modal-body]"></ng-content>
    </article>

    <!-- FOOTER -->
    <article *ngIf="showFooter" class="app-modal-footer">
      <ng-content select="[modal-footer]"></ng-content>
    </article>

  </section>

</div>

import { Component, Input } from "@angular/core";
import { Vehicle, WarrantyBarGraphSegment, WarrantyUi } from "../../../../models";

@Component({
  selector: "app-warranty-graph",
  templateUrl: "./warranty-graph.component.html",
  styleUrls: ["./warranty-graph.component.scss"]
})
export class WarrantyGraphComponent {
  @Input() warrantyUi: WarrantyUi;
  @Input() vehicle: Vehicle;

  constructor() { }

  // UI RENDERING

  bgGraphClasses(graph: WarrantyBarGraphSegment): string {
    const isVisible = graph.visibility.includes(this.warrantyUi.activeViewIndex);
    return isVisible ? `${graph.bgClass} bg-graph--visible` : graph.bgClass;
  }

  showTitle(type: string) {
    const {activeViewIndex, titleIndex} = this.warrantyUi;
    return titleIndex[ type ].includes(activeViewIndex);
  }

}

<app-modal-view modalTitle="Tax Lookup" [showFooter]=true>

  <section modal-body class="tax-lookup-modal" *ngIf="!responseReceived">
    <h2>Buyer Address</h2>
    <form [formGroup]="buyerAddressForm">
      <div class="form-section buyer-address">
        <mat-form-field appearance="fill">
          <mat-label>Street</mat-label>
          <input matInput formControlName="street">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>City</mat-label>
          <input matInput formControlName="city">
        </mat-form-field>
        <div></div>
        <mat-form-field appearance="fill">
          <mat-label>County</mat-label>
          <input matInput formControlName="county">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>State</mat-label>
          <input matInput formControlName="state">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Zip Code</mat-label>
          <input matInput formControlName="zipCode">
        </mat-form-field>
        <!--mat-form-field appearance="fill">
          <mat-label>Zip Code Extension</mat-label>
          <input matInput formControlName="zipCodeExt">
        </mat-form-field-->
      </div>
    </form>
    <!--<form [formGroup]="buyerForm">
      <h2>Buyer Additional Information</h2>
      <div class="form-section buyer-info">
        <mat-form-field appearance="fill">
          <mat-label>Is Individual?</mat-label>
          <mat-select formControlName="isIndividual">
            <mat-option [value]=true>Yes</mat-option>
            <mat-option [value]=false>No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Owner Type</mat-label>
          <mat-select formControlName="ownerType">
            <mat-option value="Individual">Individual</mat-option>
            <mat-option value="Business">Business</mat-option>
            <mat-option value="Government">Government</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>fein (if business)</mat-label>
          <input matInput formControlName="fein">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Business Name (if business)</mat-label>
          <input matInput formControlName="businessName">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>dlNotMatchName?</mat-label>
          <mat-select formControlName="dlNotMatchName">
            <mat-option [value]=true>Yes</mat-option>
            <mat-option [value]=false>No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>ownersAddressMatchOwnersId?</mat-label>
          <mat-select formControlName="ownersAddressMatchOwnersId">
            <mat-option [value]=true>Yes</mat-option>
            <mat-option [value]=false>No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>ownersAddressMatchOwnersId?</mat-label>
          <mat-select formControlName="ownersAddressMatchOwnersId">
            <mat-option [value]=true>Yes</mat-option>
            <mat-option [value]=false>No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>applyingForPrimaryAddressException?</mat-label>
          <mat-select formControlName="applyingForPrimaryAddressException">
            <mat-option [value]=true>Yes</mat-option>
            <mat-option [value]=false>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <form [formGroup]="vehicleForm">
      <h2>Vehicle Information</h2>
      <div class="form-section buyer-address">
        <mat-form-field appearance="fill">
          <mat-label>vehicleType</mat-label>
          <mat-select formControlName="vehicleType">
            <mat-option value="Passenger">Passenger</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>fuelType</mat-label>
          <mat-select formControlName="fuelType">
            <mat-option value="Electric">Electric</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>useType</mat-label>
          <mat-select formControlName="useType">
            <mat-option value="Personal">Personal</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>year</mat-label>
          <input matInput formControlName="year">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>unladenWeight</mat-label>
          <input matInput formControlName="unladenWeight">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>numberOfLienholder</mat-label>
          <input matInput formControlName="numberOfLienholder">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>manufacturesSuggestedRetailPrice</mat-label>
          <input matInput formControlName="manufacturesSuggestedRetailPrice">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>addOns</mat-label>
          <input matInput formControlName="addOns">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>color</mat-label>
          <input matInput formControlName="color">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>make</mat-label>
          <input matInput formControlName="make">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>model</mat-label>
          <input matInput formControlName="model">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>vin</mat-label>
          <input matInput formControlName="vin">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>bodyType</mat-label>
          <input matInput formControlName="bodyType">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>bodyTypeId</mat-label>
          <input matInput formControlName="bodyTypeId">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>grossWeight</mat-label>
          <input matInput formControlName="grossWeight">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>odometerReading</mat-label>
          <input matInput formControlName="odometerReading">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>taxableSellingPrice</mat-label>
          <input matInput formControlName="taxableSellingPrice">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>documentFees</mat-label>
          <input matInput formControlName="documentFees">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>SaleType</mat-label>
          <mat-select formControlName="saleType">
            <mat-option value="Purchase">Purchase</mat-option>
            <mat-option value="Lease">Lease</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Vehicle Sold As</mat-label>
          <mat-select formControlName="vehicleSoldAs">
            <mat-option value="New">New</mat-option>
            <mat-option value="Used">Used</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Odometer Validity</mat-label>
          <mat-select formControlName="odometerValidity">
            <mat-option value="Actual">Actual</mat-option>
            <mat-option value="NotActual">Not Actual</mat-option>
            <mat-option value="ExceedsMechanicalLimits">Exceeds Mechanical Limits</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Is Owner Military?</mat-label>
          <mat-select formControlName="isOwnerMilitary">
            <mat-option [value]=true>Yes</mat-option>
            <mat-option [value]=false>No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>-->
  </section>

  <div modal-body *ngIf="responseReceived" style="display: flex;gap:20px;flex-direction: column">
    <section class="tax-lookup-modal">
      <h2>Total State Taxes & Fees</h2>
      <div class="data-table">
        <div class="data-row">
          <div class="cell-label">Total State Tax:</div>
          <div class="cell-data">{{totalTaxes |currency:'USD':'symbol':'1.2-2'}}</div>
        </div>
        <div class="data-row">
          <div class="cell-label">Total State Fees:</div>
          <div class="cell-data">{{totalFees |currency:'USD':'symbol':'1.2-2'}}</div>
        </div>
        <div class="data-row">
          <div class="cell-label">Combined Total:</div>
          <div class="cell-data">{{totalTaxes + totalFees |currency:'USD':'symbol':'1.2-2'}}</div>
        </div>
      </div>
    </section>
    <section class="tax-lookup-modal">
      <h2>State Taxes & Fees Details by Line Item</h2>
      <ngx-json-viewer [json]="responseData" [expanded]="false"></ngx-json-viewer>
    </section>
  </div>

  <section modal-footer class="footer">
    <ng-container *ngIf="!responseReceived">
      <button class="btn btn-sm rounded-pill app-btn-success mr-1 mx-1 form-button" (click)="lookupTaxes()">Lookup State Taxes & Fees</button>
    </ng-container>
    <ng-container *ngIf="responseReceived">
      <button class="btn btn-sm rounded-pill mr-1 mx-1" (click)="back()">Back</button>
      <button class="btn btn-sm rounded-pill app-btn-success mr-1 mx-1 form-button" (click)="applyUpdates()">Apply State Taxes & Fees to Deal</button>
    </ng-container>
  </section>

</app-modal-view>

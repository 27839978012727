<div class="app-page-container">

  <section class="d-flex align-items-center justify-content-between flex-wrap">
    <button *ngIf="!inSalesQueue()" class="btn app-btn-secondary mb-3" type="button" (click)="onJoinSalesQueue()"
            [disabled]="waitOnAction() || waitOnData()">
    <span *ngIf="waitOnAction()" class="spinner-border spinner-border-sm mr-1">
    </span>
      Join Queue
    </button>
    <button *ngIf="inSalesQueue()" class="btn app-btn-success mb-3" type="button" (click)="onLeaveSalesQueue()"
            [disabled]="waitOnAction() || waitOnData()">
    <span *ngIf="waitOnAction()" class="spinner-border spinner-border-sm mr-1">
    </span>
      Leave Queue
    </button>
  </section>

  <app-q-list [salesCue]="salesCue"></app-q-list>

</div>

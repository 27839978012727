<app-modal-view #modal="modal" modalTitle="">
  <section modal-body>
    <div>
      <img
        class="mx-auto d-block"
        src="static/images/banner.png"
        alt="banner"
      />
      <h3 class="header text-center">
        {{ deal?.customer?.firstName ? deal.customer.firstName + "'s New Car" : "" }}
      </h3>
      <img
        class="mx-auto d-block mb-3"
        src="static/images/banner.png"
        alt="banner"
      />
      <div class="img mx-auto d-block m-3">
        <app-vehicle-image [vehicle]="vehicle"></app-vehicle-image>
      </div>
      <p class="text text-center">
        {{ deal?.customer?.firstName ? deal.customer.firstName + "'s " : "" }}{{ deal.vehicle | vehicleName }}
      </p>
      <h4 class="text text-center font-weight-bold">Confirmation</h4>
      <header class="first">
        <p>Vehicle Costs</p>
      </header>
    </div>
    <div *ngIf="deal" class="d-flex flex-column p-2">
      <!-- row 1 -->
      <div
        class="d-flex" style="gap: 20px"
        [ngClass]="{ 'flex-column': uiState.screenWidth < 1024 }"
      >
        <!-- VEHICLE INFO -->
        <div class="card card-body flex-item p-0">
          <div class="flex-row align-items-baseline sectionTitle">
            <ng-container *ngIf="uiState.vehicleChecked; else unchecked">
              <img
                class="m-2"
                src="static/images/checked.png"
                style="cursor: pointer"
                (click)="toggleChecked('vehicleChecked')"
              />
            </ng-container>
            <ng-template #unchecked>
              <img
                class="m-2"
                src="static/images/unchecked.png"
                style="cursor: pointer"
                (click)="toggleChecked('vehicleChecked')"
              />
            </ng-template>
            <label>
              <h5
                class="mt-3"
                [ngClass]="
                  !uiState.vehicleChecked && uiState.submit
                    ? 'app-text-danger'
                    : ''
                "
              >
                Vehicle and Price
              </h5>
            </label>
          </div>
          <table class="table table-striped m-0 p-0">
            <thead>
            <tr>
              <th></th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Base Price</td>
              <td>
                {{
                baseVehiclePrice$ | async | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr *ngFor="let acc of dealerAccessories">
              <ng-container *ngIf="deal && deal.selectedDealerAccessories && deal.selectedDealerAccessories.includes(acc.name)">
                <td>+ {{ acc.name }}</td>
                <td>{{ acc.price | currency: "USD":"symbol":"1.2-2" }}</td>
              </ng-container>
            </tr>
            <ng-container *ngFor="let acc of deal.accessories">
              <ng-container *ngIf="!acc.disabled">
                <tr>
                  <td>+ {{ acc.name }}</td>
                  <td>{{ acc.price | currency: "USD":"symbol":"1.2-2" }}</td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td>Customized Vehicle Price</td>
              <td>
                {{
                customizedVehiclePrice$
                  | async
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr>
              <td>Rebates</td>
              <td>{{ cashRebates | currency: "USD":"symbol":"1.2-2" }}</td>
            </tr>
            <tr *ngIf="!deal.leaseOptions.leaseSelected">
              <td>Trade Allowance</td>
              <td>
                {{
                tradeAllowanceEstimate$
                  | async
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr *ngIf="!deal.leaseOptions.leaseSelected">
              <td>Trade Payoff</td>
              <td>
                {{
                tradePayoffEstimate$
                  | async
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr
              *ngIf="
                  !deal.leaseOptions.leaseSelected ||
                  (deal.leaseOptions.leaseSelected &&
                    (tradeEquityEstimateIsNegative$ | async))
                "
            >
              <td>Trade Equity</td>
              <td>
                {{
                tradeEquityEstimate$
                  | async
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr *ngIf="!deal.leaseOptions.leaseSelected">
              <td>Down Payment</td>
              <td>
                {{
                deal.financeOptions.downPayment
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr>
              <td>DMV & Document Fees</td>
              <td>
                {{
                deal.financeOptions.totalFees
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr *ngIf="deal.leaseOptions.leaseSelected">
              <td>Acquisition Fee</td>
              <td>
                {{
                deal.leaseOptions.acquisitionFee ||
                financingSettings.leaseDefault.acquisitionFee
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr
              *ngIf="
                  deal.leaseOptions.leaseSelected &&
                  (excessMileageCharge$ | async) as excessMileageCharge
                "
            >
              <td>
                Prepaid Miles
                <p>
                  <small
                  >Usage in excess of 15,000 miles per year @ $0.10 per
                    mile. Mileage overages at end of term are charged at
                    higher rate.
                    <a class="app-text-blue" (click)="toggleAdjustUsage()"
                    >Adjust usage</a
                    ></small
                  >
                </p>
                <br/>
                <form
                  style="width: 60%"
                  *ngIf="uiState.adjustUsage"
                  [formGroup]="vehicleNeedsForm"
                >
                  <select
                    class="form-control"
                    formControlName="milesDrivenPerYear"
                    (change)="submitVehicleNeedsForm()"
                    [value]="deal.vehicleNeeds.milesDrivenPerYear"
                  >
                    <option
                      *ngFor="let option of milesOptions"
                      [value]="option.miles"
                    >
                      {{ option.title }}
                    </option>
                  </select>
                </form>
              </td>
              <td>
                {{ excessMileageCharge | currency: "USD":"symbol":"1.2-2" }}
              </td>
            </tr>
            <tr>
              <td>Taxes</td>
              <td>
                <ng-container *ngIf="deal.leaseOptions.leaseSelected">
                  {{
                  ((taxResult$ | async).totalTax || 0)
                    | currency: "USD":"symbol":"1.2-2"
                  }}

                </ng-container>
                <ng-container *ngIf="!deal.leaseOptions.leaseSelected">
                  {{
                  (financeOrCashBaseTax || 0)
                    | currency: "USD":"symbol":"1.2-2"
                  }}

                </ng-container>
              </td>
            </tr>
            <tr *ngIf="!deal.leaseOptions.leaseSelected" style="font-weight: bold">
              <td *ngIf="!deal.financeOptions.cashPurchase">
                Base Amount Financed
              </td>
              <td *ngIf="deal.financeOptions.cashPurchase">
                Base Amount Paid
              </td>
              <td>
                {{
                (financeOptions.cashPurchase
                    ? vehicleBaseFinanceAmountForCash$
                    : vehicleBaseFinanceAmount$
                )
                  | async
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr *ngIf="deal.leaseOptions.leaseSelected" style="font-weight: bold">
              <td>Base Gross Capitalized Cost</td>
              <td>
                {{ grossCapCost$ | async | currency: "USD":"symbol":"1.2-2" }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- LEASE - only show if this is a lease -->
        <div
          *ngIf="deal.leaseOptions.leaseSelected"
          class="card card-body flex-item p-0">
          <div class="flex-row align-items-baseline sectionTitle">
            <ng-container *ngIf="uiState.dueTodayChecked; else unchecked">
              <img
                class="m-2"
                src="static/images/checked.png"
                style="cursor: pointer"
                (click)="toggleChecked('dueTodayChecked')"
              />
            </ng-container>
            <ng-template #unchecked>
              <img
                class="m-2"
                src="static/images/unchecked.png"
                style="cursor: pointer"
                (click)="toggleChecked('dueTodayChecked')"
              />
            </ng-template>
            <label>
              <h5
                class="mt-3"
                [ngClass]="
                  !uiState.dueTodayChecked && uiState.submit
                    ? 'app-text-danger'
                    : ''
                "
              >
                Due at Signing
              </h5>
            </label>
          </div>

          <table class="table table-striped m-0 p-0" style="width: 100%">
            <thead>
            <tr>
              <th></th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <!-- <tr>
              <td>Rebates</td>
              <td>{{cashRebates | currency:'USD':'symbol':'1.2-2'}}</td>
            </tr> -->
            <tr>
              <td>Down Payment</td>
              <td>
                {{
                deal.financeOptions.downPayment
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr *ngIf="!(tradeEquityEstimateIsNegative$ | async)">
              <td>Trade Equity</td>
              <td>
                {{
                tradeEquityEstimate$
                  | async
                  | currency: "USD":"symbol":"1.2-2"
                }}
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{{ DueAtSigning | currency: "USD":"symbol":"1.2-2" }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Due Today</td>
              <td></td>
            </tr>
            <tr>
              <td>First Month's Payment</td>
              <td>
                {{ this.totalMonthlyLeasePayment$ | async | currency: "USD" }}
              </td>
            </tr>
            <tr>
              <td>Capitalized Cost Reduction</td>
              <td>{{ CCR$ | async | currency: "USD" }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- end row 1 -->
      </div>

      <!-- row 2 -->
      <div
        class="d-flex mt-3 mb-3"
        [ngClass]="{ 'flex-column': uiState.screenWidth < 1024 }"
      >

        <div
          class="d-flex" style="justify-content: stretch;width: 100%;gap:20px"
          [ngClass]="{ 'flex-column': uiState.screenWidth < 1024 }">

          <!-- DECLINED INSURANCE PRODUCTS -->
          <div
            *ngIf="declinedProducts?.length > 0"
            class="card flex-item"
            style="width: 100%;"
          >
            <div class="flex-row sectionTitle align-items-baseline">
              <ng-container *ngIf="uiState.declineChecked; else unchecked">
                <img
                  class="m-2"
                  src="static/images/checked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('declineChecked')"
                />
              </ng-container>
              <ng-template #unchecked>
                <img
                  class="m-2"
                  src="static/images/unchecked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('declineChecked')"
                />
              </ng-template>
              <label>
                <h5
                  [ngClass]="
                    !uiState.declineChecked && uiState.submit
                      ? 'app-text-danger'
                      : ''
                  "
                  class="card-title"
                >
                  Decline
                </h5>
              </label>
            </div>

            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Amount</th>
                <th *ngIf="financeOptions.financeSelected">Monthly</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let product of declinedProducts">
                <td>{{ product.name }}</td>
                <td>
                  {{
                  productPrice(product) | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
                <td *ngIf="financeOptions.financeSelected">
                  {{
                  calculateInsuranceProductMonthlyPayment$(product)
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- LEASE INSURANCE OPTIONS - only show if this is a lease -->
          <div *ngIf="deal.leaseOptions.leaseSelected" class="card p-0 flex-item" style="width: 100%;">
            <div class="flex-row align-items-baseline sectionTitle">
              <ng-container *ngIf="uiState.leaseChecked; else unchecked">
                <img
                  class="m-2"
                  src="static/images/checked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('leaseChecked')"
                />
              </ng-container>
              <ng-template #unchecked>
                <img
                  class="m-2"
                  src="static/images/unchecked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('leaseChecked')"
                />
              </ng-template>
              <label>
                <h5
                  [ngClass]="
                  !uiState.leaseChecked && uiState.submit
                    ? 'app-text-danger'
                    : ''
                "
                  class="card-title mt-3"
                >
                  {{ deal.leaseOptions.selectedLeaseTerm }} Month Lease
                </h5>
              </label>
            </div>
            <table class="table table-striped m-0 p-0">
              <thead>
              <tr>
                <th></th>
                <th>Amount</th>
                <th>Monthly</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Base Payment</td>
                <td></td>
                <td>
                  {{
                  baseMonthlyLeasePayment$
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
              </tr>
              <tr *ngFor="let product of leaseInsuranceProducts">
                <td>
                  + {{ product.name }}
                  <br/>
                  {{ closestTermTerm(product) }} month /
                  {{ closestTermMiles(product) }} miles
                </td>
                <td>
                  {{
                  closestTermPrice(product) | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
                <td>
                  {{
                  calculateInsuranceProductMonthlyPaymentForLease$(product)
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Total</td>
                <td></td>
                <td class="font-weight-bold">
                  {{
                  totalMonthlyLeasePayment$
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- FINANCE -->
          <div
            *ngIf="financeOptions.financeSelected"
            class="card flex-item"
            style="width: 100%">
            <div class="flex-row sectionTitle align-items-baseline">
              <ng-container *ngIf="uiState.financeChecked; else unchecked">
                <img
                  class="m-2"
                  src="static/images/checked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('financeChecked')"
                />
              </ng-container>
              <ng-template #unchecked>
                <img
                  class="m-2"
                  src="static/images/unchecked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('financeChecked')"
                />
              </ng-template>
              <label>
                <h5 [ngClass]="!uiState.financeChecked && uiState.submit ? 'app-text-danger' : ''" class="card-title">
                  {{
                  financeOptions.financeSelected &&
                  financeOptions.selectedFinancingTerm
                  }}
                  Month Financing
                </h5>
              </label>
            </div>

            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Amount</th>
                <th>Monthly</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Base Payment</td>
                <td>
                  {{
                  vehicleBaseFinanceAmount$
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
                <td>
                  {{ baseMonthlyFinancePayment$() | async | currency: "USD" }}
                </td>
              </tr>
              <tr *ngFor="let product of financeInsuranceProducts">
                <td>
                  + {{ product.name }} {{ productDescription(product) }}
                </td>
                <td>{{displayFinanceInsuranceProductsAmount(product) | currency: "USD" : "symbol" : "1.2-2"}}</td>
                <td>{{calculateInsuranceProductMonthlyPayment$(product) | async | currency: "USD"}}</td>
              </tr>
              <tr>
                <td>Taxes</td>
                <td>
                  <ng-container *ngIf="(vehicleBaseFinanceAmount$ | async) as vehicleBaseFinanceAmount">
                    <ng-container *ngIf="(totalVehicleFinancePrice$ | async ) as totalVehicleFinancePrice">
                      <ng-container *ngIf="(financeInsuranceProducts)">
                        {{calcFinanceAccPrice(vehicleBaseFinanceAmount, totalVehicleFinancePrice, financeInsuranceProducts)}}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="(baseMonthlyFinancePayment$() | async) as baseMonthlyFinancePayment">
                    <ng-container *ngIf="(totalVehicleFinanceMonthlyPayment$ | async ) as totalVehicleFinanceMonthlyPayment">
                      <ng-container *ngIf="(financeInsuranceProducts)">
                        {{calcFinanceMonthlyAccPrice(baseMonthlyFinancePayment, totalVehicleFinanceMonthlyPayment, financeInsuranceProducts, interestRate, deal?.financeOptions)}}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
              <tr style="font-weight: bold">
                <td>Total</td>
                <td>
                  {{
                  totalVehicleFinancePrice$
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
                <td>
                  {{
                  totalVehicleFinanceMonthlyPayment$
                    | async
                    | currency: "USD"
                  }}
                </td>
              </tr>
              <tr>
                <td>Interest Rate</td>
                <td>{{ interestRate$ | async | percent: "1.1-2" }} APR</td>
                <td></td>
              </tr>
              <tr>
                <td class="app-text-small app-text-dark">
                  * On approved credit. Monthly payment on this worksheet may
                  differ from actual monthly payment.
                </td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- CASH SALE -->
          <div *ngIf="financeOptions.cashPurchase" class="card flex-item" style="width: 100%">
            <div class="flex-row sectionTitle align-items-baseline">
              <ng-container *ngIf="uiState.cashChecked; else unchecked">
                <img
                  class="m-2"
                  src="static/images/checked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('cashChecked')"
                />
              </ng-container>
              <ng-template #unchecked>
                <img
                  class="m-2"
                  src="static/images/unchecked.png"
                  style="cursor: pointer"
                  (click)="toggleChecked('cashChecked')"
                />
              </ng-template>
              <label>
                <h5
                  [ngClass]="
                    !uiState.cashChecked && uiState.submit
                      ? 'app-text-danger'
                      : ''
                  "
                  class="card-title"
                >
                  Cash
                </h5>
              </label>
            </div>

            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Amount</th>
                <th *ngIf="!financeOptions.cashPurchase">Monthly</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Base Payment</td>
                <td>
                  {{
                  vehicleBaseFinanceAmountForCash$
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
              </tr>
              <tr *ngFor="let product of cashInsuranceProducts">
                <td>+ {{ product.name }}</td>
                <td>
                  {{
                  pathOr(
                    0,
                    ["termCosts", product.selectedTerm, "price"],
                    product
                  ) | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
              </tr>
              <tr>
                <td>Taxes</td>
                <td>
                  <ng-container *ngIf="(vehicleBaseFinanceAmountForCash$ | async) as vehicleBaseFinanceAmountForCash">
                    <ng-container *ngIf="(totalVehicleCashPrice$ | async ) as totalVehicleCashPrice">
                      <ng-container *ngIf="(cashInsuranceProducts)">
                        {{calcCashAccPrice(vehicleBaseFinanceAmountForCash, totalVehicleCashPrice, cashInsuranceProducts)}}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
              <tr style="font-weight: bold">
                <td>Total</td>
                <td>
                  {{
                  totalVehicleCashPrice$
                    | async
                    | currency: "USD":"symbol":"1.2-2"
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end row 2  -->
      </div>

      <!-- row 3 -->
      <div
        class="d-flex mb-5" style="justify-content: stretch;width: 100%;gap:20px"
        [ngClass]="{ 'flex-column': uiState.screenWidth < 1024 }">
        <!-- BUYER -->
        <div class="card card-body flex-item tight">
          <div class="d-flex flex-row align-items-baseline header-row">
            <h5 class="card-title">Buyer Information</h5>
          </div>
          <form [formGroup]="buyerForm">
            <table class="table table-striped">
              <tbody>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'firstName')
                    }">
                  First Name
                </td>
                <td>
                  <input
                    formControlName="firstName"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                    [ngClass]="{
                      'is-invalid': isNotValid('buyerForm', 'firstName')
                    }"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{'app-text-danger': isNotValid('buyerForm', 'middleName')}">
                  Middle Name
                </td>
                <td>
                  <input
                    formControlName="middleName"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{'app-text-danger': isNotValid('buyerForm', 'lastName')}">
                  Last Name
                </td>
                <td>
                  <input
                    formControlName="lastName"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"/>
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'street')
                    }"
                >
                  Address
                </td>
                <td>
                  <input
                    formControlName="street"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'city')
                    }"
                >
                  City
                </td>
                <td>
                  <input
                    formControlName="city"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'state')
                    }"
                >
                  State
                </td>
                <td>
                  <input
                    formControlName="state"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'zip')
                    }"
                >
                  Zip
                </td>
                <td>
                  <input
                    formControlName="zip"
                    class="float-right"
                    type="text"
                    inputmode="numeric"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'phone')
                    }"
                >
                  Phone Number
                </td>
                <td>
                  <input
                    formControlName="phone"
                    class="float-right"
                    type="text"
                    inputmode="numeric"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid': isNotValid('buyerForm', 'phone')
                    }"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'email')
                    }"
                >
                  Email Address
                </td>
                <td>
                  <input
                    formControlName="email"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                    [ngClass]="{
                      'is-invalid': isNotValid('buyerForm', 'email')
                    }"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>

        <!-- COBUYER -->

        <div class="card card-body flex-item tight">
          <div class="d-flex flex-row align-items-baseline panel-with-button header-row">
            <h5 class="card-title">Co-Buyer Information</h5>
            <button class="header-icon-button shift-down" (click)="copyAddressToCoBuyer()">
              <i class="material-icons app-text-white">assignment</i>
            </button>
          </div>
          <form [formGroup]="coBuyerForm">
            <table class="table table-striped">
              <tbody>
              <tr>
                <td>First Name</td>
                <td>
                  <input
                    formControlName="firstName"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td>Middle Name</td>
                <td>
                  <input
                    formControlName="middleName"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>
                  <input
                    formControlName="lastName"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>
                  <input
                    formControlName="street"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td>City</td>
                <td>
                  <input
                    formControlName="city"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td>State</td>
                <td>
                  <input
                    formControlName="state"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td
                  [ngClass]="{
                      'app-text-danger': isNotValid('buyerForm', 'zip')
                    }"
                >
                  Zip
                </td>
                <td>
                  <input
                    formControlName="zip"
                    class="float-right"
                    type="text"
                    inputmode="numeric"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>
                  <input
                    formControlName="phone"
                    class="float-right"
                    type="text"
                    maxlength="10"
                  />
                </td>
              </tr>
              <tr>
                <td>Email Address</td>
                <td>
                  <input
                    formControlName="email"
                    class="float-right"
                    type="text"
                    [maxlength]="REQS.maxLength.standard"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>

      <!-- trade in form -->
      <header>
        <p>Trade-in Information</p>
      </header>

      <button class="trade-in" *ngIf="uiState.showTradeIn1==false" (click)="uiState.showTradeIn1=true">Add Trade-in Vehicle</button>
      <button class="trade-in remove" *ngIf="uiState.showTradeIn1==true" (click)="uiState.showTradeIn1=false;uiState.showTradeIn2=false;">Remove Trade-in Vehicle{{uiState.showTradeIn2 ? 's' : ''}}</button>

      <div *ngIf="uiState.showTradeIn1==true" class="d-flex w-100 flex-column">
        <app-trade-in-form
          [formGroup]="tradeInForm"
          formLabel="Trade-in Vehicle">
        </app-trade-in-form>
        <app-trade-in-owner-form
          [formGroup]="tradeInOwnerForm"
          formLabel="Trade-in Owner"
          [showCopyButton]="true"
          (copyRequested)="copyTradeInOwnerAddress()">
        </app-trade-in-owner-form>
        <button class="trade-in" *ngIf="uiState.showTradeInOwner2==false" (click)="uiState.showTradeInOwner2=true">Add Second Owner to First Vehicle</button>
        <button class="trade-in remove" *ngIf="uiState.showTradeInOwner2==true" (click)="uiState.showTradeInOwner2=false">Remove Second Owner from First Vehicle</button>
        <app-trade-in-owner-form
          *ngIf="uiState.showTradeInOwner2==true"
          [formGroup]="tradeInOwner2Form"
          formLabel="Second Trade-in Owner">
        </app-trade-in-owner-form>
      </div>

      <ng-container *ngIf="uiState.showTradeIn1==true">
        <button class="trade-in" *ngIf="uiState.showTradeIn2==false" (click)="uiState.showTradeIn2=true">Add Second Trade-in Vehicle</button>
        <button class="trade-in remove" *ngIf="uiState.showTradeIn2==true" (click)="uiState.showTradeIn2=false;uiState.showTradeIn2Owner2=false;">Remove Second Trade-in Vehicle</button>
      </ng-container>

      <!-- trade in 2 form -->
      <div *ngIf="uiState.showTradeIn2==true" class="d-flex w-100 flex-column">
        <app-trade-in-form
          [formGroup]="tradeIn2Form"
          formLabel="Second Trade-in Vehicle">
        </app-trade-in-form>
        <app-trade-in-owner-form
          [formGroup]="tradeIn2OwnerForm"
          formLabel="Trade-in Owner for Second Vehicle">
        </app-trade-in-owner-form>
        <button class="trade-in" *ngIf="uiState.showTradeIn2Owner2==false" (click)="uiState.showTradeIn2Owner2=true">Add Second Owner to Second Vehicle</button>
        <button class="trade-in remove" *ngIf="uiState.showTradeIn2Owner2==true" (click)="uiState.showTradeIn2Owner2=false">Remove Second Owner from Second Vehicle</button>
        <app-trade-in-owner-form
          *ngIf="uiState.showTradeIn2Owner2==true"
          [formGroup]="tradeIn2Owner2Form"
          formLabel="Second Trade-in Owner for Second Vehicle">
        </app-trade-in-owner-form>
      </div>

      <!-- row 4 -->
      <header class="mt-5">
        <p>Insurance Information</p>
      </header>
      <div
        class="d-flex"
        [ngClass]="{ 'flex-column': uiState.screenWidth < 1024 }"
      >
        <!-- INSURANCE INFO -->
        <div class="subform-container card card-body flex-item p-0 mt-3">
          <div class="d-flex flex-row align-items-baseline"></div>
          <form [formGroup]="insuranceInformationForm">
            <table class="table table-striped m-0">
              <tbody>
              <tr style="background-color: #dddddd">
                <td>Insurance Information Available?</td>
                <td>
                  <select (change)="insuranceInfoAvailableChanged(insuranceInformationForm)"
                          formControlName="insuranceInfoAvailable"
                          class="float-right"
                  >
                    <option value="yes">Yes, insurance information available</option>
                    <option value="no">No, information not available</option>
                  </select>
                </td>
              </tr>
              <ng-container *ngIf="insuranceInformationForm.get('insuranceInfoAvailable').value ==='yes'">
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'companyName')
                    }"
                  >Insurance Company Name
                  </td>
                  <td>
                    <input
                      formControlName="companyName"
                      type="text"
                      [maxlength]="REQS.maxLength.standard"
                      class="float-right"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'companyName')
                    }"
                    />
                  </td>
                </tr>
                <!--<tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'street')
                    }"
                  >Address
                  </td>
                  <td>
                    <input
                      formControlName="street"
                      type="text"
                      [maxlength]="REQS.maxLength.standard"
                      class="float-right"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'street')
                    }"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'city')
                    }"
                  >City
                  </td>
                  <td>
                    <input
                      formControlName="city"
                      type="text"
                      [maxlength]="REQS.maxLength.standard"
                      class="float-right"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'city')
                    }"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'state')
                    }"
                  >State
                  </td>
                  <td>
                    <input
                      formControlName="state"
                      type="text"
                      [maxlength]="REQS.maxLength.standard"
                      class="float-right"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'state')
                    }"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'zip')
                    }"
                  >Zip Code
                  </td>
                  <td>
                    <input
                      formControlName="zip"
                      type="text"
                      maxlength="5"
                      class="float-right"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'zip')
                    }"
                    />
                  </td>
                </tr>-->
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'policyNumber')
                    }"
                  >Policy Number
                  </td>
                  <td>
                    <input
                      formControlName="policyNumber"
                      type="text"
                      [maxlength]="REQS.maxLength.standard"
                      class="float-right"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'policyNumber')
                    }"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'phone')
                    }"
                  >Phone Number
                  </td>
                  <td>
                    <input
                      formControlName="phone"
                      type="text"
                      inputmode="numeric"
                      maxlength="10"
                      class="float-right"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'phone')
                    }"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'policyEffectiveDate')
                    }"
                  >Policy Effective Date
                  </td>
                  <td>
                    <input
                      style="width:191px!important;"
                      formControlName="policyEffectiveDate"
                      id="policyEffectiveDate"
                      placeholder="Select Date"
                      type="date"
                      inputmode="numeric"
                      class="float-right date"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'policyEffectiveDate')
                    }"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    [ngClass]="{
                      'app-text-danger': isNotValid('insuranceInformationForm', 'policyExpirationDate')
                    }"
                  >Policy Expiration Date
                  </td>
                  <td>
                    <input
                      style="width:191px!important;"
                      formControlName="policyExpirationDate"
                      type="date"
                      inputmode="numeric"
                      id="policyExpirationDate"
                      placeholder="Select Date"
                      class="float-right date"
                      [ngClass]="{
                      'is-invalid': isNotValid('insuranceInformationForm', 'policyExpirationDate')
                    }"
                    />
                  </td>
                </tr>
                <!-- <tr>
                  <td>Agent
                    Name</td>
                  <td><input formControlName="agentName"
                           type="text"
                           [maxlength]="REQS.maxLength.standard"
                           class="float-right"></td>
                </tr> -->
              </ng-container>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <!-- SIGNATURE BOXES -->
      <div
        class="d-flex flex-column mt-3"
        [ngStyle]="{
          width:
            (uiState.screenWidth < 1035
              ? uiState.screenWidth / 1.2
              : uiState.screenWidth / 2) + 'px'
        }"
      >
        <div class="m-1">
          <app-signature-pad
            signatureTitle="Purchaser/Lessee Signature"
            *ngIf="!deal.customer.signatureUrl"
            [width]="
              uiState.screenWidth < 1035
                ? uiState.screenWidth / 1.2
                : uiState.screenWidth / 2
            "
            (latestImage)="captureBuyerSignature($event)"
          >
          </app-signature-pad>

          <div class="d-flex justify-content-between">
            <span
              *ngIf="deal.customer.signatureUrl"
              class="app-text-bold app-text-secondary ml-1"
            >
              Signed</span
            >

            <span
              *ngIf="!uiState.customerSigned && uiState.submit"
              class="app-text-danger"
            >Signature Required</span
            >
          </div>
        </div>
        <div class="m-1 mt-3">
          <app-signature-pad
            signatureTitle="Co-Signer Signature"
            *ngIf="!deal.coSigner.signatureUrl"
            [width]="
              uiState.screenWidth < 1035
                ? uiState.screenWidth / 1.2
                : uiState.screenWidth / 2
            "
            (latestImage)="captureCoBuyerSignature($event)"
          >
          </app-signature-pad>

          <div class="d-flex justify-content-between">
            <span
              *ngIf="deal.coSigner.signatureUrl"
              class="app-text-bold app-text-secondary ml-1"
            >
              Signed</span
            >

            <span
              *ngIf="!uiState.coSignerSigned && uiState.submit"
              class="app-text-danger"
            >Signature Required</span
            >
          </div>
        </div>
      </div>
      <!--div class="pt-4">
        <header>
          <p class="ml-2 p-0 commentsBox">Comments</p>
        </header>
        <textarea
          (change)="commentsValueChange($event)"
          class="form-control m-0 p-0"
          rows="7"
        >{{ comments }}</textarea
        >
      </div-->
      <div class="d-flex">
        <div class="mt-3" style="padding-bottom: 60px">
          <button
            *ngIf="showSubmit"
            class="btn d-flex align-items-center"
            (click)="submit()"
          >
            Submit Offer
            <div
              *ngIf="waitOnAction"
              class="spinner-border spinner-border-sm ml-2"
            ></div>
          </button>
          <button
            *ngIf="!showSubmit"
            class="btn app-btn-secondary d-flex align-items-center"
            (click)="unSubmit()"
          >
            Un-Submit Offer
            <div
              *ngIf="waitOnAction"
              class="spinner-border spinner-border-sm ml-2"
            ></div>
          </button>
        </div>
        <div *ngIf="uiState.invalidMessages.length" style="padding-top:23px">
          <ul>
            <li
              *ngFor="let invalidMessage of uiState.invalidMessages"
              class="app-text-danger"
            >
              {{ invalidMessage }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</app-modal-view>

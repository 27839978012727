<div class="app-page-container">

  <button *ngFor="let button of buttons"
          class="btn btn-block app-btn-secondary mx-auto mb-3"
          style="max-width: 600px"
          [routerLink]="button.link">
    {{ button.name }}
  </button>

</div>

<div *ngIf="touchedInvalid('roundTripCommuteMiles') || touchedInvalid('otherMilesPerWeek')"
     class="app-bg-light app-text-danger text-center mb-3 p-2">
  Mileage should be {{ mileage.min }} to {{ mileage.max}} miles.
</div>

<div *ngIf="touchedInvalid('commuteDaysPerWeek')"
     class="app-bg-light app-text-danger text-center mb-3 p-2">
  Commute days should be 1 to 7 days.
</div>

<form [formGroup]="milesPerYearForm"
      class="form-layout">

  <div>
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('roundTripCommuteMiles') }">
      Round Trip Commute Miles
    </label>
    <input class="form-control"
           formControlName="roundTripCommuteMiles"
           type="number"
           min="0"
           max="9999"
           (blur)="autoSubmitMilesPerYearForm()"
           required/>
  </div>

  <div>
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('commuteDaysPerWeek') }">
      Commute Days Per Week
    </label>
    <input class="form-control"
           formControlName="commuteDaysPerWeek"
           type="number"
           min="0"
           max="7"
           (blur)="autoSubmitMilesPerYearForm()"
           required/>
  </div>

  <div>
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('otherMilesPerWeek') }">
      Other Miles Per Week
    </label>
    <input class="form-control"
           formControlName="otherMilesPerWeek"
           type="number"
           min="0"
           max="9999"
           (blur)="autoSubmitMilesPerYearForm()"/>
  </div>

  <div class="form-result">
    <span class="h5 mb-0">
      {{ milesPerYearForm.valid ? (milesPerYear | number) : '---' }}
    </span>
    <span class="small">miles per year</span>
  </div>

</form>
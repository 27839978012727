import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { MenuItem } from "../../models/";
import { AppService } from "../../services";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/auth-module/services";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"]
})
export class SideNavComponent implements OnInit {
  isMenuOpen = false;
  menuItems$: Observable<MenuItem[]>;
  calculationsExposed: boolean;
  isProduction: boolean;
  isSalesManager$;
  isAdmin$;
  isQa: boolean;

  constructor(
    private appService: AppService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.menuItems$ = this.appService.selectMenuItems();
    this.isProduction = environment.production;
    this.isQa = environment.qa;
    this.isSalesManager$ = this.authService.isSalesManager$();
    this.isAdmin$ = this.authService.isAdmin$();
  }

  // UI CONTROL

  onSelectAuxiliaryModalRoute(menuItem: MenuItem) {
    this.onToggleMenu();

    const [outlet, path] = menuItem.linkUrl.split(":");
    const isAuxiliaryModalRoute = outlet === "modal";

    if (isAuxiliaryModalRoute) {
      this.router.navigate([{outlets: {[ outlet ]: path}}]);
    }
  }

  onToggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.calculationsExposed = false;
  }

  onSelectExposeCalculations() {
    this.calculationsExposed = true;
  }
}

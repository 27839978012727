import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../auth-module/services";
import { CanDeactivateGuard } from "../shared-module/services";
import { ArchiveComponent, DealerComponent, FinanceComponent, FormServerComponent, IncentivesComponent, InsuranceProductsComponent, LeaseResidualsComponent, MenuLinksComponent, SettingsMenuComponent } from "./pages/";
import { USER_ROLES } from "../app.config";

const settingsRoutes: Routes = [
  {
    path: "settings",
    children: [
      {
        path: "dealer",
        component: DealerComponent
      },
      {
        path: "finance",
        component: FinanceComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: "insurance",
        component: InsuranceProductsComponent
      },
      {
        path: "lease-residuals",
        component: LeaseResidualsComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: "incentives",
        component: IncentivesComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: "menu",
        component: MenuLinksComponent
      },
      {
        path: "archive",
        component: ArchiveComponent
      },
      {
        path: "form-server",
        component: FormServerComponent
      },
      {
        path: "",
        component: SettingsMenuComponent
      }
    ],
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.sys_admin]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(settingsRoutes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import * as appActions from "src/app/clearpath-module/store/actions/app.actions";
import { AppService } from "src/app/clearpath-module/services";
import { AlertService, CanComponentDeactivate } from "src/app/shared-module/services";
import { ConfigFinance, FinanceDefault, FinanceRate, LeaseDefault } from "../../models";
import { FinancingSettings } from "src/app/clearpath-module/models";

@Component({
  selector: "app-finance",
  templateUrl: "./finance.component.html",
  styleUrls: ["./finance.component.scss"]
})
export class FinanceComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new Subject<void>();
  private uiState = {
    activeViewId: "newRates",
    waitOn: {init: true, action: false},
    unsavedChanges: false,
    configFinance: {
      newRates: null,
      usedRates: null,
      certUsedRates: null,
      financeDefault: null,
      leaseDefault: null
    },
    viewList: [
      {id: "newRates", title: "New Rates"},
      {id: "usedRates", title: "Used Rates"},
      {id: "certUsedRates", title: "Certified Rates"},
      {id: "financeDefault", title: "Finance Default"},
      {id: "leaseDefault", title: "Lease Default"}
    ]
  };

  constructor(
    private appService: AppService,
    private alertService: AlertService,
    private actions$: Actions,
  ) { }

  ngOnInit() {
    this.getConfigFinance();
    this.subToSettingsData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canDeactivate(): boolean {
    const {unsavedChanges} = this.uiState;
    const navigateAway = unsavedChanges ? confirm("Discard Changes?") : true;
    return navigateAway;
  }

  // INITIALIZATION

  private getConfigFinance() {
    this.appService.dispatchGetConfigFinance();
  }

  private subToSettingsData() {
    this.appService.selectFinancing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: FinancingSettings) => {
        const configFinance: ConfigFinance = {
          newRates: data.newRates,
          usedRates: data.usedRates,
          certUsedRates: data.certUsedRates,
          financeDefault: data.financeDefault,
          leaseDefault: data.leaseDefault
        };
        this.uiState.configFinance = configFinance;
      });

    // Config Finance API Http Success
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.configFinanceApiSuccess),
    ).subscribe(() => {
      this.uiState.waitOn = {init: false, action: false};
      this.uiState.unsavedChanges = false;
      this.alertService.clear();
      window.scroll({top: 0, behavior: "smooth"});
    });

    // Config Finance API Http Failure
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.configFinanceApiFailure),
    ).subscribe(action => {
      this.uiState.waitOn = {init: false, action: false};
      this.alertService.error(action.error);
      window.scroll({top: 0, behavior: "smooth"});
    });
  }

  // DATA ACCESS

  get newRates(): FinanceRate[] {
    return this.uiState.configFinance.newRates || [];
  }

  get usedRates(): FinanceRate[] {
    return this.uiState.configFinance.usedRates || [];
  }

  get certUsedRates(): FinanceRate[] {
    return this.uiState.configFinance.certUsedRates || [];
  }

  get financeDefault(): FinanceDefault {
    return this.uiState.configFinance.financeDefault || {};
  }

  get leaseDefault(): LeaseDefault {
    return this.uiState.configFinance.leaseDefault || {};
  }

  // ACTIONS

  updateNewRates(newRates: FinanceRate[]) {
    const configFinance = {
      ...this.uiState.configFinance,
      newRates: this.sortRates(newRates)
    };
    this.uiState.waitOn.action = true;
    this.appService.dispatchSetConfigFinance(configFinance);
  }

  updateUsedRates(usedRates: FinanceRate[]) {
    const configFinance = {
      ...this.uiState.configFinance,
      usedRates: this.sortRates(usedRates)
    };
    this.uiState.waitOn.action = true;
    this.appService.dispatchSetConfigFinance(configFinance);
  }

  updateCertUsedRates(certUsedRates: FinanceRate[]) {
    const configFinance = {
      ...this.uiState.configFinance,
      certUsedRates: this.sortRates(certUsedRates)
    };
    this.uiState.waitOn.action = true;
    this.appService.dispatchSetConfigFinance(configFinance);
  }

  updateFinanceDefault(financeDefault: FinanceDefault) {
    const configFinance = {
      ...this.uiState.configFinance,
      financeDefault
    };
    this.uiState.waitOn.action = true;
    this.appService.dispatchSetConfigFinance(configFinance);
  }

  updateLeaseDefault(leaseDefault: LeaseDefault) {
    const configFinance = {
      ...this.uiState.configFinance,
      leaseDefault
    };
    this.uiState.waitOn.action = true;
    this.appService.dispatchSetConfigFinance(configFinance);
  }

  // UI CONTROL & RENDERING

  onSelectView(id: string) {
    if (id === this.uiState.activeViewId) { return; }

    const {unsavedChanges} = this.uiState;
    const switchView = unsavedChanges ? confirm("Discard Changes?") : true;

    if (switchView) {
      this.uiState.activeViewId = id;
      this.uiState.unsavedChanges = false;
      this.alertService.clear();
    }
  }

  activeView(id: string): boolean {
    return id === this.uiState.activeViewId;
  }

  get viewList(): Array<{ id: string; title: string; }> {
    return this.uiState.viewList;
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOn.action;
  }

  get waitOnInit(): boolean {
    return this.uiState.waitOn.init;
  }

  // HELPERS

  unsavedChanges() {
    this.uiState.unsavedChanges = true;
    this.alertService.clear();
  }

  sortRates(rates: FinanceRate[]) {
    return rates.sort((a, b) => a.term - b.term);
  }

}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService, CalculationService, DealService, LeaseCalculationService, VehicleService } from "../../../services";
import { share, take, takeUntil } from "rxjs/operators";
import * as fromRoot from "../../../store/state";
import { DealState, DealStatus } from "../../../store/state";
import { combineLatest, Observable, Subject } from "rxjs";
import { Accessory, FinancingSettings, Insurance, InsuranceProduct, TaxResult, Vehicle, WarrantySettingOption } from "../../../models";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import * as dealActions from "../../../store/actions/deal.actions";
import { SignatureData } from "../../../models/customer";
import * as $ from "jquery";
import { COMPLETED_STATUSES, DEAL_STATUSES, FORM_INPUT_REQS } from "src/app/app.config";
import { DealIncentivesService } from "src/app/clearpath-module/services/deal/deal-incentives.service";
import { WARRANTY_SETTINGS } from "../../warranty/warranty-config";
import { clone, pathOr } from "ramda";
import { DealerAccessory } from "src/app/clearpath-module/models/vehicle";
import { Store } from "@ngrx/store";
import * as dealSelectors from "../../../store/selectors/deal";
import { async } from "@angular/core/testing";
import { formatCurrency } from "@angular/common";
import { HistoryService } from "../../../services/history.service";
import { getCurrentEnv } from "../../../../util/featureFlags";
import { initialCustomerState } from "../../../store/state/deal/customer";
import { initialTradeInState } from "../../../store/state/deal/trade-in";

const buyerFormTemplate = {
  firstName: ["", Validators.required],
  middleName: [""],
  lastName: [""],
  street: [""],
  city: [""],
  state: [""],
  zip: [""],
  phone: ["", Validators.required],
  email: ["", Validators.required],
  driverId: [""],
  signatureUrl: [""]
};

const coBuyerFormTemplate = {
  firstName: [""],
  middleName: [""],
  lastName: [""],
  street: [""],
  city: [""],
  state: [""],
  zip: [""],
  phone: [""],
  email: [""],
  driverId: [""],
  signatureUrl: [""]
};

const tradeInFormTemplate = {
  year: [""],
  make: [""],
  model: [""],
  tradeEstimate: [""],
  payOffEstimate: [""],
};

const tradeInOwnerFormTemplate = {
  firstName: [""],
  middleName: [""],
  lastName: [""],
  street: [""],
  city: [""],
  state: [""],
  zip: [""],
  phone: [""],
  email: [""],
};

const insuranceInformationFormTemplate = {
  insuranceInfoAvailable: ['yes'],
  companyName: ["", Validators.required],
  /*street: ["", Validators.required],
  city: ["", Validators.required],
  state: ["", Validators.required],
  zip: ["", Validators.required],*/
  phone: ["", Validators.required],
  policyNumber: ["", Validators.required],
  policyEffectiveDate: [new Date(), Validators.required],
  policyExpirationDate: [new Date(), Validators.required]
};

const vehicleNeedsFormTemplate = {
  milesDrivenPerYear: ["0"]
};

@Component({
  selector: "app-submit-modal",
  templateUrl: "./submit-modal.component.html",
  styleUrls: ["./submit-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitModalComponent implements OnInit, OnDestroy, AfterViewInit {

  pathOr = pathOr;
  private unsubscribe$ = new Subject();
  public REQS = FORM_INPUT_REQS;
  deal: DealState;
  vehicle: Vehicle;
  financingSettings: FinancingSettings;
  signatureData: SignatureData = {buyer: "", coBuyer: ""};
  dealerAccessories: DealerAccessory[] = [];

  insuranceInfoAvailableChanged = (obj: FormGroup) => {
    console.log(obj);
    let currentValue = obj.get('insuranceInfoAvailable').value;
    if (currentValue === 'yes') {
      obj.get('companyName').setValidators([Validators.required]);
      /*obj.get('street').setValidators([Validators.required]);
      obj.get('city').setValidators([Validators.required]);
      obj.get('state').setValidators([Validators.required]);
      obj.get('zip').setValidators([Validators.required]);*/
      obj.get('policyNumber').setValidators([Validators.required]);
      obj.get('phone').setValidators([Validators.required]);
      obj.get('policyEffectiveDate').setValidators([Validators.required]);
      obj.get('policyExpirationDate').setValidators([Validators.required]);
      this.historyService.dispatchAddEvent({
        shortName: "Insurance Info Availability Changed",
        oldValue: "not Available",
        newValue: "Available",
      });
    } else {
      obj.get('companyName').setValidators([]);
      /*obj.get('street').setValidators([]);
      obj.get('city').setValidators([]);
      obj.get('state').setValidators([]);
      obj.get('zip').setValidators([]);*/
      obj.get('policyNumber').setValidators([]);
      obj.get('phone').setValidators([]);
      obj.get('policyEffectiveDate').setValidators([]);
      obj.get('policyExpirationDate').setValidators([]);
      this.historyService.dispatchAddEvent({
        shortName: "Insurance Info Availability Changed",
        oldValue: "Available",
        newValue: "Not Available",
      });
    }

    obj.markAsPristine();
    obj.updateValueAndValidity();
    obj.markAsUntouched();
    obj.reset();
    obj.get('insuranceInfoAvailable').setValue(currentValue);
  };

  uiState = {
    waitOn: {
      action: false,
      actionType: ""
    },
    adjustUsage: false,
    submit: false,
    vehicleChecked: false,
    dueTodayChecked: false,
    declineChecked: false,
    financeChecked: false,
    leaseChecked: false,
    cashChecked: false,
    customerSigned: false,
    coSignerSigned: true,
    screenWidth: 0,
    invalidMessages: [],

    showTradeIn1: false,
    showTradeIn2: false,
    showTradeInOwner2: false,
    showTradeIn2Owner2: false,
  };
  comments: string;
  leaseResiduals: any[];
  vehicleBaseFinanceAmount$: Observable<number>;
  vehicleBaseFinanceAmountWithProducts$: Observable<number>;
  vehicleBaseFinanceAmountForCash$: Observable<number>;
  taxResult$: Observable<TaxResult>;
  totalVehicleFinancePrice$: Observable<any>;
  totalVehicleCashPrice$: Observable<any>;
  totalVehicleFinanceMonthlyPayment$: Observable<any>;
  totalMonthlyLeasePayment$: Observable<number>;
  baseMonthlyLeasePayment$: Observable<number>;
  declinedProducts: InsuranceProduct[];
  grossCapCost$: Observable<number>;
  CCR$: Observable<number>;
  excessMileageCharge$: Observable<number>;
  interestRate$: Observable<number>;
  cashRebates: number;
  baseVehiclePrice$: Observable<number>;
  memoryTag = "submit-modal";

  constructor(
    private store: Store<fromRoot.DealState>,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private incentivesService: DealIncentivesService,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private calculationService: CalculationService,
    private leaseCalculationService: LeaseCalculationService,
    private actions$: Actions,
    private router: Router,
    private cd: ChangeDetectorRef,
    private historyService: HistoryService,
  ) { }

  taxesBasePriceOnly: TaxResult;
  financeOrCashBaseTax: number;

  // FORMS
  buyerForm: FormGroup = this.formBuilder.group(buyerFormTemplate);
  coBuyerForm: FormGroup = this.formBuilder.group(coBuyerFormTemplate);
  insuranceInformationForm = this.formBuilder.group(insuranceInformationFormTemplate);
  vehicleNeedsForm: FormGroup = this.formBuilder.group(vehicleNeedsFormTemplate);
  tradeInForm: FormGroup = this.formBuilder.group(tradeInFormTemplate);
  tradeInOwnerForm: FormGroup = this.formBuilder.group(tradeInOwnerFormTemplate);
  tradeInOwner2Form: FormGroup = this.formBuilder.group(tradeInOwnerFormTemplate);
  tradeIn2Form: FormGroup = this.formBuilder.group(tradeInFormTemplate);
  tradeIn2OwnerForm: FormGroup = this.formBuilder.group(tradeInOwnerFormTemplate);
  tradeIn2Owner2Form: FormGroup = this.formBuilder.group(tradeInOwnerFormTemplate);

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.uiState.screenWidth = window.innerWidth;
  }

  // INIT

  interestRate;

  ngOnInit() {
    this.historyService.dispatchAddEvent({
      shortName: "Submit Writeup Window Opened"
    });

    // this.dealService.dispatchRecalculate();
    this.subscribeToDeal();
    this.subscribeToVehicle();
    this.subscribeToFinance();
    this.subToActionSuccessFailure();
    this.initObservables();
    this.subscribeToDeclinedProducts();
    this.initializeConfirmBox();


    combineLatest([
      this.calculationService.findInterestRate$(),
      this.calculationService.calcTax$({basePriceOnly: true}),
    ])
      //.pipe(take(1))
      .subscribe((
        [
          interestRate,
          taxesBasePriceOnly
        ]) => {
        this.taxesBasePriceOnly = taxesBasePriceOnly;
        this.financeOrCashBaseTax = taxesBasePriceOnly.totalTax;
        this.interestRate = interestRate;
      });
  }

  ngOnDestroy() {
    // this.dealService.dispatchChangeDeal();
    this.historyService.dispatchAddEvent({
      shortName: "Submit Writeup Window Closed"
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.cleanUpDateForms();
    this.getScreenSize();
    this.cd.detectChanges();
  }

  initObservables() {
    this.vehicleBaseFinanceAmountWithProducts$ = this.calculationService.vehicleBaseFinanceAmount$({basePriceOnly: false});
    this.vehicleBaseFinanceAmount$ = this.calculationService.vehicleBaseFinanceAmount$({basePriceOnly: true});
    this.vehicleBaseFinanceAmountForCash$ = this.calculationService.vehicleBaseFinanceAmount$({finance: true});
    this.taxResult$ = this.calculationService.calcTax$({memoryTag: this.memoryTag});
    this.totalVehicleFinancePrice$ = this.calculationService.totalVehicleFinancePrice$({withoutDaysToFirstPay: true});
    this.totalVehicleCashPrice$ = this.calculationService.totalVehicleCashPrice$();
    this.totalVehicleFinanceMonthlyPayment$ = this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$({memoryTag: this.memoryTag});
    this.totalMonthlyLeasePayment$ = this.leaseCalculationService.calcTotalMonthlyLeasePayment$();
    this.grossCapCost$ = this.leaseCalculationService.calcGrossCapCost$();
    this.CCR$ = this.leaseCalculationService.calcCCR$();
    this.excessMileageCharge$ = this.leaseCalculationService.excessMileageCharge$();
    this.interestRate$ = this.calculationService.findInterestRate$();
    this.baseVehiclePrice$ = this.calculationService.baseVehiclePrice$();
  }

  initializeConfirmBox() {
    const shallConfirm = {
      [ DealStatus.Submitted ]: true,
    };
    const {status} = this.deal;
    if (shallConfirm[ status ]) {
      const confirmed = confirm("Deal has been submitted to desk. Would you like to make changes?");
      if (confirmed) {
        this.unSubmit();
      }
    }
  }

  initializeCheckBoxes() {
    if (COMPLETED_STATUSES.includes(this.deal.status as DEAL_STATUSES)) {
      this.uiState.financeChecked = true;
      this.uiState.cashChecked = true;
      this.uiState.vehicleChecked = true;
      this.uiState.dueTodayChecked = true;
      this.uiState.declineChecked = true;
    }
  }

  initializeSignatureBoxes() {
    if (this.deal.customer.signatureUrl) {
      this.uiState.customerSigned = true;
    }
    if (this.deal.coSigner.signatureUrl) {
      this.uiState.coSignerSigned = true;
    }
    if (this.deal.status === "updated") {
      this.deal.customer.signatureUrl = "";
      this.deal.coSigner.signatureUrl = "";
    }
  }

  toggleChecked(propName: string) {
    this.uiState[ propName ] = !this.uiState[ propName ];
  }

  // SUBSCRIBE

  private subToActionSuccessFailure() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.dealApiSuccess)
    ).subscribe((action) => {
      const formSubmitSuccess = this.uiState.waitOn.actionType === "submit";
      if (formSubmitSuccess) {
        setTimeout(() => {
          this.router.navigateByUrl("/clearpath/list");
        }, 1);
      }
      this.uiState.waitOn.action = false;
      this.uiState.waitOn.actionType = "";
    });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.dealApiFailure, dealActions.setSignaturesFailure)
    ).subscribe(action => {
      if (action.error) { alert(action.error); }
      this.uiState.waitOn.action = false;
      this.uiState.waitOn.actionType = "";
    });
  }

  subscribeToDeal() {
    this.dealService.selectDeal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(deal => {
        this.deal = deal;
        this.buyerForm.patchValue(deal.customer);
        this.coBuyerForm.patchValue(deal.coSigner);
        this.tradeInForm.patchValue(deal.tradeIn);
        if (deal.tradeIn?.year > 1900) {
          this.uiState.showTradeIn1 = true;
        }
        this.tradeInOwnerForm.patchValue(deal.tradeInOwner);
        this.tradeInOwner2Form.patchValue(deal.tradeInOwner2);
        if (!!deal.tradeInOwner2?.firstName?.trim()) {
          this.uiState.showTradeInOwner2 = true;
        }
        this.tradeIn2Form.patchValue(deal.tradeIn2);
        if (deal.tradeIn2?.year > 1900) {
          this.uiState.showTradeIn2 = true;
        }
        this.tradeIn2OwnerForm.patchValue(deal.tradeIn2Owner);
        this.tradeIn2Owner2Form.patchValue(deal.tradeIn2Owner2);
        if (!!deal.tradeIn2Owner2?.firstName?.trim()) {
          this.uiState.showTradeIn2Owner2 = true;
        }
        this.insuranceInformationForm.patchValue(deal.insuranceInfo);
        this.comments = deal.comments;
        this.initializeCheckBoxes();
        this.initializeSignatureBoxes();
        this.cleanUpDateForms();
        this.getCashRebates();
        this.initObservables();
        this.baseMonthlyLeasePayment$ = this.leaseCalculationService
          .calcBaseMonthlyLeasePayment$({term: deal.leaseOptions.selectedLeaseTerm});
      });
  }

  subscribeToVehicle() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(vehicle => {
        this.vehicle = vehicle;
        this.dealerAccessories = this.vehicleService.parsePBSCustomFields(vehicle);
        this.initObservables();
      });
  }

  subscribeToFinance() {
    this.appService.selectFinancing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(financingSettings => {
        this.financingSettings = financingSettings;
      });
  }

  subscribeToDeclinedProducts() {
    combineLatest([
      this.vehicleService.selectVehicle(),
      this.dealService.dealInsuranceService.selectDisabledInsuranceProducts()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([vehicle, declinedProducts]) => {

        // if declinedProducts is undefined or null, set to empty array
        declinedProducts = declinedProducts || [];

        // iterate through vehicle's insurance products, and if any are missing in the deal and not in the declined array, then add it to the declined array
        if (this?.deal?.insuranceProducts && vehicle.insuranceProducts) {
          vehicle.insuranceProducts.forEach(vehicleProduct => {
            let found = false;
            this?.deal?.insuranceProducts.forEach(product => {
                if (product.productKey === vehicleProduct.productKey) {
                  found = true;
                }
              }
            );
            if (!found) {
              if (Array.isArray(declinedProducts)) {
                found = false;
                declinedProducts.forEach(product => {
                  if (product.productKey === vehicleProduct.productKey) {
                    found = true;
                  }
                });
                if (!found) {
                  declinedProducts.push(vehicleProduct);
                }
              }
            }
          });
        }

        const leaseSelected = this.deal.leaseOptions.leaseSelected ? "lease" : "finance";
        this.declinedProducts = this.calculationService
          .filterProductsByType(declinedProducts, leaseSelected);
      });
  }

  isValidDate(d) {
    d = new Date(d);
    return d instanceof Date && !isNaN(d.getTime());
  }

  /* used to translate the date values of
  policyEffectiveDate and policyExpirationDate to valid form values */
  cleanUpDateForms() {
    const policyEffectiveNewDate = new Date(this.deal.insuranceInfo.policyEffectiveDate);
    if (this.isValidDate(policyEffectiveNewDate)) {
      const policyEffectiveDate = this.isValidDate(policyEffectiveNewDate) ?
        policyEffectiveNewDate : new Date(0);
      // if datepicker is supported
      if ($("#policyEffectiveDate").prop("type") === "date") {
        if (Math.sign(policyEffectiveDate.getTime()) <= 0) {
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyEffectiveDate: ""
            }
          };
        } else {
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyEffectiveDate: policyEffectiveDate.toISOString().split("T")[ 0 ]
            }
          };
        }
      } else {  // Datepicker not supported (safari)
        if (Math.sign(policyEffectiveDate.getTime()) <= 0) {
          this.deal.insuranceInfo.policyEffectiveDate = "";
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyEffectiveDate: ""
            }
          };
        } else {
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyEffectiveDate: policyEffectiveDate.toLocaleString().split(",")[ 0 ]
            }
          };
        }
      }
    }

    const policyExpirationNewDate = new Date(this.deal.insuranceInfo.policyExpirationDate);
    if (this.isValidDate(policyEffectiveNewDate)) {

      const policyExpirationDate = this.isValidDate(policyExpirationNewDate) ?
        policyExpirationNewDate : new Date(0);

      // if datepicker is supported
      if ($("#policyExpirationDate").prop("type") === "date") {
        if (policyExpirationDate.getTime() <= 0) {
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyExpirationDate: ""
            }
          };
        } else {
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyExpirationDate: policyExpirationDate.toISOString().split("T")[ 0 ]
            }
          };
        }
      } else {  // Datepicker not supported (safari)
        if (Math.sign(policyExpirationDate.getTime()) <= 0) {
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyExpirationDate: ""
            }
          };
        } else {
          this.deal = {
            ...this.deal, insuranceInfo: {
              ...this.deal.insuranceInfo,
              policyExpirationDate: policyExpirationDate.toLocaleString().split(",")[ 0 ]
            }
          };
        }
      }
    }
    console.log(this.deal.insuranceInfo);
    this.insuranceInformationForm.patchValue(this.deal.insuranceInfo);
  }

  // LEASE

  get DueAtSigning() {
    const rebates = this.getCashRebates();
    return rebates +
      this.deal.financeOptions.downPayment +
      ((this.deal.tradeIn.tradeValue > 0) ? this.deal.tradeIn.tradeValue : 0);
  }

  // USED IN TEMPLATE

  get leaseInsuranceProducts(): InsuranceProduct[] {
    return this.calculationService.filterProductsByType(this.deal.insuranceProducts, "lease");
  }

  get financeInsuranceProducts(): InsuranceProduct[] {
    return this.calculationService.filterProductsByType(this.deal.insuranceProducts, "finance");
  }


  get cashInsuranceProducts(): InsuranceProduct[] {
    return this.calculationService.filterProductsByType(this.deal.insuranceProducts, "cash");
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOn.action;
  }

  commentsValueChange(ev) {
    this.comments = ev.target.value;
  }

  get customizedVehiclePrice$() {
    return this.calculationService.customizedVehiclePrice$({
      lease: this.deal.leaseOptions.leaseSelected
    });
  }

  getCashRebates() {
    let result = 0;
    const {adjustedPrice, incentivesApplied} = this.incentivesService.applyCashIncentives({
      price: 0,
      incentives: this.deal.incentives,
      leaseOptions: this.deal.leaseOptions,
      leaseSelected: this.deal.leaseOptions.leaseSelected,
      financeOptions: this.deal.financeOptions
    });
    result = Math.abs(adjustedPrice);

    // if (this.deal.incentives && this.deal.incentives.length) {
    //   this.deal.incentives.forEach((incentive: DealIncentive) => {
    //     if (this.deal.leaseOptions.leaseSelected) {
    //       const leaseOfferTerms = incentive.leaseOffer.leaseOfferTerms || [];
    //       const selectedLeaseOfferTerm = leaseOfferTerms.find((leaseOfferTerm) => {
    //         return leaseOfferTerm.term === this.deal.leaseOptions.selectedLeaseTerm;
    //       });
    //       if (selectedLeaseOfferTerm) {
    //         if (selectedLeaseOfferTerm.leaseSubvention) {
    //           result = selectedLeaseOfferTerm.leaseSubvention.toString();
    //         } else {
    //           result = selectedLeaseOfferTerm.tieredLeaseMFs[this.deal.financeOptions.selectedCreditTier].toString();
    //         }
    //       }
    //     }
    //     if (!this.deal.leaseOptions.leaseSelected) {
    //       result = incentive.customerCash.toString();
    //     }
    //   });
    // }
    this.cashRebates = result;
    return result;
  }

  productDescription(product: InsuranceProduct): string {
    product = this.dealService.dealInsuranceService.setHardcodedTerm(product, this.deal.financeOptions.selectedFinancingTerm);
    const {productKey, termCosts} = product;
    if (!product) { return ""; }

    const {selectedTerm} = product;
    const termCost = termCosts[ selectedTerm ];
    if (product.productKey.toLowerCase() === "gap") {
      // Issue #1958: Remove GAP product description
      return "";
      /*
      if (this?.financeOptions?.selectedFinancingTerm === termCost.term) {
        return "for the life of the loan";
      }
      return `- ${termCost.term / 12} years / unlimited miles`;
      */
    }
    if (termCost) {
      return `${termCost.term / 12} years / ${termCost.miles} miles`;
    } else {
      return "";
    }
  }

  setClosestTerm(product) {
    product = clone(product);
    const selectedTerm = this.dealService.dealInsuranceService.findClosestTerm(
      product,
      this.deal.leaseOptions.leaseSelected ?
        this.deal.leaseOptions.selectedLeaseTerm :
        this.deal.financeOptions.selectedFinancingTerm,
      this.deal.vehicleNeeds.milesDrivenPerYear * this.deal.vehicleNeeds.plannedLengthOfOwnership
    );
    const index = product.termCosts.findIndex(termCost => {
      return termCost.term === selectedTerm.term && termCost.miles === selectedTerm.miles;
    });
    product.selectedTerm = index;
    return product;
  }

  productPrice(product: InsuranceProduct): number {
    const selectedTerm = product.termCosts[ product.selectedTerm ];
    return selectedTerm ? selectedTerm.price : 0;
  }

  get financeOptions() {
    return this.deal.financeOptions;
  }

  get accessories() {
    return this.deal.accessories || [];
  }

  isNotValid(formName: string, controlName: string): boolean {
    const valid = this[ formName ].controls[ controlName ].valid;
    return this.uiState.submit && !valid;
  }

  public get tradeAllowanceEstimate$(): Observable<number> {
    return this.store.select(dealSelectors.selectTradeAllowanceEstimate);
  }

  public get tradePayoffEstimate$(): Observable<number> {
    return this.store.select(dealSelectors.selectTradePayOffEstimate);
  }

  public get tradeEquityEstimate$(): Observable<number> {
    return this.store.select(dealSelectors.selectTradeEquityEstimate);
  }

  public get tradeEquityEstimateIsNegative$(): Observable<boolean> {
    return this.store.select(dealSelectors.selectTradeEquityEstimateIsNegative);
  }

  baseMonthlyFinancePayment$() {
    return this.calculationService.baseMonthlyPayment$(this.financeOptions.selectedFinancingTerm);
  }

  findClosestTerm(product) {
    const prod = Object.assign({}, product);
    return this.dealService.dealInsuranceService.findClosestTerm(
      prod,
      this.deal.leaseOptions.selectedLeaseTerm
    );
  }

  closestTermTerm(product) {
    return pathOr("", ["term"], this.findClosestTerm(product));
  }

  closestTermMiles(product) {
    return pathOr("", ["miles"], this.findClosestTerm(product));
  }

  closestTermPrice(product) {
    return pathOr("", ["price"], this.findClosestTerm(product));
  }

  calculateInsuranceProductMonthlyPayment$(product: InsuranceProduct) {
    return this.calculationService.insuranceProductMonthlyPayment$(product);
  }

  calculateInsuranceProductMonthlyPaymentForLease$(product: InsuranceProduct): Observable<number> {
    return this.leaseCalculationService
      .calculateInsuranceProductMonthlyPaymentForLease$(
        product,
        this.deal.leaseOptions.selectedLeaseTerm
      );
  }

  calcMonthlyAccessoryPrice(acc: Accessory) {
    return acc.price / this.financeOptions.selectedFinancingTerm;
  }

  captureBuyerSignature(data: string) {
    if (!data) {
      this.uiState.customerSigned = false;
      this.buyerForm.controls.signatureUrl.patchValue("");
    } else {
      this.signatureData.buyer = data;
      this.uiState.customerSigned = true;
    }
  }

  toggleAdjustUsage() {
    this.uiState.adjustUsage = !this.uiState.adjustUsage;
  }

  submitVehicleNeedsForm() {
    // if (this.vehicleNeedsForm.pristine) { return; }
    let {milesDrivenPerYear} = this.vehicleNeedsForm.value;
    milesDrivenPerYear = parseInt(milesDrivenPerYear, 10);

    this.dealService.dispatchSetVehicleNeeds({
      milesDrivenPerYear
    });
  }

  captureCoBuyerSignature(data: string) {
    if (!data) {
      this.uiState.coSignerSigned = false;
      this.coBuyerForm.controls.signatureUrl.patchValue("");
    } else {
      this.signatureData.coBuyer = data;
      this.uiState.coSignerSigned = true;
    }
  }

  get milesOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.milesDrivenPerYear || [];
  }

  checkFormsInvalidMessages() {
    const invalidMessages = [];
    const customer = this.buyerForm.value;
    const coSigner = this.coBuyerForm.value;
    const formIsValid = this.buyerForm.valid &&
      this.insuranceInformationForm.valid &&
      this.coBuyerForm.valid;

    this.uiState.submit = true;

    if (!formIsValid) {
      if (!this.buyerForm.valid) {
        invalidMessages.push("Buyer Information Incomplete. Please Scroll Up.");
      }
      if (!this.coBuyerForm.valid) {
        invalidMessages.push("Co-Buyer Information Incomplete. Please Scroll Up.");
      }
      if (!this.insuranceInformationForm.valid) {
        //invalidMessages.push("Insurance Information Incomplete. Please Scroll Up.");
      }
    }
    if (this.deal.leaseOptions.leaseSelected) {
      if (!this.uiState.dueTodayChecked) {
        invalidMessages.push("Confirm: 'Due Today'");
      }
    }
    if (this.deal.financeOptions.financeSelected && !this.uiState.financeChecked) {
      invalidMessages.push("Confirm: 'Financing'");
    }
    if (this.deal.financeOptions.cashPurchase && !this.uiState.cashChecked) {
      invalidMessages.push("Confirm: 'Cash Sale'");
    }
    if (!this.uiState.vehicleChecked) {
      invalidMessages.push("Confirm: 'Vehicle'");
    }
    if (!customer.signatureUrl && !this.signatureData.buyer) {
      invalidMessages.push("Signature Required");
    }
    if (coSigner.firstName && !this.signatureData.coBuyer && !coSigner.signatureUrl) {
      this.uiState.coSignerSigned = false;
    }
    return invalidMessages;
  }

  prepareSubmit() {
    const customer = {...this.deal.customer, ...this.buyerForm.value};
    const coSigner = {...this.deal.coSigner, ...this.coBuyerForm.value};
    let tradeIn = {...this.deal.tradeIn, ...this.tradeInForm.value};
    let tradeInOwner = {...this.deal.tradeInOwner, ...this.tradeInOwnerForm.value};
    let tradeInOwner2 = {...this.deal.tradeInOwner2, ...this.tradeInOwner2Form.value};
    let tradeIn2 = {...this.deal.tradeIn2, ...this.tradeIn2Form.value};
    let tradeIn2Owner = {...this.deal.tradeIn2Owner, ...this.tradeIn2OwnerForm.value};
    let tradeIn2Owner2 = {...this.deal.tradeIn2Owner2, ...this.tradeIn2Owner2Form.value};
    const parsedInsuranceInfo: Insurance = this.insuranceInformationForm.value;
    const invalidMessages = this.checkFormsInvalidMessages();
    this.uiState.invalidMessages = invalidMessages;
    if (this.uiState.invalidMessages.length > 0) {
      return;
    }

    // if tradeIns or owners are not shown, set tradeIns and/or owners to initial state
    
    if (!this.uiState.showTradeIn2Owner2) {
      tradeIn2Owner2 = initialCustomerState;
    }
    if (!this.uiState.showTradeInOwner2) {
      tradeInOwner2 = initialCustomerState;
    }
    if (!this.uiState.showTradeIn2) {
      tradeIn2 = initialTradeInState;
      tradeIn2Owner = initialCustomerState;
      tradeIn2Owner2 = initialCustomerState;
    }
    if (!this.uiState.showTradeIn1) {
      tradeIn = initialTradeInState;
      tradeInOwner = initialCustomerState;
      tradeInOwner2 = initialCustomerState;
      tradeIn2 = initialTradeInState;
      tradeIn2Owner = initialCustomerState;
      tradeIn2Owner2 = initialCustomerState;
    }

    if (getCurrentEnv() == "production" && !this.insuranceInformationForm.valid) {
      if (!confirm("Insurance Information incomplete.  Continue Submission?")) {
        return;
      }
      this.historyService.dispatchAddEvent({
        shortName: "Incomplete Insurance Info Submitted",
      });
    }


    this.uiState.waitOn.action = true;
    this.uiState.waitOn.actionType = "submit";

    const requestData = {
      signatureData: this.signatureData,
      signatureUrls: {
        buyerSignatureUrl: customer.signatureUrl,
        coBuyerSignatureUrl: coSigner.signatureUrl
      },
      customer,
      coSigner,
      tradeIn,
      tradeInOwner,
      tradeInOwner2,
      tradeIn2,
      tradeIn2Owner,
      tradeIn2Owner2,
      insuranceInfo: parsedInsuranceInfo,
      comments: this.comments
    };
    return requestData;
  }

  submit() {
    const requestData = this.prepareSubmit();
    if (requestData) {
      this.historyService.dispatchAddEvent({
        shortName: "Writeup Submitted"
      });
      this.dealService.submitDealSubmitModalForm(requestData);
    }
  }

  unSubmit() {
    this.uiState.waitOn.action = true;
    this.uiState.waitOn.actionType = "unsubmit";
    this.dealService.dispatchRetractDeal(this.deal.dealId);
  }

  get showSubmit(): boolean {
    const allowed = {
      [ DealStatus.Created ]: true,
      [ DealStatus.Changed ]: true,
      [ DealStatus.Updated ]: true
    };
    const {status} = this.deal;
    return allowed[ status ] || false;
  }

  copyAddressToCoBuyer(): void {
    this.coBuyerForm.patchValue(this.buyerForm.value);
  }

  copyTradeInOwnerAddress(): void {
    this.tradeInOwnerForm.patchValue(this.buyerForm.value);
  }

  displayFinanceInsuranceProductsAmount(product: any): any {
    const priceOverride = pathOr(0, ["termCosts", product.selectedTerm, "priceOverride"], product);
    const price = pathOr(0, ["termCosts", product.selectedTerm, "price"], product);
    return priceOverride || price;
  }

  protected readonly async = async;

  calcCashAccPrice(vehicleBaseFinanceAmountForCash, totalVehicleCashPrice, cashInsuranceProducts) {
    let output = totalVehicleCashPrice - vehicleBaseFinanceAmountForCash;
    if (cashInsuranceProducts)
      cashInsuranceProducts.forEach(product => {
        if (product.selectedTerm && pathOr(0, ["termCosts", product.selectedTerm, "price"], product)) {
          //console.log("term", pathOr(0, ["termCosts", product.selectedTerm, "price"], product))
          output -= pathOr(0, ["termCosts", product.selectedTerm, "price"], product);
        }
      });
    return this.formatCurrencyVal(output);
  }

  calcFinanceAccPrice(vehicleBaseFinanceAmount, totalVehicleFinancePrice, financeInsuranceProducts) {
    let output = totalVehicleFinancePrice - vehicleBaseFinanceAmount;
    if (financeInsuranceProducts)
      financeInsuranceProducts.forEach(product => {
        output -= this.displayFinanceInsuranceProductsAmount(product);
      });
    return this.formatCurrencyVal(output);
  }

  calcFinanceMonthlyAccPrice(baseMonthlyFinancePayment, totalVehicleFinanceMonthlyPayment, financeInsuranceProducts, interestRate, financeOptions) {
    let output = totalVehicleFinanceMonthlyPayment - baseMonthlyFinancePayment;
    if (financeInsuranceProducts)
      financeInsuranceProducts.forEach(product => {
        output -= this.calculationService.insuranceProductMonthlyPaymentCalc(interestRate, financeOptions, product);
      });
    return this.formatCurrencyVal(output);
  }

  formatCurrencyVal(val: number) {
    return formatCurrency(val ? Math.round(val * 100) / 100 : 0, "en-US", "$", "USD");
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SalesCue, SalesQPerson } from '../../models/sales-queue';

@Component({
  selector: "app-q-item",
  templateUrl: "./q-item.component.html",
  styleUrls: ["./q-item.component.scss"]
})
export class QItemComponent implements OnChanges {
  @Input() person: SalesQPerson;
  @Input() salesCue: SalesCue;
  @Input() queueIndex: number;
  @Input() isAdmin = false;
  @Input() controlsEnabled = true;

  @Output() removeFromSalesQueue = new EventEmitter();
  @Output() moveToBottomOfSalesQueue = new EventEmitter();
  @Output() addCustomer = new EventEmitter();
  @Output() removeCustomer = new EventEmitter();

  uiState = {
    pendingAction: ""
  };

  actions = {
    remove: "remove",
    moveToBottom: "moveToBottom",
    toggleWithCustomer: "toggleWithCustomer"
  };

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.salesCue) {
      this.clearPendingAction();
    }
  }

  // ACTIONS

  onMoveToBottomClicked() {
    this.setPendingAction("moveToBottom");
    this.moveToBottomOfSalesQueue.emit();
  }

  onRemoveFromSalesQueueClicked() {
    this.setPendingAction("remove");
    this.removeFromSalesQueue.emit();
  }

  onWithCustomerClicked() {
    this.setPendingAction("toggleWithCustomer");
    if (this.person.withCustomer) {
      this.removeCustomer.emit();
    } else {
      this.addCustomer.emit();
    }
  }

  // UI CONTROL & RENDERING

  setPendingAction(action: string) {
    this.uiState.pendingAction = action;
  }

  clearPendingAction() {
    this.uiState.pendingAction = "";
  }

  isPendingAction(action: string): boolean {
    return this.uiState.pendingAction === action;
  }

  clearPhotoUrl() {
    this.person.photoUrl = null;
  }

  isNextUp(): boolean {
    return this.queueIndex === 1;
  }

  withCustomer(): boolean {
    return this.person.withCustomer;
  }

  waitOnUpdates(): boolean {
    return !this.controlsEnabled;
  }

  get fullName(): string {
    const {firstName, lastName} = this.person;
    return `${firstName} ${lastName}`;
  }

  get nameInitials(): string {
    const {firstName, lastName} = this.person;
    return `${firstName.slice(0, 1).toUpperCase()} ${lastName.slice(0, 1).toUpperCase()}`;
  }
}

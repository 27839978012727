import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService, DealService, VehicleService } from "src/app/clearpath-module/services";
import * as appActions from "src/app/clearpath-module/store/actions/app.actions";
import { DealState } from "src/app/clearpath-module/store/state";
import { pathOr } from "ramda";
import { Vehicle } from "src/app/clearpath-module/models";
import * as calculationDocs from "../../../clearpath-module/services/calculations/docs/calculation-docs.json";

@Component({
  selector: "app-form-server",
  templateUrl: "./form-server.component.html",
  styleUrls: ["./form-server.component.scss"]
})
export class FormServerComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private actions$: Actions,
    private formBuilder: FormBuilder
  ) { }

  formServerData;
  printDeals: DealState[] = [];
  selectedDealIndex: number;
  vehicles: Vehicle[];
  selectedVehicle: Vehicle;
  dealLookupForm: FormGroup = this.formBuilder.group({
    dealIndex: [-1],
  });

  ngOnInit() {
    this.appService.dispatchGetFormServer();
    this.dealService.dispatchGetPrintDeals();
    this.vehicleService.dispatchGetAllVehicles();

    this.vehicleService.selectVehicles().subscribe(vehicles => {
      this.vehicles = vehicles;
    });

    this.dealLookupForm.valueChanges.subscribe(({dealIndex}) => {
      this.selectedDealIndex = dealIndex;
      this.selectedVehicle = (this.vehicles || [])[ this.vehicles.findIndex(v => {
        return v.stockNumber === this.printDeals[ this.selectedDealIndex ].stockNumber;
      }) ];
    });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.formServerApiSuccess),
    ).subscribe(() => {
      window.scroll({top: 0, behavior: "smooth"});
    });

    this.appService.selectFormServer()
      .pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.formServerData = data;
    });

    this.appService.selectPrintDeals()
      .pipe(takeUntil(this.unsubscribe$)).subscribe(printDeals => {
      this.printDeals = printDeals;
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCalculation(fieldName: string) {
    // oadaOtherFees;
    const splitName = fieldName.split(".");
    if (fieldName === "financeOptions.otherCharges") {
      //console.log("fieldName:", fieldName);
    }

    if (splitName.length == 1) {
      return pathOr("", [splitName[ 0 ]], calculationDocs);
    }
    if (splitName.length == 2) {
      return pathOr("", [splitName[ 0 ], splitName[ 1 ]], calculationDocs);
    }
  }

  getFieldValue(name: string) {
    if (this.printDeals && this.printDeals.length === 0 || this.selectedDealIndex === -1) {
      return;
    }

    if (name) {
      const deal = pathOr(null, ["printDeals", this.selectedDealIndex], this);
      const splitName = name.split(".");

      if (splitName[ 0 ] === "Vehicle") {
        return pathOr("N/A", [splitName[ 1 ]], this.selectedVehicle);
      }

      if (splitName[ 0 ] === "Deal") {
        if (deal) {
          switch (splitName[ 1 ]) {
            case "contractDay":
              return deal.contractDate;
            case "contractMonth":
              return deal.contractDate;
            case "contractYear":
              return deal.contractDate;
            case "activeInterestRatePercent":
              return deal.financeOptions.activeInterestRate;
          }
        }
      }

      if (splitName[ 0 ] === "financeOptions") {
        if (deal) {
          switch (splitName[ 1 ]) {
            case "activeInterestRatePercent":
              return deal.financeOptions.activeInterestRate;
            case "firstPaymentDueDATE":
              return deal.financeOptions.firstPaymentDue;
            default:
              return deal.financeOptions[ splitName[ 1 ] ];
          }
        }
      }

      if (splitName[ 0 ] === "tradeIn") {
        if (deal) {
          switch (splitName[ 1 ]) {
            case "tradeYearMakeModel":
              return deal.tradeIn.year + deal.tradeIn.make + deal.tradeIn.model;
            case "tradeMakeModel":
              return deal.tradeIn.make + deal.tradeIn.model;
            default:
              return deal.tradeIn[ splitName[ 1 ] ];
          }
        }
      }

      if (splitName[ 0 ] === "tradeInOwner") {
        if (deal) {
          switch (splitName[ 1 ]) {
            case "fullName":
              return deal.tradeInOwner.firstName + deal.tradeInOwner.lastName;
            case "tradeMakeModel":
              return deal.tradeIn.make + deal.tradeIn.model;
            default:
              return deal.tradeIn[ splitName[ 1 ] ];
          }
        }
      }

      if (splitName[ 0 ] === "lienHolder") {
        if (deal) {
          switch (splitName[ 1 ]) {
            case "addressCityStateZip":
              return deal.lienHolder.address +
                deal.lienHolder.city +
                deal.lienHolder.state +
                deal.lienHolder.zip;
            case "tradeMakeModel":
              return deal.tradeIn.make + deal.tradeIn.model;
            default:
              return deal.tradeIn[ splitName[ 1 ] ];
          }
        }
      }

      if (splitName[ 0 ].toLowerCase() === "customer") {
        return pathOr("NOT SET", [this.selectedDealIndex, splitName, 1], this.printDeals);
      }

      //console.log("splitName[0]:", splitName[ 0 ]);
      if (splitName[ 0 ].toLowerCase() === "InsuranceProd[]") {
        return "";
        // return pathOr("NOT SET", [insuranceProducts, 1], deal);
      }
    }

  }

}

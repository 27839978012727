import { Injectable } from "@angular/core";
import { TermPercentage, TermValue } from "../models";

@Injectable({
  providedIn: "root"
})
export class ParsingService {

  constructor() { }

  csvToJSON(fullCSV: string, startIndex: number): object[] {

    const strippedCSV = this.stripCommas(fullCSV);
    const csv = strippedCSV.slice(startIndex);
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[ 0 ].split(",");

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentline = lines[ i ].split(",");
      for (let j = 0; j < headers.length; j++) {
        const header = headers[ j ].replace(/\s+/g, ""); // strip all space from inside the header
        obj[ header ] = currentline[ j ];
      }
      result.push(obj);
    }
    return result;
  }

  stripSymbols(str: string): string {
    const symbols = ["$", ",", '"'];
    const arr = str.split("");
    const filtered = arr.filter(el => !symbols.includes(el));
    return filtered.join("");
  }

  stripCommas(csv: string): string {
    for (let i = 0; i < csv.length; i++) {
      if (csv[ i ] === "$") {
        let j = i + 1;
        while (csv[ j ] && csv[ i ] && csv[ j ] !== '"') {
          if (csv[ j ] === ",") {
            csv = csv.slice(0, j) + csv.slice(j + 1);
          }
          j++;
        }
      }
    }
    return csv;
  }

  sortTerms(values: TermValue[] | TermPercentage[]) {
    values.sort((a, b) => {
      if (a.term < b.term) { return -1; }
      if (a.term > b.term) { return 1; }
      return 0;
    });
  }

  isValidModelNumber(input: string): boolean {
    return input && input.length <= 5 && !input.includes(" ") && !isNaN(parseInt(input, 10));
  }

  isValidKey(key: string): boolean {
    return !isNaN(parseInt(key, 10));
  }

}

<section class="row no-gutters d-flex justify-content-center">
  <div
    *ngIf="insuranceProducts.length === 0"
    class="app-bg-light app-text-neutral rounded text-center px-3 py-2 w-100"
  >
    No Insurance Products Set.
  </div>
  <div class="d-flex justify-content-between w-100 mx-4">
    <div
      *ngFor="let product of insuranceProducts"
      class="d-flex align-items-center flex-column mt-3"
    >
      <a
        data-toggle="modal"
        data-target="#insurance-item-modal"
        (click)="onSelectProduct(product.productKey)"
      >
        <img [src]="getIconSrc(product.productKey)" style="width:100px;height:100px;margin-bottom:20px;"/>
      </a>
      <div class="font-weight-bold text-center">{{ product.name }}</div>
    </div>
  </div>
</section>

<div id="insurance-item-modal" class="modal fade">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <button
        class="close"
        style="position: absolute; top: 0.25rem; right: 0.5rem"
        type="button"
        data-dismiss="modal"
        (click)="onSelectProduct('')"
        #closeModal
      >
        <span>&times;</span>
      </button>

      <app-insurance-item-ewu
        *ngIf="isActiveProduct('EWU')"
        [product]="insuranceProduct('EWU')"
        [selectedTermIndex]="selectedProductTermIndex('EWU')"
        (closeModal)="onCloseModal()"
      >
      </app-insurance-item-ewu>

      <app-insurance-item-gap
        *ngIf="isActiveProduct('GAP')"
        [product]="insuranceProduct('GAP')"
        [selectedTermIndex]="selectedProductTermIndex('GAP')"
        (closeModal)="onCloseModal()"
      >
      </app-insurance-item-gap>

      <app-insurance-item-ppm
        *ngIf="isActiveProduct('PPM')"
        [product]="insuranceProduct('PPM')"
        [selectedTermIndex]="selectedProductTermIndex('PPM')"
        (closeModal)="onCloseModal()"
      >
      </app-insurance-item-ppm>

      <!-- <div *ngIf="dealData.vehicleCondition === 'new'"> -->
      <app-insurance-item-elite
        *ngIf="isActiveProduct('SELECT')"
        [product]="insuranceProduct('SELECT')"
        [selectedTermIndex]="selectedProductTermIndex('SELECT')"
        (closeModal)="onCloseModal()"
      >
      </app-insurance-item-elite>
      <!-- </div> -->

      <!-- <div *ngIf="dealData.vehicleCondition !== 'new'">
        <app-insurance-item-twp
          *ngIf="isActiveProduct('SELECT')"
          [product]="insuranceProduct('SELECT')"
          [selectedTermIndex]="selectedProductTermIndex('SELECT')"
          (closeModal)="onCloseModal()"
        >
        </app-insurance-item-twp>
      </div> -->

      <app-insurance-item-vsa
        *ngIf="isActiveProduct('VSA')"
        [product]="insuranceProduct('VSA')"
        [vehicleCondition]="dealData.vehicleCondition"
        [selectedTermIndex]="selectedProductTermIndex('VSA')"
        (closeModal)="onCloseModal()"
      >
      </app-insurance-item-vsa>
    </div>
  </div>
</div>

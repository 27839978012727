// selectors.ts
import { createSelector } from "@ngrx/store";
import { selectClearpath } from "./clearpath";
import { DealState } from "../state";
import { FinanceOptions, TradeIn } from "../../models";

export const selectDeal = createSelector(selectClearpath, state => state.deal);
export const selectCustomer = createSelector(
  selectDeal, (state: DealState) => state.customer
);
export const selectCoSigner = createSelector(
  selectDeal, (state: DealState) => state.coSigner
);
export const selectInsuranceProducts = createSelector(
  selectDeal, (state: DealState) => state.insuranceProducts
);
export const selectDisabledInsuranceProducts = createSelector(
  selectDeal, (state: DealState) => state.disabledInsuranceProducts
);
export const selectFinanceOptions = createSelector(
  selectDeal, (state: DealState) => state.financeOptions
);
export const selectFinanceOptionsEdits = createSelector(
  selectDeal, (state: DealState) => state.financeOptionsEdits
);
export const selectCustomSalesTaxRateFinance = createSelector(
  selectFinanceOptions, (financeOptions: FinanceOptions) => financeOptions.customSalesTaxRate
);
export const selectLeaseOptions = createSelector(
  selectDeal, (state: DealState) => state.leaseOptions
);
export const selectTradeIn = createSelector(
  selectDeal, (state: DealState) => state.tradeIn
);
export const selectTradeIn2 = createSelector(
  selectDeal, (state: DealState) => state.tradeIn2
);
export const selectInsuranceInfo = createSelector(
  selectDeal, (state: DealState) => state.insuranceInfo
);
export const selectAccessories = createSelector(
  selectDeal, (state: DealState) => state.accessories
);
export const selectSelectedDealerAccessories = createSelector(
  selectDeal, (state: DealState) => state.selectedDealerAccessories
);
export const selectIncentives = createSelector(
  selectDeal, (state: DealState) => state.incentives
);
export const selectVehicleNeeds = createSelector(
  selectDeal, (state: DealState) => state.vehicleNeeds
);
export const selectVerifedForms = createSelector(
  selectDeal, (state: DealState) => state.verifiedForms
);
export const selectValuesForWarrantyForm = createSelector(
  selectVehicleNeeds,
  selectInsuranceProducts,
  (
    {
      plannedLengthOfOwnership,
      milesDrivenPerYear
    },
    insuranceProducts
  ) => ({
    plannedLengthOfOwnership,
    milesDrivenPerYear,
    insuranceProducts
  })
);

/*********************************************************************************************
 * Calculation Selectors
 *
 * These selectors are a combination of one or more selectors that returns a calculated value.
 * Optimization occurs by memoization.
 * Learn more at: https://ngrx.io/guide/store/selectors
 *********************************************************************************************/
export const selectNetTradeInValue = createSelector(
  selectTradeIn, selectTradeIn2,
  (tradeIn: TradeIn, tradeIn2: TradeIn) => {
    // tradeIn.tradeValue & tradeIn2.tradeValue is a dynamic value that may not have been initialized.  therefore, calculate the correct value here.
    return tradeIn.tradeEstimate + tradeIn2.tradeEstimate - (tradeIn.payOff || tradeIn.payOffEstimate) - (tradeIn2.payOff || tradeIn2.payOffEstimate);
  }
);

export const selectTradeAllowanceEstimate = createSelector(selectTradeIn, selectTradeIn2,
  (tradeIn: TradeIn, tradeIn2: TradeIn) => (tradeIn.tradeEstimate || 0) + (tradeIn2.tradeEstimate || 0)
);

export const selectTradePayOffEstimate = createSelector(selectTradeIn, selectTradeIn2,
  (tradeIn: TradeIn, tradeIn2: TradeIn) => (tradeIn.payOffEstimate || 0) + (tradeIn2.payOffEstimate || 0)
);

export const selectTradeEquityEstimate = createSelector(selectTradeAllowanceEstimate, selectTradePayOffEstimate,
  (tradeAllowanceEstimate: number, tradePayOffEstimate: number) => tradeAllowanceEstimate - tradePayOffEstimate
);

export const selectTradeEquityEstimateIsNegative = createSelector(selectTradeEquityEstimate,
  (tradeEquityEstimate: number) => tradeEquityEstimate < 0
);

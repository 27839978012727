<form [formGroup]="leaseDefaultForm" class="card card-body mb-3">
  <h5>Terms Shown</h5>

  <label>New Terms Shown</label>

  <div
    *ngIf="newTerms.length === 0"
    class="app-bg-light app-text-neutral text-center rounded px-3 py-2 mb-3"
  >
    No Terms Set.
  </div>

  <section formArrayName="newTermsShown" class="term-controls-layout mb-2">
    <article
      *ngFor="let term of newTermsShown; let i = index"
      class="input-group"
    >
      <input
        class="form-control"
        [ngClass]="{
          'app-border-danger': touchedInvalidTermShown('newTermsShown', i)
        }"
        [formControlName]="i"
        type="number"
        min="1"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-sm app-btn-neutral d-flex align-items-center"
          type="button"
          (click)="onRemoveTerm('newTermsShown', i)"
          [disabled]="waitOnAction"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onAddTermShown('newTermsShown')"
      [disabled]="waitOnAction"
    >
      Add
    </button>
  </section>

  <label>Certified Terms Shown</label>

  <div
    *ngIf="certUsedTermsShown.length === 0"
    class="app-bg-light app-text-neutral text-center rounded px-3 py-2 mb-3"
  >
    No Terms Set.
  </div>

  <section formArrayName="certUsedTermsShown" class="term-controls-layout mb-2">
    <article
      *ngFor="let term of certUsedTermsShown; let i = index"
      class="input-group"
    >
      <input
        class="form-control"
        [ngClass]="{
          'app-border-danger': touchedInvalidTermShown('certUsedTermsShown', i)
        }"
        [formControlName]="i"
        type="number"
        min="1"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-sm app-btn-neutral d-flex align-items-center"
          type="button"
          (click)="onRemoveTerm('certUsedTermsShown', i)"
          [disabled]="waitOnAction"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onAddTermShown('certUsedTermsShown')"
      [disabled]="waitOnAction"
    >
      Add
    </button>
  </section>

  <hr/>

  <!-- ---------------------------------------- -->
  <h5>Terms Available</h5>

  <label>New Terms Available</label>

  <div
    *ngIf="newTerms.length === 0"
    class="app-bg-light app-text-neutral text-center rounded px-3 py-2 mb-3"
  >
    No Terms Set.
  </div>

  <section formArrayName="newTerms" class="term-controls-layout mb-2">
    <article *ngFor="let term of newTerms; let i = index" class="input-group">
      <input
        class="form-control"
        [ngClass]="{
          'app-border-danger': touchedInvalidAvailableTerm('newTerms', i)
        }"
        [formControlName]="i"
        type="number"
        min="1"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-sm app-btn-neutral d-flex align-items-center"
          type="button"
          (click)="onRemoveTerm('newTerms', i)"
          [disabled]="waitOnAction"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onAddTerm('newTerms')"
      [disabled]="waitOnAction"
    >
      Add
    </button>
  </section>

  <label>Certified Terms Available</label>

  <div
    *ngIf="certUsedTerms.length === 0"
    class="app-bg-light app-text-neutral text-center rounded px-3 py-2 mb-3"
  >
    No Terms Set.
  </div>

  <section formArrayName="certUsedTerms" class="term-controls-layout">
    <article
      *ngFor="let term of certUsedTerms; let i = index"
      class="input-group"
    >
      <input
        class="form-control"
        [ngClass]="{
          'app-border-danger': touchedInvalidAvailableTerm('certUsedTerms', i)
        }"
        [formControlName]="i"
        type="number"
        min="1"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-sm app-btn-neutral d-flex align-items-center"
          type="button"
          (click)="onRemoveTerm('certUsedTerms', i)"
          [disabled]="waitOnAction"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onAddTerm('certUsedTerms')"
      [disabled]="waitOnAction"
    >
      Add
    </button>
  </section>

  <hr/>
  <!-- ---------------------------------------- -->

  <section class="form-controls-layout">
    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('newMarkup') }">
        New Markup *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="newMarkup"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">
            {{
            valid("newMarkup")
              ? (value("newMarkup") | percent: "1.0-2")
              : "--%"
            }}
          </span>
        </span>
      </div>
    </div>

    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('certUsedMarkup') }"
      >
        Certified Markup *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="certUsedMarkup"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">
            {{
            valid("certUsedMarkup")
              ? (value("certUsedMarkup") | percent: "1.0-2")
              : "--%"
            }}
          </span>
        </span>
      </div>
    </div>
  </section>

  <hr/>

  <section class="form-controls-layout">
    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('acquisitionFee') }"
      >
        Acquisition Fee *
      </label>
      <div class="input-group">
        <span class="input-group-prepend">
          <span class="input-group-text">$</span>
        </span>
        <input
          class="form-control"
          formControlName="acquisitionFee"
          type="number"
          min="0"
          required
        />
      </div>
    </div>

    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('onePayDiscount') }"
      >
        One Pay Discount *
      </label>
      <div class="input-group">
        <span class="input-group-prepend">
          <span class="input-group-text">$</span>
        </span>
        <input
          class="form-control"
          formControlName="onePayDiscount"
          type="number"
          min="0"
          required
        />
      </div>
    </div>

    <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('onePayDiscFloor') }"
      >
        One Pay Discount Floor *
      </label>
      <div class="input-group">
        <span class="input-group-prepend">
          <span class="input-group-text">$</span>
        </span>
        <input
          class="form-control"
          formControlName="onePayDiscFloor"
          type="number"
          min="0"
          required
        />
      </div>
    </div>

    <!-- <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('prepaidMileage') }">
        Prepaid Mileage *
      </label>
      <input class="form-control"
             formControlName="prepaidMileage"
             type="number"
             min="0"
             required />
    </div> -->

    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('moneyFactor') }">
        Money Factor *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="moneyFactor"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">
            {{
            valid("moneyFactor")
              ? (value("moneyFactor") | percent: "1.0-2")
              : "--%"
            }}
          </span>
        </span>
      </div>
    </div>

    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('taxRate') }">
        Tax Rate *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="taxRate"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">
            {{
            valid("taxRate") ? (value("taxRate") | percent: "1.0-2") : "--%"
            }}
          </span>
        </span>
      </div>
    </div>

    <!-- <div>
      <label
        [ngClass]="{ 'app-text-danger': touchedInvalid('excessMileageRate') }"
      >
        Excess Mileage Rate *
      </label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="excessMileageRate"
          type="number"
          min="0"
          required
        />
        <span class="input-group-append">
          <span class="input-group-text">$</span>
        </span>
      </div>
    </div> -->
  </section>
</form>

<button
  class="btn app-btn-secondary d-block mx-auto"
  type="button"
  (click)="onSubmitLeaseDefaultForm()"
  [disabled]="
    waitOnAction || leaseDefaultForm.pristine || leaseDefaultForm.invalid
  "
>
  Submit
</button>

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { DealIncentive, VehicleIncentive } from "src/app/settings-module/models/incentives";
import { isEmpty, isNil } from "ramda";
import { DealService } from "src/app/clearpath-module/services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";


@Component({
  selector: "app-smw-rebates-form",
  templateUrl: "./smw-rebates-form.component.html",
  styleUrls: ["./smw-rebates-form.component.scss"]
})
export class SmwRebatesFormComponent implements OnInit, OnDestroy, OnChanges {

  private unsubscribe$ = new Subject();

  @Input() dealIncentives: DealIncentive[];
  @Input() vehicleIncentives: VehicleIncentive[];
  @Input() dealType: string;
  @Output() updateIncentives = new EventEmitter<DealIncentive[]>();
  rebates: any[];

  constructor(private dealService: DealService) { }

  ngOnInit() {
    this.rebates = this.getRebates();
    this.dealService.selectDeal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(deal => {
        this.dealIncentives = deal.incentives || [];
        this.rebates = this.getRebates();


        /*
        this.dealIncentives.forEach(incentive => {
          console.log("Incentive:", incentive)
        })

        this.rebates.forEach(rebate => {
          console.log("rebate:", rebate)
        })
        */

      });
  }

  ngOnChanges() {
    this.rebates = this.getRebates();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // ACTIONS

  onToggleAccept(rebate) {
    const ig = (this.dealIncentives || [])
      .find(i => i.incentiveGroupName === rebate.incentiveGroupName);
    const rebateIndex = this.rebates.findIndex(r => r.title === rebate.title);
    if (isNil(ig) || isEmpty(ig)) {
      const newGroup: DealIncentive = {
        incentiveGroupName: rebate.incentiveGroupName,
        [ rebate.type ]: rebate.value
      };
      this.rebates[ rebateIndex ].selected = true;
      this.dealIncentives.push(newGroup);
    } else {
      this.rebates[ rebateIndex ].selected = !this.rebates[ rebateIndex ].selected;
      if (ig[ rebate.type ]) {
        if (rebate.type === "customerCash") {
          ig.customerCashDisabled = !ig.customerCashDisabled;
        } else if (rebate.type === "bonusCash") {
          ig.bonusCashDisabled = !ig.bonusCashDisabled;
        } else if (rebate.type === "leaseCash") {
          ig.leaseCashDisabled = !ig.leaseCashDisabled;
        } else if (rebate.type === "financeCash") {
          ig.financeCashDisabled = !ig.financeCashDisabled;
        } else {
          delete ig[ rebate.type ];
        }

      } else {
        if (rebate.type === "customerCash") {
          ig.customerCashDisabled = false;
        }
        if (rebate.type === "bonusCash") {
          ig.bonusCashDisabled = false;
        }
        if (rebate.type === "leaseCash") {
          ig.leaseCashDisabled = true;
        }
        if (rebate.type === "financeCash") {
          ig.financeCashDisabled = true;
        }
        ig[ rebate.type ] = rebate.value;
      }
    }
    this.updateIncentives.emit(this.dealIncentives);
  }

  // UI & RENDERING

  getRebates() {
    const rebates = [];
    let ig;
    this.vehicleIncentives.forEach(vi => {

      ig = vi.incentiveGroupName;

      if (vi.incentive.cashDetails) {
        const cd = vi.incentive.cashDetails;

        // console.log("CashDeals",cd)

        if (cd.otherRebate) {
          if (ig !== "Toyota Lease Loyalty Program") {
            rebates.push({
              incentiveGroupName: ig,
              title: "Rebate",
              type: "otherRebate",
              value: cd.otherRebate,
              selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "otherRebate")
            });
          }
        }
        if (cd.customerCash) {
          rebates.push({
            incentiveGroupName: ig,
            title: "Customer Cash",
            type: "customerCash",
            value: cd.customerCash,
            selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "customerCash")
          });
        }
        if (cd.bonusCash) {
          rebates.push({
            incentiveGroupName: ig,
            title: "Bonus Cash",
            type: "bonusCash",
            value: cd.bonusCash,
            selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "bonusCash")
          });
        }
        if (this.dealType === 'lease' && cd.leaseCash) {
          rebates.push({
            incentiveGroupName: ig,
            title: "Lease Cash",
            type: "leaseCash",
            value: cd.leaseCash,
            selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "leaseCash")
          });
        }
        if (this.dealType === 'finance' && cd.financeCash) {
          rebates.push({
            incentiveGroupName: ig,
            title: "Finance Cash",
            type: "financeCash",
            value: cd.financeCash,
            selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "financeCash")
          });
        }
      }

    });

    // these rebates are available for all vehicles and are associated
    // with the following incentive group.
    ig = "Toyota Portland Region Incentives";

    rebates.push({
      incentiveGroupName: ig,
      title: "Military Rebate",
      type: "militaryRebate",
      value: 500,
      selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "militaryRebate")
    });

    rebates.push({
      incentiveGroupName: ig,
      title: "College Rebate",
      type: "collegeRebate",
      value: 500,
      selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "collegeRebate")
    });

    rebates.push({
      incentiveGroupName: ig,
      title: "Lease Loyalty",
      type: "customerLoyalty500",
      value: 500,
      selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "customerLoyalty500")
    });

    rebates.push({
      incentiveGroupName: ig,
      title: "Finance Loyalty",
      type: "customerLoyalty1000",
      value: 1000,
      selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "customerLoyalty1000")
    });

    rebates.push({
      incentiveGroupName: ig,
      title: "Uber Rebate",
      type: "uberRebate",
      value: 750,
      selected: this.isCashIncentiveSelected(this.dealIncentives, ig, "uberRebate")
    });

    return rebates;
  }

  checkBoxColor(rebate): string {
    if (rebate.selected) {
      return "app-bg-success";
    }
    return "";
  }

  isCashIncentiveSelected(dealIncentives, incentiveGroupName, type): boolean {
    const inc = (dealIncentives || []).find(i => i.incentiveGroupName === incentiveGroupName);
    if (!inc) { return false; }
    if (type === "customerCash") {
      return !inc.customerCashDisabled;
    }
    if (type === "bonusCash") {
      return !inc.bonusCashDisabled;
    }
    if (type === "leaseCash") {
      return !inc.leaseCashDisabled;
    }
    if (type === "financeCash") {
      return !inc.financeCashDisabled;
    }
    return !!inc[ type ];
  }

  isLeaseOffer(incentive) {
    return incentive.leaseOffer;
  }
}

<div
  class="cash app-text-light px-1 app-text-bold my-1 py-1 d-flex justify-content-between primary-section-header-block"
  (click)="onSelectCashDeal()">
  <span>Cash Purchase</span>
  <span class="app-text-secondary app-text-white">{{ totalVehicleCashPrice$ | async | currency }}</span>
</div>

<app-smw-rebates-form
  [dealIncentives]="dealIncentives"
  [vehicleIncentives]="vehicleIncentives"
  dealType="finance"
  (updateIncentives)="onUpdateIncentives($event)">
</app-smw-rebates-form>

<div class="section-header">Products</div>

<app-smw-insurance-products-form
  [dealDefaults]="dealDefaults"
  (flagUnsavedChanges)="flagChange()"
  type="cash">
</app-smw-insurance-products-form>

<!-- <app-simple-input [fieldValue]="totalInsuranceProductsPrice$ | async | currency"
                  [name]="'Total Protection Adds'"
                  [type]="'number'">
</app-simple-input> -->

<app-simple-input
  [fieldValue]="tradeInNetValue$ | async"
  name="Trade Net"
  type="number"
  pipe="currency">
</app-simple-input>

<app-simple-input
  [fieldValue]="totalVehicleCashPrice$ | async"
  name="Total Amount Due"
  type="number"
  pipe="currency"
  isBold="true">
</app-simple-input>

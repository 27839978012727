import { Injectable } from "@angular/core";
import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "src/app/auth-module/services";
import { Actions } from "@ngrx/effects";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private actions$: Actions
  ) { }

  loggingOut = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    return next.handle(request).pipe(
      catchError(response => {
        if (response.status === 401) {
          this.authService.logout();
          this.authService.clearUser();
          localStorage.clear();
          this.authService.clearTokenTimeout();
          this.authService.token = "";
          this.router.navigate(["/login"]);
          location.reload(true);

          // return authActions.logoutSuccess();
        }
        const errorString = this.getErrorMessageString(response);
        return throwError(errorString);
      })
    );
  }

  getErrorMessageString(response) {
    if (typeof response.error === "string") {
      return response.error;
    }
    if (typeof response.error === "object") {
      if (typeof response.error.error === "string") {
        return response.error.error;
      }
    }
    return response.message || "An Unknown Error Occurred";
  }
}

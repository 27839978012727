<h3>{{title}}</h3>
<button *ngIf="!hideButtons" (click)="reset()" class="btn btn-outline-primary">RESET</button>
<button *ngIf="!hideButtons" (click)="save()" class="btn btn-outline-success ml-1">SAVE</button>
<button *ngIf="!hideButtons" (click)="deleteItem()" class="btn btn-outline-danger ml-1">REMOVE</button>
<form [formGroup]="menuItemForm">
  <div class="form-group">
    <label>Icon Url</label>
    <input formControlName="iconUrl" class="form-control" type="text" [maxlength]="REQS.maxLength.long" value="{{menuItem.iconUrl}}">
  </div>
  <div class="form-group">
    <label>Link Url</label>
    <input formControlName="linkUrl" class="form-control" type="text" [maxlength]="REQS.maxLength.long" value="{{menuItem.linkUrl}}">
  </div>
  <div class="form-group">
    <label>Description</label>
    <input formControlName="description" class="form-control" type="text" [maxlength]="REQS.maxLength.standard" value="{{menuItem.description}}">
  </div>
</form>
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared-module/shared.module";
import { UserAdminRoutingModule } from "./user-admin-routing.module";

import { UserAdminComponent } from "./pages";
import { UserFormComponent } from "./components";
import { SecurityGroupFilterPipe } from "./pipes/security-group-filter.pipe";

@NgModule({
  declarations: [UserAdminComponent, UserFormComponent, SecurityGroupFilterPipe],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    UserAdminRoutingModule
  ],
  exports: [UserAdminComponent, UserFormComponent]
})
export class UserAdminModule {}

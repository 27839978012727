import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, } from "@angular/core";
import SignaturePad from "signature_pad";

@Component({
  selector: "app-signature-pad",
  templateUrl: "./signature-pad.component.html",
  styleUrls: ["./signature-pad.component.scss"]
})
export class SignaturePadComponent implements AfterViewInit, OnDestroy {

  signaturePad: SignaturePad;
  @Input() signatureTitle = "Signature";
  @Input() imageURL: string;
  @Input() width: number;
  @Output() latestImage = new EventEmitter();
  @ViewChild("sigPadElem", {static: false}) sigPadElem: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit() {

    // INIT SIGNATURE PAD
    this.signaturePad = new SignaturePad(this.sigPadElem.nativeElement, {
      backgroundColor: "rgba(255, 255, 255, 0)",
      penColor: "rgb(0, 0, 0)"
    });

    this.drawBaseLine();
    if (this.imageURL) {
      this.drawSignature();
    }

    // LISTEN FOR CHANGES
    this.signaturePad.onEnd = res => {
      const data = this.signaturePad.toDataURL("image/png");
      this.latestImage.emit(data);
    };
  }

  ngOnDestroy() {
    this.signaturePad.off();
  }

  get ctx() {
    return this.sigPadElem.nativeElement.getContext("2d");
  }

  drawBaseLine() {
    this.ctx.beginPath();
    this.ctx.moveTo(5, 105);
    this.ctx.lineTo(595, 105);
    this.ctx.strokeStyle = "#6c757d";
    this.ctx.stroke();
  }

  drawSignature() {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      this.ctx.drawImage(img, 0, 0); // Or at whatever offset you like
    };
    img.src = this.imageURL;
  }

  clear() {
    this.signaturePad.clear();
    this.latestImage.emit(null);
  }

}

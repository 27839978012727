/*
 * Define all feature flags here.  This enum allows the app to reference each feature flag via a strong reference,
 * which allows the compiler to detect missing Feature Flags at compile time when a flag is removed.
 */
import { environment } from "../../environments/environment";

/**
 * A list of all known feature flags.
 */
enum FeatureFlag {
  CONSOLE_LOGS,
  VITU,
}

/**
 * A list of all known environments.
 */
type FlagSetting = {
  local: boolean,
  dev: boolean,
  staging: boolean,
  production: boolean,
}

/**
 * Configure each feature flag in the enum above here.  Each flag can have a default value,
 * as well as have an override per env. If override is not set for an env, the default
 * value will be used.
 */
const featureFlagsConfig = new Map<FeatureFlag, FlagSetting>([
  [FeatureFlag.CONSOLE_LOGS,
    <FlagSetting>{
      local: true,
      dev: true,
      staging: true,
      production: false,
    }],
  [FeatureFlag.VITU,
    <FlagSetting>{
      local: true,
      dev: true,
      staging: true,
      production: true,
    }]
]);

/**
 * Returns the current environment name
 */
const getCurrentEnv = () => {
  return environment?.name?.toLowerCase() || undefined;
};

/**
 * Returns weather if a given feature flag is enabled for the current environment.
 *
 * @param featureFlag
 */
const isFlagEnabled = (featureFlag: FeatureFlag) => {
  return featureFlagsConfig.get(featureFlag)[ getCurrentEnv() ];
};

/**
 * Console logs input only if the feature flags allow it.
 * This is recommended to be used instead of a bare console.log.
 *
 * @param logs
 */
const consoleLog = (...logs: any[]) => {
  if (isFlagEnabled(FeatureFlag.CONSOLE_LOGS)) {
    console.log(...logs);
  }
};

export {
  FeatureFlag,
  isFlagEnabled,
  getCurrentEnv,
  consoleLog
};

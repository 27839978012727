<div
  class="finance px-1 app-text-bold app-text-white my-1 py-1 d-flex justify-content-between primary-section-header-block"
  (click)="onSelectFinanceDeal()">
  <span class="header-label">Finance</span>
  <div class="app-text-secondary app-text-white" *ngIf="!uiState.customSelected">
    <span *ngIf="!uiState.incentiveSelected" class="app-text-white">standard</span>
    <span *ngIf="uiState.incentiveSelected" class="app-text-white">incentive</span>
    {{ financeTotal$ | async | currency }}
  </div>
  <span *ngIf="uiState.customSelected" class="app-text-white">
    {{ customMonthlyPayment$() | async | currency }}
  </span>
</div>

<div class="term-rate-table">

  <app-lender-select
    [lender]="lender"
    [lenderName]="lender.name"
    [flagChange]="flagChange">
  </app-lender-select>

  <div class="section-header" style="margin-top: 6px!important;margin-bottom:0!important;">Standard Rates</div>
  <div style="padding:0 2px!important;box-sizing: border-box">
    <div class="term-rate-header-row">
      <span class="cell-terms">Term</span>
      <span class="cell-apr-header">APR</span>
      <span class="cell-payment">Payment</span>
    </div>
    <div
      *ngFor="let financeRate of financeDefaultRates"
      (click)="onSelectDefaultFinance(financeRate)"
      class="app-inline-form-layout {{isActiveDefaultRate(financeRate.term) ? (financeSelected ? 'row-selected-blue' : 'row-selected-grey') : 'finance-table-row'}}">
      <span class="cell-terms">{{ financeRate.term }}</span>
      <ng-container *ngIf="financeRate.tieredRates[creditTier] as rate; else elseBlock">
        <span class="cell-apr">{{ rate | percent: "1.0-2" }}</span>
        <span class="cell-payment">{{ calculateTotalVehicleFinanceMonthlyPayment$({term: financeRate.term, interestRate: rate, forceCashIncentive: true}) | async | currency }}</span>
      </ng-container>
      <ng-template #elseBlock>
        <span class="cell-apr">-.--%</span>
        <span class="cell-payment">--N/A--</span>
      </ng-template>
    </div>
  </div>

  <hr class="standard-section-breaker">

  <app-smw-rebates-form
    [dealIncentives]="dealIncentives"
    [vehicleIncentives]="vehicleIncentives"
    [dealType]="'finance'"
    (updateIncentives)="onUpdateIncentives($event)">
  </app-smw-rebates-form>

  <hr class="standard-section-breaker">

  <div style="display:flex;gap:2px;align-items: center">
    <input
      type="checkbox"
      class="form-check"
      [checked]="!this.miscFinanceForm.get('aprSubventionDisabled').value"
      (click)="toggleAprSubventionDisabled()"/>
    <app-simple-input
      formCtrlName="aprSubvention"
      name="APR Subvention"
      type="number"
      pipe="currency"
      [readOnly]="true"
      [parentForm]="miscFinanceForm"
      [fieldValue]="uiState.aprSubvention"
      [keydown]="flagChange">
    </app-simple-input>
  </div>

  <div class="term-rate-table">
    <div class="section-header" style="margin-bottom:0!important;">Incentive Rates</div>
    <div style="padding:0 2px!important;box-sizing: border-box">
      <div *ngIf="financeIncentiveRates.length" class="term-rate-header-row">
        <span class="cell-terms">Term</span>
        <span class="cell-apr-header">APR</span>
        <span class="cell-payment">Payment</span>
      </div>
      <div
        *ngFor="let financeRate of financeIncentiveRates"
        (click)="onSelectIncentiveFinance(financeRate)"
        class="app-inline-form-layout {{isActiveIncentiveRate(financeRate.term) ? (financeSelected ? 'row-selected-blue' : 'row-selected-grey') : 'finance-table-row'}}">
        <span class="cell-terms">{{ financeRate.term }}</span>
        <ng-container *ngIf="financeRate.tieredRates[creditTier] !== null; else elseBlock">
          <span class="cell-apr">{{ financeRate.tieredRates[creditTier] | percent: "1.0-2" }}</span>
          <span class="cell-payment">{{ calculateTotalVehicleFinanceMonthlyPayment$({term: financeRate.term, interestRate: financeRate.tieredRates[creditTier], excludeIncentives: true}) | async | currency }}</span>
        </ng-container>
        <ng-template #elseBlock>
          <span class="text-right">--</span>
          <span>--</span>
        </ng-template>
      </div>
    </div>
  </div>

  <div *ngIf="financeIncentiveRates.length === 0" class="d-flex align-items-center p-2">
    <span style="color:#777;font-weight:600;font-size:14px;">No Incentive Rates</span>
  </div>

  <div class="term-rate-table">

    <div class="section-header" style="margin-bottom:0!important;">Custom Rate</div>
    <div style="padding:0 2px!important;box-sizing: border-box">
      <div class="term-rate-header-row">
        <span>Term</span>
        <span>APR</span>
        <span>Payment</span>
      </div>

      <form [formGroup]="customFinanceForm" class="custom-rate-row">

        <div
          *ngIf="uiFormsState.customerProvidedFinancingTerm.editing === false"
          (click)="uiFormsState.customerProvidedFinancingTerm.editing = true"
          class="app-text-blue">
          {{ uiState.customerProvidedFinancingTerm }}
        </div>

        <div
          *ngIf="uiFormsState.customerProvidedFinancingTerm.editing === null"
          (click)="uiFormsState.customerProvidedFinancingTerm.editing = true"
          class="app-text-blue">
          edit term
        </div>

        <input
          *ngIf="uiFormsState.customerProvidedFinancingTerm.editing"
          (blur)="submitCustomFinanceForm();uiFormsState.customerProvidedFinancingTerm.editing = false"
          class="input-underline restrict-width"
          formControlName="customerProvidedFinancingTerm"
          type="number"
          min="0"/>

        <div
          *ngIf="uiFormsState.customerProvidedInterestRate.editing === false"
          class="app-text-blue"
          (click)="uiFormsState.customerProvidedInterestRate.editing = true">
          {{ financeOptions.customerProvidedInterestRate | percent: "1.3-5" }}
        </div>

        <div
          *ngIf="uiFormsState.customerProvidedInterestRate.editing === null"
          class="app-text-blue"
          (click)="uiFormsState.customerProvidedInterestRate.editing = true">
          edit rate
        </div>

        <input
          *ngIf="uiFormsState.customerProvidedInterestRate.editing"
          (blur)="submitCustomFinanceForm();uiFormsState.customerProvidedInterestRate.editing = false"
          class="input-underline restrict-width"
          formControlName="customerProvidedInterestRate"
          type="number"
          min="0"/>

        <div [ngClass]="uiState.customSelected?'app-bg-success app-text-white':''">{{ customMonthlyPayment$() | async | currency }}</div>
      </form>
    </div>
  </div>

  <div class="section-header">Products</div>

  <app-smw-insurance-products-form
    [dealDefaults]="dealDefaults"
    (flagUnsavedChanges)="flagChange()"
    [selectedDealTerm]="financeOptions.selectedFinancingTerm"
    type="finance">
  </app-smw-insurance-products-form>

  <app-simple-input
    [parentForm]="miscFinanceForm"
    [fieldValue]="uiState.daysToFirstPay"
    [blur]="submitMiscFinanceForm"
    [keydown]="flagChange"
    [change]="flagChange"
    formCtrlName="daysToFirstPay"
    name="Days To First Pay"
    type="number">
  </app-simple-input>

  <app-simple-input
    [fieldValue]="totalFinanced$ | async"
    name="Total Amount Financed"
    type="number"
    pipe="currency">
  </app-simple-input>

</div>

import { TradeIn } from "../../../models";

export const initialTradeInState: TradeIn = {
  pbsVehicleRef: "",
  tradeEstimate: 0,
  tradeValue: 0,
  payOffEstimate: 0,
  payOff: 0,
  year: 0,
  make: "",
  model: "",
  trim: "",
  bodyType: "",
  vin: "",
  license: "",
  condition: "",
  holding: "",
  color: "",
  negativeEquity: 0,
  stockNumber: "",
  odometer: 0,
  lienHolderName: "",
  lienHolderPhone: "",
  lienHolderAddress: "",
  lienHolderCity: "",
  lienHolderProvince: "",
  lienHolderPostal: "",
};


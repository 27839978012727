import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Accessory } from "../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-add-accessory",
  templateUrl: "./add-accessory.component.html",
  styleUrls: ["./add-accessory.component.scss"]
})
export class AddAccessoryComponent {
  @Output() addAccessory = new EventEmitter<Partial<Accessory>>();

  public REQS = FORM_INPUT_REQS;
  addAccessoryForm: FormGroup = this.formBuilder.group({
    name: ["", Validators.required],
    price: [null, [Validators.required, Validators.min(0)]]
  });

  constructor(private formBuilder: FormBuilder) { }

  // ACTIONS

  onAddAccessory() {
    this.trimNameInput();
    const {invalid} = this.addAccessoryForm;
    if (invalid) { return; }

    const accessory: Partial<Accessory> = this.addAccessoryForm.value;
    this.addAccessory.emit(accessory);
    this.addAccessoryForm.markAsPristine();
    this.addAccessoryForm.reset();
  }

  trimNameInput() {
    const {name} = this.addAccessoryForm.value;
    this.addAccessoryForm.patchValue({name: name.trim()});
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.addAccessoryForm.get(controlName);
    return control.touched && control.invalid;
  }

}

export function formatPhoneNumber(phoneNumber: string): string {
  // Ensure the input is only digits and exactly 10 characters long
  if (!phoneNumber)
    return phoneNumber;
  
  if (!/^\d{10}$/.test(phoneNumber)) {
    return phoneNumber;
  }

  // Extract the area code, first three digits, and last four digits
  const areaCode = phoneNumber.slice(0, 3);
  const firstThree = phoneNumber.slice(3, 6);
  const lastFour = phoneNumber.slice(6);

  // Format and return the phone number
  return `(${areaCode}) ${firstThree}-${lastFour}`;
}

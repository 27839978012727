import { Pipe, PipeTransform } from "@angular/core";
import { PipeHelpers } from "./helpers";
import { VehicleService } from "src/app/clearpath-module/services";
import { Vehicle } from "src/app/clearpath-module/models";

@Pipe({
  name: "vehicleSort"
})
export class VehicleSortPipe implements PipeTransform {

  constructor(private helpers: PipeHelpers, private vehicleService: VehicleService) { }

  transform(vehicles: any[], sortBy: string, ascending: boolean): any[] {

    const sortByProp = prop => this.sortVehiclesByProperty(prop, vehicles, ascending);

    if (!vehicles) { return []; }
    if (!sortBy) { return vehicles; }

    return sortByProp(sortBy);

  }

  sortVehiclesByProperty(property: string, vehicles: any[], ascending: boolean) {
    return vehicles.sort((a, b) => {
      const fieldA = this.vehicleGetSortableValue(property, a);
      const fieldB = this.vehicleGetSortableValue(property, b);
      return this.helpers.compare(fieldA, fieldB, ascending);
    });
  }

  vehicleGetSortableValue(prop: string, vehicle: Vehicle) {
    switch (prop) {
      case "type":
        return this.vehicleService.vehicleCondition(vehicle);
      case "exteriorColor":
        return vehicle[ prop ].description;
      default:
        return vehicle[ prop ];
    }
  }

}

<form [formGroup]="incentiveGroupForm" class="p-4">

  <section class="row">
    <div class="form-group col-sm-6">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('name') }">
        Incentive Group Name *
      </label>
      <input class="form-control"
             formControlName="name"
             (keydown)="flagChange()"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             autofocus="true"
             required/>
    </div>

    <div class="form-group col-sm-6">
      <label>
        Description
      </label>
      <input class="form-control"
             formControlName="description"
             (keydown)="flagChange()"
             type="text"
             [maxlength]="REQS.maxLength.standard"/>
    </div>
  </section>

  <section class="row">
    <div class="form-group col-sm-6">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('startDate') }" class="d-flex flex-column">
        <span>Start Date *</span>
        <span style="font-size: 10px;">YYYY-MM-DD</span>
      </label>
      <input class="form-control"
             formControlName="startDate"
             (keydown)="flagChange()"
             type="text"
             maxlength="10"
             required/>
    </div>

    <div class="form-group col-sm-6">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('endDate') }" class="d-flex flex-column">
        <span>End Date *</span>
        <span style="font-size: 10px;">YYYY-MM-DD</span>
      </label>
      <input class="form-control"
             formControlName="endDate"
             (keydown)="flagChange()"
             type="text"
             maxlength="10"
             required/>
    </div>
  </section>

  <section class="row mt-3">
    <label class="pl-3 font-weight-bold">Group Qualifier:</label>
  </section>

  <section class="row">
    <div class="form-group col-sm-3">
      <label>
        Group Name
      </label>
      <input class="form-control"
             formControlName="groupName"
             (keydown)="flagChange()"
             type="text"
             [maxlength]="REQS.maxLength.standard"/>
    </div>

    <div class="form-group col-sm-6">
      <label>
        Group Description
      </label>
      <input class="form-control"
             formControlName="groupDescription"
             (keydown)="flagChange()"
             type="text"
      />
    </div>

    <div class="form-group col-sm-3 d-flex justify-content-end align-items-end">
      <label class="pr-2 pb-1 mb-1" style="width: 310px;">
        Validation&nbsp;Required
      </label>
      <input class="form-control"
             formControlName="validationRequired"
             (click)="flagChange()"
             type="checkbox"/>
    </div>
  </section>

  <section class="row mt-3">
    <div class="form-group" formArrayName="exclusions" style="width: 100%">
      <div class="d-flex justify-content-between align-items-start pr-3 pb-2">
        <label class="pl-3 pb-0 font-weight-bold">Exclusions:</label>
        <button class="btn btn-sm app-btn-secondary m-1 d-flex align-items-center" (click)="onAddExclusion()">
          Add Exclusion <i class="material-icons">add</i>
        </button>
      </div>
      <div *ngFor="let exclusion of incentiveGroupForm.get('exclusions')['controls']; let i = index"
           class="col d-flex"
           [formGroupName]="i">
        <input class="form-control mt-2 mb-2"
               formControlName="item"
               (keydown)="flagChange()"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               required/>
        <i class="material-icons app-text-danger mt-auto mb-auto ml-2" (click)="onRemoveExclusion(i)">
          close
        </i>
      </div>
    </div>
  </section>

  <section class="row mt-4">
    <div class="d-flex justify-content-between align-items-center pr-3" style="width: 100%">
      <h3 class="h3 pl-3 font-weight-light">Incentives</h3>
      <div class="d-flex">
        <button class="btn btn-sm app-btn-success m-1 d-flex align-items-center" (click)="logVehiclesWithIncentives()">Log: Incentives with Rates</button>
        <button class="btn btn-sm app-btn-success m-1 d-flex align-items-center"
                (click)="onSetChildExpansion(true)">
          Expand All <i class="material-icons">add</i>
        </button>
        <button class="btn btn-sm app-btn-success m-1 d-flex align-items-center"
                (click)="onSetChildExpansion(false)">
          Collapse All <i class="material-icons">remove</i>
        </button>
        <button class="btn btn-sm app-btn-secondary m-1 d-flex align-items-center" (click)="onAddIncentive()">
          Add Incentive <i class="material-icons">add</i>
        </button>
      </div>
    </div>
  </section>

  <section *ngFor="let incentive of incentives; let j = index">

    <hr>

    <app-incentive-form
      [incentive]="incentive.value"
      [itemIndex]="j"
      [expansionAction]="expandChildAction"
      (removeIncentive)="onRemoveIncentive(j)"
      (updateIncentive)="onUpdateIncentive($event, j)">
    </app-incentive-form>
  </section>

  <hr>

  <div *ngIf="incentives.length > 0" class="d-flex justify-content-end align-items-center" style="width: 100%">
    <div class="d-flex">
      <button class="btn btn-sm app-btn-success m-1 d-flex align-items-center"
              (click)="onSetChildExpansion(true)">
        Expand All <i class="material-icons">add</i>
      </button>
      <button class="btn btn-sm app-btn-success m-1 d-flex align-items-center"
              (click)="onSetChildExpansion(false)">
        Collapse All <i class="material-icons">remove</i>
      </button>
      <button class="btn btn-sm app-btn-secondary m-1 d-flex align-items-center" (click)="onAddIncentive()">
        Add Incentive <i class="material-icons">add</i>
      </button>
    </div>
  </div>

  <button *ngIf="!isNewGroup"
          class="btn app-btn-danger mt-5 mb-5 ml-auto mr-1 d-block"
          type="button"
          (click)="onRemoveIncentiveGroup()"
          [disabled]="waitOnAction">
    Remove Incentive Group
  </button>
</form>

<section class="footer-menu"
         [ngClass]="{'footer-menu_hidden': waitOnAction || incentiveGroupForm.invalid || !unsavedChanges}">
  <button class="btn app-btn-secondary"
          type="button"
          (click)="onSubmitIncentiveGroup()"
          [disabled]="waitOnAction || incentiveGroupForm.invalid || !unsavedChanges">
    Save Changes
  </button>
</section>

<section class="footer-menu"
         [ngClass]="{'footer-menu_hidden': waitOnAction || incentiveGroupForm.valid || !unsavedChanges }">
  <div class="app-text-danger" (click)="logInvalidControls()">
    Please make sure the form is valid before saving
  </div>
</section>

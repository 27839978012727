<section class="position-relative border rounded">
  <div
    class="position-absolute badge app-text-white"
    [ngClass]="withCustomer() ? 'app-bg-secondary' : 'app-bg-neutral'"
    style="top:0; left: 0"
  >
    {{ queueIndex + 1 }}
  </div>

  <div class="d-flex align-items-center p-3">
    <div class="position-relative">
      <img
        *ngIf="person.photoUrl"
        [src]="person.photoUrl"
        class="rounded-circle"
        style="height: 60px; width: 60px; min-width: 60px; object-fit: cover"
        (error)="clearPhotoUrl()"
      />

      <div
        *ngIf="!person.photoUrl"
        class="rounded-circle app-bg-dark app-text-light d-flex align-items-center justify-content-center"
        style="height: 60px; width: 60px; min-width: 60px">
        {{ nameInitials }}
      </div>

      <span
        *ngIf="isNextUp()"
        class="position-absolute badge app-text-white app-bg-secondary w-100"
        style="bottom: -5px; left: 0"
      >
        Next Up
      </span>
    </div>

    <div class="h4 mb-0 ml-3 mr-3">
      {{ fullName | titlecase }}
    </div>
  </div>
</section>

<!-- Admin Controls -->
<section *ngIf="isAdmin" class="d-flex justify-content-center mt-1">
  <div class="btn-group w-100">
    <button
      class="btn btn-sm"
      [ngClass]="person.withCustomer ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
      type="button"
      (click)="onWithCustomerClicked()"
      [disabled]="waitOnUpdates()"
    >
      <span
        *ngIf="isPendingAction('toggleWithCustomer')"
        class="spinner-border spinner-border-sm mr-1"
      >
      </span>
      With Guest
    </button>

    <button
      class="btn btn-sm app-btn-neutral"
      type="button"
      (click)="onMoveToBottomClicked()"
      [disabled]="waitOnUpdates()"
    >
      <span
        *ngIf="isPendingAction('moveToBottom')"
        class="spinner-border spinner-border-sm mr-1"
      >
      </span>
      To Bottom
    </button>

    <button
      class="btn btn-sm app-btn-danger"
      type="button"
      (click)="onRemoveFromSalesQueueClicked()"
      [disabled]="waitOnUpdates()"
    >
      <span *ngIf="isPendingAction('remove')" class="spinner-border spinner-border-sm mr-1">
      </span>
      Remove
    </button>
  </div>
</section>

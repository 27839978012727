import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../../auth-module/services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RoutingService, VehicleService } from "src/app/clearpath-module/services";
import { Vehicle } from "../../clearpath-module/models";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CnaService } from "../../clearpath-module/services/cna.service";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-cna-page",
  templateUrl: "./cna-page.component.html",
  styleUrls: ["./cna-page.component.scss"]
})
export class CNAPageComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  vehicles: Vehicle[] = [];
  filteredVehicles: Vehicle[] = [];
  searchText: string;
  sortParam = "model";
  filter = false;
  ascending = false;
  changeDealOnSelectVehicle = true;
  dispatchStockNumberOnly = false;

  searchForm: FormGroup = this.formBuilder.group({
    conditionFilter: new FormControl('all'),
    colorFilter: new FormControl('all'),
    makeFilter: new FormControl('all'),
    typeFilter: new FormControl('all'),
    mileFilter: new FormControl('all'),
    priceFilter: new FormControl('all'),
    sortOption: new FormControl('Price: Low to High'),
  });

  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private vehicleService: VehicleService,
    private formBuilder: FormBuilder,
    public cnaService: CnaService,
  ) { }

  public cnas: any[] = [
    {
      question: "What are the main reasons you are interested in a new vehicle?",
      options: [
        {
          label: "Good Overall Size",
          selected: false,
        },
        {
          label: "Good MPG",
        },
        {
          label: "Styling",
        },
        {
          label: "Safety Features",
        },
        {
          label: "Dependability",
        },
        {
          label: "Good Resale Value",
        },
      ],
    },
    {
      question: "Gas, Hybrid, or Plug-In Hybrid?",
      options: [
        {
          label: "Gas"
        },
        {
          label: "Hybrid",
        },
        {
          label: "Plug-In Hybrid",
        },
      ],
    },
    {
      question: "Click on all of the road types you drive on?",
      options: [
        {
          label: "Highway"
        },
        {
          label: "City",
        },
        {
          label: "Rural Roads",
        },
        {
          label: "Mountain",
        },
        {
          label: "Off-Road/Camping",
        },
      ],
    },
    {
      question: "Are there any Options or Features that you want to be sure to have?",
      options: [
        {
          label: "Leather Seats"
        },
        {
          label: "Heated Seats",
        },
        {
          label: "Heated Steering Wheel",
        },
        {
          label: "Sunroof",
        },
        {
          label: "Alloy Wheels",
        },
        {
          label: "Navigation",
        },
        {
          label: "Premium Sound",
        },
      ],
    },
    {
      question: "What things do you not like about your current vehicle?",
      options: [
        {
          label: "MPG"
        },
        {
          label: "Not Reliable",
        },
        {
          label: "High Repair Costs",
        },
        {
          label: "Too big or small",
        },
        {
          label: "Not Safe Enough",
        },
        {
          label: "Too Slow",
        },
        {
          label: "Styling",
        },
        {
          label: "Need AWD",
        },
      ],
    },
  ];

  public models: any[] = [
    {
      id: "4Runner",
      image: "4Runner.png",
      name: "4-Runner",
    },
    {
      id: "Avalon",
      image: "Avalon.png",
      name: "Avalon",
    },
    {
      id: "AvalonHybrid",
      image: "AvalonHybrid.png",
      name: "Avalon Hybrid",
    },
    {
      id: "bz4x",
      image: "bz4x.png",
      name: "bz4x",
    },
    {
      id: "Camry",
      image: "Camry.png",
      name: "Camry",
    },
    {
      id: "CamryHybrid",
      image: "CamryHybrid.png",
      name: "Camry Hybrid",
    },
    {
      id: "CH-R",
      image: "CH-R.png",
      name: "CH-R",
    },
    {
      id: "Corolla",
      image: "Corolla.png",
      name: "Corolla",
    },
    {
      id: "CorollaCross",
      image: "CorollaCross.png",
      name: "Corolla Cross",
    },
    {
      id: "CorollaHatchback",
      image: "CorollaHatchback.png",
      name: "Corolla Hatchback",
    },
    {
      id: "CorollaHybrid",
      image: "CorollaHybrid.png",
      name: "Corolla Hybrid",
    },
    {
      id: "GR86",
      image: "GR86.png",
      name: "GR86",
    },
    {
      id: "GR-Supra",
      image: "GR-Supra.png",
      name: "GR Supra",
    },
    {
      id: "Highlander",
      image: "Highlander.png",
      name: "Highlander",
    },
    {
      id: "HighlanderHybrid",
      image: "HighlanderHybrid.png",
      name: "Highlander Hybrid",
    },
    {
      id: "Prius",
      image: "Prius.png",
      name: "Prius",
    },
    {
      id: "PriusPrime",
      image: "PriusPrime.png",
      name: "Prius Prime",
    },
    {
      id: "RAV4",
      image: "RAV4.png",
      name: "RAV4",
    },
    {
      id: "RAV4Hybrid",
      image: "RAV4Hybrid.png",
      name: "RAV4 Hybrid",
    },
    {
      id: "Sequoia",
      image: "Sequoia.png",
      name: "Sequoia",
    },
    {
      id: "Sienna",
      image: "Sienna.png",
      name: "Sienna",
    },
    {
      id: "Tacoma",
      image: "Tacoma.png",
      name: "Tacoma",
    },
    {
      id: "Tundra",
      image: "Tundra.png",
      name: "Tundra",
    },

    {
      id: "TundraMAX",
      image: "TundraMAX.png",
      name: "Tundra MAX",
    },
    {
      id: "Venza",
      image: "Venza.png",
      name: "Venza",
    },
  ];

  public selectedModel: any;

  public conditionFilters = ['New', 'Certified', 'Used'];

  public colorFilters = ['Beige', 'Black', 'Blue', 'Crimson', 'Gray', 'Green', 'Red', 'Silver', 'White'];

  public makeFilters = ['Audi', 'BMW', 'Ford', 'Mazda', 'Toyota'];

  public typeFilters = ['Car', 'Truck'];

  public mileFilters = ['up to 30,000', 'up to 50,000', 'up to 80,000', '80,000+'];

  public priceFilters = ['up to $15,000', '$15,000 - $25,000', '$25,000 - $35,000', '$35,000+'];

  public sortOptions = [
    'Price: Low to High',
    'Price: High to Low',
    'Miles: Low to High',
    'Miles: High to Low',
    'MPG: Low to High',
    'MPG: High to Low',
  ];

  ngOnInit() {
    /*this.subToVehicles();

    this.searchForm.valueChanges
      .subscribe(value => {
        if (this.searchForm.dirty) {
          console.log("Search Form Changed")
          this.updateFilteredVehicles();
        }
      });*/
  }

  getConditon(isUsed, isCertified) {
    if (isCertified)
      return 'Certified'
    if (isUsed)
      return 'Used'
    return 'New'
  }

  private subToVehicles() {
    this.vehicleService.selectUnsoldVehicles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicles: Vehicle[]) => {
        setTimeout(() => {
          if ((this.vehicles || []).length === 0) {
            this.vehicleService.dispatchGetAllVehicles();
          }
        }, 5000);

        // blocked vehicles (never show these vehicles.  Issue #1393)
        const blockedVehicles = [26493, 26619, 28151, 28274, 28314, 28318, 28374, 28404, 28405, 28440, 28452, 28531];

        // return list of vehicles which do not include blocked stock numbers
        this.vehicles = vehicles.filter(vehicle => !vehicle.sold && !(blockedVehicles.includes(Number(vehicle.stockNumber))));

        //console.log("2. Starting Vehicle to Model Group associator: vehicle count: ", vehicles.length);

        if (this.vehicles?.length > 0) {
          //console.log(this.vehicles[ 0 ]);
        }

        this.updateFilteredVehicles();

        this.vehicles.forEach(vehicle => {
          // console.log(vehicle.stockNumber + " -" + vehicle.make + " -" + vehicle.model + " -" + vehicle.trim);
        });
      });
  }

  updateFilteredVehicles() {

    this.vehicles.forEach(vehicle => {
      // console.log(vehicle.bodyStyle);
    });

    const list = [];
    this.vehicles.forEach(vehicle => {
      if (
        this.conditionFilterCheck(vehicle, this.searchForm.get('conditionFilter').value) &&
        this.makeFilterCheck(vehicle, this.searchForm.get('makeFilter').value) &&
        this.typeFilterCheck(vehicle, this.searchForm.get('typeFilter').value) &&
        this.mileFilterCheck(vehicle, this.searchForm.get('mileFilter').value) &&
        this.priceFilterCheck(vehicle, this.searchForm.get('priceFilter').value)
      ) {
        list.push(vehicle);
      }
    });

    const sortOption = this.searchForm.get('sortOption').value

    list.sort((a, b) => {
      switch (sortOption) {
        case 'Price: Low to High':
          return a.retail < b.retail ? -1 : (a.retail > b.retail ? 1 : 0)
        case 'Price: High to Low':
          return a.retail < b.retail ? 1 : (a.retail > b.retail ? -1 : 0)
        case 'Miles: Low to High':
          return a.odometer < b.odometer ? -1 : (a.odometer > b.odometer ? 1 : 0)
        case 'Miles: High to Low':
          return a.odometer < b.odometer ? 1 : (a.odometer > b.odometer ? -1 : 0)
        case 'MPG: Low to High':
          return a.mpg < b.mpg ? -1 : (a.mpg > b.mpg ? 1 : 0)
        case 'MPG: High to Low':
          return a.mpg < b.mpg ? 1 : (a.mpg > b.mpg ? -1 : 0)
      }
    })
    this.filteredVehicles = list;
  }

  conditionFilterCheck(vehicle: Vehicle, filterValue: string): boolean {
    if (filterValue == 'all') return true;
    if (filterValue == 'New' && !vehicle.isUsed && !vehicle.isCertified) return true;
    if (filterValue == 'Certified' && vehicle.isCertified) return true;
    if (filterValue == 'Used' && vehicle.isUsed && !vehicle.isCertified) return true;
    return false
  }

  makeFilterCheck(vehicle: Vehicle, filterValue: string): boolean {
    const vehicleMake = vehicle.make.toLowerCase()
    if (filterValue == 'all') return true;
    let matchFound = false
    this.makeFilters.forEach(make => {
      if (this.makeFilterCheckItem(filterValue, make, vehicleMake)) matchFound = true;
    })
    return matchFound
  }

  makeFilterCheckItem(filterValue, make, vehicleMake) {
    return filterValue == make && vehicleMake.includes(make.toLowerCase())
  }

  typeFilterCheck(vehicle: Vehicle, filterValue: string): boolean {
    if (filterValue == 'all') return true;
    let matchFound = false
    this.typeFilters.forEach(type => {
      if (filterValue == type && vehicle.bodyStyle == type.toUpperCase()) matchFound = true;
    })
    return matchFound
  }

  mileFilterCheck(vehicle: Vehicle, filterValue: string): boolean {
    if (filterValue == 'all') return true;
    switch (filterValue) {
      case 'up to 30,000':
        return vehicle.odometer < 30000
      case 'up to 50,000':
        return vehicle.odometer < 50000
      case 'up to 80,000':
        return vehicle.odometer < 80000
      case '80,000+':
        return vehicle.odometer > 80000
    }
    return false
  }

  priceFilterCheck(vehicle: Vehicle, filterValue: string): boolean {
    if (filterValue == 'all') return true;
    switch (filterValue) {
      case 'up to $15,000':
        return vehicle.retail < 15000
      case '$15,000 - $25,000':
        return vehicle.retail >= 15000 && vehicle.retail < 25000
      case '$25,000 - $35,000':
        return vehicle.retail >= 25000 && vehicle.retail < 35000
      case '$35,000+':
        return vehicle.retail > 35000
    }
    return false
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  viewCNA(model: any) {
    this.selectedModel = model;
  }

  isActiveSort(type: string): boolean {
    return this.sortParam === type;
  }

  onSelectSort(type: string) {
    this.sortParam = type;
    this.ascending = !this.ascending;
  }


  updateCustomerFirstName($event) {
    this.cnaService.firstName = $event;
  }

  updateCustomerLastName($event) {
    this.cnaService.lastName = $event;
  }

  resetForm() {
    this.cnaService.resetForm()
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { AppService, CalculationService, DealService, VehicleService } from "../../services";
import { Accessory, FinanceOptions, LeaseOptions, Links, Vehicle } from "../../models";
import { SelectedIncentive } from "src/app/settings-module/models/incentives";
import { DealIncentivesService } from "../../services/deal/deal-incentives.service";
import { DealerAccessory } from "../../models/vehicle";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-vehicle-price",
  templateUrl: "./vehicle-price.component.html",
  styleUrls: ["./vehicle-price.component.scss"]
})
export class VehiclePriceComponent implements OnInit, OnDestroy {
  @Input() vehicle: Vehicle;
  @Input() leaseOptions: LeaseOptions;
  @Input() financeOptions: FinanceOptions;
  @Input() financeOptionsEdits: FinanceOptions;
  private unsubscribe$ = new Subject();
  private uiState = {showDetails: true};
  private appData = {
    catalogUrl: "",
    featuredAccessories: {
      new: [],
      certified: [],
      used: []
    }
  };
  private dealData = {accessories: null};
  dealerAccessories: DealerAccessory[] = [];
  selectedIncentives: SelectedIncentive[];
  selectedCashIncentivesTotal: number;
  baseVehiclePrice$: Observable<number>;
  selectedDealerAccessories: string[] = [];

  showCostOfOwnershipLink: boolean = false;

  // flag used to display 'msrp' vs 'market price'
  isUsed: boolean = false;

  constructor(
    private dealService: DealService,
    private historyService: HistoryService,
    private appService: AppService,
    private calculationService: CalculationService,
    private incentivesService: DealIncentivesService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.subToVehicle();
    this.subToStoreData();
    this.initObservables();
    this.baseVehiclePrice$ = this.calculationService.baseVehiclePrice$();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  subToVehicle() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle: any) => {
        this.isUsed = vehicle.isUsed;
        this.vehicle = vehicle;
        this.dealerAccessories = this.vehicleService.parsePBSCustomFields(this.vehicle);
        this.baseVehiclePrice$ = this.calculationService.baseVehiclePrice$();
      });
  }

  private subToStoreData() {
    this.appService.selectLinks()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((links: Links) => {
        const {accessoryCatalogLink: catalogLink} = links;
        if (catalogLink) { this.appData.catalogUrl = catalogLink.linkUrl; }

        const newAcc = links.featuredAccessoriesNew || [];
        const certAcc = links.featuredAccessoriesCertified || [];
        const usedAcc = links.featuredAccessoriesUsed || [];

        this.appData.featuredAccessories = {
          new: newAcc.filter(item => item.name !== ""),
          certified: certAcc.filter(item => item.name !== ""),
          used: usedAcc.filter(item => item.name !== "")
        };
      });

    this.dealService.selectAccessories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((accessories: Partial<Accessory>[]) => {
        this.dealData.accessories = accessories.filter(a => !a.hidden);
      });

    this.dealService.selectSelectedDealerAccessories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((accessories: string[]) => {
        this.selectedDealerAccessories = accessories;
      });
  }

  initObservables() {
    this.incentivesService
      .selectedCashIncentives$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedIncentives: SelectedIncentive[]) => {
        this.selectedIncentives = selectedIncentives;
        this.selectedCashIncentivesTotal = selectedIncentives.reduce(
          (sum: number, incentive: SelectedIncentive) => sum + (incentive.value || 0)
          , 0
        );
      });
  }


  // ACTIONS

  dispatchCatalogEvent() {
    this.historyService.dispatchAccessoriesCatalog();
  }

  onAddAccessory(accessory: Partial<Accessory>) {
    if (!this.accessoryInCart(accessory)) {
      this.dealService.submitAccessory(accessory);
    }
  }

  onRemoveAccessory(accessory: Partial<Accessory>) {
    if (this.accessoryInCart(accessory)) {
      this.dealService.submitAccessory(accessory);
    }
  }

  onViewIncentives() {
    this.router.navigate([{outlets: {modal: "vehicle-incentives"}}]);
  }

  // UI CONTROL & RENDERING

  btSavings() {
    const savings = (this.financeOptionsEdits.discount !== null) ? (this.financeOptionsEdits.discount) : (this.msrp - this.retail);
    return savings > 0 ? savings : 0;
  }

  get msrp() {
    return this.financeOptionsEdits.msr === null ? this.vehicle.msr : this.financeOptionsEdits.msr;
  }

  get retail() {
    return this.financeOptionsEdits.retail === null ?
      this.vehicle.retail :
      this.financeOptionsEdits.retail;
  }

  onToggleDetails() {
    this.uiState.showDetails = !this.uiState.showDetails;
  }

  accessoryInCart(accessory): boolean {
    const {name, price} = accessory;
    for (const item of this.selectedAccessories) {
      const nameMatch = item.name === name;
      const priceMatch = true;//item.price === price;
      if (nameMatch && priceMatch) { return true; }
    }
    return false;
  }

  viewCostOfOwnership = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    window.open('https://www.kbb.com/new-cars/total-cost-of-ownership/', 'costOfOwnership')
  }

  selectedDealAccessory(currentAccessory, selectedAccessories: Partial<Accessory>[]) {
    let selected = null;
    selectedAccessories.forEach(acc => {
      if (acc.name === currentAccessory.name) {
        selected = acc
      }
    })
    return selected
  }

  isPaintAccessoryEnabled(currentAccessory, selectedAccessories: Partial<Accessory>[]) {
    let isEnabled = false;
    if (currentAccessory.name !== "Paint Protection Film") {
      return true;
    }
    selectedAccessories.forEach(acc => {
      if (acc.name === "Paint Protection Film" && !acc.disabled) {
        isEnabled = true;
      }
    })
    return isEnabled;
  }

  get dealerAccessoriesTotal() {
    let price = 0;
    this.dealerAccessories.forEach((acc: DealerAccessory) => {
      price += acc.price;
    });
    return price;
  }

  get showDetails(): boolean {
    return this.uiState.showDetails;
  }

  get catalogUrl(): string {
    return this.appData.catalogUrl;
  }

  get vehicleName(): string {
    return this.vehicleService.vehicleName(this.vehicle);
  }

  get vehicleCondition(): string {
    return this.vehicleService.vehicleCondition(this.vehicle);
  }

  get featuredAccessories(): Accessory[] {
    return this.appData.featuredAccessories[ this.vehicleCondition ] || [];
  }

  get selectedAccessories(): Partial<Accessory>[] {
    return this.dealData.accessories || [];
  }

  get calcSavings(): number {
    const savings = this.vehicle.msr - this.vehicle.retail;
    return savings > 0 ? savings : 0;
  }

  get customizedVehiclePrice$(): Observable<number> {
    return this.calculationService.customizedVehiclePrice$();
  }

}

import { Component } from "@angular/core";

@Component({
  selector: "app-gap-graph",
  templateUrl: "./gap-graph.component.html",
  styleUrls: ["./gap-graph.component.scss"]
})
export class GapGraphComponent {

  constructor() { }

}

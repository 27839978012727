import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FeatureFlag, isFlagEnabled } from "../../../util/featureFlags";

@Component({
  selector: "app-sales-manager-writeup-controls",
  templateUrl: "./sales-manager-writeup-controls.component.html",
  styleUrls: ["./sales-manager-writeup-controls.component.scss"]
})
export class SalesManagerWriteupControlsComponent {

  constructor() { }

  @Input() dealStatus: string;
  @Input() waitOnAction = false;
  @Input() unsavedChanges = false;
  @Input() salesManagerApproved;
  @Output() taxLookup = new EventEmitter();
  @Output() updateDeal = new EventEmitter();
  @Output() approveDeal = new EventEmitter();
  @Output() printDeal = new EventEmitter();
  @Output() printDisclosure = new EventEmitter();
  @Output() criticalMemo = new EventEmitter();
  @Output() completeDeal = new EventEmitter();
  @Output() declineDeal = new EventEmitter();
  @Output() saveDealChanges = new EventEmitter();
  @Output() cancelDealChanges = new EventEmitter();

  taxLookupButton: any = isFlagEnabled(FeatureFlag.VITU) ? {
    onClick: () => this.onTaxLookup(),
    title: "Tax Lookup",
    statusReqs: ["submitted", "approved", "printed", "printing", "completed", "changed", "updated", "created"]
  } : {}

  controls = {
    status: [
      /*{
        onClick: () => this.onUpdateDeal(),
        title: "Updated",
        statusReqs: ["submitted"]
      },*/
      {
        /*
        Disable critical Memo functionality until PBS can support it.
        onClick: () => this.onCriticalMemo(),
        */
        onClick: () => this.onApproveDeal(),
        title: "Send to PBS",
        statusReqs: ["submitted", "changed", "updated", "approved", "printed", "printing", "completed"]
      },
      {
        onClick: () => this.onPrintDeal(),
        title: "Print",
        statusReqs: ["submitted", "approved", "printed", "printing", "completed", "changed", "updated", "created"],
      },
      {
        onClick: () => this.onPrintDisclosure(),
        title: "Print Disclosure",
        statusReqs: ["submitted", "approved", "printed", "printing", "completed", "changed", "updated", "created"],
      },
      ...this.taxLookupButton,
      /*{
        onClick: () => this.onCriticalMemo(),
        title: "Critical Memo",
        statusReqs: ["submitted", "approved", "printed", "printing", "completed", "changed", "updated", "created"],
      },*/
      /*{
        onClick: () => this.onCompleteDeal(),
        title: "Sold",
        statusReqs: ["printing", "printed"]
      },
      {
        onClick: () => this.onDeclineDeal(),
        title: "No Sale",
        statusReqs: [
          "created", "changed", "submitted", "updated", "approved", "printing", "printed"
        ]
      },*/
    ],
    form: [
      {onClick: () => this.onSaveDealChanges(), title: "Save Changes"},
      /*{onClick: () => this.onCancelDealChanges(), title: "Cancel"},*/
    ]
  };

  // ACTIONS

  private onTaxLookup() {
    this.taxLookup.emit();
  }

  private onUpdateDeal() {
    this.updateDeal.emit();
  }

  private onApproveDeal() {
    this.approveDeal.emit();
  }

  private onPrintDeal() {
    this.printDeal.emit();
  }

  private onPrintDisclosure() {
    this.printDisclosure.emit();
  }

  private onCriticalMemo() {
    this.criticalMemo.emit();
  }

  private onCompleteDeal() {
    this.completeDeal.emit();
  }

  private onDeclineDeal() {
    this.declineDeal.emit();
  }

  private onSaveDealChanges() {
    this.saveDealChanges.emit();
  }

  private onCancelDealChanges() {
    this.cancelDealChanges.emit();
  }

  // UI CONTROL & RENDERING

  actionNotAllowed(control): boolean {
    return !control.statusReqs.includes(this.dealStatus);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FinanceDefault } from "src/app/settings-module/models";

@Component({
  selector: "app-finance-default-form",
  templateUrl: "./finance-default-form.component.html",
  styleUrls: ["./finance-default-form.component.scss"]
})
export class FinanceDefaultFormComponent implements OnInit {
  @Input() financeDefault: FinanceDefault;
  @Input() waitOnAction = false;
  @Output() submitFinanceDefault = new EventEmitter<FinanceDefault>();
  @Output() unsavedChanges = new EventEmitter();

  financeDefaultForm: FormGroup = this.formBuilder.group({
    newTermsShown: new FormArray([]),
    usedTermsShown: new FormArray([]),
    certTermsShown: new FormArray([]),
    customTermDefault: [0, [Validators.required, Validators.min(0)]],
    daysToFirstPay: [0, [Validators.required, Validators.min(0)]],
    dmvFees: [0, [Validators.required, Validators.min(0)]],
    gapAmortFactor: [0, [Validators.required, Validators.min(0)]],
    gapDepreciation: [0, [Validators.required, Validators.min(0)]],
    taxRate: [0, [Validators.required, Validators.min(0)]]
  });

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeFinanceDefaultForm();
    this.subToFormChanges();
  }

  // INITIALIZATION

  private initializeFinanceDefaultForm() {
    this.financeDefaultForm.patchValue({
      customTermDefault: this.financeDefault.customTermDefault,
      daysToFirstPay: this.financeDefault.daysToFirstPay,
      dmvFees: this.financeDefault.dmvFees,
      gapAmortFactor: this.financeDefault.gapAmortFactor,
      gapDepreciation: this.financeDefault.gapDepreciation,
      taxRate: this.financeDefault.taxRate
    });

    const initialNewTermsShown = this.financeDefault.newTermsShown || [];
    const initialUsedTermsShown = this.financeDefault.usedTermsShown || [];
    const initialCertTermsShown = this.financeDefault.certTermsShown || [];

    initialNewTermsShown.forEach(term => {
      this.addTermControl("newTermsShown", term);
    });

    initialUsedTermsShown.forEach(term => {
      this.addTermControl("usedTermsShown", term);
    });

    initialCertTermsShown.forEach(term => {
      this.addTermControl("certTermsShown", term);
    });
  }

  private subToFormChanges() {
    this.financeDefaultForm.valueChanges.subscribe(() => {
      this.unsavedChanges.emit();
    });
  }

  // ACTIONS

  onSubmitFinanceDefaultForm() {
    const financeDefault: FinanceDefault = this.financeDefaultForm.value;
    this.submitFinanceDefault.emit(financeDefault);
    this.financeDefaultForm.markAsPristine();
  }

  // UI CONTROL & RENDERING

  onAddTerm(termType: string) {
    this.addTermControl(termType);
    this.financeDefaultForm.markAsDirty();
  }

  onRemoveTerm(termType: string, termIndex: number) {
    const formArray = this.financeDefaultForm.get(termType) as FormArray;
    formArray.removeAt(termIndex);
    this.financeDefaultForm.markAsDirty();
  }

  get newTermsShown(): AbstractControl[] {
    const formArray = this.financeDefaultForm.get("newTermsShown") as FormArray;
    return formArray.controls;
  }

  get usedTermsShown(): AbstractControl[] {
    const formArray = this.financeDefaultForm.get("usedTermsShown") as FormArray;
    return formArray.controls;
  }

  get certTermsShown(): AbstractControl[] {
    const formArray = this.financeDefaultForm.get("certTermsShown") as FormArray;
    return formArray.controls;
  }

  valid(controlName: string): boolean {
    const control = this.financeDefaultForm.get(controlName);
    return control.valid;
  }

  value(controlName: string): number {
    return this.financeDefaultForm.value[ controlName ];
  }

  // FORM VALIDATION AND HELPERS

  private addTermControl(termType: string, term?: number) {
    const formArray = this.financeDefaultForm.get(termType) as FormArray;
    const control = new FormControl(term || null, [Validators.required, Validators.min(1)]);
    formArray.push(control);
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.financeDefaultForm.get(controlName);
    return control.touched && control.invalid;
  }

  touchedInvalidTerm(termType: string, termIndex: number): boolean {
    const control = this.financeDefaultForm.get([termType, termIndex]);
    return control.touched && control.invalid;
  }

}

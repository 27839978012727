<form [formGroup]="dealerSettingsForm"
      class="app-page-container">

  <h3 class="h3 mb-4 mt-4"
      style="text-align: center">Dealer Settings</h3>

  <section formGroupName="dealerInfo">
    <div class="row">
      <div class="form-group col-sm-6">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('dealerInfo', 'companyName') }">
          Company Name *
        </label>
        <input class="form-control"
               formControlName="companyName"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               autofocus="true"
               (blur)="trimInput('dealerInfo', 'companyName')"
               required/>
      </div>
      <div class="form-group col-sm-6">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('dealerInfo', 'companyPhone') }">
          Company Phone *
        </label>
        <input class="form-control"
               formControlName="companyPhone"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('dealerInfo', 'companyPhone')"
               required/>
      </div>
    </div>
  </section>

  <section formGroupName="companyAddress">
    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('companyAddress', 'street') }">
          Street *
        </label>
        <input class="form-control"
               formControlName="street"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('companyAddress', 'street')"
               required/>
      </div>
      <div class="form-group col-sm-4 col-md-2">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('companyAddress', 'city') }">
          City *
        </label>
        <input class="form-control"
               formControlName="city"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('companyAddress', 'city')"
               required/>
      </div>
      <div class="form-group col-sm-4 col-md-2">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('companyAddress', 'state') }">
          State *
        </label>
        <input class="form-control"
               formControlName="state"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('companyAddress', 'state')"
               required/>
      </div>
      <div class="form-group col-sm-4 col-md-2">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('companyAddress', 'zip') }">
          Zip *
        </label>
        <input class="form-control"
               formControlName="zip"
               type="text"
               inputmode="numeric"
               maxlength="5"
               (blur)="trimInput('companyAddress', 'zip')"
               required/>
      </div>
    </div>
  </section>

  <section formGroupName="dealerSettings">
    <div class="row">
      <div class="form-group col-sm-6">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('dealerSettings', 'dealerCode') }">
          Dealer Code *
        </label>
        <input class="form-control"
               formControlName="dealerCode"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('dealerSettings', 'dealerCode')"
               required/>
      </div>
      <div class="form-group col-sm-6">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('dealerSettings', 'gasPrice') }">
          Gas Price *
        </label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn input-group-text"
                    style="padding: .1rem .75rem; cursor: default; border-radius: .25rem 0 0 .25rem;">
              $
            </button>
          </div>
          <input class="form-control"
                 formControlName="gasPrice"
                 type="number"
                 required/>
        </div>
      </div>
    </div>
  </section>

  <h1 class="h4 font-weight-light mb-2 mt-3">Display Defaults</h1>

  <hr>

  <section formGroupName="displayDefaults">
    <div class="row">
      <div class="form-group col-sm-4">
        <label>
          Web Logo URL
        </label>
        <input class="form-control"
               formControlName="webLogoUrl"
               type="text"
               [maxlength]="REQS.maxLength.long"
               (blur)="trimInput('displayDefaults', 'webLogoUrl')"/>
      </div>
      <div class="form-group col-sm-4">
        <label>
          Print Logo URL
        </label>
        <input class="form-control"
               formControlName="printLogoUrl"
               type="text"
               [maxlength]="REQS.maxLength.long"
               (blur)="trimInput('displayDefaults', 'printLogoUrl')"/>
      </div>
      <!-- <div class="form-group col-sm-4">
        <label>
          Estimated Mileage
        </label>
        <input class="form-control" formControlName="estimatedMileage" type="number" />
      </div> -->
    </div>

    <div class="row">
      <!-- <div class="form-group col-sm-4">
        <label>
          Estimated Ownership
        </label>
        <input class="form-control" formControlName="estimatedOwnership" type="number" />
      </div> -->
      <div class="form-group col-sm-4">
        <label>
          Password Reset Message
        </label>
        <input class="form-control"
               formControlName="pwResetHeaderMsg"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('displayDefaults', 'pwResetHeaderMsg')"/>
      </div>
      <div class="form-group col-sm-4">
        <label>
          Password Reset Email
        </label>
        <input class="form-control"
               formControlName="pwResetEmail"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('displayDefaults', 'pwResetEmail')"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4">
        <label>
          Default Vehicle Photo URL
        </label>
        <input class="form-control"
               formControlName="vehiclePhotoUrl"
               type="text"
               [maxlength]="REQS.maxLength.long"
               (blur)="trimInput('displayDefaults', 'vehiclePhotoUrl')"/>
      </div>
      <div class="form-group col-sm-4">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('displayDefaults', 'feedbackEmail') }">
          Feedback Email
        </label>
        <input class="form-control"
               formControlName="feedbackEmail"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('displayDefaults', 'feedbackEmail')"
               required/>
      </div>
    </div>
  </section>

  <h1 class="h4 font-weight-light mb-2 mt-3">Service Defaults</h1>

  <hr>

  <section formGroupName="serviceDefaults">
    <div class="row">
      <div class="form-group col-sm-6">
        <label>
          Labor Rate
        </label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn input-group-text"
                    style="padding: .1rem .75rem; cursor: default; border-radius: .25rem 0 0 .25rem;">
              $
            </button>
          </div>
          <input class="form-control"
                 formControlName="laborRate"
                 type="number"/>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label>
          Appointment Email
        </label>
        <input class="form-control"
               formControlName="appointmentEmail"
               type="text"
               [maxlength]="REQS.maxLength.standard"
               (blur)="trimInput('serviceDefaults', 'appointmentEmail')"/>
      </div>
    </div>
  </section>

  <h1 class="h4 font-weight-light mb-2 mt-3">Deal Defaults</h1>

  <hr>

  <section formGroupName="dealDefaults">
    <div class="row d-flex align-items-end">
      <div class="form-group col-sm-3">
        <label>
          Declined/Stale Deal Archive Days
        </label>
        <input class="form-control"
               formControlName="staleArchiveDays"
               type="number"/>
      </div>
      <div class="form-group col-sm-3">
        <label>
          Completed Deal Archive Days
        </label>
        <input class="form-control"
               formControlName="completedArchiveDays"
               type="number"/>
      </div>
      <div class="form-group col-sm-3">
        <button (click)="runDealArchiver()"
                class="btn app-btn-success">Archive Submitted/Stale deals
        </button>
      </div>
    </div>
  </section>

  <hr>

  <h1 class="h4 font-weight-light mb-2 mt-3">Insurance Defaults</h1>

  <hr>

  <section formGroupName="insuranceDefaults">
    <div>Product Keys</div>
    <article formArrayName="productKeys"
             *ngFor="let productKey of productKeys; let i = index; trackBy:trackProductKeys;"
             class="input-group">
      <input class="form-control"
             [formControlName]="i"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             value="{{productKey}}"
             required>
      <div class="input-group-append">
        <button class="btn btn-sm app-btn-neutral d-flex align-items-center"
                type="button"
                (click)="onRemoveProductKey(i)">
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>
    <button class="btn app-btn-secondary"
            type="button"
            (click)="onAddProductKey()">
      Add
    </button>
  </section>

  <button type="submit"
          class="btn app-btn-secondary text-center mt-1 mb-2 mr-auto ml-auto d-block"
          (click)="onSubmitDealerForm(dealerSettingsForm.value)"
          [disabled]="!dealerSettingsForm.touched || !dealerSettingsForm.valid">
    Submit
  </button>

</form>

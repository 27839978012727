import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { User } from "../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"]
})
export class UserFormComponent implements OnInit {
  @Input() securityGroups: Array<string> = [];
  @Input() editTarget: User = null;
  @Input() waitOnAction = false;

  @Output() createUser = new EventEmitter();
  @Output() editUser = new EventEmitter();

  public REQS = FORM_INPUT_REQS;
  private formMode = {
    current: null,
    options: {
      create: "create",
      edit: "edit"
    },
    employeeIdLocked: true
  };

  userForm: FormGroup = this.formBuilder.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    email: ["", [Validators.required]],
    smsCellNumber: ["", [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern("[0-9]*")
    ]],
    employeeId: ["", Validators.required],
    managerId: [""],
    password: [""], // Validators dynamically set by form mode
    photoUrl: ["", Validators.maxLength(this.REQS.maxLength.long)],
    securityGroupsArray: new FormArray([]),
    suppressPresentationStarted: [false, Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.setFormMode();
    this.initializeFormData();
  }

  // INITIALIZATION

  setFormMode() {
    const {create, edit} = this.formMode.options;
    const editMode = this.editTarget !== null;
    this.formMode.current = editMode ? edit : create;

    const lengthValidators = [
      Validators.minLength(this.REQS.minLength.password),
      Validators.maxLength(this.REQS.maxLength.password)
    ];

    if (editMode) {
      // Edit Mode: No Input => No Password Change
      this.userForm.controls.password.setValidators(lengthValidators);
    } else {
      // Create Mode: Requires Password
      this.userForm.controls.password.setValidators(
        [Validators.required].concat(lengthValidators)
      );
    }
  }

  initializeFormData() {
    let securityGroupMembership: Array<string> = [];

    const editMode = this.formMode.current === this.formMode.options.edit;
    if (editMode) {
      const {
        firstName,
        lastName,
        email,
        smsCellNumber,
        employeeId,
        managerId,
        photoUrl,
        securityGroups,
        suppressPresentationStarted
      } = this.editTarget;

      this.userForm.patchValue({
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        employeeId: employeeId || "",
        managerId: managerId || "",
        smsCellNumber: smsCellNumber || "",
        photoUrl: photoUrl || "",
        suppressPresentationStarted: suppressPresentationStarted || false
      });

      securityGroupMembership = User.unpackSecurityGroups(securityGroups);
    }

    // Generate Security Groups Checkboxes
    this.securityGroups.forEach(group => {
      const userMembership: boolean = securityGroupMembership.includes(group);
      const control = new FormControl(userMembership);
      (this.userForm.controls.securityGroupsArray as FormArray).push(control);
    });
  }

  // ACTIONS

  onSubmit() {
    const {
      firstName,
      lastName,
      email,
      smsCellNumber,
      employeeId,
      managerId,
      password,
      photoUrl,
      securityGroupsArray,
      suppressPresentationStarted
    } = this.userForm.value;

    const securityGroupMembership = [];
    securityGroupsArray.forEach((isMember: boolean, i: number) => {
      if (isMember) {
        securityGroupMembership.push(this.securityGroups[ i ]);
      }
    });

    const userData: User = {
      firstName,
      lastName,
      email,
      smsCellNumber,
      employeeId,
      managerId,
      password,
      photoUrl,
      securityGroups: User.packSecurityGroups(securityGroupMembership),
      suppressPresentationStarted
    };

    const {create, edit} = this.formMode.options;
    switch (this.formMode.current) {
      case create:
        this.createUser.emit({userData});
        break;
      case edit:
        this.editUser.emit({userData});
        break;
    }
  }

  // FORM VALIDATION & HELPERS

  activeFormMode(mode: string): boolean {
    return this.formMode.current === this.formMode.options[ mode ];
  }

  onToggleEmployeeIdLock() {
    this.formMode.employeeIdLocked = !this.formMode.employeeIdLocked;
  }

  touchedInvalid(controlName: string): boolean {
    const touched = this.userForm.controls[ controlName ].touched;
    const valid = this.userForm.controls[ controlName ].valid;
    return touched && !valid;
  }

  trimInput(controlName: string) {
    const value = this.userForm.value[ controlName ].trim();
    this.userForm.patchValue({[ controlName ]: value});
  }

  get securityGroupControls() {
    return (this.userForm.get("securityGroupsArray") as FormArray).controls;
  }

  get employeeIdLocked(): boolean {
    return this.formMode.employeeIdLocked;
  }

}

<section class="bar-graph-container">

  <!-- RISK INFO LABEL -->
  <div *ngIf="showLabel('riskInfo')" class="bar-graph-label-group">
    <article
      class="bar-graph-label"
      [ngClass]="graph.riskInfo.bgClass">
      <div class="bar-graph-label__title">
        {{ graph.riskInfo.title }}
      </div>
      <div *ngFor="let detail of graph.riskInfo.details">
        {{ detail }}
      </div>
    </article>
  </div>

  <!-- PRODUCT GRAPH SUB LAYER -->
  <article class="bar-graph bar-graph--sub-layer"
           [ngClass]="graphClasses(graph.product)"
           [ngStyle]="{ 'width': graph.product.width + '%'}">
  </article>

  <!-- PRODUCT LABEL -->
  <div *ngIf="showLabel('product')"
       class="bar-graph-label-group"
       [ngStyle]="{ 'width': graph.product.width + '%'}">
    <article class="bar-graph-label"
             [ngClass]="graph.riskInfo.bgClass">
      <div class="bar-graph-label__title">
        {{ graph.riskInfo.title }}
      </div>
      <div *ngFor="let detail of graph.riskInfo.details">
        {{ detail }}
      </div>
    </article>

    <article class="bar-graph-label text-right">
      <div class="bar-graph-label__title">
        {{ graph.product.title }}
      </div>
      <div>
        {{ graph.product.term }} Months /
        <span *ngIf="unlimitedMiles(graph.product.miles)">
          Unlimited Miles
        </span>
        <span *ngIf="!unlimitedMiles(graph.product.miles)">
          {{ graph.product.miles / 1000 | number }}K Miles
        </span>
      </div>
      <div *ngFor="let detail of graph.product.details">
        {{ detail }}
      </div>
    </article>
  </div>

  <!-- DEFAULT COVERAGE GRAPHS -->
  <article *ngFor="let graph of graph.defaults"
           class="multi-bar-graph-track">

    <div *ngFor="let segment of graph"
         class="bar-graph"
         [ngClass]="graphClasses(segment)"
         [ngStyle]="{ 'width': segment.width + '%' }">

      <div class="bar-graph-label">
        <div class="bar-graph-label__title">
          {{ segment.title }}
        </div>

        <div>
          <img *ngIf="segment.imageSrc"
               [src]=segment.imageSrc
               class="bar-graph-label__image">
          {{ segment.term }} Months / {{ segment.miles / 1000 | number }}K Miles
        </div>

        <div *ngIf="showDefaultDetails()">
          <div *ngFor="let detail of segment.details">
            {{ detail }}
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let segment of graph"
         [ngClass]="segment.width > 0 ? 'd-none' : 'bar-graph-label'">
      <div>{{ segment.title }}</div>
      <div>
        <img *ngIf="segment.imageSrc"
             [src]=segment.imageSrc
             class="bar-graph-label__image">
        {{ segment.term }} Months / {{ segment.miles / 1000 | number }}K Miles
        <span class="app-text-danger">Expired</span>
      </div>
    </div>

  </article>

</section>

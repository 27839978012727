import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InventoryPageComponent } from "./pages/inventory-page.component";
import { RouterModule } from "@angular/router";
import { InventoryRoutingModule } from "./inventory.routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";


@NgModule({
  declarations: [
    InventoryPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    InventoryRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatInputModule,
  ]
})
export class InventoryModule {}

export interface TermCost {
  term: number;
  miles: number;
  price: number;
  cost: number;
  markup: number;
  providerId: string;
  priceOverride: number;
}

export class DefaultTermCost implements TermCost {
  constructor() { }

  term: 0;
  miles: 0;
  price: 0;
  markup: 0;
  cost: 0;
  providerId: "";
  priceOverride: null;
}

interface RichLink {
  iconUrl: string;
  linkUrl: string;
  description: string;
}

export interface InsuranceProduct {
  termCosts: Array<TermCost>;
  link: RichLink;
  minYear: number;
  maxOdometer: number;
  name: string;
  tagLine: string;
  provider: string;
  providerName: string;
  productKey: string;
  productTerms: string;
  guidelines: string;
  details: Array<string>;
  excludedMakes: Array<string>;
  iconUrl: string;
  preLoadNew: boolean;
  preLoadUsed: boolean;
  preLoadCertUsed: boolean;
  preLoadCar: boolean;
  preLoadTruck: boolean;
  preLoadSuv: boolean;
  preLoadVan: boolean;
  preLoadOther: boolean;
  sellWithNew: boolean;
  sellWithUsed: boolean;
  sellWithCertUsed: boolean;
  pbsName: string;
  pbsType: string;
  flatPrice: number;
  flatMarkup: number;
  markupMatrix: MarkupMatrixItem[];
  selectedTerm: number;
}

export interface MarkupMatrixItem {
  termMin: number;
  termMax: number;
  newMarkup: number;
  usedMarkup: number;
  certMarkup: number;
}

export class DefaultInsuranceProduct implements InsuranceProduct {
  constructor() { }

  termCosts: Array<TermCost> = [];
  link: RichLink = {
    iconUrl: "",
    linkUrl: "",
    description: ""
  };
  minYear = 0;
  maxOdometer = 0;
  selectedTerm = 0;
  name = "";
  tagLine = "";
  provider = "";
  providerName = "";
  productKey = "";
  productTerms = "";
  guidelines = "";
  details = [];
  excludedMakes = [];
  iconUrl = "";
  preLoadNew = false;
  preLoadUsed = false;
  preLoadCertUsed = false;
  preLoadCar = false;
  preLoadTruck = false;
  preLoadSuv = false;
  preLoadVan = false;
  preLoadOther = false;
  sellWithNew = false;
  sellWithUsed = false;
  sellWithCertUsed = false;
  pbsName: "";
  pbsType: "";
  flatPrice: 0;
  flatMarkup: 0;
  markupMatrix: [];
}

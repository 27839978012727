<form [formGroup]="stockNumberForm" class="row" (ngSubmit)="onSubmitForm()">
  <div class="form-group col-sm-6">
    <label class="small">Stock Number *</label>
    <label *ngIf="error" class="small app-text-danger">{{ error }}</label>
    <input
      class="form-control"
      formControlName="stockNumber"
      type="text"
      inputmode="numeric"
      [maxlength]="REQS.maxLength.standard"
      required
    />
  </div>

  <div>
    <button
      class="btn rounded-pill px-2 py-0 mx-2"
      id="lookupButton"
      type="button"
      (click)="openVehicleLookup()"
    >
      Lookup
    </button>
  </div>
  <!--  -->

  <div class="form-group col-sm-6">
    <label class="small">Mileage</label>
    <input
      class="form-control"
      formControlName="odometer"
      type="number"
      inputmode="numeric"
      min="0"
    />
  </div>

  <div class="col-12 d-flex justify-content-end">
    <button
      class="btn app-btn-secondary"
      type="submit"
      [disabled]="stockNumberForm.invalid"
    >
      Submit
    </button>
  </div>
</form>

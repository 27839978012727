import * as rg4js from "raygun4js";
import { ErrorHandler } from "@angular/core";
import { environment } from "src/environments/environment";

const VERSION_NUMBER = "1.0.0.0";
rg4js("apiKey", environment.rayGunApiKey);
rg4js("setVersion", VERSION_NUMBER);
rg4js("enableCrashReporting", true);
rg4js("enablePulse", true);

// Create a new ErrorHandler and report and an issue straight to Raygun
export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any) {
    console.error("A runtime error has occurred.", e);
    rg4js("send", {
      error: e,
    });
  }
}

<i class="material-icons side-nav-icon app-text-dark"
   (click)="onToggleMenu()">
  menu
</i>

<section class="side-nav-menu"
         [ngClass]="{ 'side-nav-menu--open': isMenuOpen }">
  <div class="side-nav-title">
    <h1 class="h4 mb-0">MENU</h1>
    <i class="material-icons side-nav-icon"
       (click)="onToggleMenu()">
      close
    </i>
  </div>
  <div *ngIf="calculationsExposed"
       class="app-bg-white">
    <app-calculation-exposure></app-calculation-exposure>
  </div>
  <div>
    <div *ngFor="let item of menuItems$ | async"
         class="side-nav-item"
         (click)="onSelectAuxiliaryModalRoute(item)">
      {{item.description}}
    </div>
    <div *ngIf="(!calculationsExposed && !isProduction) || isQa || (isSalesManager$ | async) || (isAdmin$ | async)"
         class="side-nav-item"
         (click)="onSelectExposeCalculations()">
      Expose Calculations
    </div>
  </div>
</section>
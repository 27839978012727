<app-modal-view #modal="modal" modalTitle="Feedback">
  <section modal-body>
    <form [formGroup]="feedbackForm">

      <div class="form-group row justify-content-center">
        <div class="col-sm-offset-3 col-6">
          <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('title') }">
            Title <span>*</span>
          </label>
          <input class="form-control" formControlName="title" type="text" required/>
        </div>
      </div>

      <div class="form-group row justify-content-center">
        <div class="col-6">
          <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('message') }">
            Message
          </label>
          <input class="form-control" formControlName="message" type="text" required/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-6">
          <button [disabled]="loading" class="btn btn-sm rounded-pill border-white app-bg-blue app-text-white"
                  (click)="sendFeedback()">
            send
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          </button>
        </div>
      </div>

    </form>
  </section>
</app-modal-view>

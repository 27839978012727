import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IncentiveGroup } from "../../../models";

@Component({
  selector: "app-incentive-group-list",
  templateUrl: "./incentive-group-list.component.html",
  styleUrls: ["./incentive-group-list.component.scss"]
})
export class IncentiveGroupListComponent {

  @Input() incentiveGroups: IncentiveGroup[];
  @Input() activeGroupIndex = -1;
  @Output() selectGroup = new EventEmitter<number>();

  constructor() { }

  onSelectGroup(index: number) {
    this.selectGroup.emit(index);
  }

  isActiveGroup(index: number): boolean {
    return index === this.activeGroupIndex;
  }

  noGroups(): boolean {
    return this.incentiveGroups.length === 0;
  }


}

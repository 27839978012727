import { Directive, ElementRef, Renderer2 } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Directive({
  selector: "[appDealerLogo]"
})
export class DealerLogoDirective {
  env = environment;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.setAttribute(
      el.nativeElement,
      "src",
      `${this.env.apiUrl}${environment.dealerImagePath}`
    );
    renderer.setAttribute(el.nativeElement, "alt", `${environment.dealershipTitle}`);
  }
}

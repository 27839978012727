import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppService, RoutingService } from "src/app/clearpath-module/services";
import { Links } from "../../../clearpath-module/models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import * as appActions from "src/app/clearpath-module/store/actions/app.actions";
import { AlertService } from "src/app/shared-module/services";

@Component({
  selector: "app-menu-links",
  templateUrl: "./menu-links.component.html",
  styleUrls: ["./menu-links.component.scss"]
})
export class MenuLinksComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  loading = true;
  config: Links;

  constructor(
    private alertService: AlertService,
    private appService: AppService,
    private routingService: RoutingService,
    private actions$: Actions
  ) { }

  ngOnInit() {
    this.getLinks();
    this.subToLinkApiSuccessFailure();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITILIZATION

  private getLinks() {
    this.routingService.getLinks()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((links: Links) => {
        this.loading = false;
        this.config = links;
      });
  }

  private subToLinkApiSuccessFailure() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.linksApiSuccess),
    ).subscribe(() => {
      this.loading = false;
      this.alertService.success("Saved");
      setTimeout(() => {
        this.alertService.clear();
      }, 6000);
    });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.linksApiFailure),
    ).subscribe(action => {
      this.loading = false;
      this.alertService.error(action.error);
    });
  }

  // ACTIONS

  onModifyMenuLinks(menuItems) {
    this.config.menuItems = menuItems || [];
    this.save();
  }

  onModifyBlueBookLink({menuItem}) {
    this.config.blueBookLink = menuItem;
    this.save();
  }

  onModifyAccessoryCatalogLink({menuItem}) {
    this.config.accessoryCatalogLink = menuItem;
    this.save();
  }

  onModifyAccessoriesNew(accessories) {
    this.config.featuredAccessoriesNew = accessories;
    this.save();
  }

  onModifyAccessoriesUsed(accessories) {
    this.config.featuredAccessoriesUsed = accessories;
    this.save();
  }

  onModifyAccessoriesCertified(accessories) {
    this.config.featuredAccessoriesCertified = accessories;
    this.save();
  }

  save() {
    this.loading = true;
    this.appService.dispatchSetLinks(this.config);
  }

}

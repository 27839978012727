<div
  class="d-flex justify-content-between align-items-center"
  style="cursor: pointer"
  (click)="onToggleShowContents()"
>
  <label class="pb-0 h5 app-text-secondary font-weight-bold">
    #{{ itemIndex + 1 }}
    <span *ngIf="incentiveForm.get('title').value">
      - {{ incentiveForm.get("title").value }}</span
    >
  </label>
  <i class="material-icons app-text-secondary h2">
    {{ showContents ? "expand_less" : "expand_more" }}
  </i>
</div>

<form *ngIf="showContents" [formGroup]="incentiveForm">
  <div class="row mt-2">
    <div class="form-group col-sm-8">
      <label> Incentive Title </label>
      <input
        class="form-control"
        formControlName="title"
        type="text"
        [maxlength]="REQS.maxLength.standard"
      />
    </div>
  </div>

  <div class="d-flex align-items-start justify-content-between mt-4 mb-2">
    <label class="pb-0 font-weight-bold">Qualifying Vehicles:</label>
    <button
      class="btn btn-sm btn-sm app-btn-secondary m-1 d-flex align-items-center"
      (click)="onAddVehicle()"
    >
      Add Vehicle <i class="material-icons">add</i>
    </button>
  </div>

  <!-- Vehicle Qualifier List -->
  <section formArrayName="vehicleQualifierList">
    <div
      *ngFor="
        let vehicle of incentiveForm.get('vehicleQualifierList')['controls'];
        let i = index
      "
      class="row rates"
      [formGroupName]="i"
    >
      <div class="form-group col-sm-1">
        <label>Year</label>
        <input
          class="form-control"
          formControlName="year"
          type="text"
          maxlength="4"
        />
      </div>
      <div class="col-sm-1 pb-3 d-flex justify-content-center align-items-end">
        <strong>AND</strong>
      </div>
      <div class="form-group col-sm-2">
        <label>Make</label>
        <input
          class="form-control"
          formControlName="make"
          type="text"
          [maxlength]="REQS.maxLength.standard"
        />
      </div>
      <div class="form-group col-sm-2">
        <label class="d-flex align-items-center" style="margin-bottom: 0.6rem">
          <span class="pr-1">Model</span>
          <span style="font-size: 10px"
          >(leave&nbsp;blank&nbsp;if&nbsp;all)</span
          >
        </label>
        <input
          class="form-control"
          formControlName="model"
          type="text"
          [maxlength]="REQS.maxLength.standard"
        />
      </div>
      <div class="form-group col-sm-2">
        <label class="d-flex align-items-center" style="margin-bottom: 0.6rem">
          <span class="pr-1">Trim</span>
          <span style="font-size: 10px"
          >(leave&nbsp;blank&nbsp;if&nbsp;all)</span
          >
        </label>
        <input
          class="form-control"
          formControlName="trim"
          type="text"
          [maxlength]="REQS.maxLength.standard"
        />
      </div>
      <div class="col-sm-1 pb-3 d-flex justify-content-center align-items-end">
        <strong>OR</strong>
      </div>
      <div class="form-group col-sm-2">
        <label>Model&nbsp;#</label>
        <input
          class="form-control"
          formControlName="modelNumber"
          type="text"
          [maxlength]="REQS.maxLength.standard"
        />
      </div>
      <div
        class="col-sm-1 pl-0 pt-3 d-flex justify-content-end align-items-center"
      >
        <i
          class="material-icons app-text-danger mt-auto mb-auto ml-2"
          (click)="onRemoveVehicle(i)"
        >
          close
        </i>
      </div>
    </div>
  </section>

  <hr/>

  <!-- Finance Offer -->
  <section formGroupName="financeOffer">
    <!--  Finance Rates -->
    <div class="d-flex align-items-start justify-content-between mt-4 mb-1">
      <label class="pb-0 font-weight-bold">Finance Rates:</label>
      <button
        class="btn btn-sm btn-sm app-btn-secondary m-1 d-flex align-items-center"
        (click)="onAddFinanceRate()"
      >
        Add Finance Rate <i class="material-icons">add</i>
      </button>
    </div>

    <div class="row">
      <div
        *ngFor="
          let financeRate of incentiveForm
            .get('financeOffer')
            .get('financeRates')['controls'];
          let j = index
        "
        formArrayName="financeRates"
        class="col-sm-2 pl-1 pr-1 rates"
      >
        <div class="d-flex flex-column" [formGroupName]="j">
          <div class="form-group">
            <label>Term</label>
            <input class="form-control" formControlName="term" type="number"/>
          </div>
          <div formArrayName="tieredRates">
            <div
              *ngFor="
                let tieredRate of financeRate.get('tieredRates')['controls'];
                let k = index
              "
              class="form-group"
            >
              <div [formGroupName]="k">
                <label>Tier {{ k === 0 ? k + 1 + "+" : k }}</label>
                <input
                  class="form-control"
                  formControlName="item"
                  type="number"
                />
              </div>
            </div>
            <button
              class="btn btn-sm app-btn-danger d-block mr-auto ml-auto"
              (click)="onRemoveFinanceRate(j)"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- APR SUBVENTION -->
    <label class="mt-5 mb-2 font-weight-bold">APR Subvention:</label>
    <div class="row">
      <div formArrayName="aprSubventionTiers" class="col-sm-2 pl-1 pr-1 rates">
        <div
          *ngFor="let subventionTier of AprSubventionControls; let k = index"
          class="form-group"
        >
          <label>Tier {{ k === 0 ? k + 1 + "+" : k }}</label>
          <input class="form-control" [formControlName]="k" type="number"/>
        </div>
        <button
          class="btn btn-sm app-btn-success d-block mr-auto ml-auto"
          (click)="onAddAprSubventionTier()"
        >
          Add Tier
        </button>
        <hr/>
        <button
          class="btn btn-sm app-btn-danger d-block mr-auto ml-auto"
          (click)="onRemoveAprSubvention()"
        >
          Remove
        </button>
      </div>
    </div>

    <!-- Finance Details -->
    <label class="mt-5 mb-2 font-weight-bold">Finance Cash Details:</label>
    <div class="row">
      <div class="form-group col-sm-2">
        <label>Customer Cash</label>
        <span class="input-group">
          <div class="input-group-prepend">
            <button class="btn app-btn-neutral">$</button>
          </div>
          <input
            class="form-control"
            formControlName="customerCash"
            type="number"
          />
        </span>
      </div>
    </div>
  </section>

  <hr/>

  <!-- Lease Offer MFs-->
  <section formGroupName="leaseOffer">
    <div class="d-flex align-items-start justify-content-between mt-4 mb-1">
      <label class="pb-0 font-weight-bold">Lease Money Factors:</label>
      <button
        class="btn btn-sm btn-sm app-btn-secondary m-1 d-flex align-items-center"
        (click)="onAddLeaseMF()"
      >
        Add Lease Money Factor <i class="material-icons">add</i>
      </button>
    </div>

    <div class="row">
      <div
        *ngFor="let leaseOfferTerm of LeaseOfferTermsControls; let j = index"
        formArrayName="leaseOfferTerms"
        class="col-sm-2 pl-1 pr-1 rates"
      >
        <div class="d-flex flex-column" [formGroupName]="j">
          <div class="form-group">
            <label>Term</label>
            <input class="form-control" formControlName="term" type="number"/>
          </div>

          <div formArrayName="tieredLeaseMFs">
            <div
              *ngFor="let tieredLeaseMF of getMFControls(j); let l = index"
              class="form-group"
            >
              <label>Tier {{ l === 0 ? l + 1 + "+" : l }}</label>
              <input class="form-control" [formControlName]="l" type="number"/>
            </div>
            <button
              class="btn btn-sm app-btn-success d-block mr-auto ml-auto"
              (click)="onAddTier(j)"
            >
              Add Tier
            </button>
            <hr/>
            <button
              class="btn btn-sm app-btn-danger d-block mr-auto ml-auto"
              (click)="onRemoveLeaseMF(j)"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>

    <label class="mt-4 mb-2 font-weight-bold">Lease Details:</label>

    <div class="row">
      <div
        *ngFor="let leaseOfferTerm of LeaseOfferTermsControls; let j = index"
        formArrayName="leaseOfferTerms"
        class="col-sm-2 pl-1 pr-1 rates"
      >
        <div class="d-flex flex-column" [formGroupName]="j">
          <div class="form-group">
            <label>Term</label>
            <input class="form-control" formControlName="term" type="number"/>
          </div>

          <div class="form-group">
            <label>Lease Subvention</label>
            <span class="input-group">
              <div class="input-group-prepend">
                <button class="btn app-btn-neutral">$</button>
              </div>
              <input
                class="form-control"
                formControlName="leaseSubvention"
                type="number"
              />
            </span>
          </div>

          <div class="form-group">
            <label>Cash at Signing</label>
            <span class="input-group">
              <div class="input-group-prepend">
                <button class="btn app-btn-neutral">$</button>
              </div>
              <input
                class="form-control"
                formControlName="cashAtSigning"
                type="number"
              />
            </span>
          </div>

          <div class="form-group">
            <label>RCF</label>
            <input class="form-control" formControlName="rcf" type="number"/>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Cash Offer -->
  <section formGroupName="cashDetails">
    <label class="mt-4 mb-2 font-weight-bold">Additional Offers:</label>
    <div class="row">
      <div class="form-group col-sm-2">
        <label>Military Rebate</label>
        <span class="input-group">
          <div class="input-group-prepend">
            <button class="btn app-btn-neutral">$</button>
          </div>
          <input
            class="form-control"
            formControlName="militaryRebate"
            type="number"
          />
        </span>
      </div>
      <div class="form-group col-sm-2">
        <label>College Rebate</label>
        <span class="input-group">
          <div class="input-group-prepend">
            <button class="btn app-btn-neutral">$</button>
          </div>
          <input
            class="form-control"
            formControlName="collegeRebate"
            type="number"
          />
        </span>
      </div>
      <div class="form-group col-sm-2">
        <label>Lease Cash</label>
        <span class="input-group">
          <div class="input-group-prepend">
            <button class="btn app-btn-neutral">$</button>
          </div>
          <input
            class="form-control"
            formControlName="leaseCash"
            type="number"
          />
        </span>
      </div>
      <div class="form-group col-sm-2">
        <label>Finance Cash</label>
        <span class="input-group">
          <div class="input-group-prepend">
            <button class="btn app-btn-neutral">$</button>
          </div>
          <input
                  class="form-control"
                  formControlName="financeCash"
                  type="number"
          />
        </span>
      </div>
      <div class="form-group col-sm-2">
        <label>Bonus Cash</label>
        <span class="input-group">
          <div class="input-group-prepend">
            <button class="btn app-btn-neutral">$</button>
          </div>
          <input
            class="form-control"
            formControlName="bonusCash"
            type="number"
          />
        </span>
      </div>
      <div class="form-group col-sm-2">
        <label>Other Rebate</label>
        <span class="input-group">
          <div class="input-group-prepend">
            <button class="btn app-btn-neutral">$</button>
          </div>
          <input
            class="form-control"
            formControlName="otherRebate"
            type="number"
          />
        </span>
      </div>
    </div>
  </section>

  <section class="row mt-4">
    <div class="form-group col-sm-4 d-flex justify-content-end align-items-end">
      <label class="pr-2 pb-1 mb-1" style="width: 260px">
        Combine&nbsp;Offers
      </label>
      <input
        class="form-control"
        formControlName="combineOffers"
        type="checkbox"
      />
    </div>
    <div class="form-group col-sm-4 d-flex justify-content-end align-items-end">
      <label class="pr-2 pb-1 mb-1" style="width: 320px">
        Dealer&nbsp;Financing&nbsp;Required
      </label>
      <input
        class="form-control"
        formControlName="dealerFinancingRequired"
        type="checkbox"
      />
    </div>
    <button
      class="btn btn-sm app-btn-danger d-block ml-auto mr-3"
      style="height: 32px"
      (click)="onRemoveIncentive()"
    >
      Remove Incentive
    </button>
  </section>
</form>

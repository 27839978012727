import { Injectable } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { filter, tap } from "rxjs/operators";
import { AuthService } from "src/app/auth-module/services";
import { DealService, VehicleService } from "../../services";

const paths = {
  dealRouted: "deal/:dealId",
  salesManagerWriteUpRouted: "writeup/:dealId",
  newSalesManagerWriteUpRouted: "newSMWriteup",
  newDeal: "new-deal"
};

@Injectable()
export class RouterEffects {

  private listenToRouter() {
    this.router.events.pipe(
      filter(event => event instanceof ActivationStart),
      tap((event: ActivationStart) => {
        if (event.snapshot.routeConfig.path === paths.dealRouted) {
          this.vehicleService.dispatchClearVehicle();
          this.dealService.dispatchClearDeal();
        }
        if (event.snapshot.routeConfig.path === paths.newDeal) {
          this.authService.verifyToken();
        }
      })
    ).subscribe((event: ActivationStart) => {
      const isAuxiliaryModalRoute = event.snapshot.outlet === "modal";
      if (isAuxiliaryModalRoute) { return; }

      if (
        event.snapshot.routeConfig.path === paths.dealRouted ||
        event.snapshot.routeConfig.path === paths.salesManagerWriteUpRouted
      ) {
        const {dealId} = event.snapshot.params;
        this.dealService.dispatchGetByDealId(dealId);
      }

    });
  }

  constructor(
    private dealService: DealService,
    private authService: AuthService,
    private vehicleService: VehicleService,
    private router: Router,
  ) {
    this.listenToRouter();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MenuItem } from "src/app/clearpath-module/models";

@Component({
  selector: "app-menu-link-display",
  templateUrl: "./menu-link-display.component.html",
  styleUrls: ["./menu-link-display.component.scss"]
})
export class MenuLinkDisplayComponent implements OnInit {

  @Input() menuItem: MenuItem;
  @Input() index: number;
  @Input() overrideCollapsed: number;
  @Input() title: string;
  @Output() changes = new EventEmitter<{ menuItem: MenuItem, index: number }>();
  collapsed: boolean;

  constructor() { }

  ngOnInit() {
    this.collapsed = true;
  }

  onFormChanges = (menuItem) => {
    this.menuItem = menuItem;
    this.changes.emit({menuItem, index: this.index});
    this.collapsed = true;
  }

}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { take } from "rxjs/operators";
import { CalculationService, DealService } from "../../../services";
import { VehicleNeeds } from "../../../models";

@Component({
  selector: "app-miles-per-year-calculator",
  templateUrl: "./miles-per-year-calculator.component.html",
  styleUrls: ["./miles-per-year-calculator.component.scss"]
})
export class MilesPerYearCalculatorComponent implements OnInit, OnDestroy {
  mileage = {min: 0, max: 9999};
  milesPerYearForm: FormGroup = this.formBuilder.group({
    roundTripCommuteMiles: [30, [
      Validators.required,
      Validators.min(this.mileage.min),
      Validators.max(this.mileage.max)]
    ],
    commuteDaysPerWeek: [5, [
      Validators.required,
      Validators.min(1),
      Validators.max(7)]],
    otherMilesPerWeek: [80, [
      Validators.min(this.mileage.min),
      Validators.max(this.mileage.max)
    ]],
    milesDrivenPerYear: [0] // READONLY - Auto-Calculate
  });

  constructor(
    private formBuilder: FormBuilder,
    private calculationService: CalculationService,
    private dealService: DealService
  ) { }

  ngOnInit() {
    this.syncSelectedVehicleNeeds();
  }

  ngOnDestroy() {
    this.dealService.dispatchChangeDeal();
  }

  // INITIALIZATION

  private syncSelectedVehicleNeeds() {
    this.dealService.selectVehicleNeeds()
      .pipe(take(1))
      .subscribe((data: VehicleNeeds) => {
        const {roundTripCommuteMiles, commuteDaysPerWeek, otherMilesPerWeek} = data;

        if (commuteDaysPerWeek > 0) {
          this.milesPerYearForm.patchValue({
            roundTripCommuteMiles,
            commuteDaysPerWeek,
            otherMilesPerWeek
          });
        }
        this.calcMilesDrivenPerYear();
      });
  }

  // UI RENDERING

  get milesPerYear(): number {
    return this.milesPerYearForm.value.milesDrivenPerYear || 0;
  }

  // FORM VALIDATION & HELPERS

  private calcMilesDrivenPerYear() {
    const {
      roundTripCommuteMiles,
      commuteDaysPerWeek,
      otherMilesPerWeek
    } = this.milesPerYearForm.value;

    const milesDrivenPerYear = this.calculationService.getMilesPerYear(
      roundTripCommuteMiles,
      commuteDaysPerWeek,
      otherMilesPerWeek
    );

    this.milesPerYearForm.patchValue({milesDrivenPerYear});
  }

  autoSubmitMilesPerYearForm() {
    const {pristine, invalid} = this.milesPerYearForm;
    if (pristine || invalid) { return; }

    this.calcMilesDrivenPerYear();
    this.dealService.dispatchSetVehicleNeeds(this.milesPerYearForm.value);
    this.milesPerYearForm.markAsPristine();
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.milesPerYearForm.get(controlName);
    return control.touched && control.invalid;
  }

}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DealService, VehicleService, WarrantyUiService } from "../../../../services";
import { WARRANTY_SETTINGS } from "../../warranty-config";
import { InsuranceProduct, WarrantySettingOption, WarrantyUi } from "../../../../models";
import { Actions } from "@ngrx/effects";
import { TermCost } from "src/app/settings-module/models";
import { HistoryService } from "../../../../services/history.service";

@Component({
  selector: "app-warranty-form",
  templateUrl: "./warranty-form.component.html",
  styleUrls: ["./warranty-form.component.scss"]
})
export class WarrantyFormComponent implements OnInit, OnDestroy {
  @Input() warrantyUi: WarrantyUi;
  private unsubscribe$ = new Subject();
  private uiState = {showCalculator: false};
  private appData = {insuranceProducts: []};
  private dealData = {insuranceProducts: []};
  private vehicleInsuranceProducts: InsuranceProduct[];
  private previousPlannedLengthOfOwnership
  private previousMilesDrivenPerYear
  private previousProductValue: Map<string, string> = new Map()

  vehicleNeedsForm: FormGroup = this.formBuilder.group({
    // Select Values Return As String
    plannedLengthOfOwnership: ["0"],
    milesDrivenPerYear: ["0"]
  });

  insuranceProductsForm: FormGroup = this.formBuilder.group({
    // Dynamically Set [productKey]: termCostIndex
  });

  constructor(
    private formBuilder: FormBuilder,
    private dealService: DealService,
    private warrantyUiService: WarrantyUiService,
    private vehicleService: VehicleService,
    private actions$: Actions,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    this.syncSelectedVehicleNeeds();
    this.syncInsuranceProducts();
    this.autoSubmitVehicleNeedsForm();
    // this.listenForVehicleNeedsChanges();
  }

  ngOnDestroy() {
    this.autoSubmitVehicleNeedsForm();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private syncSelectedVehicleNeeds() {
    this.previousPlannedLengthOfOwnership = this.warrantyUi.ownershipYears
    this.previousMilesDrivenPerYear = this.warrantyUi.milesPerYear
    console.log("Syncing Selected Vehicle Needs", {
      plannedLengthOfOwnership: this.warrantyUi.ownershipYears,
      milesDrivenPerYear: this.warrantyUi.milesPerYear
    });
    this.vehicleNeedsForm.patchValue({
      plannedLengthOfOwnership: this.warrantyUi.ownershipYears,
      milesDrivenPerYear: this.warrantyUi.milesPerYear
    });
  }

  private syncInsuranceProducts() {
    combineLatest([
      this.vehicleService.selectInsuranceProducts(),
      this.dealService.dealInsuranceService.selectInsuranceProducts()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([insuranceProducts, selectedProducts]) => {
        this.vehicleInsuranceProducts = insuranceProducts;
        this.initWarrantyForm(insuranceProducts, selectedProducts);
      });

  }

  // listenForVehicleNeedsChanges() {
  //   this.actions$.pipe(
  //     takeUntil(this.unsubscribe$),
  //     ofType(dealActions.setVehicleNeeds),
  //   ).subscribe(() => {
  //     const selectedProducts = this.appData.insuranceProducts;
  //     this.initWarrantyForm(this.vehicleInsuranceProducts, selectedProducts);
  //   });
  // }

  initWarrantyForm(insuranceProducts, selectedProducts) {
    this.dealData.insuranceProducts = selectedProducts || [];
    this.insuranceProductsForm = this.formBuilder.group({});
    this.appData.insuranceProducts = [];
    this.warrantyUi.productGraphs.forEach(graph => {
      const {productKey} = graph.product;
      const product = insuranceProducts.find(item => item.productKey === productKey);
      const selectedProduct = this.selectedProducts.find(item => item.productKey === productKey);
      if (selectedProduct) {
        const {selectedTerm} = selectedProduct;
        const selectedTermCost = selectedProduct.termCosts[ selectedTerm ];
        const termCostIndex = product.termCosts.findIndex((termCost: TermCost) => {
          return selectedTermCost?.term === termCost?.term && selectedTermCost?.miles === termCost?.miles;
        });
        this.insuranceProductsForm.addControl(productKey, new FormControl(termCostIndex));
        this.previousProductValue.set(productKey, this.getProductLabel(product, termCostIndex))
        this.appData.insuranceProducts.push(product);
      }
    });
  }

  // FORM HELPERS

  autoSubmitVehicleNeedsForm(changedItem: string = '') {
    // if (this.vehicleNeedsForm.pristine) { return; }
    let {milesDrivenPerYear, plannedLengthOfOwnership} = this.vehicleNeedsForm.value;
    milesDrivenPerYear = parseInt(milesDrivenPerYear, 10);
    plannedLengthOfOwnership = parseInt(plannedLengthOfOwnership, 10);

    this.warrantyUiService.selectWarrantySettingOptions(
      +plannedLengthOfOwnership,
      +milesDrivenPerYear
    );

    this.warrantyUiService.updateGapTerm(plannedLengthOfOwnership * 12, this.selectedProducts);

    if (changedItem === 'plannedLengthOfOwnership') {
      this.historyService.dispatchAddEvent({
        shortName: "Planned Length of Ownership Changed",
        oldValue: this.getPlannedLengthOfOwnershipLabel(this.previousPlannedLengthOfOwnership),
        newValue: this.getPlannedLengthOfOwnershipLabel(plannedLengthOfOwnership)
      });
    } else if (changedItem === 'milesDrivenPerYear') {
      this.historyService.dispatchAddEvent({
        shortName: "Miles Driven per Year Changed",
        oldValue: this.getMilesDrivenPerYearLabel(this.previousMilesDrivenPerYear),
        newValue: this.getMilesDrivenPerYearLabel(milesDrivenPerYear)
      });
    }
    this.previousPlannedLengthOfOwnership = plannedLengthOfOwnership
    this.previousMilesDrivenPerYear = milesDrivenPerYear

    this.vehicleNeedsForm.markAsPristine();
    // this.dealService.dispatchChangeDeal();
    this.dealService.dispatchSetVehicleNeeds({plannedLengthOfOwnership, milesDrivenPerYear});
  }

  getPlannedLengthOfOwnershipLabel(value: any): string {
    if (!this.yearsOptions || !this.yearsOptions.length) return '';
    for (let i = 0; i < this.yearsOptions.length; i++) {
      if (this.yearsOptions[ i ].years == value) return this.yearsOptions[ i ].title;
    }
    return '';
  }

  getMilesDrivenPerYearLabel(value: any): string {
    if (!this.milesOptions || !this.milesOptions.length) return '';
    for (let i = 0; i < this.milesOptions.length; i++) {
      if (this.milesOptions[ i ].miles == value) return this.milesOptions[ i ].title;
    }
    return '';
  }

  getProductLabel(product: InsuranceProduct, termCostIndex: any): string {
    if (!product || !product.termCosts) return '';
    const option = product.termCosts[ termCostIndex ]
    return `${option.term / 12} Years / ${option.miles >= 999999 ? 'Unlimited' : (option.miles)} Miles`
  }

  autoSubmitInsuranceProductsForm(product: InsuranceProduct) {
    if (this.insuranceProductsForm.pristine) { return; }
    const {productKey} = product;
    const termCostIndex = this.insuranceProductsForm.value[ productKey ];

    //console.log("Product Changed", product)

    let productNameChanged = "";

    if (product.name.indexOf("Prepaid Maintenance") > -1) {
      productNameChanged = "PPM Changed"
    } else if (product.name.indexOf("Clear Care Elite") > -1) {
      productNameChanged = "CCE Changed"
    } else if (product.name.indexOf("Vehicle Service Agreement") > -1) {
      productNameChanged = "VSA Changed"
    }

    if (productNameChanged) {
      if (product?.termCosts[ termCostIndex ]) {
        const newValue = this.getProductLabel(product, termCostIndex);
        this.historyService.dispatchAddEvent({
          shortName: productNameChanged,
          oldValue: this.previousProductValue.get(productKey),
          newValue
        });
        this.previousProductValue.set(productKey, newValue)
      }
    }

    this.warrantyUiService.selectInsuranceProduct(product, +termCostIndex);
    this.insuranceProductsForm.markAsPristine();
    this.dealService.dispatchChangeDeal();
  }

  // UI CONTROL & RENDERING

  onToggleCalculator() {
    this.uiState.showCalculator = !this.uiState.showCalculator;
  }

  get showVehicleNeedsForm(): boolean {
    return this.warrantyUi.activeViewIndex > 0;
  }

  get showInsuranceProductsForm(): boolean {
    const {activeViewIndex, maxViewIndex} = this.warrantyUi;
    return activeViewIndex === maxViewIndex;
  }

  get showCalculator(): boolean {
    return this.uiState.showCalculator;
  }

  get milesOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.milesDrivenPerYear || [];
  }

  get yearsOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.plannedLengthOfOwnership || [];
  }

  get insuranceProducts(): InsuranceProduct[] {
    return this.appData.insuranceProducts || [];
  }

  get selectedProducts(): InsuranceProduct[] {
    return this.dealData.insuranceProducts || [];
  }

}

<section class="header-layout">
  <h1 class="h2 header-title">Excess Wear &amp; Use</h1>

  <form [formGroup]="ewuForm" class="header-form">
    <select
      class="header-form-control form-control"
      formControlName="termCostIndex">
      <option value="" disabled>Select A Plan</option>
      <option *ngFor="let option of termCosts; let i = index" [value]="i">
        {{ option.term / 12 }} Years / {{ option.miles || "Unlimited" }} Miles -
        {{ option.price | currency: "USD":"symbol":"1.0-0" }}
      </option>
    </select>
  </form>
</section>

<app-cna-list-dialog-header dialogName="Excess Wear & Use"></app-cna-list-dialog-header>

<ul class="nav nav-pills mb-3">
  <li *ngFor="let tab of viewTabs" class="nav-item">
    <a
      [routerLink]=""
      class="nav-link"
      [ngClass]="{ active: activeView(tab.id) }"
      (click)="onSelectView(tab.id)"
    >
      {{ tab.title }}
    </a>
  </li>
</ul>

<section class="text-cente" *ngIf="activeView('damages')">
  <img src="/static/images/insurance/ewu-samples.png" class="w-100 p-0"/>
</section>

<section *ngIf="activeView('coverage')">
  <img src="/static/images/insurance/ewu-coverage.png" class="w-100 p-0"/>
</section>

<section class="text-center" *ngIf="activeView('overview')">
  <img src="/static/images/insurance/ewu-overview.png" class="w-100 p-0"/>
</section>

<div class="btn-layout">
  <button
    class="btn"
    [ngClass]="planSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
    type="button"
    (click)="onAcceptPlan()">
    Yes: Include Coverage
  </button>
  <button
    class="btn"
    [ngClass]="noPlanSelected() ? 'app-btn-dark' : 'app-btn-outline-dark'"
    type="button"
    (click)="onDeclinePlan()">
    Decline
  </button>
</div>

import { RichLink } from "./links";

export interface Insurance {
  companyName: string;
  agentName: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  policyNumber: string;
  policyEffectiveDate: string;
  policyExpirationDate: string;
}

export interface InsuranceProduct {
  termCosts: TermCost[];
  isFallbackTermCosts:boolean;
  selectedTerm: number; // Position Index in TermCost[]
  link: RichLink;
  minYear: number;
  name: string;
  tagLine: string;
  provider: string;
  providerName: string;
  productKey: string;
  productTerms: string;
  guidelines: string;
  details: string;
  iconUrl: string;
  preLoadNew: boolean;
  preLoadUsed: boolean;
  preLoadCertUsed: boolean;
  preLoadCar: boolean;
  preLoadTruck: boolean;
  preLoadSuv: boolean;
  preLoadVan: boolean;
  preLoadOther: boolean;
  pbsName: string;
  pbsType: string;
}

export interface TermCost {
  priceOverride: number;
  term: number;
  miles: number;
  price: number;
  cost: number;
  markup: number;
  providerId: string;
}

export enum InsuranceProductKeys {
  GAP = "GAP",
  PPM = "MAINTENANCEPLAN",
  SELECT = "SELECT",
  VSA = "VSC",
  EWU = "LEASEWEARTEAR"
}

export enum LeaseInsuranceProductKeys {
  EWU = InsuranceProductKeys.EWU,
  GAP = InsuranceProductKeys.GAP,
  PPM = InsuranceProductKeys.PPM,
  SELECT = InsuranceProductKeys.SELECT
}

export enum FinanceInsuranceProductKeys {
  GAP = InsuranceProductKeys.GAP,
  PPM = InsuranceProductKeys.PPM,
  SELECT = InsuranceProductKeys.SELECT,
  VSA = InsuranceProductKeys.VSA
}

export enum CashInsuranceProductKeys {
  PPM = InsuranceProductKeys.PPM,
  SELECT = InsuranceProductKeys.SELECT,
  VSA = InsuranceProductKeys.VSA
}

export enum InsuranceProductKeysIconSrcMap {
  "LEASEWEARTEAR" = "/static/images/insurance/products/icons/EWU.png",
  "GAP" = "/static/images/insurance/products/icons/GAP.png",
  "MAINTENANCEPLAN" = "/static/images/insurance/products/icons/PPM.png",
  "SELECT" = "/static/images/insurance/products/icons/CCE.png",
  "VSC" = "/static/images/insurance/products/icons/VSA.png"
}

<p>
  <button type="button" class="btn btn-outline-primary" (click)="uiState.isCollapsed = !uiState.isCollapsed">
    {{accessory.name || 'Accessory Link'}}
  </button>
</p>
<div *ngIf="!uiState.isCollapsed">
  <div class="card">
    <div class="card-body">
      <button (click)="save()" class="btn btn-outline-success">SAVE</button>
      <!-- <a (click)="deleteItem()">
        <i class="material-icons text-danger h2 float-right">delete_sweep</i>
      </a> -->
      <div class="d-flex flex-row justify-content-between">
        <h3>{{accessory.name || 'untitled accessory'}}</h3>
      </div>
      <app-menu-link-form [menuItem]="accessory.accessoryLink" [hideButtons]="true"
                          (formChanges)="onFormChanges.accessoryLink($event)">
      </app-menu-link-form>
      <app-accessory-form [accessory]="accessory" (formChanges)="onFormChanges.accessoryForm($event)">
      </app-accessory-form>
    </div>
  </div>
</div>

<app-modal-view #modal="modal"
                modalTitle="Model Lookup">
  <section modal-body>

    <app-search-form [placeholder]="'Search'"></app-search-form>

    <section class="data-table-container">
      <table class="data-table mx-auto w-100">
        <tr>
          <th class="selectable"
              [ngClass]="{ 'selected': isActiveSort('modelNumber') }"
              (click)="onSelectSort('modelNumber')">
            <div class="d-flex align-items-center">
              Model Number
              <i *ngIf="isActiveSort('modelNumber')"
                 class="material-icons">
                {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
              </i>
            </div>
          </th>

          <th class="selectable"
              [ngClass]="{ 'selected': isActiveSort('make') }"
              (click)="onSelectSort('make')">
            <div class="d-flex align-items-center">
              Make
              <i *ngIf="isActiveSort('make')"
                 class="material-icons">
                {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
              </i>
            </div>
          </th>

          <th class="selectable"
              [ngClass]="{ 'selected': isActiveSort('model') }"
              (click)="onSelectSort('model')">
            <div class="d-flex align-items-center">
              Model
              <i *ngIf="isActiveSort('model')"
                 class="material-icons">
                {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
              </i>
            </div>
          </th>

          <th class="selectable"
              [ngClass]="{ 'selected': isActiveSort('trim') }"
              (click)="onSelectSort('trim')">
            <div class="d-flex align-items-center">
              Trim
              <i *ngIf="isActiveSort('trim')"
                 class="material-icons">
                {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
              </i>
            </div>
          </th>

          <th class="selectable"
              [ngClass]="{ 'selected': isActiveSort('engine') }"
              (click)="onSelectSort('engine')">
            <div class="d-flex align-items-center">
              Engine
              <i *ngIf="isActiveSort('engine')"
                 class="material-icons">
                {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
              </i>
            </div>
          </th>

          <th class="selectable"
              [ngClass]="{ 'selected': isActiveSort('type') }"
              (click)="onSelectSort('type')">
            <div class="d-flex align-items-center">
              Type
              <i *ngIf="isActiveSort('type')"
                 class="material-icons">
                {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
              </i>
            </div>
          </th>

          <th class="selectable"
              [ngClass]="{ 'selected': isActiveSort('transmission') }"
              (click)="onSelectSort('transmission')">
            <div class="d-flex align-items-center">
              Transmission
              <i *ngIf="isActiveSort('transmission')"
                 class="material-icons">
                {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
              </i>
            </div>
          </th>

        </tr>

        <tr *ngFor="let model of modelList"
            class="selectable"
            (click)="onSelect(model)">
          <td class="table-element">{{model.modelNumber}}</td>
          <td class="table-element">{{model.make}}</td>
          <td class="table-element">{{model.model}}</td>
          <td class="table-element">{{model.trim}}</td>
          <td class="table-element">{{model.engine}}</td>
          <td class="table-element">{{model.type}}</td>
          <td class="table-element">{{model.transmission}}</td>
        </tr>
      </table>
    </section>
  </section>

</app-modal-view>
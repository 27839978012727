import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "teamFilter"
})
export class TeamFilterPipe implements PipeTransform {
  transform(deals: any[], managerId: string): any[] {
    if (!deals) { return []; }
    if (!managerId) { return deals; }
    return deals.filter(deal => deal.managerId === managerId);
  }
}

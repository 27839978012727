import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../../../auth-module/services/auth.service";
import { SalesQueueService } from "../../services/sales-queue.service";
import { AlertService } from "../../../shared-module/services";
import { PubnubService } from "../../../shared-module/services/PubSub/pubnub.service";
import { User } from "src/app/user-admin-module/models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SalesCue, SalesQPerson } from '../../models/sales-queue';

@Component({
  selector: "app-sales-queue-admin",
  templateUrl: "./sales-queue-admin.component.html",
  styleUrls: ["./sales-queue-admin.component.scss"]
})
export class SalesQueueAdminComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  currentUser: User;
  salesCue: SalesCue;
  uiState = {
    isAdmin: true,
    controlsEnabled: true,
    waitOnUpdates: false
  };

  constructor(
    private authService: AuthService,
    private salesQueueService: SalesQueueService,
    private alertService: AlertService,
    private pubsub: PubnubService
  ) { }

  ngOnInit() {
    this.subToCurrentUser();
    this.watchSalesQueueRotation();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION


  subToCurrentUser() {
    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.currentUser = user;
      });
  }

  watchSalesQueueRotation() {
    this.getSalesQueue();
    this.pubsub.salesQueueChanged(() => {
      if (!this.waitOnUpdates()) {
        this.getSalesQueue();
      }
    });
  }

  getSalesQueue() {
    this.disableControls();
    this.blockUpdates();
    this.salesQueueService.read().subscribe({
      next: data => {
        this.salesCue = data;
        this.enableControls();
        this.allowUpdates();
      },
      error: error => {
        this.enableControls();
        this.allowUpdates();
        this.alertService.error(error);
      }
    });
  }

  // ACTIONS

  onUpdateSalesQueue(q: SalesCue) {
    this.disableControls();
    this.blockUpdates();
    this.salesQueueService.update(q).subscribe({
      next: data => {
        this.salesCue = data;
        this.enableControls();
        this.setUpdateTimer();
      },
      error: error => {
        this.enableControls();
        this.allowUpdates();
        this.refreshSalesQueue();
        this.alertService.error(error);
      }
    });
  }

  onRemoveFromSalesQueue(salesQPerson: SalesQPerson) {
    this.disableControls();
    this.blockUpdates();
    this.salesQueueService.remove(salesQPerson.userId).subscribe({
      next: data => {
        this.salesCue = data;
        this.enableControls();
        this.setUpdateTimer();
      },
      error: error => {
        this.enableControls();
        this.allowUpdates();
        this.refreshSalesQueue();
        this.alertService.error(error);
      }
    });
  }

  onMoveToBottomOfSalesQueue(salesQPerson: SalesQPerson) {
    this.disableControls();
    this.blockUpdates();
    const salesCueCopy = Object.assign({}, this.salesCue);
    const q = salesCueCopy.salesQueue;

    const index = q.findIndex(member => member.userId === salesQPerson.userId);
    if (index > -1) {
      salesCueCopy.salesQueue = [...q.slice(0, index), ...q.slice(index + 1), q[ index ]];
      this.onUpdateSalesQueue(salesCueCopy);
    } else {
      this.enableControls();
      this.allowUpdates();
    }
  }

  onAddCustomer(salesQPerson: SalesQPerson) {
    this.disableControls();
    this.blockUpdates();
    const salesCueCopy = Object.assign({}, this.salesCue);
    const q = salesCueCopy.salesQueue;

    const index = q.findIndex(member => member.userId === salesQPerson.userId);
    if (index > -1) {
      q[ index ].withCustomer = true;
      salesCueCopy.salesQueue = [...q.slice(0, index), ...q.slice(index + 1), q[ index ]];
      this.onUpdateSalesQueue(salesCueCopy);
    } else {
      this.enableControls();
      this.allowUpdates();
    }
  }

  onRemoveCustomer(salesQPerson: SalesQPerson) {
    this.disableControls();
    this.blockUpdates();
    const salesCueCopy = Object.assign({}, this.salesCue);
    const q = salesCueCopy.salesQueue;

    const index = q.findIndex(member => member.userId === salesQPerson.userId);
    if (index > -1) {
      q[ index ].withCustomer = false;
      this.onUpdateSalesQueue(salesCueCopy);
    } else {
      this.enableControls();
      this.allowUpdates();
    }
  }

  onReorderQueue({previousIndex, currentIndex}) {
    const q = this.salesCue.salesQueue;
    const salesQPerson = q[ previousIndex ];
    q.splice(previousIndex, 1);
    q.splice(currentIndex, 0, salesQPerson);
    this.onUpdateSalesQueue(this.salesCue);
  }

  onDragStart() {
    this.disableControls();
    this.blockUpdates();
  }

  onDragRelease(e) {
    const {previousIndex, currentIndex} = e;
    if (previousIndex !== currentIndex) {
      this.onReorderQueue({previousIndex, currentIndex});
    } else {
      this.enableControls();
      this.allowUpdates();
    }
  }

  onResetQueue() {
    const confirmed = window.confirm("Are you sure you want to reset the Sales Queue?");
    if (!confirmed) { return; }

    this.disableControls();
    this.blockUpdates();
    const salesCueCopy = Object.assign({}, this.salesCue);
    salesCueCopy.managerName = "";
    salesCueCopy.salesQueue = [];
    salesCueCopy.salestimer = 0;
    this.onUpdateSalesQueue(salesCueCopy);
  }

  // UI CONTROL & RENDERING

  enableControls() {
    this.uiState.controlsEnabled = true;
  }

  disableControls() {
    this.uiState.controlsEnabled = false;
  }

  allowUpdates() {
    this.uiState.waitOnUpdates = false;
  }

  blockUpdates() {
    this.uiState.waitOnUpdates = true;
  }

  waitOnUpdates(): boolean {
    return this.uiState.waitOnUpdates;
  }

  setUpdateTimer() {
    this.blockUpdates();
    setTimeout(() => {
      this.allowUpdates();
    }, 5000);
  }

  refreshSalesQueue() {
    // To initiate change detection in child components
    const salesCueCopy = Object.assign({}, this.salesCue);
    this.salesCue = salesCueCopy;
  }
}

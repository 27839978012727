import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { User } from "../../models";

const routes = {
  create: "/v1/user/create",
  read: userId => `/v1/user/read/${userId}`,
  getAllUsers: "/v1/user/list",
  update: userId => `/v1/user/update/${userId}`,
  delete: userId => `/v1/user/hardDelete/${userId}`,
  listSecGroups: "/v1/user/listsecgroups",
  getBySecurityGroup: "/v1/user/listBySecurityGroup",
  submitFeedback: "/v1/user/submitFeedback"
};

export interface Feedback {
  title: string;
  message: string;
  email: string;
}

@Injectable({providedIn: "root"})
export class UserService {
  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(`${routes.getAllUsers}`);
  }

  create(user: User): Observable<any> {
    return this.http.post<any>(`${routes.create}`, user);
  }

  update(user: User): Observable<any> {
    const updateRoute = `${routes.update(user.id)}`;
    return this.http.post<any>(updateRoute, user);
  }

  delete(user: User): Observable<any> {
    const deleteUserRoute = routes.delete(user.id);
    return this.http.delete<any>(deleteUserRoute);
  }

  read(user: User): Observable<any> {
    return this.http.get<any>(routes.read(user.id));
  }

  getSecurityGroups() {
    return this.http.get<any>(`${routes.listSecGroups}`);
  }

  getBySecurityGroup(securityGroups: Array<string>) {
    const route = routes.getBySecurityGroup;
    const body = {securityGroups};
    return this.http.post<any>(route, body);
  }

  submitFeedback(feedback: Feedback): Observable<any> {
    return this.http.post(routes.submitFeedback, feedback);
  }

  getSalesPeople() {
    const route = routes.getBySecurityGroup;
    const securityGroups = ["sales_floor"];
    const body = {securityGroups};
    return this.http.post<any>(route, body);
  }

  getSalesManagers() {
    const route = routes.getBySecurityGroup;
    const securityGroups = ["sales_manager"];
    const body = {securityGroups};
    return this.http.post<any>(route, body);
  }
}

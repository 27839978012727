import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { DealService } from "../../services";
import { Accessory } from "../../models";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-accessory-item",
  templateUrl: "./accessory-item.component.html",
  styleUrls: ["./accessory-item.component.scss"]
})
export class AccessoryItemComponent implements OnInit {
  @Input() accessory: Accessory;
  @Input() _selectedDealAccessory: Accessory;
  @Input() itemIndex: number;
  @Input() inCart = false;
  @ViewChild("imageArea", {static: true}) imageArea: ElementRef;
  halfXpelLabel = "Half Xpel - $1,495 (standard)"
  fullXpelLabel = "Full Xpel - $2,195 (ultimate)"
  public uiState = {
    isValidStockPhoto: true,
    imageHeight: null,
    activeView: "coverage",
    tabs: [
      {title: "What's Covered?", id: "coverage"},
      {title: "Video", id: "video"},
    ]
  };

  xpelForm: FormGroup = this.formBuilder.group({
    coverage: [this.selectedDealAccessory ? this.selectedDealAccessory.price : ""]
  });

  @Input() set selectedDealAccessory(acc: Accessory) {
    this._selectedDealAccessory = acc;
    if (this.xpelForm) {
      this.xpelForm.patchValue({
        coverage: [this._selectedDealAccessory ? this._selectedDealAccessory.price : ""]
      })
    }
  }

  constructor(private formBuilder: FormBuilder,
              private dealService: DealService) { }

  ngOnInit() {
    this.autoSetImageHeight();
  }

  // ACTIONS

  onSelectAccessory(accessory: Accessory) {
    this.dealService.submitAccessory(accessory);
  }

  // UI RENDERING

  invalidateImage() {
    this.uiState.isValidStockPhoto = false;
  }

  autoSetImageHeight() {
    const width = this.imageArea.nativeElement.offsetWidth;
    const widthHeightRatio = 1.33;
    this.uiState.imageHeight = Math.floor(width / widthHeightRatio);
  }

  get iconUrl(): string {
    return this.uiState.isValidStockPhoto ? this.accessory.accessoryLink.iconUrl : null;
  }

  get imageHeight(): number {
    return this.uiState.imageHeight;
  }

  onXpelCoverageChange($event) {
    this._selectedDealAccessory.price = Number.parseFloat($event.target.value)
    if (this.dealService) {
      this.dealService.dispatchAddEvent({
        shortName: 'Paint Protection Film Package Changed',
        oldValue: this._selectedDealAccessory.price === 1495 ? this.fullXpelLabel : this.halfXpelLabel,
        newValue: this._selectedDealAccessory.price !== 1495 ? this.fullXpelLabel : this.halfXpelLabel,
      })
    }
    this.dealService.dispatchCalcTax();
  }

}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService, DealService, VehicleService } from "../../../services";
import { FinancingSettings, Vehicle } from "../../../models";
import { DealState } from "../../../store/state";
import { DealIncentive, VehicleIncentive } from "src/app/settings-module/models/incentives";
import { DealIncentivesService } from "src/app/clearpath-module/services/deal/deal-incentives.service";
import { clone, pathOr } from "ramda";
import { HistoryService } from "../../../services/history.service";

@Component({
  selector: "app-vehicle-incentives",
  templateUrl: "./vehicle-incentives.component.html",
  styleUrls: ["./vehicle-incentives.component.scss"]
})
export class VehicleIncentivesComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject();
  vehicle: Vehicle;
  financingSettings: FinancingSettings;
  deal: DealState;
  getMatchingDealIncentive;
  vehicleUpdated = false;
  vehicleIncentives: VehicleIncentive[];

  constructor(
    private vehicleService: VehicleService,
    private dealService: DealService,
    private IncentiveService: DealIncentivesService,
    private appService: AppService,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    //this.subToFinancing()
    //this.subToVehicle();
    //this.subToDeal();
    this.historyService.dispatchAddEvent({
      shortName: "Factory Offers Window Opened"
    });
    combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.appService.selectFinancing(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, vehicle, financingSettings]: [DealState, Vehicle, FinancingSettings]) => {
        this.deal = deal;
        this.vehicle = vehicle;
        this.financingSettings = financingSettings;
      });
    this.getMatchingDealIncentive = this.IncentiveService.getMatchingDealIncentive;
    this.vehicleIncentives = clone(this.vehicle.incentives);
  }

  ngOnDestroy() {
    this.historyService.dispatchAddEvent({
      shortName: "Factory Offers Window Closed"
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.dealService.dispatchChangeDeal();
  }

  private subToFinancing() {
    this.appService.selectFinancing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((financingSettings: FinancingSettings) => {
        this.financingSettings = financingSettings;
        //console.log("financingSettings", financingSettings)
      });
  }

  private subToVehicle() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle) => {
        this.vehicle = vehicle;
      });
  }

  private subToDeal() {
    this.dealService.selectDeal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((deal) => {
        this.deal = deal;
      });
  }

  // ACTIONS

  onUpdateDealIncentive(updatedDealIncentive: DealIncentive) {
    const dealIncentives = this.dealIncentives ? this.dealIncentives.slice() : [];
    const subventionTiers = pathOr([], ["financeOffer", "aprSubventionTiers"], updatedDealIncentive);
    if (subventionTiers.length > 0) {
      this.dealService.dispatchSetFinanceOptions({incentiveSelected: true});
    }
    this.IncentiveService.onUpdateDealIncentive(updatedDealIncentive, dealIncentives);
  }

  // UI & RENDERING

  get dealIncentives(): DealIncentive[] {
    return this.deal.incentives;
  }

  incentiveIsEmpty(incentive: DealIncentive): boolean {
    return Object.keys(incentive).length <= 1;
  }
}

<section
  class="cp-accordion-header"
  [ngClass]="{ 'cp-accordion-header--open': showDetails }"
  (click)="onToggleDetails()"
>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <i class="material-icons app-bg-light rounded mr-1">{{
        showDetails ? "keyboard_arrow_up" : "keyboard_arrow_down"
        }}</i>
      <h2 class="h5 mb-0">Base Finance</h2>
    </div>
    <span class="h5 mb-0">{{
      vehicleBaseFinanceAmount | currency: "USD":"symbol":"1.0-0"
      }}</span>
  </div>
</section>

<section *ngIf="showDetails" class="cp-accordion-content p-4">
  <div class="d-flex justify-content-between mb-3">
    <span class="app-text-neutral">Customized Vehicle Price</span>
    <span>{{
      calcCustomizedVehiclePrice() | currency: "USD":"symbol":"1.0-0"
      }}</span>
  </div>

  <div class="d-flex justify-content-between mb-3">
    <span class="app-text-neutral">Trade Equity</span>
    <span>{{
      calcTradeEquityEstimate() | currency: "USD":"symbol":"1.0-0"
      }}</span>
  </div>

  <div class="d-flex justify-content-between mb-3">
    <span class="app-text-neutral">Down Payment</span>
    <span>{{
      financeOptions.downPayment | currency: "USD":"symbol":"1.0-0"
      }}</span>
  </div>

  <div
    *ngIf="acquisitionFee && leaseOptions.leaseSelected"
    class="d-flex justify-content-between mb-3"
  >
    <span class="app-text-neutral">Acquisition Fee</span>
    <span>{{ acquisitionFee | currency: "USD":"symbol":"1.2-2" }}</span>
  </div>

  <hr/>

  <!-- DMV -->

  <section (click)="onToggleDMVDetails()">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <i class="material-icons app-bg-light rounded mr-1">{{
          showDMVDetails ? "keyboard_arrow_up" : "keyboard_arrow_down"
          }}</i>
        <span class="app-text-neutral">DMV &amp; Document Fees Total</span>
      </div>
      <span class="h5">{{
        (calcFees$ | async)?.totalFees | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </div>
  </section>

  <section *ngIf="showDMVDetails">
    <div class="d-flex justify-content-between mb-3">
      <span class="app-text-neutral"
      ><small>Title & Registration Processing Fee</small></span
      >
      <span
      ><small>{{
        (calcFees$ | async)?.docFees | currency: "USD":"symbol":"1.2-2"
        }}</small></span
      >
    </div>

    <div class="d-flex justify-content-between mb-3">
      <span class="app-text-neutral"><small>Electronic Filing Fee</small></span>
      <span
      ><small>{{
        (calcFees$ | async)?.eFilingFee | currency: "USD":"symbol":"1.2-2"
        }}</small></span
      >
    </div>

    <div
      *ngIf="financeOptions.regFee"
      class="d-flex justify-content-between mb-3"
    >
      <span class="app-text-neutral"><small>Registration Fee</small></span>
      <span
      ><small>{{
        (calcFees$ | async)?.regFee | currency: "USD":"symbol":"1.2-2"
        }}</small></span
      >
    </div>

    <div class="d-flex justify-content-between mb-3">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          [checked]="financeOptions.plateTransfer"
          (click)="onTogglePlateTransfer()"
        />
        <label class="app-text-neutral">
          <small>
            Plate Transfer ({{
            PLATE_TRANSFER_FEE | currency: "USD":"symbol":"1.2-2"
            }})
          </small>
        </label>
      </div>
    </div>

    <div
      *ngIf="financeOptions.countyFee"
      class="d-flex justify-content-between mb-3"
    >
      <span class="app-text-neutral"><small>County Fee</small></span>
      <span
      ><small>{{
        (calcFees$ | async)?.countyFee | currency: "USD":"symbol":"1.2-2"
        }}</small></span
      >
    </div>

    <div
      *ngIf="financeOptions.titleFee"
      class="d-flex justify-content-between mb-3"
    >
      <span class="app-text-neutral"><small>Title Fee</small></span>
      <span
      ><small>{{
        (calcFees$ | async)?.titleFee | currency: "USD":"symbol":"1.2-2"
        }}</small></span
      >
    </div>


    <div
      *ngIf="financeOptions.totalStateFees"
      class="d-flex justify-content-between mb-3"
    >
      <span class="app-text-neutral"><small>Total State Fees</small></span>
      <span
      ><small>{{
        (calcFees$ | async)?.totalStateFees | currency: "USD":"symbol":"1.2-2"
        }}</small></span
      >
    </div>
  </section>

  <!-- <form [formGroup]="docFeesForm"
        class="row mb-3">
    <label class="col-sm-6 col-form-label app-text-neutral"
           [ngClass]="{ 'app-text-danger': touchedInvalid('docFees') }">
      DMV &amp; Document Fees
    </label>
    <div class="col-sm-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input class="form-control text-right"
               formControlName="docFees"
               type="number"
               min="0"
               (blur)="autoSubmitDocFeesForm()"
               required>
      </div>
    </div>
  </form> -->

  <hr/>

  <!-- TAXES -->

  <ng-container *ngIf="financeOptions">
    <section (click)="onToggleTaxDetails()">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <i class="material-icons app-bg-light rounded mr-1">{{
            showTaxDetails ? "keyboard_arrow_up" : "keyboard_arrow_down"
            }}</i>
          <span class="app-text-neutral mb-0">Taxes</span>
        </div>
        <span class="h5">{{
          taxResult.totalTax | currency: "USD":"symbol":"1.2-2"
          }}</span>
      </div>
    </section>

    <section *ngIf="showTaxDetails">
      <div
        *ngIf="financeOptions.salesTax"
        class="d-flex justify-content-between mb-3"
      >
        <span class="app-text-neutral"><small>Sales Tax</small></span>
        <span
        ><small>{{
          taxResult.salesTax | currency: "USD":"symbol":"1.2-2"
          }}</small></span
        >
      </div>

      <div class="d-flex justify-content-between mb-3">
        <span class="app-text-neutral"><small>Privilege Tax</small></span>
        <span
        ><small>{{
          taxResult.privilegeTax | currency: "USD":"symbol":"1.2-2"
          }}</small></span
        >
      </div>

      <div class="d-flex justify-content-between mb-3">
        <span class="app-text-neutral"
        ><small>Corporate Activity Tax Recovery</small></span
        >
        <span
        ><small>{{
          taxResult.catTax | currency: "USD":"symbol":"1.2-2"
          }}</small></span
        >
      </div>
    </section>
  </ng-container>

  <hr/>

  <div class="d-flex justify-content-between">
    <strong>Base Amount Financed</strong>
    <span>{{
      vehicleBaseFinanceAmount | currency: "USD":"symbol":"1.2-2"
      }}</span>
  </div>
</section>

export interface ConfigFinance {
  newRates: FinanceRate[];
  usedRates: FinanceRate[];
  certUsedRates: FinanceRate[];
  financeDefault: FinanceDefault;
  leaseDefault: LeaseDefault;
}

export interface FinanceRate {
  minYear: number;
  term: number;
  tieredRates: number[];
}

export class DefaultFinanceRate implements FinanceRate {
  constructor(numTieredRates: number) {
    this.tieredRates = Array.from({length: numTieredRates}, () => null);
  }

  minYear = new Date().getFullYear();
  term = 0;
  tieredRates: number[];
}

export interface FinanceDefault {
  newTermsShown: number[];
  usedTermsShown: number[];
  certTermsShown: number[];
  customTermDefault: number;
  taxRate: number;
  daysToFirstPay: number;
  dmvFees: number;
  gapDepreciation: number;
  gapAmortFactor: number;
}

export interface LeaseDefault {
  newTerms: number[];
  certUsedTerms: number[];
  newTermsShown: number[];
  certUsedTermsShown: number[];
  newMarkup: number;
  certUsedMarkup: number;
  moneyFactor: number;
  acquisitionFee: number;
  prepaidMileage: number;
  onePayDiscount: number;
  onePayDiscFloor: number;
  taxRate: number;
  excessMileageRate: number;
}


import { Insurance } from "../../../models";

export const initialInsuranceState: Insurance = {
  companyName: "",
  agentName: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  policyNumber: "",
  policyEffectiveDate: new Date(0).toISOString(),
  policyExpirationDate: new Date(0).toISOString()
};

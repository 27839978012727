import { AuthState, initialAuthState } from "../state/auth";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "../actions/auth.actions";


const reducer = createReducer(
  initialAuthState,
  on(AuthActions.loginWithToken, (state, {token}) => ({...state, token})),
  on(AuthActions.loginSuccess, (state, {token, user}) => ({...state, token, user})),
  on(AuthActions.loginWithTokenSuccess, (state, {token, user}) => ({...state, token, user})),
  on(AuthActions.refreshTokenSuccess, (state, {token}) => ({...state, token})),
  // // clear the state completely
  on(AuthActions.clearUser, state => ({...state, user: null})),
  on(AuthActions.logoutSuccess, () => initialAuthState),
  on(AuthActions.logoutFailure, () => initialAuthState)
);

export function authStateReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}

export const rootReducer = authStateReducer;

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { DealService, VehicleService } from "src/app/clearpath-module/services";
import { Accessory, Vehicle } from "src/app/clearpath-module/models";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { NavigationExtras, Router } from "@angular/router";
import { DealState, DealStatus } from "src/app/clearpath-module/store/state";
import { VehicleInfoForm } from "src/app/sales-manager-module/models/forms";
import * as dealActions from "../../../../clearpath-module/store/actions/deal.actions";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../../clearpath-module/store/state";

@Component({
  selector: "app-vehicle-info-form",
  templateUrl: "./vehicle-info-form.component.html",
  styleUrls: ["./vehicle-info-form.component.scss"]
})
export class VehicleInfoFormComponent implements OnInit, OnChanges {

  constructor(
    private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private dealService: DealService,
    private router: Router,
    private store: Store<fromRoot.DealState>,
  ) { }

  get condition() {
    return this.vehicleService.vehicleCondition(this.vehicle).toUpperCase();
  }

  @Input() vehicle: Vehicle;
  @Input() accessories: Accessory[];
  @Input() deal: DealState;
  @Input() dealStatus: string;
  @Input() vehicles: Vehicle[];
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();
  @Output() updateVehicleForm = new EventEmitter<any>();


  public REQS = FORM_INPUT_REQS;
  vehicleForm: FormGroup = this.formBuilder.group({
    stockNumber: [{value: "", disabled: false}],
    modelNumber: [{value: "", disabled: false}],
    year: [{value: "", disabled: false}],
    make: [{value: "", disabled: false}],
    model: [{value: "", disabled: false}],
    trim: [{value: "", disabled: false}],
    plateNumber: [{value: "", disabled: false}],
    plateExpires: [{value: "", disabled: false}],
    odometer: [{value: 0, disabled: false}],
    bodyStyle: [{value: "", disabled: false}],
    vin: [{value: "", disabled: false}],
    lot: [{value: "", disabled: false}],
    condition: [{value: "", disabled: false}, this.conditionValidator],
    isHybrid: [{value: false, disabled: false}],
    mpg: [{value: 0, disabled: false}]
  });

  // INITIALIZATION

  ngOnInit() {
    this.syncFormData();
  }

  ngOnChanges() {
    if (this.vehicle.stockNumber !== this.vehicleForm.value.stockNumber) {
      this.syncFormData();
      this.update();
    } else {
      this.syncFormData();
    }
  }

  private syncFormData() {
    this.vehicleForm.patchValue({
      stockNumber: this.vehicle.stockNumber,
      modelNumber: this.vehicle.modelNumber,
      year: this.vehicle.year,
      model: this.vehicle.model,
      make: this.vehicle.make,
      trim: this.vehicle.trim,
      plateNumber: this.deal.plateNumber,
      plateExpires: this.deal.plateExpires,
      odometer: this.vehicle.odometer,
      bodyStyle: this.vehicle.bodyStyle,
      vin: this.vehicle.vin,
      lot: this.vehicle.lot,
      condition: this.condition,
      isHybrid: this.isHybrid,
      mpg: this.vehicle.mpg
    });
  }

  get isHybrid() {
    return this.vehicleService.isHybrid(this.vehicle) ? "TRUE" : "FALSE";
  }

  conditionValidator(control: AbstractControl) {
    if (!["NEW", "CERTIFIED", "USED"].includes(control.value)) {
      return {
        conditionValidator: {
          valid: false
        }
      };
    }
  }

  autoSubmitForm = () => {
    if (this.vehicleForm) {
      const {pristine, invalid} = this.vehicleForm;
      if (pristine || invalid) { return; }
      this.update();
    }
  }

  autoSubmitStockNumber = () => {
    if (this.vehicleForm) {
      if (this.vehicle.stockNumber !== this.vehicleForm.value.stockNumber) {
        const foundVehicle = this.vehicles.find((vehicle: Vehicle) => {
          return vehicle.stockNumber === this.vehicleForm.value.stockNumber;
        });
        if (foundVehicle) {
          this.vehicleService.dispatchSetVehicle(foundVehicle);

          // set all dealer accessories for the found vehicle to the deal by default
          const selectedDealerAccessories = [];
          if (foundVehicle?.customFields?.length > 0) {
            foundVehicle?.customFields.forEach(field => {
              if (field.key.startsWith("DealerAccDesc")) {
                selectedDealerAccessories.push(field.value);
              }
            });
          }

          this.store.dispatch(dealActions.updateSelectedDealerAccessories(selectedDealerAccessories));

        } else {
          alert("Stock Number not found");
          this.vehicleForm.patchValue({stockNumber: this.vehicle.stockNumber});
        }
      }
    }
  }

  update() {
    if (this.vehicleForm.pristine) { return; }
    const vehicleInfoForm: VehicleInfoForm = this.vehicleForm.value;
    vehicleInfoForm.mpg = parseFloat(vehicleInfoForm.mpg.toString());
    vehicleInfoForm.odometer = parseFloat(vehicleInfoForm.odometer.toString());
    this.dealService.setDealOdometer(vehicleInfoForm.odometer);
    this.dealService.dispatchSetPlate({
      plateExpires: vehicleInfoForm.plateExpires,
      plateNumber: vehicleInfoForm.plateNumber
    });
    // this.vehicleService.dispatchSetVehicle(vehicleInfoForm);
    this.vehicleService.dispatchUpdateVehicle({...this.vehicle, ...vehicleInfoForm});
    this.vehicleForm.markAsUntouched();
    this.vehicleForm.markAsPristine();
    this.flagUnsavedChanges.emit(true);
  }

  // UI CONTROL

  onSelectInventory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {changeDealOnSelectVehicle: false},
    };
    this.router.navigate([{
      outlets: {
        modal: ["vehicle-lookup"]
      },
    }], navigationExtras);
  }

  onSelectModelNumberLookup() {
    this.router.navigate([{
      outlets: {
        modal: ["model-lookup"]
      },
    }]);
  }

  completedDeal(): boolean {
    return this.dealStatus === DealStatus.Completed;
  }

  bodyStyleChanged(e) {
    //console.log(e)
    this.vehicle.bodyStyle=e
  }

}

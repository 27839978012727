<app-sales-manager-writeup-controls
  [dealStatus]="dealStatus"
  [waitOnAction]="waitOnAction"
  [unsavedChanges]="unsavedChanges"
  (taxLookup)="taxLookup()"
  (updateDeal)="updateDeal()"
  (approveDeal)="approveDeal()"
  (printDeal)="printDeal()"
  (completeDeal)="completeDeal()"
  (declineDeal)="declineDeal()"
  (saveDealChanges)="saveDealChanges()"
  (criticalMemo)="criticalMemo()"
  (printDisclosure)="printDisclosure()"
  (cancelDealChanges)="cancelDealChanges()">
</app-sales-manager-writeup-controls>

<div class="d-flex flex-row overflow-control formatting">

  <!-- COLUMN 1 -->
  <div class="border px-1 auto-fit-layout format white-panel">

    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 column-header">Contract</div>

    <app-simple-input
      [fieldValue]="deal.contractDate"
      [parentForm]="salesManagerMasterForm"
      [blur]="autoSubmitForm"
      formCtrlName="contractDate"
      name="Contract Date"
      type="date"
      (change)="flagUnsavedChanges(true)">
    </app-simple-input>

    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header">Buyer</div>
    <app-buyer-form
      *ngIf="dealDefaults"
      [buyer]="deal.customer"
      [dealDefaults]="dealDefaults"
      [financeOptions]="deal.financeOptions"
      [vehicleNeeds]="deal.vehicleNeeds"
      [buyerIsCustomer]="true"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-buyer-form>

    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header-with-button" (click)="hideCoBuyer = !hideCoBuyer">
      <span>Co-Buyer</span>
      <button class="box-header-icon-button" (click)="deal.coSigner=deepCopy(copyAddress($event,deal.customer,deal.coSigner));hideCoBuyer=false">
        <i class="material-icons app-text-white app-bg-blue">assignment</i>
      </button>
      <a>
        <i class="material-icons app-text-white app-bg-blue">{{hideCoBuyer ? 'add' : 'remove'}}</i>
      </a>
    </div>
    <app-buyer-form
      *ngIf="!hideCoBuyer && dealDefaults"
      [buyer]="deal.coSigner"
      [dealDefaults]="dealDefaults"
      [financeOptions]="deal.financeOptions"
      [vehicleNeeds]="deal.vehicleNeeds"
      [buyerIsCoSigner]="true"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-buyer-form>

    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header-with-button" (click)="hideTradeInOwner = !hideTradeInOwner">
      <span>Trade-In Owner</span>
      <button class="box-header-icon-button" (click)="deal.tradeInOwner=deepCopy(copyProfile($event,deal.customer,deal.tradeInOwner));hideTradeInOwner=false">
        <i class="material-icons app-text-white app-bg-blue">assignment</i>
      </button>
      <a>
        <i class="material-icons app-text-white app-bg-blue">{{hideTradeInOwner ? 'add' : 'remove'}}</i>
      </a>
    </div>
    <app-trade-owner
      *ngIf="!hideTradeInOwner && dealDefaults"
      [tradeInOwner]="deal.tradeInOwner"
      (submitSalesManagerTradeInOwner)="submitSalesManagerTradeInOwner($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-trade-owner>

    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header-with-button" (click)="hideTradeInOwner2 = !hideTradeInOwner2">
      <span>Trade-In Owner•Second</span>
      <button class="box-header-icon-button" (click)="deal.tradeInOwner2=deepCopy(copyProfile($event,deal.coSigner,deal.tradeInOwner2));hideTradeInOwner2=false">
        <i class="material-icons app-text-white app-bg-blue">assignment</i>
      </button>
      <a>
        <i class="material-icons app-text-white app-bg-blue">{{hideTradeInOwner2 ? 'add' : 'remove'}}</i>
      </a>
    </div>
    <app-trade-owner
      *ngIf="!hideTradeInOwner2 && dealDefaults"
      [tradeInOwner]="deal.tradeInOwner2"
      (submitSalesManagerTradeInOwner)="submitSalesManagerTradeInOwner2($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-trade-owner>

    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header-with-button" (click)="hideTradeIn2Owner = !hideTradeIn2Owner">
      <span>Trade-In 2 Owner</span>
      <button class="box-header-icon-button" (click)="deal.tradeIn2Owner=deepCopy(copyProfile($event,deal.customer,deal.tradeIn2Owner));hideTradeIn2Owner=false">
        <i class="material-icons app-text-white app-bg-blue">assignment</i>
      </button>
      <a>
        <i class="material-icons app-text-white app-bg-blue">{{hideTradeIn2Owner ? 'add' : 'remove'}}</i>
      </a>
    </div>
    <app-trade-owner
      *ngIf="!hideTradeIn2Owner && dealDefaults"
      [tradeInOwner]="deal.tradeIn2Owner"
      (submitSalesManagerTradeInOwner)="submitSalesManagerTradeIn2Owner($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-trade-owner>

    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header-with-button" (click)="hideTradeIn2Owner2 = !hideTradeIn2Owner2">
      <span>Trade-In 2 Owner•Second</span>
      <button class="box-header-icon-button" (click)="deal.tradeIn2Owner2=deepCopy(copyProfile($event,deal.coSigner,deal.tradeIn2Owner2));hideTradeIn2Owner2=false">
        <i class="material-icons app-text-white app-bg-blue">assignment</i>
      </button>
      <a>
        <i class="material-icons app-text-white app-bg-blue">{{hideTradeIn2Owner2 ? 'add' : 'remove'}}</i>
      </a>
    </div>
    <app-trade-owner
      *ngIf="!hideTradeIn2Owner2 && dealDefaults"
      [tradeInOwner]="deal.tradeIn2Owner2"
      (submitSalesManagerTradeInOwner)="submitSalesManagerTradeIn2Owner2($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-trade-owner>

    <hr class="standard-section-breaker">

    <!-- Sales mgr and sales person select -->
    <div class="section-header">Staff</div>
    <app-staff-form
      [deal]="deal"
      (submitSalesPerson)="updateSalesPerson($event)"
      (submitSalesManager)="updateSalesManager($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-staff-form>
  </div>

  <!-- COLUMN 2 -->
  <div class="border px-1 auto-fit-layout columnTwo white-panel">
    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 column-header">Vehicle</div>
    <app-vehicle-info-form
      [vehicle]="vehicle"
      [vehicles]="vehicles"
      [deal]="deal"
      [accessories]="deal.accessories"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-vehicle-info-form>
    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header-with-button" style="margin-bottom: 6px!important;" (click)="hideTradeIn = !hideTradeIn">
      <span>Trade-In</span>
      <a>
        <i class="material-icons app-text-white app-bg-blue">{{hideTradeIn ? 'add' : 'remove'}}</i>
      </a>
    </div>
    <app-trade-info
      *ngIf="!hideTradeIn && dealDefaults"
      [tradeIn]="deal.tradeIn"
      (submitSalesManagerTradeIn)="submitSalesManagerTradeIn($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-trade-info>
    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between section-header-with-button" style="margin-bottom: 6px!important;" (click)="hideTradeIn2 = !hideTradeIn2">
      <span>Trade-In 2</span>
      <a>
        <i class="material-icons app-text-white app-bg-blue">{{hideTradeIn2 ? 'add' : 'remove'}}</i>
      </a>
    </div>
    <app-trade-info
      *ngIf="!hideTradeIn2 && dealDefaults"
      [tradeIn]="deal.tradeIn2"
      (submitSalesManagerTradeIn)="submitSalesManagerTradeIn2($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-trade-info>

    <hr class="standard-section-breaker">

    <app-insurance-info (flagUnsavedChanges)="flagUnsavedChanges($event)"></app-insurance-info>
  </div>

  <!-- COLUMN 3 -->
  <div class="px-1 auto-fit-layout border white-panel">
    <div class="app-bg-gray px-1 app-text-bold my-1 py-1 d-flex justify-content-between column-header">
      <span>Pricing</span>
      <span>{{vehicleRetail | currency: "USD":"symbol":"1.0-0"}}</span>
    </div>
    <app-vehicle-price-form
      [customerZip]="deal.customer.zip"
      [baseVehiclePrice]="baseVehiclePrice"
      [customizedVehiclePrice]="customizedVehiclePrice"
      [selectedCashIncentivesTotal]="selectedCashIncentivesTotal"
      [deal]="deal"
      [accessories]="accessories"
      [dealStatus]="dealStatus"
      [dealDefaults]="dealDefaults"
      [incentives]="selectedIncentives"
      (submitAccessories)="updateAccessories($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)">
    </app-vehicle-price-form>

    <app-simple-input [fieldValue]="deal.financeOptions.downPayment"
                      [parentForm]="salesManagerMasterForm"
                      [blur]="autoSubmitForm"
                      formCtrlName="downPayment"
                      name="Down Payment"
                      type="number"
                      pipe="currency">
    </app-simple-input>

    <div class="app-bg-lt-gray px-1 my-1 py-1 d-flex justify-content-between">
      <span>Trade Net</span>
      <span>{{ tradeInNetValue$ | async | currency }}</span>
    </div>

  </div>

  <!-- COLUMN 4 -->
  <div class="px-1 auto-fit-layout white-panel big-border"
       [ngClass]="{'big-border-selected':deal.financeOptions.financeSelected}">
    <app-finance-form
      [financeOptions]="deal.financeOptions"
      [financingSettings]="financingSettings"
      [vehicleCondition]="vehicleCondition"
      [tradeIn]="tradeIn"
      [vehicle]="vehicle"
      [lienHolder]="deal.lienHolder"
      [vehicleIncentives]="vehicle.incentives || []"
      [dealIncentives]="deal.incentives || []"
      [dealDefaults]="dealDefaults"
      [dealStatus]="dealStatus"
      (submitDealType)="updateDealType($event)"
      (submitFinanceOptions)="updateFinanceOptions($event)"
      (submitIncentives)="updateIncentives($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)"
      ngClass="{{deal.financeOptions.financeSelected?'primary-section-header-selected':'primary-section-header'}}">
    </app-finance-form>
  </div>

  <!-- COLUMN 5 -->
  <div class="px-1 auto-fit-layout white-panel big-border"
       [ngClass]="{'big-border-selected':deal.leaseOptions.leaseSelected}">
    <app-lease-form
      [leaseOptions]="leaseOptions"
      [leaseDefault]="leaseDefault"
      [dealDefaults]="dealDefaults"
      [vehicleNeeds]="deal.vehicleNeeds"
      [leaseResiduals]="leaseResiduals"
      [tradeIn]="tradeIn"
      [vehicleCondition]="vehicleCondition"
      [dealIncentives]="deal.incentives || []"
      [vehicleIncentives]="vehicle.incentives || []"
      [dealStatus]="dealStatus"
      (submitIncentives)="updateIncentives($event)"
      [selectedCreditTier]="selectedCreditTier"
      (submitDealType)="updateDealType($event)"
      (submitLeaseOptions)="updateLeaseOptions($event)"
      (submitLeasingTerm)="updateLeasingTerm($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)"
      ngClass="{{deal.leaseOptions.leaseSelected?'primary-section-header-selected':'primary-section-header'}}">
    </app-lease-form>
  </div>

  <!-- COLUMN 6 -->
  <div class="px-1 auto-fit-layout white-panel big-border"
       [ngClass]="{'big-border-selected':deal.financeOptions.cashPurchase}">
    <app-cash-form
      [financeOptions]="deal.financeOptions"
      [tradeIn]="tradeIn"
      [dealIncentives]="deal.incentives || []"
      [dealDefaults]="dealDefaults"
      [vehicleIncentives]="vehicle.incentives || []"
      [dealStatus]="dealStatus"
      (submitDealType)="updateDealType($event)"
      (submitIncentives)="updateIncentives($event)"
      (flagUnsavedChanges)="flagUnsavedChanges($event)"
      ngClass="{{deal.financeOptions.cashPurchase?'primary-section-header-selected':'primary-section-header'}}">
    </app-cash-form>
  </div>
</div>

<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#memo-modal">Open Modal</button>

<!-- Memo Modal -->
<div id="memo-modal" class="modal fade memo-modal">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <button
        class="close"
        style="position: absolute; top: 1rem; right: 1.5rem"
        type="button"
        data-dismiss="modal"
        #closeModal>
        <span>&times;</span>
      </button>
      <h3>Critical Memo</h3>
      <hr>
      <p>Once a deal has been approved, the critical memo field for the vehicle should be updated. Please review the memo for accuracy, then submit to PBS using the button below. After submission, check the vehicle's memo field.</p>
      <textarea style="width:100%;height:160px;padding: 5px;font-family: 'Courier New',serif;font-weight:bold">{{this.additionalCriticalMemoText}}</textarea>
      <button (click)="updateCriticalMemo()" style="margin-top:10px;color:#fff;background:#3b6c17;font-weight:bold;border:none;border-radius: 4px;padding:8px">Send to PBS</button>
    </div>
  </div>
</div>

<img src="/assets/images/tfs-advance-as-of-january-2024.jpg" style="width:1px;height: 1px;position: absolute;opacity: 0;overflow: hidden;clip: rect(1px, 1px, 1px, 1px);"/>

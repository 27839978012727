import { Injectable } from "@angular/core";
import { formatCurrency } from "@angular/common";
import { InsuranceProduct } from "../../models";
import { CashInsuranceProductKeys, FinanceInsuranceProductKeys, LeaseInsuranceProductKeys } from "../../models/insurance";
import { FinanceRate } from "src/app/settings-module/models";
import Big from "big.js";

@Injectable({
  providedIn: "root"
})
export class CalculationUtilityService {

  constructor() { }

  /* SHARED SERVICES
  (services shared between calculation service and lease or other calculation services) */

  calculateMonthlyPayment(
    principal: number,
    loanTerm: number,
    interestRate: number,
    isMoneyFactor?: boolean
  ): number {
    if (!interestRate) {
      return principal / loanTerm;
    } else {
      if (isMoneyFactor) {
        const interestRatePercentage = Big(interestRate).times(2400);
        interestRate = interestRatePercentage.div(100).toNumber();
      }
      const monthlyInterestRate = Big(interestRate).div(12).toNumber();
      const tau = Big(monthlyInterestRate).add("1").toNumber();
      const tauToTheN = Math.pow(tau, loanTerm);
      const magicNumber = tauToTheN * monthlyInterestRate / (tauToTheN - 1);
      const result = Big(principal).times(magicNumber);
      return Big(result).round(2).toNumber();
    }
  }

  filterRates(vehicleYear, rates) {
    vehicleYear = parseInt(vehicleYear, 10);
    const filteredRates = rates.filter((rate: FinanceRate) => {
      return rate.minYear <= vehicleYear;
    });
    const sortedFilteredRates = filteredRates.sort((a, b) => b.minYear - a.minYear);
    const final = [];
    sortedFilteredRates.forEach((rate: FinanceRate) => {
      const found = final.find(r => r.term === rate.term);
      if (!found) {
        // if term doesnt exists, then push
        final.push(rate);
      }
    });
    const sortedFinal = final.sort((a, b) => a.term - b.term);
    return sortedFinal;
  }

  // HELPERS

  truncateDecimal(value: number, decimalPlaces: number = 0,useCeil=false): number {
    const decimalShift = Math.pow(10, decimalPlaces);
    return useCeil ? (Math.ceil(value * decimalShift) / decimalShift) : (Math.round(value * decimalShift) / decimalShift);
  }

  convertDecimalToPercentage(decimal: number): number {
    return Math.round(decimal * 10000) / 100;
  }

  convertPercentageToDecimal(percentage: number): number {
    return percentage / 100;
  }

  convertMoneyFactorToInterestRate(moneyFactor: number): number {
    return this.truncateDecimal(moneyFactor * 240, 3);
  }

  formatCurrencyUS(val: number) {
    return formatCurrency(val, "en-US", "$", "USD");
  }

  filterProductsByType(allProducts: InsuranceProduct[], type: string): InsuranceProduct[] {
    if (!allProducts) {
      return [];
    }
    if (type === "cash") {
      return allProducts.filter(product => {
        const isLeaseProductKey = Object.values(CashInsuranceProductKeys)
          .includes(product.productKey);
        return isLeaseProductKey;
      });
    }
    if (type === "lease") {
      return allProducts.filter(product => {
        const isLeaseProductKey = Object.values(LeaseInsuranceProductKeys)
          .includes(product.productKey);
        return isLeaseProductKey;
      });
    } else {
      return allProducts.filter(product => {
        const isFinanceProductKey = Object.values(FinanceInsuranceProductKeys)
          .includes(product.productKey);
        return isFinanceProductKey;
      });
    }
  }


}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanDeactivateGuard } from "../shared-module/services";
import { AuthGuard } from "../shared-module/helpers";
import { USER_ROLES } from "../app.config";
import { ClearpathComponent, NewDealComponent, SalesPersonWriteupsListComponent } from "./pages";
import { FuelSavingsModalComponent, MilesPerYearModalComponent, RollPaymentModalComponent, SimplePaymentModalComponent, StockNumberModalComponent, SubmitModalComponent, VehicleIncentivesComponent, VehicleLookupComponent, WarrantyUiModalComponent } from "./components";
import { FeedbackComponent } from "../shared-module/components/feedback/feedback.component";
import { ModelLookupComponent } from "./components/modals/model-lookup/model-lookup.component";
import { ActivityEventsComponent } from "../shared-module/components/activity-events/activity-events.component";
import { CreditCheckComponent } from "./pages/credit-check/credit-check.component";
import { FinanceVsCashModalComponent } from "./components/modals/finance-vs-cash-modal/finance-vs-cash-modal/finance-vs-cash-modal.component";
import { TaxLookupModalComponent } from "./components/modals/tax-lookup-modal/tax-lookup-modal.component";

const appRoutes: Routes = [
  {
    path: "clearpath",
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [
        USER_ROLES.sales_floor,
        USER_ROLES.cue_admin,
        USER_ROLES.sales_manager]
    },
    children: [
      {
        path: "deal/:dealId",
        component: ClearpathComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: "new-deal",
        children: [
          {
            path: "",
            component: NewDealComponent
          },
          {
            path: ":stockNumber",
            component: NewDealComponent
          }
        ]
      },
      {
        path: "credit-check",
        component: CreditCheckComponent
      },
      {
        path: "list",
        component: SalesPersonWriteupsListComponent
      },
      {
        path: "activity/:dealId",
        component: ActivityEventsComponent
      },
      {
        path: "",
        component: ClearpathComponent,
      },
    ]
  },
  {
    path: "tax-lookup",
    component: TaxLookupModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "vehicle-lookup",
    component: VehicleLookupComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "model-lookup",
    component: ModelLookupComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  // CLEARPATH WRITEUP MODALS
  {
    path: "finance-vs-cash",
    component: FinanceVsCashModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "change-stock-number",
    component: StockNumberModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "fuel-savings-calculator",
    component: FuelSavingsModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "miles-per-year-calculator",
    component: MilesPerYearModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "roll-payment-calculator",
    component: RollPaymentModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "simple-payment-calculator",
    component: SimplePaymentModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "submit-writeup",
    component: SubmitModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "warranty",
    component: WarrantyUiModalComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "vehicle-incentives",
    component: VehicleIncentivesComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
  {
    path: "feedback",
    component: FeedbackComponent,
    canActivate: [AuthGuard],
    outlet: "modal"
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class ClearpathRoutingModule {}

import { CREDIT_TIERS } from "src/app/app.config";
import { FinancingSettings, GlobalSettings, Links, Vehicle } from "../../models";
import { CertUsedResiduals, ConfigDealer, NewResiduals, VehicleIncentive } from "../../../settings-module/models";
import { ZipTable } from "src/app/settings-module/models/zip-table";
import { DealState } from ".";

export interface AppState {
  vehicle: Vehicle;
  searchString: string;
  writeupListLength: number;
  selectedManagerId: string;
  financing: FinancingSettings;
  globalSettings: GlobalSettings;
  links: Links;
  printDeals: DealState[];
  dealer: ConfigDealer;
  newResiduals: NewResiduals[];
  certUsedResiduals: CertUsedResiduals;
  zipTaxTable: ZipTable;
  formServer: any[];
  vehicleIncentives: VehicleIncentive[];
}

export const initialVehicleState = {
  stockNumber: "",
  year: "",
  make: "",
  model: "",
  carline: "",
  trim: "",
  odometer: 0,
  imageUrl: "",
  stockPhoto: "",
  retail: 0,
  insuranceProducts: [],
  incentives: [],
  lot: "",
  msr: 0,
  vehicleType: "",
  bodyStyle: "",
  exteriorColor: {
    code: "",
    description: ""
  },
  customFields: [],
  vin: "",
  modelNumber: "",
  options: [],
  engine: "",
  order: {
    invoiceNumber: "",
    price: 0,
    status: "",
    estimatedCost: 0,
    statusDate: "",
    description: "",
    locationStatus: "",
    locationStatusDate: ""
  },
  inventory: 0,
  mpg: 0,
  sold: false
};

export const initialConfigDealer = {
  companyName: "",
  companyPhone: "",
  companyAddress: {
    street: "",
    city: "",
    state: "",
    zip: ""
  },
  dealerCode: "",
  gasPrice: 0,
  displayDefaults: {
    webLogoUrl: "",
    printLogoUrl: "",
    estimatedMileage: 0,
    estimatedOwnership: 0,
    pwResetHeaderMsg: "",
    pwResetEmail: "",
    vehiclePhotoUrl: "",
    feedbackEmail: ""
  },
  insuranceDefaults: {
    productKeys: []
  },
  serviceDefaults: {
    laborRate: 0,
    appointmentEmail: "string"
  },
  dealDefaults: {
    staleArchiveDays: 0,
    completedArchiveDays: 0
  },
  salesDefaults: {}
};

export const initialAppState: AppState = {
  vehicle: initialVehicleState,
  searchString: "",
  writeupListLength: 0,
  selectedManagerId: "",
  financing: {
    creditTiers: CREDIT_TIERS,
    newRates: [],
    usedRates: [],
    certUsedRates: [],
    financeDefault: {
      newTermsShown: [],
      usedTermsShown: [],
      certTermsShown: [],
      customTermDefault: 0,
      taxRate: 0,
      daysToFirstPay: 0,
      dmvFees: 0,
      gapDepreciation: 0,
      gapAmortFactor: 0
    },
    leaseDefault: {
      newTerms: [],
      certUsedTerms: [],
      newTermsShown: [],
      certUsedTermsShown: [],
      newMarkup: 0,
      certUsedMarkup: 0,
      moneyFactor: 0,
      acquisitionFee: 0,
      prepaidMileage: 0,
      onePayDiscount: 0,
      onePayDiscFloor: 0,
      taxRate: 0,
      excessMileageRate: 0
    }
  },
  formServer: [],
  globalSettings: {
    fuelCostPerGallon: 3.25
  },
  dealer: initialConfigDealer,
  links: {
    menuItems: [],
    featuredAccessoriesNew: [],
    featuredAccessoriesCertified: [],
    featuredAccessoriesUsed: []
  },
  newResiduals: [],
  certUsedResiduals: {residualValues: []},
  zipTaxTable: null,
  vehicleIncentives: [],
  printDeals: []
};

import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest: HttpRequest<any>;
    let modifications: object;
    let noAuth: boolean; // does not add token to request
    const token = this.authService.token;
    // use if no authentication is required (for example an http request to an outside source)
    // if (request.url.includes("cloudinary")) {
    //   noAuth = true;
    // }
    if (token && !noAuth) {
      modifications = {
        setHeaders: {Authorization: `Bearer ${token}`}
      };
    }
    modifiedRequest = token ? request.clone(modifications) : request.clone();
    return next.handle(modifiedRequest);
  }
}

<div class="app-page-container">

  <div class="d-flex align-items-center justify-content-between flex-wrap">
    <h1 class="mb-3 mr-3" style="line-height: 1">Sales Cue Admin</h1>
    <button class="btn app-btn-neutral mb-3" type="button" (click)="onResetQueue()">
      Reset Queue
    </button>
  </div>

  <app-q-list
    [salesCue]="salesCue"
    [controlsEnabled]="uiState.controlsEnabled"
    [isAdmin]="uiState.isAdmin"
    (onRemoveFromSalesQueue)="onRemoveFromSalesQueue($event)"
    (onMoveToBottomOfSalesQueue)="onMoveToBottomOfSalesQueue($event)"
    (onAddCustomer)="onAddCustomer($event)"
    (onRemoveCustomer)="onRemoveCustomer($event)"
    (onReorderQueue)="onReorderQueue($event)"
    (onDragStart)="onDragStart()"
    (onDragRelease)="onDragRelease($event)"
  >
  </app-q-list>

</div>

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../shared-module/helpers";
import { UserAdminComponent } from "./pages";
import { USER_ROLES } from "../app.config";

const appRoutes: Routes = [
  {
    path: "user",
    component: UserAdminComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.sys_admin]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class UserAdminRoutingModule {}

<div class="card card-body flex-item tight mt-3">
  <div class="d-flex flex-row align-items-baseline panel-with-button header-row">
    <h5 class="card-title">{{formLabel}}</h5>
  </div>

  <form [formGroup]="formGroup">
    <table class="table table-striped">
      <tbody>
      <tr>
        <td>Year</td>
        <td><input formControlName="year" class="float-right" type="number" inputmode="numeric" min="0"/></td>
      </tr>
      <tr>
        <td>Make</td>
        <td><input formControlName="make" class="float-right" type="text" [maxlength]="REQS.maxLength.standard"/></td>
      </tr>
      <tr>
        <td>Model</td>
        <td><input formControlName="model" class="float-right" type="text" [maxlength]="REQS.maxLength.standard"/></td>
      </tr>
      <tr>
        <td>Valuation</td>
        <td><input formControlName="tradeEstimate" class="float-right" type="number" inputmode="numeric" min="0"/></td>
      </tr>
      <tr>
        <td>Pay off estimate</td>
        <td><input formControlName="payOffEstimate" class="float-right" type="number" inputmode="numeric" min="0"/></td>
      </tr>
      </tbody>
    </table>
  </form>
</div>

<div class="app-page-container">
  <div class="search-header">
    <h1 style="margin-top:20px">Customer Portrait</h1>
    <hr>

    <div style="text-align: left">
      To reset all fields, <a (click)="resetForm()" style="color:blue;text-decoration: underline;cursor:pointer">click here</a>.
    </div>
    <section class="model-info" style="margin-top:10px;">
      <span>First Name:</span>
      <input style="margin-left:10px" placeholder="Customer's First Name" [ngModel]="cnaService.firstName" (ngModelChange)="updateCustomerFirstName($event)">
    </section>

    <section class="model-info" style="margin-top:10px;">
      <span>Last Name:</span>
      <input style="margin-left:10px" placeholder="Customer's Last Name" [ngModel]="cnaService.lastName" (ngModelChange)="updateCustomerLastName($event)">
    </section>

    <hr>

    <section class="wants">
      <div class="cna-list-container">
        <h2>All Options</h2>
        <div
          cdkDropList
          #todoList="cdkDropList"
          [cdkDropListData]="cnaService.allOptions"
          [cdkDropListConnectedTo]="[doneList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="cna-list-item" *ngFor="let item of cnaService.allOptions" cdkDrag>{{item}}</div>
        </div>
      </div>
      <div class="cna-list-container">
        <h2>Customer Priorities</h2>
        <div
          cdkDropList
          #doneList="cdkDropList"
          [cdkDropListData]="cnaService.selectedOptions"
          [cdkDropListConnectedTo]="[todoList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="cna-list-item" *ngFor="let item of cnaService.selectedOptions" cdkDrag>{{item}}</div>
        </div>
      </div>
    </section>

    <hr>

    <div class="cna-container">
      <ng-container *ngFor="let cna of cnas;let i = index;">
        <div class="cna-box">
          <div class="cna-question">{{cna.question}}</div>
          <div class="options-container">
            <ng-container *ngFor="let option of cna.options;let j = index;">
              <div class="option-item">
                <input type="checkbox" [id]="'o'+i+'-'+j" [value]="option.label" class="css-checkbox" [checked]="option.selected">
                <label [for]="'o'+i+'-'+j">{{option.label}}</label>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>





  <!--section class="tile-holder">
    <ng-container *ngFor="let model of models">
      <div class="tile">
        <img [src]="'/static/images/vehicles/models/'+model.image">
        <h4>{{model.name}}</h4>
        <div class="spacer"></div>
        <div class="matchCount">8 available</div>
        <div class="priceRange">$23,945 - $48,406</div>
        <button
          data-toggle="modal"
          data-target="#cna-item-modal"
          (click)="viewCNA(model)">
          View CNA
        </button>
      </div>
    </ng-container>
  </section-->

</div>


<div id="cna-item-modal" class="modal fade cna-item-modal">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <button
        class="close"
        style="position: absolute; top: 0.25rem; right: 0.5rem"
        type="button"
        data-dismiss="modal"
        #closeModal>
        <span>&times;</span>
      </button>
      <h3>Vehicle Details</h3>
      <hr>

      This modal will include:
      <ul>
        <li>Vehicle Photo (if available)</li>
        <li>All basic vehicle information</li>
        <li>Insurance Products</li>
        <li>Button to Writeups - And pre-populate vehicle information and if CNA info available, Customer data as well </li>
      </ul>

      <ng-container *ngIf="selectedModel">
        <section class="model-info">
          <div class="stats">
            <h1>{{selectedModel.name}}</h1>
            <div class="spacer"></div>
            <div class="matchCount">8 available</div>
            <div class="priceRange">$23,945 - $48,406</div>
          </div>
          <img [src]="'/static/images/vehicles/models/'+selectedModel.image">
        </section>
        <hr>


        <section class="data-table-container">
          <table class="data-table mx-auto w-100">
            <tr>
              <th class="selectable" style="width: auto;" [ngClass]="{ 'selected': isActiveSort('stockNumber') }" (click)="onSelectSort('stockNumber')">
                <div class="d-flex align-items-center">Stock #
                  <i *ngIf="isActiveSort('stockNumber')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="width: max-content;" [ngClass]="{ 'selected': isActiveSort('year') }" (click)="onSelectSort('year')">
                <div class="d-flex align-items-center">Year
                  <i *ngIf="isActiveSort('year')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('make') }" (click)="onSelectSort('make')">
                <div class="d-flex align-items-center">Make
                  <i *ngIf="isActiveSort('make')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('model') }" (click)="onSelectSort('model')">
                <div class="d-flex align-items-center">Model
                  <i *ngIf="isActiveSort('model')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('trim') }" (click)="onSelectSort('trim')">
                <div class="d-flex align-items-center">Trim
                  <i *ngIf="isActiveSort('trim')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 300px;" [ngClass]="{ 'selected': isActiveSort('engine') }" (click)="onSelectSort('engine')">
                <div class="d-flex align-items-center">Engine
                  <i *ngIf="isActiveSort('engine')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 300px;" [ngClass]="{ 'selected': isActiveSort('transmission') }" (click)="onSelectSort('transmission')">
                <div class="d-flex align-items-center">Transmission
                  <i *ngIf="isActiveSort('transmission')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <!-- <th class="selectable"
              style="min-width: 75px;"
                  [ngClass]="{ 'selected': isActiveSort('exteriorColor') }"
                  (click)="onSelectSort('exteriorColor')">
                <div class="d-flex align-items-center">
                  Color
                  <i *ngIf="isActiveSort('exteriorColor')"
                     class="material-icons">
                    {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
                  </i>
                </div>
              </th> -->

              <th class="selectable" style="min-width: 75px;" [ngClass]="{ 'selected': isActiveSort('odometer') }" (click)="onSelectSort('odometer')">
                <div class="d-flex align-items-center">Mileage
                  <i *ngIf="isActiveSort('odometer')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 75px;" [ngClass]="{ 'selected': isActiveSort('msr') }" (click)="onSelectSort('msr')">
                <div class="d-flex align-items-center">Price
                  <i *ngIf="isActiveSort('msr')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 75px;" [ngClass]="{ 'selected': isActiveSort('retail') }" (click)="onSelectSort('retail')">
                <div class="d-flex align-items-center">Retail
                  <i *ngIf="isActiveSort('retail')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 75px" [ngClass]="{ 'selected': isActiveSort('type') }" (click)="onSelectSort('type')">
                <div class="d-flex align-items-center">Type
                  <i *ngIf="isActiveSort('type')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px" [ngClass]="{ 'selected': isActiveSort('lot') }" (click)="onSelectSort('lot')">
                <div class="d-flex align-items-center">Lot
                  <i *ngIf="isActiveSort('lot')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

            </tr>

            <!--ng-container *ngIf="(vehicles || []).length">
              <tr *ngFor="let vehicle of vehiclesList | vehicleSort: sortParam : ascending"
                  class="selectable"
                  (click)="onSelectVehicle(vehicle)">
                <td class="table-element">{{vehicle.stockNumber}}</td>
                <td class="table-element">{{vehicle.year}}</td>
                <td class="table-element">{{vehicle.make}}</td>
                <td class="table-element">{{vehicle.model}}</td>
                <td class="table-element">{{vehicle.trim}}</td>
                <td class="table-element">{{vehicle.engine}}</td>
                <td class="table-element">{{vehicle.transmission}}</td>
                <td class="table-element">{{vehicle.odometer}}</td>
                <td class="table-element">{{vehicle.msr}}</td>
                <td class="table-element">{{vehicle.retail}}</td>
                <td class="table-element">{{getType(vehicle)}}</td>
                <td class="table-element">{{vehicle.lot}}</td>
              </tr>
            </ng-container-->
          </table>


        </section>


      </ng-container>

    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CalculationService, DealService, VehicleService } from "../../services";
import { InsuranceProduct } from "../../models";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { FinanceInsuranceProductKeys } from "../../models/insurance";
import { takeUntil } from "rxjs/operators";
import { DealState } from "../../store/state";
import { clone } from "ramda";
import { TermCost } from "src/app/settings-module/models";

@Component({
  selector: "app-buy-box-finance",
  templateUrl: "./buy-box-finance.component.html",
  styleUrls: ["./buy-box-finance.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuyBoxFinanceComponent implements OnInit, OnDestroy, OnChanges {
  private unsubscribe$ = new Subject();
  @Input() financingTerm: number;
  totalEquity: number;
  totalEquityPercent: number;
  @Output() submitDisabledProducts = new EventEmitter<InsuranceProduct[]>();
  @Output() gapQuestion = new EventEmitter<InsuranceProduct>();
  @Input() disabledProducts: InsuranceProduct[] = [];
  insuranceProducts: InsuranceProduct[] = [];
  selectedProducts: InsuranceProduct[] = [];
  totalMonthlyPayment$: Observable<number>;
  interestRate: number;
  deal: DealState;
  memoryTag = "buy-box-finance";

  constructor(
    private calculationService: CalculationService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  gapQuestionClicked(product: InsuranceProduct): void {
    this.gapQuestion.emit(product);
  }

  ngOnInit() {
    this.subToDealVehicle();
    this.calculationService.findInterestRate$({term: this.financingTerm})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(interestRate => {
        this.interestRate = interestRate;
        this.initCalcs();
      });
    // this.totalMonthlyPayment$ = this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$(
    //   { term: this.financingTerm }
    // );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges() {
    this.initCalcs();
  }


  initCalcs() {
    this.totalMonthlyPayment$ = this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$(
      {term: this.financingTerm, interestRate: this.interestRate, forceCashIncentive: true, memoryTag: this.memoryTag, finance: true}
    );
    // this.totalFinanceMonthlyPaymentWithoutIncentives$ = (term?, interestRate?) => {
    //   return this.calcService.calculateTotalVehicleFinanceMonthlyPayment$({
    //     term,
    //     interestRate,
    //   });
    // }
    // this.totalFinanceMonthlyPaymentWithIncentives$ = (term, interestRate) => {
    //   return this.calcService.calculateTotalVehicleFinanceMonthlyPayment$({
    //     term,
    //     interestRate,
    //     excludeIncentives: true,
    //   });
    // }
    // this.calcService.totalVehicleFinancePrice$({ withoutDaysToFirstPay: true, actualTrade: true })
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(result => {
    //     this.uiState.totalFinanced = result;
    //   });
  }

  private subToDealVehicle() {
    combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.calculationService.totalVehicleFinancePrice$({
        withoutDaysToFirstPay: true,
        term: this.financingTerm,
        interestRate: this.interestRate,
        forceCashIncentive: true,
      })])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, vehicle, totalAmountFinanced]) => {
        const products = Object.assign([], vehicle.insuranceProducts);
        this.insuranceProducts = products;
        this.insuranceProducts = this.filterFinanceProducts(this.insuranceProducts);
        this.insuranceProducts = this.dealService.dealInsuranceService.orderInsuranceProducts(this.insuranceProducts);
        this.deal = deal;
        if (this.deal.insuranceProducts) {
          // if (!this.selectedProducts.length) {
          //   this.selectedProducts = this.deal.insuranceProducts;
          // }
          // if (this.deal.leaseOptions.leaseSelected) {
          //   return;
          // } else {
          //   this.selectedProducts = this.deal.insuranceProducts;
          // }
          this.selectedProducts = this.deal.insuranceProducts;

          this.totalEquity = this.calculationService.calcTradeEquity(this.deal, 'finance');
          this.totalEquityPercent = this.totalEquity / totalAmountFinanced;
          /*this.calculationService.logManager.consoleLog(
            `Finance Buy Box: ${this.financingTerm} term`, {
              totalEquity: this.totalEquity,
              totalAmountFinanced,
              tradeEquityPercent: this.totalEquityPercent,
            })*/
        }
      });
  }

  // UI CONTROL & RENDERING

  onSelectPaymentPlan() {
    // only update the financing term if this payment plan isn't selected
    if (!this.paymentPlanSelected()) {
      this.dealService.setFinancingTerm(this.financingTerm);
      this.dealService.dispatchSetDealType("finance");
    }
    this.router.navigate([{outlets: {modal: "submit-writeup"}}]);
  }

  paymentPlanSelected(): boolean {
    return (
      this.deal.financeOptions.financeSelected &&
      this.financingTerm === this.deal.financeOptions.selectedFinancingTerm
    );
  }

  filterFinanceProducts(insuranceProducts: InsuranceProduct[]): InsuranceProduct[] {
    return insuranceProducts.filter(product => {
      return Object.values(FinanceInsuranceProductKeys)
        .includes(product.productKey);
    });
  }

  productSelected(productKey: string): boolean {
    const product = this.selectedProducts.find(item => {
      return item.productKey === productKey;
    });
    return product ? true : false;
  }

  enableProduct(product: InsuranceProduct) {
    return;
    // const disabledProduct = this.disabledProducts.find((disabledProduct: InsuranceProduct) => {
    //   return disabledProduct.productKey === product.productKey;
    // });
    // if (!disabledProduct) {
    //   this.dealService.Insurance.enableUninitializedProduct(
    //     this.insuranceProducts,
    //     product,
    //     this.financingTerm
    //   );
    //   return;
    // }
    // const disabledProductIndex = this.disabledProducts.findIndex((disProduct: InsuranceProduct) => {
    //   return product.productKey === disProduct.productKey;
    // });
    // if (disabledProductIndex >= 0) {
    //   this.disabledProducts.splice(disabledProductIndex, 1);
    // }
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.submitInsuranceProduct(disabledProduct, disabledProduct.selectedTerm);
  }

  disableProduct(product: InsuranceProduct) {
    return;
    // const selectedProduct = this.selectedProducts.find(item => {
    //   return item.productKey === product.productKey;
    // });
    // this.disabledProducts.push(selectedProduct);
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.removeInsuranceProduct(product);
  }

  productPrice(product: InsuranceProduct): number {
    product = clone(product);
    product = this.dealService.dealInsuranceService.setHardcodedTerm(product, this.financingTerm);
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return 0; }
    const {selectedTerm} = selectedProduct;
    const selectedTermCost = selectedProduct.termCosts[ selectedTerm ];
    const termCostIndex = product.termCosts.findIndex((termCost: TermCost) => {
      return selectedTermCost.term === termCost.term && selectedTermCost.miles === termCost.miles;
    });
    const termCost = product.termCosts[ termCostIndex ];
    return termCost ? termCost.price : 0;
  }

  productDescription(product: InsuranceProduct): string {
    product = clone(product);
    product = this.dealService.dealInsuranceService.setHardcodedTerm(product, this.financingTerm);
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return ""; }

    const {selectedTerm} = selectedProduct;
    const selectedTermCost = selectedProduct.termCosts[ selectedTerm ];
    const termCostIndex = product.termCosts.findIndex((termCost: TermCost) => {
      return selectedTermCost?.term === termCost?.term && selectedTermCost?.miles === termCost?.miles;
    });
    const termCost = product.termCosts[ termCostIndex ];
    if (product.productKey.toLowerCase() === "gap") {
      // Issue #1958: Remove GAP product description
      return "";
      /*
      if (termCost && termCost.term !== this.financingTerm) {
        return `${termCost.term / 12} Years / Unlimited Miles`;
      } else {
        return "For the life of the loan";
      }
      */
    }
    if (termCost) {
      return `${termCost.term / 12} years / ${termCost.miles} miles`;
    } else {
      return "";
    }
  }

  baseMonthlyPayment$() {
    return this.calculationService.baseMonthlyPayment$(this.financingTerm);
  }

  /**
   * NOTE: We're including the unused deal param so that the async will be triggered when the deal changes.
   */
  calculateInsuranceProductMonthlyPayment$(product: InsuranceProduct, deal: DealState) {
    product = clone(product);
    product = this.dealService.dealInsuranceService.setHardcodedTerm(product, this.financingTerm);
    const selectedProduct = clone(this.selectedProducts).find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return of(0); }
    // if (product.productKey.toLowerCase() === "gap") {
    //   selectedProduct.termCosts = product.termCosts;
    //   selectedProduct.selectedTerm = product.selectedTerm;
    // } else {
    //   // product termcosts are always in the right order
    //   selectedProduct.termCosts = product.termCosts;
    // }
    return this.calculationService
      .insuranceProductMonthlyPayment$(selectedProduct, this.financingTerm, this.memoryTag);
  }

}

import { Injectable } from "@angular/core";
import { AppService } from "../app.service";
import { map } from "rxjs/operators";
import { AppState } from "../../store/state";
import { VehicleService } from "../vehicle.service";
import { Observable } from "rxjs";
import { Accessory, InsuranceProduct, VehicleNeeds } from "../../models";
import { initialVehicleNeedsState } from "../../store/state/deal/vehicle-needs";
import { DealService } from "./deal.service";

export interface DealDefaults {
  accessories: Accessory[];
  insuranceProducts: InsuranceProduct[];
  downPayment: number;
  vehicleNeeds: VehicleNeeds;
  selectedCreditTier: number;
}

@Injectable({
  providedIn: "root"
})
export class DealDefaultsService {

  constructor(
    private appService: AppService,
    private vehicleService: VehicleService,
    private dealService: DealService
  ) { }

  getDealDefaults$(): Observable<DealDefaults> {

    const dealDefaults: any = {
      incentives: [],
      vehicleNeeds: {
        milesDrivenPerYear: initialVehicleNeedsState.milesDrivenPerYear,
        plannedLengthOfOwnership: initialVehicleNeedsState.plannedLengthOfOwnership
      },
      financeOptions: {
        selectedCreditTier: 1
      }
    };

    return this.appService.selectClearpathApp().pipe(map((app: AppState) => {

      const vehicleCondition = this.vehicleService.vehicleCondition(app.vehicle);

      if (vehicleCondition === "new") {
        dealDefaults.accessories = app.links.featuredAccessoriesNew;
      }
      if (vehicleCondition === "used") {
        dealDefaults.accessories = app.links.featuredAccessoriesUsed;
      }
      if (vehicleCondition === "certified") {
        dealDefaults.accessories = app.links.featuredAccessoriesCertified;
      }

      dealDefaults.insuranceProducts = this.dealService.dealInsuranceService.configInitialProductSelections(
        app.vehicle.insuranceProducts
      );

      dealDefaults.selectedCreditTier = 1;

      return dealDefaults;

    }));

  }
}

<section class="data-table-container p-4">

  <div style="display:flex;gap:15px;align-items: center">
    <h1 style="flex-grow:2">Activity Tracker</h1>
    <button (click)="viewDeal()" class="btn btn-sm rounded-pill app-btn-outline-neutral">View Deal</button>
    <button (click)="refresh()" class="btn btn-sm rounded-pill app-btn-success">Refresh Activity Table</button>
  </div>

  <div *ngIf="deal?.dealId" style="border-top: 1px solid #999;padding: 10px 0">
    <div><b style="min-width: 150px;display: inline-block">Sales Person:</b> {{deal?.salesPerson}}</div>
    <div><b style="min-width: 150px;display: inline-block">Customer Name:</b> {{deal?.customer?.firstName}} {{deal?.customer?.lastName}}</div>
    <div><b style="min-width: 150px;display: inline-block">Vehicle:</b> {{deal?.vehicle}} (Stock #{{deal?.stockNumber}})</div>
    <div *ngIf="totalPresentationTime"><b style="min-width: 150px;display: inline-block">Presentation Time:</b> {{totalPresentationTime}}</div>
  </div>

  <table class="data-table mx-auto w-100" [style.opacity]="isLoading ? .3 : 1">

    <tr style="border-top:1px solid lightgray;pointer-events: none;background: #eee">
      <th class="selectable">
        <div class="d-flex align-items-center">Event</div>
      </th>
      <th class="selectable">
        <div class="d-flex align-items-center">Old Value</div>
      </th>
      <th class="selectable">
        <div class="d-flex align-items-center">New Value</div>
      </th>
      <th class="selectable">
        <div class="d-flex align-items-center">Changed By</div>
      </th>
      <th class="selectable" style="width: 190px;max-width: 190px;">
        <div class="d-flex align-items-center">Time</div>
      </th>
    </tr>

    <ng-container>
      <tr class="event-row" *ngFor="let event of events">
        <td>{{ event.shortName }}</td>
        <td>{{ event.oldValue}}</td>
        <td>{{ event.newValue}}</td>
        <td>{{ event.createdByName || 'User #' + event.createdBy}}</td>
        <td style="max-width: 190px;">{{ event.createdAt | date: "medium" }}</td>
      </tr>
    </ng-container>

  </table>
</section>

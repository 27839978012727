<div *ngIf="salesCue"
     class="fluid-container"
     cdkDropList
     [cdkDropListDisabled]="!isAdmin || !controlsEnabled"
     (cdkDropListDropped)="drop($event)">

  <div *ngFor="let person of salesCue.salesQueue; let i = index"
       class="mb-3"
       [ngClass]="{'d-none': !person.inQueue}"
       cdkDrag
       (cdkDragStarted)="drag()">
    <app-q-item [person]="person"
                [salesCue]="salesCue"
                [queueIndex]="getQueueIndex(i, salesCue.salesQueue)"
                [controlsEnabled]="controlsEnabled"
                [isAdmin]="isAdmin"
                (removeFromSalesQueue)="removeFromSalesQueue(person)"
                (moveToBottomOfSalesQueue)="moveToBottomOfSalesQueue(person)"
                (addCustomer)="addCustomer(person)"
                (removeCustomer)="removeCustomer(person)">
    </app-q-item>
  </div>

</div>

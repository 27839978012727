<div class="p-0">
  <div *ngFor="let rebate of rebates; let i = index" class="form-check">
    <input
      class="form-check-input check"
      type="checkbox"
      [checked]="checkBoxColor(rebate)"
      (click)="onToggleAccept(rebate)"/>
    <div class="pl-2 pt-1 cursor-default color-grey-4">
      {{ rebate.title }}
      <span class="float-right cursor-default">
        {{rebate.value | currency: "USD":"symbol":"1.0-0"}}
      </span>
    </div>
  </div>
  <div *ngIf="rebates.length === 0" class="d-flex align-items-center p-2">
    <span class="app-text-danger cursor-default">No Rebates</span>
  </div>
</div>

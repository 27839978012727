<form [formGroup]="insuranceForm">
  <app-insurance-product-selector
    *ngIf="product('VSA') && showVSA()"
    [product]="product('VSA')"
    [disabled]="disabled"
    [selectedDealTerm]="selectedDealTerm"
    [productPrice]="productPrice('VSA')"
    [useClosestTerm]="type === 'lease'"
    [productKey]="productKey('VSA')"
    [parentForm]="insuranceForm"
    [verified]="isVerified(product('VSA'))"
    [unverified]="isUnverified(product('VSA'))"
    [submitParentForm]="submitInsuranceProductsForm"
    [verifyInsuranceProduct]="verifyInsuranceProduct"
  >
  </app-insurance-product-selector>

  <app-insurance-product-selector
    *ngIf="product('PPM')"
    [product]="product('PPM')"
    [disabled]="disabled"
    [selectedDealTerm]="selectedDealTerm"
    [productPrice]="productPrice('PPM')"
    [useClosestTerm]="type === 'lease'"
    [productKey]="productKey('PPM')"
    [parentForm]="insuranceForm"
    [verified]="isVerified(product('PPM'))"
    [unverified]="isUnverified(product('PPM'))"
    [submitParentForm]="submitInsuranceProductsForm"
    [verifyInsuranceProduct]="verifyInsuranceProduct"
  >
  </app-insurance-product-selector>

  <app-insurance-product-selector
    *ngIf="product('SELECT')"
    [product]="product('SELECT')"
    [disabled]="disabled"
    [selectedDealTerm]="selectedDealTerm"
    [productPrice]="productPrice('SELECT')"
    [useClosestTerm]="type === 'lease'"
    [productKey]="productKey('SELECT')"
    [parentForm]="insuranceForm"
    [verified]="isVerified(product('SELECT'))"
    [unverified]="isUnverified(product('SELECT'))"
    [submitParentForm]="submitInsuranceProductsForm"
    [verifyInsuranceProduct]="verifyInsuranceProduct"
  >
  </app-insurance-product-selector>

  <app-insurance-product-selector
    *ngIf="product('GAP') && type !== 'cash'"
    [product]="product('GAP')"
    [disabled]="disabled"
    [selectedDealTerm]="selectedDealTerm"
    [productPrice]="productPrice('GAP')"
    [useClosestTerm]="type === 'lease'"
    [productKey]="productKey('GAP')"
    [parentForm]="insuranceForm"
    [verified]="isVerified(product('GAP'))"
    [unverified]="isUnverified(product('GAP'))"
    [submitParentForm]="submitInsuranceProductsForm"
    [verifyInsuranceProduct]="verifyInsuranceProduct"
  >
  </app-insurance-product-selector>

  <app-insurance-product-selector
    *ngIf="product('EWU') && type === 'lease'"
    [product]="product('EWU')"
    [disabled]="disabled"
    [selectedDealTerm]="selectedDealTerm"
    [productPrice]="productPrice('EWU')"
    [useClosestTerm]="type === 'lease'"
    [productKey]="productKey('EWU')"
    [parentForm]="insuranceForm"
    [verified]="isVerified(product('EWU'))"
    [unverified]="isUnverified(product('EWU'))"
    [submitParentForm]="submitInsuranceProductsForm"
    [verifyInsuranceProduct]="verifyInsuranceProduct"
  >
  </app-insurance-product-selector>

  <div *ngIf="noAvailableProducts" class="d-flex align-items-center p-2">
    <span style="color:#777;font-weight:600;font-size:14px;">No Insurance Products</span>
  </div>

  <hr class="standard-section-breaker">

  <app-simple-input
    [fieldValue]="totalInsuranceProductsPrice"
    class="lightGray"
    name="Total Protection Adds"
    type="number"
    pipe="currency">
  </app-simple-input>
</form>

<div class="app-page-container">

  <h1 class="h2 text-center mb-3">
    Finance &amp; Lease Settings
  </h1>

  <section class="view-list-layout mb-3">
    <button *ngFor="let view of viewList"
            class="btn"
            [ngClass]="activeView(view.id) ? 'app-btn-success' : 'app-btn-outline-success'"
            (click)="onSelectView(view.id)">
      {{ view.title }}
    </button>
  </section>

  <div *ngIf="waitOnInit" class="d-flex app-text-success justify-content-center align-items-center">
    <span class="spinner-border spinner-border-sm mr-1"></span>
    Loading...
  </div>

  <section *ngIf="!waitOnInit">

    <article *ngIf="activeView('newRates')">
      <h2 class="h3 text-center mb-3">
        Finance Rates<br>
        <span class="h5 font-weight-normal">
        New Vehicles
      </span>
      </h2>
      <app-finance-rates-form
        [financeRates]="newRates"
        [waitOnAction]="waitOnAction"
        (submitFinanceRates)="updateNewRates($event)"
        (unsavedChanges)="unsavedChanges()">
      </app-finance-rates-form>
    </article>

    <article *ngIf="activeView('usedRates')">
      <h2 class="h3 text-center mb-3">
        Finance Rates<br>
        <span class="h5 font-weight-normal">
        Used Vehicles
      </span>
      </h2>
      <app-finance-rates-form
        [financeRates]="usedRates"
        [waitOnAction]="waitOnAction"
        (submitFinanceRates)="updateUsedRates($event)"
        (unsavedChanges)="unsavedChanges()">
      </app-finance-rates-form>
    </article>

    <article *ngIf="activeView('certUsedRates')">
      <h2 class="h3 text-center mb-3">
        Finance Rates<br>
        <span class="h5 font-weight-normal">
        Certified Vehicles
      </span>
      </h2>
      <app-finance-rates-form
        [financeRates]="certUsedRates"
        [waitOnAction]="waitOnAction"
        (submitFinanceRates)="updateCertUsedRates($event)"
        (unsavedChanges)="unsavedChanges()">
      </app-finance-rates-form>
    </article>

    <article *ngIf="activeView('financeDefault')">
      <h2 class="h3 text-center mb-3">
        Finance Default
      </h2>
      <app-finance-default-form
        [financeDefault]="financeDefault"
        [waitOnAction]="waitOnAction"
        (submitFinanceDefault)="updateFinanceDefault($event)"
        (unsavedChanges)="unsavedChanges()">
      </app-finance-default-form>
    </article>

    <article *ngIf="activeView('leaseDefault')">
      <h2 class="h3 text-center mb-3">
        Lease Default
      </h2>
      <app-lease-default-form
        [leaseDefault]="leaseDefault"
        [waitOnAction]="waitOnAction"
        (submitLeaseDefault)="updateLeaseDefault($event)"
        (unsavedChanges)="unsavedChanges()">
      </app-lease-default-form>
    </article>

  </section>

</div>

<div class="app-page-container">

  <header class="d-flex flex-wrap align-items-center justify-content-between">
    <h1 class="h2 mb-3 mr-3">
      Insurance Products
    </h1>
    <span class="d-flex align-content-center">
            <button (click)="runPbsUserImporter()"
                    class="btn btn-sm app-btn-info mb-3 mr-2"
                    type="button"
                    [disabled]="waitOnLoad">
        Run PBS User Import
      </button>
      <button (click)="runPbsImporter()"
              class="btn btn-sm app-btn-info mb-3 mr-2"
              type="button"
              [disabled]="waitOnLoad">
        Run PBS Import
      </button>
      <button (click)="runEthosImporter()"
              class="btn btn-sm app-btn-info mb-3 mr-2"
              type="button"
              [disabled]="waitOnLoad">
        Run Ethos Import
      </button>
      <button class="btn btn-sm app-btn-secondary mb-3"
              type="button"
              (click)="onSelectAddProduct()"
              [disabled]="waitOnLoad || waitOnAction">
        Add Product
      </button>
    </span>
  </header>

  <div *ngIf="waitOnLoad"
       class="d-flex justify-content-center align-items-center app-text-success">
    <span class="spinner-border spinner-border-sm mr-1"></span>
    Loading...
  </div>

  <section *ngIf="!waitOnLoad">

    <!-- PRODUCTS -->
    <div class="mb-3">
      <app-insurance-product-list [insuranceProducts]="insuranceProducts"
                                  [activeProductIndex]="activeProductIndex"
                                  (selectProduct)="onSelectProduct($event)">
      </app-insurance-product-list>
    </div>

    <!-- EDIT PRODUCT -->
    <div *ngIf="insuranceProducts[activeProductIndex]">
      <h2 class="h3 text-center mb-3">
        Edit Product
      </h2>
      <app-insurance-product-form [product]="insuranceProducts[activeProductIndex]"
                                  [waitOnAction]="waitOnAction"
                                  (submitProduct)="updateProduct($event)"
                                  (removeProduct)="removeProduct()">
      </app-insurance-product-form>
    </div>

    <!-- ADD PRODUCT -->
    <div *ngIf="newProduct">
      <h2 class="h3 text-center mb-3">
        Add Product
      </h2>
      <app-insurance-product-form [isNewProduct]="true"
                                  [waitOnAction]="waitOnAction"
                                  (submitProduct)="createProduct($event)">
      </app-insurance-product-form>
    </div>

  </section>

</div>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/user-admin-module/services";
import { Feedback } from "src/app/user-admin-module/services/user-service/user.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth-module/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/user-admin-module/models";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"]
})
export class FeedbackComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject();
  loading: boolean;
  currentUser: User;
  feedbackForm: FormGroup = this.formBuilder.group({
    title: ["", [Validators.required]],
    message: ["", [Validators.required]]
  });

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.subToCurrentUser();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  subToCurrentUser() {
    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.currentUser = user;
      });
  }

  sendFeedback() {
    if (!this.feedbackForm.valid) {
      return alert("please complete the required forms.");
    }
    this.loading = true;
    const feedback: Feedback = this.feedbackForm.value;
    feedback.email = this.currentUser.email;
    this.userService.submitFeedback(feedback).subscribe({
      next: response => {
        this.loading = false;
        this.router.navigate([{outlets: {modal: null}}]);
      },
      error: error => {
        alert(error);
        this.loading = false;
        console.error(error);
      }
    });
  }

  // FORM VALIDATION & HELPERS

  touchedInvalid(controlName: string): boolean {
    const touched = this.feedbackForm.controls[ controlName ].touched;
    const valid = this.feedbackForm.controls[ controlName ].valid;
    return touched && !valid;
  }

}

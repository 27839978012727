<section class="header-layout">
  <h1 class="h2 header-title">CLEAR Care Elite</h1>

  <form [formGroup]="eliteForm" class="header-form">
    <select
      class="header-form-control form-control"
      formControlName="termCostIndex">
      <option value="" disabled>Select A Plan</option>
      <option *ngFor="let option of termCosts; let i = index" [value]="i">
        {{ option.term / 12 }} Years / {{ option.miles || "Unlimited" }} Miles -
        {{ option.price | currency: "USD":"symbol":"1.0-0" }}
      </option>
    </select>
  </form>
</section>

<app-cna-list-dialog-header dialogName="CLEAR Care Elite"></app-cna-list-dialog-header>

<div class="w-100 mx-auto my-4" style="max-width: 700px">
  <!-- <app-clear-graph></app-clear-graph> -->
  <img src="/static/images/insurance/clear-elite.png" class="d-block w-100"/>
</div>

<div class="btn-layout">
  <button
    class="btn"
    [ngClass]="planSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
    type="button"
    (click)="onAcceptPlan()">
    Yes: Include Coverage
  </button>
  <button
    class="btn"
    [ngClass]="noPlanSelected() ? 'app-btn-dark' : 'app-btn-outline-dark'"
    type="button"
    (click)="onDeclinePlan()">
    Decline
  </button>
</div>

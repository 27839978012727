import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../auth-module/services";
import { USER_ROLES } from "../app.config";
import { InventoryPageComponent } from "./pages/inventory-page.component";

const inventoryRoutes: Routes = [
  {
    path: "inventory",
    children: [
      {
        path: "list",
        component: InventoryPageComponent
      }
    ],
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.sales_floor]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(inventoryRoutes)],
  exports: [RouterModule]
})
export class InventoryRoutingModule {}

export class CalculationMemoryManager {

  private memory: CalculationObject[];
  private enabled = false;

  constructor(enabled = false) {
    this.enabled = enabled;
    this.memory = [];
  }

  add(tag, functionName, object) {
    if (this.enabled) {
      this.memory[ tag + ":" + functionName ] = object;
    }
  }

  show(tag, functionName, extras = []) {
    if (this.enabled) {
      const extraObjects = [];
      if (extras && extras.length > 0) {
        extras.forEach(extraFunctionName => {
          const extraObject = this.get(tag, extraFunctionName);
          if (extraObject) {
            extraObjects[ extraFunctionName ] = extraObject;
          }
        });
      }
      if (this.memory[ tag + ":" + functionName ]) {
        const memoryObject: any = this.get(tag, functionName);
        for (const [key, value] of Object.entries(extraObjects)) {
          memoryObject[ key ] = value;
        }
        //console.log(tag + ":" + functionName, memoryObject);
      }
    }
  }

  addAndShow(tag, functionName, object, extras = []) {
    if (tag) {
      this.add(tag, functionName, object);
      this.show(tag, functionName, extras);
    }
  }

  get(tag, functionName): object {
    if (this.enabled) {
      if (this.memory[ tag + ":" + functionName ]) {
        return this.memory[ tag + ":" + functionName ];
      }
    } else {
      return null;
    }
  }
}

export class CalculationObject {
  public tag: string;
  public functionName: string;
  public object?: any;
}

<form [formGroup]="userForm" class="card card-body" autocomplete="off">
  <div class="form-group">
    <label class="font-weight-bold" [ngClass]="{ 'app-text-danger': touchedInvalid('firstName') }">
      First Name *
    </label>
    <input class="form-control" formControlName="firstName" type="text" [maxlength]="REQS.maxLength.standard"
           (blur)="trimInput('firstName')" required/>
  </div>

  <div class="form-group">
    <label class="font-weight-bold" [ngClass]="{ 'app-text-danger': touchedInvalid('lastName') }">
      Last Name *
    </label>
    <input class="form-control" formControlName="lastName" type="text" [maxlength]="REQS.maxLength.standard"
           (blur)="trimInput('lastName')" required/>
  </div>

  <div class="form-group">
    <label class="font-weight-bold" [ngClass]="{ 'app-text-danger': touchedInvalid('email') }">
      Email *
    </label>
    <input class="form-control" formControlName="email" type="email" [maxlength]="REQS.maxLength.standard"
           (blur)="trimInput('email')" required/>
  </div>

  <div class="form-group">
    <label class="d-flex align-items-end justify-content-between font-weight-bold"
           [ngClass]="{ 'app-text-danger': touchedInvalid('smsCellNumber') }">
      <span>Cell Number</span>
      <span *ngIf="touchedInvalid('smsCellNumber')" class="small">
        {{ phoneInput.value.length }} / 10
      </span>
    </label>
    <input #phoneInput class="form-control" formControlName="smsCellNumber" type="tel" minLength="10" maxLength="10"
           (blur)="trimInput('smsCellNumber')"/>
  </div>

  <div class="form-group">
    <label class="font-weight-bold" [ngClass]="{ 'app-text-danger': touchedInvalid('password') }">
      {{ activeFormMode('create') ? 'Password' : 'Change Password' }} *
    </label>
    <input #password class="form-control" formControlName="password" type="password" autocomplete="new-password"/>
    <label *ngIf="activeFormMode('create') || password.value.length > 0" class="small">
      Must be {{ REQS.minLength.password }} to {{ REQS.maxLength.password }} characters.
      <span [ngClass]="{ 'app-text-danger': touchedInvalid('password') }">
        [{{ password.value.length }}]
      </span>
    </label>
    <label *ngIf="activeFormMode('edit') && password.value.length === 0" class="small">
      Leave blank to keep existing password.
    </label>
  </div>

  <div class="form-group">
    <label class="font-weight-bold" [ngClass]="{ 'app-text-danger': touchedInvalid('photoUrl') }">
      Photo URL
    </label>
    <input #photoUrl class="form-control" formControlName="photoUrl" type="text" (blur)="trimInput('photoUrl')"/>
    <label class="small">
      Maximum of {{ REQS.maxLength.long }} characters.
      <span [ngClass]="{ 'app-text-danger': touchedInvalid('photoUrl') }">
        [{{ photoUrl.value.length }}]
      </span>
    </label>
  </div>

  <div class="form-group">
    <label class="d-flex align-items-start justify-content-between font-weight-bold"
           [ngClass]="{ 'app-text-danger': touchedInvalid('employeeId') }">
      Employee ID *
      <button class="btn rounded-pill px-2 py-0" [ngClass]="employeeIdLocked ? 'app-btn-neutral' : 'app-btn-success'"
              style="font-size: 0.8rem" type="button" (click)="onToggleEmployeeIdLock()">
        Edit {{ employeeIdLocked ? 'Locked' : 'Unlocked' }}
      </button>
    </label>
    <input *ngIf="!employeeIdLocked" class="form-control" formControlName="employeeId" type="text"
           [maxlength]="REQS.maxLength.standard" (blur)="trimInput('employeeId')" required/>
    <div *ngIf="employeeIdLocked" class="form-control app-bg-disabled">
      {{ userForm.value.employeeId || 'No Employee ID' }}
    </div>
  </div>

  <div class="form-group">
    <label class="d-flex align-items-start justify-content-between font-weight-bold">
      Manager ID
    </label>
    <input class="form-control" formControlName="managerId" type="text" [maxlength]="REQS.maxLength.standard"
           (blur)="trimInput('managerId')"/>
  </div>

  <label class=" font-weight-bold">Application Alerts</label>

  <div class="form-group mb-4">
    <div class="form-check">
      <input class="form-check-input" formControlName="suppressPresentationStarted" type="checkbox"/>
      <label class="form-check-label">Suppress Presentation Started Alert</label>
    </div>
  </div>

  <label class=" font-weight-bold">Security Groups</label>

  <div class="form-group mb-4" formArrayName="securityGroupsArray">
    <div *ngFor="let group of securityGroupControls; let i = index" class="form-check">
      <input class="form-check-input" [formControlName]="i" type="checkbox"/>
      <label class="form-check-label">{{ securityGroups[i] | uppercase }}</label>
    </div>
  </div>

  <button class="btn app-btn-secondary" (click)="onSubmit()" [disabled]="waitOnAction || userForm.invalid">
    <span *ngIf="waitOnAction" class="spinner-border spinner-border-sm mr-1"> </span>
    Submit
  </button>
</form>

<div style="background: #59873d;margin:0;box-sizing:border-box;padding: 0">
  <app-navbar
    *ngIf="currentUser"
    [currentRelease]="currentRelease"
    [currentUser]="currentUser"
    [currentRoute]="currentRoute">
  </app-navbar>
  <app-alert></app-alert>
</div>
<router-outlet></router-outlet>
<router-outlet name="modal"></router-outlet>
<!--
<app-footer
  *ngIf="isSalesManager"
  [showRecords]="this.currentRoute === '/sales-manager/list'">
</app-footer>
<footer class="footer-section">
  <div>© 2022 NorthStar. Server Version: {{currentRelease}}</div>
</footer>
-->
<div appFlutterLogout></div>

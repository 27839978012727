<form [formGroup]="migrateRequestForm" class="app-page-container">
  <h3 class="h3 mb-4 mt-4" style="text-align: center">Archive</h3>

  <section>
    <button class="btn app-btn-primary" (click)="runArchiver()">
      <span
        *ngIf="archiveRequestLoading"
        class="spinner-border spinner-border-sm mr-1"
      ></span
      >Run Archiver
    </button>
  </section>

  <hr/>

  <h3 class="h3 mb-4 mt-4">Migrate Request</h3>
  <h6>
    Select one or more of the following to import from the url to this server
  </h6>
  <p>The urls must end with ".json"</p>

  <section *ngIf="migrateRequestForm" formArrayName="objects">
    <div *ngFor="let key of objectKeys; let i = index">
      <div [formGroupName]="i">
        <label class="app-text-bold">{{ key }}</label>
        <div class="input-group-text">
          <div class="input-group-prepend mr-2">
            <input
              (change)="selectionChanged(i)"
              class="form-check-label"
              type="checkbox"
              [checked]="hasSourceUrl(i)"
            />
          </div>
          <input
            class="form-control"
            formControlName="sourceUrl"
            [ngClass]="{ 'app-text-danger': touchedInvalid(i) }"
            type="text"
            pattern=".*.json$"
            [value]="getArchivePath(key)"
            placeholder="use format: {env}/archives/configurations/cert_used_residuals/2020-04-17.json"
          />
        </div>
      </div>
    </div>
  </section>

  <button class="btn app-btn-primary mt-2" (click)="migrateRequest()">
    <span
      *ngIf="migrateRequestLoading"
      class="spinner-border spinner-border-sm mr-1"
    ></span
    >Submit
  </button>
</form>

// Use this file for any app wide strings, constants or configs.
import { CreditTier } from "./clearpath-module/models";
import { InsuranceProductKeys, TermCost } from "./clearpath-module/models/insurance";

export const USER_ROLES = {
  sys_admin: "sys_admin",
  cue_admin: "cue_admin",
  sales_floor: "sales_floor",
  cue_display: "cue_display",
  sales_manager: "sales_manager"
};

export const CREDIT_TIERS: CreditTier[] = [
  {tier: 0, name: "Tier 1+ (720+)"},
  {tier: 1, name: "Tier 1 (719 - 690)"},
  {tier: 2, name: "Tier 2 (689 - 670)"},
  {tier: 3, name: "Tier 3 (669 - 650)"},
  {tier: 4, name: "Tier 4 (649 - 630)"},
  {tier: 5, name: "Tier 5 (629 - 610)"},
  {tier: 6, name: "Tier 6 (609 - 580)"},
  {tier: 7, name: "Tier 7 (579 - 520)"}
];

export enum DEAL_STATUSES {
  Changed = "changed",
  Submitted = "submitted",
  Approved = "approved",
  Printing = "printing",
  Printed = "printed",
  Completed = "completed",
  Declined = "declined",
  Updated = "updated"
}

export const COMPLETED_STATUSES = [
  "submitted",
  "approved",
  "printing",
  "printed",
  "updated",
  "completed",
  "declined"
];

export const INSURANCE_PRODUCT_ORDER = [
  "VSC",
  "LEASEWEARTEAR",
  "MAINTENANCEPLAN",
  "SELECT",
  "GAP"
];

export const DEFAULT_LEASE_MONEY_FACTORS = [
  .00315,
  .00325,
  .00370,
  .00460,
  .00525,
  .00605,
  .00665,
  .00785
];
export const DEFAULT_LEASE_W_TIER_MONEY_FACTOR_REDUCTION = .0001; // subtract this from all MF's
export const DEFUALT_LEASE_LONG_TERM_MONEY_FACTOR_ADDITION = .0002; // add this to leases > 36mo
export const HIGH_FICO_REDUCTION = .0001;

// this can use any of the InsuranceDefaultTerms to set either a default term, or miles or both.
export const InsuranceDefaultTerms:
  Partial<{ [prop in InsuranceProductKeys]: Partial<TermCost> }> = {
  // [InsuranceProductKeys.VSA]: { term: 72, miles: 75000 },
  [ InsuranceProductKeys.PPM ]: {term: 60}
};

export const FORM_INPUT_REQS = {
  minLength: {password: 8},
  maxLength: {password: 24, standard: 100, long: 500}
};


export enum PubsubMessages {
  DealCreated = "{dealCreated}",
  DealSubmitted = "{dealSubmitted}",
  DealPrinting = "{dealPrinting}",
  DealRetracted = "{dealRetracted}",
  DealApproved = "{dealApproved}",
  DealUpdated = "{dealUpdated}"
}

// The objects which can be archived - mirrored on backend
export const ARCHIVE_OBJECTS = {
  ActiveDeals: "active_deals",
  ArchiveDeals: "archive_deals",
  Vehicles: "vehicles",
  CertUsedResiduals: "cert_used_residuals",
  NewResiduals: "new_residuals",
  Dealer: "dealer",
  Insurance: "insurance",
  Finance: "finance",
  Forms: "forms",
  Incentives: "incentives",
  Links: "links"
};

export const ARCHIVE_PATHS = {
  ActiveDeals: "/archives/active_deals/",
  ArchiveDeals: "/archives/archive_deals/",
  Vehicles: "/archives/vehicles/",
  CertUsedResiduals: "/archives/configurations/cert_used_residuals/",
  NewResiduals: "/archives/configurations/new_residuals/",
  Dealer: "/archives/configurations/dealer/",
  Insurance: "/archives/configurations/insurance/",
  Finance: "/archives/configurations/finance/",
  Forms: "/archives/configurations/forms/",
  Incentives: "/archives/configurations/incentives/",
  Links: "/archives/configurations/links/"
};

// note: regFee's have plate fee added for used
export const OREGON_REG_FEES_TABLE = {
  new: [
    {
      minMpg: 0,
      maxMpg: 19,
      regFee: 288,
      titleFee: 101,
      orego: false
    },
    {
      minMpg: 20,
      maxMpg: 39,
      regFee: 298,
      titleFee: 106,
      orego: false
    },
    {
      minMpg: 40,
      maxMpg: 59,
      regFee: 338,
      titleFee: 116,
      orego: false
    },
    {
      minMpg: 60,
      maxMpg: 999.9999,
      regFee: 658,
      titleFee: 192,
      orego: false
    }
  ],
  used: [
    {
      minMpg: 0,
      maxMpg: 19,
      regFee: 152,
      titleFee: 101,
      orego: false
    },
    {
      minMpg: 20,
      maxMpg: 39,
      regFee: 162,
      titleFee: 106,
      orego: false
    },
    {
      minMpg: 40,
      maxMpg: 59,
      regFee: 182,
      titleFee: 116,
      orego: false
    },
    {
      minMpg: 60,
      maxMpg: 999.9999,
      regFee: 342,
      titleFee: 192,
      orego: false
    }
  ]
};

export const PREPAID_MILEAGE_RATE = .1;
export const PREPAID_MILES_PER_YEAR = 15000;

export const ELECTRONIC_FILING_FEE = 50;
export const PLATE_TRANSFER_FEE = 30;

export const COUNTY_FEES = {
  Washington: {new: 120, used: 60},
  Clackamas: {new: 120, used: 60},
  Multnomah: {new: 224, used: 112}
};

export const PRIVILEGE_TAX_RATE = .005;
export const CAT_TAX_RATE = .0057;
export const WASHINGTON_SALES_TAX = .087;


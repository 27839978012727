<form [formGroup]="newDealForm" class="app-page-container">
  <h1 class="mb-3" style="line-height: 1">Customer</h1>

  <section formGroupName="customer">
    <div class="row">
      <div class="form-group col-sm-4">
        <label
          [ngClass]="{
          'app-text-danger': touchedInvalid('customer', 'firstName')
        }"
        >
          First Name *
        </label>
        <input
          class="form-control"
          formControlName="firstName"
          type="text"
          autofocus="true"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('customer', 'firstName', true)"
        />
      </div>

      <div class="form-group col-sm-4">
        <label> Middle Name </label>
        <input
          class="form-control"
          formControlName="middleName"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('customer', 'middleName', true)"
        />
      </div>

      <div class="form-group col-sm-4">
        <label>
          Last Name
        </label>
        <input
          class="form-control"
          formControlName="lastName"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('customer', 'lastName', true)"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-md-12">
        <label>
          <b>Business Name:</b> Optional.
        </label>
        <input
          class="form-control"
          formControlName="companyName"
          type="text"
          [maxlength]="REQS.maxLength.standard"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-md-12">
        <label>
          <b>Address Finder:</b> Optional. Start entering an address in the field below to search for an address.
        </label>
        <div style="position: relative;display: flex;justify-content: stretch;align-items: stretch">
          <mat-icon style="position: absolute;top:10px;left:10px;">search</mat-icon>
          <input
            #addressText
            class="form-control"
            type="text"
            style="padding-left: 35px;"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-md-12">
        <label>
          Street
        </label>
        <input
          class="form-control"
          formControlName="street"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('customer', 'street')"
        />
      </div>

      <div class="form-group col-sm-4 col-md-3">
        <label>
          City
        </label>
        <input
          class="form-control"
          formControlName="city"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('customer', 'city')"
        />
      </div>

      <div class="form-group col-sm-4 col-md-3">
        <label>
          County
        </label>
        <input
          class="form-control"
          formControlName="county"
          type="text"
          (blur)="trimInput('customer', 'county')"
        />
      </div>

      <div class="form-group col-sm-4 col-md-3">
        <label>
          State
        </label>
        <select
          class="form-control"
          formControlName="state">
          <option value="">Select State</option>
          <option value="OR">Oregon</option>
          <option value="WA">Washington</option>
          <optgroup label="──────────"></optgroup>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>

      </div>

      <div class="form-group col-sm-4 col-md-3">
        <label>
          Zip
        </label>
        <input
          class="form-control"
          formControlName="zip"
          type="text"
          inputmode="numeric"
          minlength="5"
          maxlength="5"
          (blur)="trimInput('customer', 'zip')"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label>
          <span>Phone</span>
          <span class="small">
            {{ phoneInput.value.length }} / 10
          </span>
        </label>
        <input
          #phoneInput
          class="form-control"
          formControlName="phone"
          type="text"
          inputmode="numeric"
          minlength="10"
          maxlength="10"
          (blur)="trimInput('customer', 'phone')"
        />
      </div>

      <div class="form-group col-sm-6">
        <label>
          Email
        </label>
        <input
          class="form-control"
          formControlName="email"
          type="email"
          (blur)="trimInput('customer', 'email')"
        />
      </div>
    </div>
  </section>

  <h1 class="mb-3" style="line-height: 1">Vehicle</h1>

  <section formGroupName="vehicle">
    <div class="row">
      <div class="form-group col-sm-6">
        <div class="d-flex align-items-start justify-content-between">
          <label>
            Stock Number *
          </label>
          <div>
            <button
              *ngIf="!isSalesFloor"
              class="btn rounded-pill px-2 py-0 mx-2"
              [ngClass]="{
                'app-btn-secondary': isDealerTrade,
                'app-btn-success': !isDealerTrade
              }"
              style="font-size: 0.8rem"
              type="button"
              (click)="toggleDealerTrade()"
            >
              Dealer Trade
            </button>
            <button
              class="btn app-btn-success rounded-pill px-2 py-0"
              style="font-size: 0.8rem"
              type="button"
              [disabled]="isDealerTrade"
              (click)="openVehicleLookup()"
            >
              Lookup
            </button>
          </div>
        </div>
        <input
          class="form-control mb-1"
          formControlName="stockNumber"
          type="text"
          inputmode="numeric"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('vehicle', 'stockNumber')"
          required
        />
      </div>

      <!--  -->

      <ng-container *ngIf="isDealerTrade">
        <div class="form-group col-sm-6">
          <div class="d-flex align-items-start justify-content-between">
            <label>Model Number</label>
            <button
              class="btn app-btn-success rounded-pill px-2 py-0"
              style="font-size: 0.8rem"
              type="button"
              (click)="onSelectModelNumberLookup()"
            >
              Lookup
            </button>
          </div>
          <input
            class="form-control"
            formControlName="modelNumber"
            type="text"
            [maxlength]="REQS.maxLength.standard"
            (blur)="trimInput('vehicle', 'modelNumber')"
          />
        </div>

        <div class="col-sm-6">
          <label
            [ngClass]="{
              'app-text-danger': touchedInvalid('vehicle', 'tradeCondition')
            }"
          >
            Condition
          </label>
          <select class="form-control" formControlName="tradeCondition">
            <option value="">Select Vehicle Condition</option>
            <option value="New">New</option>
            <option value="Certified">Certified</option>
            <option value="Used">Used</option>
          </select>
        </div>

        <div class="form-group col-sm-6">
          <label
            [ngClass]="{
              'app-text-danger': touchedInvalid('vehicle', 'year')
            }"
          >
            Year
          </label>
          <input class="form-control" formControlName="year" type="number"/>
        </div>

        <div class="form-group col-sm-6">
          <label
            [ngClass]="{
              'app-text-danger': touchedInvalid('vehicle', 'make')
            }"
          >
            Make
          </label>
          <input class="form-control" formControlName="make" type="text"/>
        </div>
        <div class="form-group col-sm-6">
          <label
            [ngClass]="{
              'app-text-danger': touchedInvalid('vehicle', 'model')
            }"
          >
            Model
          </label>
          <input class="form-control" formControlName="model" type="text"/>
        </div>
        <div class="form-group col-sm-6">
          <label
            [ngClass]="{
              'app-text-danger': touchedInvalid('vehicle', 'trim')
            }"
          >
            Trim
          </label>
          <input class="form-control" formControlName="trim" type="text"/>
        </div>

        <div class="form-group col-sm-6">
          <label>VIN (Required to Pull Ethos Data)</label>
          <input
            class="form-control"
            formControlName="vin"
            type="text"
            [maxlength]="17"
            (blur)="trimInput('vehicle', 'vin')"
          />
        </div>

        <div class="form-group col-sm-6">
          <label>MSRP</label>
          <input
            class="form-control"
            formControlName="msr"
            type="number"
            [maxlength]="REQS.maxLength.standard"
          />
        </div>

        <div class="form-group col-sm-6">
          <label>Selling Price</label>
          <input
            class="form-control"
            formControlName="retail"
            type="number"
            [maxlength]="REQS.maxLength.standard"
          />
        </div>
      </ng-container>

      <!--  -->

      <div class="form-group col-sm-6">
        <label
          [ngClass]="{
          'app-text-danger': touchedInvalid('vehicle', 'odometer')
        }"
        >
          Odometer
        </label>
        <input
          class="form-control"
          formControlName="odometer"
          inputmode="numeric"
          type="number"
        />
      </div>

      <div class="form-group col-sm-6">
        <label>License Plate</label>
        <input
          class="form-control"
          formControlName="plateNumber"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('vehicle', 'plateNumber')"
        />
      </div>

      <div class="form-group col-sm-6">
        <label>Plate Expiration</label>
        <input
          class="form-control"
          formControlName="plateExpires"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          (blur)="trimInput('vehicle', 'plateExpires')"
        />
      </div>

      <div class="form-group col-sm-6">
        <label>Sales Person ID</label>
        <input
          class="form-control"
          formControlName="salesId"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          readonly
        />
      </div>
    </div>
  </section>

  <button
    class="btn app-btn-secondary"
    type="button"
    (click)="onSubmitNewDealForm()"
    [disabled]="uiState.busy"
  >
    <!-- <span
      *ngIf="waitOnAction"
      class="spinner-border spinner-border-sm mr-1"
    ></span> -->
    Continue
  </button>
</form>

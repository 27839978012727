import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MenuItem } from "src/app/clearpath-module/models";

@Component({
  selector: "app-menu-link-list",
  templateUrl: "./menu-link-list.component.html",
  styleUrls: ["./menu-link-list.component.scss"]
})
export class MenuLinkListComponent {

  @Input() menuItems: MenuItem[];
  @Output() changes = new EventEmitter<MenuItem[]>();
  childOverrideCollapsed: number;

  constructor() { }


  onChanges({menuItem, index}) {
    if (menuItem) {
      this.menuItems[ index ] = menuItem;
      // this.childOverrideCollapsed = index;
    } else {
      this.menuItems.splice(index, 1);
    }
    this.changes.emit(this.menuItems);
  }

  createMenuItem() {
    this.menuItems.unshift({
      iconUrl: "",
      linkUrl: "",
      description: "New Menu Item (Click to edit)"
    });
  }

}

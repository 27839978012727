import { ErrorHandler, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ErrorService implements ErrorHandler {
  handleError(error) {
    console.error("error:", error);
    // error handling logic
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SalesCue, SalesQPerson } from '../../models/sales-queue';

@Component({
  selector: "app-q-list",
  templateUrl: "./q-list.component.html",
  styleUrls: ["./q-list.component.scss"]
})
export class QListComponent {
  @Input() salesCue: SalesCue;
  @Input() isAdmin = false;
  @Input() controlsEnabled = true;

  // tslint:disable: no-output-on-prefix
  @Output() onRemoveFromSalesQueue = new EventEmitter();
  @Output() onMoveToBottomOfSalesQueue = new EventEmitter();
  @Output() onAddCustomer = new EventEmitter();
  @Output() onRemoveCustomer = new EventEmitter();
  @Output() onReorderQueue = new EventEmitter();
  @Output() onDragStart = new EventEmitter();
  @Output() onDragRelease = new EventEmitter();

  constructor() { }

  removeFromSalesQueue(person: SalesQPerson) {
    this.onRemoveFromSalesQueue.emit(person);
  }

  moveToBottomOfSalesQueue(person: SalesQPerson) {
    this.onMoveToBottomOfSalesQueue.emit(person);
  }

  addCustomer(person: SalesQPerson) {
    this.onAddCustomer.emit(person);
  }

  removeCustomer(person: SalesQPerson) {
    this.onRemoveCustomer.emit(person);
  }

  drag() {
    this.onDragStart.emit();
  }

  drop(e) {
    this.onDragRelease.emit(e);
  }

  getQueueIndex(indexInArray: number, q: Array<SalesQPerson>): number {
    let queueIndex = 0;
    for (let i = 0; i < indexInArray; i++) {
      if (q[ i ].inQueue) { queueIndex++; }
    }
    return queueIndex;
  }
}

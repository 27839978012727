export class LogManager {

  private allLogJson?: Map<string, string>;
  private enabled = false;

  constructor(enabled = false) {
    this.enabled = enabled;
    this.allLogJson = new Map([]);
  }

  consoleLog = (key: string, value: object): void => {
    if (this.enabled) {
      let valueJson = JSON.stringify(value)
      if (!valueJson) return
      let existing = this.allLogJson.get(key)
      if (existing === valueJson) {
        return
      } else {
        this.allLogJson.set(key, valueJson)
        console.log(key + ':', value)
      }
    }
  }
}

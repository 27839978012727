import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "../store/state";
import * as historyActions from "../store/actions/history.actions";
import * as appSelectors from "../store/selectors/clearpath";
import { interval, Observable, of, Subject } from "rxjs";
import { DealHistory, HistoryEvent, HistoryTimers, HistoryTimerSubscribers, initialHistoryTimersState, initialHistoryTimerSubscribersState } from "../models/history";
import { takeUntil } from "rxjs/operators";
import { User } from "../../user-admin-module/models";
import { CalculationUtilityService } from "./calculations/calculation-utility.service";

const api = "/v1/deal_history";
const routes = {
  read: (dealId: string) => `${api}/read/${dealId}`,
  list: `${api}/list`,
  addEvent: (dealId: string) => `${api}/add_event/${dealId}`,
  update: `${api}/update`,
};

@Injectable({
  providedIn: "root",
})
export class HistoryService {
  private unsubscribe$ = new Subject();

  private latestHistoryMap = new Map<string, string>();

  getHistory(): Observable<any> {
    return this.unsubscribe$.asObservable();
  }

  isDuplicateEvent(event: Partial<HistoryEvent>): boolean {
    let key = event.eventId + "_" + event.shortName;
    let val = event.oldValue;
    if (!event.oldValue && !event.newValue) {
      key = "nav";
      val = event.eventId + "_" + event.shortName;
    }
    let existing = this.latestHistoryMap.get(key);
    if (existing && existing === val) {
      return true;
    } else if (event.oldValue && event.newValue && event.oldValue === event.newValue) {
      return true;
    }
    //console.log("Non Duplicate Event:", key, val)
    this.latestHistoryMap.set(key, val);
    return false;
  }

  constructor(
    private http: HttpClient,
    private calculationUtilityService: CalculationUtilityService,
    private store: Store<fromRoot.DealState>
  ) { }

  timers: HistoryTimers = initialHistoryTimersState;
  timerSubscriptions: HistoryTimerSubscribers = initialHistoryTimerSubscribersState;


  // SELECT

  selectHistories(): Observable<DealHistory[]> {
    return this.store.select(appSelectors.selectHistories);
  }

  selectHistory() {
    return this.store.select(appSelectors.selectHistory);
  }

  // DISPATCH

  dispatchGetHistories() {
    this.store.dispatch(historyActions.getHistories());
  }

  dispatchGetHistory(dealId: string) {
    this.store.dispatch(historyActions.getHistory(dealId));
  }

  dispatchOverviewPage() {
    this.dispatchAddEvent({shortName: "Presentation Page Entered"} as HistoryEvent);
  }

  dispatchAddEvent(event: Partial<HistoryEvent>) {
    try {
      if (!this.isDuplicateEvent(event)) {
        this.store.dispatch(historyActions.addEvent(event));
      } else {
        //console.log("Duplicate Event:", event)
      }
    } catch (e) {
      console.error("Error dispatching history event", e);
    }
  }

  dispatchDownPaymentChanged(oldValue: any, newValue: any) {
    this.dispatchAddEvent({
      eventId: 3,
      oldValue: this.calculationUtilityService.formatCurrencyUS(oldValue),
      newValue: this.calculationUtilityService.formatCurrencyUS(newValue)
    } as Partial<HistoryEvent>);
  }

  dispatchAccessoriesCatalog() {
    this.store.dispatch(historyActions.accessoriesCatalog());
  }

  dispatchBetterOwnershipEvent() {
    this.store.dispatch(historyActions.betterOwnership());
  }

  dispatchBestOwnershipEvent() {
    this.store.dispatch(historyActions.bestOwnership());
  }

  dispatchPaymentOptionPresented() {
    this.store.dispatch(historyActions.paymentOptionsPresented());
  }

  // HTTP

  read(dealId): Observable<any> {
    return dealId ? this.http.get(routes.read(dealId)) : of(null);
  }

  addEvent(dealId: string, event: Partial<HistoryEvent>): Observable<any> {
    if (!dealId) console.error("No Deal ID", event);
    if (event.oldValue !== undefined && event.oldValue !== null) event.oldValue = event.oldValue.toString();
    if (event.newValue !== undefined && event.newValue !== null) event.newValue = event.newValue.toString();
    //console.log("Event Dispatch:", event.eventId, event.shortName, event)
    return this.http.post(routes.addEvent(dealId), event);
  }

  update(history: DealHistory): Observable<any> {
    return this.http.post(routes.update, history);
  }

  list(): Observable<any> {
    return this.http.get(routes.list);
  }

  // HELPERS

  // OVERVIEW PAGE

  startOverviewPagetimer() {

    this.timerSubscriptions.overviewPage.timer = interval(1000)
      .pipe(takeUntil(this.timerSubscriptions.overviewPage.cancel))
      .subscribe((val: number) => {
        //this.timers.overviewPage.next(val);
      });

  }


  stopOverviewPageTimer() {
    this.timerSubscriptions.overviewPage.cancel.next();
    const elapsed = this.timers.overviewPage.value;
    // const event: HistoryEvent = {
    //   eventId: 2,
    //   createdAt: new Date(),
    //   createdBy: string,
    //   description: string,
    //   shortName: string,
    //   elasped: number,
    //   notes: string,
    // };
  }

  // EVENTS

  genericEvent(user: User, event: Partial<HistoryEvent>) {
    return {
      ...this.getBaseHistoryData(0, user),
      ...event
    };
  }

  overviewPageEvent(user: User, event: Partial<HistoryEvent>) {
    return {
      ...this.getBaseHistoryData(2, user),
      ...event,
    };
  }

  tradeInEvent(user: User) {
    return {
      ...this.getBaseHistoryData(1, user),
    };
  }

  downPaymentChangedEvent(user: User, event: Partial<HistoryEvent>) {
    return {
      ...this.getBaseHistoryData(3, user),
      ...event
    };
  }

  accessoriesCatalogEvent(user: User) {
    return {
      ...this.getBaseHistoryData(4, user),
    };
  }

  betterOwnershipEvent(user: User) {
    return {
      ...this.getBaseHistoryData(9, user),
    };
  }

  bestOwnershipEvent(user: User) {
    return {
      ...this.getBaseHistoryData(11, user),
    };
  }

  paymentOptionsPresented(user: User) {
    return {
      ...this.getBaseHistoryData(13, user),
    };
  }

  getBaseHistoryData(eventId: number, user: User) {
    return {
      eventId: eventId,
      createdAt: new Date(),
      createdBy: user.id.toString(),
      createdByName: this.userFullName(user)
    };
  }

  userFullName(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }
}

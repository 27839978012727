import { Pipe, PipeTransform } from '@angular/core';
import { sort } from 'ramda';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(object: any, propertyName: any): number {
    return sort((a, b) => {
      return a[ propertyName ] - b[ propertyName ];
    }, object)
  }
}

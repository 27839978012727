import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CalculationService, DealService, VehicleService } from "../../services";
import { InsuranceProduct } from "../../models";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { FinanceInsuranceProductKeys } from "../../models/insurance";
import { takeUntil } from "rxjs/operators";
import { DealState } from "../../store/state";

@Component({
  selector: "app-buy-box-custom",
  templateUrl: "./buy-box-custom.component.html",
  styleUrls: ["./buy-box-custom.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuyBoxCustomComponent implements OnInit, OnDestroy, OnChanges {
  private unsubscribe$ = new Subject();
  @Input() financingTerm: number;
  @Output() submitDisabledProducts = new EventEmitter<InsuranceProduct[]>();
  @Input() disabledProducts: InsuranceProduct[] = [];
  insuranceProducts: InsuranceProduct[] = [];
  selectedProducts: InsuranceProduct[] = [];
  totalMonthlyPayment$: Observable<number>;
  interestRate: number;
  deal: DealState;

  constructor(
    private calculationService: CalculationService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.subToDealVehicle();
    this.calculationService.findInterestRate$({term: this.financingTerm})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(interestRate => {
        this.interestRate = interestRate;
        this.initCalcs();
      });
    // this.totalMonthlyPayment$ = this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$(
    //   { term: this.financingTerm }
    // );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges() {
    this.initCalcs();
  }


  initCalcs() {
    this.totalMonthlyPayment$ = this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$(
      {term: this.financingTerm}
    );
    // this.totalFinanceMonthlyPaymentWithoutIncentives$ = (term?, interestRate?) => {
    //   return this.calcService.calculateTotalVehicleFinanceMonthlyPayment$({
    //     term,
    //     interestRate,
    //   });
    // }
    // this.totalFinanceMonthlyPaymentWithIncentives$ = (term, interestRate) => {
    //   return this.calcService.calculateTotalVehicleFinanceMonthlyPayment$({
    //     term,
    //     interestRate,
    //     excludeIncentives: true,
    //   });
    // }
    // this.calcService.totalVehicleFinancePrice$({ withoutDaysToFirstPay: true, actualTrade: true })
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(result => {
    //     this.uiState.totalFinanced = result;
    //   });
  }

  private subToDealVehicle() {
    combineLatest([this.dealService.selectDeal(), this.vehicleService.selectVehicle()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, vehicle]) => {
        const products = Object.assign([], vehicle.insuranceProducts);
        this.insuranceProducts = products;
        this.insuranceProducts = this.filterFinanceProducts(this.insuranceProducts);
        this.insuranceProducts = this.dealService.dealInsuranceService.orderInsuranceProducts(this.insuranceProducts);
        this.deal = deal;
        if (this.deal.insuranceProducts) {
          // if (!this.selectedProducts.length) {
          //   this.selectedProducts = this.deal.insuranceProducts;
          // }
          // if (this.deal.leaseOptions.leaseSelected) {
          //   return;
          // } else {
          //   this.selectedProducts = this.deal.insuranceProducts;
          // }
          this.selectedProducts = this.deal.insuranceProducts;
        }
      });
  }

  // UI CONTROL & RENDERING

  onSelectPaymentPlan() {
    this.dealService.setFinancingTerm(this.financingTerm);
    this.dealService.dispatchSetFinanceOptions({
      customSelected: true,
      incentiveSelected: false,
      selectedFinancingTerm: this.financingTerm
    });
    this.dealService.dispatchSetDealType("finance");
    this.router.navigate([{outlets: {modal: "submit-writeup"}}]);
  }

  paymentPlanSelected(): boolean {
    return (
      this.deal.financeOptions.customSelected &&
      this.financingTerm === this.deal.financeOptions.selectedFinancingTerm
    );
  }

  filterFinanceProducts(insuranceProducts: InsuranceProduct[]): InsuranceProduct[] {
    return insuranceProducts.filter(product => {
      return Object.values(FinanceInsuranceProductKeys)
        .includes(product.productKey);
    });
  }

  productSelected(productKey: string): boolean {
    const product = this.selectedProducts.find(item => {
      return item.productKey === productKey;
    });
    return product ? true : false;
  }

  enableProduct(product: InsuranceProduct) {
    return;
    // const disabledProduct = this.disabledProducts.find((disabledProduct: InsuranceProduct) => {
    //   return disabledProduct.productKey === product.productKey;
    // });
    // if (!disabledProduct) {
    //   this.dealService.Insurance.enableUninitializedProduct(
    //     this.insuranceProducts,
    //     product,
    //     this.financingTerm
    //   );
    //   return;
    // }
    // const disabledProductIndex = this.disabledProducts.findIndex((disProduct: InsuranceProduct) => {
    //   return product.productKey === disProduct.productKey;
    // });
    // if (disabledProductIndex >= 0) {
    //   this.disabledProducts.splice(disabledProductIndex, 1);
    // }
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.submitInsuranceProduct(disabledProduct, disabledProduct.selectedTerm);
  }

  disableProduct(product: InsuranceProduct) {
    return;
    // const selectedProduct = this.selectedProducts.find(item => {
    //   return item.productKey === product.productKey;
    // });
    // this.disabledProducts.push(selectedProduct);
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.removeInsuranceProduct(product);
  }

  productPrice(product: InsuranceProduct): number {
    product = this.dealService.dealInsuranceService.setHardcodedTerm(product, this.financingTerm);
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return 0; }
    const {selectedTerm} = selectedProduct;
    const termCost = product.termCosts[ selectedTerm ];

    return termCost ? termCost.price : 0;
  }

  productDescription(product: InsuranceProduct): string {
    product = this.dealService.dealInsuranceService.setHardcodedTerm(product, this.financingTerm);
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return ""; }

    const {selectedTerm} = selectedProduct;
    const termCost = product.termCosts[ selectedTerm ];
    if (product.productKey.toLowerCase() === "gap") {
      // Issue #1958: Remove GAP product description
      return "";
      /*
      const { selectedTerm } = product;
      const termCost = product.termCosts[selectedTerm];
      return "For the life of the loan";
      */
    }
    if (termCost) {
      return `${termCost.term / 12} years / ${termCost.miles} miles`;
    } else {
      return "";
    }
  }

  baseMonthlyPayment$() {
    return this.calculationService.baseMonthlyPayment$(this.financingTerm);
  }

  calculateInsuranceProductMonthlyPayment$(product: InsuranceProduct) {
    product = this.dealService.dealInsuranceService.setHardcodedTerm(product, this.financingTerm);
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return of(0); }
    if (product.productKey.toLowerCase() === "gap") {
      selectedProduct.termCosts = product.termCosts;
      selectedProduct.selectedTerm = product.selectedTerm;
    } else {
      // product termcosts are always in the right order
      selectedProduct.termCosts = product.termCosts;
    }
    return this.calculationService
      .insuranceProductMonthlyPayment$(selectedProduct, this.financingTerm);
  }

}

<div class="app-page-container d-flex flex-column align-items-center justify-content-center" style="min-height: 90vh;">
  <img appDealerLogo class="w-75 mb-5" style="max-width: 500px;"/>

  <form [formGroup]="forgotPasswordForm" class="mb-3">
    <h1 class="h4 font-weight-light text-center mb-3">Password Reset</h1>

    <div class="form-group">
      <input
        class="form-control"
        [ngClass]="{ 'border-danger': touchedInvalid('email') }"
        formControlName="email"
        type="email"
        placeholder="Email"
        autofocus="autofocus"
        autocomplete="email"
        name="email"
        (blur)="trimInput('email')"
        required
      />
    </div>

    <button
      class="btn app-btn-success btn-block"
      type="submit"
      (click)="onSubmit()"
      [disabled]="forgotPasswordForm.invalid || loading"
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      SUBMIT
    </button>
  </form>

  <a routerLink="/login">Back to Login</a>
</div>

import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFireUploadTask } from "@angular/fire/storage/task";
import "firebase/storage";
import { environment } from "src/environments/environment";
import { v4 as uuid } from "uuid";

@Injectable({
  providedIn: "root"
})
export class FileUploadService {

  constructor(private storage: AngularFireStorage) { }

  upload(base64Data: string, path: string): AngularFireUploadTask {
    base64Data = base64Data.split(",")[ 1 ];
    const ref = this.storage.ref(path);
    return ref.putString(base64Data, "base64", {contentType: "image/jpg"});
  }

  uploadBuyerSignature(base64Data: string) {
    return this.upload(base64Data, `${environment.firebase.signatureStorage}/${uuid()}.jpg`);
  }

  uploadCoBuyerSignature(base64Data: string) {
    return this.upload(base64Data, `${environment.firebase.signatureStorage}/${uuid()}.jpg`);
  }

}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DealService } from "../../services";
import { FinanceOptions } from "../../models";
import Big from "big.js";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-custom-rate-input",
  templateUrl: "./custom-rate-input.component.html",
  styleUrls: ["./custom-rate-input.component.scss"]
})
export class CustomRateInputComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  private savedCustomRate: number;

  private uiState = {
    customRatePercentage: 0,
  };

  constructor(
    private dealService: DealService,
    private historyService: HistoryService,
    private formBuilder: FormBuilder,
  ) { }

  customRateForm: FormGroup = this.formBuilder.group({
    customRatePercentage: [0, Validators.min(0)]
  });

  ngOnInit() {
    this.subToFinanceOptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subToFinanceOptions() {
    this.dealService.selectFinanceOptions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((financeOptions: FinanceOptions) => {
        this.savedCustomRate = financeOptions.customerProvidedInterestRate;
        this.updateCustomRateForm();
      });
  }

  autoSubmitCustomRateForm() {
    const {dirty, valid} = this.customRateForm;
    if (dirty && valid) {
      const {customRatePercentage} = this.customRateForm.value;

      const eventObj = {
        shortName: 'Custom Rate Changed',
        oldValue:
          (this.uiState.customRatePercentage === customRatePercentage
            ? 0
            : this.uiState.customRatePercentage) + '%',
        newValue: customRatePercentage + '%',
      };
      this.historyService.dispatchAddEvent(eventObj);

      const customRate = Big(customRatePercentage).div(100).round(6).toNumber();
      this.dealService.submitCustomFinanceRate(customRate);
      this.customRateForm.markAsPristine();
      this.uiState.customRatePercentage = customRatePercentage;
    }
  }

  clearRate() {
    this.dealService.submitCustomFinanceRate(0);
    const eventObj = {
      shortName: 'Custom Rate Changed',
      oldValue:
        (this.uiState.customRatePercentage === 0
          ? 0
          : this.uiState.customRatePercentage) + '%',
      newValue: '0%',
    };
    this.historyService.dispatchAddEvent(eventObj);
    this.uiState.customRatePercentage = 0;
  }

  private updateCustomRateForm() {
    const customRate = this.savedCustomRate || 0;
    const customRatePercentage = Big(customRate).times(100).round(6).toNumber();
    this.customRateForm.patchValue({customRatePercentage});
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.customRateForm.get(controlName);
    return control.touched && control.invalid;
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "vehicleName"
})
export class VehicleNamePipe implements PipeTransform {
  transform(vehicleName: string): string {
    if (vehicleName) {
      const [year, make, model, trim] = vehicleName.split(" ");
      return make && make.toUpperCase() === "TOYOTA" ?
        `${model} ${trim}` :
        `${make} ${model}`;
    } else {
      return vehicleName;
    }
  }
}

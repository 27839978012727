import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../shared-module/helpers";
import { USER_ROLES } from "../app.config";
import { MasterSalesManagerWriteupComponent, SalesManagerWriteupsListComponent } from "./pages/";
import { CanDeactivateGuard } from "../shared-module/services";
import { ActivityEventsComponent } from "../shared-module/components/activity-events/activity-events.component";

const smRoutes: Routes = [
  {
    path: "sales-manager",
    children: [
      {
        path: "writeup",
        component: MasterSalesManagerWriteupComponent,
        canDeactivate: [CanDeactivateGuard],
        children: [{
          path: "newSMWriteup",
          component: MasterSalesManagerWriteupComponent,
        }]
      },
      {
        path: "writeup/:dealId",
        component: MasterSalesManagerWriteupComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: "list",
        component: SalesManagerWriteupsListComponent
      },
      {
        path: "activity/:dealId",
        component: ActivityEventsComponent
      },
    ],
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [USER_ROLES.sales_manager]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(smRoutes)],
  exports: [RouterModule]
})
export class SalesManagerRoutingModule {}

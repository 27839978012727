<h2 class="h4 app-bg-light rounded text-center font-weight-light p-3 mb-3">
  {{ productForm.value.name || "New Product" }}
</h2>

<form [formGroup]="productForm" class="card card-body">
  <section class="row">
    <div class="form-group col-sm-4">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('provider') }">
        Provider ID <i>ex: ethos</i> *
      </label>
      <input
        class="form-control"
        formControlName="provider"
        type="text"
        [maxlength]="REQS.maxLength.standard"
        autofocus="true"
        required
      />
    </div>

    <div class="form-group col-sm-4">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('providerName') }">
        Provider Name <i>ex: Ethos Group Inc.</i> *
      </label>
      <input
        class="form-control"
        formControlName="providerName"
        type="text"
        [maxlength]="REQS.maxLength.standard"
        required
      />
    </div>

    <div class="form-group col-sm-4">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('name') }">
        Product Name *
      </label>
      <input
        class="form-control"
        formControlName="name"
        type="text"
        [maxlength]="REQS.maxLength.standard"
        required
      />
    </div>
  </section>

  <!-- <section class="row">
    <div class="form-group col-sm-6">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('pbsName') }">
        PBS Name
      </label>
      <input
        class="form-control"
        formControlName="pbsName"
        type="text"
        [maxlength]="REQS.maxLength.standard"
        autofocus="true"
      />
    </div>

    <div class="form-group col-sm-6">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('pbsType') }">
        PBS Type (Protection, Warranty)
      </label>
      <input
        class="form-control"
        formControlName="pbsType"
        type="text"
        [maxlength]="REQS.maxLength.standard"
      />
    </div>
  </section> -->

  <section class="row">
    <div class="form-group col-sm-4">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('productKey') }">
        Product Key *
      </label>
      <input
        class="form-control"
        formControlName="productKey"
        type="text"
        [maxlength]="REQS.maxLength.standard"
        required
      />
    </div>

    <div class="form-group col-sm-4">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('minYear') }">
        Min Model Year *
      </label>
      <input
        class="form-control"
        formControlName="minYear"
        min="1000"
        max="9999"
        type="number"
        required
      />
    </div>

    <div class="form-group col-sm-4">
      <label>Max Mileage</label>
      <input
        class="form-control"
        formControlName="maxOdometer"
        max="2000000"
        type="number"
      />
    </div>

    <!-- <div class="form-group col-sm-4">
      <label> Icon URL </label>
      <input
        class="form-control"
        formControlName="iconUrl"
        type="text"
        [maxlength]="REQS.maxLength.long"
      />
    </div> -->
  </section>

  <!-- <section class="row">
    <div class="form-group col-sm-4">
      <label> Link URL </label>
      <input
        class="form-control"
        formControlName="linkUrl"
        type="text"
        [maxlength]="REQS.maxLength.long"
      />
    </div>

    <div class="form-group col-sm-4">
      <label> Link Icon URL </label>
      <input
        class="form-control"
        formControlName="linkIconUrl"
        type="text"
        [maxlength]="REQS.maxLength.long"
      />
    </div>

    <div class="form-group col-sm-4">
      <label> Link Description </label>
      <input
        class="form-control"
        formControlName="linkDescription"
        type="text"
        [maxlength]="REQS.maxLength.standard"
      />
    </div>
  </section> -->

  <hr/>

  <section class="grid-layout-col-4">
    <div></div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadNew"
        type="checkbox"
      />
      <label class="form-check-label"> New </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadUsed"
        type="checkbox"
      />
      <label class="form-check-label"> Used </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadCertUsed"
        type="checkbox"
      />
      <label class="form-check-label"> Certified </label>
    </div>
  </section>

  <section class="grid-layout-col-4">
    <div class="font-weight-bold">Available On</div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadCar"
        type="checkbox"
      />
      <label class="form-check-label"> Car </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadTruck"
        type="checkbox"
      />
      <label class="form-check-label"> Truck </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadSuv"
        type="checkbox"/>
      <label class="form-check-label"> SUV </label>
    </div>

  </section>

  <section class="grid-layout-col-4">
    <div></div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadVan"
        type="checkbox"/>
      <label class="form-check-label"> Van </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="preLoadOther"
        type="checkbox"
      />
      <label class="form-check-label"> Other </label>
    </div>

  </section>

  <hr/>
  <!-- EXCLUDED MODELS -->
  <label class="mb-3 font-weight-bold"> Excluded Models </label>
  <section class="mb-3 row" formArrayName="excludedMakes">
    <div
      *ngIf="excludedMakeControls.length === 0"
      class="app-bg-light app-text-neutral rounded px-3 py-2 mb-3"
    >
      No excluded models added.
    </div>

    <article
      *ngFor="let excludedMake of excludedMakeControls; let i = index"
      class="input-group mb-3 col-sm-3"
    >
      <input
        class="form-control"
        [ngClass]="{ 'app-border-danger': touchedInvalidExcludedMake(i) }"
        [formControlName]="i"
        type="text"
        [maxlength]="REQS.maxLength.standard"
        placeholder="Please enter an excluded model."
        required
      />
      <div class="input-group-append">
        <button
          class="
            btn
            app-btn-neutral
            d-flex
            align-items-center
            justify-content-center
          "
          type="button"
          (click)="onRemoveExcludedMake(i)"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>
  </section>

  <section class="row">
    <button
      class="
        btn btn-sm
        app-btn-secondary
        d-flex
        align-items-center
        justify-content-center
      "
      type="button"
      (click)="onAddExcludedMake()"
    >
      <i class="material-icons">add</i>
    </button>
  </section>

  <!--- <section class="grid-layout-col-4">
    <div class="font-weight-bold">Available On</div>

    <div class="form-check">
      <input class="form-check-input"
              formControlName="sellWithNew"
              type="checkbox">
      <label class="form-check-label">
        New
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input"
              formControlName="sellWithUsed"
              type="checkbox">
      <label class="form-check-label">
        Used
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input"
              formControlName="sellWithCertUsed"
              type="checkbox">
      <label class="form-check-label">
        Certified
      </label>
    </div>
  </section> -->

  <!-- <hr />

  <div class="form-group">
    <label> Tag Line </label>
    <input
      class="form-control"
      formControlName="tagLine"
      type="text"
      [maxlength]="REQS.maxLength.standard"
    />
  </div>

  <div class="form-group">
    <label> Product Terms </label>
    <textarea
      class="form-control"
      formControlName="productTerms"
      rows="5"
    ></textarea>
  </div>

  <div class="form-group">
    <label> Guidelines </label>
    <textarea
      class="form-control"
      formControlName="guidelines"
      rows="5"
    ></textarea>
  </div> -->

  <!-- <hr /> -->

  <!-- DETAILS -->
  <!-- <section class="mb-3" formArrayName="details">
    <label class="mb-3"> Details </label>

    <div
      *ngIf="detailControls.length === 0"
      class="app-bg-light app-text-neutral rounded px-3 py-2 mb-3"
    >
      No product details added.
    </div>

    <article
      *ngFor="let detail of detailControls; let i = index"
      class="input-group mb-3"
    >
      <input
        class="form-control"
        [ngClass]="{ 'app-border-danger': touchedInvalidDetail(i) }"
        [formControlName]="i"
        type="text"
        [maxlength]="REQS.maxLength.standard"
        placeholder="Please enter a detail."
        required
      />
      <div class="input-group-append">
        <button
          class="
            btn
            app-btn-neutral
            d-flex
            align-items-center
            justify-content-center
          "
          type="button"
          (click)="onRemoveDetail(i)"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="
        btn btn-sm
        app-btn-secondary
        d-flex
        align-items-center
        justify-content-center
      "
      type="button"
      (click)="onAddDetail()"
    >
      <i class="material-icons">add</i>
    </button>
  </section> -->

  <hr/>

  <section>
    <label class="mb-3"> Markup </label>
    <article class="row">
      <div class="form-group col-sm-4">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('flatPrice') }">
          Flat Price
        </label>
        <span class="input-symbol-dollar">
          <input
            id="currency"
            class="form-control"
            formControlName="flatPrice"
            type="number"
          />
        </span>
      </div>
      <div class="form-group col-sm-4">
        <label [ngClass]="{ 'app-text-danger': touchedInvalid('flatMarkup') }">
          Flat Markup
        </label>
        <span class="input-symbol-dollar">
          <input
            class="form-control"
            formControlName="flatMarkup"
            type="number"
          />
        </span>
      </div>
    </article>

    <hr/>

    <article class="mb-3" formArrayName="markupMatrix">
      Markup Matrix
      <div
        *ngFor="let markup of markupMatrixControls; let i = index"
        class="card card-body app-bg-light mb-3"
        [formGroupName]="i"
      >
        <div class="term-cost-layout" style="border-top-right-radius: 0">
          <div>
            <label
              [ngClass]="{
                'app-text-danger': touchedInvalidMarkupMatrix(i, 'termMin')
              }"
            >
              Minimum Term (At least 0)
            </label>
            <input
              class="form-control"
              formControlName="termMin"
              min="0"
              type="number"
              required
            />
          </div>
          <div>
            <label
              [ngClass]="{
                'app-text-danger': touchedInvalidMarkupMatrix(i, 'termMax')
              }"
            >
              Maximum Term
            </label>
            <input
              class="form-control"
              formControlName="termMax"
              min="0"
              type="number"
              required
            />
          </div>
          <div>
            <label
              [ngClass]="{
                'app-text-danger': touchedInvalidMarkupMatrix(i, 'newMarkup')
              }"
            >
              New Markup
            </label>
            <div class="input-icon">
              <i>$</i>
              <input
                class="form-control"
                formControlName="newMarkup"
                min="0"
                type="number"
                required
              />
            </div>
          </div>
          <div>
            <label
              [ngClass]="{
                'app-text-danger': touchedInvalidMarkupMatrix(i, 'usedMarkup')
              }"
            >
              Used Markup
            </label>
            <div class="input-icon">
              <i>$</i>
              <input
                class="form-control"
                formControlName="usedMarkup"
                min="0"
                type="number"
                required
              />
            </div>
          </div>
          <div>
            <label
              [ngClass]="{
                'app-text-danger': touchedInvalidMarkupMatrix(i, 'certMarkup')
              }"
            >
              Certified Used Markup
            </label>
            <div class="input-icon">
              <i>$</i>
              <input
                class="form-control"
                formControlName="certMarkup"
                min="0"
                type="number"
                required
              />
            </div>
          </div>
        </div>
        <button
          class="
            btn
            app-btn-neutral
            d-flex
            align-items-center
            justify-content-center
          "
          style="position: absolute; top: 0; right: 0"
          type="button"
          (click)="onRemoveMarkupMatrixItem(i)"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </article>

    <button
      class="
        btn btn-sm
        app-btn-secondary
        d-flex
        align-items-center
        justify-content-center
      "
      type="button"
      (click)="onAddMarkupMatrixItem()"
    >
      Add Markup Matrix Term
      <i class="material-icons">add</i>
    </button>
  </section>

  <hr/>

  <!-- TERM COSTS -->
  <section class="mb-3" formArrayName="termCosts">
    <label class="mb-3">
      Term Costs
      <span *ngIf="isEthos()" class="app-text-danger">
        ETHOS product terms are set automatically on the server on a
        per-vehicle. Disregard these term costs.
      </span>
    </label>

    <div
      *ngIf="termCostControls.length === 0"
      class="app-bg-light app-text-neutral rounded px-3 py-2 mb-3">
      No product term costs added.
    </div>

    <article
      *ngFor="let termCost of termCostControls; let i = index"
      class="card card-body app-bg-light mb-3"
      [formGroupName]="i">
      <div class="term-cost-layout" style="border-top-right-radius: 0">
        <div>
          <label [ngClass]="{ 'app-text-danger': touchedInvalidTermCost(i, 'term') }">Term *</label>
          <input
            class="form-control"
            formControlName="term"
            min="0"
            type="number"
            required
          />
        </div>

        <div>
          <label [ngClass]="{'app-text-danger': touchedInvalidTermCost(i, 'miles')}">Miles *</label>
          <input
            class="form-control"
            formControlName="miles"
            min="0"
            type="number"
            required
          />
        </div>

        <div>
          <label
            [ngClass]="{'app-text-danger': touchedInvalidTermCost(i, 'price')}">Price *</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              class="form-control"
              formControlName="price"
              min="0"
              type="number"
              required
            />
          </div>
        </div>

        <div>
          <label
            [ngClass]="{ 'app-text-danger': touchedInvalidTermCost(i, 'cost') }"
          >
            Cost *
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              class="form-control"
              formControlName="cost"
              min="0"
              type="number"
              required
            />
          </div>
        </div>

        <div>
          <label
            [ngClass]="{
              'app-text-danger': touchedInvalidTermCost(i, 'providerId')
            }"
          >
            Provider ID *
          </label>
          <input
            class="form-control"
            formControlName="providerId"
            type="text"
            [maxlength]="REQS.maxLength.standard"
            required
          />
        </div>
      </div>

      <button
        class="
          btn
          app-btn-neutral
          d-flex
          align-items-center
          justify-content-center
        "
        style="position: absolute; top: 0; right: 0"
        type="button"
        (click)="onRemoveTermCost(i)"
      >
        <i class="material-icons">close</i>
      </button>
    </article>

    <button
      class="
        btn btn-sm
        app-btn-secondary
        d-flex
        align-items-center
        justify-content-center
      "
      type="button"
      (click)="onAddTermCost()"
    >
      <i class="material-icons">add</i>
    </button>
  </section>

  <hr/>

  <div class="d-flex justify-content-between">
    <button
      class="btn app-btn-secondary"
      type="button"
      (click)="onSubmitProductForm()"
      [disabled]="waitOnAction || productForm.pristine || productForm.invalid"
    >
      Submit
    </button>
    <button
      *ngIf="!isNewProduct"
      class="btn app-btn-danger"
      type="button"
      (click)="onRemoveProduct()"
      [disabled]="waitOnAction"
    >
      Remove Product
    </button>
  </div>
</form>

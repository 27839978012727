import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserService } from "../../user-admin-module/services/user-service/user.service";
import { USER_ROLES } from "src/app/app.config";
import { SalesCue } from "../models/sales-queue";

const api = "/v1/salescue";
const routes = {
  add: userId => `${api}/addtoqueue/${userId}`,
  read: `${api}/read`,
  update: `${api}/update`,
  remove: userId => `${api}/removefromqueue/${userId}`
};

@Injectable({providedIn: "root"})
export class SalesQueueService {
  constructor(private http: HttpClient, private userService: UserService) { }

  add(userId): Observable<any> {
    return this.http.post(routes.add(userId), {});
  }

  read(): Observable<any> {
    return this.http.get(routes.read);
  }

  update(q: SalesCue): Observable<any> {
    return this.http.post(routes.update, q);
  }

  remove(userId): Observable<any> {
    return this.http.delete<any>(routes.remove(userId));
  }

  getSalesRoster() {
    return this.userService.getBySecurityGroup([USER_ROLES.sales_floor]);
  }
}

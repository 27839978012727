<h2 class="h3 mb-3 text-center">
  <span *ngIf="showTitle('basic')">
    Your Ownership Experience
  </span>

  <span *ngIf="showTitle('better')">
    Your <strong class="app-text-secondary">CLEAR</strong> Ownership Experience
  </span>

  <span *ngIf="showTitle('best')">
    Your <strong class="app-text-info">COMPLETE</strong> Ownership Experience.
  </span>
</h2>

<section class="graph-container">

  <!-- BACKGROUND BAR GRAPH LAYERS -->
  <div *ngFor="let graph of warrantyUi.backgroundGraphs"
       class="bg-graph"
       [ngClass]="bgGraphClasses(graph)"
       [ngStyle]="{ 'width': graph.width + '%' }">

    <div class="bg-graph__label">
      <div class="mb-2">{{ graph.title }}</div>
      <div *ngFor="let detail of graph.details"
           class="small">
        {{ detail }}
      </div>
    </div>

  </div>

  <!-- FOREGROUND BAR GRAPH LAYERS -->
  <div *ngFor="let graph of warrantyUi.productGraphs"
       class="fg-graph">
    <app-product-graph [activeViewIndex]="warrantyUi.activeViewIndex"
                       [graph]="graph" [vehicle]="vehicle">
    </app-product-graph>
  </div>

</section>

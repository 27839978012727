import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ArchiveComponent, DealerComponent, FinanceComponent, FormServerComponent, IncentivesComponent, InsuranceProductsComponent, LeaseResidualsComponent, MenuLinksComponent, SettingsMenuComponent } from "./pages";
import { AccessoryFormComponent, CertUsedResidualsTableComponent, FeaturedAccessoriesComponent, FeaturedAccessoryComponent, FinanceDefaultFormComponent, FinanceRatesFormComponent, IncentiveFormComponent, IncentiveGroupComponent, IncentiveGroupListComponent, InsuranceProductFormComponent, InsuranceProductListComponent, LeaseDefaultFormComponent, MenuLinkDisplayComponent, MenuLinkFormComponent, MenuLinkListComponent, NewResidualsTableComponent, UploadCertUsedResidualsComponent, UploadNewResidualsComponent, } from "./components";
import { SettingsRoutingModule } from "./settings.routing.module";
import { NgbCollapseModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../shared-module/shared.module";

@NgModule({
  declarations: [
    SettingsMenuComponent,
    MenuLinksComponent,
    MenuLinkFormComponent,
    AccessoryFormComponent,
    DealerComponent,
    FinanceComponent,
    FinanceDefaultFormComponent,
    FinanceRatesFormComponent,
    InsuranceProductFormComponent,
    InsuranceProductListComponent,
    InsuranceProductsComponent,
    SettingsMenuComponent,
    FeaturedAccessoryComponent,
    FeaturedAccessoriesComponent,
    LeaseDefaultFormComponent,
    LeaseResidualsComponent,
    MenuLinkDisplayComponent,
    MenuLinkListComponent,
    UploadNewResidualsComponent,
    UploadCertUsedResidualsComponent,
    NewResidualsTableComponent,
    CertUsedResidualsTableComponent,
    IncentivesComponent,
    IncentiveGroupComponent,
    IncentiveFormComponent,
    IncentiveGroupListComponent,
    ArchiveComponent,
    FormServerComponent,
  ],
  imports: [
    NgbModule,
    NgbCollapseModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SettingsRoutingModule,
    SharedModule
  ]
})
export class SettingsModule {}

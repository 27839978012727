import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Accessory } from "src/app/clearpath-module/models";

@Component({
  selector: "app-featured-accessories",
  templateUrl: "./featured-accessories.component.html",
  styleUrls: ["./featured-accessories.component.scss"]
})
export class FeaturedAccessoriesComponent {
  @Input() accessories: Accessory[];
  @Output() changes = new EventEmitter<Accessory[]>();

  constructor() { }

  onChanges({accessory, index}) {
    if (accessory) {
      this.accessories[ index ] = accessory;
    } else {
      this.accessories.splice(index, 1);
    }
    this.changes.emit(this.accessories);
  }

  createItem() {
    const newAccessory: Accessory = {
      accessoryLink: {
        iconUrl: "",
        linkUrl: "",
        description: ""
      },
      hidden: false,
      disabled: false,
      name: "New Accessory (Click To Edit)",
      tagLine: "",
      price: 0,
      details: [],
      preLoad: true,
      pbsName: "",
      pbsType: ""
    };
    this.accessories.unshift(newAccessory);
    this.changes.emit(this.accessories);
  }
}

import { createAction } from "@ngrx/store";
import { DealHistory, HistoryEvent } from "../../models/history";

const prefix = "[Clearpath]";

const OVERVIEW_PAGE = `${prefix} OVERVIEW PAGE`;
const BEST_OWNERSHIP = `${prefix} BEST OWNERSHIP`;
const BETTER_OWNERSHIP = `${prefix} BETTER OWNERSHIP`;
const PAYMENT_OPTIONS_PRESENTED = `${prefix} PAYMENT OPTIONS PRESENTED`;
const DOWN_PAYMENT_CHANGED = `${prefix} DOWN PAYMENT CHANGED`;
const ACCESSORIES_CATALOG = `${prefix} ACCESSORIES CATALOG`;

const GET_HISTORY = `${prefix} GET HISTORY`;
const GET_HISTORY_SUCCESS = `${prefix} GET HISTORY SUCCESS`;
const GET_HISTORY_FAILURE = `${prefix} GET HISTORY FAILURE`;

const GET_HISTORIES = `${prefix} GET HISTORIES`;
const GET_HISTORIES_SUCCESS = `${prefix} GET HISTORIES SUCCESS`;
const GET_HISTORIES_FAILURE = `${prefix} GET HISTORIES FAILURE`;

const UPDATE_HISTORY_SUCCESS = `${prefix} UPDATE HISTORY SUCCESS`;

const ADD_EVENT = `${prefix} ADD EVENT`;
const ADD_EVENT_SUCCESS = `${prefix} ADD EVENT SUCCESS`;
const ADD_EVENT_FAILURE = `${prefix} ADD EVENT FAILURE`;

// EVENTS

export const overviewPage = createAction(
  OVERVIEW_PAGE,
  (event: Partial<HistoryEvent>) => ({event})
);

export const downPaymentChanged = createAction(
  DOWN_PAYMENT_CHANGED,
  (event: Partial<HistoryEvent>) => ({event})
);

export const betterOwnership = createAction(
  BETTER_OWNERSHIP
);

export const bestOwnership = createAction(
  BEST_OWNERSHIP
);

export const paymentOptionsPresented = createAction(
  PAYMENT_OPTIONS_PRESENTED
);

export const accessoriesCatalog = createAction(
  ACCESSORIES_CATALOG
);

// HTTP

export const getHistories = createAction(
  GET_HISTORIES
);

export const getHistory = createAction(
  GET_HISTORY,
  (dealId: string) => ({dealId})
);

export const getHistorySuccess = createAction(
  GET_HISTORY_SUCCESS,
  (history: DealHistory) => ({history})
);

export const getHistoryFailure = createAction(GET_HISTORY_FAILURE, error => error);

export const getHistoriesSuccess = createAction(
  GET_HISTORIES_SUCCESS,
  (histories: DealHistory[]) => ({histories})
);

export const getHistoriesFailure = createAction(GET_HISTORIES_FAILURE, error => error);

export const historyUpdateApiSuccess = createAction(
  UPDATE_HISTORY_SUCCESS,
  (history: DealHistory) => ({history})
);

export const addEvent = createAction(
  ADD_EVENT,
  (event: Partial<HistoryEvent>) => event
);
export const addEventSuccess = createAction(
  ADD_EVENT_SUCCESS,
  (event: HistoryEvent) => ({event})
);
export const addEventFailure = createAction(ADD_EVENT_FAILURE, error => error);


<form [formGroup]="dealLookupForm" class="header-form">
  <div class="row">
    <div class="col-sm-6">
      <select
        class="header-form-control form-control"
        formControlName="dealIndex"
      >
        <option value="" disabled>Select A Deal</option>
        <option *ngFor="let deal of printDeals; let i = index" [value]="i">
          Stock: {{ deal.stockNumber }} {{ deal.customer.firstName }}
          {{ deal.customer.lastName }}
        </option>
      </select>
    </div>
  </div>
</form>

<div
  *ngFor="let form of formServerData; let i = index"
  class="accordion"
  id="formServerAccordion"
>
  <div class="card">
    <div class="card-header no-padding" [id]="'heading' + i">
      <h2 class="mb-0">
        <button
          class="btn btn-link btn-block text-left"
          type="button"
          data-toggle="collapse"
          [attr.data-target]="'#collapse' + i"
          aria-expanded="true"
          [attr.aria-controls]="'collapse' + i"
        >
          {{ form.Name }}
        </button>
      </h2>
    </div>

    <div
      [id]="'collapse' + i"
      class="collapse"
      [attr.aria-labelledby]="'heading' + i"
      data-parent="#formServerAccordion"
    >
      <div class="card-body">
        <table class="table">
          <thead>
          <tr>
            <th style="width: 20%">Deal Field</th>
            <th style="width: 20%">PDF Field</th>
            <th style="width: 10%">Value</th>
            <th style="width: 50%">Calculation</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let fieldValue of form.fieldValues">
            <td>{{ fieldValue.dealVariable }}</td>
            <td>{{ fieldValue.name }}</td>
            <td>
              {{ getFieldValue(fieldValue.dealVariable) }}
            </td>
            <td>{{ getCalculation(fieldValue.dealVariable) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<section class="group-list-layout">

  <button *ngFor="let group of incentiveGroups; let i = index;"
          class="btn"
          [ngClass]="isActiveGroup(i) ? 'app-btn-success' : 'app-btn-outline-success'"
          type="button"
          (click)="onSelectGroup(i)">
    {{ group.name }}
  </button>

  <div *ngIf="noGroups()"
       class="p-2 rounded text-center app-bg-light app-text-neutral">
    No Incentives Set.
  </div>

</section>

import { WarrantySettings, WarrantyUi } from "../../models/warranty-ui";

export enum WarrantyBarGraphBgClass {
  Gray1 = "warranty-ui-gray-1",
  Gray2 = "warranty-ui-gray-2",
  Gray3 = "warranty-ui-gray-3",
  Green = "warranty-ui-green",
  Blue = "warranty-ui-blue",
  RedGrad = "warranty-ui-red-gradient",
  LightStripe = "warranty-ui-light-stripe"
}

export const WARRANTY_SETTINGS: WarrantySettings = {
  plannedLengthOfOwnership: [
    {title: "3 Years", years: 3},
    {title: "4 Years", years: 4},
    {title: "5 Years", years: 5},
    {title: "6 Years", years: 6},
    {title: "7 Years", years: 7},
    {title: "8 Years", years: 8},
    {title: "9+ Years", years: 9}
  ],
  milesDrivenPerYear: [
    {title: "10,000", miles: 10000},
    {title: "12,000", miles: 12000},
    {title: "15,000", miles: 15000},
    {title: "18,000", miles: 18000},
    {title: "25,000", miles: 25000}
  ]
};

export const DEFAULT_WARRANTY_UI: WarrantyUi = {
  activeViewIndex: 0,
  maxViewIndex: 0,
  titleIndex: {
    basic: [],
    better: [],
    best: []
  },
  milesPerYear: 0,
  ownershipYears: 0,
  maxOwnershipYears: 9,
  vehicleAge: 0,
  vehicleCondition: "",
  vehicleOdometer: 0,
  productGraphs: [],
  backgroundGraphs: []
};

// controls which products show on which screens
export const WARRANTY_UI_CONFIG = {
  new: {
    maxViewIndex: 5,
    titleIndex: {basic: [1, 2], better: [3, 4], best: [5]},
    backgroundConfig: [
      {id: "OWNERSHIP", visibility: [1, 2, 3]},
      {id: "OWNERSHIP_RISK", visibility: [4, 5]}
    ],
    productConfig: [
      {
        id: "MAINTENANCEPLAN",
        visibility: {riskInfo: [2], product: [5]},
        defaults: [
          [{id: "FACTORY_MAINTENANCEPLAN", visibility: [3, 4]}]
        ]
      },
      {
        id: "SELECT",
        visibility: {riskInfo: [2, 3, 4], product: [5]},
        defaults: []
      },
      {
        id: "VSC",
        visibility: {riskInfo: [2, 3, 4], product: [5]},
        defaults: [
          [{id: "FACTORY_BASIC", visibility: [0, 1, 2, 3, 4, 5]}],
          [
            {id: "FACTORY_POWERTRAIN", visibility: [0, 1, 2, 3, 4, 5]},
            {id: "NEW_POWERTRAIN", visibility: [3, 4, 5]},
          ],
        ]
      }
    ]
  },
  certified: {
    maxViewIndex: 5,
    titleIndex: {basic: [1, 2, 3], better: [4], best: [5]},
    backgroundConfig: [
      {id: "VEHICLE_AGE", visibility: [0]},
      {id: "OWNERSHIP", visibility: [1, 2]},
      {id: "OWNERSHIP_RISK", visibility: [3, 4, 5]}
    ],
    productConfig: [
      {
        id: "MAINTENANCEPLAN",
        visibility: {riskInfo: [2, 3], product: [5]},
        defaults: [
          [{id: "FACTORY_MAINTENANCEPLAN", visibility: [4]}]
        ]
      },
      {
        id: "SELECT",
        visibility: {riskInfo: [2, 3, 4], product: [5]},
        defaults: []
      },
      {
        id: "VSC",
        visibility: {riskInfo: [2, 3, 4], product: [5]},
        defaults: [
          [{id: "FACTORY_BASIC", visibility: [0, 1, 2, 3, 4, 5]}],
          [
            {id: "FACTORY_POWERTRAIN", visibility: [0, 1, 2, 3, 4, 5]},
            {id: "CERTIFIED_POWERTRAIN", visibility: [4, 5]},
          ],
        ]
      }
    ]
  },
  used: {
    maxViewIndex: 4,
    titleIndex: {basic: [1, 2, 3], better: [], best: [4]},
    backgroundConfig: [
      {id: "VEHICLE_AGE", visibility: [0]},
      {id: "OWNERSHIP", visibility: [1, 2]},
      {id: "OWNERSHIP_RISK", visibility: [3, 4]}
    ],
    productConfig: [
      {
        id: "MAINTENANCEPLAN",
        visibility: {riskInfo: [2, 3], product: [4]},
        defaults: [],
      },
      {
        id: "SELECT",
        visibility: {riskInfo: [2, 3], product: [4]},
        defaults: []
      },
      {
        id: "VSC",
        visibility: {riskInfo: [2, 3], product: [4]},
        defaults: [
          [{id: "FACTORY_BASIC", visibility: [0, 1, 2, 3, 4, 5]}],
          [{id: "FACTORY_POWERTRAIN", visibility: [0, 1, 2, 3, 4, 5]}],
        ]
      }
    ]
  }
};


// todo: add models for typescript
export const GetDefaultWarrantyUIGraphs = () => ({
  backgroundGraphs: {
    OWNERSHIP: {
      title: "Ownership",
      bgClass: WarrantyBarGraphBgClass.Gray1,
      width: 0,
      details: [],
      visibility: []
    },
    OWNERSHIP_RISK: {
      title: "Ownership Risk",
      bgClass: WarrantyBarGraphBgClass.RedGrad,
      width: 0,
      details: [
        "Risk Responsible",
        "Repairs More Likely"
      ],
      visibility: []
    },
    VEHICLE_AGE: {
      title: "Vehicle Age",
      bgClass: WarrantyBarGraphBgClass.LightStripe,
      width: 0,
      details: [],
      visibility: []
    }
  },
  productGraphs: {
    MAINTENANCEPLAN: {
      product: {
        title: "Pre-Paid Maintenance",
        bgClass: WarrantyBarGraphBgClass.Blue,
        width: 0,
        details: [],
        productKey: "MAINTENANCEPLAN",
        term: 0,
        miles: 0,
        visibility: []
      },
      riskInfo: {
        title: "Maintenance",
        details: [
          "Oil Changes",
          "Tire Rotations",
          "Factory Scheduled Maint."
        ],
        visibility: []
      },
      defaults: []
    },
    SELECT: {
      product: {
        title: "Clear Care Elite",
        bgClass: WarrantyBarGraphBgClass.Blue,
        width: 0,
        details: [],
        productKey: "SELECT",
        term: 0,
        miles: 0,
        visibility: []
      },
      riskInfo: {
        title: "Wear & Tear",
        details: [
          "Tire & Wheel Damage",
          "Windshield Chips",
          "Dents & Dings",
        ],
        visibility: []
      },
      defaults: []
    },
    CLEAR_ELITE: {
      product: {
        title: "SELECT",
        bgClass: WarrantyBarGraphBgClass.Blue,
        width: 0,
        details: [],
        productKey: "SELECT",
        term: 0,
        miles: 0,
        visibility: []
      },
      riskInfo: {
        title: "CLEAR",
        bgClass: WarrantyBarGraphBgClass.Green,
        details: [
          "Nitrogen filled tires",
          "Repel paint protection",
          "Door Edge Guards",
        ],
        visibility: []
      },
      defaults: []
    },
    VSC: {
      product: {
        title: "Vehicle Service Agreement",
        bgClass: WarrantyBarGraphBgClass.Blue,
        width: 0,
        details: [],
        productKey: "VSC",
        term: 0,
        miles: 0,
        visibility: []
      },
      riskInfo: {
        title: "Repair Related Incidentals",
        details: [
          "Roadside Assistance & Towing",
          "Travel Costs (Meals & Lodging)",
          "Rental Car"
        ],
        visibility: []
      },
      defaults: []
    }
  },
  defaultGraphs: {
    FACTORY_MAINTENANCEPLAN: {
      title: "Maintenance",
      bgClass: WarrantyBarGraphBgClass.Gray2,
      width: 0,
      details: [
        "Oil Changes",
        "Tire Rotations",
        "Factory Scheduled Maint."
      ],
      imageSrc: "http://www.russauto.com/clear/bt/img/maintenance.png",
      term: 2 * 12,
      miles: 25000,
      visibility: []
    },
    FACTORY_BASIC: {
      title: "Basic Manufacturer Warranty",
      bgClass: WarrantyBarGraphBgClass.Gray3,
      width: 0,
      details: [
        "Covers repairs and adjustments needed to correct defects in materials and workmanship of any part supplied by the manufacturer except wear and tear items and abuse."
      ],
      term: 3 * 12,
      miles: 36000,
      visibility: []
    },
    FACTORY_POWERTRAIN: {
      title: "Manufacturer Powertrain Warranty",
      bgClass: WarrantyBarGraphBgClass.Gray2,
      width: 0,
      details: [
        "Covers repairs and adjustments needed to correct defects in materials and workmanship of the Engine, Transmission, Transaxle, and Drive Axle(s)."
      ],
      term: 5 * 12,
      miles: 60000,
      visibility: []
    },
    NEW_POWERTRAIN: {
      title: "BT New Vehicle Warranty",
      bgClass: WarrantyBarGraphBgClass.Green,
      width: 0,
      details: [],
      imageSrc: "http://www.russauto.com/clear/bt/img/warranty.png",
      term: 7 * 12,
      miles: 100000,
      visibility: []
    },
    CERTIFIED_POWERTRAIN: {
      title: "Toyota Certified Used Warranty",
      bgClass: WarrantyBarGraphBgClass.Green,
      width: 0,
      details: [],
      term: 7 * 12,
      miles: 100000,
      visibility: []
    },
    // CLEAR_CARE: {
    //   title: "CLEAR",
    //   bgClass: WarrantyBarGraphBgClass.Green,
    //   width: 0,
    //   details: [
    //     "Nitrogen filled tires",
    //     "Repel paint protection",
    //     "Door Edge Guards",
    //   ],
    //   term: 2 * 12,
    //   miles: 25000,
    //   visibility: []
    // },
    // CLEAR_ELITE: {
    //   title: "CLEAR Elite",
    //   bgClass: WarrantyBarGraphBgClass.Blue,
    //   width: 0,
    //   details: [
    //     "Nitrogen filled tires",
    //     "Repel paint protection",
    //     "Door Edge Guards",
    //   ],
    //   term: 2 * 12,
    //   miles: 25000,
    //   visibility: []
    // },
  }
});

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services";
import * as authActions from "../../store/actions/auth.actions";
import { Actions, ofType } from "@ngrx/effects";
import { map, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AlertService } from "src/app/shared-module/services";
import { environment } from "src/environments/environment";
import { User } from "../../../user-admin-module/models";
import { USER_ROLES } from "../../../app.config";
import { Router } from "@angular/router";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.scss"]
})

export class LoginComponent implements OnInit {
  private unsubscribe$ = new Subject();
  loading = false;

  loginForm: FormGroup = this.formBuilder.group({
    email: ["", Validators.required],
    password: ["", Validators.required]
  });
  environment;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private actions$: Actions,
    private alertService: AlertService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.alertService.clear();
    this.subToActionResult();
    this.environment = environment;

    //console.log("Checking User")
    // check login status
    this.authService.selectUser().pipe(
      take(1),
      map((user: User) => {
        if(user) {
          if(User.unpackSecurityGroups(user.securityGroups).includes(USER_ROLES.sys_admin)) {
            this.router.navigate(["/settings"]);
          } else if( User.unpackSecurityGroups(user.securityGroups).includes(USER_ROLES.sales_manager)){
            this.router.navigate(["/sales-manager/list"]);
        } else {
            this.router.navigate(["/clearpath/list"]);
          }
        }
      })
    ).subscribe()
    // this.authService.
  }

  onLogin() {
    this.loading = true;
    this.authService.login(this.loginForm.value);
  }

  private subToActionResult() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(authActions.loginFailure),
    ).subscribe(({error}) => {
      this.alertService.error(error || "Could not get user.");
      this.loginForm.patchValue({password: ""});
      this.loading = false;
    });
  }

  // FORM VALIDATION & HELPERS

  touchedInvalid(controlName: string): boolean {
    const touched = this.loginForm.controls[ controlName ].touched;
    const valid = this.loginForm.controls[ controlName ].valid;
    return touched && !valid;
  }

  trimInput(controlName: string) {
    const value = this.loginForm.value[ controlName ].trim();
    this.loginForm.patchValue({[ controlName ]: value});
  }
}

<app-modal-view #modal="modal" modalTitle="Current Factory Offers">
  <div modal-body>
    <div *ngFor="let vehicleIncentive of vehicleIncentives">
      <app-vehicle-incentive
        [vehicleIncentive]="vehicleIncentive"
        [vehicle]="vehicle"
        [financingSettings]="financingSettings"
        [dealIncentive]="getMatchingDealIncentive(vehicleIncentive, deal)"
        [financeOptions]="deal.financeOptions"
        [leaseOptions]="deal.leaseOptions"
        (updateDealIncentive)="onUpdateDealIncentive($event)">
      </app-vehicle-incentive>
    </div>
    <div *ngIf="vehicle.incentives.length === 0">
      There are currently no factory offers available for this vehicle.
    </div>
  </div>
</app-modal-view>

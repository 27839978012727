import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { VehicleService } from "../../../services";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-simple-payment-container",
  templateUrl: "./simple-payment-container.component.html",
  styleUrls: ["./simple-payment-container.component.scss"]
})
export class SimplePaymentContainerComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  uiState = {
    showCalculator: false
  };

  vehicleModel: string;

  constructor(private vehicleService: VehicleService) { }

  ngOnInit() {
    this.subToVehicle();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITILIZATION

  subToVehicle() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle: any) => {
        this.vehicleModel = vehicle.model;
      });
  }

  // ACTIONS

  onToggleShowCalculator() {
    this.uiState.showCalculator = !this.uiState.showCalculator;
  }

  // UI & DISPLAY

  get showCalculator(): boolean {
    return this.uiState.showCalculator;
  }

}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { combineLatest, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { DealHistory, HistoryEvent } from "src/app/clearpath-module/models/history";
import { HistoryService } from "src/app/clearpath-module/services/history.service";
import { Actions, ofType } from "@ngrx/effects";
import * as historyActions from "../../../clearpath-module/store/actions/history.actions";
import { DealService } from "../../../clearpath-module/services";
import { DealState } from "../../../clearpath-module/store/state";

@Component({
  selector: "app-activity-events",
  templateUrl: "./activity-events.component.html",
  styleUrls: ["./activity-events.component.scss"]
})
export class ActivityEventsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  private timerId: any = null;
  public dealId = "";
  public events: HistoryEvent[];
  public isLoading = true;
  public deal: DealState;
  public totalPresentationTime: string;

  constructor(
    private route: ActivatedRoute,
    private actions$: Actions,
    private historyService: HistoryService,
    private router: Router,
    private dealService: DealService,
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.dealId = params.dealId;
        this.dealService.dispatchGetByDealId(this.dealId);
      });

    combineLatest([
      this.dealService.selectDeal(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal]: [DealState]) => {
        if (deal.dealId === this.dealId) {
          this.deal = deal;
        }
      });

    this.actions$.pipe(
      ofType(historyActions.getHistorySuccess),
      takeUntil(this.unsubscribe$),
      map((value: any) => {
          if (value?.history?.events?.length) {
            const startTime = new Date(value?.history?.events[ 0 ].createdAt);
            const endTime = new Date(value?.history?.events[ value?.history?.events?.length - 1 ].createdAt);
            this.totalPresentationTime = this.formatDateDifference(startTime, endTime);
            this.events = value?.history?.events?.reverse();
          }
          this.isLoading = false;
          this.startAutoRefresh();
        }
      )
    ).subscribe();
  }

  startAutoRefresh() {
    // Clear any existing timer
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    // Set a new timer
    this.timerId = setTimeout(() => {
      this.timerId = null;
      this.refresh();
    }, 10000);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refresh() {
    this.isLoading = true;
    this.historyService.dispatchGetHistory(this.dealId);
  }

  viewDeal() {
    this.router.navigate([`/sales-manager/writeup/${this.dealId}`]).then();
  }

  formatDateDifference(startDate, endDate) {
    // Calculate the difference in milliseconds
    let difference = endDate - startDate;

    // Calculate the time components
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;

    const days = Math.floor(difference / msInDay);
    difference -= days * msInDay;

    const hours = Math.floor(difference / msInHour);
    difference -= hours * msInHour;

    const minutes = Math.floor(difference / msInMinute);
    difference -= minutes * msInMinute;

    const seconds = Math.floor(difference / msInSecond);

    // Format the result
    let result = '';
    if (days > 0) {
      result += days + 'd ';
    }
    if (hours > 0 || result) {
      result += hours + 'h ';
    }
    if (minutes > 0 || result) {
      result += minutes + 'm ';
    }
    result += seconds + 's';

    return result.trim();
  }
}

import { User } from "../../../user-admin-module/models/user";

export interface AuthState {
  user: User;
  token: string;
}

export const initialAuthState: AuthState = {
  user: null,
  token: ""
};

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { CPOResidualInput, ResidualValue, TermValue } from "../../../models";
import { AlertService } from "../../../../shared-module/services";
import { ParsingService } from "../../../services";
import * as Papa from "papaparse";

@Component({
  selector: "app-upload-cert-used-residuals",
  templateUrl: "./upload-cert-used-residuals.component.html",
  styleUrls: ["./upload-cert-used-residuals.component.scss"]
})
export class UploadCertUsedResidualsComponent {

  @Input() waitOnAction: boolean;
  @Output() unsavedChanges = new EventEmitter<boolean>();
  @Output() submitResiduals = new EventEmitter<ResidualValue[]>();

  @ViewChild("certUsedResidualsCSV", {static: false}) certUsedResidualsCSV: ElementRef;

  residuals: ResidualValue[];

  constructor(
    private alertService: AlertService,
    private parsingService: ParsingService
  ) { }

  // ACTIONS

  fileEvent(event) {
    const file = event.target.files[ 0 ];
    this.readFile(file);
  }

  readFile(file: File) {
    const results = Papa.parse(file, {
      header: true,
      complete: ({data, errors}) => {
        const cpoResiduals = this.constructNewResiduals(data);
        // this.onSubmitResiduals(cpoResiduals);
        this.residuals = cpoResiduals;
      },
      error: (e) => {
        //console.log("e:", e);
      }
    });
  }

  onSubmitResiduals(residuals: ResidualValue[]) {
    this.submitResiduals.emit(residuals);
  }

  // UI & RENDERING

  constructNewResiduals(data: CPOResidualInput[]): ResidualValue[] {
    const cpoResiduals: ResidualValue[] = [];

    data.forEach((el: CPOResidualInput) => {
      const values: TermValue[] = [];
      if (!this.parsingService.isValidModelNumber(el.modelNum)) {
        return;
      }
      [24, 36, 48, 60].forEach(term => {
        values.push({
          term,
          value: parseInt(el[ `${term}mo` ], 10)
        });
      });
      cpoResiduals.push({
        modelNumber: el.modelNum,
        year: parseInt(el.year, 10),
        values
      });
    });

    return cpoResiduals;

  }

}


import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CalculationService, DealService } from "../../services";
import { InsuranceProduct } from "../../models";
import { Observable, Subject } from "rxjs";
import { CashInsuranceProductKeys } from "../../models/insurance";
import { takeUntil } from "rxjs/operators";
import { DealState } from "../../store/state";

@Component({
  selector: "app-buy-box-cash",
  templateUrl: "./buy-box-cash.component.html",
  styleUrls: ["./buy-box-cash.component.scss"]
})
export class BuyBoxCashComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject();
  @Input() insuranceProducts: InsuranceProduct[] = [];
  @Input() selectedProducts: InsuranceProduct[] = [];
  @Output() submitDisabledProducts = new EventEmitter<InsuranceProduct[]>();
  @Input() disabledProducts: InsuranceProduct[] = [];
  totalVehicleCashPrice$: Observable<number>;
  initialSelectedProducts: InsuranceProduct[] = [];
  vehicleBaseFinanceAmount;
  interestRate: number;
  deal: DealState;

  constructor(
    private calculationService: CalculationService,
    private dealService: DealService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.initialSelectedProducts = this.selectedProducts;
    this.subToDeal();
    this.calculationService.vehicleBaseFinanceAmount$({finance: true})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicleBaseFinanceAmount: number) => {
        this.vehicleBaseFinanceAmount = vehicleBaseFinanceAmount;
      });
  }

  ngOnChanges() {
    this.initData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subToDeal() {
    this.dealService.selectDeal()
      .pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe((deal: DealState) => {
      this.deal = deal;
      this.initData();
    });
  }

  initData() {
    if (this.deal) {
      this.totalVehicleCashPrice$ = this.calculationService.totalVehicleCashPrice$();
      this.insuranceProducts = this.filterCashProducts(this.insuranceProducts);
      this.insuranceProducts = this.dealService.dealInsuranceService.orderInsuranceProducts(this.insuranceProducts);
      this.selectedProducts = this.deal.insuranceProducts || [];
      this.initialSelectedProducts = this.selectedProducts;
    }
  }

  // UI CONTROL & RENDERING

  onSelectPaymentPlan() {
    this.dealService.dispatchSetDealType("cash");
    this.dealService.dispatchSetFinanceOptions({
      customSelected: false
    });
    this.router.navigate([{outlets: {modal: "submit-writeup"}}]);
  }

  paymentPlanSelected(): boolean {
    return this.deal.financeOptions.cashPurchase;
  }

  productSelected(productKey: string): boolean {
    const product = (this.selectedProducts || []).find(item => {
      return item.productKey === productKey;
    });
    return product ? true : false;
  }

  filterCashProducts(insuranceProducts: InsuranceProduct[]): InsuranceProduct[] {
    return insuranceProducts.filter(product => {
      return Object.values(CashInsuranceProductKeys)
        .includes(product.productKey);
    });
  }

  enableProduct(product: InsuranceProduct) {
    return;
    // const disabledProduct = this.disabledProducts.find((disabledProduct: InsuranceProduct) => {
    //   return disabledProduct.productKey === product.productKey;
    // });
    // if (!disabledProduct) {
    //   this.dealService.Insurance.enableUninitializedProduct(
    //     this.insuranceProducts,
    //     product
    //   );
    //   return;
    // }
    // const disabledProductIndex = this.disabledProducts.findIndex((disProduct: InsuranceProduct) => {
    //   return product.productKey === disProduct.productKey;
    // });
    // if (disabledProductIndex >= 0) {
    //   this.disabledProducts.splice(disabledProductIndex, 1);
    // }
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.submitInsuranceProduct(disabledProduct, disabledProduct.selectedTerm);
  }

  disableProduct(product: InsuranceProduct) {
    return;
    // const selectedProduct = this.selectedProducts.find(item => {
    //   return item.productKey === product.productKey;
    // });
    // this.disabledProducts.push(selectedProduct);
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.removeInsuranceProduct(product);
  }

  productPrice(product: InsuranceProduct): number {
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return 0; }
    const {selectedTerm} = selectedProduct;
    const termCost = product.termCosts[ selectedTerm ];
    return termCost ? termCost.price : 0;
  }

  productDescription(product: InsuranceProduct): string {
    const {productKey, termCosts} = product;
    const selectedProduct = (this.selectedProducts || [])
      .find(item => item.productKey === productKey);
    if (!selectedProduct) { return ""; }

    const {selectedTerm} = selectedProduct;
    const termCost = termCosts[ selectedTerm ];
    if (product.productKey.toLowerCase() === "gap") {
      // Issue #1958: Remove GAP product description
      // return "For the life of the loan";
      return "";
    }
    if (termCost) {
      return `${termCost.term / 12} years / ${termCost.miles} miles`;
    } else {
      return "";
    }
  }

  showFinanceVsInvest = () => this.router.navigate([{
    outlets: {modal: "finance-vs-cash"}
  }]).then();
}

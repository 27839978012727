// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name: "production",
  qa: false,
  production: true,
  apiUrl: "https://northstar-prod.herokuapp.com",
  dealerImagePath: "/static/images/svg/Beaverton-Toyota-Logo.svg",
  dealershipTitle: "Beaverton Toyota",
  pubNubPubKey: "pub-c-081d3bc1-365b-40fe-9496-b8b17b3e6e90",
  pubNubSubKey: "sub-c-1a783bab-02e9-446f-905e-c48a13a1235b",
  salesQueueChannel: "salesQueueChannel",
  rayGunApiKey: "",
  msgChannel: "messageChannel",
  useHash: false,
  firebase: {
    apiKey: "AIzaSyDpFFYynX14u0Oy1BxOIkf-Vzt_-rn8H8k",
    authDomain: "clearpath-cloud.firebaseapp.com",
    projectId: "clearpath-cloud",
    storageBucket: "clearpath-cloud.appspot.com",
    signatureStorage: "production/deal_submit_signatures"
  }
};

import { FinanceOptions } from "../../../models";
import { LeaseOptions } from "src/app/clearpath-module/models/calculations";
import { Lender } from "src/app/sales-manager-module/models/data";
import { PLATE_TRANSFER_FEE } from "../../../../app.config";

export const initialFinanceOptionsState: FinanceOptions = {
  financeSelected: false,
  incentiveSelected: false,
  customSelected: false,
  aprSubventionDisabled: true,
  daysToFirstPay: 0,
  desiredPayment: 0,
  numberOfMonthlyPayments: 0,
  selectedCreditTier: 1,
  creditTierSelected: false,
  selectedFinancingTerm: 0,
  monthlyPayment: 0,
  downPayment: 0,
  defaultDownPayment: 0,
  downPaymentZero: false,
  finalVehiclePrice: null,
  totalFinanced: null,
  docFees: 0,
  customSalesTaxRate: 0,
  activeInterestRate: 0,
  customerProvidedInterestRate: 0,
  customerProvidedFinancingTerm: 0,
  eFilingFee: 0,
  cashPurchase: false,
  amountFinanced: 0,
  countyFee: 0,
  titleFee: 0,
  totalStateFees: 0,
  regFee: 0,
  plateTransfer: false,
  plateTransferFeeWhenApplied: PLATE_TRANSFER_FEE,
  totalFees: 0,
  privilegeTax: 0,
  totalStateTaxes: 0,
  salesTax: 0,
  catTax: 0,
  totalTax: 0,
  totalRegFee: 0,
  vehicleLicRegFee: 0,
  totalTitleRegFee: 0,
  dmvTotalFees: 0,
  oadaOtherFees: 0,
  oadaOtherDesc: "",
  oadaTotalOtherCharges: 0,
  oadaTotalCashSalePrice: 0,
  oadaOtherPrice: 0,
  totalDownRebate: 0,
  totalDownRebateLabel: "",
  totalDownRebateTrade: 0,
  totalDownRebateNonNegTrade: 0,
  tradeInValueTotal: 0,
  tradeInPayoffTotal: 0,
  tradeInTotalNegativeEquity: 0,
  totalRetailPrice: 0,
  otherCharges: 0,
  totalSalePrice: 0,
  dealerFees: 0,
  paymentTotal: 0,
  paymentFinanceCharge: 0,
  firstPaymentDue: "",
  unpaidCashSalePrice: 0,
  amountDue: 0,
  amountFinancedPB: 0,
  selectedRebateValue: 0,
  catPrice: 0,
  catCostAdjust: 0,
  catAdjustedSale: 0,
  msr: 0,
  retail: 0,
  discount: 0
};

export const initialFinanceOptionsEditsState: FinanceOptions = {
  financeSelected: false,
  incentiveSelected: false,
  customSelected: false,
  aprSubventionDisabled: true,
  daysToFirstPay: 0,
  desiredPayment: null,
  numberOfMonthlyPayments: 0,
  selectedCreditTier: 1,
  creditTierSelected: false,
  selectedFinancingTerm: 0,
  monthlyPayment: null,
  downPayment: null,
  defaultDownPayment: null,
  downPaymentZero: false,
  totalTitleRegFee: 0,
  finalVehiclePrice: null,
  totalFinanced: null,
  docFees: null,
  customSalesTaxRate: null,
  activeInterestRate: null,
  customerProvidedInterestRate: null,
  customerProvidedFinancingTerm: null,
  eFilingFee: null,
  cashPurchase: false,
  amountFinanced: null,
  countyFee: null,
  titleFee: null,
  regFee: null,
  totalStateFees: null,
  plateTransfer: false,
  plateTransferFeeWhenApplied: 0,
  totalFees: null,
  privilegeTax: null,
  totalStateTaxes: null,
  salesTax: null,
  catTax: null,
  totalTax: null,
  totalRegFee: null,
  vehicleLicRegFee: null,
  dmvTotalFees: null,
  oadaOtherFees: null,
  oadaOtherDesc: "",
  oadaTotalOtherCharges: null,
  oadaTotalCashSalePrice: null,
  oadaOtherPrice: null,
  totalDownRebate: null,
  totalDownRebateLabel: "",
  totalDownRebateTrade: null,
  totalDownRebateNonNegTrade: 0,
  tradeInValueTotal: 0,
  tradeInPayoffTotal: 0,
  tradeInTotalNegativeEquity: 0,
  totalRetailPrice: null,
  otherCharges: null,
  totalSalePrice: null,
  dealerFees: null,
  paymentTotal: null,
  paymentFinanceCharge: null,
  firstPaymentDue: "",
  unpaidCashSalePrice: null,
  amountDue: null,
  amountFinancedPB: null,
  selectedRebateValue: null,
  catPrice: null,
  catCostAdjust: null,
  catAdjustedSale: null,
  msr: null,
  retail: null,
  discount: null
};

export const initialLienHolderState: Lender = {
  name: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  titleAddress: "",
  titleCity: "",
  titleState: "",
  titlePostalCode: "",
  id: "",
  insuranceAddress: "",
  insuranceCity: "",
  insuranceState: "",
  insurancePostalCode: "",
};

export const initialLeaseOptionsState: LeaseOptions = {
  leaseSelected: false,
  prepaidMonths: 0,
  prepaidMiles: 0,
  selectedLeaseTerm: 0,
  customSalesTaxRate: 0,
  monthlyPayment: 0,
  additionalCashDue: 0,
  rateMarkup: 0,
  acquisitionFee: 0,
  leaseTerms: [],
  residualValue: 0,
  capReduction: 0,
  grossCapCost: 0,
  activeMoneyFactor: 0,
  subventionCashOverride: 0,
  subventionCashDisabled: true,
};

import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { Lender } from "src/app/sales-manager-module/models/data";
import { LENDERS } from "src/app/sales-manager-module/data/lenders";
import { DealService } from "src/app/clearpath-module/services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { initialLienHolderState } from "../../../../clearpath-module/store/state/deal/financing";

@Component({
  selector: "app-lender-select",
  templateUrl: "./lender-select.component.html",
  styleUrls: ["./lender-select.component.scss"]
})
export class LenderSelectComponent implements OnInit, OnChanges {
  @ViewChild('lenderSelect') lenderSelect!: ElementRef;
  @Input() lender: Lender;
  @Input() flagChange;
  @Input() lenderName: string;
  public selectedLenderIndex: number;
  public selectedLenderIndexBuffer: number;
  customLienHolderSelected: boolean;
  toStr = JSON.stringify;

  customLienHolderForm: FormGroup = this.formBuilder.group({
    name: [""],
    phone: [""],
    address: [""],
    city: [""],
    state: [""],
    zip: [""],
    id: [""]
  });

  constructor(
    private dealService: DealService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.selectedLenderIndex = this.lenders.findIndex(lender => {
      return lender.id === this.lender.id && lender.name === this.lender.name;
    });
    if (this.selectedLenderIndex < 0 && this.lender.name) {
      this.customLienHolderSelected = true;
    }
    this.customLienHolderForm.patchValue(this.lender);
  }

  clearLienHolder() {
    // console.log("Clearing Lien Holder...");
    this.lender = {...initialLienHolderState};
    this.dealService.dispatchSetLienHolder(this.lender);
    this.selectedLenderIndex = -1;
    this.lenderSelect.nativeElement.value = "";
    this.flagChange();
  }

  get lenders(): Lender[] {
    return LENDERS;
  }

  selectLender(i) {
    this.selectedLenderIndex = parseInt(i, 10);
    this.dealService.dispatchSetLienHolder(this.lenders[ this.selectedLenderIndex ]);
    this.flagChange();
  }

  selectCustomLienHolder() {
    // save the current lender index in case the user cancels the custom lienholder entry
    this.selectedLenderIndexBuffer = this.selectedLenderIndex;
    this.customLienHolderForm.reset();
    this.submitCustomLienHolderForm(true);
    this.customLienHolderSelected = !this.customLienHolderSelected;
  }

  cancelSelectCustomLienHolder() {
    this.customLienHolderSelected = false;
    this.customLienHolderForm.reset();
    this.submitCustomLienHolderForm(true);
    // if there is an pre-existing selected lender index, select that lender when custom lender is canceled
    if (this.selectedLenderIndexBuffer > -1) {
      this.selectLender(this.selectedLenderIndexBuffer);
    }
  }

  submitCustomLienHolderForm = (override: boolean) => {

    const {pristine, invalid, value} = this.customLienHolderForm;
    if (pristine || invalid) {
      if (!override) {
        return;
      }
    }

    const lender: Lender = {
      name: value.name || "",
      phone: value.phone || "",
      address: value.address || "",
      city: value.city || "",
      state: value.state || "",
      zip: value.zip || "",
      id: value.id || "",
      titleAddress: "",
      titleCity: "",
      titleState: "",
      titlePostalCode: "",
      insuranceAddress: "",
      insuranceCity: "",
      insuranceState: "",
      insurancePostalCode: ""
    };

    this.dealService.dispatchSetLienHolder(lender);
    this.customLienHolderForm.markAsPristine();
    this.flagChange();
  };
}

import { Injectable } from "@angular/core";
import { Vehicle } from "../models";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CnaService {

  _firstName: string = "";
  _lastName: string = "";

  private firstNameSource = new BehaviorSubject<any>(this._firstName);
  public firstName$ = this.firstNameSource.asObservable();

  private lastNameSource = new BehaviorSubject<any>(this._lastName);
  public lastName$ = this.lastNameSource.asObservable();

  get firstName() {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
    this.firstNameSource.next(value);
  }

  get lastName() {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
    this.lastNameSource.next(value);
  }

  stockNumber: string;

  vehicle: Vehicle;

  allOptions = [
    "Safety",
    "Performance",
    "Dependability",
    "Resale Value",
    "Expenses",
    "Appearance",
    "Convenience"
  ];

  selectedOptions = [];

  public resetForm() {
    this.firstName = "";
    this.lastName = "";
    const buffer = this.selectedOptions;
    buffer.forEach(item => this.allOptions.push(item));
    this.selectedOptions = [];
  }
}

import { createReducer, on } from "@ngrx/store";
import * as historyActions from "../actions/history.actions";
import { initialHistoryState } from "../state/history";

export const historyReducer = createReducer(
  initialHistoryState,
  on(historyActions.getHistorySuccess, (state, {history}) => {
    return history;
  })
);

export const historiesReducer = createReducer(
  [],
  on(historyActions.getHistoriesSuccess, (state, {histories}) => {
    return histories;
  }),
);


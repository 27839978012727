import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { CalculationService } from "../../../services/calculations/calculation.service";
import { DealService } from "../../../services/deal/deal.service";
import { FinanceOptions } from "../../../models";

@Component({
  selector: "app-simple-payment-calculator",
  templateUrl: "./simple-payment-calculator.component.html",
  styleUrls: ["./simple-payment-calculator.component.scss"]
})
export class SimplePaymentCalculatorComponent implements OnInit, OnDestroy {

  monthlyPayment: number;

  simplePaymentForm: FormGroup = this.formBuilder.group({
    leaseResidual: [0, Validators.required],
    interestRate: [5.79, Validators.required],
    loanTerm: [48, Validators.required]
  });

  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private calculationService: CalculationService,
    private dealService: DealService
  ) { }

  ngOnInit() {
    this.subToFormChanges();
    this.subToFinanceOptions();
    this.onFormChange(this.simplePaymentForm);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private subToFormChanges() {
    this.simplePaymentForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_ => {
        this.onFormChange(this.simplePaymentForm);
      }));
  }

  private subToFinanceOptions() {
    this.dealService.selectFinanceOptions()
      .pipe(take(1))
      .subscribe((financeOptions: FinanceOptions) => {
        const {numberOfMonthlyPayments, customerProvidedInterestRate} = financeOptions;
        if (numberOfMonthlyPayments) {
          this.simplePaymentForm.patchValue({loanTerm: numberOfMonthlyPayments});
        }
        if (customerProvidedInterestRate) {
          this.simplePaymentForm.patchValue({interestRate: customerProvidedInterestRate});
        }
      });
  }

  // ACTIONS

  onFormChange(simplePaymentForm: FormGroup) {
    if (!simplePaymentForm.valid) { return; }

    const {leaseResidual, loanTerm, interestRate} = simplePaymentForm.value;

    this.monthlyPayment = this.calculationService.calculateMonthlyPayment(
      leaseResidual, loanTerm, interestRate / 100
    );
  }

  // FORM VALIDATION & HELPERS

  touchedInvalid(controlName: string): boolean {
    const touched = this.simplePaymentForm.controls[ controlName ].touched;
    const valid = this.simplePaymentForm.controls[ controlName ].valid;
    return touched && !valid;
  }

}

import { Component, Input, OnChanges } from "@angular/core";
import { ResidualValue } from "../../../models/lease-residuals";

@Component({
  selector: "app-cert-used-residuals-table",
  templateUrl: "./cert-used-residuals-table.component.html",
  styleUrls: ["./cert-used-residuals-table.component.scss"]
})
export class CertUsedResidualsTableComponent implements OnChanges {

  @Input() residuals: ResidualValue[];

  headers: string[];

  constructor() { }

  ngOnChanges() {
    this.headers = this.constructTableHeaders(this.residuals);
  }

  constructTableHeaders(residuals: ResidualValue[]): string[] {
    if (!residuals || residuals.length === 0) { return; }
    const headers = ["Model #", "Model Year"];
    residuals[ 0 ].values.forEach(el => {
      headers.push(el.term.toString());
    });
    return headers;
  }

}


import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dealFilter"
})
export class DealFilterPipe implements PipeTransform {
  transform(deals: any[], searchText: string): any[] {
    if (!deals) { return []; }
    if (!searchText) { return deals; }

    const searchTextArray = searchText.toLowerCase().split(" ");

    return deals.filter(deal => {
      const {
        customer,
        coSigner,
        stockNumber,
        dealId,
        vehicle,
        salesPerson,
        salesManager,
        status
      } = deal;
      let match = 0;

      searchTextArray.forEach(field => {
        const customerNameMatch = (
          customer.firstName.toLowerCase().includes(field) ||
          customer.middleName.toLowerCase().includes(field) ||
          customer.lastName.toLowerCase().includes(field)
        );
        const coSignerNameMatch = (
          coSigner?.firstName?.toLowerCase().includes(field) ||
          coSigner?.middleName?.toLowerCase().includes(field) ||
          coSigner?.lastName?.toLowerCase().includes(field)
        );
        const stockNumberMatch = stockNumber.toLowerCase().includes(field);
        const dealIdMatch = dealId.toLowerCase().toLowerCase().includes(field);
        const vehicleMatch = vehicle.toLowerCase().includes(field);
        const salesPersonMatch = salesPerson.toLowerCase().includes(field);
        const salesManagerMatch = salesManager.toLowerCase().includes(field);
        const statusMatch = status.toLowerCase().includes(field);

        if (
          customerNameMatch ||
          coSignerNameMatch ||
          dealIdMatch ||
          stockNumberMatch ||
          vehicleMatch ||
          salesPersonMatch ||
          salesManagerMatch ||
          statusMatch
        ) {
          match++;
        }
      });

      return match === searchTextArray.length;
    });
  }
}

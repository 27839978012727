<h2 class="h4">
  Why Lease?
</h2>

<p>Create a better ownership experience by managing your {{vehicleModel}}'s depreciation.</p>

<div class="row">
  <section class="col-md-6">
    <h2 class="h4">
      Purchase and Ownership
    </h2>

    <ul class="pl-4">
      <li class="mb-3">
        You can significantly reduce your monthly payment by paying only for the part of the {{vehicleModel}} you will use, not the whole thing.
      </li>
      <li class="mb-3">
        Having trouble committing or wish you could have the latest car like you have the latest smartphone? Delay the scary purchase decision 24 or 36 months.
      </li>
      <li class="mb-3">
        While you drive, your {{vehicleModel}} is going to depreciate. Some of these factors you cannot control such as accidents, the economy, market conditions, fuel prices, vehicle reviews or how a newer model affects your vehicles resale value. <strong>When you lease, you are only responsible for your vehicle's mileage and condition.</strong> That's it.
      </li>
    </ul>
  </section>

  <section class="col-md-6">
    <h2 class="h4">
      Options at Lease End
    </h2>

    <ol class="pl-4">
      <li class="mb-3">
        <strong>Love your {{vehicleModel}}?</strong> You can keep it. We'll help you buy it!
        <a [routerLink]="" (click)="onToggleShowCalculator()">Estimate your post-lease payment</a>
      </li>
      <li class="mb-3">
        <strong>Like the {{vehicleModel}}?</strong> You can extend the lease if you're not sure what's next.
      </li>
      <li class="mb-3">
        <strong>Cash out.</strong> If your {{vehicleModel}} is worth more than its residual value, you can buy it, sell it and pocket the profit.
      </li>
      <li class="mb-3">
        <strong>Trade it in.</strong> Use the equity of your leased {{vehicleModel}} towards your next purchase if it's worth more than the residual value.
      </li>
      <li class="mb-3">
        <strong>Walk away.</strong> Did the market not work out in your favor? Hand in the keys and walk away with no negative equity and no hassle of trying to sell.
      </li>
    </ol>
  </section>
</div>

<div class="app-page-container">
  <div *ngIf="hash=='#/cna/list'" class="search-header">
    <h2>CNA / Custom Inventory Filters</h2>
    <hr>
    <div class="cna-container">
      <ng-container *ngFor="let cna of cnas;let i = index;">
        <div class="cna-box">
          <div class="cna-question">{{cna.question}}</div>
          <div class="options-container">
            <ng-container *ngFor="let option of cna.options;let j = index;">
              <div class="option-item">
                <input type="checkbox" [id]="'o'+i+'-'+j" [value]="option.label" class="css-checkbox" [checked]="option.selected">
                <label [for]="'o'+i+'-'+j">{{option.label}}</label>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="hash=='#/inventory/list'">

    <form [formGroup]="searchForm" style="padding: 20px 20px 0 20px ">
      <h1>Search Inventory <span style="font-weight:normal">(Showing {{filteredVehicles?.length}} matches of  {{vehicles?.length}} total) </span></h1>

      <div style="display: flex;column-gap:10px;width:100%;justify-content: stretch;flex-wrap: wrap">

        <mat-form-field appearance="fill">
          <mat-label>Condition</mat-label>
          <mat-select formControlName="conditionFilter">
            <mat-option value="all">
              Show All
            </mat-option>
            <mat-option *ngFor="let conditionFilter of conditionFilters" [value]="conditionFilter">
              {{conditionFilter}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field appearance="fill">
          <mat-label>Make</mat-label>
          <mat-select formControlName="makeFilter">
            <mat-option value="all">
              Show All
            </mat-option>
            <mat-option *ngFor="let makeFilter of makeFilters" [value]="makeFilter">
              {{makeFilter}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <!--mat-form-field appearance="fill" >
        <mat-label>Color</mat-label>
        <mat-select formControlName="colorFilter">
          <mat-option value="all">
            Show All
          </mat-option>
          <mat-option *ngFor="let colorFilter of colorFilters" [value]="colorFilter">
            {{colorFilter}}
          </mat-option>
        </mat-select>
      </mat-form-field-->


        <mat-form-field appearance="fill">
          <mat-label>Type</mat-label>
          <mat-select formControlName="typeFilter">
            <mat-option value="all">
              Show All
            </mat-option>
            <mat-option *ngFor="let typeFilter of typeFilters" [value]="typeFilter">
              {{typeFilter}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Miles</mat-label>
          <mat-select formControlName="mileFilter">
            <mat-option value="all">
              Show All
            </mat-option>
            <mat-option *ngFor="let mileFilter of mileFilters" [value]="mileFilter">
              {{mileFilter}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Price</mat-label>
          <mat-select formControlName="priceFilter">
            <mat-option value="all">
              Show All
            </mat-option>
            <mat-option *ngFor="let priceFilter of priceFilters" [value]="priceFilter">
              {{priceFilter}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Sort</mat-label>
          <mat-select formControlName="sortOption">
            <mat-option *ngFor="let sortOption of sortOptions" [value]="sortOption">
              {{sortOption}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </form>


    <section class="tile-holder">
      <ng-container *ngFor="let vehicle of filteredVehicles">
        <div class="tile">
          <div style="display: flex;align-items:flex-end;justify-content: space-between">
            <h4 style="margin:0;padding:0">{{getConditon(vehicle.isUsed, vehicle.isCertified)}}</h4>
            <div class="matchCount" style="margin:0;padding:0">Retail: {{vehicle.retail  | currency:'USD':'symbol':'1.0-0'}}</div>
          </div>
          <hr style="width: 100%">
          <img [src]="getVehicleImage(vehicle)" style="padding:20px 0"/>
          <hr style="width: 100%">
          <h4>{{vehicle.make}} - {{vehicle.model}}</h4>
          <div style="margin-top:15px;display:flex;justify-content: space-between">
            <div class="priceRange">Year: {{vehicle.year}}</div>
            <div class="priceRange">Miles: {{vehicle.odometer}}</div>
          </div>
          <div style="margin-top:5px;display:flex;justify-content: space-between">
            <div class="priceRange">Trim: {{vehicle.trim}}</div>
            <div class="priceRange">Type: {{vehicle.bodyStyle}}</div>
          </div>
          <div style="margin-top:5px;display:flex;justify-content: space-between">
            <div class="priceRange">Stock #: {{vehicle.stockNumber}}</div>
            <div class="priceRange">MPG: {{vehicle.mpg}}</div>
          </div>
          <div class="spacer"></div>
          <button
            (click)="this.selectedVehicle=vehicle"
            data-toggle="modal"
            data-target="#inventory-item-modal">
            Vehicle Details
          </button>
        </div>
      </ng-container>
    </section>
  </ng-container>
  <!--section class="tile-holder">
    <ng-container *ngFor="let model of models">
      <div class="tile">
        <img [src]="'/static/images/vehicles/models/'+model.image">
        <h4>{{model.name}}</h4>
        <div class="spacer"></div>
        <div class="matchCount">8 available</div>
        <div class="priceRange">$23,945 - $48,406</div>
        <button
          data-toggle="modal"
          data-target="#inventory-item-modal"
          (click)="viewInventory(model)">
          View Inventory
        </button>
      </div>
    </ng-container>
  </section-->

</div>


<div *ngIf="selectedVehicle" id="inventory-item-modal" class="modal fade inventory-item-modal">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <button
        class="close"
        style="position: absolute; top: 0.25rem; right: 0.5rem"
        type="button"
        data-dismiss="modal"
        #closeModal>
        <span>&times;</span>
      </button>
      <h3>Vehicle Details</h3>
      <hr>

      <div style="display: flex;align-items: flex-start">
        <div style="width:50%">

          <h2>{{selectedVehicle.year}} {{selectedVehicle.make}} {{selectedVehicle.model}}</h2>

          <div style="margin-top:15px;display:flex;flex-direction: column">
            <div class="priceRange">Year: {{selectedVehicle.year}}</div>
            <div class="priceRange">Miles: {{selectedVehicle.odometer}}</div>
            <div class="priceRange">Trim: {{selectedVehicle.trim}}</div>
            <div class="priceRange">Type: {{selectedVehicle.bodyStyle}}</div>
            <div class="priceRange">Stock #: {{selectedVehicle.stockNumber}}</div>
            <div class="priceRange">MPG: {{selectedVehicle.mpg}}</div>
          </div>

          <div *ngIf="getVehicleDescription(selectedVehicle)">
            <br>
            <h5>Description</h5>
            <hr>
            <div [innerHTML]="getVehicleDescription(selectedVehicle)"></div>
            <hr>
          </div>

          <div *ngIf="getVehicleDetails(selectedVehicle)">
            <br>
            <h5>Additional Details</h5>
            <hr>
            <div [innerHTML]="getVehicleDetails(selectedVehicle)"></div>
            <hr>
          </div>

          <div style="width:300px;display: flex;gap:20px;">
            <button
              class="green"
              data-dismiss="modal"
              #closeModal
              (click)="createWriteup()">
              Create Writeup
            </button>

            <button
              class="green"
              data-dismiss="modal"
              #closeModal>
              Close
            </button>

          </div>


        </div>
        <img [src]="getVehicleImage(this.selectedVehicle)" style="width:50%;padding:20px 0"/>
      </div>


      <!--

      <ng-container *ngIf="selectedModel">
        <section class="model-info">
          <div class="stats">
            <h1>{{selectedModel.name}}</h1>
            <div class="spacer"></div>
            <div class="matchCount">8 available</div>
            <div class="priceRange">$23,945 - $48,406</div>
          </div>
          <img [src]="'/static/images/vehicles/models/'+selectedModel.image">
        </section>
        <hr>


        <section class="data-table-container">
          <table class="data-table mx-auto w-100">
            <tr>
              <th class="selectable" style="width: auto;" [ngClass]="{ 'selected': isActiveSort('stockNumber') }" (click)="onSelectSort('stockNumber')">
                <div class="d-flex align-items-center">Stock #
                  <i *ngIf="isActiveSort('stockNumber')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="width: max-content;" [ngClass]="{ 'selected': isActiveSort('year') }" (click)="onSelectSort('year')">
                <div class="d-flex align-items-center">Year
                  <i *ngIf="isActiveSort('year')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('make') }" (click)="onSelectSort('make')">
                <div class="d-flex align-items-center">Make
                  <i *ngIf="isActiveSort('make')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('model') }" (click)="onSelectSort('model')">
                <div class="d-flex align-items-center">Model
                  <i *ngIf="isActiveSort('model')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px;" [ngClass]="{ 'selected': isActiveSort('trim') }" (click)="onSelectSort('trim')">
                <div class="d-flex align-items-center">Trim
                  <i *ngIf="isActiveSort('trim')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 300px;" [ngClass]="{ 'selected': isActiveSort('engine') }" (click)="onSelectSort('engine')">
                <div class="d-flex align-items-center">Engine
                  <i *ngIf="isActiveSort('engine')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 300px;" [ngClass]="{ 'selected': isActiveSort('transmission') }" (click)="onSelectSort('transmission')">
                <div class="d-flex align-items-center">Transmission
                  <i *ngIf="isActiveSort('transmission')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

             <th class="selectable"
              style="min-width: 75px;"
                  [ngClass]="{ 'selected': isActiveSort('exteriorColor') }"
                  (click)="onSelectSort('exteriorColor')">
                <div class="d-flex align-items-center">
                  Color
                  <i *ngIf="isActiveSort('exteriorColor')"
                     class="material-icons">
                    {{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}
                  </i>
                </div>
              </th>

              <th class="selectable" style="min-width: 75px;" [ngClass]="{ 'selected': isActiveSort('odometer') }" (click)="onSelectSort('odometer')">
                <div class="d-flex align-items-center">Mileage
                  <i *ngIf="isActiveSort('odometer')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 75px;" [ngClass]="{ 'selected': isActiveSort('msr') }" (click)="onSelectSort('msr')">
                <div class="d-flex align-items-center">Price
                  <i *ngIf="isActiveSort('msr')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 75px;" [ngClass]="{ 'selected': isActiveSort('retail') }" (click)="onSelectSort('retail')">
                <div class="d-flex align-items-center">Retail
                  <i *ngIf="isActiveSort('retail')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 75px" [ngClass]="{ 'selected': isActiveSort('type') }" (click)="onSelectSort('type')">
                <div class="d-flex align-items-center">Type
                  <i *ngIf="isActiveSort('type')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

              <th class="selectable" style="min-width: 150px" [ngClass]="{ 'selected': isActiveSort('lot') }" (click)="onSelectSort('lot')">
                <div class="d-flex align-items-center">Lot
                  <i *ngIf="isActiveSort('lot')" class="material-icons">{{ ascending ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                </div>
              </th>

            </tr>

            <ng-container *ngIf="(vehicles || []).length">
              <tr *ngFor="let vehicle of vehiclesList | vehicleSort: sortParam : ascending"
                  class="selectable"
                  (click)="onSelectVehicle(vehicle)">
                <td class="table-element">{{vehicle.stockNumber}}</td>
                <td class="table-element">{{vehicle.year}}</td>
                <td class="table-element">{{vehicle.make}}</td>
                <td class="table-element">{{vehicle.model}}</td>
                <td class="table-element">{{vehicle.trim}}</td>
                <td class="table-element">{{vehicle.engine}}</td>
                <td class="table-element">{{vehicle.transmission}}</td>
                <td class="table-element">{{vehicle.odometer}}</td>
                <td class="table-element">{{vehicle.msr}}</td>
                <td class="table-element">{{vehicle.retail}}</td>
                <td class="table-element">{{getType(vehicle)}}</td>
                <td class="table-element">{{vehicle.lot}}</td>
              </tr>
            </ng-container>
          </table>


        </section>


      </ng-container>
-->
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { DealState, DealStatus } from "src/app/clearpath-module/store/state";
import * as dealActions from "src/app/clearpath-module/store/actions/deal.actions";
import * as vehicleActions from "src/app/clearpath-module/store/actions/vehicle.actions";
import * as appActions from "src/app/clearpath-module/store/actions/app.actions";
import { v1 as uuidv1 } from "uuid";
import { AppService, CalculationService, DealService, LeaseCalculationService, VehicleService } from "src/app/clearpath-module/services";
import { AlertService, CanComponentDeactivate, PubnubService } from "src/app/shared-module/services";
import { FinanceDefault, LeaseDefault } from "src/app/settings-module/models";
import { User } from "src/app/user-admin-module/models";
import { DealDefaults, DealDefaultsService } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { DealIncentive } from "src/app/settings-module/models/incentives";
import { Accessory, Buyer, FinanceOptions, FinancingSettings, LeaseOptions, LeaseResidual, TradeIn, Vehicle, VehicleNeeds, } from "src/app/clearpath-module/models";
import { AuthService } from "src/app/auth-module/services";
import { DealIncentivesService } from "src/app/clearpath-module/services/deal/deal-incentives.service";

@Component({
  selector: "app-sales-manager-writeup",
  templateUrl: "./sales-manager-writeup.component.html",
  styleUrls: ["./sales-manager-writeup.component.scss"]
})
export class SalesManagerWriteupComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new Subject();
  private uiState = {
    activeViewId: "payment",
    viewTypes: {
      customer: "customer",
      vehicle: "vehicle",
      payment: "payment"
    },
    waitOnAction: false,
    unsavedChanges: false
  };
  salesManagerVerified = false;
  vehicleForm;
  selectedCashIncentivesTotal = 0;

  // Clearpath Store State
  deal: DealState;
  dealDefaults: DealDefaults;
  vehicle: Vehicle;
  financingSettings: FinancingSettings;
  leaseResiduals: LeaseResidual[];
  customizedVehiclePrice: number;
  user: User;
  customer$;

  // Derived State
  vehicleCondition: string;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private appService: AppService,
    private calcService: CalculationService,
    private leaseCalcService: LeaseCalculationService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private dealDefaultsService: DealDefaultsService,
    private authService: AuthService,
    private incentivesService: DealIncentivesService,
    private pubsub: PubnubService,
    private actions$: Actions,
  ) { }

  ngOnInit() {
    this.subToDealData();
    this.subToSettingsData();
    this.subToVehicleData();
    this.getDealDefaults();
    this.watchDealChanges();
    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    this.dealService.dispatchClearDeal();
    this.vehicleService.dispatchClearVehicle();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canDeactivate(): boolean {
    const {unsavedChanges} = this.uiState;
    const navigateAway = unsavedChanges ? confirm("Discard Changes?") : true;
    return navigateAway;
  }

  // INITIALIZATION

  private subToDealData() {
    this.calcService.customizedVehiclePrice$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(customizedVehiclePrice => {
        this.customizedVehiclePrice = customizedVehiclePrice;
      });
    this.dealService.selectDeal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: DealState) => {
        this.deal = data;
        const {adjustedPrice, incentivesApplied} = this.incentivesService.applyCashIncentives({
          price: 0,
          incentives: this.deal.incentives,
          leaseOptions: this.deal.leaseOptions,
          leaseSelected: this.deal.leaseOptions.leaseSelected,
          financeOptions: this.deal.financeOptions
        });
        this.selectedCashIncentivesTotal = Math.abs(adjustedPrice);
      });

    // Deal API Http Success
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.dealApiSuccess),
    ).subscribe(() => {
      this.flagWaitOnAction(false);
      this.flagUnsavedChanges(false);
      this.alertService.clear();
    });

    // Deal API Http Failure
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.dealApiFailure),
    ).subscribe(action => {
      this.flagWaitOnAction(false);
      this.alertService.error(action.error);
    });
  }

  private getDealDefaults() {
    this.dealDefaultsService.getDealDefaults$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dealDefaults: DealDefaults) => {
        this.dealDefaults = dealDefaults;
      });
  }

  private subToSettingsData() {
    this.appService.selectFinancing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((financingSettings: FinancingSettings) => {
        this.financingSettings = financingSettings;
      });
  }

  private subToVehicleData() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: Vehicle) => {
        this.setVehicle(data);
      });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(vehicleActions.setVehicle),
    ).subscribe(({vehicle}) => {
      this.setVehicle(vehicle);
    });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(vehicleActions.getVehicleFailure, vehicleActions.updateVehicleFailure),
    ).subscribe(action => {
      this.alertService.error(action.error);
    });
  }

  setVehicle(data: Vehicle) {
    // use object assign so that child component can detect changes
    this.vehicle = Object.assign({}, data);
    // this.deal.stockNumber = this.vehicle.stockNumber;
    this.vehicleCondition = this.vehicleService.vehicleCondition(data);
    this.getVehicleLeaseResiduals();
  }

  private getVehicleLeaseResiduals() {
    this.leaseCalcService.getLeaseResiduals()
      .pipe(take(1))
      .subscribe((data: LeaseResidual[]) => {
        this.leaseResiduals = data;
      });
  }

  private watchDealChanges() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.pubsubMessage),
    ).subscribe(({messageName, dealId}) => {
      if (messageName === this.pubsub.Messages.DealSubmitted) {
        if (this.deal.dealId === dealId) {
          this.dealService.dispatchGetByDealId(this.deal.dealId);
          alert("Deal Updated by Sales Person");
        }
      }
      if (messageName === this.pubsub.Messages.DealRetracted) {
        if (this.deal.dealId === dealId) {
          this.dealService.dispatchGetByDealId(this.deal.dealId);
          alert("Sales person is changing the deal");
        }
      }
    });
  }

  // DEAL API ACTIONS

  updateDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    if (this.dealStatus === DealStatus.Submitted) {
      this.flagWaitOnAction(true);
      this.dealService.dispatchUpdateDeal();
    }
  }

  approveDeal() {
    let confirmed = true;
    if (!this.salesManagerVerified) {
      confirmed = confirm("Not all changes are approved. Continue?");
    }
    if (confirmed) {
      if (this.waitOnAction || this.unsavedChanges) { return; }
      this.flagWaitOnAction(true);
      this.dealService.dispatchApproveDeal(this.deal.dealId);
    }
  }

  printDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    if (this.dealStatus === DealStatus.Approved ||
      this.dealStatus === DealStatus.Printed ||
      this.dealStatus === DealStatus.Printing
    ) {
      this.flagWaitOnAction(true);
      this.dealService.dispatchPrintDeal(this.deal.dealId);
    }
  }

  completeDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    const dealPrinting = this.dealStatus === DealStatus.Printing;
    const dealPrinted = this.dealStatus === DealStatus.Printed;

    if (dealPrinting || dealPrinted) {
      this.flagWaitOnAction(true);
      this.dealService.dispatchCompleteDeal();
    }
  }

  declineDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    if (this.dealStatus === DealStatus.Completed) { return; }
    if (confirm("Decline Deal?")) {
      this.flagWaitOnAction(true);
      this.dealService.dispatchDeclineDeal(this.deal.dealId);
    }
  }

  saveDealChanges() {
    if (this.waitOnAction || this.unsavedChanges === false) { return; }
    if (this.dealStatus === DealStatus.Completed) { return; }
    const noVehicle = !this.deal.stockNumber;

    if (noVehicle) {
      this.alertService.error("Deal requires a stock number.");
      return;
    }

    this.flagWaitOnAction(true);
    const newDeal = !this.deal.dealId;

    if (newDeal) {
      this.deal.dealId = uuidv1(); // create unique id for the deal
      this.deal.salesManager = this.user.employeeId;
      this.dealService.dispatchSalesManagerCreateDeal(this.deal);
    } else {
      this.dealService.dispatchSaveDeal(this.deal);
    }
    this.vehicleService.dispatchUpdateVehicle(this.vehicle);
  }

  cancelDealChanges() {
    if (this.waitOnAction || this.unsavedChanges === false) { return; }
    const newDeal = !this.deal.dealId;

    if (newDeal) {
      if (confirm("Discard New Deal?")) {
        this.flagUnsavedChanges(false);
        this.router.navigate(["/clearpath/sales-manager/list"]);
      }
    } else {
      if (confirm("Cancel Changes?")) {
        this.flagWaitOnAction(true);
        this.dealService.dispatchGetByDealId(this.deal.dealId);
      }
    }
  }

  // DEAL STATE OPERATIONS

  updateDealType(type: string) {
    this.dealService.dispatchSetDealType(type);
    this.flagUnsavedChanges(true);
  }

  updateAccessories(accessories: Accessory[]) {
    this.dealService.dispatchSetAccessories(accessories);
    this.flagUnsavedChanges(true);
  }

  updateFinanceOptions(financeOptions: Partial<FinanceOptions>) {
    this.dealService.dispatchSetFinanceOptions(financeOptions);
    this.flagUnsavedChanges(true);
  }

  updateLeaseOptions(leaseOptions: Partial<LeaseOptions>) {
    this.dealService.dispatchSetLeaseOptions(leaseOptions);
    this.flagUnsavedChanges(true);
  }

  updateLeasingTerm(leasingTerm) {
    this.dealService.setLeasingTerm(leasingTerm);
    this.flagUnsavedChanges(true);
  }

  updateIncentives(incentives: DealIncentive[]) {
    this.dealService.dispatchSetIncentives(incentives);
    this.flagUnsavedChanges(true);
  }

  updateSalesManager(salesManager: User) {
    this.dealService.dispatchSetSalesManager(salesManager);
    this.flagUnsavedChanges(true);
  }

  updateSalesPerson(salesPerson: User) {
    this.dealService.dispatchSetSalesPerson(salesPerson);
    this.flagUnsavedChanges(true);
  }

  // DATA ACCESS

  get accessories(): Accessory[] {
    return this.deal.accessories || [];
  }

  get customer(): Buyer {
    return this.deal.customer;
  }

  get coSigner(): Buyer {
    return this.deal.coSigner;
  }

  get dealStatus(): string {
    return this.deal.status;
  }

  get financeDefault(): FinanceDefault {
    return this.financingSettings.financeDefault;
  }

  get financeOptions(): FinanceOptions {
    return this.deal.financeOptions;
  }

  get selectedCreditTier(): number {
    return this.financeOptions.selectedCreditTier || 0;
  }

  get leaseDefault(): LeaseDefault {
    return this.financingSettings.leaseDefault;
  }

  get leaseOptions(): LeaseOptions {
    return this.deal.leaseOptions;
  }

  get dealIncentives(): DealIncentive[] {
    return this.deal.incentives || [];
  }

  get stockNumber(): string {
    return this.deal.stockNumber;
  }

  get tradeIn(): TradeIn {
    return this.deal.tradeIn;
  }

  get vehicleNeeds(): VehicleNeeds {
    return this.deal.vehicleNeeds;
  }

  get vehicleIncentives() {
    return this.vehicle.incentives || [];
  }

  // UI CONTROL & RENDERING

  onSelectView(id: string) {
    const {viewTypes} = this.uiState;
    this.uiState.activeViewId = viewTypes[ id ] || null;
  }

  activeView(id: string): boolean {
    const {viewTypes} = this.uiState;
    return viewTypes[ id ] ? viewTypes[ id ] === this.uiState.activeViewId : false;
  }

  get viewTypes(): { [ viewId: string ]: string; } {
    return this.uiState.viewTypes;
  }

  flagWaitOnAction(bool: boolean): void {
    this.uiState.waitOnAction = bool;
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOnAction;
  }

  flagUnsavedChanges(bool: boolean): void {
    this.uiState.unsavedChanges = bool;
  }

  get unsavedChanges(): boolean {
    return this.uiState.unsavedChanges;
  }

  get leaseAvailable(): boolean {
    const isNew = this.vehicleCondition === "new";
    const isCertified = this.vehicleCondition === "certified";
    return isNew || isCertified;
  }

}

import { BehaviorSubject, Subject, Subscription } from "rxjs";

export interface DealHistory {
  events: HistoryEvent[];
  dealId: string;
}

export interface HistoryEvent {
  eventId: number;
  createdAt: Date;
  createdBy: string; // userId that created event
  createdByName: string;
  description: string;
  shortName: string;
  elapsed: number;
  notes: string;
  oldValue: string;
  newValue: string;
}

export interface HistoryTimers {
  overviewPage: BehaviorSubject<number>;
}

export interface HistoryTimerSubscribers {
  overviewPage: Timer;
}

export const initialHistoryTimersState: HistoryTimers = {
  overviewPage: new BehaviorSubject(0)
};

export interface Timer {
  timer: Subscription;
  cancel: Subject<any>;
}

export const initialHistoryTimerSubscribersState: HistoryTimerSubscribers = {
  overviewPage: {timer: null, cancel: new Subject()}
};

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingPageComponent } from "./landing-page-module/pages/landing-page.component";
import { AuthGuard } from "./auth-module/services";
import { environment } from "../environments/environment";

const appRoutes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    canActivate: [AuthGuard]
  },
  {path: "**", redirectTo: ""}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: environment.useHash})],
  exports: [RouterModule]
})
export class AppRoutingModule {}

<div class="d-flex align-items-center justify-content-between flex-wrap">
  <h2 class="header-title">Payment Plans</h2>
  <div class="d-flex align-items-right" style="gap:15px;">
    <button
      class="btn app-btn-success btn-sm mb-3"
      #downloadButton
      type="button"
      (dblclick)="screenshot()"
      (click)="saveSnapshot()">
      Email Payment Plans
    </button>
    <button
      class="btn app-btn-success btn-sm mb-3"
      type="button"
      data-toggle="modal"
      data-target="#paymentOptionsModal"
      (click)="paymentOptionsModalOpened()">
      Payment Options
    </button>
  </div>
</div>

<!-- GAP QUESTION MODAL -->
<div #gapQuestionModal id="gapQuestionModal" class="modal gapQuestionModal fade" data-backdrop="static">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <button
        class="close"
        style="position: absolute; top: 0.25rem; right: 0.5rem"
        type="button"
        data-dismiss="modal">
        <span>&times;</span>
      </button>
      <h1 class="h2 header-title">Guaranteed Auto Protection</h1>
      <hr>
      <p>Great news! Your down payment and/or trade equity exceeds the amount where Guaranteed Auto Protection may be applicable to your purchase.</p>
      <div style="display: flex;justify-content: flex-end">
        <button class="btn app-btn-secondary" (click)="handleGapQuestionContinued()">Continue</button>
      </div>
    </div>
  </div>
</div>

<!-- OPTIONS MODAL -->
<div id="paymentOptionsModal" class="modal fade" data-backdrop="static">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <!-- BODY START -->
      <button
        class="close"
        style="position: absolute; top: 0.25rem; right: 0.5rem"
        type="button"
        data-dismiss="modal"
        (click)="paymentOptionsModalClosed()">
        <span>&times;</span>
      </button>

      <h1 class="h2 header-title">Payment Options</h1>

      <h2 class="h5">Qualifying Rates</h2>
      <p class="small">Select or estimate your FICO Credit Score.</p>

      <div class="form-group">
        <app-credit-tier-input></app-credit-tier-input>
      </div>

      <div class="interest-rate-list-layout card card-body">
        <div class="interest-rate app-text-white app-bg-neutral rounded">
          <div>Terms</div>
          <div>Standard</div>
          <div>Incentive</div>
        </div>
        <div
          *ngFor="let termRate of financingTermRates"
          class="interest-rate rounded">
          <div>{{ termRate.term }} Months</div>
          <div>
            {{
            findStandardInterestRate$(termRate.term)
              | async
              | percent: "1.1-2"
            }}
          </div>

          <div *ngIf="findIncentiveRate(termRate.term); else elseBlock">
            {{ findIncentiveRate(termRate.term) | percent: "1.1-2" }}
          </div>

          <ng-template #elseBlock>
            {{ findIncentiveRate(termRate.term) | percent: "1.1-2" }}
            <div *ngIf="findIncentiveRate(termRate.term) === null">--</div>
            <!-- <div *ngIf="findIncentiveRate(termRate.term) === 0">
              {{ findIncentiveRate(termRate.term) | percent: "1.1-2" }}
            </div> -->
          </ng-template>
        </div>
        <div
          *ngIf="financeIncentiveRatesExist"
          class="interest-rate rounded app-btm">
          <div></div>
          <button
            *ngIf="financeIncentiveRatesApplied"
            class="btn app-btn-neutral"
            (click)="applyStandardRate()">
            Apply Standard Rate
          </button>
          <button
            *ngIf="!financeIncentiveRatesApplied"
            class="btn app-btn-secondary"
            (click)="applyStandardRate()">
            Standard Rate Applied
          </button>

          <button
            *ngIf="!financeIncentiveRatesApplied"
            class="btn app-btn-neutral"
            (click)="applyIncentiveRate()">
            Apply Incentive Rate
          </button>
          <button
            *ngIf="financeIncentiveRatesApplied"
            class="btn app-btn-secondary"
            (click)="applyIncentiveRate()">
            Incentive Rate Applied
          </button>
        </div>
      </div>

      <h2 class="h5">Custom Rate</h2>
      <p class="small">Provide your own financing.</p>

      <div class="row px-3">
        <div class="col-sm-6 form-group">
          <app-custom-rate-input></app-custom-rate-input>
        </div>
      </div>

      <hr class="w-100 mb-4"/>

      <h2 class="h5 mb-3">Taxes &amp; Fees</h2>

      <form [formGroup]="taxRateForm" class="row px-3 mb-3">
        <label
          class="col-sm-6 col-form-label d-flex align-items-center"
          [ngClass]="{
            'app-text-danger': touchedInvalid(
              'taxRateForm',
              'taxRatePercentage'
            )
          }">
          Sales Tax - {{ salesTax | currency: "USD":"symbol":"1.0-0" }}
          <span class="badge badge-secondary ml-2">
            {{ salesTax | currency: "USD":"symbol":"1.0-0" }}
          </span>
        </label>
        <div class="col-sm-6">
          <div class="input-group">
            <input
              class="form-control text-right"
              formControlName="taxRatePercentage"
              type="number"
              min="0"
              [attr.disabled]="isOregon() === true"
              (blur)="autoSubmitTaxRateForm()"/>
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="docFeesForm" class="row px-3 mb-3">
        <label
          class="col-sm-6 col-form-label"
          [ngClass]="{'app-text-danger': touchedInvalid('docFeesForm', 'docFees')}">
          DMV &amp; Document Fees
        </label>
        <div class="col-sm-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              class="form-control text-right"
              formControlName="docFees"
              type="number"
              min="0"
              (blur)="autoSubmitDocFeesForm()"
              required/>
          </div>
        </div>
      </form>

      <hr class="w-100 mb-4"/>

      <h2 class="h5 mb-3">Display Terms</h2>

      <form class="row px-3">
        <div class="form-group col-6">
          <label class="badge badge-secondary">Financing</label>
          <div *ngIf="financingTermRates.length === 0">No Finance Terms Set.</div>
          <div *ngFor="let termRate of financingTermRates" class="form-check">
            <div *ngIf="hasRateForCreditTier(termRate) && termRate.term !== 96 && termRate.term !== 84">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="displayTerm('financing', termRate?.term?.toString())"
                (click)="onToggleTerm('financing', termRate?.term?.toString())"/>
              <label class="form-check-label">{{ termRate.term }} Months</label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div *ngIf="isNewOrCertVehicle()" class="form-group">
            <label class="badge badge-secondary">Leasing</label>
            <div *ngIf="leasingTerms.length === 0">No Lease Terms Set.</div>
            <ng-container *ngFor="let term of leasingTerms">
              <div class="form-check" [ngClass]="{ 'd-none': restrictedTerm(term) }">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="displayTerm('leasing', term)"
                  (click)="onToggleTerm('leasing', term)"/>
                <label class="form-check-label"> {{ term }} Months </label>
              </div>
            </ng-container>
          </div>
          <div class="form-group">
            <label class="badge badge-secondary">Other</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox"
                     [checked]="displayTerm('other', 'cashPurchase')"
                     (click)="onToggleTerm('other', 'cashPurchase')"/>
              <label class="form-check-label"> Cash Purchase </label>
            </div>
          </div>
        </div>
      </form>
      <!-- BODY END -->
    </div>
  </div>
</div>

<!-- PAYMENT PLAN LIST -->
<section
  *ngIf="financingTermRates.length === 0"
  class="app-bg-light app-text-neutral rounded text-center px-3 py-2 mb-3">
  No Financing Terms Set.
</section>

<section id="buyBoxMenuLayout" class="buy-box-menu-layout">
  <ng-container *ngIf="leaseResiduals.length">
    <div
      *ngFor="let term of leasingTerms"
      [ngClass]="{ 'd-none': !displayTerm('leasing', term) }">
      <app-buy-box-lease
        *ngIf="leaseResiduals.length"
        (gapQuestion)="handleGapQuestion($event)"
        [insuranceProducts]="insuranceProducts"
        [financeSettings]="financeSettings"
        [leaseDefault]="leaseDefault"
        [leasingTerm]="term"
        [disabledProducts]="disabledProducts"
        (submitDisabledProducts)="disabledProductsCatch($event)"
        [leaseResiduals]="leaseResiduals">
      </app-buy-box-lease>
    </div>
  </ng-container>

  <div
    *ngFor="let termRate of financingTermRates"
    [ngClass]="{ 'd-none': !displayTerm('financing', termRate.term) }">
    <app-buy-box-finance
      *ngIf="hasRateForCreditTier(termRate)"
      (gapQuestion)="handleGapQuestion($event)"
      [financingTerm]="termRate.term"
      [disabledProducts]="disabledProducts"
      (submitDisabledProducts)="disabledProductsCatch($event)">
    </app-buy-box-finance>
  </div>

  <div
    *ngIf="financeOptions.customerProvidedFinancingTerm > 0 &&
    termNotAlreadyDisplayed(financingTermRates,financeOptions.customerProvidedFinancingTerm,financeOptions.customerProvidedInterestRate)">
    <app-buy-box-custom
      [financingTerm]="financeOptions.customerProvidedFinancingTerm"
      [disabledProducts]="disabledProducts"
      (submitDisabledProducts)="disabledProductsCatch($event)">
    </app-buy-box-custom>
  </div>

  <div
    class="card"
    [ngClass]="{ 'd-none': !displayTerm('other', 'cashPurchase') }">
    <app-buy-box-cash
      [insuranceProducts]="insuranceProducts"
      [disabledProducts]="disabledProducts"
      (submitDisabledProducts)="disabledProductsCatch($event)">
    </app-buy-box-cash>
  </div>
</section>

<form [formGroup]="fuelSavingsForm" class="row" style="padding:0;margin:0">

  <div class="form-group col-sm-2">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('financeRate') }">Finance Rate %</label>
    <span class="input-symbol-percent">
<input class="form-control"
       formControlName="financeRate"
       type="number"
       inputmode="numeric"
       min="0"
       [step]="0.01"
       (keyup)="updateCalculations()"
       required/>
</span>
  </div>

  <div class="form-group col-sm-2">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('numPayments') }"># Payments</label>
    <select
      class="form-control"
      formControlName="numPayments"
      (change)="updateFinanceRate();updateCalculations()">
      <option [value]="36">36</option>
      <option [value]="48">48</option>
      <option [value]="60">60</option>
      <option [value]="66">66</option>
      <option [value]="72">72</option>
      <option [value]="84">84</option>
      <option [value]="96">96</option>
    </select>
  </div>

  <div class="form-group col-sm-2">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('amountFinanced') }">Amount Financed</label>
    <span class="input-symbol-dollar">
<input class="form-control"
       formControlName="amountFinanced"
       type="number"
       inputmode="numeric"
       value="currency"
       min="0"
       [step]="0.01"
       (keyup)="updateCalculations()"
       required/>
</span>
  </div>

  <div class="form-group col-sm-2">&nbsp;</div>

  <div class="form-group col-sm-2">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('payment') }">Payment</label>
    <span class="input-symbol-dollar">
<input class="form-control"
       style="background: #fffcde"
       formControlName="payment"
       type="number"
       inputmode="numeric"
       value="currency"
       min="0"
       (keyup)="integerTrim('payment');updateCalculations()"
       required/>
</span>
  </div>

  <div class="form-group col-sm-2">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('sumOfPayments') }">Sum of Payments
    </label>
    <span class="input-symbol-dollar">
<input class="form-control"
       style="background: #fffcde;"
       formControlName="sumOfPayments"
       type="number"
       value="currency"
       inputmode="numeric"
       min="0"
       (keyup)="updateCalculations()"
       required/>
</span>
  </div>

  <div class="form-group col-sm-2">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('investRate') }">Invest Rate %
    </label>
    <span class="input-symbol-percent">
<input id="currency"
       class="form-control"
       formControlName="investRate"
       type="number"
       inputmode="numeric"
       value="numeric"
       min="0"
       [step]=".01"
       (change)="updateCalculations()"
       (keyup)="updateCalculations()"
       required/>
</span>
  </div>

  <div class="form-group col-sm-2">&nbsp;</div>
  <div class="form-group col-sm-2">&nbsp;</div>
  <div class="form-group col-sm-2">&nbsp;</div>
  <div class="form-group col-sm-2">&nbsp;</div>

  <div class="form-group col-sm-2">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('investValue') }">Invest Value</label>
    <span class="input-symbol-dollar">
<input
  class="form-control"
  style="background: #fffcde"
  formControlName="investValue"
  type="number"
  inputmode="numeric"
  value="currency"
  min="0"
  (keyup)="decimalTrim('investValue');updateCalculations()"
  required/>
</span>
  </div>

  <!--<div class="form-group col-sm-2">&nbsp;</div>
  <div class="form-group col-sm-2">&nbsp;</div>
  <div class="form-group col-sm-2">&nbsp;</div>

  <div class="form-group col-sm-2">
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('investmentGain') }">
      Investment Gain
    </label>
    <span class="input-symbol-dollar">
  <input
    class="form-control"
    style="background: #fffcde"
    formControlName="investmentGain"
    type="number"
    inputmode="numeric"
    value="currency"
    min="0"
    (keyup)="decimalTrim('investmentGain');updateCalculations()"
    required/>
  </span>
  </div>

  <div class="form-group col-sm-2">
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('costOfLoan') }">
      Cost of Loan
    </label>
    <span class="input-symbol-dollar">
  <input
    class="form-control"
    style="background: #fffcde"
    formControlName="costOfLoan"
    type="number"
    inputmode="numeric"
    value="currency"
    min="0"
    (keyup)="decimalTrim('costOfLoan');updateCalculations()"
    required/>
  </span>
  </div>

  <div class="form-group col-sm-2">
    <label class="small" style="font-weight:bold" [ngClass]="{ 'app-text-danger': touchedInvalid('savings') }">
      Savings
    </label>
    <span class="input-symbol-dollar bold">
  <input
    readonly
    class="form-control"
    style="background: #e5ffde;font-weight:bold"
    formControlName="savings"
    type="number"
    inputmode="numeric"
    value="currency"
    min="0"
    (keyup)="decimalTrim('savings')"
    required/>
  </span>
  </div>-->

  <hr style="width:100%">

  <div class="savings-table" style="display:flex;flex-direction: column">

    <div style="display: flex">
      <div class="top-left"></div>
      <ng-container *ngFor="let year of years">
        <div [class]="'header-year '+(year === years[years.length-1] ? 'green ' : ' ' )">Year {{year}}</div>
      </ng-container>
    </div>

    <div style="display: flex">
      <div class="label-left">Investment Gain</div>
      <ng-container *ngFor="let year of years">
        <div [class]="'data-year first-top '+(year === years[0] ? 'first tl ' : ' ' )+(year === years[years.length-1] ? 'lg ' : ' ' )">
          {{calculateInvestmentGain(
          this.fuelSavingsForm.get('amountFinanced').value,
          this.fuelSavingsForm.get('investRate').value,
          year * 12)|currency:'USD':'symbol':'1.2-2'}}
        </div>
      </ng-container>
    </div>

    <div style="display: flex">
      <div class="label-left">Cost of Loan</div>
      <ng-container *ngFor="let year of years">
        <div [class]="'data-year '+(year === years[0] ? 'first ' : ' ' )+(year === years[years.length-1] ? 'lg ' : ' ' )">
          {{calculateCostOfLoan(
          this.fuelSavingsForm.get('amountFinanced').value,
          this.fuelSavingsForm.get('financeRate').value,
          year * 12)|currency:'USD':'symbol':'1.2-2'}}
        </div>
      </ng-container>
    </div>

    <div style="display: flex">
      <div class="label-left green">Savings</div>
      <ng-container *ngFor="let year of years">
        <div [class]="'data-year lg '+(year === years[0] ? 'first ' : ' ' )+(year === years[years.length-1] ? 'green ' : ' ' )">
          {{calculateSavings(
          this.fuelSavingsForm.get('amountFinanced').value,
          this.fuelSavingsForm.get('financeRate').value,
          this.fuelSavingsForm.get('investRate').value,
          year * 12)|currency:'USD':'symbol':'1.2-2'}}
        </div>
      </ng-container>
    </div>

  </div>

</form>

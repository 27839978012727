<section class="app-bg-secondary d-flex align-items-start justify-content-between px-2 pt-2">

  <div class="d-flex flex-row button-bar">
    <ng-container *ngFor="let control of controls.status">
      <button *ngIf="control.title"
              class="btn app-btn-reverse-success rounded-pill border-white mx-2 mb-3 mb-3"
              (click)="control.onClick()"
              [disabled]="waitOnAction || unsavedChanges || actionNotAllowed(control)">
        {{control.title}}
      </button>
    </ng-container>
    <div *ngIf="dealStatus === 'created'" class="app-text-white app-text-bold button-message">
      * Customer signature needed to continue
    </div>
  </div>

  <div>
    <button *ngFor="let control of controls.form"
            class="btn app-btn-reverse-success rounded-pill border-white mx-2 mb-3 mb-3"
            (click)="control.onClick()"
            [disabled]="waitOnAction || !unsavedChanges">
      {{control.title}}
    </button>
  </div>

</section>

import { Component, OnInit } from "@angular/core";
import { ImportIncentive, Incentive, IncentiveGroup } from "../../models/";
import { ParsingService, SettingsService } from "../../services";
import { AlertService } from "../../../shared-module/services";
import { IncentivesService } from "../../services/incentives.service";

@Component({
  selector: "app-incentives",
  templateUrl: "./incentives.component.html",
  styleUrls: ["./incentives.component.scss"]
})
export class IncentivesComponent implements OnInit {

  uiState = {
    waitOn: {init: true, action: false},
    activeGroupIndex: -1,
    unsavedChanges: false,
    newGroup: false,
    uploadSelected: false
  };

  incentiveGroups: IncentiveGroup[] = [];

  constructor(
    private settingsService: SettingsService,
    private alertService: AlertService,
    private parsingService: ParsingService,
    private incentivesService: IncentivesService
  ) { }

  ngOnInit() {
    this.getIncentives();
  }

  canDeactivate(): boolean {
    const {unsavedChanges} = this.uiState;
    const navigateAway = unsavedChanges ? confirm("Discard Changes?") : true;
    if (navigateAway) { this.unsavedChanges = false; }
    return navigateAway;
  }

  // INITIALIZATION

  private getIncentives() {
    this.settingsService.getIncentives().subscribe({
      next: (incentives) => {
        this.incentiveGroups = incentives.incentiveGroups;
        this.uiState.waitOn.init = false;
      },
      error: (err) => {
        this.uiState.waitOn.init = false;
        this.incentiveGroups = [];
        this.alertService.error(err);
      }
    });
  }

  private setIncentives(incentives: IncentiveGroup[], actionVerb: string) {
    this.uiState.waitOn.action = true;
    this.settingsService.setIncentives({incentiveGroups: incentives})
      .subscribe({
        next: () => {
          this.uiState.newGroup = false;
          this.uiState.waitOn.action = false;
          this.unsavedChanges = false;
          this.alertService.success(`Incentive Group ${actionVerb}`);
        },
        error: error => {
          this.uiState.waitOn.action = false;
          this.unsavedChanges = false;
          this.alertService.error(error);
          window.scroll({top: 0, behavior: "smooth"});
        }
      });
  }

  readFile(file: File) {
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "csv") {
      return this.alertService.error("Only .csv files are allowed.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      const csv = reader.result.toString();
      const json: ImportIncentive[] = this.parsingService.csvToJSON(csv, 0) as ImportIncentive[];
      const newIncentives = this.incentivesService.importIncentives(json);
      const regionalIncentiveGroupIndex = this.incentiveGroups.findIndex((ig: IncentiveGroup) => {
        return ig.description === "Toyota Portland Region Incentives" || ig.name === "Toyota Portland Region Incentives";
      });
      if (this.incentiveGroups[ regionalIncentiveGroupIndex ]) {
        this.incentiveGroups.splice(regionalIncentiveGroupIndex, 1);
      }
      this.createRegionalIncentiveGroup(newIncentives);
    };
    reader.readAsText(file);
  }

  // ACTIONS

  createRegionalIncentiveGroup(incentives: Incentive[]) {
    this.createGroup({
      description: "Toyota Portland Region Incentives",
      name: "Toyota Portland Region Incentives",
      incentives,
      exclusions: [],
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      groupQualifier: {
        groupName: "",
        groupDescription: "",
        validationRequired: false
      }
    });
  }

  fileEvent(event) {
    const file = event.target.files[ 0 ];
    this.alertService.clear();
    this.readFile(file);
  }

  createGroup(group: IncentiveGroup) {
    this.incentiveGroups.push(group);
    this.setIncentives(this.incentiveGroups, "Created");
  }

  updateGroup(group: IncentiveGroup) {
    const newIncentiveGroups: IncentiveGroup[] = this.incentiveGroups.slice();
    newIncentiveGroups[ this.activeGroupIndex ] = group;
    this.setIncentives(newIncentiveGroups, "Updated");
  }

  removeGroup() {
    this.incentiveGroups.splice(this.activeGroupIndex, 1);
    this.setIncentives(this.incentiveGroups, "Removed");
  }

  // UI & RENDERING

  onSelectAddGroup() {
    if (!this.canDeactivate()) { return; }
    this.unsavedChanges = false;
    this.uiState.activeGroupIndex = null;
    this.uiState.newGroup = true;
    this.alertService.clear();
  }

  onSelectGroup(index: number) {
    if (!this.canDeactivate()) { return; }
    this.unsavedChanges = false;
    this.uiState.activeGroupIndex = index;
    this.uiState.newGroup = false;
    this.alertService.clear();
  }

  get activeGroupIndex(): number {
    return this.uiState.activeGroupIndex;
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOn.action;
  }

  get waitOnInit(): boolean {
    return this.uiState.waitOn.init;
  }

  get unsavedChanges(): boolean {
    return this.uiState.unsavedChanges;
  }

  set unsavedChanges(val: boolean) {
    this.uiState.unsavedChanges = val;
  }

  get newGroup(): boolean {
    return this.uiState.newGroup;
  }

}

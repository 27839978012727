<h5 *ngIf="residuals" class="text-center mt-4 mb-4 ml-auto mr-auto">{{residuals.year}} Toyota Models</h5>

<section class="d-flex justify-content-center">
  <table *ngIf="residuals" class="data-table" style="max-width: 700px;">
    <tr>
      <th *ngFor="let header of headers"
          [ngClass]="isEmphasizedTerm(header) ? 'text-emphasized' : ''">
        {{header}}
      </th>
    </tr>
    <tr *ngFor="let model of residuals.residualPercentages">
      <td class="font-weight-bold">{{model.modelNumber}}</td>
      <td *ngFor="let termPercentage of model.termPercentages"
          [ngClass]="isEmphasizedTerm(termPercentage.term) ? 'text-emphasized' : ''">
        {{termPercentage.percentage}}
      </td>
    </tr>
  </table>
</section>
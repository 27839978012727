import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromRoot from "../store/state/app";
import * as appSelectors from "../store/selectors/clearpath";
import * as dealSelectors from "../store/selectors/deal";
import { DealService } from "./deal/deal.service";
import { VehicleService } from "./vehicle.service";
import { DealState } from "../store/state";
import { InsuranceProduct, InsuranceProductKeys, TermCost, Vehicle, VehicleNeeds, WarrantyBarGraph, WarrantyBarGraphSegment, WarrantyUi } from "../models";
import { DEFAULT_WARRANTY_UI, GetDefaultWarrantyUIGraphs, WARRANTY_SETTINGS, WARRANTY_UI_CONFIG, WarrantyBarGraphBgClass } from "../components/warranty/warranty-config";
import { initialVehicleNeedsState } from "../store/state/deal/vehicle-needs";
import { InsuranceDefaultTerms } from "src/app/app.config";
import { pathOr } from "ramda";
import { WarrantyUIGraph } from "../components/warranty/models/warranty";

@Injectable({
  providedIn: "root"
})
export class WarrantyUiService {
  private innerState = {
    dealId: "",
    vehicle: null,
    warrantyUi: new BehaviorSubject<WarrantyUi>(null),
    presentationComplete: new BehaviorSubject<boolean>(false),
  };

  constructor(
    private store: Store<fromRoot.AppState>,
    private dealService: DealService,
    private vehicleService: VehicleService
  ) { }

  // DATA HANDLING

  private setWarrantyUi(warrantyUi: WarrantyUi) {
    this.innerState.warrantyUi.next(warrantyUi);
  }

  private setPresentationComplete(isComplete: boolean) {
    this.innerState.presentationComplete.next(isComplete);
  }

  private getVehicle(): Vehicle {
    let vehicle: Vehicle;
    this.store.select(appSelectors.selectVehicle)
      .pipe(take(1))
      .subscribe((data: Vehicle) => {
        if (!data.stockPhoto) {
          data.customFields.forEach(field => {
            if (field.key === "StockPhoto" && field.value)
              data.stockPhoto = field.value
          })
        }
        vehicle = data
        this.innerState.vehicle = vehicle;
      });
    return vehicle;
  }

  private getDeal(): DealState {
    let deal: DealState;
    this.store.select(dealSelectors.selectDeal)
      .pipe(take(1))
      .subscribe((data: DealState) => deal = data);
    return deal;
  }

  // FEATURE - CONSUME METHODS

  public warrantyUiObservable(): Observable<WarrantyUi> {
    return this.innerState.warrantyUi.asObservable();
  }

  public presentationCompleteObservable(): Observable<boolean> {
    return this.innerState.presentationComplete.asObservable();
  }

  public get warrantyUi(): WarrantyUi {
    return this.innerState.warrantyUi.value;
  }

  public get activeDealId(): string {
    return this.innerState.dealId;
  }

  public resetWarrantyUi() {
    this.setPresentationComplete(false);
    this.setWarrantyUi(null);
  }

  public initializeWarrantyUi() {
    const template = {backgroundGraphs: [], productGraphs: []};
    const warrantyUi: WarrantyUi = {...DEFAULT_WARRANTY_UI, ...template};

    const vehicle: Vehicle = this.getVehicle();
    const deal: DealState = this.getDeal();

    // Store Deal ID Context
    this.innerState.dealId = deal.dealId;

    const products = vehicle.insuranceProducts || [];
    const selectedProducts = deal.insuranceProducts || [];
    const vehicleNeeds = deal.vehicleNeeds;

    // Sequence-Specific Setup
    this.configVehicleDependencies(warrantyUi, vehicle);
    this.configWarrantySettingOptions(warrantyUi, vehicleNeeds);
    this.attachWarrantyGraphs(warrantyUi, products);
    this.configBgGraphWidths(warrantyUi, this.innerState.vehicle);
    this.configInitialProductSelections(warrantyUi, products, selectedProducts);
    this.configProductGraphWidths(warrantyUi);
    this.auditWarrantyUi(warrantyUi);
    this.setWarrantyUi(warrantyUi);
  }

  public nextViewIndex() {
    const {activeViewIndex, maxViewIndex} = this.warrantyUi;
    if (activeViewIndex > maxViewIndex - 1) { return; }

    const newWarrantyUi = {...this.warrantyUi};
    newWarrantyUi.activeViewIndex++;
    this.setWarrantyUi(newWarrantyUi);
    this.configBgGraphWidths(newWarrantyUi, this.innerState.vehicle);

    if (newWarrantyUi.activeViewIndex === maxViewIndex) {
      this.setPresentationComplete(true);
    }
  }

  public previousViewIndex() {
    const {activeViewIndex} = this.warrantyUi;
    if (activeViewIndex < 1) { return; }

    const newWarrantyUi = {...this.warrantyUi};
    newWarrantyUi.activeViewIndex--;
    this.setWarrantyUi(newWarrantyUi);
    this.configBgGraphWidths(newWarrantyUi, this.innerState.vehicle);
  }

  public selectWarrantySettingOptions(ownershipYears: number, milesPerYear: number) {
    const newWarrantyUi = {...this.warrantyUi};
    const yearsChanged = ownershipYears !== newWarrantyUi.ownershipYears;
    const milesChanged = milesPerYear !== newWarrantyUi.milesPerYear;

    if (yearsChanged) {
      newWarrantyUi.ownershipYears = ownershipYears;
      this.configBgGraphWidths(newWarrantyUi, this.innerState.vehicle);
    }

    if (milesChanged) {
      newWarrantyUi.milesPerYear = milesPerYear;
      this.configProductGraphWidths(newWarrantyUi);
    }

    this.dealService.dispatchSetVehicleNeeds({
      milesDrivenPerYear: milesPerYear,
      plannedLengthOfOwnership: ownershipYears
    });

    this.setWarrantyUi(newWarrantyUi);
  }

  public selectInsuranceProduct(product: InsuranceProduct, termCostIndex: number) {
    const {productKey, termCosts} = product;
    const termCost = termCosts[ termCostIndex ];

    const newWarrantyUi = {...this.warrantyUi};
    const {productGraphs} = newWarrantyUi;

    for (const graph of productGraphs) {
      if (graph.product.productKey === productKey) {
        graph.product.term = termCost.term;
        graph.product.miles = termCost.miles;
        break;
      }
    }

    this.dealService.dealInsuranceService.submitInsuranceProduct(product, termCostIndex);
    this.configProductGraphWidths(newWarrantyUi);
    this.setWarrantyUi(newWarrantyUi);
  }

  // FEATURE - INTERNAL PROCESSES

  private configVehicleDependencies(warrantyUi: WarrantyUi, vehicle: Vehicle) {
    const vehicleCondition = this.vehicleService.vehicleCondition(vehicle);
    warrantyUi.maxViewIndex = WARRANTY_UI_CONFIG[ vehicleCondition ].maxViewIndex;
    warrantyUi.titleIndex = WARRANTY_UI_CONFIG[ vehicleCondition ].titleIndex;
    warrantyUi.vehicleAge = this.vehicleService.calculateVehicleAge(+vehicle.year);
    warrantyUi.vehicleCondition = vehicleCondition;
    warrantyUi.vehicleOdometer = vehicle.odometer || 0;
  }

  private configWarrantySettingOptions(
    warrantyUi: WarrantyUi,
    vehicleNeeds: VehicleNeeds
  ) {
    // Find Ownership Years
    let yearsNeeds: number = vehicleNeeds.plannedLengthOfOwnership || initialVehicleNeedsState.plannedLengthOfOwnership;
    const yearsOptions = WARRANTY_SETTINGS.plannedLengthOfOwnership;
    const yearsLookUp = yearsOptions.find(item => item.years === yearsNeeds);

    if (!yearsLookUp) {
      const defaultIndex = yearsOptions.length - 1;
      yearsNeeds = yearsOptions[ defaultIndex ].years;
    }

    // Find Miles Per Year
    let milesNeeds: number = vehicleNeeds.milesDrivenPerYear || initialVehicleNeedsState.milesDrivenPerYear;
    const milesOptions = WARRANTY_SETTINGS.milesDrivenPerYear;
    const milesLookUp = milesOptions.find(option => option.miles === milesNeeds);

    if (!milesLookUp) {
      const diffs = milesOptions.map(item => Math.abs(item.miles - milesNeeds));
      let minDiffIndex = 0;

      for (let i = 1; i < diffs.length; i++) {
        if (diffs[ i ] < diffs[ minDiffIndex ]) {
          minDiffIndex = i;
        }
      }

      milesNeeds = milesOptions[ minDiffIndex ].miles;
    }

    warrantyUi.ownershipYears = yearsNeeds;
    warrantyUi.milesPerYear = milesNeeds;
  }

  // note: these changes may be reset by addActiveViewIndexBasedCopyModifications
  // avoid changing properties in this function if you are changing them in addActiveViewIndexBasedCopyModifications
  private addVehicleConditionCopyModifications(
    warrantyUIGraphs: WarrantyUIGraph,
    vehicleCondition: string
  ) {
    if (vehicleCondition === "new") {
      warrantyUIGraphs.productGraphs.SELECT.riskInfo.details.push("Paint", "Interior Rips & Stains");
      warrantyUIGraphs.productGraphs.SELECT.product.title = "CLEAR Care Elite";
      // warrantyUIGraphs.productGraphs.SELECT.riskInfo.title = "CLEAR Care";
      warrantyUIGraphs.productGraphs.VSC.product.details.push("1 year key replacement");
    }
    return warrantyUIGraphs;
  }

  addActiveViewIndexBasedCopyModifications(barGraph: WarrantyBarGraph): WarrantyBarGraph {
    const defaultWarrantyUiGraphs = GetDefaultWarrantyUIGraphs();
    if (this.warrantyUi.vehicleCondition === "new") {
      if (this.warrantyUi.activeViewIndex > 2) {
        if (barGraph.product.productKey === "SELECT") {
          barGraph.riskInfo.title = defaultWarrantyUiGraphs.productGraphs.CLEAR_ELITE.riskInfo.title;
          barGraph.riskInfo.details = defaultWarrantyUiGraphs.productGraphs.CLEAR_ELITE.riskInfo.details;
          // barGraph.product = defaultWarrantyUiGraphs.productGraphs.CLEAR_ELITE.product;
          if (barGraph.riskInfo.title === "CLEAR") {
            barGraph.riskInfo.bgClass = WarrantyBarGraphBgClass.Green;
          }
        }
      } else {
        // revert changes
        if (barGraph.product.productKey === "SELECT") {
          barGraph.riskInfo.title = defaultWarrantyUiGraphs.productGraphs.SELECT.riskInfo.title;
          barGraph.riskInfo.details = defaultWarrantyUiGraphs.productGraphs.SELECT.riskInfo.details;
          if (barGraph.riskInfo.title === "SELECT") {
            barGraph.riskInfo.bgClass = WarrantyBarGraphBgClass.Blue;
          }
          if (barGraph.riskInfo.title === "Wear & Tear") {
            barGraph.riskInfo.bgClass = WarrantyBarGraphBgClass.Gray1;
          }
          if (this.warrantyUi.activeViewIndex > 1) {
            barGraph.riskInfo.details.push("Paint", "Interior Rips & Stains");
          }
        }
      }
    }
    return barGraph;
  }

  private attachWarrantyGraphs(
    warrantyUi: WarrantyUi,
    products: InsuranceProduct[]
  ) {
    const {vehicleCondition} = warrantyUi;
    const {backgroundConfig, productConfig} = WARRANTY_UI_CONFIG[ vehicleCondition ];
    const defaultWarrantyUiGraphs = GetDefaultWarrantyUIGraphs();
    const {
      backgroundGraphs,
      productGraphs,
      defaultGraphs
    } = this.addVehicleConditionCopyModifications(defaultWarrantyUiGraphs, vehicleCondition);
    // const { backgroundGraphs, productGraphs, defaultGraphs } = defaultWarrantyUiGraphs;

    backgroundConfig.forEach(config => {
      const bgGraph = backgroundGraphs[ config.id ];
      bgGraph.visibility = config.visibility;
      warrantyUi.backgroundGraphs.push(bgGraph);
    });

    productConfig.forEach(config => {
      // if (item.productKey === "SELECT") {
      //   return item.productKey === config.id || config.id === "CLEAR_ELITE";
      // }
      const product = products.find(item => item.productKey === config.id);
      if (!product) { return; }

      const graph = productGraphs[ config.id ];
      graph.riskInfo.visibility = config.visibility.riskInfo;
      graph.product.visibility = config.visibility.product;
      graph.defaults = [];

      config.defaults.forEach(list => {
        const group = [];

        list.forEach(listItem => {
          const segment = defaultGraphs[ listItem.id ];
          segment.visibility = listItem.visibility;
          group.push(segment);
        });

        graph.defaults.push(group);
      });

      warrantyUi.productGraphs.push(graph);
    });
  }

  private configBgGraphWidths(warrantyUi: WarrantyUi, vehicle: Vehicle) {
    const {activeViewIndex, backgroundGraphs, ownershipYears, maxOwnershipYears, vehicleAge} = warrantyUi;
    const graphWidths = {ownership: 0, vehicleAge: 0}; // Limit: 0 to 100

    //console.log("vehicle", vehicle);
    const yearDiff = !vehicle || !vehicle.isUsed || activeViewIndex >= 4 ? 0 :
      new Date().getFullYear() - +vehicle.year;
    const yearRatio = (ownershipYears + yearDiff) / maxOwnershipYears;
    graphWidths.ownership = yearRatio > 1 ? 100 : yearRatio * 100;

    const ageRatio = vehicleAge / maxOwnershipYears;
    graphWidths.vehicleAge = ageRatio > 1 ? 100 : ageRatio * 100;

    backgroundGraphs.forEach((graph: WarrantyBarGraphSegment) => {
      switch (graph.title) {
        case "Ownership":
        case "Ownership Risk":
          graph.width = graphWidths.ownership;
          break;

        case "Vehicle Age":
          graph.width = graphWidths.vehicleAge;
          break;
      }
    });
  }

  configInitialProductSelections(
    warrantyUi: WarrantyUi,
    products: InsuranceProduct[],
    selectedProducts: InsuranceProduct[]
  ) {
    const {productGraphs} = warrantyUi;

    productGraphs.forEach(graph => {
      const {productKey} = graph.product;
      const product = products.find(item => item.productKey === productKey);
      const selectedProduct = selectedProducts.find(item => item.productKey === productKey);

      let selectionUpdated = false;
      let termCostIndex: number = null;
      let termCost: TermCost;

      if (selectedProduct) {
        const {selectedTerm} = selectedProduct;
        termCostIndex = product.termCosts[ selectedTerm ] ? selectedTerm : null;
      }

      if (termCostIndex === null) {
        const defaultTerm = pathOr(null, [product.productKey], InsuranceDefaultTerms);
        const prod = Object.assign({}, product);
        const closestTermCost = this.dealService.dealInsuranceService.findClosestTerm(
          prod,
          initialVehicleNeedsState.plannedLengthOfOwnership * 12
        );
        termCostIndex = product.termCosts.findIndex((termCost: TermCost) => {
          return closestTermCost.term === termCost.term;
        });
        if (defaultTerm) {
          const foundTermCostIndex = product.termCosts.findIndex((tc: TermCost) => {
            return tc.term === defaultTerm.term || tc.miles === defaultTerm.miles;
          });
          if (foundTermCostIndex >= 0) {
            termCostIndex = foundTermCostIndex;
          }
        }
        selectionUpdated = true;
      }

      if (termCostIndex >= 0) {
        termCost = product.termCosts[ termCostIndex ];

        graph.product.term = termCost.term;
        graph.product.miles = termCost.miles;

        if (selectionUpdated) {
          this.dealService.dealInsuranceService.submitInsuranceProduct(product, termCostIndex);
        }
      }
    });

    // For GAP Auto-Select (No Graph)
    const isNewVehicle = warrantyUi.vehicleCondition === "new";
    const gapProductKey = InsuranceProductKeys.GAP;
    const gapProduct = products ? products.find(product => product.productKey === gapProductKey) : null;
    const selectedGap = selectedProducts ? selectedProducts.find(product => product.productKey === gapProductKey) : null;
    if (isNewVehicle && gapProduct && !selectedGap) {
      this.dealService.dealInsuranceService.submitInsuranceProduct(gapProduct, 0);
    }
  }

  /**
   * Updates the gap term based off of the planned length of ownership
   * @param plannedLengthOfOwnership
   * @param products
   */
  updateGapTerm(plannedLengthOfOwnership, products) {
    const gapProduct = products ? products.find(product => product.productKey === InsuranceProductKeys.GAP) : null;
    if (gapProduct) {
      gapProduct.termCosts.forEach((termCost, index) => {
        if (termCost.term === plannedLengthOfOwnership) {
          gapProduct.selectedTerm = index;
        }
      });
    }
  }

  configProductGraphWidths(warrantyUi: WarrantyUi) {
    const {
      productGraphs,
      milesPerYear,
      maxOwnershipYears,
      vehicleAge,
      vehicleCondition,
      vehicleOdometer
    } = warrantyUi;

    productGraphs.forEach((graph: WarrantyBarGraph) => {
      const graphGroups = [[graph.product], ...graph.defaults];
      const calcTypes = ["product", "defaults"];

      graphGroups.forEach((group, i) => {
        const calcType = calcTypes[ i ] || calcTypes[ 1 ];
        let existingCoverageRatio = 0; // Max Ratio: 1 (100% Graph Width)

        group.forEach(segment => {
          let graphWidth = 0;  // Limit: 0 to 100
          let {term, miles} = segment;

          if (calcType === "defaults" && vehicleCondition !== "new") {
            // do not include age of used vehicle to deduct from graph of warranty chart length (#2107)
            //const usableTerm = term - (vehicleAge * 12);
            const usableTerm = term;
            term = usableTerm > 0 ? usableTerm : 0;
          }

          if (
            calcType === "defaults" ||
            vehicleCondition !== "used" &&
            vehicleCondition !== "certified"
          ) {
            // do not include used miles of used vehicle to deduct from graph of warranty chart length (#2107)
            const usableMiles = miles //- vehicleOdometer;
            miles = usableMiles > 0 ? usableMiles : 0;
          }

          // Width Calculation
          const limitYears = term / 12;
          const usageYears = miles / milesPerYear;

          const coverageYears = usageYears > limitYears ? limitYears : usageYears;
          const graphRatio = coverageYears / maxOwnershipYears;
          let normalizedRatio = graphRatio > 1 ? 1 : graphRatio;

          normalizedRatio -= existingCoverageRatio;
          existingCoverageRatio += normalizedRatio;

          graphWidth = normalizedRatio < 0 ? 0 : normalizedRatio * 100;
          if (graphWidth < 15) {
            graphWidth = 15;
          }
          segment.width = graphWidth;
        });
      });

    });
  }

  private auditWarrantyUi(warrantyUi: WarrantyUi) {
    const {productGraphs} = warrantyUi;
    if (productGraphs.length > 0) { return; }

    warrantyUi.maxViewIndex = 0;
    this.setPresentationComplete(true);
  }

}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AlertService } from "src/app/shared-module/services";
import { User } from "../../user-admin-module/models/user";
import { Observable } from "rxjs";
import { AuthState } from "../store/state/auth";
import * as authSelectors from "../store/selectors/auth";
import * as authActions from "../store/actions/auth.actions";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { USER_ROLES } from "src/app/app.config";
import { AppService } from "src/app/clearpath-module/services";

const routes = {
  login: "/v1/login",
  forgotPassword: "/v1/forgot",
  resetPassword: "/v1/reset-password",
  logout: token => `/v1/user/logout/${token}`,
  refreshToken: "/v1/refresh-token",
  verify: "/v1/token",
  read: (id: string) => `/v1/user/read/${id}`
};

@Injectable({providedIn: "root"})
export class AuthService {
  private tokenTimeout;
  token: string;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private appService: AppService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private store: Store<AuthState>
  ) { }

  // SELECTORS

  selectUser(): Observable<User> {
    return this.store.select(authSelectors.selectUser);
  }

  selectToken(): Observable<string> {
    return this.store.select(authSelectors.selectToken);
  }

  selectAuthState(): Observable<AuthState> {
    return this.store.select(authSelectors.selectAuth);
  }


  // DISPATCH

  public login({email, password}) {
    this.store.dispatch(authActions.login({email, password}));
  }

  public logout() {
    this.store.dispatch(authActions.logout());
  }

  public loginWithToken(token) {
    this.store.dispatch(authActions.loginWithToken({token}));
  }

  public refreshToken() {
    this.store.dispatch(authActions.refreshToken());
  }

  public verifyToken() {
    this.store.dispatch(authActions.verifyToken());
  }

  public clearUser() {
    this.store.dispatch(authActions.clearUser());
  }

  // HTTP

  logoutHttp(token: string) {
    return this.http.get<any>(routes.logout(token));
  }

  loginHttp({email, password}): Observable<any> {
    return this.http.post(routes.login, {email, password});
  }

  refreshTokenHttp(token: string) {
    return this.http.post<any>(routes.refreshToken, {token});
  }

  verifyTokenHttp(token: string) {
    return this.http.post<any>(routes.verify, {token});
  }

  loginWithTokenHttp(token): Observable<any> {
    const {id} = this.jwtHelper.decodeToken(token);
    return this.http.get(routes.read(id));
  }


  // REFRESH / TOKEN

  reuseToken() {
    this.selectToken().subscribe(token => {
      this.token = token;
    });
  }

  setTokenTimeout(token: string) {
    const expireDate = this.jwtHelper.getTokenExpirationDate(token);
    const timeRemaining = expireDate.getTime() - Date.now();
    const timeOut = timeRemaining - 3000;
    this.clearTokenTimeout();
    this.tokenTimeout = setTimeout(() => {
      // this.refreshToken();
      // this.appService.flutterMessage("hello");
      this.logout();
    }, timeOut);
    // refresh token 30 seconds before it expires
  }

  clearTokenTimeout() {
    clearTimeout(this.tokenTimeout);
    this.tokenTimeout = null;
  }

  // PASSWORD RESET

  public forgotPassword(email) {
    this.alertService.clear();
    return this.http
      .post<any>(routes.forgotPassword, {email})
      .toPromise()
      .then(() => {
        this.router.navigate(["/login"]);
        this.alertService.success(
          "Success! Please check your email for further instructions."
        );
        return true;
      })
      .catch(err => {
        this.alertService.error(err);
        return false;
      });
  }

  public resetPassword(username, password, passwordConfirmation, token) {
    this.alertService.clear();
    return this.http
      .post<any>(routes.resetPassword, {
        username,
        password,
        passwordConfirmation,
        token
      })
      .toPromise()
      .then(() => {
        this.router.navigate(["/login"]);
        this.alertService.success("Success! Your password has been updated.");
        return true;
      })
      .catch(err => {
        this.alertService.error(err);
        return false;
      });
  }

  // OTHER

  isSalesManager$(): Observable<boolean> {
    return this.selectUser().pipe(map((user: User) => {
      return this.isSalesManager(user);
    }));
  }

  isAdmin$(): Observable<boolean> {
    return this.selectUser().pipe(map((user: User) => {
      return this.isAdmin(user);
    }));
  }

  isSalesManager(user: User): boolean {
    if (!user) { return; }
    return User.unpackSecurityGroups(user.securityGroups).includes(USER_ROLES.sales_manager);
  }

  isAdmin(user: User): boolean {
    if (!user) { return; }
    return User.unpackSecurityGroups(user.securityGroups).includes(USER_ROLES.sys_admin);
  }
}

import { Injectable } from "@angular/core";
import { Accessory, Buyer, FinanceOptions, InsuranceProduct, LeaseOptions, TaxResult, TermCost, Vehicle } from "../../models";
import { VehicleService } from "../vehicle.service";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DealService } from "../deal/deal.service";
import { AppService } from "../app.service";
import { CalculationUtilityService } from "./calculation-utility.service";
import { DealIncentivesService } from "../deal/deal-incentives.service";
import { ZipTable, ZipTableItem } from "src/app/settings-module/models/zip-table";
import { CAT_TAX_RATE, COUNTY_FEES, ELECTRONIC_FILING_FEE, OREGON_REG_FEES_TABLE, PLATE_TRANSFER_FEE, PRIVILEGE_TAX_RATE, WASHINGTON_SALES_TAX } from "src/app/app.config";
import { pathOr } from "ramda";
import { FeesResult, FinancingSettings } from "../../models/calculations";
import Big from "big.js";
import { LeaseInsuranceProductKeys } from "../../models/insurance";
import { DealerAccessory } from "../../models/vehicle";
import { CalculationMemoryManager } from "./calculation-memory-manager/calculationMemoryManager";
import { DealIncentive } from "../../../settings-module/models/incentives";
import { LogManager } from "../../../util/logManager";
import { DealState } from "../../store/state";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class CalculationService {

  // uncomment for non-prod usage
  // private memory: CalculationMemoryManager = new CalculationMemoryManager(environment.name !== "production");
  // uncomment to disable calculation memory manager
  private memory: CalculationMemoryManager = new CalculationMemoryManager(false);

  public logManager = new LogManager(environment.name !== "production");

  /**
   * A cache for the last valid term used in a calculation.  A valid term is a valid number (not undefined or null) that's greater than 0.
   */
  private previousValidTerm = 0;

  constructor(
    private vehicleService: VehicleService,
    private dealService: DealService,
    private appService: AppService,
    private dealIncentivesService: DealIncentivesService,
    public calculationUtilityService: CalculationUtilityService
  ) { }

  calculateMonthlyPayment = this.calculationUtilityService.calculateMonthlyPayment;
  filterProductsByType = this.calculationUtilityService.filterProductsByType;

  getMilesPerYear(
    commuteMilesPerDay: number,
    commuteDaysPerWeek: number,
    otherMilesPerWeek: number
  ): number {
    const commuteMilesPerWeek = commuteMilesPerDay * commuteDaysPerWeek;
    const totalMilesPerWeek = commuteMilesPerWeek + otherMilesPerWeek;
    return totalMilesPerWeek * 52;
  }

  getFuelSavings(
    currentVehicleMPG: number,
    newVehicleMPG: number,
    milesPerMonth: number,
    fuelCostPerGallon: number
  ): number {
    const currentCost = (milesPerMonth / currentVehicleMPG) * fuelCostPerGallon;
    const newCost = (milesPerMonth / newVehicleMPG) * fuelCostPerGallon;
    return currentCost - newCost;
  }

  getDownPayment(
    interest: number,
    principal: number,
    monthlyPayment: number,
    loanTerm: number,
    netTradeEquity: number
  ): number {
    const total = interest + principal;
    const paid = monthlyPayment * loanTerm;
    const downPayment = total - paid - netTradeEquity;
    return downPayment > 0 ? downPayment : 0;
  }

  getSimpleInterest(
    principal: number,
    annualRate: number,
    loanTerm: number
  ): number {
    return principal * (annualRate / 100 / 12) * loanTerm;
  }

  /**
   * Net Equity = tradeValue - payOff.
   *
   * @param tradeValue
   * @param payOff
   */
  calcVehicleTradeEquity(
    tradeValue: number,
    payOff: number
  ): number {
    return tradeValue - payOff;
  }

  calcCustomizedVehiclePrice(
    vehicleRetail: number,
    selectedIncentivesTotal: number,
    accessories: Partial<Accessory>[]
  ): number {
    let price = vehicleRetail - selectedIncentivesTotal;
    price += this.calcAccessoriesTotal(accessories);
    return price;
  }

  calcBaseVehiclePrice(
    vehicleRetail: number,
    selectedIncentivesTotal: number,
  ): number {
    return vehicleRetail - selectedIncentivesTotal;
  }

  dealerAccessoriesTotal(vehicle, selectedDealerAccessories) {
    let total = 0;
    const dealerAccessories = this.vehicleService.parsePBSCustomFields(vehicle);
    dealerAccessories.forEach((acc: DealerAccessory) => {
      if (selectedDealerAccessories && selectedDealerAccessories.includes(acc.name)) {
        total += acc.price;
      }
    });
    return total;
  }

  calcAccessoriesTotal(accessories: Partial<Accessory>[]) {
    let price = 0;
    if (accessories && accessories.length) {
      accessories.forEach(accessory => {
        if (accessory.disabled || accessory.hidden) {
          return;
        } else {
          price += accessory.price;
        }
      });
    }
    return price;
  }

  calcVehicleBaseFinanceAmount(
    customizedVehiclePrice: number,
    totalTaxes: number,
    totalFees: number,
    tradeEquity: number,
    downPayment: number,
  ): number {
    const charges: number = Big(customizedVehiclePrice).add(totalFees).add(totalTaxes).toNumber();
    return Big(charges).minus(tradeEquity).minus(downPayment).round(2).toNumber();
  }

  calcDaysToFirstPayAmount(
    totalAmountFinanced: number,
    interestRate: number,
    daysToFirstPayment: number
  ) {
    return totalAmountFinanced * interestRate * ((daysToFirstPayment - 30) / 365);
  }

  calcFees$(): Observable<FeesResult> {
    return combineLatest([
      this.appService.selectZipTaxTable(),
      this.vehicleService.selectVehicle(),
      this.dealService.selectCustomer(),
      this.dealService.selectFinanceOptionsEdits(),
      this.dealService.selectFinanceOptions()
    ]).pipe(
      map(([
             zipTable,
             vehicle,
             customer,
             financeOptionsEdits,
             financeOptions
           ]: [
        ZipTable,
        Vehicle,
        Buyer,
        FinanceOptions,
        FinanceOptions
      ]) => {
        const vehicleCondition = this.vehicleService.vehicleCondition(vehicle);
        const regFeesTable = vehicleCondition === "new" ?
          OREGON_REG_FEES_TABLE.new :
          OREGON_REG_FEES_TABLE.used;
        let result: FeesResult;
        let docFees = financeOptions.docFees || 200;
        let totalFees = 0;
        let countyFee = 0;
        let titleFee = 0;
        let regFee = 0;
        let totalStateFees = 0;
        let homeState = "";
        let county = "";
        let foundZipTable: ZipTableItem;
        const dmvTotalFees = 0;
        const totalRegFee = 0;
        let eFilingFee = 0;

        if (!zipTable) {
          return;
        }
        // find item for customer's zip code
        const zipTaxTable = zipTable ? [...zipTable.oregon, ...zipTable.washington] : [];
        foundZipTable = (zipTaxTable || []).find((zipTableItem: ZipTableItem) => {
          return pathOr(null, ["zipCode"], zipTableItem) === parseInt(customer.zip, 10);
        });

        eFilingFee = financeOptionsEdits.eFilingFee === null ? ELECTRONIC_FILING_FEE : financeOptionsEdits.eFilingFee;

        if (!foundZipTable) {
          result = {
            totalFees: 0,
            countyFee: financeOptionsEdits.countyFee === null ?
              countyFee :
              financeOptionsEdits.countyFee,
            titleFee: financeOptionsEdits.titleFee === null ?
              titleFee :
              financeOptionsEdits.titleFee,
            docFees: financeOptionsEdits.docFees === null ?
              docFees :
              financeOptionsEdits.docFees,
            regFee: financeOptionsEdits.regFee === null ?
              regFee :
              financeOptionsEdits.regFee,
            totalStateFees: financeOptionsEdits.totalStateFees === null ?
              totalStateFees :
              financeOptionsEdits.totalStateFees,
            county,
            homeState,
            totalRegFee: 0, // set below
            dmvTotalFees,
            eFilingFee,
            plateTransferFeeWhenApplied: PLATE_TRANSFER_FEE,
          };
          result.totalRegFee = financeOptionsEdits.totalRegFee === null ?
            (regFee + countyFee) :
            financeOptionsEdits.totalRegFee;
          result.totalFees = result.countyFee +
            result.titleFee +
            result.docFees +
            result.regFee +
            result.totalStateFees +
            eFilingFee;
        } else {
          const isOregonZip = pathOr([], ["oregon"], zipTable)
            .find((zipTableItem: ZipTableItem) => {
              return pathOr(null, ["zipCode"], foundZipTable) === zipTableItem.zipCode;
            });
          const isWashingtonZip = pathOr([], ["washington"], zipTable)
            .find((zipTableItem: ZipTableItem) => {
              return pathOr(null, ["zipCode"], foundZipTable) === zipTableItem.zipCode;
            });

          // if county is included in the COUNTY_FEES object, then return the COUNTY_FEE
          const foundRegCounty = Object.keys(COUNTY_FEES).find((county: string) => {
            return county.toLowerCase() === foundZipTable.county.toLowerCase();
          });

          if (isOregonZip) {
            homeState = "OR";
          }

          if (isWashingtonZip) {
            homeState = "WA";
          }

          /* If county fee is in the COUNTY_FEES object, then use that county fee
          * Then add the rest of the fees from the zip table. */
          if (!foundRegCounty) {
            county = pathOr(false, ["county"], isWashingtonZip) ||
              pathOr(false, ["county"], isOregonZip);
            countyFee = foundZipTable.countyFee;
            regFee = foundZipTable.regFee;
            titleFee = foundZipTable.titleFee;
          } else {
            const countyFeeStruct = COUNTY_FEES[ foundRegCounty ];
            county = foundRegCounty;
            countyFee = vehicleCondition === "new" ? countyFeeStruct.new : countyFeeStruct.used;
            titleFee = foundZipTable.titleFee;
          }

          if (vehicle.mpg && isOregonZip) {
            const oregonRegFee = regFeesTable.find(regFee => {
              return vehicle.mpg <= regFee.maxMpg && vehicle.mpg >= regFee.minMpg;
            });
            regFee = financeOptionsEdits.regFee === null ?
              oregonRegFee.regFee :
              financeOptionsEdits.regFee;
            titleFee = oregonRegFee.titleFee;
          }

          //console.log("TitleFee:", titleFee)

          // #2131 set fees for all of washington state
          if (isWashingtonZip) {
            docFees = 200;
            regFee = 450;
            countyFee = 0;
            titleFee = 0;
            totalStateFees = 0;
            eFilingFee = financeOptionsEdits.eFilingFee === null ? 0.00000000001 : financeOptionsEdits.eFilingFee;
          } else {
            eFilingFee = financeOptionsEdits.eFilingFee === null ? ELECTRONIC_FILING_FEE : financeOptionsEdits.eFilingFee;
          }

          // use financeOptionsEdits to overwrite calculations if user has edited these values
          result = {
            totalFees: 0, // set below
            countyFee: financeOptionsEdits.countyFee === null ?
              countyFee :
              financeOptionsEdits.countyFee,
            titleFee: financeOptionsEdits.titleFee === null ?
              titleFee :
              financeOptionsEdits.titleFee,
            docFees: financeOptionsEdits.docFees === null ?
              docFees :
              financeOptionsEdits.docFees,
            regFee: financeOptionsEdits.regFee === null ?
              regFee :
              financeOptionsEdits.regFee,
            totalStateFees: financeOptionsEdits.totalStateFees === null ?
              totalStateFees :
              financeOptionsEdits.totalStateFees,
            county,
            totalRegFee: 0, // set below
            homeState,
            dmvTotalFees,
            eFilingFee: isWashingtonZip ? 0.00000000001 : eFilingFee,
            plateTransferFeeWhenApplied: PLATE_TRANSFER_FEE
          };
          result.totalRegFee = financeOptionsEdits.totalRegFee === null ?
            (result.regFee + result.countyFee) :
            financeOptionsEdits.totalRegFee;
          result.dmvTotalFees = financeOptionsEdits.dmvTotalFees === null ?
            (result.titleFee + result.totalRegFee) :
            financeOptionsEdits.dmvTotalFees;
          result.totalFees = result.countyFee + result.titleFee + result.docFees + result.regFee + (result.totalStateFees || 0) + eFilingFee;
        }
        if (financeOptions.plateTransfer) {
          result.countyFee = 0;
          result.regFee = PLATE_TRANSFER_FEE;
          result.totalRegFee = PLATE_TRANSFER_FEE;
          result.totalFees = result.countyFee + result.titleFee + result.docFees + result.regFee + (result.totalStateFees || 0) + eFilingFee;
        }
        return result;

      }));

  }

  calcTax$({basePriceOnly, productsOnly = false, leaseSelected, finance, term, memoryTag}: {
    basePriceOnly?: boolean,
    productsOnly?: boolean,
    leaseSelected?: boolean,
    finance?: boolean,
    term?: number,
    memoryTag?: string
  } = {}): Observable<TaxResult> {
    return combineLatest([
      this.appService.selectZipTaxTable(),
      this.vehicleService.selectVehicle(),
      this.dealService.selectAccessories(),
      this.dealService.selectLeaseOptions(),
      this.dealService.selectCustomSalesTaxRateFinance(),
      this.dealService.selectCustomer(),
      this.totalFinanceInsuranceProductsPrice$({term}),
      this.calcTotalLeaseInsuranceProductsPrice$(term),
      // this.totalFinanceInsuranceProductsCostToDealer$(term),
      // this.totalLeaseInsuranceProductsCostToDealer$(term),
      this.customizedVehiclePrice$({lease: leaseSelected, finance}),
      this.calcFees$(),
      this.dealService.selectFinanceOptions(),
      this.dealService.selectFinanceOptionsEdits(),
      this.dealService.selectIncentives(),
      this.dealService.dealInsuranceService.selectInsuranceProducts(),
      this.dealService.selectSelectedDealerAccessories()
      // this.dealService.Insurance.selectInsuranceProducts(),
      // this.dealService.selectTradeIn(),
      // this.dealService.selectTradeIn2(),
    ]).pipe(
      map(([
             zipTable,
             vehicle,
             accessories,
             leaseOptions,
             customSalesTaxRate,
             customer,
             financeInsuranceProductsTotal,
             leaseInsuranceProductsTotal,
             // financeInsuranceProductsTotalCost,
             // leaseInsuranceProductsTotalCost,
             customizedVehiclePrice,
             fees,
             financeOptions,
             financeOptionsEdits,
             incentives,
             insuranceProducts,
             selectedDealerAccessories
             // insuranceProducts,
             // tradeIn,
             // tradeIn2,
           ]:
             [
               ZipTable,
               Vehicle,
               Accessory[],
               LeaseOptions,
               number,
               Buyer,
               number,
               number,
               // number,
               // number,
               number,
               FeesResult,
               FinanceOptions,
               FinanceOptions,
               DealIncentive[],
               InsuranceProduct[],
               string[]
               // InsuranceProduct[],
               // TradeIn,
               // TradeIn,
             ]
      ) => {
        basePriceOnly = basePriceOnly ?? false
        const vehicleCondition = this.vehicleService.vehicleCondition(vehicle);
        const retail = financeOptionsEdits.retail || vehicle.retail;
        const financePrice = vehicle.retail;
        let privilegeTax = 0;
        let salesTax = 0;
        let catTax = 0;
        let totalStateTaxes = financeOptionsEdits.totalStateTaxes || financeOptions.totalStateTaxes;
        let totalTax = 0;
        let homeState = "";
        let foundZipTable: ZipTableItem;
        const accessoriesTotal = this.calcAccessoriesTotal(accessories);

        // if finance (is selected) is undefined, use the value in finance options.
        finance = finance === undefined && financeOptions ? financeOptions.financeSelected : finance;

        // only check for lease selected if finance is not true
        if (finance === null || finance === undefined) {
          leaseSelected = typeof leaseSelected === "undefined" ?
            leaseOptions.leaseSelected :
            leaseSelected;
        }

        // find item for customer's zip code
        const zipTaxTable = zipTable ? [...zipTable.oregon, ...zipTable.washington] : [];
        foundZipTable = (zipTaxTable || []).find((zipTableItem: ZipTableItem) => {
          return pathOr(null, ["zipCode"], zipTableItem) === parseInt(customer.zip, 10);
        });
        const isOregonZip = pathOr([], ["oregon"], zipTable)
          .find((zipTableItem: ZipTableItem) => {
            return pathOr(null, ["zipCode"], foundZipTable) === zipTableItem.zipCode;
          });
        const isWashingtonZip = pathOr([], ["washington"], zipTable)
          .find((zipTableItem: ZipTableItem) => {
            return pathOr(null, ["zipCode"], foundZipTable) === zipTableItem.zipCode;
          });

        // ((MSRP - discount + accessorySum + financeProductSum + docFees) - ((vehicleCosts + financeCosts)* .35) * .0057
        let insuranceProductsTotal = 0;
        let insuranceProductsTotalCost = 0;

        const leaseInsuranceProductsTotalCost = this.totalLeaseInsuranceProductsCostToDealer(leaseOptions, insuranceProducts, term);
        const financeInsuranceProductsTotalCost = financeOptions.cashPurchase ?
          this.totalCashInsuranceProductsCostToDealer(insuranceProducts) :
          this.totalFinanceInsuranceProductsCostToDealer(insuranceProducts, term);

        if (!basePriceOnly || productsOnly) {
          if (leaseSelected) {
            // lease
            insuranceProductsTotal = leaseInsuranceProductsTotal;
            insuranceProductsTotalCost = leaseInsuranceProductsTotalCost;
          } else if (finance) {
            // finance
            insuranceProductsTotal = financeInsuranceProductsTotal;
            insuranceProductsTotalCost = financeInsuranceProductsTotalCost;
          } else {
            // cash
            insuranceProductsTotal = financeInsuranceProductsTotal;
            insuranceProductsTotalCost = financeInsuranceProductsTotalCost;
          }
        }

        let totalPrice = Big(0);
        let totalCost = Big(0);

        if (productsOnly) {
          totalPrice = Big(insuranceProductsTotal);
          totalCost = Big(insuranceProductsTotalCost);
        } else if (customizedVehiclePrice) {

          // #1934: Calculate totalDownRebateTrade & incentives total here locally so that cat tax is calculated correctly every time
          const {adjustedPrice,} = this.dealIncentivesService.applyCashIncentives({
            price: 0,
            incentives,
            leaseOptions,
            financeOptions,
            leaseSelected,
            term
          });
          const incentivesTotal = Math.abs(adjustedPrice);
          financeOptions.selectedRebateValue = incentivesTotal;
          const leaseDownRebate = incentivesTotal + financeOptions.downPayment - leaseOptions.monthlyPayment;
          const nonLeaseDownRebate = incentivesTotal + financeOptions.downPayment;
          financeOptions.totalDownRebate = leaseSelected ? leaseDownRebate : nonLeaseDownRebate;

          /*
          // Issue #1934: As requested in this comment: https://github.com/russauto/clearpath-cloud/issues/1934#issuecomment-1176686644
          // We're removing totalDownRebateTrade from the cat calc equation.
          const tradeValue = tradeIn.tradeValue + tradeIn2.tradeValue;
          const tradeValueNonNegative = tradeValue > 0 ? tradeValue : 0;
          const totalDownRebateTrade = financeOptions.totalDownRebate + tradeValueNonNegative;
          const calcNumber1 = Big(retail).add(accessoriesTotal).sub(totalDealerAccCost).add(insuranceProductsTotal).add(fees.docFees).sub(totalDownRebateTrade).sub(pack);
          */

          const totalDealerAccPrice = this.getTotalDealerAccPriceForVehicle(vehicle, selectedDealerAccessories);
          const totalDealerAccCost = this.getTotalDealerAccCostForVehicle(vehicle, selectedDealerAccessories);
          const pack = vehicleCondition === "used" ? 650 : 0;
          const askingPrice = retail;
          const optionalAccPrice = accessoriesTotal;
          const totalRebates = financeOptions.selectedRebateValue;
          const vehicleInvoiceCost = vehicle.order.price;
          const docFees = fees?.docFees || 0;
          totalPrice = Big(askingPrice).add(optionalAccPrice).add(totalDealerAccPrice).add(insuranceProductsTotal).add(docFees).sub(totalRebates);
          totalCost = Big(vehicleInvoiceCost).add(totalDealerAccCost).add(insuranceProductsTotalCost).sub(pack);
        }
        let finalCatTax = 0;
        if (isOregonZip) {
          const costRate = .35;
          const catTaxRate = CAT_TAX_RATE;
          const cost35 = Big(totalCost).times(costRate);
          const netPrice = totalPrice.sub(cost35);
          finalCatTax = netPrice.times(catTaxRate).round(2, Big.roundUp).toNumber();
        }

        /* const catTaxParts = {
          optionalAccPrice,
          askingPrice,
          costRate,
          totalDealerAccCost,
          insuranceProductsTotal,
          totalDealerAccPrice,
          docFees,
          totalRebates,
          pack,
          vehicleInvoiceCost,
          insuranceProductsTotalCost,
          catTaxRate,
          subCalculations: {
            totalPrice: totalPrice.round(6, Big.roundUp).toNumber(),
            totalCost: totalCost.round(6, Big.roundUp).toNumber(),
            cost35: cost35.round(6, Big.roundUp).toNumber(),
            netPrice: netPrice.round(6, Big.roundUp).toNumber(),
            finalCatTax
          },
          extra: {
            "financeOptionsEdits.catTax": financeOptionsEdits.catTax,
            isOregonZip,
            isWashingtonZip,
            homeState,
            vehicleCondition,
            financeInsuranceProductsTotalCost,
            financeInsuranceProductsTotal,
            basePriceOnly
          }
        }; */

        catTax = financeOptionsEdits.catTax === null ? finalCatTax : financeOptionsEdits.catTax;

        totalTax = Big(totalTax).add(catTax).round(2).toNumber();

        if (!productsOnly) {
          if (isOregonZip) {
            homeState = "OR";
            if (vehicleCondition === "new") {
              privilegeTax = financeOptionsEdits.privilegeTax === null ? Big(customizedVehiclePrice).times(PRIVILEGE_TAX_RATE).round(2).toNumber() : financeOptionsEdits.privilegeTax;
              salesTax = 0;
              totalTax = Big(totalTax).add(privilegeTax).round(2).toNumber();
            }
          }
          if (isWashingtonZip) {
            homeState = "WA";
            privilegeTax = financeOptionsEdits.privilegeTax === null ? 0 : financeOptionsEdits.privilegeTax;
            if (customSalesTaxRate) {
              totalTax = Big(financePrice).times(customSalesTaxRate).round(2).toNumber();
            } else {
              totalTax = Big(totalTax).add(salesTax).add(privilegeTax).round(2).toNumber();
            }
          }
          if (homeState === "OR") {
            salesTax = 0;
          } else {
            if (homeState === "WA") {
              salesTax = financeOptionsEdits.salesTax === null ?
                (Big(customizedVehiclePrice).times(WASHINGTON_SALES_TAX)).round(2).toNumber() : financeOptionsEdits.salesTax;
            } else {
              salesTax = financeOptionsEdits.salesTax === null ? 0 : financeOptionsEdits.salesTax;
            }
            totalTax += salesTax;
          }
        }

        /* if (memoryTag) {
          this.memory.add(memoryTag, "calcTax$", {
            totalTax,
            customizedVehiclePrice,
            privilegeTax,
            salesTax,
            catTax,
            "financeOptionsEdits.catTax": financeOptionsEdits.catTax,
            finalCatTax,
            catTaxParts,
            homeState,
            basePriceOnly,
            accessoriesTotal,
            insuranceProductsTotal,
            insuranceProductsTotalCost,
            financeInsuranceProductsTotalCost,
            finance,
            leaseSelected,
          });
        } */

        if (totalStateTaxes > 0) {
          totalTax += totalStateTaxes;
        }

        return {
          privilegeTax,
          salesTax,
          catTax,
          totalTax,
          totalStateTaxes,
          homeState
        };

        /*const taxResult = {
          privilegeTax: financeOptionsEdits.privilegeTax === null ? privilegeTax : financeOptionsEdits.privilegeTax,
          salesTax: financeOptionsEdits.salesTax === null ? salesTax : financeOptionsEdits.salesTax,
          catTax: financeOptionsEdits.catTax === null ? catTax : financeOptionsEdits.catTax,
          totalStateTaxes: financeOptionsEdits.totalStateTaxes === null ? totalStateTaxes : financeOptionsEdits.totalStateTaxes,
          totalTax,
          homeState
        };*/

        /* if (memoryTag) {
          this.memory.add(memoryTag, "calcTax$", {
            totalTax,
            customizedVehiclePrice,
            privilegeTax,
            salesTax,
            catTax,
            "financeOptionsEdits.catTax": financeOptionsEdits.catTax,
            homeState,
            basePriceOnly,
            accessoriesTotal,
            insuranceProductsTotal,
            insuranceProductsTotalCost,
            financeInsuranceProductsTotalCost,
            finance,
            leaseSelected,
          });
        } */

        // return taxResult;
      }));
  }

  /**
   * For a given Vehicle, return the total dealer accessory price.
   *
   * @param vehicle Vehicle
   */
  getTotalDealerAccPriceForVehicle(vehicle: Vehicle, selectedDealerAccessories: string[] | null): number {
    let totalDealerAccPrice = 0;
    if (vehicle?.customFields?.length > 0) {
      vehicle.customFields.forEach(customField => {
        if (customField.key.startsWith("DealerAccPrice")) {
          if (this.isDealerAccessorySelected(vehicle, customField.key.replace("Price", "Desc"), selectedDealerAccessories)) {
            totalDealerAccPrice += Number(customField.value);
          }
        }
      });
    }
    return totalDealerAccPrice;
  }

  /**
   * For a given Vehicle, return the total dealer accessory cost.
   *
   * @param vehicle Vehicle
   */
  getTotalDealerAccCostForVehicle(vehicle: Vehicle, selectedDealerAccessories: string[] | null): number {
    let totalDealerAccCost = 0;
    if (vehicle?.customFields?.length > 0) {
      vehicle.customFields.forEach(customField => {
        if (customField.key.startsWith("DealerAccCost")) {
          if (this.isDealerAccessorySelected(vehicle, customField.key.replace("Cost", "Desc"), selectedDealerAccessories)) {
            totalDealerAccCost += Number(customField.value);
          }
        }
      });
    }
    return totalDealerAccCost;
  }

  /**
   * For a given key, see if it exists in selectedDealerAccessories.
   *
   * @param vehicle
   * @param key
   * @param selectedDealerAccessories
   */
  isDealerAccessorySelected(vehicle: Vehicle, key: string, selectedDealerAccessories: string[] | null) {
    let found = false;
    if (vehicle?.customFields?.length > 0) {
      vehicle.customFields.forEach(customField => {
        if (customField.key === key) {
          if (selectedDealerAccessories && selectedDealerAccessories.includes(customField.value)) {
            found = true;
          }
        }
      });
    }
    return found;
  }

  // OBSERVABLES

  baseVehiclePrice$({withoutIncentives}: { withoutIncentives?: boolean } = {}): Observable<number> {
    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle()
    ]).pipe(
      map(([deal, vehicle]) => {
        const retail = deal.financeOptionsEdits.retail === null ?
          vehicle.retail :
          deal.financeOptionsEdits.retail;
        if (!retail || !vehicle.msr) {
          return 0;
        }
        const {adjustedPrice, incentivesApplied} = this.dealIncentivesService.applyCashIncentives({
          price: 0,
          incentives: deal.incentives,
          leaseOptions: deal.leaseOptions,
          leaseSelected: deal.leaseOptions.leaseSelected,
          financeOptions: deal.financeOptions
        });
        return this.calcBaseVehiclePrice(
          retail || vehicle.msr,
          withoutIncentives ? 0 : Math.abs(adjustedPrice)
        );
      })
    );
  }

  vehicleBaseFinanceAmount$({
                              withoutTax,
                              basePriceOnly = true,
                              actualTrade,
                              excludeIncentives,
                              excludeDown,
                              finance,
                              forceCashIncentive,
                              term,
                              memoryTag
                            }: {
    withoutTax?: boolean,
    basePriceOnly?: boolean,
    actualTrade?: boolean,
    excludeIncentives?: boolean,
    excludeDown?: boolean,
    finance?: boolean,
    forceCashIncentive?: boolean,
    term?: number,
    memoryTag?: string
  } = {}): Observable<number> {
    return combineLatest([
      this.customizedVehiclePrice$({excludeIncentives, finance, forceCashIncentive, term}),
      this.dealService.selectFinanceOptions(),
      this.dealService.selectFinanceOptionsEdits(),
      actualTrade ? this.actualTradeEquity$() : this.tradeEquityEstimate$(),
      this.calcTax$({basePriceOnly, finance, term, memoryTag}),
      this.calcFees$()
    ]).pipe(
      map(([
             customizedVehiclePrice,
             financeOptions,
             financeOptionsEdits,
             calcTradeEquityEstimate,
             taxResult,
             feesResult
           ]) => {
        const totalTax = withoutTax ? 0 : taxResult.totalTax || 0;
        const totalFees = financeOptionsEdits?.totalFees || feesResult?.totalFees || 0;
        const downPayment = excludeDown ? 0 : financeOptions.downPayment || 0;
        const baseFinanceAmount = this.calcVehicleBaseFinanceAmount(
          customizedVehiclePrice,
          totalTax,
          totalFees,
          calcTradeEquityEstimate,
          downPayment
        );

        if (memoryTag) {
          this.memory.add(memoryTag, "vehicleBaseFinanceAmount$", {
            baseFinanceAmount,
            customizedVehiclePrice,
            totalTax,
            "calcTax$": this.memory.get(memoryTag, "calcTax$"),
            totalFees,
            "totalFees-financeOptions": financeOptions,
            "totalFees-financeOptionsEdits": financeOptionsEdits,
            calcTradeEquityEstimate,
            downPayment
          });
        }

        return baseFinanceAmount;
      })
    );
  }

  accessoriesTotal$() {
    return this.dealService.selectAccessories()
      .pipe(map((accessories: Accessory[]) => {
        return this.calcAccessoriesTotal(accessories);
      }));
  }

  tradeEquityEstimate$(): Observable<number> {
    return this.dealService.selectDeal().pipe(
      map(deal => {
        return this.calcVehicleTradeEquity(
          (deal.tradeIn?.tradeEstimate || 0) + (deal.tradeIn2?.tradeEstimate || 0),
          (deal.tradeIn?.payOffEstimate || 0) + (deal.tradeIn2?.payOffEstimate || 0)
        );
      })
    );
  }

  actualTradeEquity$(): Observable<number> {
    return this.dealService.selectDeal().pipe(
      map(deal => {
        return this.calcVehicleTradeEquity(
          (deal.tradeIn?.tradeEstimate || 0) + (deal.tradeIn2?.tradeEstimate || 0),
          (deal.tradeIn?.payOffEstimate || 0) + (deal.tradeIn2?.payOffEstimate || 0)
        );
      })
    );
  }

  tradeEquity$(): Observable<number> {
    return combineLatest([this.actualTradeEquity$(), this.tradeEquityEstimate$()])
      .pipe(map(([actualTradeEquity, tradeEquityEstimate]) => {
        return actualTradeEquity || tradeEquityEstimate;
      }));
  }

  customizedVehiclePrice$({
                            excludeIncentives,
                            finance,
                            lease,
                            forceCashIncentive,
                            term
                          }: {
    excludeIncentives?: boolean,
    finance?: boolean,
    lease?: boolean,
    forceCashIncentive?: boolean,
    term?: number
  } = {}): Observable<number> {
    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.dealService.selectAccessories(),
    ]).pipe(
      map(([deal, vehicle, accessories]) => {
        const retail = deal.financeOptionsEdits.retail === null ?
          vehicle.retail :
          deal.financeOptionsEdits.retail;
        if (!retail) {
          return 0;
        }

        const dealerAccessories = this.vehicleService.parsePBSCustomFields(vehicle).filter(accessory => deal && deal.selectedDealerAccessories && deal.selectedDealerAccessories.includes(accessory.name));

        const {adjustedPrice, incentivesApplied} = this.dealIncentivesService.applyCashIncentives({
          price: 0,
          incentives: deal.incentives,
          leaseOptions: deal.leaseOptions,
          leaseSelected: finance ? false : lease ? true : deal.leaseOptions.leaseSelected,
          financeOptions: deal.financeOptions,
          forceCashIncentive,
          term
        });
        if (
          incentivesApplied.includes("leaseSubvention") ||
          incentivesApplied.includes("financeSubvention") ||
          incentivesApplied.includes("collegeRebate") ||
          incentivesApplied.includes("militaryRebate") ||
          incentivesApplied.includes("leaseCash") ||
          incentivesApplied.includes("financeCash") ||
          incentivesApplied.includes("customerLoyalty500") ||
          incentivesApplied.includes("customerLoyalty1000") ||
          incentivesApplied.includes("uberRebate")) {
          excludeIncentives = false;
        }
        const customizedPrice = this.calcCustomizedVehiclePrice(
          retail || vehicle.msr,
          excludeIncentives ? 0 : Math.abs(adjustedPrice),
          [...(accessories || []), ...dealerAccessories]
        );
        return customizedPrice;
      })
    );
  }

  getEffectiveTaxRate$(): Observable<number> {
    return combineLatest([
      this.vehicleService.selectVehicle(),
      this.dealService.selectFinanceOptions(),
      this.dealService.selectCustomer()
    ]).pipe(map(([vehicle, financeOptions, customer]) => {
      return this.getEffectiveTaxRate(vehicle, financeOptions, customer);
    }));
  }

  getEffectiveTaxRate(vehicle: Vehicle, financeOptions: FinanceOptions, customer: Buyer): number {
    let taxRate = 0;
    if (customer.state.toLowerCase() === "or") {
      if (this.vehicleService.vehicleCondition(vehicle) === "new") {
        taxRate += PRIVILEGE_TAX_RATE;
        taxRate += CAT_TAX_RATE;
      }
    }
    if (customer.state.toLowerCase() === "wa") {
      if (financeOptions.customSalesTaxRate) {
        taxRate = financeOptions.customSalesTaxRate;
      } else {
        taxRate += WASHINGTON_SALES_TAX;
      }
    }
    return taxRate;
  }

  /**
   * @usageNotes Returns current selected financing interest rate
   */
  findInterestRate$(
    {term, excludeIncentives}:
      { term?: number, excludeIncentives?: boolean } = {}
  ): Observable<number> {
    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.appService.selectFinancing()
    ]).pipe(
      map(([
             deal,
             vehicle,
             financingSettings
           ]) => {
        if (deal.financeOptions.customSelected) {
          return deal.financeOptions.customerProvidedInterestRate;
        } else {
          return this.vehicleService.findInterestRate(
            vehicle,
            deal,
            financingSettings,
            term,
            excludeIncentives
          );
        }
      })
    );
  }

  isIncentiveRate$({term}: { term?: number } = {}): Observable<boolean> {
    return this.dealService.selectDeal()
      .pipe(
        map((deal) => {
          if (deal.financeOptions.customSelected) {
            return !!deal.financeOptions.customerProvidedInterestRate;
          } else {
            const filteredTerms = this.vehicleService.findIncentiveRate(deal.incentives, term);
            const tieredRates = this.vehicleService.getTieredRates(filteredTerms, term);
            const selectedRate = tieredRates[ deal.financeOptions.selectedCreditTier ];
            return !!selectedRate;
          }
        })
      );
  }

  /**
   * @usageNotes Returns default financing interest rate
   */
  findStandardInterestRate$(term?: number): Observable<number> {
    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.appService.selectFinancing()
    ]).pipe(
      map(([
             deal,
             vehicle,
             financingSettings
           ]) => {
        return this.vehicleService.findInterestRate(
          vehicle,
          deal,
          financingSettings,
          term,
          true
        );
      })
    );
  }

  /**
   * @usageNotes Returns total price of finance insurance products
   */
  totalInsuranceProductsPrice$(): Observable<number> {
    return this.dealService.dealInsuranceService.selectInsuranceProducts().pipe(
      map(insuranceProducts => {
        if (!insuranceProducts) { return 0; }
        let total = 0;
        insuranceProducts.forEach(product => {
          const selectedTerm: TermCost = product?.termCosts[ product.selectedTerm ] || null;
          if (selectedTerm !== null) {
            try {
              const productPrice = selectedTerm.priceOverride === null ? selectedTerm.price : selectedTerm.priceOverride;
              total += productPrice || 0;
            } catch (e) {
              //console.log('e:', e)
            }
          }
        });
        return total;
      })
    );
  }

  /**
   * @usageNotes Returns total price of cash insurance products
   * filters products by cash
   */
  totalCashInsuranceProductsPrice$(): Observable<number> {
    return this.dealService.dealInsuranceService.selectInsuranceProducts().pipe(
      map(insuranceProducts => {
        insuranceProducts = this.calculationUtilityService.filterProductsByType(insuranceProducts, "cash");
        if (!insuranceProducts) { return 0; }
        let total = 0;
        insuranceProducts.forEach(product => {
          const selectedTerm: TermCost = product?.termCosts[ product.selectedTerm ] || null;
          if (selectedTerm !== null) {
            const productPrice = selectedTerm.priceOverride === null ? selectedTerm.price : selectedTerm.priceOverride;
            total += productPrice || 0;
          }
        });
        return total;
      })
    );
  }

  /**
   * @usageNotes Returns total price of finance insurance products
   * filters products by finance
   */
  totalFinanceInsuranceProductsPrice$(
    {term}:
      { term?: number, findClosestTerm?: boolean } = {}
  ): Observable<number> {
    return combineLatest([
      this.dealService.dealInsuranceService.selectInsuranceProducts(),
      this.dealService.selectFinanceOptions()
    ]).pipe(
      map(([insuranceProducts, financeOptions]) => {
        term = term || financeOptions.selectedFinancingTerm;
        insuranceProducts = this.calculationUtilityService.filterProductsByType(insuranceProducts, "finance");
        if (!insuranceProducts) { return 0; }
        let total = 0;
        insuranceProducts.forEach(product => {
          if (term) {
            // if (findClosestTerm) {
            //   const closestTerm = this.dealService.Insurance.findClosestTerm(product, term);
            //   const termCostIndex = product
            //     .termCosts.findIndex((termCost: TermCost) => {
            //       return closestTerm.term === termCost.term;
            //     });
            //   product.selectedTerm = termCostIndex;
            // } else {
            product = this.dealService.dealInsuranceService.setHardcodedTerm(product, term);
            // }
          }
          const selectedTerm: TermCost = product?.termCosts[ product.selectedTerm ] || null;
          if (selectedTerm !== null) {
            const productPrice = selectedTerm.priceOverride === null ? selectedTerm.price : selectedTerm.priceOverride;
            total += productPrice || 0;
          }
        });
        return total;
      })
    );
  }

  /**
   * @usageNotes Returns total cost of lease insurance products
   * filters products by lease
   */
  totalLeaseInsuranceProductsCostToDealer$(term?: number, withoutTax?: boolean): Observable<number> {
    return combineLatest([
      this.dealService.selectLeaseOptions(),
      this.dealService.dealInsuranceService.selectInsuranceProducts()
    ]).pipe(map(([leaseOptions, insuranceProducts]) => {
      let insuranceProductsTotal = 0;
      term = term || leaseOptions.selectedLeaseTerm;
      insuranceProducts = insuranceProducts || [];
      this.filterProductsByType(insuranceProducts, "lease")
        .forEach(product => {
          const isLeaseProductKey = Object.values(LeaseInsuranceProductKeys)
            .includes(product.productKey);
          if (!isLeaseProductKey) {
            return;
          }
          const prod = Object.assign({}, product);
          const closestTerm: TermCost = this.dealService.dealInsuranceService.findClosestTerm(
            prod,
            term || leaseOptions.selectedLeaseTerm
          );
          if (!closestTerm) {
            return;
          }
          insuranceProductsTotal += pathOr(0, ["termCosts", Math.max(0, product.selectedTerm), "cost"], product);

        });
      return insuranceProductsTotal;
    }));
  }


  /**
   * @usageNotes Returns total cost of lease insurance products
   * filters products by lease
   */
  totalLeaseInsuranceProductsCostToDealer(leaseOptions, insuranceProducts: InsuranceProduct[], term?: number): number {
    let insuranceProductsTotal = 0;
    term = term || leaseOptions.selectedLeaseTerm;
    insuranceProducts = insuranceProducts || [];
    this.filterProductsByType(insuranceProducts, "lease")
      .forEach(product => {
        const isLeaseProductKey = Object.values(LeaseInsuranceProductKeys)
          .includes(product.productKey);
        if (!isLeaseProductKey) {
          return;
        }
        const prod = Object.assign({}, product);
        const closestTerm: TermCost = this.dealService.dealInsuranceService.findClosestTerm(
          prod,
          term || leaseOptions.selectedLeaseTerm
        );
        if (!closestTerm) {
          return;
        }
        insuranceProductsTotal += pathOr(0, ["termCosts", Math.max(0, product.selectedTerm), "cost"], product);

      });
    return insuranceProductsTotal;
  }

  calcTotalLeaseInsuranceProductsPrice$(term?: number, withoutTax?: boolean): Observable<number> {
    return combineLatest([
      this.dealService.selectLeaseOptions(),
      this.dealService.dealInsuranceService.selectInsuranceProducts()
    ]).pipe(map(([leaseOptions, insuranceProducts]) => {
      let insuranceProductsTotal = 0;
      term = term || leaseOptions.selectedLeaseTerm;
      insuranceProducts = insuranceProducts || [];
      this.filterProductsByType(insuranceProducts, "lease")
        .forEach(product => {
          const isLeaseProductKey = Object.values(LeaseInsuranceProductKeys)
            .includes(product.productKey);
          if (!isLeaseProductKey) {
            return;
          }
          const prod = Object.assign({}, product);
          const closestTerm: TermCost = this.dealService.dealInsuranceService.findClosestTerm(
            prod,
            term || leaseOptions.selectedLeaseTerm
          );
          if (!closestTerm) {
            return;
          }
          insuranceProductsTotal += closestTerm.priceOverride === null ? closestTerm.price : closestTerm.priceOverride;
        });
      return insuranceProductsTotal;
    }));
  }


  /**
   * @usageNotes Returns total cost of cash insurance products
   * filters products by cash
   */
  totalCashInsuranceProductsCostToDealer$(): Observable<number> {
    return this.dealService.dealInsuranceService.selectInsuranceProducts().pipe(
      map(insuranceProducts => {
        insuranceProducts = this.calculationUtilityService.filterProductsByType(insuranceProducts, "cash");
        if (!insuranceProducts) { return 0; }
        let total = 0;
        insuranceProducts.forEach(product => {
          total += product.termCosts[ product.selectedTerm ].cost;
        });
        return total;
      })
    );
  }

  /**
   * @usageNotes Returns total cost of cash insurance products
   * filters products by cash
   */
  totalCashInsuranceProductsCostToDealer(insuranceProducts: InsuranceProduct[]): number {
    insuranceProducts = this.calculationUtilityService.filterProductsByType(insuranceProducts, "cash");
    if (!insuranceProducts) { return 0; }
    let total = 0;
    insuranceProducts.forEach(product => {
      total += product.termCosts[ product.selectedTerm ].cost;
    });
    return total;
  }

  /**
   * @usageNotes Returns total price of finance insurance products
   * filters products by finance
   */
  totalFinanceInsuranceProductsCostToDealer$(term?: number): Observable<number> {
    return this.dealService.dealInsuranceService.selectInsuranceProducts().pipe(
      map(insuranceProducts => {
        // if we get an invalid term as an input, use the previous valid term.
        term = !term ? this.previousValidTerm : term;
        this.previousValidTerm = term ? term : this.previousValidTerm;
        insuranceProducts = this.calculationUtilityService.filterProductsByType(insuranceProducts, "finance");
        if (!insuranceProducts) { return 0; }
        let total = 0;
        insuranceProducts.forEach(product => {
          if (term) {
            product = this.dealService.dealInsuranceService.setHardcodedTerm(product, term);
            total += product?.termCosts[ product.selectedTerm ]?.cost || 0;
          }
        });
        return total;
      })
    );
  }


  /**
   * @usageNotes Returns total price of finance insurance products
   * filters products by finance
   */
  totalFinanceInsuranceProductsCostToDealer(insuranceProducts: InsuranceProduct[], term?: number): number {
    // if we get an invalid term as an input, use the previous valid term.
    term = !term ? this.previousValidTerm : term;
    this.previousValidTerm = term ? term : this.previousValidTerm;
    insuranceProducts = this.calculationUtilityService.filterProductsByType(insuranceProducts, "finance");
    if (!insuranceProducts) { return 0; }
    let total = 0;
    insuranceProducts.forEach(product => {
      if (term) {
        product = this.dealService.dealInsuranceService.setHardcodedTerm(product, term);
        total += product?.termCosts[ product.selectedTerm ]?.cost || 0;
      }
    });
    return total;
  }

  /**
   * @usageNotes Gets monthly payment price with everything included
   * Including accessories and insuranceOptions, taxes etc.
   * like totalVehicleFinancePrice$(), but for monthly payments
   * optional term argument allows for a calculation with a specific term
   * optional specifiedInterestRate argument allows for a calculation with a specific interest rate
   */
  calculateTotalVehicleFinanceMonthlyPayment$(
    {
      term,
      withoutDaysToFirstPay,
      interestRate,
      excludeIncentives,
      findClosestTerm,
      forceCashIncentive,
      finance,
      memoryTag,
    }: {
      term?: number,
      withoutDaysToFirstPay?: boolean,
      interestRate?: number,
      excludeIncentives?: boolean,
      findClosestTerm?: boolean,
      forceCashIncentive?: boolean,
      finance?: boolean,
      memoryTag?: string,
    } = {}
  ): Observable<number> {

    return of(null).pipe(
      switchMap(() => combineLatest([
        this.dealService.selectFinanceOptions(),
        this.dealService.selectLeaseOptions()
      ])),

      map(([financeOptions, leaseOptions]) => {
        term = term || financeOptions.selectedFinancingTerm || leaseOptions.selectedLeaseTerm;
      }),

      switchMap(() => combineLatest([
        this.findInterestRate$({term, excludeIncentives})
      ])),

      map(([stateInterestRate]) => {
        interestRate = typeof interestRate === "undefined" ? (stateInterestRate || 0) : interestRate;
      }),

      switchMap(() => combineLatest([
        this.totalVehicleFinancePrice$({
          withoutDaysToFirstPay,
          interestRate,
          term,
          excludeIncentives,
          findClosestTerm,
          forceCashIncentive,
          finance,
          memoryTag
        })
      ])),

      map(([totalVehicleFinancePrice]) => {

        if (!term) {
          return 0;
        }

        const result = this.calculateMonthlyPayment(totalVehicleFinancePrice, term, interestRate);

        const monthlyPayment = Big(result).round(2).toNumber();

        if (memoryTag) {
          this.memory.add(memoryTag, "calculateTotalVehicleFinanceMonthlyPayment$", {
            term,
            monthlyPayment,
            totalVehicleFinancePrice,
            forceCashIncentive,
            findClosestTerm,
            excludeIncentives,
            withoutDaysToFirstPay,
            interestRate,
            totalVehicleFinancePrice$: this.memory.get(memoryTag, "totalVehicleFinancePrice$"),
          });
          this.memory.show(memoryTag, "calculateTotalVehicleFinanceMonthlyPayment$");
        }

        return monthlyPayment;
      })
    );
  }

  baseMonthlyPayment$(term?: number) {
    return combineLatest([
      this.baseVehicleFinancePrice$({term, memoryTag: 'baseMonthlyPayment$'}),
      this.findInterestRate$({term}),
      this.dealService.selectFinanceOptions(),
    ]).pipe(
      map(([baseVehicleFinancePrice, interestRate, financeOptions]) => {
        return this.calculateMonthlyPayment(
          baseVehicleFinancePrice,
          term || financeOptions.selectedFinancingTerm,
          interestRate
        );
      })
    );
  }

  /**
   * @usageNotes Gets base price without insurance products
   * Including accessories, taxes, daysttoirstpay
   */
  baseVehicleFinancePrice$(
    {withoutDaysToFirstPay, term, actualTrade, memoryTag}:
      { withoutDaysToFirstPay?: boolean, term?: number, actualTrade?: boolean, memoryTag?: string } = {}
  ): Observable<number> {
    return combineLatest([
      this.vehicleBaseFinanceAmount$({actualTrade}),
      this.findInterestRate$({term}),
      this.appService.selectFinancing(),
      this.totalFinanceInsuranceProductsPrice$({term}),
      this.dealService.selectFinanceOptions()
    ]).pipe(
      map(([baseFinanceAmount, interestRate, financing, insuranceProductsPrice, financeOptions]) => {
        const totalVehicleFinancePrice = parseFloat(
          (baseFinanceAmount + insuranceProductsPrice).toFixed(2)
        );
        const daysToFirstPayAmount = this.calcDaysToFirstPayAmount(
          totalVehicleFinancePrice,
          interestRate,
          financeOptions.daysToFirstPay || financing.financeDefault.daysToFirstPay
        );
        if (memoryTag) {
          this.memory.add(memoryTag, "baseVehicleFinancePrice$", {
            term,
            baseFinanceAmount,
            totalVehicleFinancePrice,
            interestRate,
            financing,
            daysToFirstPayAmount,
            insuranceProductsPrice,
            withoutDaysToFirstPay,
            financeOptions
          });
          this.memory.show(memoryTag, "baseVehicleFinancePrice$");
        }
        return withoutDaysToFirstPay ? baseFinanceAmount : baseFinanceAmount + daysToFirstPayAmount;
        // return totalVehicleFinancePrice + daysToFirstPayAmount;
      })
    );
  }


  /**
   * @usageNotes Gets absolute total price
   * Including accessories and insuranceOptions, taxes etc.
   */
  totalVehicleFinancePrice$(
    {
      withoutDaysToFirstPay,
      basePriceOnly = false,
      interestRate,
      term,
      actualTrade,
      excludeIncentives,
      findClosestTerm,
      finance,
      forceCashIncentive,
      memoryTag
    }: {
      withoutDaysToFirstPay?: boolean,
      basePriceOnly?: boolean,
      interestRate?: number,
      term?: number,
      actualTrade?: boolean,
      excludeIncentives?: boolean,
      findClosestTerm?: boolean,
      finance?: boolean,
      forceCashIncentive?: boolean,
      memoryTag?: string
    } = {}
  ): Observable<number> {
    return combineLatest([
      this.vehicleBaseFinanceAmount$({basePriceOnly, actualTrade, excludeIncentives, finance, forceCashIncentive, term, memoryTag}),
      this.findInterestRate$({term}),
      this.appService.selectFinancing(),
      this.totalFinanceInsuranceProductsPrice$({term, findClosestTerm}),
      this.dealService.selectFinanceOptions(),
    ]).pipe(
      map(([
             baseFinanceAmount,
             stateInterestRate,
             financing,
             insuranceProductsPrice,
             financeOptions,
           ]: [
        number,
        number,
        FinancingSettings,
        number,
        FinanceOptions
      ]) => {
        const totalVehicleFinancePrice = Big(baseFinanceAmount)
          .add(insuranceProductsPrice).round(2).toNumber();
        interestRate = typeof interestRate === "undefined" ? (stateInterestRate || 0) : interestRate;
        const daysToFirstPayAmount = this.calcDaysToFirstPayAmount(
          totalVehicleFinancePrice,
          interestRate,
          financeOptions.daysToFirstPay || financing.financeDefault.daysToFirstPay
        );
        const total = withoutDaysToFirstPay ?
          totalVehicleFinancePrice :
          totalVehicleFinancePrice + daysToFirstPayAmount;

        if (memoryTag) {
          this.memory.add(memoryTag, "totalVehicleFinancePrice$", {
            total,
            term,
            basePriceOnly,
            stateInterestRate,
            insuranceProductsPrice,
            vehicleBaseFinanceAmount: baseFinanceAmount,
            vehicleBaseFinanceAmount$: this.memory.get(memoryTag, "vehicleBaseFinanceAmount$"),
          });
        }

        return total;
      })
    );
  }

  daysToFirstPay$({term, interestRate}: { term: number, interestRate: number }) {
    return combineLatest([
      this.vehicleBaseFinanceAmount$(),
      this.appService.selectFinancing(),
      this.totalFinanceInsuranceProductsPrice$({term}),
      this.dealService.selectFinanceOptions()
    ]).pipe(map(([baseFinanceAmount, financing, insuranceProductsPrice, financeOptions]) => {
      const totalVehicleFinancePrice = parseFloat(
        (baseFinanceAmount + insuranceProductsPrice).toFixed(2)
      );
      const daysToFirstPayAmount = this.calcDaysToFirstPayAmount(
        totalVehicleFinancePrice,
        interestRate,
        financeOptions.daysToFirstPay || financing.financeDefault.daysToFirstPay
      );
      return daysToFirstPayAmount;
    }));
  }

  /**
   * @usageNotes Gets absolute total price
   * Including accessories and insuranceOptions, taxes etc.
   */
  totalVehicleCashPrice$(basePriceOnly = false): Observable<number> {
    return combineLatest([
      this.vehicleBaseFinanceAmount$({basePriceOnly, finance: true, term: 0}),
      this.totalCashInsuranceProductsPrice$()
    ]).pipe(
      map(([baseFinanceAmount, insuranceProductsPrice]) => {
        return Big(baseFinanceAmount).add(insuranceProductsPrice).round(2).toNumber();
      })
    );
  }

  insuranceProductMonthlyPayment$(product: InsuranceProduct, term?: number, memoryTag?: string) {
    return combineLatest([
      this.findInterestRate$({term}),
      this.dealService.selectFinanceOptions(),
    ]).pipe(
      map(([interestRate, financeOptions]) =>
        this.insuranceProductMonthlyPaymentCalc(interestRate, financeOptions, product, term, memoryTag)
      ));
  }

  insuranceProductMonthlyPaymentCalc(interestRate, financeOptions, product: InsuranceProduct, term?: number, memoryTag?: string) {
    // match selected term by given term
    let selectedTerm: TermCost = product.termCosts.find(termCost => termCost.term === term);

    // if no matches are found, use the selected term associated with the product
    selectedTerm = selectedTerm ? selectedTerm : product.termCosts[ product.selectedTerm ];

    const loanTerm = term || financeOptions.selectedFinancingTerm;
    if (selectedTerm) {
      const productPrice = (selectedTerm.priceOverride === null ? selectedTerm.price : selectedTerm.priceOverride) || 0;
      const calculatedMonthlyPayment = this.calculationUtilityService.calculateMonthlyPayment(productPrice, loanTerm, interestRate);
      if (memoryTag) {
        this.memory.addAndShow(memoryTag, "insuranceProductMonthlyPayment$", {
          calculatedMonthlyPayment,
          product,
          productPrice,
          interestRate,
          term,
          loanTerm,
          selectedTerm
        });
      }
      return calculatedMonthlyPayment;
    } else {
      if (memoryTag) {
        this.memory.addAndShow(memoryTag, "insuranceProductMonthlyPayment$", {
          calculatedMonthlyPayment: 0,
          product,
          productPrice: 0,
          interestRate,
          term,
          loanTerm,
          selectedTerm
        });
      }
      return 0;
    }
  }

  findSelectedTermPrice(insuranceProducts: InsuranceProduct[], productKeys: string[]) {
    if (!insuranceProducts) {
      return 0;
    }
    const index = insuranceProducts ? insuranceProducts.findIndex((product: InsuranceProduct) => {
      return productKeys.includes(product.productKey.toLowerCase());
    }) : -1;
    if (index > -1) {
      const product = insuranceProducts[ index ];
      const selectedTermCost = pathOr(0, ["termCosts", product.selectedTerm], product);
      if (selectedTermCost.priceOverride !== null) {
        return selectedTermCost.priceOverride;
      } else {
        return selectedTermCost.price;
      }
    }
    return 0;
  }

  hasClearCareEliteAccessory$() {
    return combineLatest([
      this.dealService.dealInsuranceService.selectInsuranceProducts(),
      this.dealService.selectAccessories()
    ]).pipe(map(([insuranceProducts, accessories]) => {
      return accessories.findIndex(a => {
        return (a.name.toLowerCase().includes("clear elite") || a.name.toLowerCase().includes("clear care elite"));
      }) > -1;
    }));
  }


  calcTradeEquity = (deal: DealState, buyBoxType: string) => {
    // down payment percent calculation
    let {downPayment} = deal.financeOptions;

    // initialize total trade equity
    let totalTradeEquity = 0;

    // calc trade in equity for trade in #1
    if (deal.tradeIn) {
      let positiveTradeInEquity = deal.tradeIn.tradeEstimate > deal.tradeIn.tradeValue ? deal.tradeIn.tradeEstimate : deal.tradeIn.tradeValue;
      let negativeTradeInEquity = deal.tradeIn.negativeEquity > deal.tradeIn.payOffEstimate ? deal.tradeIn.negativeEquity : deal.tradeIn.payOffEstimate;
      let totalTradeInEquity = positiveTradeInEquity - negativeTradeInEquity;
      totalTradeEquity += totalTradeInEquity;
    }

    // calc trade in equity for trade in #2
    if (deal.tradeIn2) {
      let positiveTradeInEquity2 = deal.tradeIn2.tradeEstimate > deal.tradeIn2.tradeValue ? deal.tradeIn2.tradeEstimate : deal.tradeIn2.tradeValue;
      let negativeTradeInEquity2 = deal.tradeIn2.negativeEquity > deal.tradeIn2.payOffEstimate ? deal.tradeIn2.negativeEquity : deal.tradeIn2.payOffEstimate;
      let totalTradeInEquity2 = positiveTradeInEquity2 - negativeTradeInEquity2;
      totalTradeEquity += totalTradeInEquity2;
    }

    let totalIncentives = 0;

    // calc rebates
    if (deal.incentives?.length > 0) {
      let incentives = deal.incentives[ 0 ];
      if (incentives.uberRebate > 0) {
        totalIncentives += incentives.uberRebate;
      }
      if (incentives.collegeRebate > 0) {
        totalIncentives += incentives.collegeRebate;
      }
      if (incentives.militaryRebate > 0) {
        totalIncentives += incentives.militaryRebate;
      }
      if (incentives.bonusCash > 0 && !incentives.bonusCashDisabled) {
        totalIncentives += incentives.bonusCash;
      }
      if (incentives.customerCash > 0 && !incentives.customerCashDisabled) {
        totalIncentives += incentives.customerCash;
      }

      if (buyBoxType === 'finance') {
        if (incentives.financeCash > 0 && !incentives.financeCashDisabled) {
          totalIncentives += incentives.financeCash;
        }
        if (incentives.customerLoyalty1000 > 0) {
          totalIncentives += incentives.customerLoyalty1000;
        }

        if ((incentives.financeOffer.aprSubventionTiers || []).length) {
          if (!deal.financeOptions.aprSubventionDisabled)
            totalIncentives += incentives.financeOffer.aprSubventionTiers[ 0 ];
        }
      }

      if (buyBoxType === 'lease') {
        if (incentives.leaseCash > 0 && !incentives.leaseCashDisabled) {
          totalIncentives += incentives.leaseCash;
        }

        if (incentives.customerLoyalty500 > 0) {
          totalIncentives += incentives.customerLoyalty500;
        }

        if (!deal.leaseOptions.subventionCashDisabled) {
          if (incentives?.leaseOffer?.leaseOfferTerms?.length) {
            if (deal.leaseOptions.selectedLeaseTerm) {
              const selectedLeaseOfferTermIndex = incentives.leaseOffer.leaseOfferTerms
                .findIndex(lot => lot.term === deal.leaseOptions.selectedLeaseTerm);
              if (selectedLeaseOfferTermIndex >= 0) {
                totalIncentives += deal.leaseOptions.subventionCashOverride ||
                  incentives.leaseOffer.leaseOfferTerms[ selectedLeaseOfferTermIndex ].leaseSubvention;
              }
            }
          } else if (deal.leaseOptions.subventionCashOverride > 0) {
            totalIncentives += deal.leaseOptions.subventionCashOverride;
          }
        }
      }
    }

    return downPayment + totalTradeEquity + totalIncentives;
  };
}

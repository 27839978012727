<article class="card buy-box">
  <div>
    <div class="card-header app-text-white app-bg-dark">Cash Purchase</div>

    <div class="buy-box-detail app-text-small">
      <div class="d-flex justify-content-between">
        <span>Vehicle Price</span>
        <span>{{ vehicleBaseFinanceAmount | currency }}</span>
      </div>
    </div>

    <div *ngFor="let product of insuranceProducts"
         class="buy-box-detail font-weight-bold app-text-small d-flex align-items-center justify-content-between">
      <span class="d-flex align-items-center mr-2">
        <i *ngIf="productSelected(product.productKey)"
           (click)="disableProduct(product)"
           class="material-icons app-text-secondary mr-2">done_outline</i>
        <i *ngIf="!productSelected(product.productKey)"
           (click)="enableProduct(product)"
           class="material-icons app-text-danger mr-2">close</i>
        {{ product.name }}
        <br>
        {{productDescription(product)}}
      </span>
      <span>{{ productPrice(product) | currency }}</span>
    </div>
  </div>

  <div class="buy-box-detail app-text-small">

    <div style="font-weight:bold">Notes:</div>

    <span class="d-flex align-items-center justify-content-between">
      <span class="mr-2">Guaranteed Future Value</span>
      <span style="display: flex;align-items: center">
        <i class="material-icons app-text-small mr-1 app-text-bold" style="margin-bottom:-2px">close</i>
        None
      </span>
    </span>

  </div>

  <div class="buy-box-detail app-text-small">
    <button
      class="btn btn-block d-flex align-items-center justify-content-center"
      [ngClass]="'app-btn-outline-secondary'"
      type="button"
      (click)="showFinanceVsInvest()">
      Finance vs Invest
    </button>
  </div>

  <div style="flex-grow: 2"></div>

  <div class="buy-box-footer">
    <div class="h3 mb-0">{{ totalVehicleCashPrice$ | async | currency }}</div>
    <div class="mb-3">total</div>
    <button class="btn btn-block d-flex align-items-center justify-content-center"
            [ngClass]="paymentPlanSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
            type="button"
            (click)="onSelectPaymentPlan()">
      <i *ngIf="paymentPlanSelected()"
         class="material-icons mr-2">done_outline</i>
      Select
    </button>
  </div>
</article>

<form [formGroup]="addAccessoryForm"
      class="form-layout">

  <div>
    <label [ngClass]="{ 'app-text-danger': touchedInvalid('name')}">
      Accessory *
    </label>
    <div class="input-group">
      <span class="input-group-prepend">
        <button class="btn d-flex align-items-center justify-content-center p-2 reduce-z-index"
                [ngClass]="addAccessoryForm.valid ? 'app-btn-secondary' : 'app-btn-neutral'"
                type="submit"
                (click)="onAddAccessory()"
                [disabled]="addAccessoryForm.invalid">
          <i class="material-icons">add</i>
        </button>
      </span>
      <input class="form-control"
             formControlName="name"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="trimNameInput()"
             required/>
    </div>
  </div>

  <div>
    <label [ngClass]="{ 'app-text-danger': touchedInvalid('price')}">
      Price *
    </label>
    <div class="input-group">
      <span class="input-group-prepend">
        <span class="input-group-text">$</span>
      </span>
      <input class="form-control"
             formControlName="price"
             type="number"
             min="0"
             required/>
    </div>
  </div>

</form>

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, debounceTime, map, switchMap, withLatestFrom } from "rxjs/operators";
import * as historyActions from "../actions/history.actions";
import * as dealActions from "../actions/deal.actions";
import { HistoryService } from "../../services/history.service";
import { DealHistory, HistoryEvent } from "../../models/history";
import { AuthService } from "src/app/auth-module/services";
import { DealService } from "../../services";

@Injectable()
export class HistoryEffects {
  getDealHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(historyActions.getHistory),
      switchMap(({dealId}) => {
          return this.historyService.read(dealId).pipe(
            map((history: DealHistory) => historyActions.getHistorySuccess(history)),
            catchError(error => of(historyActions.getHistoryFailure({error})))
          );
        }
      )
    ),
  );

  getDealHistories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(historyActions.getHistories),
      switchMap(() => {
          return this.historyService.list().pipe(
            map((histories: DealHistory[]) => historyActions.getHistoriesSuccess(histories)),
            catchError(error => of(historyActions.getHistoriesFailure({error})))
          );
        }
      )
    ),
  );

  addEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(historyActions.addEvent),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      //debounceTime(25),
      switchMap(([[event, user], deal]) => {
        //console.log("Adding Event, Deal:", deal.dealId, "Event:", event)
        return this.historyService.addEvent(deal.dealId, this.historyService.genericEvent(user, event)).pipe(
          map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
          catchError(error => of(historyActions.addEventFailure({error})))
        );
      })
    ),
  );

  overviewPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(historyActions.overviewPage),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      switchMap(([[action, user], deal]) => {
        const historyEvent = this.historyService.overviewPageEvent(user, action.event);
        if (this.historyService.isDuplicateEvent(historyEvent)) {
          return of(historyActions.addEventSuccess({} as HistoryEvent));
        }
        return this.historyService
          .addEvent(deal.dealId, historyEvent).pipe(
            map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
            catchError(error => of(historyActions.addEventFailure({error})))
          );
      })
    );
  });

  /*setTradeIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dealActions.setTradeIn),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      //debounceTime(20000),
      switchMap(([[{tradeIn}, user], deal]) => {
        return this.historyService
          .addEvent(deal.dealId, this.historyService.tradeInEvent(user)).pipe(
            map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
            catchError(error => of(historyActions.addEventFailure({error})))
          );
      })
    );
  });*/

  downPaymentChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(historyActions.downPaymentChanged),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      debounceTime(2000),
      switchMap(([[action, user], deal]) => {
        const historyEvent = this.historyService.downPaymentChangedEvent(user, action.event);
        if (this.historyService.isDuplicateEvent(historyEvent)) {
          return of(historyActions.addEventSuccess({} as HistoryEvent));
        }
        return this.historyService
          .addEvent(deal.dealId, historyEvent).pipe(
            map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
            catchError(error => of(historyActions.addEventFailure({error})))
          );
      })
    );
  });


  accessoriesCatalog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(historyActions.accessoriesCatalog),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      debounceTime(20000),
      switchMap(([[action, user], deal]) => {
        return this.historyService
          .addEvent(deal.dealId, this.historyService.accessoriesCatalogEvent(user)).pipe(
            map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
            catchError(error => of(historyActions.addEventFailure({error})))
          );
      })
    );
  });

  betterOwnership$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(historyActions.bestOwnership),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      switchMap(([[action, user], deal]) => {
        return this.historyService
          .addEvent(deal.dealId, this.historyService.betterOwnershipEvent(user)).pipe(
            map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
            catchError(error => of(historyActions.addEventFailure({error})))
          );
      })
    );
  });

  bestOwnership$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(historyActions.bestOwnership),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      debounceTime(20000),
      switchMap(([[action, user], deal]) => {
        return this.historyService
          .addEvent(deal.dealId, this.historyService.bestOwnershipEvent(user)).pipe(
            map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
            catchError(error => of(historyActions.addEventFailure({error})))
          );
      })
    );
  });

  paymentOptionsPresented$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(historyActions.paymentOptionsPresented),
      withLatestFrom(this.authService.selectUser()),
      withLatestFrom(this.dealService.selectDeal()),
      debounceTime(20000),
      switchMap(([[action, user], deal]) => {
        return this.historyService
          .addEvent(deal.dealId, this.historyService.paymentOptionsPresented(user)).pipe(
            map((returnedEvent: HistoryEvent) => historyActions.addEventSuccess(returnedEvent)),
            catchError(error => of(historyActions.addEventFailure({error})))
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private historyService: HistoryService,
    private dealService: DealService,
    private authService: AuthService,
  ) { }
}

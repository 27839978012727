import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

interface MigrateRequest {
  sourceURL: string;
  property: string;
}

const api = "/v1/migrate";
const routes = {
  migrateFromUrl: `${api}/fromURL`,
  runArchiver: `${api}/runArchiver`,
};

@Injectable({
  providedIn: "root"
})
export class MigrateService {

  constructor(private http: HttpClient) { }

  // HTTP

  migrateFromUrl(migrateRequest: MigrateRequest[]) {
    return this.http.post(routes.migrateFromUrl, migrateRequest);
  }

  runArchiver() {
    return this.http.get(routes.runArchiver);
  }


}

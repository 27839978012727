import { Component, Input, OnInit } from "@angular/core";
import { Accessory } from "src/app/clearpath-module/models";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { pathOr } from "ramda";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DealService } from "src/app/clearpath-module/services";
import { Observable } from "rxjs";

const formName = "sales_manager_accessories_form";

@Component({
  selector: "app-accessories-itemized",
  templateUrl: "./accessories-itemized.component.html",
  styleUrls: ["./accessories-itemized.component.scss"]
})
export class AccessoriesItemizedComponent implements OnInit {

  @Input() accessories: Accessory[];
  @Input() flagChange;
  @Input() autoSubmitAccessories;
  @Input() accessoriesTotal: number;
  @Input() dealDefaults: DealDefaults;
  @Input() parentForm: FormGroup;
  verifiedAccessories: number[] = [];
  verifiedRemovedAccessories: number[] = [];
  public REQS = FORM_INPUT_REQS;
  verified$: Observable<boolean>;
  shownAccessoriesForms = [];
  newAccessoryFormShowing: boolean;
  editing: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private dealService: DealService
  ) { }

  newAccessoryForm: FormGroup = this.formBuilder.group({
    name: [],
    price: []
  });

  ngOnInit() {
    this.verified$ = this.dealService.includesVerifiedForm$(formName);
    const missingAccessoriesFormArray = this.parentForm
      .get(["missingAccessoriesList"]) as FormArray;
    this.missingDefaultAccessories.forEach(missingAcc => {
      const formGroup = this.formBuilder.group({
        name: [missingAcc.name, Validators.required],
        price: [missingAcc.price, [Validators.required, Validators.min(0)]]
      });
      missingAccessoriesFormArray.push(formGroup);
    });
  }

  get missingAccessoriesFormArray() {
    return this.parentForm.controls.missingAccessoriesList.value;
  }

  get accessoriesListControls(): AbstractControl[] {
    const formArray = this.parentForm.get(["accessoriesList"]) as FormArray;
    return formArray.controls;
  }

  get missingDefaultAccessories(): Accessory[] {
    const accessories = pathOr([], ["accessories"], this.dealDefaults);
    const results = accessories.filter(defaultAcc => {
      const matchedAccessory = this.accessoriesListControls.filter(acclistControl => {
        return acclistControl.value.name === defaultAcc.name;
      }).length > 0;
      return !matchedAccessory;
    });
    return results;
  }

  // FORM ACTIONS & HELPERS

  toggleShowAccessoryForm() {
    if (!this.editing) {
      this.newAccessoryFormShowing = !this.newAccessoryFormShowing;
    }
  }

  toggleSelectAccessory(accessory: Accessory) {
    const foundIndex = this.accessories.findIndex((acc: Accessory) => {
      return acc.name === accessory.name;
    });
    this.accessories[ foundIndex ].disabled = !this.accessories[ foundIndex ].disabled;
    this.dealService.dispatchSetAccessories(this.accessories);
    this.flagChange();
  }

  verifiedAccessoryIndex(i: number) {
    return this.verifiedAccessories.findIndex(num => {
      return num === i;
    });
  }

  isSelected(accessory: Accessory) {
    const foundIndex = this.accessories.findIndex((acc: Accessory) => {
      return acc.name === accessory.name;
    });
    return !this.accessories[ foundIndex ].disabled;
  }

  removedAccessoryisVerified(i: number) {
    if (this.verifiedAccessoryIndex(i)) {
      return true;
    }
    return this.verifiedRemovedAccessories.includes(i);
  }

  verifyAccessory(i: number) {
    const index = this.verifiedAccessoryIndex(i);
    if (index >= 0) {
      this.verifiedAccessories.push(i);
    } else {
      this.verifiedAccessories.splice(index, 1);
    }
  }

  verifyRemovedAccessory(i: number) {
    if (!this.removedAccessoryisVerified(i)) {
      this.verifiedRemovedAccessories.push(i);
    } else {
      const index = this.verifiedRemovedAccessories.findIndex(num => {
        return num === i;
      });
      this.verifiedRemovedAccessories.splice(index, 1);
    }
  }

  touchedInvalidAccessory(controlIndex: number, controlName: string): boolean {
    const control = this.parentForm.get(["accessoriesList", controlIndex, controlName]);
    return control.touched && control.invalid;
  }


  isDefaultAccessory(accessory: Partial<Accessory>): boolean {
    const accessories = pathOr([], ["accessories"], this.dealDefaults);
    const result = accessories.filter((acc: Accessory) => {
      return acc.name === accessory.name;
    });
    return result.length > 0;
  }

  showAccessoryForm(i: number) {
    this.shownAccessoriesForms.push(i);
    this.editing = true;
  }

  autoSubmitNewAccessory = () => {
    const {pristine, invalid} = this.newAccessoryForm;
    if (pristine || invalid) { return; }

    const newAccessory: Accessory = this.newAccessoryForm.value;
    this.accessories.push(newAccessory);
    this.toggleShowAccessoryForm();
    this.newAccessoryForm.reset();
    this.dealService.dispatchSetAccessories(this.accessories);
    this.newAccessoryForm.markAsUntouched();
    this.newAccessoryForm.markAsPristine();
    this.flagChange();
  }

  onKeyUp(e) {
    if (e.key.toUpperCase() === "ENTER") {
      this.onBlur(e);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onBlur(e, itemValue: any = null, i = 0) {
    if (itemValue) {
      if (!this.accessoriesListControls[ i ].get("price").value) {
        this.accessoriesListControls[ i ].get("price").setValue(0);
      }
    }
    e.preventDefault();
    e.stopPropagation();
    this.editing = false;
    this.autoSubmitAccessories();
  }

  onKeyDownNew(e) {
    if (e.key.toUpperCase() === "ENTER") {
      this.onBlur(e);
      e.preventDefault();
      e.stopPropagation();
      this.autoSubmitNewAccessory();
    }
  }
}

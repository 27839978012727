import { Pipe, PipeTransform } from "@angular/core";
import { VehicleService } from "../../clearpath-module/services";

@Pipe({
  name: "howOld"
})
export class HowOldPipe implements PipeTransform {

  constructor(private vehicleService: VehicleService) { }

  transform(year: string): number {
    return this.vehicleService.calculateVehicleAge(parseInt(year, 10));
  }
}

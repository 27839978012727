import { Component, Input } from "@angular/core";
import { Vehicle, WarrantyUi } from "../../../../models";

@Component({
  selector: "app-warranty-footer",
  templateUrl: "./warranty-footer.component.html",
  styleUrls: ["./warranty-footer.component.scss"]
})
export class WarrantyFooterComponent {
  protected readonly parseInt = parseInt;

  _warrantyUi: WarrantyUi;
  @Input() vehicle: Vehicle;

  currentYear = new Date().getFullYear();

  @Input() set warrantyUi(warrantyUi: WarrantyUi) {
    this._warrantyUi = warrantyUi;
  }

  @Input() set maxOwnershipYears(maxOwnershipYears: number) {
    this.buildYearScale(maxOwnershipYears);
  }

  yearScale: number[] = [];

  constructor() { }

  buildYearScale(maxOwnerShipYears: number) {
    this.yearScale = [];

    for (let i = 1; i <= maxOwnerShipYears; i++) {
      this.yearScale.push(i);
    }
  }

  usedYearScale(_warrantyUi) {
    const {activeViewIndex, titleIndex} = _warrantyUi;
    if (activeViewIndex < 4) {
      return parseInt(this.vehicle.year)
    } else {
      return this.currentYear
    }
  }

  usedYearLabel(i): string {
    const {activeViewIndex, titleIndex} = this._warrantyUi;
    if (activeViewIndex < 4) {
      return parseInt(this.vehicle.year) + i + 1
    } else {
      return this.currentYear + i + 1
    }
  }
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DealStatus, DealType } from "src/app/clearpath-module/store/state";
import { CalculationService, CalculationUtilityService, DealService } from "src/app/clearpath-module/services";
import { FinanceOptions, FinanceRate, FinancingSettings, TradeIn, Vehicle } from "src/app/clearpath-module/models";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { FinanceOffer } from "src/app/settings-module/models";
import { DealIncentive, VehicleIncentive } from "src/app/settings-module/models/incentives";
import { Observable, of, Subject } from "rxjs";
import { Lender } from "src/app/sales-manager-module/models/data";
import Big from "big.js";
import { map, takeUntil } from "rxjs/operators";
import { pathOr } from "ramda";

@Component({
  selector: "app-finance-form",
  templateUrl: "./finance-form.component.html",
  styleUrls: ["./finance-form.component.scss"]
})
export class FinanceFormComponent implements OnInit, OnDestroy, OnChanges {

  private unsubscribe$ = new Subject();

  @Input() financeOptions: FinanceOptions;
  @Input() financingSettings: FinancingSettings;
  @Input() dealDefaults: DealDefaults;
  @Input() vehicle: Vehicle;
  @Input() vehicleCondition: string;
  @Input() vehicleIncentives: VehicleIncentive[];
  @Input() dealIncentives: DealIncentive[];
  @Input() dealStatus: string;
  @Input() lienHolder: Lender;
  @Input() tradeIn: TradeIn;
  @Output() submitDealType = new EventEmitter<string>();
  @Output() submitFinanceOptions = new EventEmitter<Partial<FinanceOptions>>();
  @Output() submitIncentives = new EventEmitter<DealIncentive[]>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();
  verified: boolean;
  totalFinanced$;

  uiState = {
    completedDeal: false,
    activeInterestRate: 0,
    customSelected: false,
    financeSelected: false,
    incentiveSelected: false,
    selectedCreditTier: 0,
    selectedFinancingTerm: 0,
    customerProvidedFinancingTerm: 0,
    customerProvidedInterestRate: 0,
    daysToFirstPay: 0,
    aprSubvention: 0,
    //aprSubventionDisabled: true
  };
  lender;

  uiFormsState = {
    customerProvidedInterestRate: {
      editing: null
    },
    customerProvidedFinancingTerm: {
      editing: null
    }
  };

  customFinanceForm: FormGroup = this.formBuilder.group({
    customerProvidedInterestRate: [0, Validators.min(0)],
    customerProvidedFinancingTerm: [0, Validators.min(1)]
  });

  miscFinanceForm: FormGroup = this.formBuilder.group({
    daysToFirstPay: [0, [Validators.min(0), Validators.max(100)]],
    aprSubvention: [0],
    aprSubventionDisabled: [true]
  });

  // Calculation Observables
  calcTax$;
  totalInsuranceProductsPrice$;
  calculateTotalVehicleFinanceMonthlyPayment$;
  financeTotal$;

  constructor(
    private formBuilder: FormBuilder,
    private calcService: CalculationService,
    private calcHelpers: CalculationUtilityService,
    private dealService: DealService,
  ) { }

  // INITIALIZATION

  ngOnInit() {
    this.syncFinanceOptions(this.financeOptions);
    this.syncFormStatus(this.dealStatus);
    this.initCalcs();
    this.lender = this.lienHolder;
    // this.vehicleService.selectVehicle()
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(vehicle => {
    //     this.initCalcs();
    //   })
  }

  ngOnChanges() {
    this.syncFinanceOptions(this.financeOptions);
    this.lender = this.lienHolder;
    this.initCalcs();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleAprSubventionDisabled = () => {
    const bool = !(this.miscFinanceForm.get('aprSubventionDisabled').value)
    //console.log("aprSubventionDisabled?", bool)
    this.dealService.dispatchSetFinanceOptions({aprSubventionDisabled: bool});
    this.miscFinanceForm.get('aprSubventionDisabled').setValue(bool)
    //this.uiState.aprSubventionDisabled = bool
    this.flagChange()
  }

  initCalcs() {


    this.dealService.selectDeal().pipe(
      takeUntil(this.unsubscribe$),
      map(deal => {
        const offers = [];

        const financeIncentiveRatesApplied = (deal?.incentives || []).filter(incentive => {
          if (pathOr(false, ["financeOffer", "financeRates", length], incentive)) {
            return true;
          }
        }).length > 0;

        pathOr([], ["incentives"], deal).forEach(el => {
            if ((deal.financeOptions.financeSelected ||
              el.financeOffer) && (el?.financeOffer?.aprSubventionTiers || []).length) {


              /*offers.push({
                type: "APR Subvention",
                value: el.financeOffer.aprSubventionTiers[ deal.financeOptions.selectedCreditTier ]
              });*/
              this.uiState.aprSubvention = el.financeOffer.aprSubventionTiers[ 0 ]


              //console.log("APR Subvention", el.financeOffer.aprSubventionTiers[ 0 ], financeIncentiveRatesApplied)


              this.miscFinanceForm.get('aprSubvention').setValue(el.financeOffer.aprSubventionTiers[ 0 ])
              this.miscFinanceForm.get('aprSubventionDisabled').setValue(deal.financeOptions.aprSubventionDisabled)

            } else {
              //   this.miscFinanceForm.get('aprSubventionDisabled').setValue(true)
            }
          }
        )

        return "";
      })).subscribe()


    this.calcTax$ = this.calcService.calcTax$();
    this.totalInsuranceProductsPrice$ = this.calcService.totalFinanceInsuranceProductsPrice$();
    this.calculateTotalVehicleFinanceMonthlyPayment$ = ({
                                                          term,
                                                          interestRate,
                                                          forceCashIncentive,
                                                          excludeIncentives,
                                                          memoryTag = "finance-form"
                                                        }:
                                                          {
                                                            term?: number,
                                                            interestRate?: number,
                                                            forceCashIncentive?: boolean,
                                                            excludeIncentives?: boolean,
                                                            memoryTag?: string,
                                                          } = {}) => {
      return this.calcService.calculateTotalVehicleFinanceMonthlyPayment$({
        term,
        interestRate,
        excludeIncentives,
        forceCashIncentive,
        memoryTag,
        finance: true
      });
    };
    this.financeTotal$ = this.uiState.incentiveSelected
      ? this.calculateTotalVehicleFinanceMonthlyPayment$({
        term: this.uiState.selectedFinancingTerm,
        interestRate: this.uiState.activeInterestRate,
        excludeIncentives: true
      })
      : this.calculateTotalVehicleFinanceMonthlyPayment$({
        forceCashIncentive: true
      });
    this.totalFinanced$ = this.calcService.totalVehicleFinancePrice$({finance: true, withoutDaysToFirstPay: true, actualTrade: true});
  }

  private syncFinanceOptions(options: FinanceOptions) {
    let {customerProvidedInterestRate, customerProvidedFinancingTerm} = options;
    customerProvidedFinancingTerm = customerProvidedFinancingTerm;
    this.uiState.activeInterestRate = options.activeInterestRate;
    this.uiState.customSelected = options.customSelected;
    this.uiState.financeSelected = options.financeSelected;
    this.uiState.incentiveSelected = options.incentiveSelected;
    this.uiState.selectedCreditTier = options.selectedCreditTier;
    this.uiState.selectedFinancingTerm = options.selectedFinancingTerm;

    // we need to set this as the current selected financing term (customer provided should only update when the user changes this value in desking ui) (after new value has been entered then blurred).
    const displayedTerm = options.customSelected ? options.selectedFinancingTerm : options.customerProvidedFinancingTerm;

    this.uiState.customerProvidedFinancingTerm = displayedTerm;
    this.uiState.customerProvidedInterestRate = options.customerProvidedInterestRate;
    this.uiFormsState.customerProvidedFinancingTerm.editing = false;
    this.uiFormsState.customerProvidedInterestRate.editing = false;
    let {daysToFirstPay} = options;
    if (!daysToFirstPay) {
      daysToFirstPay = this.financingSettings.financeDefault.daysToFirstPay;
    }
    this.customFinanceForm.patchValue({
      customerProvidedInterestRate: Big(customerProvidedInterestRate).times(100),
      customerProvidedFinancingTerm: displayedTerm
    });
    this.miscFinanceForm.patchValue({daysToFirstPay});
    this.uiState.daysToFirstPay = daysToFirstPay;

    // if (this.financeSelected && this.isCustomFinance) {
    //   this.customFinanceForm.enable();
    // } else {
    //   this.customFinanceForm.disable();
    // }

    // if (this.financeSelected) {
    //   this.miscFinanceForm.enable();
    // } else {
    //   this.miscFinanceForm.disable();
    // }
  }

  private syncFormStatus(status: string) {
    const completedDeal = status === DealStatus.Completed;
    this.uiState.completedDeal = completedDeal;
    // if (completedDeal) {
    //   this.customFinanceForm.disable();
    //   this.miscFinanceForm.disable();
    // }
  }

  // ACTIONS

  onUpdateIncentives(incentives: DealIncentive[]) {
    this.submitIncentives.emit(incentives);
  }

  // UI CONTROL & RENDERING

  get completedDeal(): boolean {
    return this.uiState.completedDeal;
  }

  get isDefaultFinance(): boolean {
    const {incentiveSelected, customSelected} = this.uiState;
    return incentiveSelected === false && customSelected === false;
  }

  get isIncentiveFinance(): boolean {
    return this.uiState.incentiveSelected;
  }

  get isCustomFinance(): boolean {
    return this.uiState.customSelected;
  }

  get financeDefaultRates(): FinanceRate[] {
    const types = {new: "newRates", used: "usedRates", certified: "certUsedRates"};
    const conditionType = types[ this.vehicleCondition ] || "";
    const rates = this.financingSettings[ conditionType ] || [];
    const defaultRates = this.calcHelpers.filterRates(this.vehicle.year, rates);
    return defaultRates;
  }

  get incentiveWithFinanceRates(): VehicleIncentive {
    let incentive;
    this.vehicleIncentives.forEach(ig => {
      const i = ig.incentive;
      if (i.financeOffer && i.financeOffer.financeRates && i.financeOffer.financeRates.length) {
        incentive = ig;
      }
    });
    return incentive;
  }

  get financeRatesOffer(): FinanceOffer {
    if (this.incentiveWithFinanceRates) {
      return this.incentiveWithFinanceRates.incentive.financeOffer;
    }
    return {
      financeRates: [],
      aprSubventionTiers: []
    };
  }

  get financeIncentiveRates(): FinanceRate[] {
    return !this.vehicle.isUsed && this.financeRatesOffer
      ? this.financeRatesOffer.financeRates
      : [];
  }

  isActiveDefaultRate(term: number): boolean {
    const {selectedFinancingTerm} = this.uiState;
    return this.isDefaultFinance && term === selectedFinancingTerm;
  }

  isActiveIncentiveRate(term: number): boolean {
    const {selectedFinancingTerm} = this.uiState;
    return this.isIncentiveFinance && term === selectedFinancingTerm;
  }

  get incentiveRatesSelected() {
    let selected = false;
    this.dealIncentives.forEach(i => {
      if (i.financeOffer && i.financeOffer.financeRates && i.financeOffer.financeRates.length > 0) {
        selected = true;
      }
    });
    return selected;
  }

  get creditTier(): number {
    return this.uiState.selectedCreditTier;
  }

  get activeInterestRate(): number {
    return this.uiState.activeInterestRate;
  }

  get financeSelected(): boolean {
    return this.uiState.financeSelected;
  }

  get selectedFinancingTerm(): number {
    return this.uiState.selectedFinancingTerm;
  }

  customMonthlyPayment$(): Observable<number> {
    return this.uiState.customerProvidedFinancingTerm ?
      this.calculateTotalVehicleFinanceMonthlyPayment$({
          term: this.uiState.customerProvidedFinancingTerm,
          interestRate: this.uiState.customerProvidedInterestRate,
          forceCashIncentives: true,
          memoryTag: "custom-rate"
        }
      ) : of(0);
  }

  // FORM ACTIONS & HELPERS

  onSelectFinanceDeal() {
    if (this.financeSelected) { return; }
    this.submitDealType.emit(DealType.Finance);
  }

  onSelectDefaultFinance(financeRate?: FinanceRate) {
    this.onSelectFinanceDeal();
    if (!financeRate) {
      const defaultIndex = Math.floor(this.financeDefaultRates.length / 2);
      financeRate = this.financeDefaultRates[ defaultIndex ];
    }

    if (!financeRate.tieredRates[ this.creditTier ]) {
      return;
    }

    const financeOptions: Partial<FinanceOptions> = {
      incentiveSelected: false,
      customSelected: false,
      selectedFinancingTerm: financeRate.term,
      customerProvidedFinancingTerm: this.uiState.customerProvidedFinancingTerm,
      activeInterestRate: financeRate.tieredRates[ this.creditTier ],
      financeSelected: true
    };

    this.removeFinanceRatesFromDeal();

    this.submitFinanceOptions.emit(financeOptions);
    this.submitIncentives.emit(this.dealIncentives);
  }

  onSelectIncentiveFinance(financeRate?: FinanceRate) {
    this.onSelectFinanceDeal();

    if (!financeRate) {
      const defaultIndex = Math.floor(this.financeIncentiveRates.length / 2);
      financeRate = this.financeIncentiveRates[ defaultIndex ];
    }

    const financeOptions: Partial<FinanceOptions> = {
      incentiveSelected: true,
      customSelected: false,
      selectedFinancingTerm: financeRate.term,
      activeInterestRate: financeRate.tieredRates[ this.creditTier ]
    };

    this.addFinanceRatesToDeal();

    this.submitFinanceOptions.emit(financeOptions);
    // this.dealIncentives[0].customerCashDisabled = true;
    this.submitIncentives.emit(this.dealIncentives);
  }

  onSelectCustomFinance() {
    this.onSelectFinanceDeal();

    if (this.isCustomFinance) { return; }
    this.customFinanceForm.markAsDirty();
    this.removeFinanceRatesFromDeal();
    this.submitCustomFinanceForm();
    this.submitIncentives.emit(this.dealIncentives);
  }

  addFinanceRatesToDeal() {
    const ig = this.incentiveWithFinanceRates;
    if (!ig) { return; }

    const idx = this.dealIncentives.findIndex(i => i.incentiveGroupName === ig.incentiveGroupName);

    if (idx > -1) {
      this.dealIncentives[ idx ].financeOffer = this.financeRatesOffer;
    } else {
      this.dealIncentives.push({
        incentiveGroupName: ig.incentiveGroupName,
        financeOffer: this.financeRatesOffer
      });
    }
  }

  removeFinanceRatesFromDeal() {
    const ig = this.incentiveWithFinanceRates;
    if (!ig) { return; }

    const idx = this.dealIncentives.findIndex(i => i.incentiveGroupName === ig.incentiveGroupName);
    if (idx > -1) {
      this.dealIncentives[ idx ].financeOffer = null;
    }
  }

  submitCustomFinanceForm = () => {
    const {pristine, invalid, value} = this.customFinanceForm;
    if (pristine || invalid) { return; }

    const financeOptions: Partial<FinanceOptions> = {
      incentiveSelected: false,
      customSelected: true,
      selectedFinancingTerm: value.customerProvidedFinancingTerm,
      customerProvidedInterestRate: Big(value.customerProvidedInterestRate).div(100).toNumber(),
      customerProvidedFinancingTerm: value.customerProvidedFinancingTerm,
      activeInterestRate: Big(value.customerProvidedInterestRate).div(100).toNumber()
    };

    this.submitFinanceOptions.emit(financeOptions);
    this.customFinanceForm.markAsPristine();
  }

  submitMiscFinanceForm = () => {
    const {pristine, invalid} = this.miscFinanceForm;
    if (pristine || invalid) { return; }

    const {daysToFirstPay} = this.miscFinanceForm.value;
    const financeOptions: Partial<FinanceOptions> = {daysToFirstPay: +daysToFirstPay};

    this.submitFinanceOptions.emit(financeOptions);
    this.miscFinanceForm.markAsPristine();
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  }

  touchedInvalid(formName: string, controlName: string): boolean {
    const control = this[ formName ].get(controlName);
    return control.touched && control.invalid;
  }

}

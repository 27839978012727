import { AppState, initialAppState, initialVehicleState } from "../state";
import { dealStateReducer } from "./deal.reducer";
import { Action, combineReducers, createReducer, on } from "@ngrx/store";
import * as vehicleActions from "../actions/vehicle.actions";
import * as dealActions from "../actions/deal.actions";
import * as appActions from "../actions/app.actions";
import * as authActions from "../../../auth-module/store/actions/auth.actions";
import { historiesReducer, historyReducer } from "./history.reducer";

const reducer = createReducer(
  initialAppState,
  on(vehicleActions.getVehicleSuccess, (state, {vehicle}) => {
    return ({...state, vehicle, vehicleIncentives: vehicle.incentives});
  }),
  on(vehicleActions.updateVehicleSuccess, (state, {vehicle}) => {
    return ({...state, vehicle, vehicleIncentives: vehicle.incentives});
  }),
  on(vehicleActions.setVehicle, (state, {vehicle}) => {
    return ({
      ...state,
      vehicle: {...state.vehicle, ...vehicle},
      vehicleIncentives: vehicle.incentives
    });
  }),
  on(vehicleActions.changeStockNumberSuccess,
    (state, {vehicle}) => {
      return ({...state, vehicle, vehicleIncentives: vehicle.incentives, incentives: []});
    }
  ),
  on(vehicleActions.clearVehicle, state => ({
      ...state,
      vehicle: initialVehicleState,
      vehicleIncentives: initialAppState.vehicleIncentives
    })
  ),
  on(appActions.linksApiSuccess,
    (state, {links}) => ({...state, links})
  ),
  on(appActions.configFinanceApiSuccess,
    (state, {configData}) => ({...state, financing: {...state.financing, ...configData}})
  ),
  on(appActions.formServerApiSuccess,
    (state, {configData}) => ({...state, formServer: configData})
  ),
  on(dealActions.getPrintDealsApiSuccess,
    (state, {deals}) => ({...state, printDeals: deals})
  ),
  on(appActions.getDealerSuccess,
    (state, {dealer}) => ({...state, dealer})
  ),
  on(appActions.getZipTaxTableSuccess,
    (state, {zipTaxTable}) => ({...state, zipTaxTable})
  ),
  on(appActions.setSearch, (state, {searchString}) => ({...state, searchString})),
  on(appActions.setWriteupListLength, (state, {writeupListLength}) =>
    ({...state, writeupListLength})),
  on(appActions.setSelectedManagerId, (state, {selectedManagerId}) => (
    {...state, selectedManagerId}
  )),
  on(appActions.getNewResidualsSuccess,
    (state, {newResiduals}) => ({...state, newResiduals})
  ),
  on(appActions.getCertUsedResidualsSuccess,
    (state, {certUsedResiduals}) => ({...state, certUsedResiduals})
  )
);

const vehiclesReducer = createReducer(
  [],
  on(vehicleActions.getAllVehiclesSuccess,
    (state, {vehicles}) => {
      vehicles?.forEach(vehicle => {
        vehicle.insuranceProducts = vehicle.insuranceProducts || [];
        vehicle.incentives = vehicle.incentives || [];
      });
      return vehicles;
    }
  ),
);

const dealsReducer = createReducer(
  [],
  on(appActions.getDeals,
    (state) => ({...state, loading: true, deals: []})),
  on(appActions.getDealsSuccess,
    (state, {deals}) => ({...state, loading: false, deals})),
  on(appActions.getDealsFailure,
    (state) => ({...state, loading: false, deals: []})),
);

export function appStateReducer(state: AppState | undefined, action: Action) {
  return reducer(state, action);
}

const reducers = combineReducers({
  deals: dealsReducer,
  vehicles: vehiclesReducer,
  dealHistory: historyReducer,
  dealHistories: historiesReducer,
  app: appStateReducer,
  deal: dealStateReducer
});

export function rootReducer(state, action) {
  if (action.type === authActions.LOGOUT) {
    state = undefined;
  }
  return reducers(state, action);
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService, DealService, WarrantyUiService } from "src/app/clearpath-module/services";
import { takeUntil } from "rxjs/operators";
import { WarrantyUi } from "src/app/clearpath-module/models";
import { Subject } from "rxjs";
import { HistoryService } from "src/app/clearpath-module/services/history.service";

@Component({
  selector: "app-warranty-ui-modal",
  templateUrl: "./warranty-ui-modal.component.html",
  styleUrls: ["./warranty-ui-modal.component.scss"]
})
export class WarrantyUiModalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  private creditTierSelected: boolean;
  private uiState = {
    activeSequence: {id: "", title: ""},
    modalSequence: [
      {id: "creditTier", title: "Credit Tier"},
      {id: "warrantyUi", title: "Vehicle Warranty Coverage"},
      {id: "gapGraph", title: "Guaranteed Auto Protection"}
    ],
    warrantyUi: {
      activeViewIndex: 0,
      maxViewIndex: 0
    }
  };

  constructor(
    private router: Router,
    private warrantyUiService: WarrantyUiService,
    private historyService: HistoryService,
    private dealService: DealService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.historyService.dispatchAddEvent({
      shortName: "Warranty Window Opened"
    });
    this.setFirstSequence();
    this.subToWarrantyUi();
  }

  ngOnDestroy() {
    this.historyService.dispatchAddEvent({
      shortName: "Warranty Window Closed"
    });
    this.appService.dispatchLocalStorageSync();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private setFirstSequence() {
    this.uiState.activeSequence = this.uiState.modalSequence[ 0 ];
  }

  private subToWarrantyUi() {
    this.warrantyUiService.warrantyUiObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((warrantyUi: WarrantyUi) => {
        if (!warrantyUi) { return; }
        const {activeViewIndex, maxViewIndex} = warrantyUi;
        let pageName = "";
        if (activeViewIndex === 1) {
          pageName = "Time/Miles Review Page";
        } else if (activeViewIndex === 2) {
          pageName = "Normally Page";
        } else if (activeViewIndex === 3) {
          pageName = "CLEAR Ownership Page";
        } else if (activeViewIndex === 4) {
          pageName = "Agreement Page";
        } else if (activeViewIndex === 5) {
          pageName = "Complete Ownership Page";
        }
        if (pageName) {
          this.historyService.dispatchAddEvent({
            shortName: "Warranty: " + pageName,
          });
        }
        /*if (activeViewIndex === 4) {
          this.historyService.dispatchBetterOwnershipEvent();
        }
        if (activeViewIndex === 5) {
          this.historyService.dispatchBestOwnershipEvent();
        }*/
        this.uiState.warrantyUi = {activeViewIndex, maxViewIndex};
        if (warrantyUi.vehicleCondition === "new") {
          const index = this.uiState.modalSequence.findIndex(modalSequence => {
            return modalSequence.id === "clearGraph";
          });
          if (index === -1) {
            this.uiState.modalSequence.splice(1, 0, {id: "clearGraph", title: ""});
          }
        }
      });
  }

  // UI CONTROL & RENDERING

  get activeTitle(): string {
    return this.uiState.activeSequence.title;
  }

  get allowWarrantyUiNavNext(): boolean {
    const isNotWarrantyUiSequence = !this.isActiveSequence("warrantyUi");
    if (isNotWarrantyUiSequence) { return false; }
    return this.uiState.warrantyUi.activeViewIndex < this.uiState.warrantyUi.maxViewIndex;
  }

  get allowWarrantyUiNavBack(): boolean {
    const isNotWarrantyUiSequence = !this.isActiveSequence("warrantyUi");
    if (isNotWarrantyUiSequence) { return false; }
    return this.uiState.warrantyUi.activeViewIndex > 0;
  }

  isActiveSequence(id: string): boolean {
    if (id === "warrantyUi" && !this.creditTierSelected) {
      this.creditTierSelected = true;
      this.dealService.dispatchSetFinanceOptions({creditTierSelected: true});
    }
    return id === this.uiState.activeSequence.id;
  }

  previousSequence() {
    const newIndex = this.activeSequenceIndex() - 1;
    if (newIndex < 0) { this.router.navigate([{outlets: {modal: null}}]); }
    this.uiState.activeSequence = this.uiState.modalSequence[ newIndex ];
  }

  nextSequence() {
    const newIndex = this.activeSequenceIndex() + 1;
    const maxIndex = this.uiState.modalSequence.length - 1;
    if (newIndex > maxIndex) { this.router.navigate([{outlets: {modal: null}}]); }
    this.uiState.activeSequence = this.uiState.modalSequence[ newIndex ];
  }

  // HELPERS

  private activeSequenceIndex(): number {
    let sequenceIndex = 0;
    const {activeSequence, modalSequence} = this.uiState;

    for (const sequence of modalSequence) {
      if (activeSequence.id === sequence.id) { break; }
      sequenceIndex++;
    }

    return sequenceIndex;
  }

}

import { Accessory, Buyer, FinanceOptions, Insurance, InsuranceProduct, TradeIn, VehicleNeeds } from "../../../models";
import { initialFinanceOptionsEditsState, initialFinanceOptionsState, initialLeaseOptionsState, initialLienHolderState } from "./financing";
import { initialCustomerState } from "./customer";
import { initialVehicleNeedsState } from "./vehicle-needs";
import { initialTradeInState } from "./trade-in";
import { initialInsuranceState } from "./insurance";
import { LeaseOptions } from "src/app/clearpath-module/models/calculations";
import { DealIncentive } from "src/app/settings-module/models/incentives";
import { Lender } from "src/app/sales-manager-module/models/data";

export interface DealState {
  ID: number;
  dealId: string;
  initialized: boolean;
  salesId: string;
  managerId: string;
  customer: Buyer;
  coSigner: Buyer;
  stockNumber: string;
  odometer: number;
  plateNumber: string;
  plateExpires: string;
  salesPerson: string;
  salesPersonPhone: string;
  salesManager: string;
  status: string;
  vehicle: string;
  financeOptions: FinanceOptions;
  financeOptionsEdits: FinanceOptions;
  leaseOptions: LeaseOptions;
  lienHolder: Lender;
  tradeIn: TradeIn;
  tradeInOwner: Buyer;
  tradeInOwner2: Buyer;
  tradeIn2: TradeIn;
  tradeIn2Owner: Buyer;
  tradeIn2Owner2: Buyer;
  insuranceInfo: Insurance;
  accessories: Accessory[];
  vehicleNeeds: VehicleNeeds;
  insuranceProducts: InsuranceProduct[];
  insuranceProductsSet: boolean;
  disabledInsuranceProducts: InsuranceProduct[];
  incentivesSet: boolean;
  comments: string;
  createdAt: string;
  updatedAt: string;
  statusDate: string;
  displayTerms: any;
  incentives: DealIncentive[];
  verifiedForms: string[];
  contractDate: string;
  dealerTrade: boolean;
  selectedDealerAccessories: string[];
  totalDealerAccCost: number;
  productsAccepted: string;
  productsDeclined: string;
}

export const initialDealState: DealState = {
  ID: 0,
  dealId: "",
  initialized: false,
  salesId: "",
  managerId: "",
  customer: initialCustomerState,
  coSigner: initialCustomerState,
  stockNumber: "",
  odometer: 0,
  plateNumber: "",
  plateExpires: "",
  salesPerson: "",
  salesPersonPhone: "",
  salesManager: "",
  status: "",
  vehicle: "",
  financeOptions: initialFinanceOptionsState,
  financeOptionsEdits: initialFinanceOptionsEditsState,
  leaseOptions: initialLeaseOptionsState,
  tradeIn: initialTradeInState,
  tradeInOwner: initialCustomerState,
  tradeInOwner2: initialCustomerState,
  tradeIn2: initialTradeInState,
  tradeIn2Owner: initialCustomerState,
  tradeIn2Owner2: initialCustomerState,
  insuranceInfo: initialInsuranceState,
  accessories: [],
  vehicleNeeds: initialVehicleNeedsState,
  insuranceProducts: [],
  displayTerms: null,
  insuranceProductsSet: false,
  disabledInsuranceProducts: [],
  incentivesSet: false,
  comments: "",
  createdAt: new Date(0).toISOString(),
  updatedAt: new Date(0).toISOString(),
  statusDate: new Date(0).toISOString(),
  incentives: [],
  verifiedForms: [],
  lienHolder: initialLienHolderState,
  contractDate: "",
  dealerTrade: false,
  selectedDealerAccessories: [],
  totalDealerAccCost: 0,
  productsAccepted: "",
  productsDeclined: "",
};

export enum DealStatus {
  Created = "created",
  Changed = "changed",
  Submitted = "submitted",
  Updated = "updated",
  Approved = "approved",
  Printing = "printing",
  Printed = "printed",
  Completed = "completed",
  Declined = "declined"
}

export enum DealType {
  Finance = "finance",
  Lease = "lease",
  Cash = "cash"
}

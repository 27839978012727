import { Component, Input } from "@angular/core";
import { WarrantyUiService } from "../../../../services";

@Component({
  selector: "app-warranty-nav-button",
  templateUrl: "./warranty-nav-button.component.html",
  styleUrls: ["./warranty-nav-button.component.scss"]
})
export class WarrantyNavButtonComponent {
  @Input() navDirection: string;

  private navTypes = {
    forward: {
      direction: "forward",
      btnClass: "btn app-btn-secondary",
      btnText: "NEXT"
    },
    reverse: {
      direction: "reverse",
      btnClass: "btn app-btn-neutral",
      btnText: "BACK"
    },
    default: {
      direction: null,
      btnClass: "btn app-btn-danger",
      btnText: "No Direction"
    }
  };

  constructor(private warrantyUiService: WarrantyUiService) { }

  get navButton() {
    const navType = this.navTypes[ this.navDirection ];
    return navType ? navType : this.navTypes.default;
  }

  navigate() {
    const navType = this.navTypes[ this.navDirection ] || {};
    const {direction} = navType;

    switch (direction) {
      case "forward":
        this.warrantyUiService.nextViewIndex();
        break;

      case "reverse":
        this.warrantyUiService.previousViewIndex();
        break;
    }
  }

}

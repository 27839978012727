import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "../../services";
import { ConfigDealer } from "../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-dealer",
  templateUrl: "./dealer.component.html",
  styleUrls: ["./dealer.component.scss"]
})
export class DealerComponent implements OnInit {

  public REQS = FORM_INPUT_REQS;
  configDealer: ConfigDealer;

  dealerSettingsForm: FormGroup = this.formBuilder.group({
    dealerInfo: this.formBuilder.group({
      companyName: ["", Validators.required],
      companyPhone: ["", Validators.required]
    }),
    companyAddress: this.formBuilder.group({
      street: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      zip: ["", Validators.required]
    }),
    dealerSettings: this.formBuilder.group({
      dealerCode: ["", Validators.required],
      gasPrice: [null, Validators.required]
    }),
    insuranceDefaults: this.formBuilder.group({
      productKeys: this.formBuilder.array([])
    }),
    displayDefaults: this.formBuilder.group({
      webLogoUrl: [""],
      printLogoUrl: [""],
      estimatedMileage: [null],
      estimatedOwnership: [null],
      pwResetHeaderMsg: [""],
      pwResetEmail: [""],
      vehiclePhotoUrl: [""],
      feedbackEmail: [""]
    }),
    serviceDefaults: this.formBuilder.group({
      laborRate: [null],
      appointmentEmail: [""]
    }),
    dealDefaults: this.formBuilder.group({
      staleArchiveDays: [null],
      completedArchiveDays: [null]
    })
  });

  get productKeysFormArray() {
    const insuranceDefaultsFormGroup = this.dealerSettingsForm.get("insuranceDefaults") as FormGroup;
    return insuranceDefaultsFormGroup.get("productKeys") as FormArray;
  }

  get productKeys() {
    return this.productKeysFormArray.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.getConfigDealer();
  }

  // DATA ACCESS

  getConfigDealer() {
    this.settingsService.getConfigDealer()
      .subscribe((configDealer) => {
        this.configDealer = configDealer;
        this.initDealerForm(this.configDealer);
      });
  }

  setConfigDealer(updatedConfigDealer: ConfigDealer) {
    this.settingsService.setConfigDealer(updatedConfigDealer)
      .subscribe((configDealer: ConfigDealer) => {
        this.configDealer = configDealer;
        this.scrollToTop();
      });
  }

  // INITIALIZATION

  initDealerForm(configDealer: ConfigDealer) {
    const {
      companyAddress,
      displayDefaults,
      serviceDefaults,
      dealDefaults,
      insuranceDefaults
    } = configDealer;
    this.patchProductKeys(insuranceDefaults.productKeys);
    this.dealerSettingsForm.patchValue({
      dealerInfo: {
        companyName: configDealer.companyName,
        companyPhone: configDealer.companyPhone
      },
      dealerSettings: {
        dealerCode: configDealer.dealerCode,
        gasPrice: configDealer.gasPrice,
      },
      companyAddress,
      displayDefaults,
      serviceDefaults,
      dealDefaults
    });
  }

  patchProductKeys(productKeys: string[]) {
    if (productKeys) {
      productKeys.forEach(key => {
        this.productKeysFormArray.push(new FormControl(key));
      });
    }
  }

  // ACTIONS

  onAddProductKey() {
    const control = new FormControl("");
    this.productKeysFormArray.push(control);
  }

  onRemoveProductKey(productKeyIndex: number) {
    this.productKeysFormArray.removeAt(productKeyIndex);
  }

  onSubmitDealerForm(val) {
    const {
      dealerInfo,
      companyAddress,
      dealerSettings,
      insuranceDefaults,
      displayDefaults,
      serviceDefaults,
      dealDefaults
    } = val;
    const configDealer: ConfigDealer = {
      companyName: dealerInfo.companyName,
      companyPhone: dealerInfo.companyPhone,
      dealerCode: dealerSettings.dealerCode,
      gasPrice: dealerSettings.gasPrice,
      insuranceDefaults,
      companyAddress,
      displayDefaults,
      serviceDefaults,
      dealDefaults,
      salesDefaults: {},
    };
    this.setConfigDealer(configDealer);
  }

  runDealArchiver() {
    alert("deal archiver needs to be connected");
  }

  // UI AND RENDERING

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  // FORM VALIDATION & HELPERS

  trackProductKeys(index: any, item: any) {
    return index;
  }

  touchedInvalid(formGroupName: string, controlName: string): boolean {
    const formGroup = this.dealerSettingsForm.get(formGroupName);
    const control = formGroup.get(controlName);
    return control.touched && control.invalid;
  }

  trimInput(formGroupName: string, controlName: string) {
    const formGroup = this.dealerSettingsForm.get(formGroupName);
    const value = formGroup.value[ controlName ].trim();
    formGroup.patchValue({[ controlName ]: value});
  }

}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Links } from "../models";
import { USER_ROLES } from "src/app/app.config";
import { User } from "src/app/user-admin-module/models";
import { AuthService } from "src/app/auth-module/services";

const api = "/v1/config";
const routes = {
  getLinks: `${api}/getlinks`,
  setLinks: `${api}/setlinks`,
};

@Injectable({
  providedIn: "root"
})
export class RoutingService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) { }

  routeToInitialView() {
    this.authService.selectUser().subscribe(
      user => {
        if (!user) { return; }

        let initialRoute = "/clearpath/list";
        const userRoles = User.unpackSecurityGroups(user.securityGroups);
        const roleHierarchy = {
          [ USER_ROLES.sys_admin ]: "/settings",
          [ USER_ROLES.sales_manager ]: "/sales-manager/list",
        };

        for (const role in roleHierarchy) {
          if (userRoles.includes(role)) {
            initialRoute = roleHierarchy[ role ];
            break;
          }
        }

        this.router.navigate([initialRoute]);
      });
  }

  getLinks(): Observable<any> {
    return this.http.get(routes.getLinks);
  }

  setLinks(links: Links): Observable<any> {
    return this.http.post(routes.setLinks, links);
  }

}

<div mat-dialog-title>
  <h1>TFS Products may be required </h1>
</div>
<div mat-dialog-content>
  <img src="/assets/images/tfs-advance-as-of-january-2024.jpg" alt="chart" style="width:100%;height:auto">
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button type="submit" (click)="viewPDF()">View PDF</button>
</div>

// CONFIGS
import { environment } from "../environments/environment";

// SERVICES
import { AuthInterceptorService } from "./auth-module/services/auth-interceptor.service";
import { ErrorInterceptorService } from "./shared-module/helpers";
import { ErrorService } from "./shared-module/services/Error/error.service";

// OUR MODULES
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared-module/shared.module";
import { AuthModule } from "./auth-module/auth.module";
import { UserAdminModule } from "./user-admin-module/user-admin.module";
import { SalesQueueModule } from "./sales-queue-module/sales-queue.module";
import { LandingPageModule } from "./landing-page-module/landing-page.module";
import { ClearpathModule } from "./clearpath-module/clearpath.module";
import { SettingsModule } from "./settings-module/settings.module";

// OTHER MODULES / CORE
import { BrowserModule } from "@angular/platform-browser";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { NgbAccordionModule, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule, BUCKET } from "@angular/fire/storage";
import "firebase/storage";

// COMPONENTS
import { AppComponent } from "./app.component";

// HELPERS
import { TypePipe } from "./shared-module/helpers/Pipes/input-type.pipe";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { localStorageSyncReducer } from "./shared-module/services/Storage/storage.metareducer";
import { SalesManagerModule } from "./sales-manager-module/sales-manager.module";
import { RaygunErrorHandler } from "app.raygun.setup";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { InventoryModule } from "./inventory-module/inventory.module";
import { MatInputModule } from "@angular/material/input";
import { CNAModule } from "./cna-module/cna.module";

@NgModule({
  declarations: [AppComponent, TypePipe],
  imports: [
    SweetAlert2Module.forRoot(),
    BrowserModule,
    HttpClientModule,
    NgbAccordionModule,
    NgbModalModule,
    SharedModule,
    SalesManagerModule,
    AuthModule,
    UserAdminModule,
    MatInputModule,
    SalesQueueModule,
    LandingPageModule,
    ClearpathModule,
    SettingsModule,
    SalesManagerModule,
    InventoryModule,
    CNAModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule, // Last Import: Preserve wildcard route position
    StoreModule.forRoot({router: routerReducer}, {
      metaReducers: [localStorageSyncReducer],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        // strictStateSerializability: true,
        // strictActionSerializability: true,
      }
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, logOnly: environment.name !== "staging" && environment.production
    }),
    StoreRouterConnectingModule.forRoot({stateKey: "router"})
  ],
  providers: [
    {provide: BUCKET, useValue: environment.firebase.storageBucket},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {provide: ErrorHandler, useClass: ErrorService},
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

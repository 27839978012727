import { Pipe, PipeTransform } from "@angular/core";
import { DealState } from "src/app/clearpath-module/store/state";
import { PipeHelpers } from "./helpers";

@Pipe({
  name: "dealSort"
})
export class DealSortPipe implements PipeTransform {

  constructor(private helpers: PipeHelpers) { }

  transform(deals: DealState[], sortBy: string, ascending: boolean): any[] {

    const sortByProp = prop => this.sortDealsByProperty(prop, deals, ascending);

    if (!deals) { return []; }
    if (!sortBy) { return deals; }

    return sortByProp(sortBy);

  }

  sortDealsByProperty(property: string, deals: DealState[], ascending: boolean) {
    return deals.sort((a, b) => {
      const fieldA = this.dealGetSortableValue(property, a);
      const fieldB = this.dealGetSortableValue(property, b);
      return this.helpers.compare(fieldA, fieldB, ascending);
    });
  }

  dealGetSortableValue(prop: string, deal: DealState) {
    switch (prop) {
      case "customer":
        return deal.customer.lastName ? deal.customer.lastName.toLowerCase() : "";
      case "statusDate":
        return deal.statusDate ? new Date(deal.statusDate).getTime() : 0;
      default:
        return deal[ prop ];
    }
  }

}

import { VehicleModel } from "../models/data";

export const MODEL_TABLE: VehicleModel[] = [
  {
    modelNumber: 1219,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "Special Edition",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1221,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "'L'",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1223,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "LE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1224,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "Two Eco",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1225,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "XLE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1226,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "Three Touring",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1227,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "Four",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1228,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "Four Touring",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1229,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "Five",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1235,
    make: "Toyota",
    model: "Prius Prime",
    extra: "4 Door Liftback",
    trim: "Plus",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1237,
    make: "Toyota",
    model: "Prius Prime",
    extra: "4 Door Liftback",
    trim: "Premium",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1239,
    make: "Toyota",
    model: "Prius Prime",
    extra: "4 Door Liftback",
    trim: "Advanced",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1263,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "LE AWD-e",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1265,
    make: "Toyota",
    model: "Prius",
    extra: "4 Door Liftback",
    trim: "XLE AWD-e",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto CVT"
  },
  {
    modelNumber: 1466,
    make: "Toyota",
    model: "Yaris Hatchback",
    extra: "5-Door Hatchback",
    trim: "LE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 1467,
    make: "Toyota",
    model: "Yaris Hatchback",
    extra: "5-Door Hatchback",
    trim: "XLE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 1831,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "'L'",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 1832,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "'L'",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 1852,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "LE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1854,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "LE Plus",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1856,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "XLE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1863,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "SE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 1864,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "SE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1865,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "'S' Special Edition",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1866,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "XSE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1868,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "SE Nightshade",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1872,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "LE ECO",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1874,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "LE ECO Plus",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1876,
    make: "Toyota",
    model: "Corolla",
    extra: "4 Door Sedan",
    trim: "LE ECO Premium",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 1882,
    make: "Toyota",
    model: "Corolla",
    extra: "4-Door Sedan",
    trim: "Hybrid LE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2374,
    make: "Toyota",
    model: "GR Supra",
    extra: "2-Door Coupe",
    trim: "3.0 Premium",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2375,
    make: "Toyota",
    model: "GR Supra",
    extra: "2-Door Coupe",
    trim: "Launch Edition",
    engine: "6-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2402,
    make: "Toyota",
    model: "C-HR",
    extra: "2-Door CUV",
    trim: "LE",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2404,
    make: "Toyota",
    model: "C-HR",
    extra: "2-Door CUV",
    trim: "XLE",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2406,
    make: "Toyota",
    model: "C-HR",
    extra: "2-Door CUV",
    trim: "XLE Premium",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2408,
    make: "Toyota",
    model: "C-HR",
    extra: "2-Door CUV",
    trim: "Limited",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 2514,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "L",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2515,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "LE AWD",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2516,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "SE AWD",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2517,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "XLE AWD",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2518,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "XSE AWD",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2532,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "LE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2536,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "SE Nightshade",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2540,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "XLE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2546,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "SE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2547,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "SE Special Edition",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2548,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "XSE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2549,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "TRD V6",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2550,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "XSE",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2554,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "XLE",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 2559,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "Hybrid LE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2560,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "Hybrid XLE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2561,
    make: "Toyota",
    model: "Camry",
    extra: "4 Door Sedan",
    trim: "Hybrid SE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2820,
    make: "Toyota",
    model: "Venza",
    extra: "4 Door CUV",
    trim: "Hybrid XLE AWD",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 2830,
    make: "Toyota",
    model: "Venza",
    extra: "4 Door CUV",
    trim: "Hybrid Limited AWD",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto CVT"
  },
  {
    modelNumber: 3504,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Hybrid XLE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Aauto Cvt"
  },
  {
    modelNumber: 3506,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Hybrid XLE Plus",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 3507,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Hybrid XSE",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Cvt"
  },
  {
    modelNumber: 3508,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Hybrid XLE Premium",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 3514,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Hybrid Limited",
    engine: "4-Cyl HSD",
    type: "Car",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 3544,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "XLE",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 3545,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "XLE Plus",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 3546,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "XLE Premium",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 3547,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "XSE",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 3548,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Touring",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 3554,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Limited",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 3557,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "TRD",
    engine: "V6",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 3564,
    make: "Toyota",
    model: "Avalon",
    extra: "4 Door Sedan",
    trim: "Limited AWD",
    engine: "4 Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 4430,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "LE",
    engine: "4 Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4432,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "LE AWD",
    engine: "4 Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4435,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Hybrid LE AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 4440,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "XLE",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4442,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "XLE AWD",
    engine: "4 Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4444,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Hybrid XLE AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 4446,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Adventure AWD",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4448,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "TRD Off-Road AWD",
    engine: "4 Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4450,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Limited",
    engine: "4 Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4452,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Limited AWD",
    engine: "4 Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4454,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Hybrid Limited AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 4456,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Hybrid XSE AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 4472,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "SE AWD",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4476,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Platinum",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4478,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "XLE Premium AWD",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 4522,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Hybrid LE AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Cvt"
  },
  {
    modelNumber: 4526,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "XLE Hybrid AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Cvt"
  },
  {
    modelNumber: 4530,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "XSE Hybrid AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 4534,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Hybrid Limited AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 4544,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Prime SE Hybrid AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 4550,
    make: "Toyota",
    model: "RAV4",
    extra: "4 Door SUV",
    trim: "Prime XSE Hybrid AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Cvt"
  },
  {
    modelNumber: 5328,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "'L'",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5335,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "LE Auto Access",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5338,
    make: "Toyota",
    model: "Sienna",
    extra: "8 Passenger",
    trim: "LE",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5342,
    make: "Toyota",
    model: "Sienna",
    extra: "8 Passenger",
    trim: "SE",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5343,
    make: "Toyota",
    model: "Sienna",
    extra: "8 Passenger",
    trim: "SE Premium",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5345,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "XLE Mobility",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5348,
    make: "Toyota",
    model: "Sienna",
    extra: "8 Passenger",
    trim: "XLE",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5349,
    make: "Toyota",
    model: "Sienna",
    extra: "8 Passenger",
    trim: "XLE Premium",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5352,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "SE AWD",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5353,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "SE Premium AWD",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5356,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "Limited",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5357,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "Limited Premium",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5366,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "LE AWD",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5376,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "XLE AWD",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5377,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "XLE Premium AWD",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5386,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "Limited AWD",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 5387,
    make: "Toyota",
    model: "Sienna",
    extra: "7 Passenger",
    trim: "Limited Premium AWD",
    engine: "V6",
    type: "Van",
    transmission: "Auto"
  },
  {
    modelNumber: 6156,
    make: "Toyota",
    model: "Landcruiser",
    extra: "Wagon",
    trim: "VX-R 4WD",
    engine: "V8",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6157,
    make: "Toyota",
    model: "Landcruiser",
    extra: "Wagon",
    trim: "HE 4WD",
    engine: "V8",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6251,
    make: "Toyota",
    model: "86",
    extra: "2 Door Coupe",
    trim: "TRD SE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6252,
    make: "Toyota",
    model: "86",
    extra: "2 Door Coupe",
    trim: "",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6253,
    make: "Toyota",
    model: "86",
    extra: "2 Door Coupe",
    trim: "",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6254,
    make: "Toyota",
    model: "86",
    extra: "2 Door Coupe",
    trim: "GT",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6255,
    make: "Toyota",
    model: "86",
    extra: "2 Door coupe",
    trim: "GT",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6256,
    make: "Toyota",
    model: "86",
    extra: "2 Door Coupe",
    trim: "GT",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6259,
    make: "Toyota",
    model: "86",
    extra: "2 Door Coupe",
    trim: "860 Special Edition",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6262,
    make: "Toyota",
    model: "Yaris iA",
    extra: "4-Door Sedan",
    trim: "LE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6263,
    make: "Toyota",
    model: "Yaris iA",
    extra: "4-Door Sedan",
    trim: "LE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6266,
    make: "Toyota",
    model: "Yaris Sedan",
    extra: "4-Door",
    trim: "L",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6267,
    make: "Toyota",
    model: "Yaris Sedan",
    extra: "4-Door",
    trim: "L",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6268,
    make: "Toyota",
    model: "Yaris Sedan",
    extra: "4-Door",
    trim: "XLE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6272,
    make: "Toyota",
    model: "Corolla Hatchback",
    extra: "5-Door Hatchback",
    trim: "SE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6273,
    make: "Toyota",
    model: "Corolla Hatchback",
    extra: "5-Door Hatchback",
    trim: "SE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6274,
    make: "Toyota",
    model: "Corolla Hatchback",
    extra: "5-Door Hatchback",
    trim: "XSE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6275,
    make: "Toyota",
    model: "Corolla Hatchback",
    extra: "5-Door Hatchback",
    trim: "XSE",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Manual"
  },
  {
    modelNumber: 6276,
    make: "Toyota",
    model: "Corolla Hatchback",
    extra: "5-Door Hatchback",
    trim: "SE Nightshade",
    engine: "4-Cyl",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 6937,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "'L' AWD",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6942,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "LE",
    engine: "4-Cyl",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6946,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "LE",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6947,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "LE Plus",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6948,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "LE AWD",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6949,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "LE Plus AWD",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6951,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "XLE",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6952,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "SE AWD",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6953,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "XLE AWD",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6954,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "Limited",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6956,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "Limited AWD",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6957,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "Limited Platinum AWD",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 6964,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "Hybrid LE AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 6965,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "Hybrid XLE AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto Cvt"
  },
  {
    modelNumber: 6966,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "Hybrid Limited AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto CVT"
  },
  {
    modelNumber: 6967,
    make: "Toyota",
    model: "Highlander",
    extra: "4 Door SUV",
    trim: "Hybrid Limited Platinum AWD",
    engine: "4-Cyl HSD",
    type: "SUV",
    transmission: "Auto CVT"
  },
  {
    modelNumber: 7113,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "2WD Access Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7114,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "2WD Access Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7122,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "2WD SR5 Access Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7126,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "2WD SR5 Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7146,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "2WD SR5 Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7148,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "2WD TRD Sport Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7150,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "2WD TRD Off-Road Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7162,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "SR Access Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7164,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "SR Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7170,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "2WD SR5 Double Cab Long Bed",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7172,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "2WD TRD Sport Double Cab Long Bed",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7182,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "2WD Limited Double Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7185,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "2WD SR5 Double Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7186,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "SR Double Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7188,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "PreRunner Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7190,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "PreRunner Double Cab Long Bed",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7513,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD Access Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7514,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD SR Access Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7516,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD SR5 Access Cab",
    engine: "4-Cyl",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7540,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD SR5 Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7542,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Sport Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7543,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Sport Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7544,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Offroad Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7545,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Offroad Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7552,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD SR Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7553,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7554,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7556,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD Access Cab TRD Pro",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7558,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD SR5 Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7559,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD TRD Sport Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7560,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD TRD Sport Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7562,
    make: "Toyota",
    model: "Tacoma",
    extra: "Access Cab",
    trim: "4WD TRD Offroad Access Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7566,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Sport Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7568,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Offroad Double-Cab Long Bed",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7570,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD SR5 Double Cab Long Bed",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7582,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD Limited Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7593,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD SR Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7594,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD SR Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7596,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD Double Cab Long Bed",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7597,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Pro Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Manual"
  },
  {
    modelNumber: 7598,
    make: "Toyota",
    model: "Tacoma",
    extra: "Double Cab",
    trim: "4WD TRD Pro Double Cab",
    engine: "V6",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 7919,
    make: "Toyota",
    model: "Sequoia",
    extra: "4 Door SUV",
    trim: "4WD SR5",
    engine: "5.7L V8",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 7922,
    make: "Toyota",
    model: "Sequoia",
    extra: "4 Door SUV",
    trim: "4WD TRD Sport",
    engine: "V8",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 7928,
    make: "Toyota",
    model: "Sequoia",
    extra: "4 Door SUV",
    trim: "4WD TRD Pro",
    engine: "5.7L V8",
    type: "Car",
    transmission: "Auto"
  },
  {
    modelNumber: 7929,
    make: "Toyota",
    model: "Sequoia",
    extra: "4 Door SUV",
    trim: "4WD Limited",
    engine: "5.7L V8",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 7933,
    make: "Toyota",
    model: "Sequoia",
    extra: "4 Door SUV",
    trim: "4WD Platinum",
    engine: "5.7L V8",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8239,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "SR Double Cab",
    engine: "4.6L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8241,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "SR5 Double Cab",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8245,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "SR Double Cab Long Bed",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8252,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "Limited Double Cab",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8272,
    make: "Toyota",
    model: "Tundra",
    extra: "CrewMax",
    trim: "Limited CrewMax",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8339,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD SR Double Cab",
    engine: "4.6L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8340,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD SR5 Double Cab",
    engine: "4.6L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8341,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD SR5 Double Cab",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8342,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD SR Double Cab",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8345,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD SR Double Cab Long Bed",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8346,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD SR5 Double Cab",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8351,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD TRD Pro Double Cab",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8352,
    make: "Toyota",
    model: "Tundra",
    extra: "Double Cab",
    trim: "4WD Limited Double Cab",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8359,
    make: "Toyota",
    model: "Tundra",
    extra: "CrewMax",
    trim: "4WD SR5 CrewMax",
    engine: "4.6L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8361,
    make: "Toyota",
    model: "Tundra",
    extra: "CrewMax",
    trim: "4WD SR5 CrewMax",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8371,
    make: "Toyota",
    model: "Tundra",
    extra: "CrewMax",
    trim: "4WD TRD Pro CrewMax",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8372,
    make: "Toyota",
    model: "Tundra",
    extra: "CrewMax",
    trim: "4WD Limited CrewMax",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8375,
    make: "Toyota",
    model: "Tundra",
    extra: "CrewMax",
    trim: "4WD Platinum CrewMax",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8376,
    make: "Toyota",
    model: "Tundra",
    extra: "CrewMax",
    trim: "4WD 1794 Edition CrewMax",
    engine: "5.7L V8",
    type: "Truck",
    transmission: "Auto"
  },
  {
    modelNumber: 8664,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD SR5",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8665,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD Trail Special Edition",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8666,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD SR5 Premium",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8667,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD Venture Special Edition",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8668,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD Limited",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8669,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD Limited Nightshade",
    engine: "V8",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8670,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD TRD Offroad",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8672,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD TRD Offroad Premium",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  },
  {
    modelNumber: 8674,
    make: "Toyota",
    model: "4Runner",
    extra: "4 Door SUV",
    trim: "4WD TRD Pro",
    engine: "V6",
    type: "SUV",
    transmission: "Auto"
  }
];

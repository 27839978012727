import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService, CalculationUtilityService, CalculationService, DealService } from "../../../services";
import { GlobalSettings, VehicleNeeds } from "src/app/clearpath-module/models";

@Component({
  selector: "app-fuel-savings-calculator",
  templateUrl: "./fuel-savings-calculator.component.html",
  styleUrls: ["./fuel-savings-calculator.component.scss"]
})
export class FuelSavingsCalculatorComponent implements OnInit, OnDestroy {

  fuelSavings: number;

  fuelSavingsForm: FormGroup = this.formBuilder.group({
    currentVehicleMPG: [15, [Validators.required, Validators.min(1)]],
    newVehicleMPG: [25, [Validators.required, Validators.min(1)]],
    milesDrivenPerMonth: [1000, [Validators.required, Validators.min(0)]],
    fuelCostPerGallon: [0, [Validators.required, Validators.min(0)]]
  });

  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private calculationService: CalculationService,
    private calcHelpers: CalculationUtilityService,
    private dealService: DealService
  ) { }

  ngOnInit() {
    this.subToVehicleNeeds();
    this.subToGlobalSettings();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private subToVehicleNeeds() {
    this.dealService.selectVehicleNeeds()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicleNeeds: VehicleNeeds) => {
        if (vehicleNeeds.milesDrivenPerYear) {
          const milesDrivenPerMonth = Math.round(vehicleNeeds.milesDrivenPerYear / 12);
          this.fuelSavingsForm.controls.milesDrivenPerMonth.setValue(milesDrivenPerMonth);
        }
      });
  }

  private subToGlobalSettings() {
    this.appService.selectGlobalSettings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((globalSettings: GlobalSettings) => {
        const {fuelCostPerGallon} = globalSettings;
        this.fuelSavingsForm.patchValue({fuelCostPerGallon});
      });
  }

  // UI RENDERING

  get calcFuelSavings(): number {
    const {
      currentVehicleMPG,
      newVehicleMPG,
      milesDrivenPerMonth,
      fuelCostPerGallon
    } = this.fuelSavingsForm.value;

    return this.calculationService.getFuelSavings(
      currentVehicleMPG,
      newVehicleMPG,
      milesDrivenPerMonth,
      fuelCostPerGallon
    );
  }

  // FORM VALIDATION & HELPERS

  touchedInvalid(controlName: string): boolean {
    const control = this.fuelSavingsForm.get(controlName);
    return control.touched && control.invalid;
  }

  integerTrim(controlName: string) {
    const value = this.fuelSavingsForm.value[ controlName ];
    const integerValue = Math.floor(value);
    this.fuelSavingsForm.patchValue({[ controlName ]: integerValue});
  }

  decimalTrim(controlName: string) {
    const value = this.fuelSavingsForm.value[ controlName ];
    const decimalValue = this.calcHelpers.truncateDecimal(value, 2);
    this.fuelSavingsForm.patchValue({[ controlName ]: decimalValue});
  }

}

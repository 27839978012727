<form [formGroup]="rollPaymentForm" class="row">

  <div class="form-group col-sm-6">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('desiredPayment') }">
      Desired Monthly Payment *
    </label>
    <input class="form-control" formControlName="desiredPayment" inputmode="numeric" type="number" (change)="calcDownPayment()"
           (keyup)="calcDownPayment()" required/>
  </div>

  <div class="form-group col-sm-6">
    <label class="small" [ngClass]="{ 'app-text-danger': touchedInvalid('numberOfMonthlyPayments') }">
      Number of Payments *
    </label>
    <input class="form-control" formControlName="numberOfMonthlyPayments" inputmode="numeric" type="number" (change)="calcDownPayment()"
           (keyup)="calcDownPayment()" required/>
  </div>

  <div *ngIf="rollPaymentForm.valid" class="form-group col-sm-6">
    <label class="small">Down Payment Needed</label>
    <div class="input-group">
      <div class="form-control app-bg-light">
        {{ rollPaymentForm.value.downPayment | currency }}
      </div>
      <div class="input-group-append">
        <button class="btn app-btn-secondary" (click)="onSubmitRollPaymentForm()"
                [disabled]="rollPaymentForm.invalid || !rollPaymentForm.dirty" type="submit">
          Close
        </button>
      </div>
    </div>
  </div>

</form>

import { Component, OnInit } from "@angular/core";
import { NewResiduals, ResidualValue } from "../../models/lease-residuals";
import { SettingsService } from "../../services";
import { AlertService } from "../../../shared-module/services";

@Component({
  selector: "app-lease-residuals",
  templateUrl: "./lease-residuals.component.html",
  styleUrls: ["./lease-residuals.component.scss"]
})
export class LeaseResidualsComponent implements OnInit {

  private uiState = {
    activeViewId: "viewNewResiduals",
    waitOn: {init: true, action: false},
    unsavedChanges: false,
    viewList: [
      {id: "viewNewResiduals", title: "View New Residuals"},
      {id: "viewCertUsedResiduals", title: "View Certified Residuals"},
      {id: "uploadNewResiduals", title: "Upload New Residuals"},
      {id: "uploadCertUsedResiduals", title: "Upload Certified Residuals"}
    ]
  };

  newResiduals: NewResiduals[] = [];
  certUsedResiduals: ResidualValue[] = [];

  constructor(
    private settingsService: SettingsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getNewResiduals();
    this.getCertUsedResiduals();
  }

  canDeactivate(): boolean {
    const {unsavedChanges} = this.uiState;
    const navigateAway = unsavedChanges ? confirm("Discard Changes?") : true;
    return navigateAway;
  }

  // INITIALIZATION

  private getNewResiduals() {
    this.settingsService.getNewResiduals().subscribe({
      next: (data) => {
        this.newResiduals = data.newResiduals;
        this.uiState.waitOn.init = false;
      },
      error: (err) => {
        this.uiState.waitOn.init = false;
        this.alertService.error(err);
      }
    });
  }

  private getCertUsedResiduals() {
    this.settingsService.getCertUsedResiduals().subscribe({
      next: (data) => {
        this.certUsedResiduals = data.residualValues;
      },
      error: (err) => {
        this.uiState.waitOn.init = false;
        this.alertService.error(err);
      }
    });
  }

  private setNewResiduals(newResiduals: NewResiduals[]) {
    this.uiState.waitOn.action = true;

    this.settingsService.setNewResiduals(newResiduals).subscribe({
      next: (data: any) => {
        this.newResiduals = data.newResiduals;
        this.uiState.waitOn.action = false;
        this.uiState.unsavedChanges = false;
        this.alertService.success("New residuals successfully saved.");
        window.scroll({top: 0, behavior: "smooth"});
      },
      error: (err) => {
        this.uiState.waitOn.action = false;
        this.alertService.error(err);
        window.scroll({top: 0, behavior: "smooth"});
      }
    });
  }

  private setCertUsedResiduals(certUsedResiduals: ResidualValue[]) {
    this.uiState.waitOn.action = true;

    this.settingsService.setCertUsedResiduals(certUsedResiduals).subscribe({
      next: (data: any) => {
        this.certUsedResiduals = data.residualValues;
        this.uiState.waitOn.action = false;
        this.uiState.unsavedChanges = false;
        this.alertService.success("Certified used residuals successfully saved.");
        window.scroll({top: 0, behavior: "smooth"});
      },
      error: (err) => {
        this.uiState.waitOn.action = false;
        this.alertService.error(err);
        window.scroll({top: 0, behavior: "smooth"});
      }
    });
  }

  // ACTIONS

  onSelectView(id: string) {
    if (id === this.uiState.activeViewId) { return; }

    const {unsavedChanges} = this.uiState;
    const switchView = unsavedChanges ? confirm("Discard Changes?") : true;

    if (switchView) {
      this.uiState.activeViewId = id;
      this.uiState.unsavedChanges = false;
      this.alertService.clear();
    }
  }

  onSubmitNewResiduals(residuals: NewResiduals[]) {
    this.setNewResiduals(residuals);
  }

  onSubmitCertUsedResiduals(residuals: ResidualValue[]) {
    this.setCertUsedResiduals(residuals);
  }

  // UI STATE & RENDERING

  activeView(id: string): boolean {
    return id === this.uiState.activeViewId;
  }

  get viewList(): Array<{ id: string; title: string; }> {
    return this.uiState.viewList;
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOn.action;
  }

  get waitOnInit(): boolean {
    return this.uiState.waitOn.init;
  }

  sortResidualsByYear(newResiduals: NewResiduals[]) {
    return newResiduals.sort((a, b) => {
      if (a.year < b.year) { return 1; }
      if (a.year > b.year) { return -1; }
      return 0;
    });
  }

  // HELPERS

  unsavedChanges(unsavedChanges: boolean) {
    this.uiState.unsavedChanges = unsavedChanges;
    this.alertService.clear();
  }

}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { VehicleService } from "../../services";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as vehicleActions from "src/app/clearpath-module/store/actions/vehicle.actions";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

@Component({
  selector: "app-stock-number",
  templateUrl: "./stock-number.component.html",
  styleUrls: ["./stock-number.component.scss"]
})
export class StockNumberComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  @Output() formSubmitFlag = new EventEmitter();
  error = "";

  public REQS = FORM_INPUT_REQS;
  stockNumberForm: FormGroup = this.formBuilder.group({
    stockNumber: [""],
    odometer: [null, Validators.min(0)],
  });

  constructor(
    private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private router: Router,
    private actions$: Actions,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        if (params.stockNumber !== "") {
          this.stockNumberForm.patchValue({stockNumber: params.stockNumber});
        }
      });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(
        vehicleActions.changeStockNumberSuccess,
        vehicleActions.changeStockNumberFailure
      ),
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({error}) => {
        error ? this.error = error : this.formSubmitFlag.emit();
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openVehicleLookup() {
    const navigationExtras: NavigationExtras = {
      queryParams: {changeDealOnSelectVehicle: false, dispatchStockNumberOnly: true,},
    };
    this.router.navigate([{
      outlets: {
        modal: ["vehicle-lookup"]
      },
    }], navigationExtras);
  }

  onSubmitForm() {
    const {stockNumber, odometer} = this.stockNumberForm.value;
    this.vehicleService.dispatchChangeStockNumber(stockNumber, odometer);
  }

}

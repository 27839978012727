import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SalesQueueRoutingModule } from "./sales-queue-routing.module";
import { SharedModule } from "../shared-module/shared.module";
import { PubNubAngular } from "pubnub-angular2";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { QItemComponent, QListComponent, StockNumberComponent } from "./components";
import { SalesQueueAdminComponent, SalesQueueDisplayComponent, SalesQueueViewComponent } from "./pages";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    QItemComponent,
    QListComponent,
    StockNumberComponent,
    SalesQueueDisplayComponent,
    SalesQueueViewComponent,
    SalesQueueAdminComponent
  ],
  providers: [PubNubAngular],
  imports: [
    CommonModule,
    SalesQueueRoutingModule,
    SharedModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SalesQueueModule {}

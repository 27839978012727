import { Component, OnInit } from "@angular/core";
import { SalesQueueService } from "../../services/sales-queue.service";
import { AlertService } from "../../../shared-module/services";
import { PubnubService } from "../../../shared-module/services/PubSub/pubnub.service";
import { SalesCue, SalesQPerson } from '../../models/sales-queue';

@Component({
  selector: "app-sales-queue",
  templateUrl: "./sales-queue-display.component.html",
  styleUrls: ["./sales-queue-display.component.scss"]
})
export class SalesQueueDisplayComponent implements OnInit {
  uiState = {
    salesQueue: [],
    count: {
      available: 0,
      withGuest: 0
    }
  };

  constructor(
    private salesQueueService: SalesQueueService,
    private alertService: AlertService,
    private pubsub: PubnubService
  ) { }

  ngOnInit() {
    this.watchSalesQueueRotation();
  }

  // INITIALIZATION

  watchSalesQueueRotation() {
    this.getSalesQueue();
    this.pubsub.salesQueueChanged(() => this.getSalesQueue());
  }

  getSalesQueue() {
    this.salesQueueService.read().subscribe({
      next: data => {
        this.pruneAndSaveQueue(data);
      },
      error: error => {
        this.alertService.error(error);
      }
    });
  }

  pruneAndSaveQueue(data: SalesCue
  ) {
    const rawQueue = data.salesQueue || [];
    const cleanQueue: Array<SalesQPerson> = [];
    let inQueue = 0;
    let withGuest = 0;

    for (const member of rawQueue) {
      if (member.inQueue) {
        cleanQueue.push(member);
        inQueue++;
        if (member.withCustomer) { withGuest++; }
      }
    }

    this.uiState = {
      salesQueue: cleanQueue,
      count: {
        available: inQueue - withGuest,
        withGuest
      }
    };
  }

  // UI CONTROL & RENDERING

  get salesQueue(): Array<SalesQPerson> {
    return this.uiState.salesQueue;
  }

  get availableCount(): number {
    return this.uiState.count.available;
  }

  get withGuestCount(): number {
    return this.uiState.count.withGuest;
  }

  fullName(person: SalesQPerson): string {
    const {firstName, lastName} = person;
    return `${firstName} ${lastName}`;
  }

  nameInitials(person: SalesQPerson): string {
    const {firstName, lastName} = person;
    return `${firstName.slice(0, 1).toUpperCase()} ${lastName.slice(0, 1).toUpperCase()}`;
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { combineLatest, of } from "rxjs";
import { pathOr } from "ramda";
import { catchError, concatMap, map, switchMap, take, withLatestFrom } from "rxjs/operators";
import * as dealActions from "../actions/deal.actions";
import * as vehicleActions from "../actions/vehicle.actions";
import * as appActions from "../actions/app.actions";
import * as historyActions from "../actions/history.actions";
import { CalculationService, DealIncentivesService, DealService, LeaseCalculationService, VehicleService } from "../../services";
import { Router } from "@angular/router";
import { v1 as uuidv1 } from "uuid";
import { DealState } from "../state";
import { AuthService } from "src/app/auth-module/services";
import Big from "big.js";
import { FormCalculationService } from "../../services/calculations/form-calculation.service";
import { PLATE_TRANSFER_FEE } from "../../../app.config";
import swal, { SweetAlertOptions } from "sweetalert2";
import { PubNubAngular } from "pubnub-angular2";

@Injectable()
export class DealEffects {

  constructor(
    private actions$: Actions,
    private incentivesService: DealIncentivesService,
    private dealService: DealService,
    private authService: AuthService,
    private calculationService: CalculationService,
    private formCalculationService: FormCalculationService,
    private leaseCalculationService: LeaseCalculationService,
    private vehicleService: VehicleService,
    private router: Router,
    private pubnub: PubNubAngular,
  ) { }

  getDealByDealId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.getDealByDealId),
      switchMap(({dealId}) => {
        return this.dealService.getByDealId(dealId).pipe(
          switchMap((deal: DealState) => {
            this.vehicleService.getVehicle(deal.stockNumber, 0);
            return [dealActions.dealApiSuccess(deal), historyActions.getHistory(deal.dealId)];
          }),
          catchError(error => of(dealActions.dealApiFailure({error})))
        );
      })
    )
  );


  getPrintDeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.getPrintDeals),
      switchMap(() => this.dealService.getPrintDeals().pipe(
        map(resDeal => dealActions.getPrintDealsApiSuccess(resDeal)),
        catchError(error => of(dealActions.getPrintDealsApiFailure({error})))
      ))
    ));

  createDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.createDeal),
      withLatestFrom(this.authService.selectUser()),
      switchMap(([{deal}, user]) => {
        const contractDate = new Date().toLocaleString('en-US', {timeZone: "America/Los_Angeles"});
        let [month, day, year] = contractDate.split(',')[ 0 ].substr(0, 10).split("/");
        day = Number.parseInt(day) < 10 ? "0" + day : day;
        month = Number.parseInt(month) < 10 ? "0" + month : month;
        deal.contractDate = [year, month, day].join("-");
        //console.log("Contract Date Raw:", contractDate, "PT, Contract Date Formatted:", deal.contractDate)
        const isSalesManager = this.authService.isSalesManager(user);
        deal.dealId = uuidv1(); // create unique id for the deal
        if (isSalesManager) {
          deal.managerId = user.employeeId;
          deal.salesManager = `${user.firstName} ${user.lastName}`;
          deal.salesId = user.employeeId;
          deal.salesPerson = `${user.firstName} ${user.lastName}`;
          deal.salesPersonPhone = user.smsCellNumber || '';
        } else {
          deal.salesId = user.employeeId;
          deal.salesPerson = `${user.firstName} ${user.lastName}`;
          deal.salesPersonPhone = user.smsCellNumber || '';
        }
        //console.log("Effect:", deal);
        return this.dealService.create(deal).pipe(
          map(resDeal => {
            return dealActions.dealApiSuccess(resDeal);
          }),
          switchMap(({deal: newDeal}) => {

            try {
              this.pubnub.publish({
                  channel: 'messageChannel', message: {
                    type: "WriteupCreated",
                    userId: deal.salesId,
                    salesPersonName: deal.salesPerson,
                    customerName: deal.customer.companyName || deal.customer.firstName + " " + deal.customer.lastName,
                    vehicle: deal.year + ' ' + deal.make + ' ' + deal.model + ' ' + deal.trim,
                    stockNumber: deal.stockNumber,
                    dealId: deal.dealId
                  }
                }
              ).then();
            } catch (e) {
              console.log("Could not pubnub:", e);
            }

            return this.authService.isSalesManager$().pipe(
              take(1),
              map(isSalesManager => {
                isSalesManager ?
                  this.router.navigate([`/sales-manager/writeup/${newDeal.dealId}`]) :
                  this.router.navigate([`/clearpath/deal/${newDeal.dealId}`]);
                return newDeal.dealerTrade ? vehicleActions.getAllVehicles() : dealActions.noOp();
              })
            );
          }),
          catchError(error => of(dealActions.dealApiFailure({error})))
        );
      }),
    ));

  salesManagerCreateDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.salesManagerCreateDeal),
      switchMap(({deal}) => {
        return this.dealService.create(deal).pipe(
          map(resDeal => dealActions.dealApiSuccess(resDeal)),
          catchError(error => of(dealActions.dealApiFailure({error})))
        );
      })
    ));

  submitDealSubmitModalForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.submitDealSubmitModalForm),
      switchMap(({signatureData, signatureUrls}) => {
        if (signatureUrls.buyerSignatureUrl) {
          return of(dealActions.getDealAndSubmit());
        }
        return this.dealService.uploadSignatures(signatureData)
          .pipe(
            concatMap(([buyerStorageResponse, coBuyerStorageResponse]) => {
              const buyerSignatureSaved: boolean = pathOr(
                "", ["state"], buyerStorageResponse
              ) === "success";
              const coBuyerSignatureSaved: boolean = pathOr(
                "", ["state"], coBuyerStorageResponse
              ) === "success";
              return [
                dealActions.setSignaturesSuccess({buyerSignatureSaved, coBuyerSignatureSaved}),
                dealActions.getDealAndSubmit()
              ];
            }),
            catchError(error => of(dealActions.setSignaturesFailure({error}))));
      }),
    ));


  getDealAndSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.getDealAndSubmit),
      withLatestFrom(this.dealService.selectDeal()),
      map(([action, deal]) => {
        if (deal.leaseOptions.leaseSelected) {
          deal.insuranceProducts = this.calculationService
            .filterProductsByType(deal.insuranceProducts, "lease")
            .map(product => {
              return this.dealService.dealInsuranceService
                .selectTerm(product, deal.leaseOptions.selectedLeaseTerm);
            });
        }
        deal.insuranceInfo = this.dealService.parseDatesForSubmission(deal.insuranceInfo);
        deal.verifiedForms = [];
        return dealActions.submitDeal(deal);
      }),
    ));

  submitDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.submitDeal),
      switchMap(({deal}) => this.dealService.submit(deal).pipe(
        map(resDeal => dealActions.dealApiSuccess(resDeal, 'submit')),
        catchError(error => of(dealActions.dealApiFailure({error})))
      )),
    ));

  retractDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.retractDeal),
      switchMap(({dealId}) => this.dealService.retract(dealId).pipe(
        map(resDeal => dealActions.dealApiSuccess(resDeal, 'retract')),
        catchError(error => of(dealActions.dealApiFailure({error})))
      ))
    ));

  updateDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.updateDeal),
      switchMap(({deal}) => this.dealService.update(deal).pipe(
        map(resDeal => dealActions.dealApiSuccess(resDeal, 'update')),
        catchError(error => of(dealActions.dealApiFailure({error})))
      ))
    ));

  saveDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.saveDeal),
      switchMap(({deal}) => {
        return this.formCalculationService.formServerCalculations$();
      }),
      switchMap((deal) => {
        return this.dealService.save(deal).pipe(
          map(resDeal => dealActions.dealApiSuccess(resDeal, 'save')),
          catchError(error => of(dealActions.dealApiFailure({error})))
        );
      })
    ));

  approveDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.approveDeal),
      switchMap(({criticalMemo}) => this.formCalculationService.formServerCalculations$().pipe(
        map(updatedDeal => ({updatedDeal, criticalMemo}))
      )),
      switchMap(({updatedDeal, criticalMemo}) => this.dealService.save(updatedDeal).pipe(
        switchMap((serverDeal: DealState) => {
          return this.dealService.approve(serverDeal.dealId, criticalMemo);
        }),
        map(resDeal => {
          swal.fire({
            title: "Send to PBS Complete",
            icon: "success",
            showConfirmButton: true,
            confirmButtonText: "OK",
          } as SweetAlertOptions).then();
          return dealActions.dealApiSuccess(resDeal, 'approve');
        }),
        catchError(error => of(dealActions.dealApiFailure({error}))))
      )
    ));

  printDealSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.printDeal),
      switchMap(() => {
        return this.calculationService.calcTax$().pipe(take(1));
      }),
      switchMap(({privilegeTax, salesTax, catTax, totalStateTaxes, totalTax}) => {
        return [
          dealActions.setFinanceOptions({privilegeTax, salesTax, catTax, totalStateTaxes, totalTax}),
          dealActions.printDealSetupFinished()
        ];
      })
    ));

  printDealSetupFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.printDealSetupFinished),
      switchMap(() => this.formCalculationService.formServerCalculations$()),
      switchMap(updatedDeal => {
        return this.dealService.save(updatedDeal).pipe(
          switchMap((serverDeal: DealState) => {
            return this.dealService.print(serverDeal.dealId).pipe(
              map(resDeal => dealActions.dealApiSuccess(resDeal)),
              catchError(error => of(dealActions.dealApiFailure({error})))
            );
          }));
      })
    ));


  declineDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.declineDeal),
      switchMap(({dealId}) => this.dealService.decline(dealId).pipe(
        map(resDeal => dealActions.dealApiSuccess(resDeal)),
        catchError(error => of(dealActions.dealApiFailure({error})))
      ))
    ));

  completeDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.completeDeal),
      switchMap(({deal}) => {
        return this.dealService.complete(deal).pipe(
          map(resDeal => dealActions.dealApiSuccess(resDeal)),
          catchError(error => of(dealActions.dealApiFailure({error})))
        );
      })
    ));

  setInsuranceProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.setInsuranceProducts),
      withLatestFrom(this.dealService.selectDeal()),
      switchMap(([{insuranceProducts}, deal]) => {
        let accDeleteAction = dealActions.noOp();
        const cceIndex = insuranceProducts ? insuranceProducts.findIndex(product => product.name === "Clear Care Elite") : -1;
        if (cceIndex > -1) {
          const cceAccIndex = deal.accessories.findIndex(acc => acc.name === "Clear Care Elite Appearance");
          if (cceAccIndex > -1) {
            deal.accessories.splice(cceAccIndex, 1);
            accDeleteAction = dealActions.setAccessories(deal.accessories);
          }
        }
        // products.findIndex(product => product.)
        return [dealActions.calcFees(), dealActions.calcTax(), accDeleteAction];
      })
    ));

  setCustomerStateCounty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        dealActions.calcFees,
        appActions.getZipTaxTableSuccess,
        dealActions.setFinancingTerm,
        dealActions.setLeasingTerm,
        dealActions.setCustomer
      ),
      switchMap(action => {
        return this.calculationService.calcFees$()
          .pipe(
            take(1),
            map(({totalFees, countyFee, titleFee, regFee, homeState, docFees, dmvTotalFees, totalRegFee, county}) => {
              return homeState || county ?
                dealActions.setCustomerStateCounty({state: homeState, county}) :
                dealActions.noOp();
            })
          );
      })
    ));

  calcFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        dealActions.calcFees,
        appActions.getZipTaxTableSuccess,
        dealActions.setFinancingTerm,
        dealActions.setLeasingTerm,
        dealActions.setCustomer,
        dealActions.setPlateTransfer
      ),
      switchMap((action) => {
        return this.calculationService.calcFees$()
          .pipe(
            take(1),
            map(({
                   totalFees,
                   countyFee,
                   titleFee,
                   regFee,
                   docFees,
                   dmvTotalFees,
                   totalRegFee,
                   totalStateFees
                 }) => {
              return dealActions.setFinanceOptions({
                totalFees,
                countyFee,
                titleFee,
                regFee,
                docFees,
                totalRegFee,
                dmvTotalFees,
                totalStateFees,
                plateTransferFeeWhenApplied: PLATE_TRANSFER_FEE
              });
            })
          );
      })
    ));

  calcTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        dealActions.calcTax,
        appActions.getZipTaxTableSuccess,
        dealActions.setIncentives,
        dealActions.setAccessories,
        dealActions.setDealType,
      ),
      switchMap(() => this.calculationService.calcTax$().pipe(
        take(1),
        switchMap(({
                     privilegeTax,
                     salesTax,
                     catTax,
                     totalStateTaxes,
                     totalTax,
                   }) => {
          return [
            dealActions.setFinanceOptions({privilegeTax, salesTax, catTax, totalStateTaxes, totalTax}),
            // homeState ?
            //   dealActions.setCustomer({ state: homeState }) :
            //   dealActions.noOp()
          ];
        }),
      ))
    ));

  clearCustomMoneyFactors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.clearCustomMoneyFactors),
      withLatestFrom(this.dealService.selectDeal()),
      map(([action, deal]) => {
        //console.log("Clearing Custom Money Factors");
        deal?.leaseOptions?.leaseTerms?.forEach(term => {
          term.customMoneyFactor = false;
        });
        return dealActions.setLeaseOptions(deal.leaseOptions);
      }),
    ));

  setFinancingTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.setFinancingTerm),
      switchMap(({selectedFinancingTerm}) => combineLatest([
        this.dealService.selectFinanceOptions(),
        this.calculationService.findInterestRate$(),
        this.dealService.selectIncentives(),
        this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$()
      ]).pipe(
        take(1),
        switchMap(([financeOptions, interestRate, incentives, monthlyPayment]) => {
          return this.calculationService.isIncentiveRate$({term: selectedFinancingTerm})
            .pipe(
              take(1),
              switchMap(isIncentiveRate => {
                return [
                  dealActions.setFinanceOptions({
                    monthlyPayment,
                    //customerProvidedFinancingTerm: financeOptions.customSelected ?
                    //  selectedFinancingTerm :
                    //  0,
                    incentiveSelected: isIncentiveRate,
                    activeInterestRate: interestRate,
                  }),
                  dealActions.setGapToFinancingTerm(selectedFinancingTerm)
                ];
              }));
        })
      ))
    ));

  checkIneligibleIncentives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.setFinancingTerm),
      switchMap(({selectedFinancingTerm}) => combineLatest([
        this.dealService.selectDeal(),
        this.vehicleService.selectVehicle()
      ]).pipe(
        take(1),
        map(([deal, vehicle]) => {
          return this.incentivesService
            .checkIneligibleIncentives(deal?.incentives?.length ? deal.incentives[ 0 ] : null, selectedFinancingTerm);
        })
      ))
    ), {dispatch: false});

  setGapToFinancingTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.setGapToFinancingTerm),
      switchMap(({selectedFinancingTerm}) => {
        return this.dealService.dealInsuranceService.setGapToFinanceTerm$(selectedFinancingTerm)
          .pipe(switchMap((insuranceProducts) => {
            return [
              dealActions.setInsuranceProducts(insuranceProducts)
            ];
          }));
      })
    ));

  setVehicleNeeds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.setVehicleNeeds),
      withLatestFrom(
        this.dealService.dealInsuranceService.selectInsuranceProducts(),
        this.authService.isSalesManager$(),
        this.authService.isAdmin$()
      ),
      map(([{vehicleNeeds}, insuranceProducts, isSalesManager, isAdmin]) => {
        if (isSalesManager || isAdmin) { return; }
        const setTerms = this.dealService.dealInsuranceService.createInsuranceProductTerms(insuranceProducts, vehicleNeeds);
        insuranceProducts = this.dealService.dealInsuranceService.configProductSelections(
          insuranceProducts,
          setTerms,
          vehicleNeeds,
          false
        );
        this.dealService.dealInsuranceService.dispatchSetInsuranceProducts(insuranceProducts);
        // return [dealActions.setInsuranceProducts(insuranceProducts)];
      }),
      switchMap(() => {
        return this.recalculateLeaseOptionsFn();
      })
    ));

  setLeasingTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.setLeasingTerm),
      switchMap((action) => {
        return this.recalculateLeaseOptionsFn();
      }),
    ));

  // salesManagerSetFinancingInsuranceProducts$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(dealActions.salesManagerSetFinancingTerm),
  //     withLatestFrom(this.dealService.Insurance.selectFinanceInsuranceProducts()),
  //     map(([action, insuranceProducts]) => {
  //       const changedProducts = this.dealService.Insurance
  //         .setClosestTerms(insuranceProducts, action.selectedFinancingTerm);
  //       return dealActions.setInsuranceProducts(changedProducts);
  //     })
  //   )
  // );

  recalculateLeaseOptionsFn() {
    return combineLatest([
      this.leaseCalculationService.calcTotalMonthlyLeasePayment$(),
      this.leaseCalculationService.calcGrossCapCost$(),
      this.leaseCalculationService.moneyFactor$(),
      this.leaseCalculationService.residualValue$(),
      this.leaseCalculationService.calcExcessMiles$(),
      this.dealService.selectIncentives(),
      this.vehicleService.selectVehicle()
    ])
      .pipe(
        take(1),
        map(([
               monthlyPayment,
               grossCapCost,
               moneyFactor,
               residualValue,
               excessMiles,
               incentives,
               vehicle
             ]) => {
          const capReduction = Big(grossCapCost).minus(monthlyPayment).round(2).toNumber();

          // customer cash does not apply to lease
          // if (incentives.length && vehicle.incentives.length) {
          //   const incentive = incentives[0];
          //   const vehicleIncentive = vehicle.incentives[0];
          //   incentive.financeOffer = vehicleIncentive.incentive.financeOffer;
          //   incentive.customerCash = 0;
          //   incentive.customerCashDisabled = true;
          // }

          return dealActions.setLeaseOptions({
            activeMoneyFactor: moneyFactor,
            monthlyPayment,
            grossCapCost,
            capReduction,
            residualValue,
            prepaidMiles: Math.round(excessMiles)
          });
        })
      );
  }

  setStateTaxesFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.setStateTaxesFees),
      withLatestFrom(this.dealService.selectDeal()),
      switchMap(([{totalStateTaxes, totalStateFees}, deal]) => {
        //console.log("Setting State Taxes and Fees:", totalStateTaxes, totalStateFees)
        let accDeleteAction = dealActions.noOp();
        /* const cceIndex = insuranceProducts ? insuranceProducts.findIndex(product => product.name === "Clear Care Elite") : -1;
         if (cceIndex > -1) {
           const cceAccIndex = deal.accessories.findIndex(acc => acc.name === "Clear Care Elite Appearance");
           if (cceAccIndex > -1) {
             deal.accessories.splice(cceAccIndex, 1);
             accDeleteAction = dealActions.setAccessories(deal.accessories);
           }
         }*/
        dealActions.setFinanceOptions({totalStateTaxes, totalStateFees});
        // products.findIndex(product => product.)
        return [dealActions.calcFees(), dealActions.calcTax(), accDeleteAction];
      })
    ));
}

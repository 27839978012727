import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DefaultFinanceRate, DefaultVehicleQualifier, FinanceOffer, FinanceRate, Incentive, LeaseOffer, VehicleQualifier } from "../../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { isNil, pathOr } from "ramda";
import { LeaseOfferTerm } from "src/app/settings-module/models/incentives";

@Component({
  selector: "app-incentive-form",
  templateUrl: "./incentive-form.component.html",
  styleUrls: ["./incentive-form.component.scss"]
})
export class IncentiveFormComponent implements OnInit {

  @Input() set incentive(incentive: Incentive) { this.syncIncentive(incentive); }

  @Input() set expansionAction(action: { "showContents": boolean, changeDetection: number }) {
    if (!this.waitOnAction) { this.showContents = action.showContents; }
  }

  constructor(private formBuilder: FormBuilder) { }

  // UI & RENDERING
  get waitOnAction(): boolean {
    return this.uiState.waitOnAction;
  }

  set waitOnAction(wait: boolean) {
    this.uiState.waitOnAction = wait;
  }

  get showContents(): boolean {
    return this.uiState.showContents;
  }

  set showContents(show: boolean) {
    this.uiState.showContents = show;
  }

  @Input() itemIndex: number;
  @Output() removeIncentive = new EventEmitter();
  @Output() updateIncentive = new EventEmitter<Incentive>();

  public REQS = FORM_INPUT_REQS;
  uiState = {waitOnAction: false, showContents: false, incentiveSynced: false};

  incentiveForm: FormGroup = this.formBuilder.group({
    title: [""],
    vehicleQualifierList: new FormArray([]),
    financeOffer: this.formBuilder.group({
      financeRates: new FormArray([]),
      aprSubventionTiers: new FormArray([]),
      customerCash: [0]
    }),
    leaseOffer: this.formBuilder.group({
      leaseOfferTerms: new FormArray([]),
    }),
    cashDetails: this.formBuilder.group({
      militaryRebate: [0],
      collegeRebate: [0],
      bonusCash: [0],
      leaseCash: [0],
      financeCash: [0],
      otherRebate: [0]
    }),
    dealerFinancingRequired: [false],
    combineOffers: [true, Validators.required]
  });

  ngOnInit() {
    this.subToFormChanges();
  }

  // INITIALIZATION

  subToFormChanges() {
    this.incentiveForm.valueChanges.subscribe(_ => {
      if (!this.waitOnAction) { this.onUpdateIncentive(); }
    });
    this.incentiveForm.controls.financeOffer.valueChanges.subscribe(_ => {
      if (!this.waitOnAction) { this.onUpdateIncentive(); }
    });
  }

  syncIncentive(incentive: Incentive) {
    if (this.uiState.incentiveSynced) { return; }
    this.uiState.incentiveSynced = true;
    this.setVehicleQualifierList(incentive.vehicleQualifierList);
    this.setFinanceRates(pathOr(null, ["financeOffer", "financeRates"], incentive));
    this.setLeaseMFs(incentive.leaseOffer.leaseOfferTerms);
    this.setAprSubventionTiers(pathOr(null, ["financeOffer", "aprSubventionTiers"], incentive));
    this.incentiveForm.patchValue({
      title: incentive.title,
      dealerFinancingRequired: incentive.dealerFinancingRequired,
      combineOffers: incentive.combineOffers,
    });
    this.incentiveForm.get("financeOffer").patchValue({
      customerCash: incentive.cashDetails.customerCash,
    });
    this.incentiveForm.get("cashDetails").patchValue({
      militaryRebate: incentive.cashDetails.militaryRebate,
      collegeRebate: incentive.cashDetails.collegeRebate,
      bonusCash: incentive.cashDetails.bonusCash,
      leaseCash: incentive.cashDetails.leaseCash,
      financeCash: incentive.cashDetails.financeCash,
      otherRebate: incentive.cashDetails.otherRebate
    });
    this.waitOnAction = false;
  }

  private setVehicleQualifierList(list: VehicleQualifier[]) {
    if (!list || !list.length) {
      return;
    }
    const formArray = this.incentiveForm.get("vehicleQualifierList") as FormArray;
    formArray.clear();
    list.forEach(vehicle => this.addVehicleFormGroup(formArray, vehicle));
  }

  private setFinanceRates(rates: FinanceRate[]) {
    if (!rates || !rates.length) {
      return;
    }
    const formArray = this.incentiveForm.get("financeOffer").get("financeRates") as FormArray;
    formArray.clear();
    rates.forEach(rate => {
      const formGroup = this.formBuilder.group({
        minYear: [rate.minYear],
        term: [rate.term],
        tieredRates: new FormArray([])
      });
      const tieredRates = formGroup.get("tieredRates") as FormArray;
      rate.tieredRates.forEach(tieredRate => {
        this.addControl(tieredRates, tieredRate, tieredRate === null);
      });
      formArray.push(formGroup);
    });
  }

  private setLeaseMFs(leaseOfferTerms: LeaseOfferTerm[]) {
    if (!leaseOfferTerms) {
      return;
    }
    const leaseOfferTermsFormArray = this.incentiveForm.get("leaseOffer")
      .get("leaseOfferTerms") as FormArray;
    leaseOfferTermsFormArray.clear();
    leaseOfferTerms.forEach((leaseOfferTerm: LeaseOfferTerm) => {
      const formGroup = this.formBuilder.group({
        term: [leaseOfferTerm.term],
        tieredLeaseMFs: new FormArray([]),
        leaseSubvention: [leaseOfferTerm.leaseSubvention],
        cashAtSigning: [leaseOfferTerm.cashAtSigning],
        rcf: [leaseOfferTerm.rcf]
      });

      // create tiered Lease Money Factors
      const tieredLeaseMFs = formGroup.get("tieredLeaseMFs") as FormArray;
      leaseOfferTerm.tieredLeaseMFs.forEach((tieredLeaseMf: number, i) => {
        tieredLeaseMFs.push(new FormControl(tieredLeaseMf));
      });

      leaseOfferTermsFormArray.push(formGroup);
    });
  }

  private setAprSubventionTiers(aprSubventionTiers: number[]) {
    if (!aprSubventionTiers) {
      return;
    }
    const aprSubventionTiersFormArray = this.incentiveForm.get("financeOffer")
      .get("aprSubventionTiers") as FormArray;
    aprSubventionTiersFormArray.clear();
    aprSubventionTiers.forEach((aprSubventionTier: number, i) => {
      const ctrl = new FormControl(aprSubventionTier);
      ctrl.disable();
      aprSubventionTiersFormArray.push(ctrl);
    });
  }

  // ACTIONS

  onRemoveIncentive() {
    this.removeIncentive.emit();
  }

  onUpdateIncentive() {
    // tslint:disable-next-line
    const customerCash = this.incentiveForm.get("financeOffer")[ "controls" ][ "customerCash" ].value;
    const incentive: Incentive = {
      title: this.incentiveForm.get("title").value,
      vehicleQualifierList: this.incentiveForm.get("vehicleQualifierList").value,
      financeOffer: this.getFinanceOffer(),
      leaseOffer: this.getLeaseOffer(),
      cashDetails: {
        customerCash,
        ...this.incentiveForm.get("cashDetails").value,
      },
      dealerFinancingRequired: this.incentiveForm.get("dealerFinancingRequired").value,
      combineOffers: this.incentiveForm.get("combineOffers").value
    };
    this.updateIncentive.emit(incentive);
  }

  onAddVehicle() {
    const formArray = this.incentiveForm.get("vehicleQualifierList") as FormArray;
    const vehicle = new DefaultVehicleQualifier();
    if (formArray.length > 0 && formArray.at(0)) {
      vehicle.year = formArray.at(0).get("year").value;
    } else {
      vehicle.year = new Date().getFullYear().toString();
    }
    this.addVehicleFormGroup(formArray, vehicle);
  }

  onRemoveVehicle(i: number) {
    const formArray = this.incentiveForm.get("vehicleQualifierList") as FormArray;
    formArray.removeAt(i);
    this.onUpdateIncentive();
  }

  onAddFinanceRate() {
    const formArray = this.incentiveForm.get("financeOffer").get("financeRates") as FormArray;
    const rate = new DefaultFinanceRate(3);
    const formGroup = this.formBuilder.group({
      minYear: [rate.minYear],
      term: [rate.term],
      tieredRates: new FormArray([])
    });
    const tieredRates = formGroup.get("tieredRates") as FormArray;
    rate.tieredRates.forEach(tieredRate => {
      this.addControl(tieredRates, 0, tieredRate === null);
    });
    formArray.push(formGroup);
  }

  onRemoveFinanceRate(index: number) {
    this.waitOnAction = true;
    const financeOffer = this.incentiveForm.get("financeOffer").value;
    financeOffer.financeRates.splice(index, 1);
    const updatedFinanceRates = financeOffer.financeRates.map(rate => {
      rate.tieredRates = rate.tieredRates.map(r => r.item);
      return rate;
    });
    this.setFinanceRates(updatedFinanceRates);
    this.waitOnAction = false;
    this.onUpdateIncentive();
  }

  onAddLeaseMF() {
    const leaseOfferTermsFormArray = this.incentiveForm.get("leaseOffer")
      .get("leaseOfferTerms") as FormArray;
    const formGroup = this.formBuilder.group({
      term: [0],
      tieredLeaseMFs: new FormArray([]),
      leaseSubvention: [0],
      cashAtSigning: [0]
    });
    leaseOfferTermsFormArray.push(formGroup);
  }

  onAddTier(offerTermIndex: number) {
    const leaseOfferTermsFormArray = this.incentiveForm.get("leaseOffer")
      .get("leaseOfferTerms") as FormArray;
    const tieredLeaseMFsFormArray = leaseOfferTermsFormArray.controls[ offerTermIndex ]
      .get("tieredLeaseMFs") as FormArray;
    tieredLeaseMFsFormArray.push(new FormControl(0));
    // this.addControl(tieredLeaseMFsFormArray, 0);
  }

  onAddAprSubventionTier() {
    const aprSubventionTiersFormArray = this.incentiveForm.get("financeOffer")
      .get("aprSubventionTiers") as FormArray;
    aprSubventionTiersFormArray.push(new FormControl(0));
  }

  onRemoveAprSubvention() {
    this.setAprSubventionTiers([]);
    this.onUpdateIncentive();
  }

  onRemoveLeaseMF(index: number) {
    this.waitOnAction = true;
    const leaseOffer = this.incentiveForm.get("leaseOffer").value;
    const updatedLeaseRates = leaseOffer.leaseOfferTerms
      .filter((_, i) => i !== index);
    this.setLeaseMFs(updatedLeaseRates);
    this.waitOnAction = false;
    this.onUpdateIncentive();
  }

  onToggleShowContents() {
    this.showContents = this.showContents ? false : true;
  }

  // FORM HELPERS

  addControl(formArray: FormArray, itemValue: any, disabled: boolean) {
    const formGroup = this.formBuilder.group({item: [itemValue]});
    if (disabled) {
      formGroup.disable();
    }
    formArray.push(formGroup);
  }

  addVehicleFormGroup(formArray: FormArray, vehicle) {
    const formGroup = this.formBuilder.group({
      year: [vehicle.year],
      make: [vehicle.make ? vehicle.make : "Toyota"],
      model: [vehicle.model],
      trim: [vehicle.trim],
      modelNumber: [vehicle.modelNumber]
    });
    formArray.push(formGroup);
  }

  getFinanceOffer(): FinanceOffer {
    const financeOffer = this.incentiveForm.get("financeOffer").value;
    delete financeOffer.customerCash;
    financeOffer.financeRates = (financeOffer.financeRates || []).map(rate => {
      rate.tieredRates = (rate.tieredRates || []).map(tieredRate => {
        if (!isNil(tieredRate.item)) {
          return tieredRate.item;
        } else {
          return tieredRate;
        }
      });
      return rate;
    });
    return financeOffer;
  }

  getLeaseOffer(): LeaseOffer {
    return this.incentiveForm.get("leaseOffer").value as LeaseOffer;
  }

  get LeaseOfferTermsControls() {
    const leaseOfferTerms = this.incentiveForm.get("leaseOffer")
      .get("leaseOfferTerms") as FormArray;
    return leaseOfferTerms.controls;
  }

  get AprSubventionControls() {
    const aprSubventionControls = this.incentiveForm.get("financeOffer")
      .get("aprSubventionTiers") as FormArray;
    return aprSubventionControls.controls;
  }

  getMFControls(index) {
    const leaseOfferTermsFormArray = this.incentiveForm
      .get("leaseOffer").get("leaseOfferTerms") as FormArray;
    const result = leaseOfferTermsFormArray.controls[ index ].get("tieredLeaseMFs") as FormArray;
    return result.controls;
  }

}

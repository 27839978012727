import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Accessory, FinanceOptions, TaxResult, Vehicle } from "src/app/clearpath-module/models";
import { FORM_INPUT_REQS, PLATE_TRANSFER_FEE } from "src/app/app.config";
import { DealState, DealStatus } from "src/app/clearpath-module/store/state";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { CalculationService, DealService, VehicleService } from "src/app/clearpath-module/services";
import { pathOr } from "ramda";
import { DealIncentive } from "src/app/settings-module/models/incentives";
import * as dealActions from "../../../../clearpath-module/store/actions/deal.actions";
import { take, takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { FeesResult } from "src/app/clearpath-module/models/calculations";
import { Actions, ofType } from "@ngrx/effects";
import { DealerAccessory } from "src/app/clearpath-module/models/vehicle";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../../clearpath-module/store/state";

@Component({
  selector: "app-vehicle-price-form",
  templateUrl: "./vehicle-price-form.component.html",
  styleUrls: ["./vehicle-price-form.component.scss"]
})
export class VehiclePriceFormComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject();
  @Input() customerZip: number;
  @Input() selectedCashIncentivesTotal: number;

  @Input() set accessories(list: Accessory[]) { this.syncAccessoriesData(list); }

  @Input() set dealStatus(status: string) { this.syncFormStatus(status); }

  @Input() deal: DealState;
  @Input() dealDefaults: DealDefaults;
  @Input() verified: boolean;
  @Input() incentives: DealIncentive[];
  @Output() submitAccessories = new EventEmitter<Accessory[]>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();
  vehicle: Vehicle;
  accessoriesList: Accessory[];
  verifiedAccessories: number[] = [];
  verifiedRemovedAccessories: number[] = [];
  @Input() baseVehiclePrice: number;
  @Input() customizedVehiclePrice: number;
  selectedDealerAccessories: string[] = [];

  financeOptions: FinanceOptions;
  dealerAccessories: DealerAccessory[] = [];
  financeOptionsEdits: FinanceOptions;
  calcFees: FeesResult;
  taxResult: TaxResult;
  PLATE_TRANSFER_FEE;

  public REQS = FORM_INPUT_REQS;
  private uiState = {
    rebates: 0, // Need Functionality Specs
    accessoriesTotal: 0
  };

  vehiclePriceForm: FormGroup = this.formBuilder.group({
    financeCash: [false],   // Need Functionality Specs
    military: [false],      // Need Functionality Specs
    accessoriesList: this.formBuilder.array([]),
    missingAccessoriesList: this.formBuilder.array([])
  });

  financeOptionsForm: FormGroup = this.formBuilder.group({
    msr: [0, Validators.min(0)],
    discount: [0, Validators.min(0)],
    retail: [0, Validators.min(0)],
    docFees: [0, Validators.min(0)],
    eFilingFee: [null, Validators.min(0)],
    regFee: [0, [Validators.min(0)]],
    plateTransfer: [false],
    countyFee: [0, [Validators.min(0)]],
    titleFee: [0, [Validators.min(0)]],
    totalStateFees: [0, [Validators.min(0)]],
    salesTax: [0, [Validators.min(0)]],
    privilegeTax: [0, [Validators.min(0)]],
    catTax: [0, [Validators.min(0)]],
    totalStateTaxes: [0, [Validators.min(0)]],
    invoice: [0, [Validators.min(0)]]
  });

  constructor(
    private store: Store<fromRoot.DealState>,
    private formBuilder: FormBuilder,
    private calculationService: CalculationService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private actions$: Actions
  ) { }

  // INITIALIZATION

  ngOnInit() {
    this.PLATE_TRANSFER_FEE = PLATE_TRANSFER_FEE;

    combineLatest([
      this.dealService.selectDeal(),
      this.dealService.selectFinanceOptions(),
      this.dealService.selectFinanceOptionsEdits(),
      this.vehicleService.selectVehicle(),
      this.dealService.selectSelectedDealerAccessories()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, financeOptions, financeOptionsEdits, vehicle, selectedDealerAccessories]: [DealState, FinanceOptions, FinanceOptions, Vehicle, string[]]) => {
        this.deal = deal;
        this.financeOptions = financeOptions;
        this.financeOptionsEdits = financeOptionsEdits;
        this.vehicle = vehicle;
        this.selectedDealerAccessories = selectedDealerAccessories;
        this.dealerAccessories = this.vehicleService.parsePBSCustomFields(vehicle);
        const vehicleMsr = financeOptionsEdits.msr === null ? vehicle.msr : financeOptionsEdits.msr;
        const vehicleRetail = financeOptionsEdits.retail === null ?
          vehicle.retail :
          financeOptionsEdits.retail;
        let discount = vehicleMsr - vehicleRetail;
        discount = financeOptionsEdits.discount === null ? discount : financeOptionsEdits.discount;
        let regFee = financeOptionsEdits.regFee === null ? financeOptions.regFee : financeOptionsEdits.regFee;
        let salesTax = financeOptionsEdits.salesTax === null ? financeOptions.salesTax : financeOptionsEdits.salesTax;
        //console.log("Finance Options Form Before:", this.financeOptionsForm.value);
        this.financeOptionsForm.patchValue({
          invoice: vehicle.order.price,
          msr: vehicleMsr,
          plateTransfer: this.financeOptions.plateTransfer,
          discount: discount > 0 ? discount : 0,
          retail: vehicleRetail,
          regFee,
          salesTax
        });
        //console.log("Finance Options Form After:", this.financeOptionsForm.value);
        this.readCalcFees();
        this.readCalcTax();
      });

    this.actions$.pipe(
      ofType(dealActions.setCustomerStateCounty),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.readCalcFees();
      this.dealService.dispatchCalcTax();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  readCalcFees() {
    this.calculationService.calcFees$()
      .pipe(take(1))
      .subscribe((fees: FeesResult) => {
        if (fees) {
          this.calcFees = fees;
          this.financeOptionsForm.patchValue({
            docFees: fees.docFees || 0,
            eFilingFee: fees.eFilingFee || 0,
            regFee: fees.regFee || 0,
            countyFee: fees.countyFee || 0,
            titleFee: fees.titleFee || 0,
            totalStateFees: fees.totalStateFees || 0
          });
        }
      });
  }

  readCalcTax() {
    this.calculationService.calcTax$({
      leaseSelected: this.deal.leaseOptions.leaseSelected,
      finance: this.deal.financeOptions.financeSelected
    })
      .pipe(take(1))
      .subscribe((taxResult: TaxResult) => {
        this.taxResult = taxResult;
        if (taxResult) {
          this.financeOptionsForm.patchValue({
            catTax: taxResult.catTax,
            privilegeTax: taxResult.privilegeTax,
            salesTax: taxResult.salesTax,
            totalStateTaxes: taxResult.totalStateTaxes,
          });
          // check to see if the form's tax values are out of sync. Ensure that there are values to sync.  If so, sync them.
          /*if (this.financeOptionsEdits &&
            ( (taxResult.catTax > 0) ||
              (taxResult.privilegeTax > 0) ||
              (taxResult.salesTax > 0) ) &&
            ( (this.financeOptionsEdits.catTax != taxResult.catTax) ||
            (this.financeOptionsEdits.privilegeTax != taxResult.privilegeTax) ||
            (this.financeOptionsEdits.salesTax != taxResult.salesTax))
          ) {
            this.financeOptionsEdits.catTax =  taxResult.catTax;
            this.financeOptionsEdits.privilegeTax = taxResult.privilegeTax;
            this.financeOptionsEdits.salesTax = taxResult.salesTax;
            this.dealService.dispatchSetFinanceOptionsEdits({
              catTax: taxResult.catTax,
              privilegeTax:  taxResult.privilegeTax,
              salesTax: taxResult.salesTax
            });
          }*/
        }
      });
  }

  private syncFormStatus(status: string) {
    const completedDeal = status === DealStatus.Completed;
    if (completedDeal) { this.vehiclePriceForm.disable(); }
  }

  private syncAccessoriesData(accessories: Accessory[]) {
    accessories = accessories || [];
    const accessoriesFormArray = this.vehiclePriceForm.get(["accessoriesList"]) as FormArray;
    accessoriesFormArray.clear();

    for (const item of accessories) {
      const formGroup = this.formBuilder.group({
        name: [item.name, Validators.required],
        price: [item.price, [Validators.required]],
        disabled: [item.disabled]
      });
      accessoriesFormArray.push(formGroup);
    }

    const {accessoriesList} = this.vehiclePriceForm.value;
    this.accessoriesList = accessoriesList;
    this.uiState.accessoriesTotal = this.calculationService.calcAccessoriesTotal(accessoriesList);
  }

  onTogglePlateTransfer() {
    const plateTransfer = !this.financeOptions.plateTransfer;
    this.dealService.dispatchSetPlateTransfer(plateTransfer);
    this.flagChange();
  }

  // UI RENDERING

  get dealerAccessoriesTotal() {
    let total = 0;
    this.dealerAccessories.forEach((acc: DealerAccessory) => {
      if (this.deal?.selectedDealerAccessories?.includes(acc.name)) {
        total += acc.price;
      }
    });
    return total;
  }

  get accessoriesTotal(): number {
    return this.uiState.accessoriesTotal;
  }

  onSelectMiscRebateType(type: string) {
    alert("QA Note: In development. Not functional.");
    const {financeCash, military} = this.vehiclePriceForm.value;

    this.vehiclePriceForm.patchValue({
      financeCash: type === "financeCash" ? !financeCash : false,
      military: type === "military" ? !military : false
    });

    this.flagChange();
  }

  autoSubmitFinanceOptionsForm = (editedFieldTriggered: string) => () => {
    const {invalid} = this.financeOptionsForm;
    if (invalid) { return; }

    const financeOptions: Partial<FinanceOptions> = this.financeOptionsForm.value;
    Object.keys(financeOptions)
      .forEach(prop => {
        let val = pathOr(0, [prop], financeOptions);
        if (val === "") {
          val = 0;
        }
        if (typeof val === "boolean") {
          financeOptions[ prop ] = val;
        } else {
          financeOptions[ prop ] = parseFloat(val);
        }
      });

    let {invoice} = this.financeOptionsForm.value;
    invoice = parseFloat(invoice);
    this.vehicle.order.price = invoice;
    this.vehicle.inventory = invoice;
    this.vehicleService.dispatchUpdateVehicle(this.vehicle);
    this.dealService.dispatchSetFinanceOptionsEdits({
      [ editedFieldTriggered ]: financeOptions[ editedFieldTriggered ]
    });
    // financeOptions.plateTransfer = !!financeOptions.plateTransfer;
    this.dealService.dispatchSetFinanceOptions(financeOptions);
    this.dealService.dispatchCalcTax();
    this.dealService.dispatchCalcFees();
    this.financeOptionsForm.markAsUntouched();
    this.financeOptionsForm.markAsPristine();
    this.flagChange();
  }

  autoSubmitAccessories = () => {
    // console.log("New List:",this.vehiclePriceForm);
    const {pristine, invalid} = this.vehiclePriceForm;
    if (pristine || invalid) { return; }

    const accessoriesList: Accessory[] = this.vehiclePriceForm.value.accessoriesList;
    // console.log("New List:",this.vehiclePriceForm.value.accessoriesList);
    this.submitAccessories.emit(accessoriesList);
    this.vehiclePriceForm.markAsUntouched();
    this.vehiclePriceForm.markAsPristine();
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  }

  hasDisplayedIncentives(incentives: any[]): boolean {
    let count = 0;
    if (Array.isArray(incentives)) { incentives.forEach(incentive => incentive.value > 0 ? count++ : null);}
    return count > 0;
  }

  updateDealerAccessorySelected(dealerAcc: DealerAccessory, dealerAccs: DealerAccessory[]) {
    //console.log("selectedDealerAccessories", this.deal.selectedDealerAccessories, this.deal);
    if (this.deal && Array.isArray(this.deal.selectedDealerAccessories)) {
      if (!this.deal.selectedDealerAccessories.includes(dealerAcc.name)) {
        //console.log("Selecting Dealer accessory:", dealerAcc.name);
        this.deal.selectedDealerAccessories.push(dealerAcc.name);
      } else {
        //console.log("Unselecting Dealer accessory:", dealerAcc.name);
        this.deal.selectedDealerAccessories = this.deal.selectedDealerAccessories.filter(acc => acc !== dealerAcc.name);
      }
    } else {
      this.deal.selectedDealerAccessories = [];
      this.deal.selectedDealerAccessories.push(dealerAcc.name);
    }
    //console.log("Updating updateSelectedDealerAccessories");
    this.store.dispatch(dealActions.updateSelectedDealerAccessories(this.deal.selectedDealerAccessories));
    this.dealService.dispatchCalcTax();
    this.flagUnsavedChanges.emit(true);
  }

  isDealerAccessorySelected(dealerAcc) {
    if (this.deal && Array.isArray(this.deal.selectedDealerAccessories)) {
      return this.deal.selectedDealerAccessories.includes(dealerAcc.name);
    }
    return false;
  }
}

<div [formGroup]="parentForm">
  <section formArrayName="accessoriesList">
    <label class="desking-column-sub-label underlined">Optional Accessories</label>

    <div class="vehicle-price-form-column-layout">
      <div
        *ngIf="accessoriesListControls.length === 0"
        class="app-text-neutral py-1">
        No accessories selected.
      </div>

      <div
        *ngFor="let item of accessoriesListControls; let i = index"
        class="app-inline-form-layout data-row"
        style="border-top:none"
        [formGroupName]="i">
        <input
          type="checkbox"
          class="form-check"
          [checked]="isSelected(item.value)"
          (click)="toggleSelectAccessory(item.value)"/>

        <div class="optional-label">{{ item.value.name }}</div>
        <div
          *ngIf="!editing"
          class="app-text-blue"
          (click)="showAccessoryForm(i)">
          {{ item.value.price | currency }}
        </div>

        <input
          *ngIf="shownAccessoriesForms.includes(i) && editing"
          class="input-underline"
          formControlName="price"
          type="number"
          (blur)="onBlur($event,item.value,i)"
          (change)="flagChange()"
          (keydown)="flagChange()"
          (keyup)="onKeyUp($event)"
          required/>

        <ng-container *ngIf="verified$ | async as verified">
          <input
            class="text-right no-border"
            [ngClass]="{
              'app-border-danger': touchedInvalidAccessory(i, 'price'),
              'app-bg-danger': !isDefaultAccessory(item.value) && !verified}"
            formControlName="price"
            type="number"
            (blur)="onBlur($event,item.value,i)"
            (change)="flagChange()"
            (keydown)="flagChange()"
            (keyup)="onKeyUp($event)"
            required/>
        </ng-container>

        <!-- <input
          type="checkbox"
          [checked]="verified$ | async"
          (click)="verifyAccessory(i)"
        /> -->
      </div>

      <div *ngIf="!newAccessoryFormShowing" style="display: flex;justify-content: flex-start;padding:2px 0;">
        <button
          class="btn app-btn-success rounded-pill px-2 py-0"
          style="padding: 1px 30px;font-size: 0.8rem;"
          (click)="toggleShowAccessoryForm()">
          Add Accessory
        </button>
      </div>

      <form
        [formGroup]="newAccessoryForm"
        *ngIf="newAccessoryFormShowing"
        class="new-accessory">

        <div style="font-size:14px;font-weight:700;pointer-events: none;padding: 3px 0;">
          Add Accessory:
        </div>

        <input
          formControlName="name"
          type="text"
          [maxlength]="REQS.maxLength.standard"
          placeholder="Accessory Name"
          (keydown)="onKeyDownNew($event)"
          required/>

        <input
          formControlName="price"
          type="number"
          placeholder="Accessory Price"
          (keydown)="onKeyDownNew($event)"
          required/>

        <div class="button-bar">

          <button
            class="btn app-btn-neutral rounded-pill px-2 py-0"
            (click)="toggleShowAccessoryForm()">
            cancel
          </button>

          <button
            type="submit"
            class="btn app-btn-success rounded-pill px-2 py-0"
            (click)="autoSubmitNewAccessory()">
            submit
          </button>
        </div>

      </form>
    </div>
  </section>
</div>

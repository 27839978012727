<form [formGroup]="accessoryForm">
  <div class="form-group">
    <label>Name</label>
    <input formControlName="name"
           class="form-control"
           type="text"
           [maxlength]="REQS.maxLength.standard"
           value="{{accessory.name}}">
  </div>
  <div class="form-group">
    <label>PBS Name</label>
    <input formControlName="pbsName"
           class="form-control"
           type="text"
           [maxlength]="REQS.maxLength.standard"
           value="{{accessory.pbsName}}">
  </div>
  <div class="form-group">
    <label>PBS Type</label>
    <input formControlName="pbsType"
           class="form-control"
           type="text"
           [maxlength]="REQS.maxLength.standard"
           disabled
           value="'Protection'">
  </div>
  <div class="form-group">
    <label>Tag Line</label>
    <input formControlName="tagLine"
           class="form-control"
           type="text"
           [maxlength]="REQS.maxLength.standard"
           value="{{accessory.tagLine}}">
  </div>
  <div class="form-group">
    <label>Price</label>
    <input formControlName="price"
           class="form-control"
           type="number"
           value="{{accessory.price}}">
  </div>
  <div>
    <div class="d-flex flex-row align-items-end">
      <p>Details</p>
      <a (click)="createDetail()">
        <i class="material-icons text-success h2">add</i>
      </a>
    </div>
    <ul formArrayName="details"
        class="unstyled">
      <li *ngFor="let detail of details; let i = index;">
        <div class="form-group">
          <div class="d-flex flex-row">
            <input formControlName="{{i}}"
                   class="form-control"
                   type="text"
                   value="{{detail}}">
            <a (click)="removeDetail(i)">
              <i class="material-icons text-danger h2 float-right">delete_sweep</i>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</form>
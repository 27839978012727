<div class="wrapper w-100">
  <div class="d-flex justify-content-between align-items-center">
    <div class="signature-title">{{ signatureTitle }}</div>
    <a class="btn btn-link clear"
       (click)="clear()">CLEAR</a>
  </div>
  <canvas #sigPadElem
          class="signature-pad"
          [width]="width"
          height=120></canvas>
</div>

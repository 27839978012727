<article class="card buy-box">
  <div>
    <div class="card-header app-text-white app-bg-dark">
      {{ financingTerm }} Month Financing
    </div>

    <div class="buy-box-detail app-text-small">
      <div class="d-flex justify-content-between">
        <span>Base Payment</span>
        <span>{{ baseMonthlyPayment$() | async | currency }}</span>
      </div>
    </div>

    <div *ngFor="let product of insuranceProducts; let i = index;"
         class="buy-box-detail font-weight-bold app-text-small d-flex align-items-center justify-content-between">
      <span class="d-flex align-items-center mr-2">
        <div style="display:flex;flex-direction:column;gap:5px;">
          <i *ngIf="product.productKey==='GAP' && productSelected(product.productKey) && totalEquityPercent>.2"
             class="material-icons mr-2"
             style="color:#c98d0b;cursor: pointer"
             (click)="gapQuestionClicked(product)">emergency</i>
          <i *ngIf="productSelected(product.productKey)"
             (click)="disableProduct(product)"
             class="material-icons app-text-secondary mr-2">done_outline</i>
          <i *ngIf="!productSelected(product.productKey)"
             (click)="enableProduct(product)"
             class="material-icons app-text-danger mr-2">close</i>
        </div>
        {{ product.name }}
        <br>
        {{productDescription(product)}}
      </span>
      <span>{{ calculateInsuranceProductMonthlyPayment$(product, deal) | async | currency }}</span>
    </div>
  </div>

  <div class="buy-box-detail app-text-small">

    <div style="font-weight:bold">Notes:</div>

    <div class="d-flex justify-content-between">
      <span>Interest Rate</span>
      <span>{{ interestRate | percent:'1.1-2' }}</span>
    </div>

    <span class="d-flex align-items-center justify-content-between">
      <span class="mr-2">Guaranteed Future Value</span>
      <span style="display: flex;align-items: center">
        <i class="material-icons app-text-small mr-1 app-text-bold" style="margin-bottom:-2px">close</i>
        None
      </span>
    </span>

  </div>

  <div class="buy-box-footer">
    <div class="h3 mb-0">{{ totalMonthlyPayment$ | async | currency }}</div>
    <div class="mb-3">per month</div>
    <button class="btn btn-block d-flex align-items-center justify-content-center"
            [ngClass]="paymentPlanSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
            type="button"
            (click)="onSelectPaymentPlan()">
      <i *ngIf="paymentPlanSelected()"
         class="material-icons mr-2">done_outline</i>
      Select
    </button>
  </div>
</article>

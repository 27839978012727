import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppService, DealService } from "../../services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CreditTier, FinanceOptions } from "../../models";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-credit-tier-input",
  templateUrl: "./credit-tier-input.component.html",
  styleUrls: ["./credit-tier-input.component.scss"]
})
export class CreditTierInputComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  creditTiers: CreditTier[] = [];
  previousCreditTier: any;
  creditTiersForm: FormGroup = this.formBuilder.group({
    creditTier: [null]
  });

  constructor(
    private dealService: DealService,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    this.subToCreditTiers();
    this.subToSelectedCreditTier();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  subToCreditTiers() {
    this.appService.selectCreditTiers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((creditTiers: CreditTier[]) => {
        this.creditTiers = creditTiers || [];
      });
  }

  subToSelectedCreditTier() {
    this.dealService.selectFinanceOptions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((financeOptions: FinanceOptions) => {
        this.creditTiersForm.patchValue({creditTier: financeOptions.selectedCreditTier});
        this.previousCreditTier = financeOptions.selectedCreditTier
      });
  }

  selectLabel(key: any): string {
    if (!this.creditTiers || !this.creditTiers.length) return '';
    for (let i = 0; i < this.creditTiers.length; i++) {
      if (this.creditTiers[ i ].tier == key) return this.creditTiers[ i ].name;
    }
    return '';
  }

  // ACTIONS

  onSelectCreditTier() {
    const currentCreditTier = this.creditTiersForm.value;
    this.historyService.dispatchAddEvent({
      shortName: "Credit Tier Changed",
      oldValue: this.selectLabel(this.previousCreditTier),
      newValue: this.selectLabel(currentCreditTier.creditTier)
    });
    const {creditTier} = this.creditTiersForm.value;
    this.dealService.submitCreditTier(+creditTier);
  }

}

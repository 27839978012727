import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../../../auth-module/services/auth.service";
import { SalesQueueService } from "../../services/sales-queue.service";
import { AlertService } from "../../../shared-module/services";
import { User } from "src/app/user-admin-module/models";
import { PubnubService } from "../../../shared-module/services/PubSub/pubnub.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SalesCue } from "../../models/sales-queue";

@Component({
  selector: "app-sales-queue-view",
  templateUrl: "./sales-queue-view.component.html",
  styleUrls: ["./sales-queue-view.component.scss"]
})
export class SalesQueueViewComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  uiState = {
    busy: {
      action: false,
      data: false
    },
    inSalesQueue: false,
    ignoreNextUpdate: false
  };

  currentUser: User;
  salesCue: SalesCue;

  constructor(
    private authService: AuthService,
    private salesQueueService: SalesQueueService,
    private alertService: AlertService,
    private pubsub: PubnubService
  ) { }

  ngOnInit() {
    this.subToCurrentUser();
    this.watchSalesQueueRotation();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  subToCurrentUser() {
    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.currentUser = user;
      });
  }

  watchSalesQueueRotation() {
    this.getSalesQueue();
    this.pubsub.salesQueueChanged(() => this.getSalesQueue());
  }

  getSalesQueue() {
    if (this.uiState.ignoreNextUpdate) {
      this.uiState.ignoreNextUpdate = false;
      return;
    }

    this.uiState.busy.data = true;
    this.salesQueueService.read().subscribe({
      next: data => {
        this.salesCue = data;
        this.checkInQueue();
        this.uiState.busy.data = false;
      },
      error: error => {
        this.alertService.error(error);
        this.uiState.busy.data = false;
      }
    });
  }

  checkInQueue() {
    for (const member of this.salesCue.salesQueue) {
      const userMatch = member.userId === this.currentUser.id;
      if (userMatch) {
        this.uiState.inSalesQueue = member.inQueue;
        break;
      }
    }
  }

  // ACTIONS

  onJoinSalesQueue() {
    this.uiState.busy.action = true;
    this.salesQueueService.add(this.currentUser.id).subscribe({
      next: data => {
        this.uiState.ignoreNextUpdate = true;
        this.salesCue = data;
        this.checkInQueue();
        this.uiState.busy.action = false;
      },
      error: error => {
        this.alertService.error(error);
        this.uiState.busy.action = false;
      }
    });
  }

  onLeaveSalesQueue() {
    this.uiState.busy.action = true;
    this.salesQueueService.remove(this.currentUser.id).subscribe({
      next: data => {
        this.uiState.ignoreNextUpdate = true;
        this.salesCue = data;
        this.checkInQueue();
        this.uiState.busy.action = false;
      },
      error: error => {
        this.alertService.error(error);
        this.uiState.busy.action = false;
      }
    });
  }

  // UI CONTROL & RENDERING

  inSalesQueue(): boolean {
    return this.uiState.inSalesQueue;
  }

  waitOnAction(): boolean {
    return this.uiState.busy.action;
  }

  waitOnData(): boolean {
    return this.uiState.busy.data;
  }
}

import { Directive, ElementRef, Renderer2 } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Directive({
  selector: "[appDealerIcon]"
})
export class DealerIconDirective {
  private dealerIconPath = "/static/images/bt-logo.png";

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    renderer.setAttribute(
      el.nativeElement,
      "src",
      `${environment.apiUrl}${this.dealerIconPath}`
    );

    renderer.setAttribute(
      el.nativeElement,
      "alt",
      `${environment.dealershipTitle}`
    );
  }

}

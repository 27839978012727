import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LeaseDefault } from "src/app/settings-module/models";

@Component({
  selector: "app-lease-default-form",
  templateUrl: "./lease-default-form.component.html",
  styleUrls: ["./lease-default-form.component.scss"]
})
export class LeaseDefaultFormComponent implements OnInit {
  @Input() leaseDefault: LeaseDefault;
  @Input() waitOnAction = false;
  @Output() submitLeaseDefault = new EventEmitter<LeaseDefault>();
  @Output() unsavedChanges = new EventEmitter();

  leaseDefaultForm: FormGroup = this.formBuilder.group({
    newTerms: new FormArray([]),
    certUsedTerms: new FormArray([]),
    newTermsShown: new FormArray([]),
    certUsedTermsShown: new FormArray([]),
    newMarkup: [0, [Validators.required, Validators.min(0)]],
    certUsedMarkup: [0, [Validators.required, Validators.min(0)]],
    acquisitionFee: [0, [Validators.required, Validators.min(0)]],
    onePayDiscount: [0, [Validators.required, Validators.min(0)]],
    onePayDiscFloor: [0, [Validators.required, Validators.min(0)]],
    // prepaidMileage: [0, [Validators.required, Validators.min(0)]],
    moneyFactor: [0, [Validators.required, Validators.min(0)]],
    taxRate: [0, [Validators.required, Validators.min(0)]],
    // excessMileageRate: [0, [Validators.required, Validators.min(0)]]
  });

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeLeaseDefaultForm();
    this.subToFormChanges();
  }

  // INITIALIZATION

  private initializeLeaseDefaultForm() {
    this.leaseDefaultForm.patchValue({
      newMarkup: this.leaseDefault.newMarkup,
      certUsedMarkup: this.leaseDefault.certUsedMarkup,
      moneyFactor: this.leaseDefault.moneyFactor,
      acquisitionFee: this.leaseDefault.acquisitionFee,
      newTermsShown: this.leaseDefault.newTermsShown || [],
      certUsedTermsshown: this.leaseDefault.certUsedTermsShown || [],
      // prepaidMileage: this.leaseDefault.prepaidMileage,
      onePayDiscount: this.leaseDefault.onePayDiscount,
      onePayDiscFloor: this.leaseDefault.onePayDiscFloor,
      taxRate: this.leaseDefault.taxRate,
      // excessMileageRate: this.leaseDefault.excessMileageRate
    });

    const initialNewTerms = this.leaseDefault.newTerms || [];
    const initialCertUsedTerms = this.leaseDefault.certUsedTerms || [];

    const initialNewTermsShown = this.leaseDefault.newTermsShown || [];
    const initialCertUsedTermsShown = this.leaseDefault.certUsedTermsShown || [];
    initialNewTerms.forEach(term => {
      this.addTermControl("newTerms", term);
    });

    initialCertUsedTerms.forEach(term => {
      this.addTermControl("certUsedTerms", term);
    });

    initialNewTermsShown.forEach(term => {
      this.addTermControl("newTermsShown", term);
    });

    initialCertUsedTermsShown.forEach(term => {
      this.addTermControl("certUsedTermsShown", term);
    });
  }

  private subToFormChanges() {
    this.leaseDefaultForm.valueChanges.subscribe(() => {
      this.unsavedChanges.emit();
    });
  }

  // ACTIONS

  onSubmitLeaseDefaultForm() {
    const leaseDefault: LeaseDefault = this.leaseDefaultForm.value;
    this.submitLeaseDefault.emit(leaseDefault);
    this.leaseDefaultForm.markAsPristine();
  }

  // UI CONTROL & RENDERING

  onAddTerm(termType: string) {
    this.addTermControl(termType);
    this.leaseDefaultForm.markAsDirty();
  }

  onAddTermShown(termType: string) {
    this.addTermShownControl(termType);
    this.leaseDefaultForm.markAsDirty();
  }

  onRemoveTerm(termType: string, termIndex: number) {
    const formArray = this.leaseDefaultForm.get(termType) as FormArray;
    formArray.removeAt(termIndex);
    this.leaseDefaultForm.markAsDirty();
  }

  get newTerms(): AbstractControl[] {
    const formArray = this.leaseDefaultForm.get("newTerms") as FormArray;
    return formArray.controls;
  }

  get certUsedTerms(): AbstractControl[] {
    const formArray = this.leaseDefaultForm.get("certUsedTerms") as FormArray;
    return formArray.controls;
  }

  get newTermsShown(): AbstractControl[] {
    const formArray = this.leaseDefaultForm.get("newTermsShown") as FormArray;
    return formArray.controls;
  }

  get certUsedTermsShown(): AbstractControl[] {
    const formArray = this.leaseDefaultForm.get("certUsedTermsShown") as FormArray;
    return formArray.controls;
  }

  valid(controlName: string): boolean {
    const control = this.leaseDefaultForm.get(controlName);
    return control.valid;
  }

  value(controlName: string): number {
    return this.leaseDefaultForm.value[ controlName ];
  }

  // FORM VALIDATION AND HELPERS

  private addTermControl(termType: string, term?: number) {
    const formArray = this.leaseDefaultForm.get(termType) as FormArray;
    const control = new FormControl(term || null, [Validators.required, Validators.min(1)]);
    formArray.push(control);
  }

  private addTermShownControl(termType: string, term?: number) {
    const formArray = this.leaseDefaultForm.get(termType) as FormArray;
    const control = new FormControl(term || null, [Validators.required, Validators.min(1)]);
    formArray.push(control);
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.leaseDefaultForm.get(controlName);
    return control.touched && control.invalid;
  }

  touchedInvalidTermShown(termType: string, termIndex: number): boolean {
    const control = this.leaseDefaultForm.get([termType, termIndex]);
    return control.touched && control.invalid;
  }

  touchedInvalidAvailableTerm(termType: string, termIndex: number): boolean {
    const control = this.leaseDefaultForm.get([termType, termIndex]);
    return control.touched && control.invalid;
  }

}

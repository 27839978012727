<section
  class="cp-accordion-header"
  [ngClass]="{ 'cp-accordion-header--open': showDetails }"
  (click)="onToggleDetails()">
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <i class="material-icons app-bg-light rounded mr-1">{{ showDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
      <h2 class="h5 mb-0">{{title}}</h2>
    </div>
    <span class="h5 mb-0">{{ tradeEquity | currency:'USD':'symbol':'1.0-0' }}</span>
  </div>
</section>

<form *ngIf="showDetails"
      [formGroup]="tradeInForm"
      class="cp-accordion-content p-4">

  <div class="row">
    <div class="form-group col-lg-3">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('year') }">Year</label>
      <input class="form-control"
             formControlName="year"
             type="number"
             inputmode="numeric"
             min="1000"
             max="9999"
             (blur)="autoSubmitTradeInForm()">
    </div>

    <div class="form-group col-lg-4">
      <label>Make</label>
      <input class="form-control"
             formControlName="make"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="trimInput('make'); autoSubmitTradeInForm()">
    </div>

    <div class="col-lg-5 mb-4">
      <label>Model</label>
      <input class="form-control"
             formControlName="model"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="trimInput('model'); autoSubmitTradeInForm()">
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-6 col-form-label" [ngClass]="{ 'app-text-danger': touchedInvalid('tradeEstimate') }">Valuation</label>
    <div class="col-sm-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input class="form-control text-right"
               formControlName="tradeEstimate"
               inputmode="numeric"
               type="number"
               min="0"
               (blur)="autoSubmitTradeInForm()">
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-6 col-form-label" [ngClass]="{ 'app-text-danger': touchedInvalid('payOffEstimate') }">Pay Off Estimate</label>
    <div class="col-sm-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      <input class="form-control text-right"
             formControlName="payOffEstimate"
             inputmode="numeric"
             type="number"
             min="0"
             (blur)="autoSubmitTradeInForm()">
    </div>
  </div>

  <div class="row align-items-center">
    <label class="col-6 col-form-label">
      <strong>Trade Equity</strong>
    </label>
    <div class="col-6 text-right">
      {{ calcTradeEquity() | currency:'USD':'symbol':'1.0-0' }}
    </div>
  </div>

</form>

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { catchError, tap } from "rxjs/operators";

if (environment.production) {
  enableProdMode();
}
// const spy = create();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));


const debuggerOn = !environment.production;

function debug(message: string) {
  return this.pipe(
    tap(value => {
      if (debuggerOn) {
        //console.log(message, value);
      }
    }),
    catchError((error, caught) => {
      console.error("ERROR >>> ", message, error);
      return caught;
    })
  );
}

Store.prototype.debug = debug;
Observable.prototype.debug = debug;


declare module "rxjs" {
  interface Observable<T> {
    debug: (...any) => Observable<T>;
  }
}


import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { InsuranceProduct } from "src/app/clearpath-module/models";
import { pathOr } from "ramda";
import { DealService } from "src/app/clearpath-module/services";

@Component({
  selector: "app-insurance-product-selector",
  templateUrl: "./insurance-product-selector.component.html",
  styleUrls: ["./insurance-product-selector.component.scss"]
})
export class InsuranceProductSelectorComponent {
  editing = false;

  constructor(
    private formBuilder: FormBuilder,
    private dealService: DealService
  ) { }

  get checkBoxColor(): string {
    const {accept} = this.parentForm.get(this.productKey).value;
    if (accept) {
      return this.disabled ? "app-bg-neutral" : "app-bg-success";
    }
    return "";
  }

  get verificationCheckBoxColor(): string {
    if (this.disabled) {
      return "app-bg-neutral";
    }
    if (!this.unverified && this.verified) {
      return "app-bg-success";
    }
    if (this.unverified) {
      return "border-danger";
    }
    return "";
  }

  @Input() product: InsuranceProduct;
  @Input() productKey: string;
  @Input() selectedDealTerm: number;
  @Input() useClosestTerm: boolean;
  @Input() parentForm: FormGroup;
  @Input() productPrice: number;
  @Input() disabled: boolean;
  @Input() verified: boolean;
  @Input() unverified: boolean;
  @Input() submitParentForm;
  @Input() verifyInsuranceProduct;
  selectedTerm: number;
  pathOr = pathOr;

  editValue() {
    this.editing = true;
  }

  showForm() {
    let show = false;
    if (this.parentForm) {
      if (this.editing) {
        show = true;
      }
    }
    return show;
  }

  touchedInvalid(): boolean {
    if (!this.parentForm) { return; }
    const productKey = this.productKey;
    const formGroup = this.parentForm.get(productKey);
    const priceOverrideCtrl = formGroup.get("priceOverride");
    if (priceOverrideCtrl) {
      return priceOverrideCtrl.touched && priceOverrideCtrl.invalid;
    }
  }

  onToggleAccept() {
    const productKey = this.productKey;
    const formGroup = this.parentForm.get(productKey);

    formGroup.patchValue({accept: !formGroup.value.accept});
    this.parentForm.markAsDirty();
    this.submitParentForm();
  }

  onSubmitPriceOverride() {
    const productKey = this.productKey;
    const formGroup = this.parentForm.get(productKey);
    const priceOverride = formGroup.get("priceOverride").value;
    this.editing = false;
    if (priceOverride != null && priceOverride !== "") {
      // todo check if number
      formGroup.patchValue({priceOverride: +priceOverride});
      this.parentForm.markAsDirty();
      this.submitParentForm();
    }
  }

  isUnlimitedMiles(miles: number) {
    return miles >= 999999 || miles === 0;
  }

}

<app-modal-view
  #modal="modal"
  modalTitle="Roll Payment Calculator">

  <app-roll-payment-calculator
    modal-body
    (formSubmitFlag)="modal.closeModal()">
  </app-roll-payment-calculator>

</app-modal-view>

<section class="d-flex flex-column justify-content-between h-100">
  <div>
    <div #imageArea
         class="accessory-image-area mb-1"
         [ngStyle]="{ 'height': imageHeight + 'px' }"
         (window:resize)="autoSetImageHeight()">
      <img *ngIf="iconUrl"
           [src]="iconUrl"
           class="accessory-image"
           (error)="invalidateImage()">

      <i *ngIf="!iconUrl" class="material-icons app-text-neutral" style="font-size: 3rem">widgets</i>

      <div class="accessory-image-footer">
        <button class="btn btn-sm d-flex align-items-center mr-2"
                [ngClass]="inCart ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
                type="button"
                (click)="onSelectAccessory(accessory)">
          <i class="material-icons">{{ inCart ? 'done_outline' : 'add' }}</i>
        </button>
        <div class="small" style="line-height: 1.2">{{ accessory.name }}</div>
      </div>
    </div>

    <div class="d-flex justify-content-between small mb-1">
      <div class="pr-2">{{ accessory.tagLine }}</div>
      <div>{{ (_selectedDealAccessory ? _selectedDealAccessory.price : accessory.price) | currency:'USD':'symbol':'1.0-0' }}</div>
    </div>
  </div>

  <button class="more-details btn btn-sm app-btn-light btn-block"
          type="button"
          data-toggle="modal"
          [attr.data-target]="'#accessory-details-modal-' + itemIndex">
    <span class="app-text-secondary">More Details</span>
  </button>
</section>

<!-- DETAILS MODAL -->
<div [id]="'accessory-details-modal-' + itemIndex" class="modal fade">
  <div class="modal-dialog p-2 p-sm-4 m-0" style="max-width: 100vw">
    <div class="modal-content modal-body">
      <!-- BODY START -->
      <button class="close"
              style="position: absolute; top: 0.25rem; right: .5rem"
              type="button"
              data-dismiss="modal">
        <span>&times;</span>
      </button>

      <form *ngIf="accessory.name==='Paint Protection Film'" [formGroup]="xpelForm" style="position: absolute;right:35px;top:20px">
        <select class="header-form-control form-control" formControlName="coverage" (change)="onXpelCoverageChange($event)">
          <option value="" disabled>Select Coverage</option>
          <option [value]="1495">{{halfXpelLabel}}</option>
          <option [value]="2195">{{fullXpelLabel}}</option>
        </select>
      </form>

      <h1 class="h2">{{ accessory.name }}</h1>

      <app-cna-list-dialog-header [dialogName]="accessory?.name"></app-cna-list-dialog-header>

      <ng-container *ngIf="accessory.name==='Paint Protection Film'">
        <ul class="nav nav-pills mb-3">
          <li *ngFor="let tab of uiState.tabs" class="nav-item">
            <a
              [routerLink]=""
              class="nav-link"
              [ngClass]="{ active: uiState.activeView===tab.id }"
              (click)="uiState.activeView=tab.id">
              {{ tab.title }}
            </a>
          </li>
        </ul>

        <section *ngIf=" uiState.activeView==='coverage'">
          <img *ngIf="iconUrl" [src]="iconUrl" class="w-100 mb-3" alt=""/>
          <ul>
            <li *ngFor="let detail of accessory.details">{{ detail }}</li>
          </ul>
          <div class="mb-2">
            <a *ngIf="accessory.accessoryLink.linkUrl"
               [href]="accessory.accessoryLink.linkUrl"
               target="_blank">
              Learn More...
            </a>
          </div>
        </section>

        <section *ngIf=" uiState.activeView==='video'">
          <iframe style="width: 100%;aspect-ratio: 1/.56;max-height:67vh" src='https://players.brightcove.net/5776687555001/BJi3THPCM_default/index.html?videoId=5784573294001' allowfullscreen frameborder=0></iframe>
        </section>
      </ng-container>

      <ng-container *ngIf="accessory.name!=='Paint Protection Film'">
        <p>{{ accessory.tagLine }}</p>
        <img *ngIf="iconUrl" [src]="iconUrl" class="w-100 mb-3" alt=""/>
        <ul>
          <li *ngFor="let detail of accessory.details">{{ detail }}</li>
        </ul>
        <div class="mb-2">
          <a *ngIf="accessory.accessoryLink.linkUrl"
             [href]="accessory.accessoryLink.linkUrl"
             target="_blank">
            Learn More...
          </a>
        </div>
      </ng-container>
      <!-- BODY END -->
    </div>
  </div>
</div>

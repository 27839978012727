import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CalculationService, DealService, LeaseCalculationService } from "../../services";
import { FinancingSettings, InsuranceProduct } from "../../models";
import { LeaseDefault } from "src/app/settings-module/models";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { LeaseInsuranceProductKeys } from "../../models/insurance";
import { takeUntil } from "rxjs/operators";
import { DealState } from "../../store/state";

@Component({
  selector: "app-buy-box-lease",
  templateUrl: "./buy-box-lease.component.html",
  styleUrls: ["./buy-box-lease.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuyBoxLeaseComponent implements OnInit {

  constructor(
    private calculationService: CalculationService,
    private leaseCalculationService: LeaseCalculationService,
    private dealService: DealService,
    private router: Router
  ) { }

  gapQuestionClicked(product: InsuranceProduct): void {
    this.gapQuestion.emit(product)
  }

  private unsubscribe$ = new Subject();

  @Input() set insuranceProducts(list: InsuranceProduct[]) { this.initInsuranceProducts(list); }

  @Input() financeSettings: FinancingSettings;
  @Input() leaseDefault: LeaseDefault;
  @Input() leaseResiduals = [];
  @Input() leasingTerm: number;
  totalEquity: number;
  totalEquityPercent: number;
  @Output() submitDisabledProducts = new EventEmitter<InsuranceProduct[]>();
  @Output() gapQuestion = new EventEmitter<InsuranceProduct>();
  @Input() disabledProducts: InsuranceProduct[] = [];
  initialSelectedProducts: InsuranceProduct[] = [];
  deal: DealState;
  moneyFactor$: Observable<number>;
  moneyFactorType$: Observable<"CUSTOM" | "INCENTIVE" | "STANDARD">;
  uiStateInsuranceProducts: InsuranceProduct[];
  private selectedProducts: InsuranceProduct[] = [];

  get leaseOptions() {
    return this.deal.leaseOptions;
  }

  get financeOptions() {
    return this.deal.financeOptions;
  }

  get insuranceProductsTotal(): number {
    let total = 0;
    this.insuranceProducts.forEach(product => total += this.productPrice(product));
    return total;
  }

  get insuranceProducts() {
    return this.uiStateInsuranceProducts;
  }

  ngOnInit() {
    this.subToDeal();
    this.moneyFactor$ = this.leaseCalculationService.moneyFactor$(this.leasingTerm);
    this.moneyFactorType$ = this.leaseCalculationService.moneyFactorType$(this.leasingTerm);
  }

  initInsuranceProducts(insuranceProducts: InsuranceProduct[]) {
    insuranceProducts = insuranceProducts.filter(product => {
      return Object.values(LeaseInsuranceProductKeys)
        .includes(product.productKey);
    });
    insuranceProducts = this.dealService.dealInsuranceService.orderInsuranceProducts(insuranceProducts);
    this.uiStateInsuranceProducts = insuranceProducts;
  }

  private subToDeal() {
    combineLatest([
      this.dealService.selectDeal(),
      this.leaseCalculationService.calcGrossCapCost$()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, grossCapCost]) => {
        this.deal = deal;
        if (this.deal.insuranceProducts) {
          this.selectedProducts = this.deal.insuranceProducts;
          this.initialSelectedProducts = this.selectedProducts;
        }
        this.totalEquity = this.calculationService.calcTradeEquity(this.deal, 'lease')
        this.totalEquityPercent = this.totalEquity / grossCapCost
        /*this.calculationService.logManager.consoleLog(
          `Lease Buy Box: ${this.leasingTerm} term`, {
            totalEquity: this.totalEquity,
            grossCapCost,
            totalEquityPercent: this.totalEquityPercent,
          })*/
      });
  }

  // UI CONTROL & RENDERING

  onSelectPaymentPlan() {
    this.dealService.setLeasingTerm(this.leasingTerm);
    this.dealService.dispatchSetDealType("lease");
    this.selectedProducts = this.selectedProducts ? this.selectedProducts.map((product: InsuranceProduct) => {
      return this.dealService.dealInsuranceService.selectTerm(product, this.leasingTerm);
    }) : [];
    // this.dealService.Insurance.dispatchSetInsuranceProducts(this.selectedProducts);
    this.router.navigate([{outlets: {modal: "submit-writeup"}}]);
  }

  paymentPlanSelected(): boolean {
    if (!this.leaseOptions) { return false; }
    return (
      this.leaseOptions.leaseSelected &&
      this.leasingTerm === this.leaseOptions.selectedLeaseTerm
    );
  }

  productSelected(productKey: string): boolean {
    const product = this.selectedProducts.find(item => {
      return item.productKey === productKey;
    });
    return product ? true : false;
  }

  enableProduct(product: InsuranceProduct) {
    return;
    // const disabledProduct = this.disabledProducts.find((disabledProduct: InsuranceProduct) => {
    //   return disabledProduct.productKey === product.productKey;
    // });
    // if (!disabledProduct) {
    //   this.dealService.Insurance.enableUninitializedProduct(
    //     this.insuranceProducts,
    //     product,
    //     this.leasingTerm
    //   );
    //   return;
    // }
    // const disabledProductIndex = this.disabledProducts.findIndex((disProduct: InsuranceProduct) => {
    //   return product.productKey === disProduct.productKey;
    // });
    // if (disabledProductIndex >= 0) {
    //   this.disabledProducts.splice(disabledProductIndex, 1);
    // }
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.submitInsuranceProduct(disabledProduct, disabledProduct.selectedTerm);
  }

  disableProduct(product: InsuranceProduct) {
    return;
    // const selectedProduct = this.selectedProducts.find(item => {
    //   return item.productKey === product.productKey;
    // });
    // this.disabledProducts.push(selectedProduct);
    // this.submitDisabledProducts.emit(this.disabledProducts);
    // this.dealService.Insurance.removeInsuranceProduct(product);
  }

  productPrice(product: InsuranceProduct): number {
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return 0; }

    const {selectedTerm} = selectedProduct;
    const termCost = product.termCosts[ selectedTerm ];

    return termCost ? termCost.price : 0;
  }

  productDescription(product: InsuranceProduct): string {
    const selectedProductWithClosestTerm = this.dealService.dealInsuranceService
      .selectTerm(product, this.leasingTerm);
    const selectedProduct = this.selectedProducts.find(item => {
      return item.productKey === product.productKey;
    });
    if (!selectedProduct) { return ""; }

    const {selectedTerm} = selectedProductWithClosestTerm;
    const termCost = product.termCosts[ selectedTerm ];
    if (product.productKey.toLowerCase() === "gap") {
      // Issue #1958: Remove GAP product description
      return "";
      /*
      const { selectedTerm } = product;
      const termCost = product.termCosts[selectedTerm];
      return "For the life of the loan";
      */
    }

    if (termCost) {
      return `${termCost.term / 12} years / ${termCost.miles} miles`;
    } else {
      return "";
    }
  }

  calculateInsuranceProductMonthlyPaymentForLease$(product: InsuranceProduct): Observable<number> {
    if (!this.productSelected(product.productKey)) {
      return of(0);
    }

    return this.leaseCalculationService
      .calculateInsuranceProductMonthlyPaymentForLease$(product, this.leasingTerm);
  }

  residualValue$(leasingTerm): Observable<number> {
    return this.leaseCalculationService.residualValue$(leasingTerm);
  }

  calcBaseMonthlyPayment$(): Observable<number> {
    if (!this.inputsAvailable) { return of(0); }
    return this.leaseCalculationService.calcBaseMonthlyLeasePayment$({term: this.leasingTerm});
  }

  calcTotalMonthlyPayment$(): Observable<number> {
    if (!this.inputsAvailable) { return of(0); }
    return this.leaseCalculationService.calcTotalMonthlyLeasePayment$({term: this.leasingTerm});
  }

  navToSimplePayment() {
    this.router.navigate([{outlets: {modal: "simple-payment-calculator"}}]);
  }

  inputsAvailable(): boolean {
    return (
      !!this.leasingTerm &&
      !!this.insuranceProducts &&
      !!this.financeOptions &&
      !!this.financeSettings &&
      !!this.leaseOptions &&
      !!this.leaseDefault
    );
  }

}

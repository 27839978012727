<form [formGroup]="fuelSavingsForm"
      class="row">

  <div class="form-group col-sm-6">
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('currentVehicleMPG') }">
      Current Vehicle MPG *
    </label>
    <input class="form-control"
           formControlName="currentVehicleMPG"
           type="number"
           inputmode="numeric"
           min="1"
           (keyup)="integerTrim('currentVehicleMPG')"
           required/>
  </div>

  <div class="form-group col-sm-6">
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('newVehicleMPG') }">
      New Vehicle Combined MPG *
    </label>
    <input class="form-control"
           formControlName="newVehicleMPG"
           type="number"
           inputmode="numeric"
           min="1"
           (keyup)="integerTrim('newVehicleMPG')"
           required/>
  </div>

  <div class="form-group col-sm-6">
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('milesDrivenPerMonth') }">
      Miles Driven Per Month *
    </label>
    <input class="form-control"
           formControlName="milesDrivenPerMonth"
           type="number"
           inputmode="numeric"
           min="0"
           (keyup)="integerTrim('milesDrivenPerMonth')"
           required/>
  </div>

  <div class="form-group col-sm-6">
    <label class="small"
           [ngClass]="{ 'app-text-danger': touchedInvalid('fuelCostPerGallon') }">
      Fuel Cost Per Gallon *
    </label>
    <span class="input-symbol-dollar">
    <input id="currency"
           class="form-control"
           formControlName="fuelCostPerGallon"
           type="number"
           inputmode="numeric"
           value="currency"
           min="0"
           (keyup)="decimalTrim('fuelCostPerGallon')"
           required/>
          </span>
  </div>

  <div class="col-sm-6">
    <div class="form-result">
      <span class="h5 mb-0">
        {{ fuelSavingsForm.valid ? (calcFuelSavings | currency) : '---' }}
      </span>
      <span class="small">monthly fuel savings</span>
    </div>
  </div>

</form>

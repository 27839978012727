import { Component, Input, OnChanges } from "@angular/core";
import { Vehicle, WarrantyBarGraph, WarrantyBarGraphSegment } from "../../../../models";
import { WarrantyUiService } from "src/app/clearpath-module/services";

@Component({
  selector: "app-product-graph",
  templateUrl: "./product-graph.component.html",
  styleUrls: ["./product-graph.component.scss"]
})
export class ProductGraphComponent implements OnChanges {
  @Input() graph: WarrantyBarGraph;
  @Input() activeViewIndex: number;
  @Input() vehicle: Vehicle;

  constructor(private warrantyUIService: WarrantyUiService) { }

  ngOnChanges() {
    this.graph = this.warrantyUIService.addActiveViewIndexBasedCopyModifications(this.graph);
  }

  // UI RENDERING

  graphClasses(segment: WarrantyBarGraphSegment): string {

    // if used, past the 4th index, and the segment is a warranty, collapse the segment
    if (this.activeViewIndex >= 4 && this.vehicle.isUsed &&
      (segment.title === "Basic Manufacturer Warranty" || segment.title == "Manufacturer Powertrain Warranty")) {
      return `${segment.bgClass} bar-graph--collapse`
    }

    const isVisible = segment.visibility.includes(this.activeViewIndex) && segment.width > 0;
    return isVisible ? segment.bgClass : `${segment.bgClass} bar-graph--collapse`;
  }

  showLabel(labelType: string): boolean {
    return this.graph[ labelType ].visibility.includes(this.activeViewIndex);
  }

  showDefaultDetails(): boolean {
    const notVSC = this.graph.product.productKey !== "VSC";
    const isInitialView = this.activeViewIndex < 1;
    return notVSC || isInitialView;
  }

  unlimitedMiles(miles: number): boolean {
    return miles >= 999999;
  }

}
